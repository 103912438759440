import * as React from 'react';
import { useParams } from 'react-router-dom';
import i18n from "i18next";
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import { Icon, IconButton, MessageBar, Spinner } from '@fluentui/react';
import { EmailViewer } from '../_components/_cards/EmailViewer';
import { userService } from '../_services';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { ConversationViewer } from '../_components/_cards/ConversationViewer';
import { NoteViewer } from '../_components/_cards/NoteViewer';
import { AlertInvalidProject } from '../_components/_alerts/AlertInvalidProject';
import { AlertInvalidContract } from '../_components/_alerts/AlertInvalidContract';
import { AlertInvalidContact } from '../_components/_alerts/AlertInvalidContact';
import { AlertInvalidCompany } from '../_components/_alerts/AlertInvalidCompany';
import { TaskViewer } from '../_components/_cards/TaskViewer';
import { DetailsPanel } from '../_components/DetailsPanel';

export interface Props {
  routeProps: any
}

export interface States {
  itemId: string;
  showRightPanel: boolean;
  syncStatus: any;
  breadcrumbPath: any[];
  updated: boolean;
  query:string;
  selItem: any;
  panelHeight: any;
  panelWidth: any;
  newToOld: boolean;
  allCardsCollapsed: boolean;
  cardsSortedBy: string;
  showLastItems: any[];
  searchQuery: string;
  errorAlert: string;
  customProperties: any[];
  contextMenuProps: any;
  possibleContacts: any[];
  alertMessage: any;
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class SubEntityPage extends React.Component<Props, States> {
  private _isMounted: boolean;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    var panelWidth = "240px"
    if (typeof(localStorage.getItem("sidebar3Prefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("sidebar3Prefs") || "[]");
      panelWidth = panelPrefs.width
    }

    this.state = {
      itemId: this.props.params.id,
      showRightPanel: localStorage.detailsPanelOpen ? localStorage.detailsPanelOpen === 'true' : true,
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      },
      breadcrumbPath: [],
      updated: false,
      query: 'all',
      selItem: null,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      newToOld: true,
      allCardsCollapsed: false,
      cardsSortedBy: 'By date',
      showLastItems: [
        {key: 'allActivities', title: 'Show all in Main view'}
      ],
      searchQuery: '',
      errorAlert: '',
      customProperties: [],
      contextMenuProps: null,
      possibleContacts: [],
      alertMessage: null
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    if (this.props.pusher && this.props.userData && this._isMounted) {
      this.getCurrentContent();
      if (this.props.page === 'projectData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "PROJECT"})
      else if (this.props.page === 'contractData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTRACT"})
      else if (this.props.page === 'contactData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTACT"})
      else if (this.props.page === 'companyData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "COMPANY"})
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "NOTE"})
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "EMAIL"})
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONVERSATION"})
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "TASK"})
      this.props.pusher.connect()
    }
  }
  
  public componentDidUpdate(prevProps:any) {
    if (this.props.params.id !== this.state.itemId || this.state.searchQuery !== document.location.search || ((prevProps.userData !== this.props.userData) && this.props.userData)) {
      this.setState({searchQuery: document.location.search, itemId: this.props.params.id})
      this.getCurrentContent();
    }

    if (this.props.params.id !== this.state.itemId) {
      if (this.props.pusher) {
        this.props.pusher.disconnect();
      }
      if (this.props.page === 'projectData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "PROJECT"})
      else if (this.props.page === 'contractData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTRACT"})
      else if (this.props.page === 'contactData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTACT"})
      else if (this.props.page === 'companyData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "COMPANY"})
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "NOTE"})
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "EMAIL"})
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONVERSATION"})
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "TASK"})
      this.props.pusher.connect()
    }

    if (prevProps.pusher !== this.props.pusher) {
      if (this.props.pusher && this.props.userData && this._isMounted) {
        if (this.props.page === 'projectData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "PROJECT"})
        else if (this.props.page === 'contractData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTRACT"})
        else if (this.props.page === 'contactData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTACT"})
        else if (this.props.page === 'companyData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "COMPANY"})
        this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "NOTE"})
        this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "EMAIL"})
        this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONVERSATION"})
        this.props.pusher.connect()
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    if (this.props.pusher) {
      this.props.pusher.disconnect();
    }
  }

  private updateConversation(conversation:any) {
    this._isMounted && this.setState({selItem: conversation})
  }

  private async getCurrentContent() {
    this._isMounted && this.setState({
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      }
    })
    
    userService.getPermissions(this.props.params.id).then((response)=>{
      const permissionsUsers = response.data.userPermissions;
      const permissionsGroups = response.data.groupPermissions;
      const permissionLevels = {
        'ADMIN': 0,
        'EDITOR': 1,
        'RESTRICTED_EDITOR': 2,
        'VIEWER': 3,
        'PREVIEWER': 4
      }
      let highestPermission = 'PREVIEWER';

      let noPermission = true;
      if (this.props.userData && this.props.userData.user.role === 'ADMIN') {
        noPermission = false;
        highestPermission = 'ADMIN'
      } else {
        if (permissionsUsers && permissionsUsers.length > 0) {
          for (let i in permissionsUsers) {
            if (permissionsUsers[i].userId === this.props.userData.user.id) {
              noPermission = false;
              if (permissionLevels[permissionsUsers[i].permissionRole] < permissionLevels[highestPermission]) highestPermission = permissionsUsers[i].permissionRole
            }
          }
        }
        if (permissionsGroups && permissionsGroups.length > 0) {
          for (let i in permissionsGroups) {
            if (this.props.userData && this.props.userData.groups.includes(permissionsGroups[i].groupId)){
              noPermission = false;
              if (permissionLevels[permissionsGroups[i].permissionRole] < permissionLevels[highestPermission]) highestPermission = permissionsGroups[i].permissionRole
            }
          }
        }
      }

      
      if (!noPermission) {
        let entity: any;
        const entities = this.props.page === 'email' ?
          userService.getEmailContent(this.props.params.id).then(async (response:any)=>{
            entity = response.data
            if (entity.attachments && entity.attachments.length > 0) {
              let nAttachments = response.data.attachments.length;
              let index = 0;
              for (let i in entity.attachments) {
                if (entity.attachments[i].inline) {
                  userService.downloadAttachment(entity.id, entity.attachments[i].id).then((response)=>{
                    index += 1;
                    var replace = "src=\"cid:"+entity.attachments[i].name+".*?\"";
                    var re = new RegExp(replace,"g");
                    const byteArray = new Uint8Array(response.data);
                    var blob = new Blob([byteArray], { type: entity.attachments[i].contentType });
                    const url = URL.createObjectURL( blob );
                    entity.body.content = entity.body.content.replace(re, 'src=' + url)
                    if (index >= nAttachments) {
                      this._isMounted && this.setState({selItem: entity})
                    }
    
                  }).catch((error)=> {
                    console.log(error)
                    index += 1;
                    entity.attachments[i].data = response.data;
                    if (index >= nAttachments) {
                      this._isMounted && this.setState({selItem: entity})
                    }
                  })
                } else {
                  index += 1;
                  entity.attachments[i].data = response.data;
                  if (index >= nAttachments) {
                    this._isMounted && this.setState({selItem: entity})
                  }
                }
              }
            } else {
              this._isMounted && this.setState({selItem: entity})
            }
          }).catch((error)=>{
            console.log(error);
            this._isMounted && this.setState({
              errorAlert: 'invalidEntity',
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            })
          })
        : this.props.page === 'note' ?
        userService.getNoteContent(this.props.params.id).then(async (response:any)=>{
          entity = response.data
        }).catch((error)=>{
          console.log(error);
          this._isMounted && this.setState({
            errorAlert: 'invalidEntity',
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        })
        : this.props.page === 'conversation' ?
          userService.getConversationContent(this.props.params.id).then((response)=>{
            entity = response.data
            this._isMounted && this.setState({
              selItem: entity
            });
            userService.getConversationMessages(this.props.params.id, 0).then((res)=>{
              let item = {...this.state.selItem, totalPages: res.data.totalPages, page: 0};
              let messages = res.data.content;
              let requests:any = [];
              for (let i in messages) {
                for (let j in messages[i].attachments) {
                  if (messages[i].attachments[j] && messages[i].attachments[j].inline) {
                    requests.push(userService.downloadConversationAttachment(this.props.params.id, messages[i].id, messages[i].attachments[j].id).then((response)=>{
                      const byteArray = new Uint8Array(response.data);
                      const blob = new Blob([byteArray], { type: messages[i].attachments[j].contentType });
                      const url = URL.createObjectURL( blob );
                      messages[i].attachments[j].blob = url;
                    }).catch((error)=>{
                      console.log(error)
                    }))
                  }
                }
              }
              Promise.all(requests).then(() => {
                item.messages = messages
                entity = item;
                this._isMounted && this.setState({
                  selItem: item
                });
              })
            }).catch((error)=>{
              console.log(error);
              this._isMounted && this.setState({
                errorAlert: 'invalidEntity',
                syncStatus: {
                  message: "Synced",
                  type: "success",
                  icon: "SkypeCircleCheck"
                }
              })
            })
          }).catch((error)=>{
            console.log(error);
            this._isMounted && this.setState({
              errorAlert: 'invalidEntity',
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            })
          })
        : this.props.page === 'task' ?
          userService.getTask(this.props.params.id).then(async (response:any)=>{
            entity = response.data
            this._isMounted && this.setState({
              selItem: entity
            });
            userService.getTaskMessages(this.props.params.id, 0).then((res)=>{
              let item = this.state.selItem;
              let messages = res.data.content;
              let requests:any = [];
              for (let i in messages) {
                for (let j in messages[i].attachments) {
                  if (messages[i].attachments[j] && messages[i].attachments[j].inline) {
                    requests.push(userService.downloadTaskAttachment(this.props.params.id, messages[i].id, messages[i].attachments[j].id).then((response)=>{
                      const byteArray = new Uint8Array(response.data);
                      const blob = new Blob([byteArray], { type: messages[i].attachments[j].contentType });
                      const url = URL.createObjectURL( blob );
                      messages[i].attachments[j].blob = url;
                    }).catch((error)=>{
                      console.log(error)
                    }))
                  }
                }
              }
              Promise.all(requests).then(() => {
                item.messages = messages
                entity = item;
                this._isMounted && this.setState({
                  selItem: item
                });
              })
            }).catch((error)=>{
              console.log(error);
              this._isMounted && this.setState({
                errorAlert: 'invalidEntity',
                syncStatus: {
                  message: "Synced",
                  type: "success",
                  icon: "SkypeCircleCheck"
                }
              })
            })
          }).catch((error)=>{
            console.log(error);
            this._isMounted && this.setState({
              errorAlert: 'invalidEntity',
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            })
          })
        : null

        let contacts:any[] = []
        const getRepoUsers = userService.getRepoUsers('ADMIN,INTERNAL').then((response)=>{
          contacts = response.data;
          for (let i=0; i<contacts.length; i++) {
              contacts[i].key = contacts[i].id
              contacts[i].text = contacts[i].name + (contacts[i].surname ? (' ' + contacts[i].surname) : '')
          }
        }).catch((error)=>{
          console.log(error);
        })
        Promise.all([entities, getRepoUsers]).then((res) => {
          this._isMounted && this.setState({
            updated: true,
            selItem: entity,
            possibleContacts: contacts,
            breadcrumbPath: this.props.page === 'email' ? [
              {
                text: i18n.t('app:emails'),
                key: 'emails',
                onClick: ()=>{history.push('/emails')}
                },
                {
                text: entity?.subject,
                key: 'email',
                }
            ] : this.props.page === 'note' ? [
              {
                text: i18n.t('app:notes'),
                key: 'notes',
                onClick: ()=>{history.push('/notes')}
                },
                {
                text: entity?.subject,
                key: 'note',
                }
            ] : this.props.page === 'conversation' ? [
              {
                text: i18n.t('app:conversations'),
                key: 'conversations',
                onClick: ()=>{history.push('/conversations')}
                },
                {
                text: entity?.subject,
                key: 'conversation',
                }
            ] : this.props.page === 'task' ? [
              {
                text: i18n.t('app:tasks'),
                key: 'tasks',
                onClick: ()=>{history.push('/tasks')}
                },
                {
                text: entity?.subject,
                key: 'task',
                }
            ] : [],
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        })
      }
      else {
        this._isMounted && this.setState({
          errorAlert: 'noPermission',
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          }
        })
      }
    }).catch((error)=>{
      console.log(error);
      this._isMounted && this.setState({
        errorAlert: 'invalidEntity' ,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      })
    })
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private _goBackToList() {
    if(this.props.page === "note") history.push("/notes")
    if(this.props.page === "email") history.push("/emails")
    if(this.props.page === "conversation") history.push("/conversations")
    if(this.props.page === "task") history.push("/tasks")
  }

  public render() {
    const { itemId, breadcrumbPath, selItem, customProperties } = this.state

    return(
      <div>
        <Header
          ref={instance => { this.headerRef = instance; }}
          userData={ this.props.userData }
          page={this.props.page}
          items={[]}
          selItem={selItem}
          callbackFunction={this.callbackFunction}
          showRightPanel={ this.state.showRightPanel }
          syncStatus={this.state.syncStatus}
          breadcrumbPath={breadcrumbPath}
          sidebarRef={ this.sidebarRef }
          getCurrentContent={this.getCurrentContent.bind(this)}
          customProperties={customProperties}
          id={itemId}
          parentId={this.props.params.id}
        />
        <div className="content-wrap d-flex flex-row" >
          <Sidebar
            ref={(instance:any) => { this.sidebarRef = instance; }}
            headerRef={ this.headerRef }
            userData={ this.props.userData }
            page={this.props.page}
            callbackFunction={this.callbackFunction}
            item={selItem}
            id={itemId}
            closedByDefault={true}
          />
          <div className='d-flex flex-grow-1' style={{overflowX: 'auto', overflowY: 'hidden', height: 'calc(100vh - 129px)'}}>
            <div className="list mr-auto flex-grow-1 d-flex flex-column" style={{minWidth: '420px'}}>
              { this.state.syncStatus.message === 'Synced' && this.state.selItem ? <div style={{overflowY: 'hidden', overflowX: 'auto', minWidth: '400px', height: '100%'}}>
                <div className='d-flex header-card py-2'>
                  <div className='d-flex flex-grow-1 align-items-center ml-1'>
                    <IconButton className='mr-3' iconProps={{iconName: 'Back'}} onClick={ this._goBackToList.bind(this) }/>
                    { this.props.page === 'email' ?
                      <Icon className='activity-icon mr-3' style={{width: '32px', minWidth: '32px'}} {...getFileTypeIconProps({ extension: '.eml', size: 32, imageFileType: 'svg' }) } />
                    : this.props.page === 'conversation' ?
                      <img className='activity-icon mr-3' style={{transform: 'scale(1, 1.3)', height: '24px', width: '24px', minWidth: '24px'}} alt='Note' src={process.env.PUBLIC_URL + '/icons/conversations.svg'} />
                    : this.props.page === 'note' ?
                      <img className='activity-icon mr-3' style={{height: '24px', width: '24px', minWidth: '24px'}} alt='Note' src={process.env.PUBLIC_URL + '/icons/documents.svg'} />
                    : this.props.page === 'task' ?
                      <img className='activity-icon mr-3' style={{height: '24px', width: '24px', minWidth: '24px'}} alt='Task' src={process.env.PUBLIC_URL + '/icons/tasks.svg'} />
                    : 
                      <Icon className='mr-3' iconName={this.state.selItem.icon} style={{fontSize: '20px'}}/>
                    }
                    <span style={{fontWeight: 600}}>{this.state.selItem.subject}</span>
                  </div>
                </div>
                <div className='flex-grow-1' style={{height: 'calc(100% - 44px)'}}>
                  { this.state.selItem &&
                    <div className='h-100' key={this.state.selItem.key}>
                      {this.activityCard(this.state.selItem)}
                    </div>
                  }
                </div>
              </div> : this.state.syncStatus.message === 'Synced' ? <div className='m-auto' style={{opacity: .8}}>{i18n.t('app:couldntLoadEntity')}</div> : <Spinner className='m-auto'/>}
              {this.state.alertMessage ?
                <div id="alertMessage">
                  <MessageBar
                    messageBarType={this.state.alertMessage.type}
                    isMultiline={true}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => this.setState({alertMessage: null})}
                  >
                    <div dangerouslySetInnerHTML={{__html: this.state.alertMessage.text}}></div>
                  </MessageBar>
                </div>
              : null }
            </div>
          </div>
          <DetailsPanel
            appPlatform={ this.props.appPlatform }
            userData={ this.props.userData }
            showRightPanel={ this.state.showRightPanel }
            callbackFunction={this.callbackFunction}
            page={this.props.page}
            id={itemId}
            selItem={selItem}
            items={[selItem]}
            customProperties={customProperties}
            headerRef={this.headerRef}
            getCurrentContent={this.getCurrentContent.bind(this)}
            getImportance={this.getImportance.bind(this)}
            possibleContacts={this.state.possibleContacts}
          />
        </div>
        {this.state.errorAlert && this.state.errorAlert.length > 0 && this.props.page === 'projectData' && <AlertInvalidProject errorAlert={this.state.errorAlert} callbackFunction={this.callbackFunction}/>}
        {this.state.errorAlert && this.state.errorAlert.length > 0 && this.props.page === 'contractData' && <AlertInvalidContract errorAlert={this.state.errorAlert} callbackFunction={this.callbackFunction}/>}
        {this.state.errorAlert && this.state.errorAlert.length > 0 && this.props.page === 'contactData' && <AlertInvalidContact errorAlert={this.state.errorAlert} callbackFunction={this.callbackFunction}/>}
        {this.state.errorAlert && this.state.errorAlert.length > 0 && this.props.page === 'companyData' && <AlertInvalidCompany errorAlert={this.state.errorAlert} callbackFunction={this.callbackFunction}/>}
      </div>
    )
  }

  private getImportance(item:any) {

    const tags = {
      'URGENT': '#d69ca5',
      'LATER': '#f4bfab',
      'FOLLOW_UP': '#f9e2ae',
      'MAYBE': '#bdd99b',
    }

    return (
      <div className='d-flex align-items-center'>
        <Icon 
          iconName="CircleShapeSolid" 
          className="tag mr-1" 
          style={{
            fontSize: 12,
            textAlign:'center',
            color: tags[item.taskImportance] || 'black'
          }} 
        />
        <span style={{paddingBottom: '2px', fontSize: '12px', color: 'rgb(66, 66, 66)'}}>{i18n.t('app:' + item.taskImportance)}</span>
      </div> 
    )
  }

  private updateTask(task:any, ) {
    this._isMounted && this.setState({selItem: task})
  }

  private activityCard(item) {
    if (this.props.page === 'email') {
      return (
        <div className='h-100' onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
          <EmailViewer
            allCardsCollapsed={this.state.allCardsCollapsed}
            item={item}
          />
        </div>
      )
    } else if (this.props.page === 'conversation') {
      return (
        <div className='h-100' onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
          <ConversationViewer
            allCardsCollapsed={this.state.allCardsCollapsed}
            item={item}
            userData={this.props.userData}
            headerRef={this.headerRef}
            updateConversation={this.updateConversation.bind(this)}
            possibleContacts={this.state.possibleContacts}
          />
        </div>
      )
    } else if (this.props.page === 'note') {
      return (
        <div className='h-100' onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
          <NoteViewer
            allCardsCollapsed={this.state.allCardsCollapsed}
            item={item}
            possibleContacts={this.state.possibleContacts}
          />
        </div>
      )
    } else if (this.props.page === 'task') {
      return (
        <div className='h-100' onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
          <TaskViewer
            item={item}
            userData={this.props.userData}
            headerRef={this.headerRef}
            updateTask={this.updateTask.bind(this)}
            possibleContacts={this.state.possibleContacts}
            getImportance={this.getImportance.bind(this)}
          />
        </div>
      )
    }
  }

  private getContactName(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })

    return user ? (user.name || '') + ' ' + (user.surname || '') : ''
  }
}

export default withParams(SubEntityPage);
