import { Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import i18n from '../../i18n';

export interface Props {
  callback: any;
}

export interface States {
  hidden: boolean;
}

export class AlertDialog extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      setTimeout(()=>this.props.callbackFunction({close: true}),200)
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public render() {

    return(
      <Dialog
        hidden={this.state.hidden}
        dialogContentProps={{
          type: DialogType.normal,
          title: this.props.title,
          onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
        }}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: 360 } },
          dragOptions: undefined,
          className: "alert-dialog-default",
          onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
        }}
      >
        <div style={{overflow: 'auto'}}>
          {this.props.description && <p>{this.props.description}</p>}
        </div>
        <DialogFooter>
          <PrimaryButton onClick={()=>{this._isMounted && this.setState({hidden:true})}} text={i18n.t('OK')} />                   
        </DialogFooter>
      </Dialog>
    )
  }

}
