import * as React from 'react';
import "moment/min/locales";
import { DetailsListLayoutMode, Selection, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { Sidebar } from '../_components/Sidebar';
import { Header } from '../_components/Header';
import { ListExplorer } from '../_components/ListExplorer';
import { DetailsPanel } from '../_components/DetailsPanel';
import { Icon, Text } from '@fluentui/react';
import i18n from "i18next";
import { history } from '../_helpers';
import { userService } from '../_services';
import { _importCompanies } from './importCompanies';
import { Link, Persona } from '@fluentui/react-components';

declare global {
  interface Window {
      require: any;
      sreader: any;
  }
}

export interface Props {
  appPlatform: string,
  database: any,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  pusherMessage: any,
  page: string,
  userData: any,
  foldersList: any,
  repoUsers: any
}

export interface IDetailsListDashboardState {
  userData?: any;
  repoData?: any;
  goBack: number;
  isLoading: boolean;
  blankLoading: boolean;
  savingTag: boolean;
  savingMessage: boolean;
  syncStatus: any;
  downloadingFiles: any;
  creatingFoldersStructure: boolean;
  acceptedFiles: any;
  acceptedFolders: any;
  breadcrumbPath: any[];
  columns: IColumn[];
  fullItemsResponse: any;
  showFilters: boolean;
  filterByNameText: string;
  fullItemsResponseByName: any;
  filterByTypeText: string;
  fullItemsResponseByType: any;
  storedFiles: any;
  draggedItem: any;
  selItem: any;
  showRightPanel: boolean;
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  newTag: string;
  newMessage: string;
  savingFolder: boolean;
  showTagBulkDialog: boolean;
  hideDialog: boolean;
  activeTab: string;
  language: string;
  filterToggle: boolean;
  toolbarHeight: number;
  searchQuery: string;
  companies: any[];
  customProperties: any[];
  loadingMore: boolean;
}

export class CompaniesPage extends React.Component<Props, IDetailsListDashboardState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();
  private loadingMore:boolean = false;
  private page:number = 1;

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this._selection = new Selection({
      onSelectionChanged: () => {
        this._isMounted && this.setState({
          selItem: this._selection.getSelection()[this._selection.getSelection().length - 1] || []
        });
      }
    });

    this.state = {
      userData: null,
      repoData: null,
      goBack: 0,
      isLoading: true,
      blankLoading: false,
      savingTag: false,
      savingMessage: false,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      downloadingFiles: [],
      creatingFoldersStructure: false,
      acceptedFiles: [],
      acceptedFolders: [],
      breadcrumbPath: [{
        text: i18n.t('app:companies'),
        key: 'companies',
        onClick: ()=>{history.push('/companies')}
      }],
      showFilters: false,
      fullItemsResponse: [],
      filterByNameText: "",
      fullItemsResponseByName: [],
      filterByTypeText: "",
      fullItemsResponseByType: [],
      storedFiles: [],
      draggedItem: null,
      columns: [],
      selItem: null,
      showRightPanel: localStorage.detailsPanelOpen ? localStorage.detailsPanelOpen === 'true' : true,
      selectionDetails: '',
      isModalSelection: false,
      isCompactMode: true,
      newTag: '',
      newMessage: '',
      savingFolder: false,
      showTagBulkDialog: false,
      hideDialog: true,
      activeTab: 'details',
      language: i18n.language,
      filterToggle: true,
      toolbarHeight: 89,
      searchQuery: document.location.search,
      companies: [],
      customProperties: [],
      loadingMore: false,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this._getColumns();
    this._isMounted && this.props.userData && this._getRepoData();
    this._isMounted && this.props.userData && this.getCurrentContent();
    if (this.props.pusher && this.props.userData && this._isMounted) {
      console.log('Pusher connect: Workflow-'+ this.props.userData.repository.id +"-COMPANY")
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "COMPANY"})
      this.props.pusher.connect()
    }
  }

  public componentDidUpdate(prevProps: any) {

    if(this.state.language !== i18n.language) {
      this.setState({language: i18n.language});
    }

    if (prevProps.pusher !== this.props.pusher) {
      if (this.props.pusher && this.props.userData && this._isMounted) {
        console.log('Pusher connect: Workflow-'+ this.props.userData.repository.id +"-COMPANY")
        this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "COMPANY"})
        this.props.pusher.connect()
      }
    }

    if(this.props.userData !== prevProps.userData
    || this.state.searchQuery !== document.location.search ) {
      this.props.userData && this._getRepoData();
      this.getCurrentContent();
    }

    if (prevProps.pusherMessage !== this.props.pusherMessage) {
      const { pusherMessage } = this.props;
      if(pusherMessage && pusherMessage.message) {
        console.log(pusherMessage)
        /*if (pusherMessage.message.itemType === 'COMPANY') {
          if (pusherMessage.message.action === 'DELETE') {
            let items = this.state.companies;
            items = items.filter((item)=>{return item.id !== pusherMessage.message.itemId})
            this._isMounted && this.setState({companies: items})
          } else if (pusherMessage.message.action === 'UPDATE') {
            this.getCurrentContent(true);
          }
          else if (pusherMessage.message.action === 'CREATE') {
            this.getCurrentContent(true);
          }
        }*/
      }
    }

  }

  public componentWillUnmount() {
    this._isMounted = false;
    if (this.props.pusher) {
      this.props.pusher.disconnect();
    }
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private filterByRelations(items) {
    const params = new URLSearchParams(document.location.search);
    let project = params.get('projectIds') || ''
    let contract = params.get('contractIds') || ''
    let contact = params.get('contactIds') || ''
    let newItems = items;
    if (project === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.projectIds) {
          if (item.projectIds[i]) return true
        }
        return false
      })
    } else if (project === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.projectIds || item.projectIds.length === 0) return true
        return false
      })
    } else if (project && project.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.projectIds) {
          if (project.includes(item.projectIds[i])) return true
        }
        return false
      })
    }
    if (contract === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.contractIds) {
          if (item.contractIds[i]) return true
        }
        return false
      })
    } else if (contract === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.contractIds || item.contractIds.length === 0) return true
        return false
      })
    } else if (contract && contract.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.contractIds) {
          if (contract.includes(item.contractIds[i])) return true
        }
        return false
      })
    }
    if (contact === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.contactIds) {
          if (item.contactIds[i]) return true
        }
        return false
      })
    } else if (contact === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.contactIds || item.contactIds.length === 0) return true
        return false
      })
    } else if (contact && contact.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.contactIds) {
          if (contact.includes(item.contactIds[i])) return true
        }
        return false
      })
    }
    return newItems;
  }

  private getCurrentContent(hidden = false) {
    this.page = 0;
    this._isMounted && this.setState({
      isLoading: true,
      companies: hidden ? this.state.companies : [],
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      },
      searchQuery: document.location.search
    })
    
    let newItems: any[] = [];
    let query = document.location.search;
    const getCompanies = query && query.length > 0 ?
    //When query is present we do a paginated search
    userService.searchCompanies(query).then((response)=>{
      newItems = response.data.content.map((item:any)=>{return {...item, key: item.id}});
      newItems = response.data.last ? newItems :[...newItems, null];
      //newItems = this.filterByRelations(newItems);
    }).catch(()=>{
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      });
    })
    : 
    //When there is no query we get all entities
    userService.getCompanies().then((response)=>{
      newItems = _copyAndSort(response.data.filter((item)=>{return !item.trash}), 'name', false);
      //newItems = this.filterByRelations(newItems);
    }).catch(()=>{
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      });
    })

    let customProperties: any[] = [];
    const getCustomProperties = userService.getCustomProperties('','COMPANY').then((res) =>{
      customProperties = res.data.map(item=>{return {...item, id: item.id, name: item.name, position: item.customPropertyTypes.find(x=>{return x.entityType === 'COMPANY'}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === 'COMPANY'}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}});
      customProperties = customProperties.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
    }).catch(()=>{
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      });
    })

    Promise.all([getCompanies, getCustomProperties]).then((res) => {
      this._isMounted && this.setState({
        companies: newItems.map((item:any)=>{return item ? {...item, key: item.id} : null}),
        customProperties: customProperties,
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      },()=>{
        this._isMounted && this.setState({
          selItem: this._selection.getSelection()[this._selection.getSelection().length - 1] || null
        });
      });
      this._sortBySavedColumn(newItems);
    })
  }

  private getMoreContent() {

    const { loadingMore } = this.state;

    if (!loadingMore && !this.loadingMore) {
      this.loadingMore = true;
      setTimeout(()=>{
        this._isMounted && this.setState({
          loadingMore: true,
        })
        if (document.location.search && document.location.search.length > 0) {
          this.page += 1;
          userService.searchCompanies(document.location.search + '&page=' + this.page).then(async (response:any)=>{
            let oldItems = this.state.companies;
            oldItems = oldItems.filter((item)=> {return item});
            var newItems:any = oldItems.concat(response.data.content)
            newItems = response.data.last ? newItems :[...newItems, null];
            this.loadingMore = false;
            console.log(newItems)
            this._isMounted && this.setState({
              companies: newItems.map((item:any)=>{return item ? {...item, key: item.id} : null }),
              isLoading: false,
              loadingMore: false,
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            });
          }).catch(()=>{
            this.loadingMore = false;
            this._isMounted && this.setState({
              loadingMore: false,
            });
          })
        } else {
          this.loadingMore = false;
          this._isMounted && this.setState({
            loadingMore: false,
          });
        }
      },50)
    }
  }

  public render() {

    const { columns, companies, customProperties, isCompactMode, searchQuery, showRightPanel } = this.state;

    return (
      <div>
            <Header
              ref={instance => { this.headerRef = instance; }}
              userData={ this.props.userData }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              page={this.props.page}
              sidebarRef={ this.sidebarRef }
              breadcrumbPath= {this.state.breadcrumbPath}
              syncStatus = {this.state.syncStatus}
              items={companies}
              callbackFunction={this.callbackFunction}
              showRightPanel={showRightPanel}
              selItem={ this.state.selItem }
              getCurrentContent={this.getCurrentContent.bind(this)}
              import={_importCompanies}
              customProperties={customProperties}
            />
          <div className="content-wrap d-flex flex-row">
            <Sidebar
              ref={(instance:any) => { this.sidebarRef = instance; }}
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              userData={ this.props.userData }
              page={this.props.page}
              items={companies}
              searchQuery={searchQuery}
              callbackFunction={this.callbackFunction}
            />
            <div className='d-flex flex-grow-1' style={{overflowX: 'auto', minWidth: '0px'}}>
              <div className={"list mr-auto " + (this.props.page === 'companiesExplorer' ? 'flex-grow-1' : '')} style={{overflowX: 'auto'}} >
                <ListExplorer
                  ref={instance => { this.listExplorerRef = instance; }}
                  appPlatform={ this.props.appPlatform }
                  isOnline={ this.props.isOnline }
                  maintenance={ this.props.maintenance }
                  socket={this.props.socket}
                  pusher={this.props.pusher}
                  userData={this.props.userData}
                  foldersList={ this.props.foldersList }
                  items={companies}
                  selItem={ this.state.selItem }
                  isCompactMode={isCompactMode}
                  columns={columns}
                  selectionMode={SelectionMode.multiple}
                  layoutMode={DetailsListLayoutMode.fixedColumns}
                  constrainMode={ConstrainMode.unconstrained}
                  headerRef={ this.headerRef }
                  detailsPanelRef={ this.detailsPanelRef }
                  callbackFunction={ this.callbackFunction }
                  page={this.props.page}
                  sidebarRef={ this.sidebarRef }
                  loading={this.state.isLoading}
                  searchMore={this.getMoreContent.bind(this)}
                  loadingMore={this.state.loadingMore}
                />      
              </div>
              {this.props.page === 'companiesPreview' && <div className="list mr-auto flex-grow-1 d-flex flex-column" style={{minWidth: '0px'}}>
                <div style={{overflowY: 'hidden', overflowX: 'auto', minWidth: '0px', height: '100%'}}>
                <div className='d-flex header-card py-2'>
                  <div className='d-flex flex-grow-1 align-items-center ml-1'>
                    {this.state.selItem ? 
                    <>
                      <img className='activity-icon mr-3' style={{height: '24px', width: '24px', minWidth: '24px'}} alt='Conversation' src={process.env.PUBLIC_URL + '/icons/conversations.svg'} />
                      <span style={{fontWeight: 600}}>{this.state.selItem.subject}</span>
                    </>
                    :
                    i18n.t('app:mainView')
                    }
                  </div>
                </div>
                <div className='flex-grow-1' style={{height: 'calc(100% - 44px)'}}>
                  { this.state.selItem ?
                    <div className='h-100 px-5' key={this.state.selItem.key}>
                      {/*this.activityCard(this.state.selItem)*/}
                      Fields here
                    </div>
                  : this.listExplorerRef && this.listExplorerRef._selection && this.listExplorerRef._selection.getSelection().length > 1 ?
                  <div className='text-center h-100  d-flex flex-column' style={{backgroundColor: 'rgb(250, 249, 248)'}}> 
                    <div className='text-center text-secondary m-auto'>
                      <Icon style={{fontSize: '24px'}} iconName='Documentation'/>
                      <p>{this.listExplorerRef._selection.getSelection().length + ' ' + i18n.t('app:itemsSelected')}</p>
                    </div>
                  </div> 
                  : 
                  <div className='text-center h-100  d-flex flex-column' style={{backgroundColor: 'rgb(250, 249, 248)'}}> 
                    <div className='text-center text-secondary m-auto'>
                      <Icon style={{fontSize: '24px'}} iconName='Preview'/>
                      <p>{i18n.t('app:noItemSelected')}</p>
                    </div>
                  </div> 
                  }
                  </div>
                </div>
              </div>}
            </div>

            <DetailsPanel
              ref={instance => { this.detailsPanelRef = instance; }}
              appPlatform={ this.props.appPlatform }
              userData={ this.props.userData }
              repoData={ this.state.repoData }
              foldersList={ this.props.foldersList }
              repoUsers={this.props.repoUsers}
              items={ companies }
              showRightPanel={ this.state.showRightPanel }
              selection={ this._selection }
              selItem={ this.state.selItem }
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              page={ this.props.page }
              detailsPanelSection="explorer"
              callbackFunction={ this.callbackFunction }
              customProperties={customProperties}
            />
          </div>  
      </div>
    );
  }

  private _getColumns() {
    var columns: any = []
    if (this.props.page === "companiesExplorer" || this.props.page === "companiesPreview") {
      columns = [
        {
          key: 'icon',
          name: '#',
          fieldName: 'icon',
          minWidth: 1,
          isRowHeader: true,
          data: 'string',
          isPadded: true,
          onRender: (item: any) => {
            return (
              item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '0px'}}/>
              :
                <div style={{borderRadius: '0px', height: '28px'}}><Persona className='company-persona' name={item.name} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful", shape: "square" }}/></div>
              )
          }
        },
        {
          key: 'Company name',
          name: i18n.t('app:name'),
          fieldName: 'name',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <span title={ item.name }>{ item.name }</span>
            )
          }
        },
        {
          key: 'domain',
          name: i18n.t('app:domain'),
          fieldName: 'domain',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              item.domain && <Link style={{fontWeight: 400}} target={'_blank'} rel="noreferrer" href={'https://'+item.domain.replace('https://','')} title={ item.domain }>{ item.domain }</Link> 
            )
          }
        },
        {
          key: 'nickname',
          name: i18n.t('app:nickName'),
          fieldName: 'nickname',
          minWidth: 220,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Text title={ item.nickname }>{ item.nickname }</Text>
            )
          }
        },
        {
          key: 'linkedin',
          name: i18n.t('app:linkedIn'),
          fieldName: 'linkedin',
          minWidth: 320,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Link style={{fontWeight: 400}} href={item.linkedin} title={ item.linkedin } target='_blank' rel='noreferrer'>{ item.linkedin }</Link> 
            )
          }
        }
      ]
    }
    this._isMounted && this.setState({
      columns: columns
    })
  }

  onDragOver = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragEnter = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    if(this.listExplorerRef
      && !this.listExplorerRef.state.draggedItem) {
        
      setTimeout(() => {
        var el: any = document.getElementsByClassName("ms-ScrollablePane")
        for(var i = 0; i < el.length; i++) {
          if (!el[i].classList.contains("dragOverList")) {
            el[i].classList.add('dragOverList');
          }
        }
      })
    }

    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave = (e) => {
    if (e.target.className.indexOf("ms-ScrollablePane") !== -1) {
      var el: any = document.getElementsByClassName("ms-ScrollablePane")
      for(var i = 0; i < el.length; i++) {
        el[i].classList.remove('dragOverList');
      }
    }

    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onFileDrop = (e) => {
    var el: any = document.getElementsByClassName("ms-ScrollablePane")
    for(var i = 0; i < el.length; i++) {
      el[i].classList.remove('dragOverList');
    }
    
    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();

  }

  private _getRepoData() {
    this._isMounted && this.setState({
      repoData: this.props.userData.repository
    }, () => {
      //this._getCurrentContent();
    })
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, companies } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(companies, currColumn.fieldName!, currColumn.isSortedDescending);
    localStorage.setItem("columnsCompanies", JSON.stringify(newColumns))
    this._isMounted && this.setState({
      columns: newColumns,
      companies: newItems,
    });
  };

  private _sortBySavedColumn = (items: any): void => {
    if (typeof(localStorage.getItem("columnsCompanies")) === "string") {
      var dashboardColumns: IColumn[] = JSON.parse(localStorage.getItem("columnsCompanies") || "[]");

      for (let i = 0; i < this.state.columns.length; i++) {
        let column = this.state.columns[i]

        let savedColumn: any = dashboardColumns.filter(savedColumn => {
          return column.key === savedColumn.key
        })
        savedColumn = savedColumn[0]

        if(column && savedColumn) {
          column.isSorted = savedColumn.isSorted;
          column.isSortedDescending = savedColumn.isSortedDescending

          if(column.isSorted) {
            const { columns } = this.state;
            const newColumns: IColumn[] = columns.slice();
            const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        
            const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
            this._isMounted && this.setState({
              columns: newColumns,
              companies: newItems.map((item:any)=>{return {...item, key: item.id}}),
            });
          }
        } else { 
          this._isMounted && this.setState({companies: items.map((item:any)=>{return {...item, key: item.id}})})
        }
      }
    } else {
      this._isMounted && this.setState({companies: items.map((item:any)=>{return {...item, key: item.id}})})
    }
  };

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  return items.slice(0).sort((a,b) => {
    if (!a) return -1;
    if (!b) return 1;
    if (!isSortedDescending) {
      return (a[columnKey] || '').localeCompare(b[columnKey] || '');
    } else {
      return (b[columnKey] || '').localeCompare(a[columnKey] || '');
    }
  });
}