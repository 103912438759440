import { Icon, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import i18n from "i18next";
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { FluentProvider, Persona } from '@fluentui/react-components';
import { userService } from '../../_services';
import Moment from 'moment';

export interface Props {
  allCardsCollapsed: boolean;
  item: any;
}

export interface States {
  item: any;
}

export class EmailViewer extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      item: this.props.item,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate(prevProps:any) {
    if (prevProps.item !== this.props.item) {
      this._isMounted && this.setState({item: this.props.item})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private downloadAttachment(item) {
    userService.downloadAttachment(this.state.item.id, item.id, true).then((response)=>{
      var url = window.URL.createObjectURL(response.data);
      var anchorElem = document.createElement("a");
      anchorElem.href = url;
      anchorElem.download = item.name;

      document.body.appendChild(anchorElem);
      anchorElem.click();

      document.body.removeChild(anchorElem);
    })
  }

  public render() {
    return(
      <div className='h-100 d-flex flex-column'>
        <div className='py-3' style={{background: 'white', borderBottom: '1px solid #f2f2f2'}}>
          <div className='ml-3'>
            <div className='email-header px-2'>
              <div className='email-from d-flex mb-1'>
                <span style={{minWidth:'40px', textAlign: 'left', marginRight: '10px'}}>{i18n.t('app:date')+ ': '}</span>
                <span className='mb-1' style={{whiteSpace: 'nowrap', overflow:'hidden'}}>{Moment(this.state.item.savedDateTime).format(i18n.t('Y-MMM-DD HH:mm'))}</span>
              </div>
              <div className='email-from d-flex mb-1'>
                <span style={{minWidth:'40px', textAlign: 'left', marginRight: '10px'}}>{i18n.t('app:from')+ ': '}</span>
                <TooltipHost
                  content= {
                    <div className='m-1'>
                      <FluentProvider>
                        <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
                        <Persona className='mb-2' name={this.state.item.from.emailAddress.name} secondaryText={this.state.item.from.emailAddress.address} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
                      </FluentProvider>
                    </div>
                  }
                >
                  <Persona size='extra-small' name={this.state.item.from.emailAddress.name} textAlignment='center' avatar={{ color: "colorful" }}/>
                </TooltipHost>
              </div>
              <div className='email-to d-flex mb-1'>
                <span style={{minWidth:'40px', textAlign: 'left', marginRight: '10px'}}>{i18n.t('app:To')+ ': '}</span>
                <div>
                  {this.state.item && this.state.item.toRecipients && this.state.item.toRecipients.map((item, i)=>{
                    return (
                      <span key={item.emailAddress.address+'to'}>
                      <TooltipHost
                        content= {
                          <div className='m-1'>
                            <FluentProvider>
                              <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
                              <Persona className='mb-2' name={item.emailAddress.name} secondaryText={item.emailAddress.address} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
                            </FluentProvider>
                          </div>
                        }
                      >
                        <Persona className='pr-2' size='extra-small' name={i === this.state.item.toRecipients.length-1 ? item.emailAddress.name : item.emailAddress.name+', '} textAlignment='center' avatar={{ color: "colorful" }}/>
                      </TooltipHost>
                      </span>
                    )                        
                  })}
                </div>
              </div>
              {this.state.item && this.state.item.ccRecipients && this.state.item.ccRecipients.length>0 && 
              <div className='d-flex mb-1'>
                <span style={{minWidth:'40px', textAlign: 'left', marginRight: '10px', fontSize: '14px'}}>{i18n.t('app:Cc')+':'}</span>
                <div>
                  {this.state.item && this.state.item.ccRecipients && this.state.item.ccRecipients.map((item, i)=>{
                  return (
                    <span key={item.emailAddress.address+'cc'}>
                    <TooltipHost
                      content= {
                        <div className='m-1'>
                          <FluentProvider>
                            <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
                            <Persona className='mb-2' name={item.emailAddress.name} secondaryText={item.emailAddress.address} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
                          </FluentProvider>
                        </div>
                      }
                    >
                      <Persona className='pr-2' size='extra-small' name={i === this.state.item.ccRecipients.length-1 ? item.emailAddress.name : item.emailAddress.name+', '} textAlignment='center' avatar={{ color: "colorful" }}/>
                    </TooltipHost>
                    </span>
                  )                          
                  })}
                </div>
              </div>}
              {this.state.item.bccRecipients && this.state.item.bccRecipients.length>0 && 
              <div className='d-flex mb-1'>
                <span style={{minWidth:'40px', textAlign: 'left', marginRight: '10px', fontSize: '14px'}}>{i18n.t('app:Bcc')+':'}</span>
                <div>
                  {this.state.item && this.state.item.bccRecipients && this.state.item.bccRecipients.map((item, i)=>{
                    return (
                      <span key={item.emailAddress.address+'bcc'}>
                      <TooltipHost
                        content= {
                          <div className='m-1'>
                            <FluentProvider>
                              <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
                              <Persona className='mb-2' name={item.emailAddress.name} secondaryText={item.emailAddress.address} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
                            </FluentProvider>
                          </div>
                        }
                      >
                        <Persona className='pr-2' size='extra-small' name={i === this.state.item.bccRecipients.length-1 ? item.emailAddress.name : item.emailAddress.name+', '} textAlignment='center' avatar={{ color: "colorful" }}/>
                      </TooltipHost>
                      </span>
                    )                          
                  })}
                </div>
              </div>}
              <div className='d-flex w-100' style={{overflowX: 'auto'}}>
              {this.state.item.hasAttachments && this.state.item.attachments.map((item) => {return <div key={item.id} className='email-attachment' onClick={()=>{this.downloadAttachment(item)}} >
                <Icon className='mr-2' {...getFileTypeIconProps({ extension: item.name.split('.')[item.name.split('.').length -1], size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '24px', width: '24px', height: '24px'}}/>
                <span title={item.name}>{item.name}</span>
              </div>})}
              </div>
            </div>
          </div>
        </div>
        <div id='main-view' className='flex-grow-1' style={{overflowY: 'auto', scrollbarGutter: 'stable'}}>
          <div id='iframe-container' className='iframe-container'>
            <iframe id="preview-email-iframe" title="preview-email-iframe" className='bg-white flex-grow-1' allowFullScreen srcDoc={this.state.item && this.state.item.body && this.state.item.body.content} 
              onLoad={()=>{
                let iframe:any = document.getElementById('preview-email-iframe');
                let height = iframe.contentDocument.body.offsetHeight || 0;
                let iframeContainer:any = document.getElementById('iframe-container')
                iframeContainer.style.height = (height + 40) + 'px';
                iframe.contentDocument.body.style.fontFamily = "Arial";
                iframe.contentDocument.body.style.fontSize = "14px";
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  private frameCSS() {
    const iframe:any = document.getElementById('mail-iframe');
    setTimeout(()=>{
    if (iframe) {
      const link = document.createElement("style");
      link.innerHTML = `
        body {
          font-family: Segoe UI;
        }
      `
      //iframe.contentDocument.head.appendChild(link)
    }
    },100)
  }

}
