import * as React from 'react';
import "moment/min/locales";
import { DetailsListLayoutMode, Selection, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import i18n from "i18next";

import { Sidebar } from '../_components/Sidebar';
import { Header } from '../_components/Header';
import { ListExplorer } from '../_components/ListExplorer';
import { DetailsPanel } from '../_components/DetailsPanel';
import { Text } from '@fluentui/react';
import { history } from '../_helpers';
import { userService } from '../_services';
import { _importContacts } from './importContacts';
import { Link, Persona } from '@fluentui/react-components';

declare global {
  interface Window {
      require: any;
      sreader: any;
  }
}

export interface Props {
  appPlatform: string,
  database: any,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  pusherMessage: any,
  page: string,
  userData: any,
  foldersList: any,
  repoUsers: any
}

export interface State {
  userData?: any;
  repoData?: any;
  goBack: number;
  isLoading: boolean;
  blankLoading: boolean;
  savingTag: boolean;
  savingMessage: boolean;
  syncStatus: any;
  columns: IColumn[];
  items: any;
  fullItemsResponse: any;
  showFilters: boolean;
  filterByNameText: string;
  fullItemsResponseByName: any;
  filterByTypeText: string;
  fullItemsResponseByType: any;
  draggedItem: any;
  selItem: any;
  showRightPanel: boolean;
  isCompactMode: boolean;
  language: string;
  searchQuery: string;
  breadcrumbPath: any[];
  customProperties: any[];
  loadingMore: boolean;
}

export class ContactsPage extends React.Component<Props, State> {
  private _isMounted: boolean;
  private _selection: Selection;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();
  private loadingMore:boolean = false;
  private page:number = 1;

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this._selection = new Selection({
      onSelectionChanged: () => {
        this._isMounted && this.setState({
          selItem: this._selection.getSelection()[this._selection.getSelection().length - 1] || []
        });
      }
    });

    this.state = {
      userData: null,
      repoData: null,
      goBack: 0,
      isLoading: true,
      blankLoading: false,
      savingTag: false,
      savingMessage: false,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      items: [],
      showFilters: false,
      fullItemsResponse: [],
      filterByNameText: "",
      fullItemsResponseByName: [],
      filterByTypeText: "",
      fullItemsResponseByType: [],
      draggedItem: null,
      columns: [],
      selItem: null,
      showRightPanel: localStorage.detailsPanelOpen ? localStorage.detailsPanelOpen === 'true' : true,
      isCompactMode: true,
      language: i18n.language,
      searchQuery: document.location.search,
      breadcrumbPath: [{
        text: i18n.t('app:contacts'),
        key: 'contacts',
        onClick: ()=>{history.push('/contacts')}
      }],
      customProperties: [],
      loadingMore: false,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this._getColumns();
    this._isMounted && this.props.userData && this._getRepoData();
    this._isMounted && this.props.userData && this.getCurrentContent();
    if (this.props.pusher && this.props.userData) {
      console.log('Pusher connect: Workflow-'+ this.props.userData.repository.id +"-CONTACT")
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTACT"})
      this.props.pusher.connect()
    }
  }

  public componentDidUpdate(prevProps: any) {
    if(this.state.language !== i18n.language) {
      this.setState({language: i18n.language});
    }
    if (prevProps.pusher !== this.props.pusher) {
      if (this.props.pusher && this.props.userData) {
        console.log('Pusher connect: Workflow-'+ this.props.userData.repository.id +"-CONTACT")
        this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTACT"})
        this.props.pusher.connect()
      }
    }

    if(this.props.userData !== prevProps.userData 
      || (this.props.page !== prevProps.page && (this.props.page === "contactsExplorer" || this.props.page === "contactsExplorerTrash"))
      || this.state.searchQuery !== document.location.search ) {
      this.props.userData && this._getRepoData();
      this.getCurrentContent();
    }

    if (prevProps.pusherMessage !== this.props.pusherMessage) {
      const { pusherMessage } = this.props;
      if(pusherMessage && pusherMessage.message) {
        console.log(pusherMessage)
        /*if (pusherMessage.message.itemType === 'CONTACT') {
          if (pusherMessage.message.action === 'DELETE') {
            let items = this.state.items;
            items = items.filter((item)=>{return item.id !== pusherMessage.message.itemId})
            this._isMounted && this.setState({items: items})
          } else if (pusherMessage.message.action === 'UPDATE') {
            this.getCurrentContent(true);
          }
          else if (pusherMessage.message.action === 'CREATE') {
            this.getCurrentContent(true);
          }
        }*/
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    if (this.props.pusher) {
      this.props.pusher.disconnect();
    }
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private filterByRelations(items) {
    const params = new URLSearchParams(document.location.search);
    let project = params.get('projectIds') || ''
    let contract = params.get('contractIds') || ''
    let company = params.get('companyIds') || ''
    let newItems = items;
    if (project === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.projectIds) {
          if (item.projectIds[i]) return true
        }
        return false
      })
    } else if (project === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.projectIds || item.projectIds.length === 0) return true
        return false
      })
    } else if (project && project.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.projectIds) {
          if (project.includes(item.projectIds[i])) return true
        }
        return false
      })
    }
    if (contract === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.contractIds) {
          if (item.contractIds[i]) return true
        }
        return false
      })
    } else if (contract === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.contractIds || item.contractIds.length === 0) return true
        return false
      })
    } else if (contract && contract.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.contractIds) {
          if (contract.includes(item.contractIds[i])) return true
        }
        return false
      })
    }
    if (company === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.companyIds) {
          if (item.companyIds[i]) return true
        }
        return false
      })
    } else if (company === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.companyIds || item.companyIds.length === 0) return true
        return false
      })
    } else if (company && company.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.companyIds) {
          if (company.includes(item.companyIds[i])) return true
        }
        return false
      })
    }
    return newItems;
  }

  private getCurrentContent(hidden = false) {
    this.page = 0;
    this._isMounted && this.setState({
      isLoading: true,
      items: hidden ? this.state.items : [],
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      },
      searchQuery: document.location.search
    })
    const params = new URLSearchParams(document.location.search);
    let contactType = params.get('contactType')

    let newItems: any[] = [];
    let query = document.location.search;
    const getContacts = query && query.length > 0 ?
    //When query is present we do a paginated search
    userService.searchContacts(query).then((response)=>{
      newItems = response.data.content.map((item:any)=>{return {...item, key: item.id}});
      newItems = response.data.last ? newItems :[...newItems, null];
      //newItems = this.filterByRelations(newItems);
    }).catch(()=>{
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      });
    })
    : 
    //When there is no query we get all entities
    userService.getContacts().then((response)=>{
      newItems = _copyAndSort(response.data.filter((item)=>{return !item.trash}), 'firstName', false);
      //newItems = this.filterByRelations(newItems);
    }).catch(()=>{
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      });
    })

    let customProperties: any[] = [];
    const getCustomProperties = userService.getCustomProperties('','CONTACT').then((res) =>{
      customProperties = res.data.map(item=>{return {...item, id: item.id, name: item.name, position: item.customPropertyTypes.find(x=>{return x.entityType === 'CONTACT'}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === 'CONTACT'}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}});
      customProperties = customProperties.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
    }).catch(()=>{
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      });
    })

    Promise.all([getContacts, getCustomProperties]).then((res) => {
      this._isMounted && this.setState({
        items: newItems.map((item:any)=>{return item ? {...item, key: item.id, name: item.firstName + " " + item.lastName} : null}),
        customProperties: customProperties,
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      },()=>{
        this._isMounted && this.setState({
          selItem: this._selection.getSelection()[this._selection.getSelection().length - 1] || null
        });
      });
      this._sortBySavedColumn(newItems);
    })
  }

  private getMoreContent() {

    const { loadingMore } = this.state;

    if (!loadingMore && !this.loadingMore) {
      this.loadingMore = true;
      setTimeout(()=>{
        this._isMounted && this.setState({
          loadingMore: true,
        })
        if (document.location.search && document.location.search.length > 0) {
          this.page += 1;
          userService.searchContacts(document.location.search + '&page=' + this.page).then(async (response:any)=>{
            let oldItems = this.state.items;
            oldItems = oldItems.filter((item)=> {return item});
            var newItems:any = oldItems.concat(response.data.content)
            newItems = response.data.last ? newItems :[...newItems, null];
            this.loadingMore = false;
            this._isMounted && this.setState({
              items: newItems.map((item:any)=>{return item ? {...item, key: item.id, name: item.firstName + " " + item.lastName} : null }),
              isLoading: false,
              loadingMore: false,
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            });
          }).catch(()=>{
            this.loadingMore = false;
            this._isMounted && this.setState({
              loadingMore: false,
            });
          })
        } else {
          this.loadingMore = false;
          this._isMounted && this.setState({
            loadingMore: false,
          });
        }
      },50)
    }
  }

  public render() {

    const { columns, items, customProperties, isCompactMode, searchQuery, showRightPanel } = this.state;

    return (
      <div>
            <Header
              ref={instance => { this.headerRef = instance; }}
              userData={ this.props.userData }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              page={this.props.page}
              sidebarRef={ this.sidebarRef }
              syncStatus = {this.state.syncStatus}
              items={items}
              callbackFunction={this.callbackFunction}
              showRightPanel={showRightPanel}
              breadcrumbPath={this.state.breadcrumbPath}
              selItem={ this.state.selItem }
              getCurrentContent={this.getCurrentContent.bind(this)}
              import={_importContacts}
            />
          <div className="content-wrap d-flex flex-row" >
            <Sidebar
              ref={(instance:any) => { this.sidebarRef = instance; }}
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              userData={ this.props.userData }
              page={this.props.page}
              items={items}
              searchQuery={searchQuery}
              callbackFunction={this.callbackFunction}
              customProperties={customProperties}
            /> 

            <div className="list mr-auto flex-grow-1">
              <ListExplorer
                ref={instance => { this.listExplorerRef = instance; }}
                appPlatform={ this.props.appPlatform }
                isOnline={ this.props.isOnline }
                maintenance={ this.props.maintenance }
                socket={this.props.socket}
                pusher={this.props.pusher}
                userData={this.props.userData}
                foldersList={ this.props.foldersList }
                items={items}
                selItem={ this.state.selItem }
                isCompactMode={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                constrainMode={ConstrainMode.unconstrained}
                headerRef={ this.headerRef }
                detailsPanelRef={ this.detailsPanelRef }
                callbackFunction={ this.callbackFunction }
                page={this.props.page}
                sidebarRef={ this.sidebarRef }
                loading={this.state.isLoading}
                searchMore={this.getMoreContent.bind(this)}
                loadingMore={this.state.loadingMore}
              />      
            </div>
            <DetailsPanel
              ref={instance => { this.detailsPanelRef = instance; }}
              appPlatform={ this.props.appPlatform }
              userData={ this.props.userData }
              repoData={ this.state.repoData }
              foldersList={ this.props.foldersList }
              repoUsers={this.props.repoUsers}
              items={ items }
              showRightPanel={ this.state.showRightPanel }
              selection={ this._selection }
              selItem={ this.state.selItem }
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              page={ this.props.page }
              detailsPanelSection="explorer"
              callbackFunction={ this.callbackFunction }
              customProperties={customProperties}
            />
          </div>  
      </div>
    );
  }

  private _getColumns() {
    var columns: any = []
    if (this.props.page === "contactsExplorer" || this.props.page === "contactsExplorerTrash") {
      columns = [
        {
          key: 'icon',
          name: '#',
          fieldName: 'icon',
          minWidth: 1,
          isRowHeader: true,
          data: 'string',
          isPadded: true,
          //onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '28px'}}/>
              :
                <div style={{borderRadius: '24px', height: '28px'}}><Persona className='contact-persona' name={item ? item.firstName + " " + item.lastName : ''} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful" }}/></div>
            )
          }
        },
        {
          key: 'firstName',
          name: i18n.t('app:firstName'),
          fieldName: 'firstName',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'string',
          //isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <span title={ item.firstName }>{ item.firstName }</span>
            )
          }
        },
        {
          key: 'lastName',
          name: i18n.t('app:lastName'),
          fieldName: 'lastName',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <span title={ item.lastName }>{ item.lastName }</span>
            )
          }
        },
        {
          key: 'nickname',
          name: 'Nickname',
          fieldName: 'nickname',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Text title={ item.nickname }>{ item.nickname }</Text>
            )
          }
        },
        {
          key: 'emailAddresses',
          name: i18n.t('app:emailAddress'),
          fieldName: 'emailAddresses',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          //onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            if (item.emailAddresses && item.emailAddresses.length > 0) {
              let email = item.emailAddresses.find(item=>{return item.position === 0}) || item.emailAddresses[0]
              return (
                <Link style={{fontWeight: 400}} href={'mailto:'+email.email} title={ email.email } target='_blank' rel='noreferrer'>{ email.email }</Link> 
              )
            } else {
              return <></>
            }
          }
        },
        {
          key: 'phoneNumbers',
          name: i18n.t('app:phoneNumber'),
          fieldName: 'phoneNumbers',
          minWidth: 120,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          //onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            if (item.phoneNumbers && item.phoneNumbers.length > 0) {
              let phone = item.phoneNumbers.find(item=>{return item.position === 0}) || item.phoneNumbers[0]
              return (
                <Link style={{fontWeight: 400}}  href={'tel:'+phone.number} title={ phone.number } target='_blank' rel='noreferrer'>{ phone.number }</Link> 
              )
            } else {
              return <></>
            }
          }
        },
        {
          key: 'linkedin',
          name: i18n.t('app:linkedIn'),
          fieldName: 'linkedin',
          minWidth: 320,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <Link style={{fontWeight: 400}} href={item.linkedin} title={ item.linkedin } target='_blank' rel='noreferrer'>{ item.linkedin }</Link> 
            )
          }
        },
        {
          key: 'contactType',
          name: i18n.t('app:type'),
          fieldName: 'contactType',
          minWidth: 100,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              <span title={ item.contactType } className={'tag-badge ' + (item.contactType === 'INTERNAL' ? 'internal' : 'external')}>{ i18n.t('app:'+(item.contactType === 'INTERNAL' ? 'INTERNAL' : 'EXTERNAL')) }</span>
            )
          }
        }
      ]
    }
    this._isMounted && this.setState({
      columns: columns
    })
  }

  onDragOver = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragEnter = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    if(this.listExplorerRef
      && !this.listExplorerRef.state.draggedItem) {
        
      setTimeout(() => {
        var el: any = document.getElementsByClassName("ms-ScrollablePane")
        for(var i = 0; i < el.length; i++) {
          if (!el[i].classList.contains("dragOverList")) {
            el[i].classList.add('dragOverList');
          }
        }
      })
    }

    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave = (e) => {
    if (e.target.className.indexOf("ms-ScrollablePane") !== -1) {
      var el: any = document.getElementsByClassName("ms-ScrollablePane")
      for(var i = 0; i < el.length; i++) {
        el[i].classList.remove('dragOverList');
      }
    }

    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onFileDrop = (e) => {
    var el: any = document.getElementsByClassName("ms-ScrollablePane")
    for(var i = 0; i < el.length; i++) {
      el[i].classList.remove('dragOverList');
    }
    
    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();

  }

  private _getRepoData() {
    this._isMounted && this.setState({
      repoData: this.props.userData.repository
    }, () => {
      //this._getCurrentContent();
    })
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    //const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    localStorage.setItem("columnsContacts", JSON.stringify(newColumns))
    //this._isMounted && this.setState({
    //  columns: newColumns,
    //  items: newItems.map((item:any)=>{return item ? {...item, key: item.id, name: item.firstName + " " + item.lastName} : null }),
    //});
    let query = new URLSearchParams(document.location.search);
    query.set('sort',currColumn.fieldName!+','+(currColumn.isSortedDescending ? 'DESC' : 'ASC'))
    history.push('/contacts?'+query.toString())
  };

  private _sortBySavedColumn = (items: any): void => {
    if (typeof(localStorage.getItem("columnsContacts")) === "string") {
      var dashboardColumns: IColumn[] = JSON.parse(localStorage.getItem("columnsContacts") || "[]");

      for (let i = 0; i < this.state.columns.length; i++) {
        let column = this.state.columns[i]

        let savedColumn: any = dashboardColumns.filter(savedColumn => {
          return column.key === savedColumn.key
        })
        savedColumn = savedColumn[0]

        if(column && savedColumn) {
          column.isSorted = savedColumn.isSorted;
          column.isSortedDescending = savedColumn.isSortedDescending

          if(column.isSorted) {
            const { columns } = this.state;
            const newColumns: IColumn[] = columns.slice();
            const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        
            const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
            this._isMounted && this.setState({
              columns: newColumns,
              items: newItems.map((item:any)=>{return {...item, key: item.id, name: item.firstName + " " + item.lastName}}),
            });
          }
        } else { 
          this._isMounted && this.setState({items: items.map((item:any)=>{return {...item, key: item.id, name: item.firstName + " " + item.lastName}})})
        }
      }
    } else {
      this._isMounted && this.setState({items: items.map((item:any)=>{return {...item, key: item.id, name: item.firstName + " " + item.lastName}})})
    }
  };

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean, secondColumnKey?: string): T[] {
  const key = columnKey as keyof any;
  const key2 = secondColumnKey as keyof any;
  if (key === 'amount') {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (!a) return -1;
      if (!b) return 1;
      if (a[key2] < b[key2]) return -1;
      if (a[key2] > b[key2]) return 1;
      else if (parseFloat(a[key]) < parseFloat(b[key])) return isSortedDescending ? 1 : -1;
      else if (parseFloat(a[key]) > parseFloat(b[key])) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? parseFloat(a[key]) < parseFloat(b[key]) : parseFloat(a[key]) > parseFloat(b[key])) ? 1 : -1));
  } else if (key === 'phoneNumbers') {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (!a) return -1;
      if (!b) return 1;
      let a_key = a[key].length > 0 ? (a[key].find(item=>{return item.position === 0}) || a[key][0]).number : ''
      let b_key = b[key].length > 0 ? (b[key].find(item=>{return item.position === 0}) || b[key][0]).number : ''
      if ((a[key2] ? a[key2].toLowerCase() : '') < (b[key2] ? b[key2].toLowerCase() : '')) return -1;
      if ((a[key2] ? a[key2].toLowerCase() : '') > (b[key2] ? b[key2].toLowerCase() : '')) return 1;
      else return (isSortedDescending ? a_key < b_key : a_key > b_key) ? 1 : -1
    })
    else {
      return items.slice(0).sort((a: T, b: T) => {
        if (!a) return -1;
        if (!b) return 1;
        let a_key = a[key].length > 0 ? (a[key].find(item=>{return item.position === 0}) || a[key][0]).number : ''
        let b_key = b[key].length > 0 ? (b[key].find(item=>{return item.position === 0}) || b[key][0]).number : ''
        return (isSortedDescending ? a_key < b_key : a_key > b_key) ? 1 : -1
      })
    }
  } else if (key === 'emailAddresses') {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (!a) return -1;
      if (!b) return 1;
      let a_key = a[key].length > 0 ? (a[key].find(item=>{return item.position === 0}) || a[key][0]).email : ''
      let b_key = b[key].length > 0 ? (b[key].find(item=>{return item.position === 0}) || b[key][0]).email : ''
      if ((a[key2] ? a[key2].toLowerCase() : '') < (b[key2] ? b[key2].toLowerCase() : '')) return -1;
      if ((a[key2] ? a[key2].toLowerCase() : '') > (b[key2] ? b[key2].toLowerCase() : '')) return 1;
      else return (isSortedDescending ? a_key < b_key : a_key > b_key) ? 1 : -1
    })
    else {
      return items.slice(0).sort((a: T, b: T) => {
        if (!a) return -1;
        if (!b) return 1;
        let a_key = a[key].length > 0 ? (a[key].find(item=>{return item.position === 0}) || a[key][0]).email : ''
        let b_key = b[key].length > 0 ? (b[key].find(item=>{return item.position === 0}) || b[key][0]).email : ''
        return (isSortedDescending ? a_key < b_key : a_key > b_key) ? 1 : -1
      })
    }
  } else {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (!a) return -1;
      if (!b) return 1;
      if ((a[key2] ? a[key2].toLowerCase() : '') < (b[key2] ? b[key2].toLowerCase() : '')) return -1;
      if ((a[key2] ? a[key2].toLowerCase() : '') > (b[key2] ? b[key2].toLowerCase() : '')) return 1;
      else if ((a[key] ? a[key].toLowerCase() : '') < (b[key] ? b[key].toLowerCase() : '')) return isSortedDescending ? 1 : -1;
      else if ((a[key] ? a[key].toLowerCase() : '') > (b[key] ? b[key].toLowerCase() : '')) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? ( a && a[key] ? a[key].toLowerCase() : '') < ( b && b[key] ? b[key].toLowerCase() : '') : (a && a[key] ? a[key].toLowerCase() : '') > (b && b[key] ? b[key].toLowerCase() : '')) ? 1 : -1));
  }
}