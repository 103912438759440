import * as React from 'react';
import Moment from 'moment';
import "moment/min/locales";
import { Resizable } from 're-resizable';
import i18n from "i18next";
import { FluentProvider, Link, Persona, SelectTabEvent, Tab, TabList, Tooltip } from '@fluentui/react-components';
import { ActionButton, ConstrainMode, DefaultButton, DetailsListLayoutMode, Dropdown, Icon, IconButton, IDetailsHeaderProps, IRenderFunction, Label, MessageBarType, SelectionMode, ShimmeredDetailsList, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import { CustomFieldListed } from './CustomFieldListed';
import { userService } from '../_services/user.service';
import { history } from '../_helpers';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

export interface Props {
  match: {
    url: string
  }
}

export interface DetailsPanelState {
  repoData: any;
  showRightPanel: boolean;
  panelHeight: any;
  panelWidth: any;
  selItem: any;
  toolbarHeight: number;
  activeTab: string;
  showCurrentCompanies: boolean;
  showCurrentContacts: boolean;
  showCurrentProjects: boolean;
  showCurrentContracts: boolean;
  showDefaultPermissionsEditors: boolean;
  showDefaultPermissionsRestrictedEditors: boolean;
  showDefaultPermissionsViewers: boolean;
  showCustomPermissionsEditors: boolean;
  showCustomPermissionsRestrictedEditors: boolean;
  showCustomPermissionsViewers: boolean;
  currentCompanies: any[];
  currentContacts: any[];
  currentProjects: any[];
  currentContracts: any[];
  sortedAZ: boolean;
  loadingEntities: boolean;
  loadingPermissions: boolean;
  defaultPermissions: any[];
  customPermissions: any[];
  repoGroups: any[];
}

export class DetailsPanel extends React.Component<Props, DetailsPanelState> {
  private _isMounted: boolean;
  private _windowWidth: number;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._windowWidth = window.innerWidth;

    this.props = props

   var panelWidth = "420px"
   if (typeof(localStorage.getItem("detailsPanelPrefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("detailsPanelPrefs") || "[]");
      panelWidth = panelPrefs.width
   }

    this.state = {
      repoData: props.repoData,
      showRightPanel: props.showRightPanel,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      selItem: props.selItem,
      toolbarHeight: 89,
      activeTab: 'info',
      showCurrentCompanies: true,
      showCurrentContacts: true,
      showCurrentProjects: true,
      showCurrentContracts: true,
      showDefaultPermissionsEditors: true,
      showDefaultPermissionsRestrictedEditors: true,
      showDefaultPermissionsViewers: true,
      showCustomPermissionsEditors: true,
      showCustomPermissionsRestrictedEditors: true,
      showCustomPermissionsViewers: true,
      currentCompanies: this.props.item?.companyRelations || [],
      currentContacts: this.props.item?.contactRelations || [],
      currentProjects: this.props.item?.projectRelations || [],
      currentContracts: this.props.item?.contractRelations || [],
      sortedAZ: true,
      loadingEntities: true,
      loadingPermissions: true,
      defaultPermissions: [[],[],[]],
      customPermissions: [[],[],[]],
      repoGroups: []
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this._onWindowResize);
    this.props.selItem && this.getEntity(this.props.selItem.id)
    this.state.activeTab === 'permissions' && this.props.selItem && this.getPermissions(this.props.selItem.id)
  }

  public componentDidUpdate(prevProps, prevState) {
    if(this.props.repoData !== prevProps.repoData) {
      this._isMounted && this.setState({repoData: this.props.repoData})
    }

    if(this.props.selItem?.id !== prevProps.selItem?.id || this.props.selActivity?.id !== prevProps.selActivity?.id) {
      if (((this.props.selItem && this.props.selItem?.id !== prevProps.selItem?.id) || (this.props.selActivity && this.props.selActivity?.id !== prevProps.selActivity?.id)) 
        && !this.props.selItem.id.startsWith('Note-')
        && !this.props.selItem.id.startsWith('Email-')
        && !this.props.selItem.id.startsWith('Conversation-')
        && !this.props.selItem.id.startsWith('Task-')
        ) {
          this._isMounted && this.setState({
          selItem: this.props.selItem,
          currentCompanies: [],
          currentContacts: [],
          currentProjects: [],
          currentContracts: [],
          defaultPermissions: [[],[],[]],
          customPermissions: [[],[],[]],
          loadingPermissions: true,
          loadingEntities: true
        },()=>{
          const id = this.props.selItem?.id
          setTimeout(()=> {
            if (this.props.selItem && this.props.selItem.id === id) {
              this.getEntity(id)
            }
          },500)
        })
      } else {
        this._isMounted && this.setState({
          selItem: this.props.selItem,
          currentCompanies: [],
          currentContacts: [],
          currentProjects: [],
          currentContracts: []
        })
      }
    }

    if(this.props.showRightPanel !== prevProps.showRightPanel) {
      localStorage.setItem('detailsPanelOpen', this.props.showRightPanel)
      this._isMounted && this.props.callbackFunction({showRightPanel: this.props.showRightPanel})
      this._isMounted && this.setState({showRightPanel:  this.props.showRightPanel});
    }

    if (prevState.activeTab !== this.state.activeTab || (prevProps.selItem?.id !== this.props.selItem?.id)) {
      if (this.props.selItem && this.props.selItem.id && (this.props.selItem.id.startsWith('Note-') || this.props.selItem.id.startsWith('Email-') || this.props.selItem.id.startsWith('Conversation-') || this.props.selItem.id.startsWith('Task-'))) {
        if (this.state.activeTab === 'info') this.getRelations();
      }
      if (this.props.selItem && this.props.selItem.id) {
        if (this.state.activeTab === 'fileDetails') {
          if (this.props.selItem.id.startsWith('Note-') || this.props.selItem.id.startsWith('Email-') || this.props.selItem.id.startsWith('Conversation-') || this.props.selItem.id.startsWith('Task-')) {
            this._isMounted && this.setState({activeTab: 'info'})
          } else if (this.props.selItem.id.startsWith('Contract-')) {
            this.getDetails();
          }
        }
      }
      if (this.props.selItem && this.props.selItem.id) {
        if (this.state.activeTab === 'permissions') {
          if (this.props.selItem.id.startsWith('Note-') || this.props.selItem.id.startsWith('Email-') || this.props.selItem.id.startsWith('Conversation-') || this.props.selItem.id.startsWith('Task-')) {
            this._isMounted && this.setState({activeTab: 'info'})
          } else if (this.props.selItem.id.startsWith('ActF-')) {
            this._isMounted && this.setState({activeTab: 'info'})
          } else {
            const id = this.props.selItem?.id
            setTimeout(()=> {
              if (this.props.selItem && this.props.selItem.id === id) {
                this.getPermissions(this.props.selItem.id);
              }
            },500)
          }
        }
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this._onWindowResize, false);
  }

  private _onWindowResize = () => {
    var toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
    this.setState({toolbarHeight: toolbar!.offsetHeight})
    if (window.innerWidth <= 980 && window.innerWidth < this._windowWidth) {
      this._isMounted && this.props.callbackFunction({showRightPanel: false})
      this._isMounted && this.setState({showRightPanel: false});
    }
    this._windowWidth = window.innerWidth;
  }

  private async getRelations() {
    let selItem = this.props.selItem;
    let id = selItem.id + '';
    this._isMounted && this.setState({loadingEntities: true})
    if (this.state.activeTab === 'info') {
      selItem.companyIds?.length > 0 && !selItem.companyRelations && await new Promise((resolve) => {
        userService.getCompanies(selItem.companyIds.join(',')).then((res)=>{
          selItem.companyRelations = res.data.sort((a,b) => {return (a.name || '').localeCompare(b.name || '')});
          resolve(true);
        }).catch((error)=>{
          console.log(error);
          resolve(true);
        })
      })
      selItem.contactIds?.length > 0 && !selItem.contactRelations && await new Promise((resolve) => {
        userService.getContacts(selItem.contactIds.join(',')).then((res)=>{
          selItem.contactRelations = res.data.sort((a,b) => {return ((a.firstName || '') + ' ' + (a.lastName ? ' ' + a.lastName : '')).localeCompare((b.firstName || '') + (b.lastName ? ' ' + b.lastName : ''))});
          resolve(true);
        }).catch((error)=>{
          console.log(error);
          resolve(true);
        })
      })
      selItem.contractIds?.length > 0 && !selItem.contractRelations && await new Promise((resolve) => {
        userService.getContracts(selItem.contractIds.join(',')).then((res)=>{
          selItem.contractRelations = res.data.sort((a,b) => {return (a.name || '').localeCompare(b.name || '')});
          resolve(true);
        }).catch((error)=>{
          console.log(error);
          resolve(true);
        })
      })
      selItem.projectIds?.length > 0 && !selItem.projectRelations && await new Promise((resolve) => {
        userService.getProjects(selItem.projectIds.join(',')).then((res)=>{
          selItem.projectRelations = res.data.sort((a,b) => {return (a.name || '').localeCompare(b.name || '')});
          resolve(true);
        }).catch((error)=>{
          console.log(error);
          resolve(true);
        })
      })
      let items = this.props.items;
      for (let i in items) {
        if(items[i] && items[i].id === id ) {
          items[i] = selItem
        }
      }
      this.props.callbackFunction({items: items})
      this._isMounted && this.setState({
        currentCompanies: this.props.selItem?.companyRelations || [],
        currentContacts: this.props.selItem?.contactRelations || [],
        currentProjects: this.props.selItem?.projectRelations || [],
        currentContracts: this.props.selItem?.contractRelations || [],
        loadingEntities: false
      })
    }
  }

  private async getDetails() {
    let selItem = this.props.selItem;
    let id = selItem.id + '';
    let fileRequests:any[] = [];
    let files:any[] = [];
    if (this.state.activeTab === 'fileDetails' && id.startsWith('Contract-') && selItem && !selItem.files) {
      for (let id in selItem.fileIds) {
        fileRequests.push(
          userService.getDocument(selItem.fileIds[id]).then((res)=>{
            files.push(res.data)
          }).catch((error)=>{
            console.log(error);
          })
        )
      }
    }
    Promise.all(fileRequests).then((res) => {
      let items = this.props.items;
      selItem.files = files;
      for (let i in items) {
        if(items[i].id === id ) {
          items[i] = selItem
        }
      }
      this.props.selItem && this.props.selItem.id === id && this.props.callbackFunction({items: items})
    })
  }

  private removeFrom(item: any, type: string) {
    if (type === 'Project') this.setState({currentProjects: this.state.currentProjects.filter((i)=>{return i.id !== item.id})})
    else if (type === 'Company') this.setState({currentCompanies: this.state.currentCompanies.filter((i)=>{return i.id !== item.id})})
    else if (type === 'Contact') this.setState({currentContacts: this.state.currentContacts.filter((i)=>{return i.id !== item.id})})
    else if (type === 'Contract') this.setState({currentContracts: this.state.currentContracts.filter((i)=>{return i.id !== item.id})})
  }

  private toggleAll() {
    if (this.state.showCurrentCompanies && this.state.showCurrentContacts && this.state.showCurrentProjects) {
      this._isMounted && this.setState({showCurrentCompanies: false, showCurrentContacts: false, showCurrentProjects: false, showCurrentContracts: false, showCustomPermissionsEditors: false, showCustomPermissionsRestrictedEditors: false, showCustomPermissionsViewers: false, showDefaultPermissionsEditors: false, showDefaultPermissionsRestrictedEditors: false, showDefaultPermissionsViewers: false})
    } else {
      this._isMounted && this.setState({showCurrentCompanies: true, showCurrentContacts: true, showCurrentProjects: true, showCurrentContracts: true, showCustomPermissionsEditors: true, showCustomPermissionsRestrictedEditors: true, showCustomPermissionsViewers: true, showDefaultPermissionsEditors: true, showDefaultPermissionsRestrictedEditors: true, showDefaultPermissionsViewers: true})
    }
  }

  private toggleSort() {
    this._isMounted && this.setState({
      sortedAZ: !this.state.sortedAZ,
      currentCompanies: this.sortItems(this.state.currentCompanies, !this.state.sortedAZ),
      currentContacts: this.sortItems(this.state.currentContacts, !this.state.sortedAZ),
      currentProjects: this.sortItems(this.state.currentProjects, !this.state.sortedAZ),
      currentContracts: this.sortItems(this.state.currentContracts, !this.state.sortedAZ),
    })
  }

  private sortItems(items, asc:boolean) {
    if (asc) {
      return items.slice(0).sort((a: any, b: any) => {
        if (a.name < b.name || (a.firstName + ' ' + a.lastName) < (b.firstName + ' ' + b.lastName)) return -1;
        if (a.name > b.name || (a.firstName + ' ' + a.lastName) > (b.firstName + ' ' + b.lastName)) return 1;
        else return 0;
      })
    } else {
      return items.slice(0).sort((a: any, b: any) => {
        if (a.name < b.name || (a.firstName + ' ' + a.lastName) < (b.firstName + ' ' + b.lastName)) return 1;
        if (a.name > b.name || (a.firstName + ' ' + a.lastName) > (b.firstName + ' ' + b.lastName)) return -1;
        else return 0;
      })
    }
  }
  
  private getEntity(id:string = '') {
    if (id.startsWith('Project-')) {
      userService.getProject(id).then((response)=> {
        if (this.props.selItem && id === this.props.selItem.id) {
          this._isMounted && this.setState({loadingEntities: false, selItem: response.data, currentCompanies: response.data.companies, currentContacts: response.data.contacts, currentContracts: response.data.contracts})
          if (this.props.selActivity) {
            this.props.callbackFunction({selItem: {...this.props.selActivity, entity: response.data}})
          } else {
            this.props.callbackFunction({selItem: response.data})
          }
        }
      }).catch((error)=> {
        console.log(error)
        this._isMounted && this.setState({loadingEntities: false})
      })
    } else if (id.startsWith('Contract-')) {
      userService.getContract(id).then((response)=> {
        if (this.props.selItem && id === this.props.selItem.id) {
          this._isMounted && this.setState({loadingEntities: false, selItem: response.data, currentCompanies: response.data.companies, currentContacts: response.data.contacts, currentProjects: response.data.projects})
          if (this.props.selActivity) {
            this.props.callbackFunction({selItem: {...this.props.selActivity, entity: response.data}})
          } else {
            this.props.callbackFunction({selItem: response.data})
          }
        }
      }).catch((error)=> {
        console.log(error)
        this._isMounted && this.setState({loadingEntities: false})
      })
    } else if (id.startsWith('Contact-') || id.startsWith('User-') || id.startsWith('U')) {
      userService.getContact(id).then((response)=> {
        if (this.props.selItem && id === this.props.selItem.id) {
          this._isMounted && this.setState({loadingEntities: false, selItem: response.data, currentCompanies: response.data.companies, currentContracts: response.data.contracts, currentProjects: response.data.projects})
          if (this.props.selActivity) {
            this.props.callbackFunction({selItem: {...this.props.selActivity, entity: response.data}})
          } else {
            this.props.callbackFunction({selItem: response.data})
          }
        }
      }).catch((error)=> {
        console.log(error)
        this._isMounted && this.setState({loadingEntities: false})
      })
    } else if (id.startsWith('Company-')) {
      userService.getCompany(id).then((response)=> {
        if (this.props.selItem && id === this.props.selItem.id) {
          this._isMounted && this.setState({loadingEntities: false, selItem: response.data, currentContacts: response.data.contacts, currentContracts: response.data.contracts, currentProjects: response.data.projects})
          if (this.props.selActivity) {
            this.props.callbackFunction({selItem: {...this.props.selActivity, entity: response.data}})
          } else {
            this.props.callbackFunction({selItem: response.data})
          }
        }
      }).catch((error)=> {
        console.log(error)
        this._isMounted && this.setState({loadingEntities: false})
      })
    }
  }

  private getPermissions(id: string = '') {
    this._isMounted && this.setState({loadingPermissions: true})
    let defaultPermissions: any[] = [];
    const getPermissionsDefault = userService.getPermissions().then((response)=>{
      if (this.props.selItem && id === this.props.selItem.id) {
        defaultPermissions = response.data.userPermissions.concat(response.data.groupPermissions).map((item)=>{return {...item, id: item.userId || item.groupId}})
      }
    }).catch((error)=>{
      console.log(error)
      this._isMounted && this.setState({loadingPermissions: false})
    })
    let customPermissions: any[] = [];
    const getPermissionsCustom = userService.getPermissions(id).then((response)=>{
      if (this.props.selItem && id === this.props.selItem.id) {
        customPermissions = response.data.id === this.props.selItem.id ? response.data.userPermissions.concat(response.data.groupPermissions).map((item)=>{return {...item, id: item.userId || item.groupId}}) : []
      }
    }).catch((error)=>{
      console.log(error)
      this._isMounted && this.setState({loadingPermissions: false})
    })
    Promise.all([getPermissionsDefault, getPermissionsCustom]).then((res) => {
      if (defaultPermissions.length > 0 || customPermissions.length > 0) {
        userService.getRepoGroups().then((response)=>{
          const repoGroups = response.data || [];
          let userIds = defaultPermissions.filter((i)=>{return i.userId}).concat(customPermissions.filter((i)=>{return i.userId})).map((i)=>{return i.id})
          userService.getRepoUsers('ADMIN,INTERNAL',userIds.join(',')).then((response)=>{
            const repoUsers = response.data || this.props.possibleContacts || [];
            const editorsDefault = defaultPermissions.filter((item) => {return item.permissionRole === 'EDITOR'}).map((item) => {return item.userId || item.groupId})
            const restrictedEditorsDefault = defaultPermissions.filter((item) => {return item.permissionRole === 'RESTRICTED_EDITOR'}).map((item) => {return item.userId  || item.groupId})
            const viewersDefault = defaultPermissions.filter((item) => {return item.permissionRole === 'VIEWER'}).map((item) => {return item.userId  || item.groupId})
            const editorsUsersDefault = repoUsers.filter((user) => {return editorsDefault.includes(user.id)})
            const restrictedEditorsUsersDefault = repoUsers.filter((user) => {return restrictedEditorsDefault.includes(user.id)})
            const viewersUsersDefault = repoUsers.filter((user) => {return viewersDefault.includes(user.id)})
            const editorsGroupsDefault = repoGroups.filter((user) => {return editorsDefault.includes(user.id)})
            const restrictedEditorsGroupsDefault = repoGroups.filter((user) => {return restrictedEditorsDefault.includes(user.id)})
            const viewersGroupsDefault = repoGroups.filter((user) => {return viewersDefault.includes(user.id)})
      
            const editors = customPermissions.filter((item) => {return item.permissionRole === 'EDITOR'}).map((item) => {return item.userId || item.groupId})
            const restrictedEditors = customPermissions.filter((item) => {return item.permissionRole === 'RESTRICTED_EDITOR'}).map((item) => {return item.userId  || item.groupId})
            const viewers = customPermissions.filter((item) => {return item.permissionRole === 'VIEWER'}).map((item) => {return item.userId  || item.groupId})
            const editorsUsers = repoUsers.filter((user) => {return editors.includes(user.id)})
            const restrictedEditorsUsers = repoUsers.filter((user) => {return restrictedEditors.includes(user.id)})
            const viewersUsers = repoUsers.filter((user) => {return viewers.includes(user.id)})
            const editorsGroups = repoGroups.filter((user) => {return editors.includes(user.id)})
            const restrictedEditorsGroups = repoGroups.filter((user) => {return restrictedEditors.includes(user.id)})
            const viewersGroups = repoGroups.filter((user) => {return viewers.includes(user.id)})
            console.log(editorsUsersDefault)
            this._isMounted && this.setState({loadingPermissions: false, repoGroups: response.data, defaultPermissions: [editorsUsersDefault.concat(editorsGroupsDefault), restrictedEditorsUsersDefault.concat(restrictedEditorsGroupsDefault), viewersUsersDefault.concat(viewersGroupsDefault)], customPermissions: [editorsUsers.concat(editorsGroups), restrictedEditorsUsers.concat(restrictedEditorsGroups), viewersUsers.concat(viewersGroups)]})
          }).catch((error)=>{
            console.log(error)
            this._isMounted && this.setState({loadingPermissions: false})
          })
        }).catch((error)=>{
          console.log(error)
          this._isMounted && this.setState({loadingPermissions: false})
        })
      } else {
        this._isMounted && this.setState({loadingPermissions: false})
      }
    })
  }

  private getPermission(item) {
    if (item.visibility === 'PUBLIC') {
      return true;
    } else if (item.visibility === 'PRIVATE') {
      return this.props.userData
    } else if (item.visibility === 'INHERIT') {
      return true;
    }
  }

  private sendAlertMessage(alertMessageText, alertMessageType) {
    if (this.props.listExplorerRef && this.props.listExplorerRef.state) {
      this.props.listExplorerRef.setState({
        alertMessage: {
          type: alertMessageType,
          text: alertMessageText
        }
      })
      setTimeout(() => {
        this.props.listExplorerRef && this.props.listExplorerRef.setState({ alertMessage: null })
      }, 4000)
    } else {
      this.props.callbackFunction({
        alertMessage: {
          type: alertMessageType,
          text: alertMessageText
        }
      })
      setTimeout(() => {
        this.props.callbackFunction({ alertMessage: null })
      }, 4000)
    }
  }

  private updatePermission(item, value) {
    if (item.id.startsWith('Note-')) {
      userService.updateNoteVisibility(item.id, value).then(()=>{
        item.visibility = value;
        if (this.state.selItem && this.state.selItem.id === item.id && this._isMounted) this.setState({selItem: item})
        this.sendAlertMessage(i18n.t('app:permissionUpdated'), MessageBarType.success)
      }).catch((error)=>{
        console.log(error)
      })
    } else if (item.id.startsWith('Email-')) {
      userService.updateEmailVisibility(item.id, value).then(()=>{
        item.visibility = value;
        if (this.state.selItem && this.state.selItem.id === item.id && this._isMounted) this.setState({selItem: item})
        this.sendAlertMessage(i18n.t('app:permissionUpdated'), MessageBarType.success)
      }).catch((error)=>{
        console.log(error)
      })
    } else if (item.id.startsWith('Conversation-')) {
      userService.updateConversationVisibility(item.id, value).then(()=>{
        item.visibility = value;
        if (this.state.selItem && this.state.selItem.id === item.id && this._isMounted) this.setState({selItem: item})
        this.sendAlertMessage(i18n.t('app:permissionUpdated'), MessageBarType.success)
      }).catch((error)=>{
        console.log(error)
      })
    } else if (item.id.startsWith('Task-')) {
      userService.updateTaskVisibility(item.id, value).then(()=>{
        item.visibility = value;
        if (this.state.selItem && this.state.selItem.id === item.id && this._isMounted) this.setState({selItem: item})
        this.sendAlertMessage(i18n.t('app:permissionUpdated'), MessageBarType.success)
      }).catch((error)=>{
        console.log(error)
      })
    }
  }

  public render() {
    const { showRightPanel, selItem, activeTab } = this.state;
    
    const columnsCompanies:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentCompanies: !this.state.showCurrentCompanies})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentCompanies ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:companies') + ' (' + this.state.currentCompanies.length + ')' }</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}} onClick={()=>{history.push('/companies/'+item.id)}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}} onClick={()=>{history.push('/companies/'+item.id)}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}} onClick={()=>{history.push('/companies/'+item.id)}}>{item.name}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><Link target='_blank' rel="noreferrer noopener" href={'https://'+item.domain}>{item.domain}</Link></p>
            </div>
          </div>
        </>)
      }
    }]
  
    const columnsContacts:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentContacts: !this.state.showCurrentContacts})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentContacts ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:contacts') + ' (' + this.state.currentContacts.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}} onClick={()=>{history.push('/contacts/'+item.id)}}>{item.firstName + ' ' + item.lastName}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><Link target='_blank' rel="noreferrer noopener" href={'mailto:'+(item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email)}>{item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email}</Link></p>
            </div>
          </div>
        </>)
      }
    }]
  
    const columnsProjects:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentProjects: !this.state.showCurrentProjects})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentProjects ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:projects') + ' (' + this.state.currentProjects.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
          <div className='d-flex align-items-center'>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}} onClick={()=>{history.push('/projects/'+item.id)}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}} onClick={()=>{history.push('/projects/'+item.id)}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}} onClick={()=>{history.push('/projects/'+item.id)}}>{item.name}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><Link target='_blank' href={'/projects/'+item.id}>Link</Link></p>
            </div>
            </div>
          </div>
        </>)
      }
    }]

    const columnsContracts:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentContracts: !this.state.showCurrentContracts})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentContracts ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:contracts') + ' (' + this.state.currentContracts.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
          <div className='d-flex align-items-center'>
          <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}} onClick={()=>{history.push('/contracts/'+item.id)}}/>
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}} onClick={()=>{history.push('/contracts/'+item.id)}}>{item.name}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><Link target='_blank' href={'/contracts/'+item.id}>Link</Link></p>
            </div>
            </div>
          </div>
        </>)
      }
    }]

    const columnsDefaultPermissionsEditors:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showDefaultPermissionsEditors: !this.state.showDefaultPermissionsEditors})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showDefaultPermissionsEditors ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:editors') + ' (' + this.state.defaultPermissions[0].length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={((item.name || '') + ' ' + (item.surname || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}} onClick={()=>{history.push('/contacts/'+item.id)}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><Link target='_blank' rel="noreferrer noopener" href={'mailto:'+(item.email)}>{item.email}</Link></p>
            </div>
          </div>
        </>)
      }
    }]

    const columnsDefaultPermissionsRestrictedEditors:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showDefaultPermissionsRestrictedEditors: !this.state.showDefaultPermissionsRestrictedEditors})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showDefaultPermissionsRestrictedEditors ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:restrictedEditors') + ' (' + this.state.defaultPermissions[1].length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={((item.name || '') + ' ' + (item.surname || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}} onClick={()=>{history.push('/contacts/'+item.id)}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><Link target='_blank' rel="noreferrer noopener" href={'mailto:'+(item.email)}>{item.email}</Link></p>
            </div>
          </div>
        </>)
      }
    }]

    const columnsDefaultPermissionsViewers:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showDefaultPermissionsViewers: !this.state.showDefaultPermissionsViewers})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showDefaultPermissionsViewers ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:viewers') + ' (' + this.state.defaultPermissions[2].length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={((item.name || '') + ' ' + (item.surname || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}} onClick={()=>{history.push('/contacts/'+item.id)}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><Link target='_blank' rel="noreferrer noopener" href={'mailto:'+(item.email)}>{item.email}</Link></p>
            </div>
          </div>
        </>)
      }
    }]

    const columnsCustomPermissionsEditors:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCustomPermissionsEditors: !this.state.showCustomPermissionsEditors})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCustomPermissionsEditors ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:editors') + ' (' + this.state.customPermissions[0].length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={((item.name || '') + ' ' + (item.surname || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}} onClick={()=>{history.push('/contacts/'+item.id)}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><Link target='_blank' rel="noreferrer noopener" href={'mailto:'+(item.email)}>{item.email}</Link></p>
            </div>
          </div>
        </>)
      }
    }]

    const columnsCustomPermissionsRestrictedEditors:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCustomPermissionsRestrictedEditors: !this.state.showCustomPermissionsRestrictedEditors})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCustomPermissionsRestrictedEditors ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:restrictedEditors') + ' (' + this.state.customPermissions[1].length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={((item.name || '') + ' ' + (item.surname || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}} onClick={()=>{history.push('/contacts/'+item.id)}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><Link target='_blank' rel="noreferrer noopener" href={'mailto:'+(item.email)}>{item.email}</Link></p>
            </div>
          </div>
        </>)
      }
    }]

    const columnsCustomPermissionsViewers:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCustomPermissionsViewers: !this.state.showCustomPermissionsViewers})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCustomPermissionsViewers ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:viewers') + ' (' + this.state.customPermissions[2].length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={((item.name || '') + ' ' + (item.surname || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}} onClick={()=>{history.push('/contacts/'+item.id)}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><Link target='_blank' rel="noreferrer noopener" href={'mailto:'+(item.email)}>{item.email}</Link></p>
            </div>
          </div>
        </>)
      }
    }]

    const onRenderDetailsHeaderCompanies = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center'>
          {defaultRender!({
            ...props
          })}
        </div>
      );
    }

    const onRenderDetailsHeaderContacts = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center'>
          {defaultRender!({
            ...props
          })}
        </div>
      );
    }

    const onRenderDetailsHeaderProjects =(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center' style={{backgroundColor: 'white'}}>
          {defaultRender!({
            ...props
          })}
        </div>
      );
    }

    const onRenderDetailsHeaderContracts =(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center' style={{backgroundColor: 'white'}}>
          {defaultRender!({
            ...props
          })}
        </div>
      );
    }

    if (selItem) {
      for (let i in selItem.customPropertyValues) {
        selItem.customPropertyValues[i].position = this.props.customProperties.find(x=>{return x.id === selItem.customPropertyValues[i].customPropertyId})?.position  
      }
    }

    let assigned: any[] = [];

    if (selItem && selItem.assignedUsers && this.props.possibleContacts) {
      assigned = this.props.possibleContacts.filter((user) => {
        return selItem.assignedUsers && selItem.assignedUsers.includes(user.id) 
      })
    }

    return(
      <div className='d-flex'>
      {showRightPanel &&
        <Resizable
          size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
          onResizeStop={(e, direction, ref, d) => {
            var currentWidth = this.state.panelWidth.match(/\d+/)[0];
            this.setState({
              panelWidth: +currentWidth + +d.width + "px"
            }, () => {
              this._saveResizeState();
            });
          }}
          enable={{ top:false, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
          minWidth={320}
          maxWidth={440}
          handleStyles= {{
            left: {
              "left": "0px",
              "width": "6px"
            }
          }}
          handleClasses={{"left": "resize-handle"}}
        >
          <div className="details-panel pt-0 pb-3 px-3">
          { (selItem !== null && this.props.listExplorerRef && this.props.listExplorerRef._selection && this.props.listExplorerRef._selection.getSelectedCount() === 1 && !selItem.id.startsWith('ActF-')) 
            || (this.props.detailsPanelSection === "explorer" && selItem && !selItem.id.startsWith('ActF-') && this.props.listExplorerRef && this.props.listExplorerRef._selection && this.props.listExplorerRef._selection.getSelectedCount() === 0)
            || ((this.props.page.includes("Data") || this.props.page === "email" || this.props.page === "note" || this.props.page === "conversation" || this.props.page === "task") && selItem && !selItem.id.startsWith('ActF-')) ?
              <div>
                <div
                  aria-labelledby={this._getTabId(this.state.activeTab)}
                  role="tabpanel" className="position-relative"
                >
                  { activeTab === "details" ?
                    <div>
                      <div className="text-left sticky-top" style={{background: '#f2f2f2', borderBottom: '1px solid #bfbfbf', padding: '11px 0px'}}>
                        <p className="lead font-weight-bold m-0">{i18n.t('app:details')}</p>
                      </div>
                      <div className="d-flex my-3">
                        <div className='d-flex'>
                          { (selItem.id).startsWith("Email-") ?
                            <Icon {...getFileTypeIconProps({ extension: '.eml', size: 48, imageFileType: 'svg' }) } style={{minWidth: '36px', minHeight: '36px', width: '36px', height: '36px'}}/>
                            : (selItem.id).startsWith("Conversation-") ?
                            <img alt='CONVERSATION' src={process.env.PUBLIC_URL + '/icons/conversations.svg'} style={{transform: 'scale(1, 1.3)', width: '36px', height: '36px'}}/>
                            : (selItem.id).startsWith("Note-") ?
                            <img alt='Note' src={process.env.PUBLIC_URL + '/icons/documents.svg'} style={{width: '36px', height: '36px'}}/>
                            : (selItem.id).startsWith("Task-") ?
                            <img alt='Task' src={process.env.PUBLIC_URL + '/icons/tasks.svg'} style={{width: '36px', height: '36px'}}/>
                            : selItem && selItem.image ?
                            <img src={'data:image/png;base64,'+selItem.image} alt='Logo' style={{width: '36px', height: '36px', borderRadius: selItem.id.startsWith('Project-') ? '2px' : selItem.id.startsWith('Company-') ? '0' : '24px'}}/>
                            :
                            <Persona className={selItem.id.startsWith('Project-') || selItem.id.startsWith('Contract-') ? 'project-persona' : selItem.id.startsWith('Company-') ? 'company-persona' : ''} name={selItem.name || selItem.subject || ((selItem.firstName || '') + ' ' + (selItem.lastName || ''))} size='large' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
                          }
                          <div className="file-name ml-3">
                            <div className="lead d-inline-block font-weight-bold">
                              { selItem.name ||  selItem.subject || ((selItem.firstName || '') + ' ' + (selItem.lastName || '')) }
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <ul className="p-0 my-3">
                        { selItem.stage && selItem.stage.name && <li className="mb-2">
                          {this.getStagesArrows(selItem)}
                        </li>}
                        { selItem.id && <li className="mb-2 d-flex align-items-center">
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:ID')}:</div>
                            <div className="username font-weight-bold">{ selItem.id || "" }</div>
                          </div>
                        </li>}
                        { selItem.domain && <li className="mb-2 d-flex align-items-center">
                          <Icon className='mr-3 field-icon' iconName='Website'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:domain')}:</div>
                            <div className="field-value">{ selItem.domain || "" }</div>
                          </div>
                        </li>}
                        { selItem.phoneNumbers && selItem.phoneNumbers.length > 0 && <li className="mb-2">
                          {selItem.phoneNumbers.sort((a,b) => { return (a.position >= b.position ? 1 : -1)}).map(item => {
                            return (
                              <div key={item.id} className='d-flex align-items-center mb-2'>
                                <Icon className='mr-3 field-icon' iconName='Phone'/>
                                <div>
                                  <div className="text-secondary mr-2">{ i18n.t('app:'+item.type) || "" } </div>
                                  <Link className='field-value' href={'tel:'+item.number} title={ item.number } target='_blank' rel='noreferrer'>{ item.number }</Link>
                                </div>
                              </div>
                            )
                          })}
                        </li>}
                        { selItem.nickname && <li className="mb-2 d-flex align-items-center">
                          <Icon className='mr-3 field-icon' iconName='TextField'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:nickName')}:</div>
                            <div className="field-value">{ selItem.nickname || "" }</div>
                          </div>
                        </li>}
                        { selItem.status && <li className="mb-2 d-flex align-items-center">
                          <Icon className='mr-3 field-icon' iconName='TextField'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:status')}:</div>
                            <div className="field-value">{ i18n.t('app:'+selItem.status) || "" }</div>
                          </div>
                        </li>}
                        { selItem.emailAddresses && selItem.emailAddresses.length > 0 && <li className="mb-2">
                          {selItem.emailAddresses.sort((a,b) => { return (a.position >= b.position ? 1 : -1)}).map(item => {
                            return (
                              <div key={item.id} className='d-flex align-items-center mb-2'>
                                <Icon className='mr-3 field-icon' iconName='Mail'/>
                                <div>
                                  <div className="text-secondary mr-2">{ item.name ? item.name + ': ' : i18n.t('app:email') } </div>
                                  <Link className='field-value' href={'mailto:'+item.email} title={ item.email } target='_blank' rel='noreferrer'>{ item.email }</Link>
                                </div>
                              </div>
                            )
                          })}   
                        </li>}
                        { selItem.linkedin && <li className="mb-2 d-flex align-items-center">
                          <Icon className='mr-3 field-icon' iconName='LinkedInLogo'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:linkedIn')}:</div>
                            <Link className='field-value' href={selItem.linkedin} title={selItem.linkedin} target='_blank' rel='noreferrer'>{selItem.linkedin}</Link>
                          </div>
                        </li>}
                        { selItem.urls && selItem.urls.length > 0 && <li className="mb-2">
                          {selItem.urls.sort((a,b) => { return (a.position >= b.position ? 1 : -1)}).map(item => {
                            return (
                              <div key={item.id} className='d-flex align-items-center mb-2'>
                                <Icon className='mr-3 field-icon' iconName='Link'/>
                                <div>
                                  <div className="text-secondary mr-2">{ item.name + ': ' || "" } </div>
                                  <Link className='field-value' href={item.url} title={ item.url } target='_blank' rel='noreferrer'>{ item.url }</Link>
                                </div>
                              </div>
                            )
                          })}   
                        </li>}
                        { selItem.jobTitle && <li className="mb-2 d-flex align-items-center">
                          <Icon className='mr-3 field-icon' iconName='TextField'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:jobTitle')}:</div>
                            <div className="field-value">{ selItem.jobTitle || "" }</div>
                          </div>
                        </li>}
                        { selItem.signatureDate && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Calendar'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:signatureDate')}:</div>
                            <div className="field-value">{ Moment(selItem.signatureDate).format(i18n.t('Y-MMM-DD')) || "" }</div>
                          </div>
                        </li>}
                        { selItem.startDate && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Calendar'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:startDate')}:</div>
                            <div className="field-value">{ Moment(selItem.startDate).format(i18n.t('Y-MMM-DD'))|| "" }</div>
                          </div>
                        </li>}
                        { selItem.expirationDate && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Calendar'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:expirationDate')}:</div>
                            <div className="field-value">{ Moment(selItem.expirationDate).format(i18n.t('Y-MMM-DD')) || "" }</div>
                          </div>
                        </li>}
                        { selItem.taskImportance && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='IconSetsFlag'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:importance')}:</div>
                            <div className="field-value">{ this.props.getImportance(selItem) }</div>
                          </div>
                        </li>}
                        { selItem.description && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='QuickNote'/>
                          <div>
                            <div className="text-secondary mr-2">{selItem.id && selItem.id.startsWith('Task-') ? i18n.t('app:description') : i18n.t('app:notes')}:</div>
                            <div className="field-value">{ selItem.description || "" }</div>
                          </div>
                        </li>}
                        { selItem.dueDate && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Calendar'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:dueDate')}:</div>
                            <div className="field-value">{ Moment(selItem.dueDate).format(i18n.t('Y-MMM-DD HH:mm')) || "" }</div>
                          </div>
                        </li>}
                        { selItem.reminders && selItem.reminders.length > 0 && selItem.reminders.map((reminder, index)=>{
                          return <li className="mb-2 d-flex" key={reminder + index}>
                            <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Clock'/>
                            <div>
                              <div className="text-secondary mr-2">{i18n.t('app:reminder') + ' ' + (index+1)}:</div>
                              <div className="field-value">{ Moment(reminder).format(i18n.t('Y-MMM-DD HH:mm')) || "" }</div>
                            </div>
                          </li>
                        })}
                        { selItem.assignedUsers && selItem.assignedUsers.length > 0 && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Contact'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:assignedTo')}:</div>
                            <div className="field-value">
                              {assigned.map((item) => (
                                <>{this.getPersona(item.id)}</>
                              ))}
                            </div>
                          </div>
                        </li>}
                        { selItem.customPropertyValues && selItem.customPropertyValues.length > 0 && <li className="mb-2">
                          {selItem.customPropertyValues.sort((a,b) => { return (a.position >= b.position ? 1 : -1)}).map(item => {
                            return (
                              <div key={item.id}>
                                <CustomFieldListed item={item} possibleValues={item.possibleValues} field={this.props.customProperties.find(x=>{return x.id === item.customPropertyId})}/>
                              </div>
                            )
                          })}   
                        </li>}
                        { selItem.noteType && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName={selItem.noteType === 'PLAIN' ? 'QuickNote' : selItem.noteType === 'SMS' ? 'OfficeChat' : selItem.noteType === 'LINKEDIN' ? 'LinkedInLogo' : selItem.noteType === 'WHATSAPP' ? 'Phone' : selItem.noteType === 'CALL' ? 'Phone' : selItem.noteType === 'EMAIL' ? 'Mail' : ''}/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:type')}</div>
                            <span className='field-value' style={{fontSize:12, color: '#424242'}}>{selItem.noteType === 'PLAIN' ? 'Standard note' : selItem.noteType === 'SMS' ? 'SMS' : selItem.noteType === 'LINKEDIN' ? 'LinkedIn' : selItem.noteType === 'WHATSAPP' ? 'Whatsapp' : selItem.noteType === 'CALL' ? 'Call' : selItem.noteType === 'EMAIL' ? 'Email' : ''}</span>
                          </div>
                        </li>}
                        { selItem.conversationType && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName={selItem.conversationType === 'GENERAL' ? 'Chat' : selItem.conversationType === 'CONVERSATION' ? 'OfficeChat' : selItem.conversationType === 'ANNOUNCEMENT' ? 'Megaphone' : selItem.conversationType === 'APPROVAL' ? 'DocumentApproval' : selItem.conversationTypee === 'REVIEW' ? 'CheckboxComposite' : selItem.conversationType === 'MEETING' ? 'JoinOnlineMeeting' : ''}/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:type')}</div>
                              <span className='field-value' style={{fontSize:12, color: '#424242'}}>{selItem.conversationType === 'GENERAL' ? 'General' : selItem.conversationType === 'CONVERSATION' ? 'Conversation' : selItem.conversationType === 'ANNOUNCEMENT' ? 'Announcement' : selItem.conversationType === 'APPROVAL' ? 'Approval' : selItem.conversationType === 'REVIEW' ? 'Review' : selItem.conversationType === 'MEETING' ? 'Meeting' : ''}</span>
                            </div>
                        </li>}
                        { selItem.createdDateTime && (selItem.id && !selItem.id.startsWith('Email-')) && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Calendar'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:createdAt')}:</div>
                            <div className="field-value">{ Moment(selItem.createdDateTime).format(i18n.t('Y-MMM-DD HH:mm')) || "" }</div>
                          </div>
                        </li>}
                        { selItem.createdByUserId && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Contact'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:createdBy')}:</div>
                            {this.getPersona(selItem.createdByUserId)}
                          </div>
                        </li>}
                        { selItem.modifiedDateTime && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Calendar'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:modifiedAt')}:</div>
                            <div className="field-value">{ Moment(selItem.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm'))  || "" }</div>
                          </div>
                        </li>}
                        { selItem.modifiedByUserId && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Contact'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:modifiedBy')}:</div>
                            {this.getPersona(selItem.modifiedByUserId)}
                          </div>
                        </li>}
                        { selItem.receivedDateTime && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Calendar'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:receivedAt')}:</div>
                            <div className="field-value">{ Moment(selItem.receivedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) || "" }</div>
                          </div>
                        </li>}
                        { selItem.savedDateTime && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Calendar'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:savedAt')}:</div>
                            <div className="field-value">{ Moment(selItem.savedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) || "" }</div>
                          </div>
                        </li>}
                        { selItem.savedByUserId && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Contact'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:savedBy')}:</div>
                            {this.getPersona(selItem.savedByUserId)}
                          </div>
                        </li>}
                        {selItem.from && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Contact'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:from')}:</div>
                            <TooltipHost
                              content= {
                                <div className='m-1'>
                                  <FluentProvider>
                                    <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
                                    <Persona className='mb-2' name={selItem.from.emailAddress.name} secondaryText={selItem.from.emailAddress.address} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
                                  </FluentProvider>
                                </div>
                              }
                            >
                              <Persona className='pr-2' size='extra-small' name={selItem.from.emailAddress.name} textAlignment='center' avatar={{ color: "colorful" }}/>
                            </TooltipHost>
                          </div>
                        </li>}
                        {selItem.toRecipients && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Contact'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:to')}:</div>
                            {selItem.toRecipients.map((item)=>{
                              return (
                                <div key={item.emailAddress}>
                                <TooltipHost
                                  content= {
                                    <div className='m-1'>
                                      <FluentProvider>
                                        <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
                                        <Persona className='mb-2' name={item.emailAddress.name} secondaryText={item.emailAddress.address} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
                                      </FluentProvider>
                                    </div>
                                  }
                                >
                                  <Persona className='pr-2' size='extra-small' name={item.emailAddress.name} textAlignment='center' avatar={{ color: "colorful" }}/>
                                </TooltipHost>
                                </div>
                              )
                            })}
                          </div>
                        </li>}
                        { selItem.ccRecipients && selItem.ccRecipients.length > 0 && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Contact'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:cc')}:</div>
                            {selItem.ccRecipients.map((item)=>{
                              return (
                                <div key={item.emailAddress}>
                                <TooltipHost
                                  content= {
                                    <div className='m-1'>
                                      <FluentProvider>
                                        <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
                                        <Persona className='mb-2' name={item.emailAddress.name} secondaryText={item.emailAddress.address} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
                                      </FluentProvider>
                                    </div>
                                  }
                                >
                                  <Persona className='pr-2' size='extra-small' name={item.emailAddress.name} textAlignment='center' avatar={{ color: "colorful" }}/>
                                </TooltipHost>
                                </div>
                              )
                            })}
                          </div>
                        </li> }
                        { selItem.bccRecipients && selItem.bccRecipients.length > 0 && <li className="mb-2 d-flex">
                          <Icon style={{marginTop: '10px'}} className='mr-3 field-icon' iconName='Contact'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:bcc')}:</div>
                            {selItem.bccRecipients.map((item)=>{
                              return (
                                <div key={item.emailAddress}>
                                <TooltipHost
                                  content= {
                                    <div className='m-1'>
                                      <FluentProvider>
                                        <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
                                        <Persona className='mb-2' name={item.emailAddress.name} secondaryText={item.emailAddress.address} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
                                      </FluentProvider>
                                    </div>
                                  }
                                >
                                  <Persona className='pr-2' size='extra-small' name={item.emailAddress.name} textAlignment='center' avatar={{ color: "colorful" }}/>
                                </TooltipHost>
                                </div>
                              )
                            })}
                          </div>
                        </li> }
                      </ul>
                    </div>
                  : activeTab === "info" ? 
                    <div>
                    <div className="text-left sticky-top" style={{background: '#f2f2f2', borderBottom: '1px solid #bfbfbf', padding: '11px 0px'}}>
                      <p className="lead font-weight-bold m-0">{i18n.t('app:info')}</p>
                    </div>
                    <div className='mt-2 justify-content-center'>
                      <div className="d-flex my-3">
                        <div className='d-flex'>
                          { (selItem.id).startsWith("Email-") ?
                            <Icon {...getFileTypeIconProps({ extension: '.eml', size: 48, imageFileType: 'svg' }) } style={{minWidth: '36px', minHeight: '36px', width: '36px', height: '36px'}}/>
                            : (selItem.id).startsWith("Conversation-") ?
                            <img alt='CONVERSATION' src={process.env.PUBLIC_URL + '/icons/conversations.svg'} style={{transform: 'scale(1, 1.3)', width: '36px', height: '36px'}}/>
                            : (selItem.id).startsWith("Note-") ?
                            <img alt='Note' src={process.env.PUBLIC_URL + '/icons/documents.svg'} style={{width: '36px', height: '36px'}}/>
                            : (selItem.id).startsWith("Task-") ?
                            <img alt='Task' src={process.env.PUBLIC_URL + '/icons/tasks.svg'} style={{width: '36px', height: '36px'}}/>
                            : selItem && selItem.image ?
                            <img src={'data:image/png;base64,'+selItem.image} alt='Logo' style={{width: '36px', height: '36px', borderRadius: selItem.id.startsWith('Project-') ? '2px' : selItem.id.startsWith('Company-') ? '0' : '24px'}}/>
                            :
                            <Persona className={selItem.id.startsWith('Project-') || selItem.id.startsWith('Contract-') ? 'project-persona' : selItem.id.startsWith('Company-') ? 'company-persona' : ''} name={selItem.name || selItem.subject || ((selItem.firstName || '') + ' ' + (selItem.lastName || ''))} size='large' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
                          }
                          <div className="file-name ml-3">
                            <div className="lead d-inline-block font-weight-bold">
                              { selItem.name || selItem.subject || ((selItem.firstName || '') + ' ' + (selItem.lastName || '')) }
                            </div>
                          </div>
                        </div>
                      </div>
                      { selItem.id && <li className="mb-2 d-flex align-items-center">
                        <div>
                          <div className="text-secondary mr-2">{i18n.t('app:ID')}:</div>
                          <div className="username font-weight-bold">{ selItem.id || "" }</div>
                        </div>
                      </li>}
                      <div className='d-flex link-split-button mt-3'>
                        {selItem.id && <>
                          <DefaultButton                          
                          iconProps={{iconName:'PageLink'}} 
                          title={i18n.t('app:hyperlink')} 
                          text={i18n.t('app:hyperlink')}
                          onClick={()=>{this.props.headerRef._copyPrivateShortcutName([selItem])}}
                          className=""
                        />
                        <DefaultButton                          
                          iconProps={{iconName:'Link'}} 
                          title={i18n.t('app:path')} 
                          text={i18n.t('app:path')}
                          onClick={()=>{this.props.headerRef._copyPrivateShortcut(selItem)}}
                        />
                        </>}
                      </div>
                      <div className='d-flex link-split-button mt-1'>
                        {selItem && selItem.id.startsWith('Company-') ? <DefaultButton
                          iconProps={{iconName:'Edit'}} 
                          title={i18n.t('app:editCompany')} 
                          text={i18n.t('app:editCompany')}
                          onClick={()=>{this.props.headerRef && this.props.headerRef._showEditCompanyForm()}}
                        />
                        :selItem && (selItem.id.startsWith('Contact-') || selItem.id.startsWith('User-') || selItem.id.startsWith('U')) ? <DefaultButton
                          iconProps={{iconName:'Edit'}} 
                          title={i18n.t('app:editContact')} 
                          text={i18n.t('app:editContact')}
                          onClick={()=>{this.props.headerRef && this.props.headerRef._showEditContactForm()}}
                        />
                        :selItem && selItem.id.startsWith('Project-') ? <DefaultButton
                          iconProps={{iconName:'Edit'}} 
                          title={i18n.t('app:editProject')} 
                          text={i18n.t('app:editProject')}
                          onClick={()=>{this.props.headerRef && this.props.headerRef._showEditProjectForm()}}
                        />
                        :selItem && selItem.id.startsWith('Contract-') ? <DefaultButton
                          iconProps={{iconName:'Edit'}} 
                          title={i18n.t('app:editContract')} 
                          text={i18n.t('app:editContract')}
                          onClick={()=>{this.props.headerRef && this.props.headerRef._showEditContractForm()}}
                        />
                        :selItem && selItem.id.startsWith('Note-') ? <DefaultButton
                          iconProps={{iconName:'Edit'}} 
                          title={i18n.t('app:editItem')} 
                          text={i18n.t('app:editItem')}
                          onClick={()=>{this.props.headerRef && this.props.headerRef._showNoteFormEdit()}}
                          disabled={selItem.noPermission}
                        />
                          :selItem && selItem.id.startsWith('Conversation-') ? <DefaultButton
                          iconProps={{iconName:'Edit'}} 
                          title={i18n.t('app:editItem')} 
                          text={i18n.t('app:editItem')}
                          onClick={()=>{this.props.headerRef && this.props.headerRef._showConversationFormEdit()}}
                          disabled={selItem.noPermission}
                        />
                          :selItem && selItem.id.startsWith('Task-') ? <DefaultButton
                          iconProps={{iconName:'Edit'}} 
                          title={i18n.t('app:editItem')} 
                          text={i18n.t('app:editItem')}
                          onClick={()=>{this.props.headerRef && this.props.headerRef._showTaskFormEdit()}}
                          disabled={selItem.noPermission}
                        />
                        : null
                        }
                        {<DefaultButton    
                          iconProps={{iconName:'Relationship'}} 
                          title={i18n.t('app:editRelations')} 
                          text={i18n.t('app:editRelations')}
                          onClick={()=>{this.props.headerRef && this.props.headerRef._showEditRelationsForm()}}
                          disabled={this.state.loadingEntities || selItem.noPermission}
                        />}
                      </div>
                      { selItem && (selItem && selItem.id.startsWith('Note-') || selItem && selItem.id.startsWith('Email-') || selItem && selItem.id.startsWith('Conversation-') || selItem && selItem.id.startsWith('Task-')) ? 
                      <div className='mt-2'>
                        <Dropdown disabled={selItem.noPermission} selectedKey={selItem.visibility} onChange={(e,data)=>{this.updatePermission(selItem, data?.key)}} options={[{key: 'PUBLIC', text: i18n.t('app:publicVisibility')}, {key: 'INHERIT', text: i18n.t('app:inheritVisibility')}, {key: 'PRIVATE', text: i18n.t('app:privateVisibility')}]}/>
                      </div>
                      :
                      <div className='d-flex link-split-button mt-1'>
                        <DefaultButton
                          iconProps={{iconName:'Permissions'}} 
                          title={i18n.t('app:permissions')} 
                          text={i18n.t('app:permissions')}
                          onClick={()=>{this.props.headerRef && this.props.headerRef._showFormPermissions()}}
                        />
                      </div>
                      }
                      <div className='d-flex align-items-center py-2' style={{borderBottom: '1px solid #bfbfbf'}}>
                        <TooltipHost
                          content={i18n.t('app:expand/collapseAll')}
                          calloutProps={{ gapSpace: 0 }}
                          styles={{root: {marginRight: '8px'}}}
                        >
                          <IconButton
                            onClick={()=>this.toggleAll()} 
                            className='expand-icon' 
                            iconProps={{iconName: this.state.showCurrentCompanies && this.state.showCurrentContacts && this.state.showCurrentProjects ? 'CollapseContent' : 'ExploreContent', styles: {root: {fontSize: 18, color: 'rgb(0, 120, 212)'}}}} 
                          />
                        </TooltipHost>
                        <span style={{fontWeight: 600, fontSize: 14}}>{i18n.t('app:relations')}</span>
                        <TooltipHost
                          key={'sort'}
                          content={i18n.t('app:switchOrder')}
                          id={'sort'}
                          calloutProps={{ gapSpace: 0 }}
                          styles={{root: {marginLeft: 'auto'}}}
                        >
                          <ActionButton
                            onClick={()=>this.toggleSort()} 
                            className='expand-icon'
                            text={i18n.t('app:ByName')}
                            iconProps={{iconName: this.state.sortedAZ ? 'SortDown' : 'SortUp'}}
                            style={{height: '32px'}} 
                          />
                        </TooltipHost>
                      </div>
                      <div className='relation-list panel'>
                        {selItem && !selItem.id.startsWith('Project-') && <>
                        <ShimmeredDetailsList
                          className={this.state.currentProjects.length === 0 || !this.state.showCurrentProjects ? 'mt-3 height-fix' : 'mt-3'}
                          items={this.state.showCurrentProjects ? this.state.currentProjects : []}
                          columns={columnsProjects}
                          onRenderDetailsHeader={onRenderDetailsHeaderProjects}
                          selectionMode={SelectionMode.none}
                          selectionPreservedOnEmptyClick={true}
                          setKey="set"
                          layoutMode={DetailsListLayoutMode.justified}
                          isHeaderVisible={true}
                          ariaLabelForShimmer="Content is being fetched"
                          enterModalSelectionOnTouch={true}
                          shimmerLines={12}
                          constrainMode={ConstrainMode.unconstrained}
                        />
                        {this.state.currentProjects.length === 0 && this.state.showCurrentProjects && <div className='d-flex' style={{height: '40px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                          {this.state.loadingEntities ? <span className='my-auto mx-auto'><Spinner size={SpinnerSize.small}/></span> : <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>}
                        </div>}
                        </>}
                        {selItem && !selItem.id.startsWith('Contract-') && <>
                        <ShimmeredDetailsList
                          className={this.state.currentContracts.length === 0 || !this.state.showCurrentContracts ? 'mt-3 height-fix' : 'mt-3'}
                          items={this.state.showCurrentContracts ? this.state.currentContracts : []}
                          columns={columnsContracts}
                          onRenderDetailsHeader={onRenderDetailsHeaderContracts}
                          selectionMode={SelectionMode.none}
                          selectionPreservedOnEmptyClick={true}
                          setKey="set"
                          layoutMode={DetailsListLayoutMode.justified}
                          isHeaderVisible={true}
                          ariaLabelForShimmer="Content is being fetched"
                          enterModalSelectionOnTouch={true}
                          shimmerLines={12}
                          constrainMode={ConstrainMode.unconstrained}
                        />
                        {this.state.currentContracts.length === 0 && this.state.showCurrentContracts && <div className='d-flex' style={{height: '40px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                          {this.state.loadingEntities ? <span className='my-auto mx-auto'><Spinner size={SpinnerSize.small}/></span> : <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>}
                        </div>}
                        </>}
                        {selItem && !(selItem.id.startsWith('Contact-') || selItem.id.startsWith('User-') || selItem.id.startsWith('U')) && <>
                        <ShimmeredDetailsList
                          className={this.state.currentContacts.length === 0 || !this.state.showCurrentContacts ? 'mt-3 height-fix' : 'mt-3'}
                          items={this.state.showCurrentContacts ? this.state.currentContacts : []}
                          columns={columnsContacts}
                          onRenderDetailsHeader={onRenderDetailsHeaderContacts}
                          selectionMode={SelectionMode.none}
                          selectionPreservedOnEmptyClick={true}
                          setKey="set"
                          layoutMode={DetailsListLayoutMode.justified}
                          isHeaderVisible={true}
                          ariaLabelForShimmer="Content is being fetched"
                          enterModalSelectionOnTouch={true}
                          shimmerLines={12}
                          constrainMode={ConstrainMode.unconstrained}
                        />
                        {this.state.currentContacts.length === 0 && this.state.showCurrentContacts && <div className='d-flex' style={{height: '40px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                          {this.state.loadingEntities ? <span className='my-auto mx-auto'><Spinner size={SpinnerSize.small}/></span> : <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>}
                        </div>}
                        </>}
                        {selItem && !selItem.id.startsWith('Company-') && <>
                        <ShimmeredDetailsList
                          className={this.state.currentCompanies.length === 0 || !this.state.showCurrentCompanies ? 'mt-3 height-fix' : 'mt-3'}
                          items={this.state.showCurrentCompanies ? this.state.currentCompanies : []}
                          columns={columnsCompanies}
                          onRenderDetailsHeader={onRenderDetailsHeaderCompanies}
                          selectionMode={SelectionMode.none}
                          selectionPreservedOnEmptyClick={true}
                          setKey="set"
                          layoutMode={DetailsListLayoutMode.justified}
                          isHeaderVisible={true}
                          ariaLabelForShimmer="Content is being fetched"
                          enterModalSelectionOnTouch={true}
                          shimmerLines={12}
                          constrainMode={ConstrainMode.unconstrained}
                        />
                        {this.state.currentCompanies.length === 0 && this.state.showCurrentCompanies && <div className='d-flex' style={{height: '40px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                        {this.state.loadingEntities ? <span className='my-auto mx-auto'><Spinner size={SpinnerSize.small}/></span> : <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>}
                        </div>}
                        </>}
                      </div>
                    </div>
                    </div>
                  : activeTab === "fileDetails" ? 
                  <div>
                  <div className="text-left sticky-top" style={{background: '#f2f2f2', borderBottom: '1px solid #bfbfbf', padding: '11px 0px'}}>
                    <p className="lead font-weight-bold m-0">{i18n.t('app:fileDetails')}</p>
                  </div>
                  <div className='mt-2 justify-content-center'>
                    <div className="d-flex my-3">
                      <div className='d-flex'>
                        { (selItem.id).startsWith("Email-") ?
                          <Icon {...getFileTypeIconProps({ extension: '.eml', size: 48, imageFileType: 'svg' }) } style={{minWidth: '36px', minHeight: '36px', width: '36px', height: '36px'}}/>
                          : (selItem.id).startsWith("Conversation-") ?
                          <img alt='CONVERSATION' src={process.env.PUBLIC_URL + '/icons/conversations.svg'} style={{transform: 'scale(1, 1.3)', width: '36px', height: '36px'}}/>
                          : (selItem.id).startsWith("Note-") ?
                          <img alt='Note' src={process.env.PUBLIC_URL + '/icons/documents.svg'} style={{width: '36px', height: '36px'}}/>
                          : (selItem.id).startsWith("Task-") ?
                          <img alt='Task' src={process.env.PUBLIC_URL + '/icons/tasks.svg'} style={{width: '36px', height: '36px'}}/>
                          : selItem && selItem.image ?
                          <img src={'data:image/png;base64,'+selItem.image} alt='Logo' style={{width: '36px', height: '36px', borderRadius: selItem.id.startsWith('Project-') ? '2px' : selItem.id.startsWith('Company-') ? '0' : '24px'}}/>
                          :
                          <Persona className={selItem.id.startsWith('Project-') || selItem.id.startsWith('Contract-') ? 'project-persona' : selItem.id.startsWith('Company-') ? 'company-persona' : ''} name={selItem.name || selItem.subject || ((selItem.firstName || '') + ' ' + (selItem.lastName || ''))} size='large' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
                        }
                        <div className="file-name ml-3">
                          <div className="lead d-inline-block font-weight-bold">
                            { selItem.name || selItem.subject || ((selItem.firstName || '') + ' ' + (selItem.lastName || '')) }
                          </div>
                        </div>
                      </div>
                    </div>
                    { selItem.files && selItem.files.map((file, index) => {
                      return <div id={file.id}>
                        {index > 0 && <hr/>}
                        { file && file.id && <li className="mb-2 d-flex align-items-center">
                          <Icon className='mr-3 field-icon' iconName='PDF'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:file')}:</div>
                            <Link href={'https://drive.'+this.props.userData.repository.deploymentDomain + '/path/' + file.id} target='_blank' className="field-value">{ file.name || "" }</Link>
                          </div>
                        </li>}
                        { file && file.size && <li className="mb-2 d-flex align-items-center">
                          <Icon className='mr-3 field-icon' iconName='Database'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:size')}:</div>
                            <div className="field-value">{ getReadableFileSizeString(file.size) || "" }</div>
                          </div>
                        </li>}
                        { file && file.created_by && <li className="mb-2 d-flex align-items-center">
                          <Icon className='mr-3 field-icon' iconName='Contact'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:createdBy')}:</div>
                            <div className="field-value">{ file.created_by.name || "" }</div>
                          </div>
                        </li>}
                        { file && file.created_at && <li className="mb-2 d-flex align-items-center">
                          <Icon className='mr-3 field-icon' iconName='Calendar'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:dateCreated')}:</div>
                            <div className="field-value">{ Moment(file.created_at).format(i18n.t('Y-MMM-DD')) || "" }</div>
                          </div>
                        </li>}
                        { file && file.modified_by && <li className="mb-2 d-flex align-items-center">
                          <Icon className='mr-3 field-icon' iconName='Contact'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:modifiedBy')}:</div>
                            <div className="field-value">{ file.modified_by.name || "" }</div>
                          </div>
                        </li>}
                        { file && file.modified_at && <li className="mb-2 d-flex align-items-center">
                          <Icon className='mr-3 field-icon' iconName='Calendar'/>
                          <div>
                            <div className="text-secondary mr-2">{i18n.t('app:dateModified')}:</div>
                            <div className="field-value">{ Moment(file.modified_at).format(i18n.t('Y-MMM-DD')) || "" }</div>
                          </div>
                        </li>}
                      </div>
                    }) }

                  </div>
                  </div>
                  : activeTab === "permissions" ? 
                  <div>
                    <div className="text-left sticky-top" style={{background: '#f2f2f2', borderBottom: '1px solid #bfbfbf', padding: '11px 0px'}}>
                      <p className="lead font-weight-bold m-0">{i18n.t('app:permissions')}</p>
                    </div>
                    <div className="d-flex my-3">
                      <div className='d-flex'>
                        { (selItem.id).startsWith("Email-") ?
                          <Icon {...getFileTypeIconProps({ extension: '.eml', size: 48, imageFileType: 'svg' }) } style={{minWidth: '36px', minHeight: '36px', width: '36px', height: '36px'}}/>
                          : (selItem.id).startsWith("Conversation-") ?
                          <img alt='CONVERSATION' src={process.env.PUBLIC_URL + '/icons/conversations.svg'} style={{transform: 'scale(1, 1.3)', width: '36px', height: '36px'}}/>
                          : (selItem.id).startsWith("Note-") ?
                          <img alt='Note' src={process.env.PUBLIC_URL + '/icons/documents.svg'} style={{width: '36px', height: '36px'}}/>
                          : (selItem.id).startsWith("Task-") ?
                          <img alt='Task' src={process.env.PUBLIC_URL + '/icons/tasks.svg'} style={{width: '36px', height: '36px'}}/>
                          : selItem && selItem.image ?
                          <img src={'data:image/png;base64,'+selItem.image} alt='Logo' style={{width: '36px', height: '36px', borderRadius: selItem.id.startsWith('Project-') ? '2px' : selItem.id.startsWith('Company-') ? '0' : '24px'}}/>
                          :
                          <Persona className={selItem.id.startsWith('Project-') || selItem.id.startsWith('Contract-') ? 'project-persona' : selItem.id.startsWith('Company-') ? 'company-persona' : ''} name={selItem.name || selItem.subject || ((selItem.firstName || '') + ' ' + (selItem.lastName || ''))} size='large' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
                        }
                        <div className="file-name ml-3">
                          <div className="lead d-inline-block font-weight-bold">
                            { selItem.name || selItem.subject || ((selItem.firstName || '') + ' ' + (selItem.lastName || '')) }
                          </div>
                        </div>
                      </div>
                    </div>
                    { selItem.id && <li className="mb-2 d-flex align-items-center">
                      <div>
                        <div className="text-secondary mr-2">{i18n.t('app:ID')}:</div>
                        <div className="username font-weight-bold">{ selItem.id || "" }</div>
                      </div>
                    </li>}
                    <div className='justify-content-center'>
                      <div className='d-flex link-split-button mt-3'>
                        <DefaultButton
                          iconProps={{iconName:'Permissions'}} 
                          title={i18n.t('app:editPermissions')} 
                          text={i18n.t('app:editPermissions')}
                          onClick={()=>{this.props.headerRef && this.props.headerRef._showFormPermissions()}}
                        />
                      </div>
                      <div>
                        <div className='d-flex align-items-center py-2' style={{borderBottom: '1px solid #bfbfbf'}}>
                          <TooltipHost
                            content={i18n.t('app:expand/collapseAll')}
                            calloutProps={{ gapSpace: 0 }}
                            styles={{root: {marginRight: '8px'}}}
                          >
                            <IconButton
                              onClick={()=>this.toggleAll()} 
                              className='expand-icon' 
                              iconProps={{iconName: this.state.showCurrentCompanies && this.state.showCurrentContacts && this.state.showCurrentProjects ? 'CollapseContent' : 'ExploreContent', styles: {root: {fontSize: 18, color: 'rgb(0, 120, 212)'}}}} 
                            />
                          </TooltipHost>
                          <span style={{fontWeight: 600, fontSize: 14}}>{i18n.t('app:permissions')}</span>
                        </div>
                        <div className='relation-list panel'>
                          <Label className='mt-3'>{i18n.t('app:defaultPermissions')}</Label>
                          <ShimmeredDetailsList
                            className={this.state.defaultPermissions[0].length === 0 || !this.state.showDefaultPermissionsEditors ? 'mt-2 height-fix' : 'mt-2'}
                            items={this.state.showDefaultPermissionsEditors ? this.state.defaultPermissions[0] : []}
                            columns={columnsDefaultPermissionsEditors}
                            onRenderDetailsHeader={onRenderDetailsHeaderContacts}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={true}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            ariaLabelForShimmer="Content is being fetched"
                            enterModalSelectionOnTouch={true}
                            shimmerLines={12}
                            constrainMode={ConstrainMode.unconstrained}
                          />
                          {this.state.defaultPermissions[0].length === 0 && this.state.showDefaultPermissionsEditors && <div className='d-flex' style={{height: '40px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                            {this.state.loadingPermissions ? <span className='my-auto mx-auto'><Spinner size={SpinnerSize.small}/></span> : <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>}
                          </div>}
                          <ShimmeredDetailsList
                            className={this.state.defaultPermissions[1].length === 0 || !this.state.showDefaultPermissionsRestrictedEditors ? 'mt-3 height-fix' : 'mt-3'}
                            items={this.state.showDefaultPermissionsRestrictedEditors ? this.state.defaultPermissions[1] : []}
                            columns={columnsDefaultPermissionsRestrictedEditors}
                            onRenderDetailsHeader={onRenderDetailsHeaderContacts}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={true}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            ariaLabelForShimmer="Content is being fetched"
                            enterModalSelectionOnTouch={true}
                            shimmerLines={12}
                            constrainMode={ConstrainMode.unconstrained}
                          />
                          {this.state.defaultPermissions[1].length === 0 && this.state.showDefaultPermissionsRestrictedEditors && <div className='d-flex' style={{height: '40px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                            {this.state.loadingPermissions ? <span className='my-auto mx-auto'><Spinner size={SpinnerSize.small}/></span> : <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>}
                          </div>}
                          <ShimmeredDetailsList
                            className={this.state.defaultPermissions[2].length === 0 || !this.state.showDefaultPermissionsViewers ? 'mt-3 height-fix' : 'mt-3'}
                            items={this.state.showDefaultPermissionsViewers ? this.state.defaultPermissions[2] : []}
                            columns={columnsDefaultPermissionsViewers}
                            onRenderDetailsHeader={onRenderDetailsHeaderContacts}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={true}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            ariaLabelForShimmer="Content is being fetched"
                            enterModalSelectionOnTouch={true}
                            shimmerLines={12}
                            constrainMode={ConstrainMode.unconstrained}
                          />
                          {this.state.defaultPermissions[2].length === 0 && this.state.showDefaultPermissionsViewers && <div className='d-flex' style={{height: '40px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                            {this.state.loadingPermissions ? <span className='my-auto mx-auto'><Spinner size={SpinnerSize.small}/></span> : <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>}
                          </div>}
                          <Label className='mt-3'>{i18n.t('app:customPermissions')}</Label>
                          <ShimmeredDetailsList
                            className={this.state.customPermissions[0].length === 0 || !this.state.showCustomPermissionsEditors ? 'mt-2 height-fix' : 'mt-2'}
                            items={this.state.showCustomPermissionsEditors ? this.state.customPermissions[0] : []}
                            columns={columnsCustomPermissionsEditors}
                            onRenderDetailsHeader={onRenderDetailsHeaderContacts}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={true}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            ariaLabelForShimmer="Content is being fetched"
                            enterModalSelectionOnTouch={true}
                            shimmerLines={12}
                            constrainMode={ConstrainMode.unconstrained}
                          />
                          {this.state.customPermissions[0].length === 0 && this.state.showCustomPermissionsEditors && <div className='d-flex' style={{height: '40px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                            {this.state.loadingPermissions ? <span className='my-auto mx-auto'><Spinner size={SpinnerSize.small}/></span> : <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>}
                          </div>}
                          <ShimmeredDetailsList
                            className={this.state.customPermissions[1].length === 0 || !this.state.showCustomPermissionsRestrictedEditors ? 'mt-3 height-fix' : 'mt-3'}
                            items={this.state.showCustomPermissionsRestrictedEditors ? this.state.customPermissions[1] : []}
                            columns={columnsCustomPermissionsRestrictedEditors}
                            onRenderDetailsHeader={onRenderDetailsHeaderContacts}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={true}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            ariaLabelForShimmer="Content is being fetched"
                            enterModalSelectionOnTouch={true}
                            shimmerLines={12}
                            constrainMode={ConstrainMode.unconstrained}
                          />
                          {this.state.customPermissions[1].length === 0 && this.state.showCustomPermissionsRestrictedEditors && <div className='d-flex' style={{height: '40px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                            {this.state.loadingPermissions ? <span className='my-auto mx-auto'><Spinner size={SpinnerSize.small}/></span> : <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>}
                          </div>}
                          <ShimmeredDetailsList
                            className={this.state.customPermissions[2].length === 0 || !this.state.showCustomPermissionsViewers ? 'mt-3 height-fix' : 'mt-3'}
                            items={this.state.showCustomPermissionsViewers ? this.state.customPermissions[2] : []}
                            columns={columnsCustomPermissionsViewers}
                            onRenderDetailsHeader={onRenderDetailsHeaderContacts}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={true}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            ariaLabelForShimmer="Content is being fetched"
                            enterModalSelectionOnTouch={true}
                            shimmerLines={12}
                            constrainMode={ConstrainMode.unconstrained}
                          />
                          {this.state.customPermissions[2].length === 0 &&  this.state.showCustomPermissionsViewers && <div className='d-flex' style={{height: '40px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                            {this.state.loadingPermissions ? <span className='my-auto mx-auto'><Spinner size={SpinnerSize.small}/></span> : <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>}
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  : null}
                </div>
              </div>
            : this.props.listExplorerRef && this.props.listExplorerRef._selection && this.props.listExplorerRef._selection.getSelectedCount() > 1 ?
              <p className="lead font-weight-bold text-center my-2">{ this.props.listExplorerRef._selection && this.props.listExplorerRef._selection.getSelectedCount() } {i18n.t('app:itemsSelected')}</p>
            : this.props.page === 'home' ?
              <div>
                <div className="text-left sticky-top" style={{background: '#f2f2f2', borderBottom: '1px solid #bfbfbf', padding: '11px 0px'}}>
                  <p className="lead font-weight-bold m-0">{i18n.t('app:info')}</p>
                </div>
                <div className="d-flex my-4 w-100">
                  <div className="text-center w-100">
                    <img src={'data:image/png;base64,'+localStorage.getItem('companyLogo1') } style={{maxWidth: '60%', maxHeight: '100px'}} className="img-fluid" alt="Company logo" />
                  </div>
                </div>
                {this.props.userData && <li className="mb-2 d-flex align-items-center">
                  <div>
                    <div className="text-secondary mr-2">{i18n.t('app:ID')}:</div>
                    <div className="username font-weight-bold">{ this.props.userData.repository.id }</div>
                  </div>
                </li>}
                {this.props.userData && <li className="mb-2 d-flex align-items-center">
                  <div>
                    <div className="text-secondary mr-2">{i18n.t('app:role')}:</div>
                    <div className="username font-weight-bold">{ i18n.t('app:'+this.props.userData.user.role) }</div>
                  </div>
                </li>}
              </div>
            : 
              <p className="lead font-weight-bold text-center my-2">{i18n.t('app:noItemSelected')}</p>
            }
          </div>
        </Resizable>}
        {<div className="" style={{padding: '0 3px 0 4px', background: '#f2f2f2', alignItems: 'flex-start',display: 'flex',flexDirection: 'column',justifyContent: 'flex-start',rowGap: '20px', borderLeft: showRightPanel ? '1px solid #f2f2f2' : '1px solid rgb(237, 235, 233)'}}>
          <TabList selectedValue={activeTab} onTabSelect={this._handleLinkClick} vertical>
            <Tab icon={<Icon iconName='Info'/>} value="info" style={{height:'48px'}}></Tab>
            {this.props.page !== 'home' && <Tab icon={<Icon iconName='DietPlanNotebook'/>} value="details" style={{height:'48px'}}></Tab>}
            {selItem && (selItem.id.startsWith('Project-') || selItem.id.startsWith('Contract-') || selItem.id.startsWith('Contact-') || selItem.id.startsWith('User-') || selItem.id.startsWith('U') || selItem.id.startsWith('Company-')) && <Tab icon={<Icon iconName='Permissions'/>} value="permissions" style={{height:'48px'}}></Tab>}
            {selItem && selItem.id.startsWith('Contract-') && <Tab icon={<Icon iconName='PDF'/>} value="fileDetails" style={{height:'48px'}}></Tab>}
          </TabList>
        </div>}
      </div>
    )
  }

  private getPersona(id: string) {
    let user:any = this.props.possibleContacts.find((item:any) => {
      return item.id === id;
    })
    return (
      user? <TooltipHost
        content= {
          <div className='m-1'>
            <FluentProvider>
              <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
              <Persona className='mb-2' name={(user.name || '') + ' ' + (user.surname || '')} secondaryText={user.email} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
            </FluentProvider>
          </div>
        }
      >
        <Persona className='pr-2' size='extra-small' name={(user.name || '') + ' ' + (user.surname || '')} textAlignment='center' avatar={{ color: "colorful" }}/>
      </TooltipHost> : ''
    )
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("detailsPanelPrefs", JSON.stringify(prefs))
  }

  private _handleLinkClick = (e:SelectTabEvent, data: any): void => {
    this._isMounted && this.setState({
      activeTab: data.value
    });
    if (this._isMounted && this.state.activeTab ===  data.value) this.props.callbackFunction({showRightPanel: !this.state.showRightPanel})
    else if(this._isMounted && this.state.activeTab !==  data.value) this.props.callbackFunction({showRightPanel: true})
  };


  private _getTabId = (itemKey: string): string => {
    return `${itemKey}`;
  };

  private getStagesArrows(item:any) {
    const { pipelines } = this.props;
    let pipeline:any = null;
    for (let i in pipelines) {
      for (let j in pipelines[i].stages) {
        if (item.stage && pipelines[i].stages[j].id === item.stage.id) pipeline = pipelines[i]
      }
    }
    if (pipeline) {
      pipeline.stages = pipeline.stages.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
      let selected = false
      let nStages = pipeline.stages.length;
      var index = 0;
      return (
        <>
          <div className='text-secondary' style={{fontSize: 12}}>{i18n.t('app:stage')}:</div>
          <div className='d-flex align-items-center'>
            <Icon 
              iconName="CircleShapeSolid" 
              className="tag mr-2" 
              style={{
                fontSize:12,
                textAlign:'center',
                color: 'rgb(16, 110, 190)',
                opacity: (item.stage.position+1)/nStages
              }} 
            />
            <span className="m-0 p-0 field-value">{item.stage.name}</span>
          </div>
          <div className='pt-2 text-secondary' style={{fontSize: 12}}>{i18n.t('app:progress')}:</div>
          <div className='d-flex align-items-center align-self-center pt-1 stage-arrows'>
            {pipeline.stages.map((stage)=>{
              index++;
              if (!selected && stage.id !== item.stage.id) {
                return (<div key={stage.id} style={{minWidth: '0px'}}>
                  <TooltipHost content={pipeline.name + " - " + stage.name}>
                    <img className='selected arrow-image' src={process.env.PUBLIC_URL + '/img/arrow.svg'} alt='Arrow' style={{opacity: index/nStages > .9 ? .9 : index/nStages, maxWidth: '36px', height: '20px'}}/>
                  </TooltipHost>
                </div>

                )
              }
              else if (!selected && stage.id === item.stage.id) {
                selected = true
                return (<div key={stage.id} style={{minWidth: '0px'}}>
                  <TooltipHost content={pipeline.name + " - " + stage.name}>
                    <img className='selected arrow-image' src={process.env.PUBLIC_URL + '/img/arrow.svg'} alt='Arrow' style={{opacity: index/nStages > .9 ? .9 : index/nStages, maxWidth: '36px', height: '20px'}}/>
                  </TooltipHost>
                  </div>
                )
              }
              else if (selected && stage.id !== item.stage.id) {
                return (<div key={stage.id} style={{minWidth: '0px'}}>
                  <TooltipHost content={pipeline.name + " - " + stage.name}>
                    <img className='arrow-image' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} alt='Arrow' style={{maxWidth: '36px', height: '20px'}}/>
                  </TooltipHost>
                  </div>
                )
              }
              else return null;
            })}
          </div>
        </>  
      )
    }
  }

}

function getReadableFileSizeString(fileSizeInBytes) {
  var i = -1;
  var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};