import { userService } from "../_services/user.service";
import * as XLSX from 'xlsx';
import ReactDOM from 'react-dom';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, PrimaryButton, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import i18n from "../i18n";
import { Input, Persona } from "@fluentui/react-components";

export function _importContacts() {
    var showDialog = true;
    var file:any;
    var fileExtension = '';
    var sheetFileContacts:any = [];

    var _import = () => {
      var reader = new FileReader();
      reader.onload = function(e:any) {
        var data = e.target.result;
        const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'array', raw: true, cellFormula: false });
        var json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
        //showDialog = false;
        userService.getCustomProperties('','CONTACT').then((response) =>{
          let customProperties = response.data.map(item=>{return {...item, id: item.id, name: item.name, position: item.customPropertyTypes.find(x=>{return x.entityType === 'CONTACT'}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === 'CONTACT'}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}});
          customProperties = customProperties.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
          sheetFileContacts = json.map((item:any, index)=>{return {
            id: index,
            firstName: item.firstName || '-',
            lastName: item.lastName  || '-',
            nickname: item.nickname, 
            description: item.notes, 
            phoneNumbers: [
              {type: 'BUSINESS', number: item.phone_business},
              {type: 'HOME', number: item.phone_home},
              {type: 'MOBILE', number: item.phone_mobile},
              {type: 'OTHER', number: item.phone_other},
            ].map((x,i)=>{return {...x, position: i} }),
            emailAddresses: [
              {name: 'Email 1', email: item.email_1},
              {name: 'Email 2', email: item.email_2},
              {name: 'Email 3', email: item.email_3},
              {name: 'Email 4', email: item.email_4}
            ].map((x,i)=>{return {...x, position: i} }),
            linkedin: item.linkedin,
            jobTitle: item.jobTitle,
            locationAddresses: [
              {
                streetAddress: item.streetAddress,
                postalCode: item.postalCode,
                city: item.city,
                region: item.region,
                country: item.country,
              }
            ],
            customPropertyValues: customProperties.filter(p=>{
              return item[p.name] && (item[p.name].length > 0 || item[p.name] > 0)
            }).map(p=>{
              return {customPropertyId: p.id, values: [item[p.name]]}
            })
          }});
          renderDialog();
        }).catch(error=>{
          console.log(error)
        })
      };
      reader.readAsArrayBuffer(file)
      
    }

    var _showFilePicker = () => {
      var input = document.createElement('input');
      input.type = 'file';
      input.accept= '.xls, .xlsx, .xlsm, .csv,'

      input.onchange = e => {
        let event:any = e as Event;
        event.stopPropagation();
        event.preventDefault();

        file = event.target.files[0]
        fileExtension = file.name.split('.').pop()
        renderDialog()
      }

      input.click();
    }

    var onDragEnter = (event) => {
      if (event.target.className.indexOf("droptarget")!== -1) {
        event.target.style.background = "#C7E0F4";
      }
    }

    var onDragLeave = (event) => {
      if (event.target.className.indexOf("droptarget")!== -1) {
        event.target.style.background = "transparent";
      }
    }

    var onDragOver = (event) => {
      event.stopPropagation();
      event.preventDefault();
    }

    var onFileDrop = (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (event.target.className.indexOf("droptarget")!== -1) {
        event.target.style.background = "transparent";
      }

      if(event.dataTransfer.files.length === 1) {
        file = event.dataTransfer.files[0]
        fileExtension = file.name.split('.').pop()

        renderDialog()
      }
    }

    async function createContact(item) {
      return await new Promise((resolve, reject) => {
        userService.createContact(item)
        .then((response: any) => {
          resolve(response)
        }).catch(error => {
          resolve(error)
        })
      })
    }

    async function _createFromFile() {
      for (let i in sheetFileContacts) {
        let item:any = sheetFileContacts[i];
        item.emailAddresses = item.emailAddresses.filter(x=>{return x.email})
        item.phoneNumbers = item.phoneNumbers.filter(x=>{return x.number})
        await createContact(item).then((result:any)=>{
          if (result.status === 409 ) sheetFileContacts[i].state = 'alreadyExists'
          else if (result.status === 201 ) sheetFileContacts[i].state = 'created'
          else sheetFileContacts[i].state = 'failed'
        })
      }
      showDialog = false; renderDialog();
    }

    function updateValue(id:string, field: string, value: string) {
      for (let i in sheetFileContacts) {
        if (sheetFileContacts[i].id === id) {
          sheetFileContacts[i][field] = value;
          renderDialog();
        }
      }
    }

    function updateEmailValue(id:string, field: string, index:number, value: string) {
      for (let i in sheetFileContacts) {
        if (sheetFileContacts[i].id === id) {
          sheetFileContacts[i][field][index].email = value;
          renderDialog();
        }
      }
    }

    function updatePhoneValue(id:string, field: string, index:number, value: string) {
      for (let i in sheetFileContacts) {
        if (sheetFileContacts[i].id === id) {
          sheetFileContacts[i][field][index].number = value;
          renderDialog();
        }
      }
    }

    var div = document.createElement('div');
    var renderDialog = () => {

      var columns:any = [
        {
          key: 'icon',
          name: '#',
          fieldName: 'icon',
          minWidth: 1,
          maxWidth: 1,
          isRowHeader: true,
          data: 'string',
          isPadded: true,
          //onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return (
              item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '28px'}}/>
              :
                <div style={{borderRadius: '24px', height: '28px'}}><Persona className='contact-persona' name={item ? item.firstName + " " + item.lastName : ''} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful" }}/></div>
            )
          }
        },
        {
          key: 'firstName',
          name: i18n.t('app:firstName'),
          fieldName: 'firstName',
          minWidth: 150,
          maxWidth: 250,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return (
              <Input appearance='underline' value={item.firstName || ''} onChange={(e,data)=>{updateValue(item.id, 'firstName', data.value)}}/>

            )
          }
        },
        {
          key: 'lastName',
          name: i18n.t('app:lastName'),
          fieldName: 'lastName',
          minWidth: 150,
          maxWidth: 250,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return (
              <Input appearance='underline' value={item.lastName || ''} onChange={(e,data)=>{updateValue(item.id, 'lastName', data.value)}}/>
            )
          }
        },
        {
          key: 'nickname',
          name: i18n.t('app:nickName'),
          fieldName: 'nickname',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return (
              <Input appearance='underline' value={item.nickname || ''} onChange={(e,data)=>{updateValue(item.id, 'nickname', data.value)}}/>
            )
          }
        },
        {
          key: 'notes',
          name: i18n.t('app:notes'),
          fieldName: 'description',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return (
              <Input appearance='underline' value={item.description || ''} onChange={(e,data)=>{updateValue(item.id, 'description', data.value)}}/>
            )
          }
        },
        {
          key: 'emailAddress0',
          name: i18n.t('app:emailAddress') + ' 1',
          fieldName: 'emailAddress0',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return <Input appearance='underline' value={item.emailAddresses[0].email || ''} onChange={(e,data)=>{updateEmailValue(item.id, 'emailAddresses', 0, data.value)}}/>
          }
        },
        {
          key: 'emailAddress1',
          name: i18n.t('app:emailAddress') + ' 2',
          fieldName: 'emailAddress1',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return <Input appearance='underline' value={item.emailAddresses[1].email || ''} onChange={(e,data)=>{updateEmailValue(item.id, 'emailAddresses', 1, data.value)}}/>
          }
        },
        {
          key: 'emailAddress2',
          name: i18n.t('app:emailAddress') + ' 3',
          fieldName: 'emailAddress2',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return <Input appearance='underline' value={item.emailAddresses[2].email || ''} onChange={(e,data)=>{updateEmailValue(item.id, 'emailAddresses', 2, data.value)}}/>
          }
        },
        {
          key: 'emailAddress3',
          name: i18n.t('app:emailAddress') + ' 4',
          fieldName: 'emailAddress3',
          minWidth: 150,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return <Input appearance='underline' value={item.emailAddresses[3].email || ''} onChange={(e,data)=>{updateEmailValue(item.id, 'emailAddresses', 3, data.value)}}/>
          }
        },
        {
          key: 'phoneNumber0',
          name: i18n.t('app:phoneNumber') + ' 1',
          fieldName: 'phoneNumber0',
          minWidth: 120,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return <Input appearance='underline' value={item.phoneNumbers[0].number || ''} onChange={(e,data)=>{updatePhoneValue(item.id, 'phoneNumbers', 0, data.value)}}/>
          }
        },
        {
          key: 'phoneNumber1',
          name: i18n.t('app:phoneNumber') + ' 2',
          fieldName: 'phoneNumber1',
          minWidth: 120,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return <Input appearance='underline' value={item.phoneNumbers[1].number || ''} onChange={(e,data)=>{updatePhoneValue(item.id, 'phoneNumbers', 1, data.value)}}/>
          }
        },
        {
          key: 'phoneNumber2',
          name: i18n.t('app:phoneNumber') + ' 3',
          fieldName: 'phoneNumber2',
          minWidth: 120,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return <Input appearance='underline' value={item.phoneNumbers[2].number || ''} onChange={(e,data)=>{updatePhoneValue(item.id, 'phoneNumbers', 2, data.value)}}/>
          }
        },
        {
          key: 'phoneNumber3',
          name: i18n.t('app:phoneNumber') + ' 4',
          fieldName: 'phoneNumber3',
          minWidth: 120,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return <Input appearance='underline' value={item.phoneNumbers[3].number || ''} onChange={(e,data)=>{updatePhoneValue(item.id, 'phoneNumbers', 3, data.value)}}/>
          }
        },
        {
          key: 'linkedin',
          name: i18n.t('app:linkedIn'),
          fieldName: 'linkedin',
          minWidth: 320,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          //isPadded: true,
          onRender: (item: any) => {
            return (
              <Input appearance='underline' value={item.linkedin || ''} onChange={(e,data)=>{updateValue(item.id, 'linkedin', data.value)}}/>
              )
          }
        }
      ]
      
      ReactDOM.render(
        sheetFileContacts.length === 0 ? 
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div>{i18n.t('app:importContacts')}</div>
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "alert-dialog-default"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >

          <div className="dialog-content-wrap">
            {!file ? <div className={"file-droptarget droptarget"} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={(e) => onFileDrop(e)}>
              <p>{i18n.t('app:dragDrop')}<br />
              <span className="text-uppercase d-inline-block my-2">- {i18n.t('app:or')} -</span><br />
              <PrimaryButton onClick={ _showFilePicker }>
                {i18n.t('app:selectFile')}
              </PrimaryButton>
              </p>
            </div>
            :
            <div className={"file-droptarget pointer-events-enabled addedFile"}>
              <p><Icon {...getFileTypeIconProps({ extension: fileExtension, size: 24, imageFileType: 'svg' }) } /> { file.name } <br />
              <span onClick={ () => {file = null; renderDialog()}} className="remove">{i18n.t('app:remove')}</span>
              </p>
            </div>}
            <DialogFooter className="mt-4">
              <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('app:cancel')}  />
              <PrimaryButton onClick={() => _import()} text={i18n.t('app:next')} disabled={!file}/>
            </DialogFooter>
          </div>

        </Dialog>
        :
        <Dialog
          hidden={!showDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: <div>{i18n.t('app:importContacts')}</div>
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog"
          }}
          onDismiss={() => {showDialog = false; renderDialog()}}
        >

          <div className="dialog-content-wrap">
            <ShimmeredDetailsList columns={columns} className='mx-4 import-list' items={sheetFileContacts} selectionMode={SelectionMode.none} />
            <DialogFooter className="mt-4">
              <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('app:cancel')}  />
              <PrimaryButton onClick={() => _createFromFile()} text={i18n.t('app:create')} disabled={!file}/>
            </DialogFooter>
          </div>

        </Dialog>
        , div
      )
    }

    renderDialog();

  }