import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, Icon, IconButton, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import i18n from '../../i18n';
import { userService } from '../../_services';
import { Divider, FluentProvider, Input, Label, Textarea, webLightTheme } from '@fluentui/react-components';
import { CustomField } from '../CustomField';

export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  customFields: any[];
  chosenFields:any;
  chosenFieldsKeys: any;
  items: any[];
  propertiesOrder: any[];
  customProperties: any[];
  loadingProperties: boolean;
  canSubmit: boolean;
  error:any;
  phoneNumbers: any;
  locationAddresses: any;
  emailAddresses: any;
  urls: any;
  contactIds: any;
  profileImage: any;
  name: string;
  nickname: string;
  description: string;
  stage: string;
  pipelineId: string;
  pipelines: any[];
  stages: any[];
  stageId: string;
  value: string;
  changed: boolean;
}

export class FormProjectNew extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      customFields: [{name:"", value: ""}],
      chosenFields: [],
      chosenFieldsKeys: [],
      items: [],
      propertiesOrder: [],
      customProperties: [],
      loadingProperties: true,
      canSubmit: true,
      error: "",
      phoneNumbers: [{key: 1, text: '', addressType: 'MOBILE'}],
      locationAddresses: [],
      emailAddresses: [{key: 1, text: '', addressType: 'WORK'}],
      urls: [""],
      contactIds: [],
      profileImage: null,

      name: '',
      nickname: '',
      description: '',
      pipelineId: '',
      stage: '',
      pipelines: [],
      stages: [],
      stageId: '',
      value: '',
      changed: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    userService.getPipelines().then(response => {
      this.setState({pipelines: response.data.map(pipeline => {return {...pipeline, text: pipeline.name, key: pipeline.id}}) })
      userService.getCustomProperties('','PROJECT').then((res) =>{
        let customProperties = res.data.map(item=>{return {...item, id: item.id, name: item.name, position: item.customPropertyTypes.find(x=>{return x.entityType === 'PROJECT'}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === 'PROJECT'}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}});
        customProperties = customProperties.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
        this._isMounted && this.setState({
          customProperties: customProperties.map(item => { return {...item, key: item.id}}),
          pipelines: response.data.map(pipeline => {return {...pipeline, text: pipeline.name, key: pipeline.id}}),
          loadingProperties: false
        })
      }).catch(error=>{
        console.log(error)
        this._isMounted && this.setState({hidden: true})
        this.props.showAlertDialog('Error', i18n.t('app:errorGettingCustomProperties'))
      })
    }).catch((error)=>{
      this._isMounted && this.setState({hidden: true})
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else {
        this.props.showAlertDialog('Error', "An error occurred trying to get existing Pipelines. Please try again.")
      }
    })
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      this.props.callback({showNewProjectForm: false})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private callback(data) {
    this._isMounted && this.setState(data)
  }

  private _handleKeyDown(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 65 && event.keyCode <= 90)){
      this._isMounted && this.setState({changed:true})
    }
  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      this._isMounted && this.setState({hidden:true})
    }
  }

  private updateName(data) {
    this._isMounted && this.setState({name: data.value, changed: true})
  }

  private updateNickname(data) {
    this._isMounted && this.setState({nickname: data.value, changed: true})
  }

  private updateValue(data) {
    this._isMounted && this.setState({value: data.value, changed: true})
  }

  private updateDescription(data) {
    this._isMounted && this.setState({description: data.value, changed: true})
  }

  private updatePipeline(data) {
    if (data) {
      let pipeline = this.state.pipelines.find((p)=>{return p.id === data.id })
      this._isMounted && this.setState({  
        pipelineId: data.key,
        stages: pipeline.stages.map(s => {return {...s, text: s.name, key: s.id}}).sort((a,b) => { return (a.position >= b.position ? 1 : -1)}),
        stageId: '',
        changed: true
      })
    }
  }

  private _submit(e) {
    if (e) e.preventDefault();
    const {loading} = this.state;
    if (loading) return;
    this._isMounted && this.setState({loading: true})
    const {name, nickname, description, stageId, value, profileImage, customProperties} = this.state;
    let canSubmit = true;
  
    let data = {
      name: name || null,
      nickname: nickname || null,
      stage: stageId || null,
      description: description || null,
      value: value,
      status: 'OPEN',
      image : profileImage ? profileImage.split(',')[1] : null,
      customPropertyValues: customProperties.map(item=>{return {customPropertyId: item.id, values: item.value && Array.isArray(item.value) ? item.value : [item.value]}}).filter(item=>{return item.values && item.values[0] !== null && item.values[0] !== '' && item.values[0] !== undefined})
    }

    if (canSubmit) {
      userService.createProject(data).then((response)=>{
        this._isMounted && this.setState({hidden: true, loadingProperties: false})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false, loadingProperties: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to create the project. Please try again.")
        }
      })
    } else {
      this._isMounted && this.setState({loading: false, loadingProperties: false, canSubmit: true})
    }
  }

  public render() {

    const { hidden, loading, loadingProperties, name, nickname, description, pipelines, pipelineId, stages, stageId, value } = this.state;

    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Project' src={process.env.PUBLIC_URL + '/icons/projects.svg'} />{i18n.t('app:createProject')}</div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: loadingProperties ? 'none' : 'overlay'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog",
            onDismiss: ()=> {this.close()}
          }}
        >
          <div className='mb-4' style={{borderBottom: '1px solid #bfbfbf', width: 'calc(100% - 34px)', position: 'sticky', left: '16px', top: 0, zIndex: 1}}></div>
          <FluentProvider theme={webLightTheme}>
            <form id='form' className='mb-3' style={{padding: '0 16px'}} onSubmit={this._submit.bind(this)}>
              <input className='d-none' type='submit'></input>
              <div className='d-flex'>
                <div className='flex-grow-1'>
                  <div className='d-flex mt-2'>
                    <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:name')}</Label>
                    <Input className='flex-grow-1' value={name} onChange={(e,data)=>this.updateName(data)} required autoFocus/>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:nickName')}</span>
                    <Input className='flex-grow-1' value={nickname} onChange={(e,data)=>this.updateNickname(data)}/>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='D365ProjectOperationsIcon' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:pipeline')}</span>
                    <div className='d-flex align-items-center flex-grow-1' style={{position: 'relative'}}>
                      <ComboBox className='flex-grow-1' styles={{ root: {borderRadius: '4px'}}} allowFreeform selectedKey={pipelineId} onChange={(e, data:any) => this.updatePipeline(data)} options={pipelines}/>
                      {pipelineId && <IconButton style={{position: 'absolute', right: '40px', height: '28px', width: '0px', fontSize: '12px', background: 'transparent'}} styles={{icon: {fontSize: '12px', color: 'rgb(96, 94, 92)'}}} iconProps={{iconName: 'Clear'}} onClick={(e)=>this._isMounted && this.setState({stageId: '', stages: [], pipelineId: '', changed: true})}/>}
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='D365ProjectOperationsIcon' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:stage')}</span>
                    <div className='d-flex align-items-center flex-grow-1' style={{position: 'relative'}}>
                      <Dropdown className='flex-grow-1' styles={{ title: {borderRadius: '4px'}}} selectedKey={stageId} onChange={(e, data:any) => this._isMounted && this.setState({stageId: data.id, changed: true})} options={stages} disabled={pipelineId === ''}/>
                      {stageId && <IconButton style={{position: 'absolute', right: '40px', height: '28px', width: '0px', fontSize: '12px', background: 'transparent'}} styles={{icon: {fontSize: '12px', color: 'rgb(96, 94, 92)'}}} iconProps={{iconName: 'Clear'}} onClick={(e)=>this._isMounted && this.setState({stageId: '', changed: true})}/>}
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:value')}</span>
                    <Input className='flex-grow-1' type='number' value={value} onChange={(e,data)=>this.updateValue(data)}/>
                  </div>
                </div>
                <div className='ml-3'>
                  <div className='contact-picture'>
                  {this.state.profileImage ? 
                      <img src={this.state.profileImage} alt='Profile' style={{width: '128px', height: '128px', marginTop: '8px'}}/>
                    : <div className='d-flex' style={{width: '128px', height: '128px', marginTop: '8px', backgroundColor: '#bfbfbf'}}>
                        <Icon className='m-auto' style={{fontSize: '42px', color: 'white'}} iconName='Work'/>
                      </div>
                    }
                    <IconButton iconProps={{iconName: this.state.profileImage ? 'Edit' : 'Add'}} onClick={()=>{this._openFileExplorer()}} />
                  </div>
                </div>
              </div>
              <div className='d-flex mt-3 mb-3'>
                <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:notes')}</span>
                <Textarea className='flex-grow-1' value={description} onChange={(e,data)=>this.updateDescription(data)}/>
              </div>
              {this.state.loadingProperties ? <Spinner/> : this.state.customProperties && this.state.customProperties.length > 0 && <> 
                <Divider className='mt-3'/>
                <div className='mt-3'>
                  { this.state.customProperties.map(item => {
                    return <div className='my-3' key={item.key || item.id}>
                      <CustomField userData={this.props.userData} customFields={this.state.customProperties} field={item} callback={this.callback.bind(this)}/>
                    </div>
                  })}
                </div>
              </>}
            </form>
          </FluentProvider>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this.close()}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton form='form' type='submit' text={i18n.t('app:save')} disabled={loading} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

  private _openFileExplorer() {
    var input = document.createElement('input');
    input.type = 'file';
    input.setAttribute("single","");
    input.accept= "image/*"
    var self = this;

    input.onchange = e => {
        let event:any = e as Event;
        event.stopPropagation();
        event.preventDefault();
        var imageVal = event.target.files[0];
        var reader = new FileReader();
        reader.onload = function (e:any) {
            var img:any = document.createElement("img");
            img.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = 128;
                canvas.height = 128;
                var ctx:any = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, 128, 128);
                var url = canvas.toDataURL("image/png");
                self._isMounted && self.setState({profileImage: url, changed: true})
            }
            img.src = e.target.result;
        }
        reader.readAsDataURL(imageVal);
    }
    input.click();
  }

}
