import * as React from 'react';
import { Checkbox, ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, IPeoplePickerItemSelectedProps, IPersonaProps, Icon, NormalPeoplePicker, PeoplePickerItem, PrimaryButton, Spinner, SpinnerSize, ValidationState } from '@fluentui/react';
import { FluentProvider, Input, Label, Link, Persona, webLightTheme } from '@fluentui/react-components';
import i18n from '../../i18n';
import { userService } from '../../_services';
import viewItemType from '../../_constants/viewItemType.json'


export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  name: string;
  changed: boolean;
  users: any[];
  loadingUsers: boolean;
  assignedTo: any[];
  allQuickAccess: boolean;
  overwriteView: boolean;
  views:any[];
  viewSelected: string;
}

export class FormView extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      name: '',
      changed: false,
      users: [],
      loadingUsers: true,
      assignedTo: [],
      allQuickAccess: true,
      overwriteView: false,
      views: [],
      viewSelected: ''
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    userService.getRepoUsers('ADMIN,INTERNAL').then((response) => {
      userService.getViews(viewItemType[this.props.page]).then((res)=>{
        let views = res.data.map(view=>{return {...view, text: view.name, key: view.id}});
        this._isMounted && this.setState({views: views, users: response.data.map((item)=> {return {...item, key: item.id, text: ((item.name || '') + ' ' + (item.surname || ''))}}), loadingUsers: false})
      })
    }).catch(()=>{
      this._isMounted && this.setState({users: [], loadingUsers: false})
    })
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      this.props.callback({showFormView: false})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private validateInput(input: string): ValidationState {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      this._isMounted && this.setState({hidden:true})
    }
  }

  private _submit(e) {
    if (e) e.preventDefault();
    const {loading} = this.state;
    if (loading) return;
    this._isMounted && this.setState({loading: true})
    const { name, assignedTo, allQuickAccess, overwriteView, viewSelected } = this.state;

    const data = {
      name: name,
      page: this.props.page,
      query: document.location.search,
      itemType: viewItemType[this.props.page],
      quickAccessUserIds: assignedTo.filter(item=>{return item.id}).map((item)=>{return item.id}),
      allQuickAccess: allQuickAccess
    }

    if (overwriteView) {
      userService.editView(viewSelected, data).then((response)=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
        this.props.sidebarRef && this.props.sidebarRef.refreshTree();
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update the custom view. Please try again.")
        }
      })
    } else {
      userService.createView(data).then((response)=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
        this.props.sidebarRef && this.props.sidebarRef.refreshTree();
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to create the custom view. Please try again.")
        }
      })
    }
  

  }

  public render() {

    const { hidden, loading, name, allQuickAccess, overwriteView, views, viewSelected } = this.state;

    const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
      return personasToReturn;
    };

    const filterAssignedToByText = (
      filterText: string,
      currentPersonas: IPersonaProps[],
      limitResults?: number,
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
      if (filterText) {
          let filteredPersonas: IPersonaProps[] = this.state.users.filter(item => doesTextStartWith(item, filterText));
      
          filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
          filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
          return filterPromise(filteredPersonas);
      } else {
          return [];
      }
    };

    const renderItemWithSecondaryText: any = (props: IPeoplePickerItemSelectedProps) => {
      const newProps = {
        ...props,
        item: {
          ...props.item,
          ValidationState: ValidationState.valid,
          //showSecondaryText: true,
        },
      };
  
      return <PeoplePickerItem {...newProps} className={"conversationDestinatary " + (props.item.id ? "internal" : "external") } />;
    }

    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: 
            <div className='d-flex align-items-center' style={{height: '30px'}}>
              <img className='mr-3' style={{height: '28px', width: '28px'}} alt='Project' src={process.env.PUBLIC_URL + '/icons/' + (viewItemType[this.props.page] === "PROJECT" ? 'projects.svg' : viewItemType[this.props.page] === "CONTRACT" ? 'contracts.svg' : viewItemType[this.props.page] === "COMPANY" ? 'companies.svg' : viewItemType[this.props.page] === "CONTACT" ? 'contacts.svg' : viewItemType[this.props.page] === "PIPELINE" ? 'pipelines.svg' : viewItemType[this.props.page] === "EMAIL" ? 'emails.svg' : viewItemType[this.props.page] === "NOTE" ? 'documents.svg' : viewItemType[this.props.page] === "CONVERSATION" ? 'conversations.svg' : viewItemType[this.props.page] === "TASK" ? 'tasks.svg' : '')} />
              {i18n.t('app:newView')}
            </div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: 'overlay'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog",
            onDismiss: ()=> {this.close()}
          }}
        >
          <div className='mb-4' style={{borderBottom: '1px solid #bfbfbf', width: 'calc(100% - 34px)', position: 'sticky', left: '16px', top: 0, zIndex: 1}}></div>
          <FluentProvider theme={webLightTheme}>
            <form id='form' className='mb-3' style={{padding: '0 16px'}} onSubmit={this._submit.bind(this)}>
              <input className='d-none' type='submit'></input>
              <div className='d-flex'>
                <div className='flex-grow-1'>
                  <div className='d-flex mt-2'>
                    <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:name')}</Label>
                    <Input className='flex-grow-1' value={name} onChange={(e,data)=>{this._isMounted && this.setState({name: data.value, changed: true})}} required autoFocus/>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='CheckboxComposite' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:pickWhoCanView')}</Label>
                    <Checkbox className='flex-grow-1 mt-1' styles={{label: {margin: 0, height: '20px'}, checkbox: {height: '20px'}}} checked={!allQuickAccess} onChange={(e,data)=>{this._isMounted && this.setState({allQuickAccess: !allQuickAccess, changed: true})}}/>
                  </div>
                  {!allQuickAccess && <div className='d-flex mt-2'>
                    <Icon iconName='Contact' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px', minWidth: '150px'}}>{i18n.t('app:visibleTo')}</span>
                    <div className='flex-grow-1'>
                      <NormalPeoplePicker disabled={this.state.loading} styles={{text: {borderRadius: '4px', background: 'white'}, root: {padding:'10px 0'}}} onRenderSuggestionsItem={ (item:any)=>{
                        return (
                            <FluentProvider className='d-flex align-items-center w-100 px-2' style={{background: 'transparent'}}>
                              <Persona name={(item.name || '') + ' ' + (item.surname || '')} size='small' primaryText='' avatar={{ color: "colorful"}}/>
                                <div>
                                    <p className='p-0 m-0 text-left' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.text}</p>
                                    <p className='p-0 m-0 text-left' style={{color:'rgb(96, 94, 92)', fontSize: 12}}><Link target='_blank' href={'mailto:'+item.email}>{item.email}</Link></p>
                                </div>
                            </FluentProvider>
                          )
                        }} 
                        onChange={(items) => {this._isMounted && this.setState({assignedTo: items || []})}}
                        onResolveSuggestions={(f,i:any)=>filterAssignedToByText(f,i)}
                        onValidateInput={this.validateInput}
                        className="p-0"
                        selectedItems={this.state.assignedTo}
                        onRenderItem={renderItemWithSecondaryText}
                      />
                    </div>
                  </div>}
                  <div className='d-flex mt-2'>
                    <Icon iconName='CheckboxComposite' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:overwriteView')}</Label>
                    <Checkbox className='flex-grow-1 mt-1' styles={{label: {margin: 0, height: '20px'}, checkbox: {height: '20px'}}} checked={overwriteView} onChange={(e,data)=>{this._isMounted && this.setState({overwriteView: !overwriteView, changed: true})}}/>
                  </div>
                  {overwriteView && <div className='d-flex mt-2'>
                    <Icon iconName='Contact' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px', minWidth: '150px'}}>{i18n.t('app:viewToOverwrite')}</span>
                    <ComboBox className='flex-grow-1' styles={{ root: {borderRadius: '4px'}}} allowFreeform selectedKey={viewSelected} onChange={(e,data)=>{this._isMounted && this.setState({viewSelected: data?.id || '', changed: true})}} options={views}/>
                  </div>}
                </div>
              </div>
            </form>
          </FluentProvider>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this.close()}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton form='form' type='submit' text={i18n.t('app:save')} disabled={loading} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

}

function doesTextStartWith(item: any, filterText: string): boolean {
  let email = item.email
  return item.text.toLowerCase().indexOf(filterText.toLowerCase()) > -1 || (email && email.toLowerCase().indexOf(filterText.toLowerCase()) > -1);
}

function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
  return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
  if (!personas || !personas.length || personas.length === 0) {
      return false;
  }
  return personas.filter(item => item.text === persona.text).length > 0;
}