//import { authHeader } from '../_helpers';
import axios from 'axios'
import { authHeader, history } from '../_helpers';
import i18n from "i18next";
import Cookies from 'js-cookie'
import uuid from 'uuid-random';

var sessionDuration = 30 //Same duration as the token
var canRefreshToken = true;

export const userService = {
    login,
    getTokenAAD,
    loginAAD,
    afterLogin,
    verifyEmailCode,
    resendVerifyEmailCode,
    refreshToken,
    setServerDomain,
    getServerDomain,
    logout,
    getUserData,
    getTemplate,
    createCustomProperty,
    getCustomProperties,
    editCustomProperty,
    reorderCustomProperties,
    deleteCustomProperty,
    createCompany,
    getCompanies,
    getCompany,
    editCompany,
    deleteCompanies,
    updateCompanyRelations,
    createContact,
    getContacts,
    getContact,
    editContact,
    deleteContacts,
    updateContactRelations,
    syncContacts,
    createProject,
    getProjects,
    getProject,
    editProject,
    editProjects,
    deleteProjects,
    updateProjectRelations,
    createContract,
    getContracts,
    getContract,
    editContract,
    deleteContracts,
    updateContractRelations,
    createPipeline,
    getPipelines,
    editPipeline,
    deletePipeline,
    createStages,
    editStages,
    deleteStages,
    getProjectEmails,
    getContractEmails,
    getContactEmails,
    getCompanyEmails,
    getProjectNotes,
    getContractNotes,
    getContactNotes,
    getCompanyNotes,
    getProjectConversations,
    getContractConversations,
    getContactConversations,
    getCompanyConversations,
    getEmails,
    getEmail,
    getEmailContent,
    deleteEmails,
    updateEmailRelations,
    downloadAttachment,
    downloadConversationAttachment,
    downloadTaskAttachment,
    saveAttachment,
    saveConversationAttachmentToDrive,
    saveTasksAttachmentToDrive,
    getNotes,
    getNote,
    getNoteContent,
    createNote,
    deleteNotes,
    updateNote,
    updateNoteRelations,
    getConversations,
    getConversation,
    getConversationContent,
    getConversationMessages,
    createMessage,
    updateMessage,
    deleteMessage,
    createTaskMessage,
    updateTaskMessage,
    deleteTaskMessage,
    createConversation,
    deleteConversations,
    updateConversation,
    updateConversationRelations,
    getTask,
    getTasks,
    getTaskMessages,
    getProjectTasks,
    getContractTasks,
    getContactTasks,
    getCompanyTasks,
    createTask,
    deleteTasks,
    updateTask,
    updateTaskRelations,
    getPermissions,
    updatePermissions,
    updateProjectPermissions,
    updateContractPermissions,
    updateContactPermissions,
    updateCompanyPermissions,
    updateNotePermissions,
    updateNoteVisibility,
    updateEmailPermissions,
    updateEmailVisibility,
    updateConversationPermissions,
    updateConversationVisibility,
    updateTaskPermissions,
    updateTaskVisibility,
    getRepoUsers,
    getRepoGroups,
    searchProjects,
    searchContracts,
    searchContacts,
    searchCompanies,
    searchNotes,
    searchEmails,
    searchConversations,
    searchTaskMessages,
    searchMessages,
    searchTasks,
    searchActivity,
    createView,
    editView,
    deleteView,
    getViews,
    searchViews,
    getProjectCount,
    getContractCount,
    getContactCount,
    getCompanyCount,
    getPipelineCount,
    getServerInfo,
    getServerInfoCompany,
    getCookie,
    //Drive endpoints
    getFolderContent,
    getFolderDetails,
    search,
    getBookmarks,
    getRecentFiles,
    getRecentfolders,
    getPathFolders,
    listFoldersLevels,
    getDocument,
    getOfficeOnlineUrl,
};

const getStoredData = (name: string) => {
    var cookie = Cookies.get(name)
    return cookie
}

function getCookie(name: string) {
    var cookie = Cookies.get(name)
    return cookie
}

async function deleteCookies(cookies) {
    for (let i = 0; i < cookies.length; i++) {
        await new Promise((resolve, reject) => {
            try {
                let cookie = cookies[i];
                Cookies.remove(cookie, { domain: domain, expires: '', sameSite: 'none', secure: true})
                Cookies.remove(cookie, { domain: 'workflow.'+domain, expires: '', sameSite: 'none', secure: true})
                resolve(true)
            } catch(error) {
                reject(error)
            }
        })
    }
}

const synergyApi = process.env.REACT_APP_SYNERGY_API_URL
const domain = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(1).join('.') : process.env.REACT_APP_DOMAIN;
var serverDomain = getStoredData("serverdomain") || "";
var membershipApi = synergyApi + serverDomain + process.env.REACT_APP_MEMBERSHIP_API_URL;

function getWopiDomain() {
    let wopiApi = 'https://wopi.synergy.page';
    if(serverDomain === "us-prod.synergy.page") wopiApi = "https://wopi-alpha.synergy.page"
    else if(serverDomain === "eu-dev.synergy.page") wopiApi = "https://wopi-beta.synergy.page"
    return wopiApi;
}

function getInstanceId() {
    var instanceId = Cookies.get('instanceId')
    
    if(!instanceId) {
        instanceId = "Origin-" + uuid()
        Cookies.set("instanceId", instanceId,{ domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    }
    
    if (!Cookies.get('instanceId')) {
        Cookies.set("instanceId", instanceId,{ domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    }

    return instanceId;
}

function addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }

  function toTimestamp(strDate: any){
    var datum = Date.parse(strDate);
    return datum;
 }

 function getTokenAAD(token, domain?) {
    const requestOptions: any = {
        domain: domain || localStorage.getItem('companyDomain'),
        token: token
    }

    return axios.post(membershipApi + '/v2/public/azure/exchange-token', requestOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleLoginResponse(error.response || error)
    });
}

function login(email, password, companyDomain: string, staySignedIn: boolean) {
    const requestOptions = {
        'application': 'Web',
        'domain': companyDomain,
        'email': email,
        'origin': getInstanceId(),
        'password': password,
        'staySignedIn': staySignedIn
    }

    return axios.post(membershipApi + '/v2/public/auth/login', requestOptions, {})
    .then(response => {
        return response;
    }).catch(error => {
        throw handleLoginResponse(error.response || error)
    });
}

function loginAAD(token, staySignedIn: boolean) {
    var app = 'Web'

    const headersOptions = {
        headers: { 'Authorization': token }
    };

    const requestOptions: any = {
        application: app,
        domain: localStorage.getItem('companyDomain'),
        origin: getInstanceId(),
        staySignedIn: staySignedIn
    }

    return axios.post(membershipApi + '/v2/azure/login', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleLoginResponse(error.response || error)
    });
}


function verifyEmailCode(token:string, code:string, staySignedIn:boolean) {
    let headersOptions = {
        headers: { 'Authorization': token }
    };

    let requestOptions = {
        "application": "Web",
        "key": code,
        "origin": getInstanceId(),
        "staySignedIn": staySignedIn
    }

    return axios.post(membershipApi + '/v2/auth/2fa/validate', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleLoginResponse(error.response || error)
    });
}

function resendVerifyEmailCode(email, password, companyDomain) {
    const requestOptions = {
        "domain": companyDomain,
        "email": email,
        "password": password
    }

    return axios.post(membershipApi + '/v2/public/auth/2fa/resend', requestOptions)
    .then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

async function afterLogin(response, staySignedIn){
    console.log(response)
    var token = response.data.accessToken;
    var daysToExpire = 9 //Used by wopi
    var expirationDate = addDays(new Date(), daysToExpire)
    var tokenttl = toTimestamp(expirationDate)
    if (staySignedIn) {
        Cookies.set('refreshKey', response.data.refreshToken, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    }

    var sessionExpirationDate = new Date();
    sessionExpirationDate.setDate(sessionExpirationDate.getDate() + sessionDuration);

    var cookies = Cookies.withConverter({
        write: function (value, name) {
            return value;
        }
    })
    console.log(token, domain)
    cookies.set('token', token, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    Cookies.set('tokenttl', tokenttl, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    Cookies.set("serverdomain", serverDomain, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})

    return response;
}

async function refreshToken() {    
    var instanceId = getInstanceId();
    var refreshKey = Cookies.get('refreshKey') || localStorage.getItem('refreshKey');
    console.log("Can refresh token", canRefreshToken)
    console.log("Refresh key", refreshKey)

    const requestOptions = {
        refreshToken: refreshKey
    }
    
    if (canRefreshToken && refreshKey) {
        canRefreshToken = false;
        await axios.post(membershipApi + '/v2/public/auth/refresh-token',  requestOptions)
        .then(response => {
            console.log('Token refreshed')
            var token = 'Bearer ' + response.data.accessToken;
            var daysToExpire = 9 //Used by wopi
            var expirationDate = addDays(new Date(), daysToExpire)
            var tokenttl = toTimestamp(expirationDate)
            Cookies.set('refreshKey', response.data.refreshToken, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
            Cookies.set('instanceId', instanceId, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})

            var sessionExpirationDate = new Date();
            sessionExpirationDate.setDate(sessionExpirationDate.getDate() + sessionDuration);

            var cookies = Cookies.withConverter({
                write: function (value, name) {
                return value;
                }
            })

            cookies.set('token', token, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
            Cookies.set('tokenttl', tokenttl, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
            Cookies.set("serverdomain", serverDomain, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})


            setTimeout(()=>{
                canRefreshToken = true;
            },10000)
            return response;
        }).catch(error => {
            setTimeout(()=>{
                canRefreshToken = true;
            },10000)
            logout();
            history.push('/login');
            console.log('Error trying to refresh token.')
            throw error;
        });
    }
    else if (!refreshKey) {
        logout();
        history.push('/login');
        console.log('Refresh key not present.')
    }
}

function setServerDomain(url: string) {
    var promise = new Promise((resolve, reject) => {
        serverDomain = url
        membershipApi =  synergyApi + serverDomain + process.env.REACT_APP_MEMBERSHIP_API_URL;
        Cookies.set("serverdomain", serverDomain, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
        resolve(url)
    })
    return promise
}

function getServerDomain() {
    return serverDomain
}

function logout() {
    const headersOptions:any = {
        headers: authHeader()
    };
    if (getStoredData("token")) axios.delete(membershipApi + '/v2/auth/logout', headersOptions)
    // remove company logos
    localStorage.removeItem('companyLogo1');
    localStorage.removeItem('companyLogo2');
    localStorage.removeItem('companyLogo3');

    // remove serverDomain condition
    var serverDomain = Cookies.get("serverdomain")
    if(serverDomain !== process.env.REACT_APP_SYNERGY_CLOUD_US_URL
        && serverDomain !== process.env.REACT_APP_SYNERGY_CLOUD_EU_URL) {
        deleteCookies(["serverdomain"])
    }
    localStorage.removeItem('refreshKey')
    deleteCookies(["token", "tokenttl", "refreshKey"]).then(response => {
        history.push('/login');
    }).catch(error => console.log(error))
    history.push('/login');
}

function getUserData() {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(membershipApi + '/v2/auth/introspect-token', headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        if(error.status === 404) {
            logout();
            if(history.location.pathname.indexOf("/appUpdate") === -1) {
                history.push('/login');
            }
            throw error.response;
        } else {
            throw handleResponse(error.response)
        }
    });
}

function getTemplate(repoId, entityTypes) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + '/workflow-service/template/search/' + repoId + '?entityTypes=' + entityTypes, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getCustomProperties(ids?: string, entityType?: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const params = new URLSearchParams(document.location.search);

    if (ids) params.set('properties',ids)
    if (entityType) params.set('entityType',entityType)
    let query = ids || entityType ? '?'+params.toString() : ''
    
    return axios.get(synergyApi + serverDomain + `/workflow-service/properties${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createCustomProperty(property) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + '/workflow-service/properties', property, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function editCustomProperty(id, property) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.put(synergyApi + serverDomain + `/workflow-service/properties/${id}`, property, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function reorderCustomProperties(entityType, properties) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.put(synergyApi + serverDomain + `/workflow-service/properties/type/${entityType}`, properties, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteCustomProperty(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/properties/${id}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createCompany(company) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + '/workflow-service/companies', company, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getCompanies(ids?) {
    const headersOptions:any = {
        headers: authHeader()
    };

    let companies = ids ? `?companies=${ids}` : '';

    return axios.get(synergyApi + serverDomain + `/workflow-service/companies${companies}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getCompany(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/companies/${id}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function editCompany(id, company) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.put(synergyApi + serverDomain + `/workflow-service/companies/${id}`, company, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteCompanies(ids) {
    const headersOptions:any = {
        headers: authHeader(),
        data: {
            ids: ids
        }
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/companies`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateCompanyRelations(id, relations) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/companies/${id}/associate`, relations, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createContact(contact) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + '/workflow-service/contacts', contact, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContacts(ids?) {
    const headersOptions:any = {
        headers: authHeader()
    };

    let contacts = ids ? `?contacts=${ids}` : '';

    return axios.get(synergyApi + serverDomain + `/workflow-service/contacts${contacts}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContact(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/contacts/${id}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function editContact(id, contact) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.put(synergyApi + serverDomain + `/workflow-service/contacts/${id}`, contact, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteContacts(ids: string[]) {
    const headersOptions:any = {
        headers: authHeader(),
        data: {
            ids: ids
        }
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/contacts`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateContactRelations(id, relations) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/contacts/${id}/associate`, relations, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function syncContacts() {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + '/workflow-service/contacts/sync', {}, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createProject(project) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + '/workflow-service/projects', project, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getProjects(ids?) {
    const headersOptions:any = {
        headers: authHeader()
    };

    let projects = ids ? `?projects=${ids}` : '';

    return axios.get(synergyApi + serverDomain + `/workflow-service/projects${projects}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getProject(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/projects/${id}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function editProject(id, project) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.put(synergyApi + serverDomain + `/workflow-service/projects/${id}`, project, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function editProjects(ids: string[], status: any, stage: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        ids : ids
    }
    if (status) requestOptions['status'] = status
    if (stage) requestOptions['stage'] = stage

    return axios.put(synergyApi + serverDomain + `/workflow-service/projects`, requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteProjects(ids) {
    const headersOptions:any = {
        headers: authHeader(),
        data: {
            ids: ids
        }
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/projects`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateProjectRelations(id, relations) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/projects/${id}/associate`, relations, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createContract(contract) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + '/workflow-service/contracts', contract, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContracts(ids?) {
    const headersOptions:any = {
        headers: authHeader()
    };

    let contracts = ids ? `?contracts=${ids}` : '';

    return axios.get(synergyApi + serverDomain + `/workflow-service/contracts${contracts}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContract(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/contracts/${id}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function editContract(id, contract) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.put(synergyApi + serverDomain + `/workflow-service/contracts/${id}`, contract, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteContracts(ids) {
    const headersOptions:any = {
        headers: authHeader(),
        data: {
            ids: ids
        }
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/contracts`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateContractRelations(id, relations) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/contracts/${id}/associate`, relations, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getPipelines(ids?) {
    const headersOptions:any = {
        headers: authHeader()
    };

    let pipelines = ids ? `?pipelines=${ids}` : '';

    return axios.get(synergyApi + serverDomain + `/workflow-service/pipelines${pipelines}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createPipeline(pipeline) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/pipelines` , pipeline, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function editPipeline(id, pipeline) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.put(synergyApi + serverDomain + `/workflow-service/pipelines/${id}`, pipeline, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deletePipeline(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/pipelines/${id}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createStages(id, stages) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/pipelines/${id}/stages` , stages, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function editStages(id, stages) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.put(synergyApi + serverDomain + `/workflow-service/pipelines/${id}/stages`, stages, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteStages(id, stages) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/pipelines/${id}/stages?stages=${stages}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getProjectEmails(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`
    
    return axios.get(synergyApi + serverDomain + `/workflow-service/projects/${id}/emails${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContractEmails(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`

    return axios.get(synergyApi + serverDomain + `/workflow-service/contracts/${id}/emails${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContactEmails(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`

    return axios.get(synergyApi + serverDomain + `/workflow-service/contacts/${id}/emails${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getCompanyEmails(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`

    return axios.get(synergyApi + serverDomain + `/workflow-service/companies/${id}/emails${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getProjectNotes(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`

    return axios.get(synergyApi + serverDomain + `/workflow-service/projects/${id}/notes${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContractNotes(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`
    
    return axios.get(synergyApi + serverDomain + `/workflow-service/contracts/${id}/notes${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContactNotes(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`
    
    return axios.get(synergyApi + serverDomain + `/workflow-service/contacts/${id}/notes${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getCompanyNotes(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`
    
    return axios.get(synergyApi + serverDomain + `/workflow-service/companies/${id}/notes${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getProjectConversations(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`

    return axios.get(synergyApi + serverDomain + `/workflow-service/projects/${id}/conversations${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContractConversations(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`
    
    return axios.get(synergyApi + serverDomain + `/workflow-service/contracts/${id}/conversations${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContactConversations(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`
    
    return axios.get(synergyApi + serverDomain + `/workflow-service/contacts/${id}/conversations${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getCompanyConversations(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`
    
    return axios.get(synergyApi + serverDomain + `/workflow-service/companies/${id}/conversations${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getEmails(queryString:string = '', page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999` + queryString

    return axios.get(synergyApi + serverDomain + `/workflow-service/emails${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getEmail(id:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/emails/${id}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getEmailContent(id:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/emails/${id}/content`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteEmails(ids:string) {
    const headersOptions:any = {
        headers: authHeader(),
        data: {
            ids: ids
        }
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/emails`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateEmailRelations(id, relations) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/emails/${id}/associate`, relations, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function downloadAttachment(emailId: string, attachmentId: string, download = false) {
    const headersOptions:any = {
        headers: authHeader(),
        responseType: download ? "blob" : "arraybuffer"
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/emails/${emailId}/attachments/${attachmentId}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function downloadConversationAttachment(conversationId: string, conversationMessageId: string, attachmentId: string, download = false) {
    const headersOptions:any = {
        headers: authHeader(),
        responseType: download ? "blob" : "arraybuffer"
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/conversations/${conversationId}/messages/${conversationMessageId}/attachments/${attachmentId}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function downloadTaskAttachment(taskId: string, taskMessageId:string, attachmentId: string, download = false) {
    const headersOptions:any = {
        headers: authHeader(),
        responseType: download ? "blob" : "arraybuffer"
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/tasks/${taskId}/messages/${taskMessageId}/attachments/${attachmentId}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function saveAttachment(formData: FormData) {
    const headersOptions:any = {
        headers: authHeader(),
        contentType: 'multipart/form-data',
        maxContentLength: Infinity,
        maxBodyLength: Infinity
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/attachments`, formData, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function saveConversationAttachmentToDrive(conversationId, conversationMessageId, attachmentId, folderParentId) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/conversations/${conversationId}/messages/${conversationMessageId}/attachments/${attachmentId}/drive-upload/${folderParentId}`, {}, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function saveTasksAttachmentToDrive(conversationId, conversationMessageId, attachmentId, folderParentId) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/tasks/${conversationId}/messages/${conversationMessageId}/attachments/${attachmentId}/drive-upload/${folderParentId}`, {}, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getNotes(queryString:string = '', page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999` + queryString

    return axios.get(synergyApi + serverDomain + `/workflow-service/notes${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getNote(id:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/notes/${id}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getNoteContent(id:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/notes/${id}/content`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getConversation(id:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/conversations/${id}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getConversationContent(id:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/conversations/${id}/content`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getConversationMessages(id:string, page: number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=50`

    return axios.get(synergyApi + serverDomain + `/workflow-service/conversations/${id}/messages${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createMessage(id:string, content:string, to:string[], cc: string[], bcc: string[], attachments:any[] = []) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        content: content,
        to: to,
        cc: cc,
        bcc: bcc,
        attachmentIds: attachments
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/conversations/${id}/messages`, requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateMessage(id:string, messageId: string, content:string, attachments:any[] = []) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        content: content,
        attachmentIds: attachments
    };

    return axios.put(synergyApi + serverDomain + `/workflow-service/conversations/${id}/messages/${messageId}`, requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteMessage(id:string, messageId: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/conversations/${id}/messages/${messageId}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createTaskMessage(id:string, content:string, to:string[], cc:string[], bcc:string[], attachments: string[]) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        content: content,
        to: to,
        cc: cc,
        bcc: bcc,
        attachmentIds: attachments
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/tasks/${id}/messages`, requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateTaskMessage(id:string, messageId: string, content:string, attachments: string[]) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const requestOptions:any = {
        content: content,
        attachmentIds: attachments
    };

    return axios.put(synergyApi + serverDomain + `/workflow-service/tasks/${id}/messages/${messageId}`, requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteTaskMessage(id:string, messageId: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/tasks/${id}/messages/${messageId}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createNote(subject:string, content:string, noteType:string, visibility:string, relations:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    var requestOptions:any = {
        subject: subject,
        noteType: noteType,
        content: content,
        visibility: visibility,
        associateEntityRequest: {}
    }

    if (relations) requestOptions.associateEntityRequest = relations

    return axios.post(synergyApi + serverDomain + '/workflow-service/notes', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateNote(id:string, subject:string, content:string, noteType:string, relations:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    var requestOptions:any = {
        subject: subject,
        noteType: noteType,
        content: content,
        associateEntityRequest: {}
    }

    if (relations) requestOptions.associateEntityRequest = relations

    return axios.put(synergyApi + serverDomain + `/workflow-service/notes/${id}`, requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteNotes(ids:string) {
    const headersOptions:any = {
        headers: authHeader(),
        data: {
            ids: ids
        }
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/notes`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateNoteRelations(id, relations) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/notes/${id}/associate`, relations, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getConversations(queryString:string = '', page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999` + queryString

    return axios.get(synergyApi + serverDomain + `/workflow-service/conversations${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createConversation(subject:string, content:string, contacts: any, contactsCopy: any, noteType:string, visibility:string, relations:any, to: any, cc: any, attachmentIds) {
    const headersOptions:any = {
        headers: authHeader()
    };

    var requestOptions:any = {
        subject: subject,
        conversationType: noteType,
        content: content,
        to: to,
        cc: cc,
        visibility: visibility,
        attachmentIds: attachmentIds,
        associateEntityRequest: {}
    }

    if (relations) requestOptions.associateEntityRequest = relations

    return axios.post(synergyApi + serverDomain + '/workflow-service/conversations', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}
function updateConversation(id: string, subject:string, noteType:string, relations:any, to: any, cc: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    var requestOptions:any = {
        subject: subject,
        conversationType: noteType,
        to: to,
        cc: cc,
        associateEntityRequest: {}
    }

    if (relations) requestOptions.associateEntityRequest = relations

    return axios.put(synergyApi + serverDomain + `/workflow-service/conversations/${id}`, requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteConversations(ids:string) {
    const headersOptions:any = {
        headers: authHeader(),
        data: {
            ids: ids
        }
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/conversations`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateConversationRelations(id, relations) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/conversations/${id}/associate`, relations, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getTask(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/tasks/${id}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getTasks(queryString:string = '', page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999` + queryString

    return axios.get(synergyApi + serverDomain + `/workflow-service/tasks${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getTaskMessages(id:string, page: number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=50`

    return axios.get(synergyApi + serverDomain + `/workflow-service/tasks/${id}/messages${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getProjectTasks(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`

    return axios.get(synergyApi + serverDomain + `/workflow-service/projects/${id}/tasks${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContractTasks(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`
    
    return axios.get(synergyApi + serverDomain + `/workflow-service/contracts/${id}/tasks${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContactTasks(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`
    
    return axios.get(synergyApi + serverDomain + `/workflow-service/contacts/${id}/tasks${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getCompanyTasks(id:string, page:number = 0) {
    const headersOptions:any = {
        headers: authHeader()
    };

    const query = `?page=${page}&size=9999`
    
    return axios.get(synergyApi + serverDomain + `/workflow-service/companies/${id}/tasks${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createTask(requestOptions:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + '/workflow-service/tasks', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateTask(id:string, requestOptions:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.put(synergyApi + serverDomain + `/workflow-service/tasks/${id}`, requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteTasks(ids:string) {
    const headersOptions:any = {
        headers: authHeader(),
        data: {
            ids: ids
        }
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/tasks`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateTaskRelations(id, relations) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/tasks/${id}/associate`, relations, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getPermissions(id?:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    let query = id ? '/'+id : '';

    return axios.get(synergyApi + serverDomain + `/workflow-service/permissions${query}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updatePermissions(permissions:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/permissions`, permissions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateProjectPermissions(id :string, permissions:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/projects/${id}/permissions`, permissions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateContractPermissions(id :string, permissions:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/contracts/${id}/permissions`, permissions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateContactPermissions(id :string, permissions:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/contacts/${id}/permissions`, permissions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateCompanyPermissions(id :string, permissions:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/companies/${id}/permissions`, permissions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateNotePermissions(id :string, permissions:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/notes/${id}/permissions`, permissions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}
function updateNoteVisibility(id :string, visibility:string, itemId?:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    let query = itemId ? `?itemId=${itemId}` : ''

    return axios.post(synergyApi + serverDomain + `/workflow-service/notes/${id}/permissions/${visibility}` + query, {}, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateEmailPermissions(id :string, permissions:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/emails/${id}/permissions`, permissions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateEmailVisibility(id :string, visibility:string, itemId?:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    let query = itemId ? `?itemId=${itemId}` : ''

    return axios.post(synergyApi + serverDomain + `/workflow-service/emails/${id}/permissions/${visibility}` + query, {}, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateConversationPermissions(id :string, permissions:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/conversations/${id}/permissions`, permissions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateConversationVisibility(id :string, visibility:string, itemId?:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    let query = itemId ? `?itemId=${itemId}` : ''

    return axios.post(synergyApi + serverDomain + `/workflow-service/conversations/${id}/permissions/${visibility}` + query, {}, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateTaskPermissions(id :string, permissions:any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/tasks/${id}/permissions`, permissions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateTaskVisibility(id :string, visibility:string, itemId?:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    let query = itemId ? `?itemId=${itemId}` : ''

    return axios.post(synergyApi + serverDomain + `/workflow-service/tasks/${id}/permissions/${visibility}` + query, {}, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getRepoUsers(repositoryRole: string, users?:string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    let userQuery = users ? ('&user='+users) : '';
    let role = repositoryRole === 'ALL' ? '' : ('&role='+repositoryRole)

    return axios.get(membershipApi + '/v2/users?state=ACTIVE'+userQuery+role, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getRepoGroups(repositoryRole = '') {
    const headersOptions:any = {
        headers: authHeader()
    };

    let role = '?role='+repositoryRole

    return axios.get(membershipApi + '/v2/groups' + role, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchProjects(query: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/projects/search${query}&size=9999`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchContracts(query: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/contracts/search${query}&size=9999`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchContacts(query: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/contacts/search${query}&size=9999`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchCompanies(query: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/companies/search${query}&size=9999`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchNotes(query: string, options) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/notes/search${query}&size=9999`, options, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchEmails(query: string, options) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/emails/search${query}&size=9999`, options, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchConversations(query: string, options) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/conversations/search${query}&size=9999`, options, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchMessages(query: string, options) {
    const headersOptions:any = {
        headers: authHeader()
    };
    
    return axios.post(synergyApi + serverDomain + `/workflow-service/conversations/search-messages${query}&size=9999`, options, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchTaskMessages(query: string, options) {
    const headersOptions:any = {
        headers: authHeader()
    };
    
    return axios.post(synergyApi + serverDomain + `/workflow-service/tasks/search-messages${query}&size=9999`, options, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchTasks(query: string, options) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/tasks/search${query}&size=9999`, options, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchActivity(query: string, options) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/activity-feed/v2/activity/search${query}`, options, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function createView(options: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/views`, options, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function editView(id: string, options: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.put(synergyApi + serverDomain + `/workflow-service/views/${id}`, options, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function deleteView(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.delete(synergyApi + serverDomain + `/workflow-service/views/${id}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getViews(itemtype: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/views/quick-access/${itemtype}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchViews(options: any) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(synergyApi + serverDomain + `/workflow-service/views/search`, options, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getProjectCount(query = '') {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/projects/count${query}`, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getContractCount() {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/contracts/count`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getContactCount() {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/contacts/count`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getCompanyCount() {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/companies/count`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getPipelineCount() {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + `/workflow-service/pipelines/count`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getServerInfo(companyDomain: string) {
    return axios.get('https://synergy-api.common.synergy.page/server-info/public/'+companyDomain)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getServerInfoCompany(companyDomain: string) {
    return axios.get('https://synergy-api.common.synergy.page/server-info/public/company/'+companyDomain)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function handleLoginResponse(response) {
    if (response) {
        if (response.status === 405) {
            window.postMessage(
                {
                    msg: "showServiceDialog",
                    dialogMessage: i18n.t('app:noPermission')
                }
                , '*');
            throw response;
        } else if (response.status === 503) {
            // Maintenance mode
            window.postMessage(
            {
                msg: "maintenance"
            }
            , '*');
            throw response;
        } else {
            throw response;
        }
    }
}

function handleResponse(response:any) {
    if(response) {
        if (response.status === 403) {
            // auto logout if 401 or 403 response returned from api
            logout();
            history.push('/login');
            throw response;
        } else if (response.status === 405) {
            alert(i18n.t('app:noPermission'))
            throw response;
        } else if (response.status === 503) {
            // Maintenance mode
            window.postMessage(
            {
                msg: "maintenance"
            }
            , '*');
            throw response;
        } else {
            throw response;
        }
    }
    
    if(!response) {
        return 'Error: Network Error';
    }
}


//Drive services
function getFolderContent(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + '/artifact/folders/search/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {        
        throw handleResponse(error.response)
    });
}

function getFolderDetails(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + '/artifact/folders/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function search(query: string) {
    const headersOptions:any = {
        headers: authHeader(),
    };

    return axios.get(synergyApi + serverDomain + '/search/search' + query, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getBookmarks(repoId) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + '/artifact/bookmarks/getMyBookmarks?reposIds=' + repoId, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getRecentFiles() {
    const headersOptions:any = {
        headers: authHeader()
    }

    return axios.get(synergyApi + serverDomain + `/activity-feed/activity-feed/recentfiles`,headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getRecentfolders() {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + '/activity-feed/activity-feed/recentfolders', headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getPathFolders(id: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + '/artifact/paths/folders/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function listFoldersLevels(id:string, levels: number) {
    const headersOptions:any = {
        headers: authHeader()
    }

    return axios.get(synergyApi + serverDomain + `/artifact/folders/search/`+ id + `/levels/`+ levels, headersOptions)
        .then(response => {
            return response;
        }).catch(error => {
            throw handleResponse(error.response)
        });
}

function getDocument(id) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.get(synergyApi + serverDomain + '/artifact/documents/byid/' + id, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getOfficeOnlineUrl(fileId: string, action: string) {
    const headersOptions:any = {
        headers: authHeader()
    };

    return axios.post(getWopiDomain() + `/wopi/access_info_url?fileId=${fileId}&action=${action}`, null, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}