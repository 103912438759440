import { Dialog, DialogType, Icon, Spinner} from '@fluentui/react';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import * as React from 'react';
import { userService } from '../_services/user.service';
import { PdfRoot } from './_pdfViewer/PdfRoot';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import * as DOMPurify from 'dompurify';
import Cookies from 'js-cookie'
import Viewer from 'react-viewer';

const getStoredData = (name: string) => {
  var cookie = Cookies.get(name)
  return cookie
}

const wopiTypes = [
  "doc",
  "docx",
  "ppt",
  "pptx",
  "xls",
  "xlsm",
  "xlsx"
]

const imageTypes = [
  "png",
  "jpeg",
  "jpg",
  "svg"
]

const textTypes = [
  "txt",
  "json",
  "log"
]

const markdownTypes = [
  "md",
  "mdx",
  "markdown"
]

export interface Props {
  callback:any;
  item: any;
}

export interface States {
  hidden: boolean,
  loading: boolean,
  selFile: any;
  showImage: boolean;
}

export class PreviewDialog extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      selFile: null,
      showImage: false,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    const fileExtension = this.props.attachment.name.split('.').pop()
    if (this.props.attachment.file) {
      if (markdownTypes.includes(fileExtension)) {
        getAsByteArray(this.props.attachment.file).then((res) => {
          const byteFile = res
          let string = new TextDecoder().decode(byteFile);
          this._isMounted && this.setState({selFile: string})
        })
      } else if (imageTypes.includes(fileExtension)) {
        console.log(this.props.attachment)
        this._isMounted && this.setState({selFile: URL.createObjectURL( this.props.attachment.file )}, ()=> {
          this._isMounted && this.setState({showImage: true})
        })
      } else {
        console.log(this.props.attachment)
        this._isMounted && this.setState({selFile: this.props.attachment.file})
      }
    } else if (this.props.type === "CONVERSATION_ATTACHMENT") {
      userService.downloadConversationAttachment(this.props.item.id, this.props.message.id, this.props.attachment.id).then((response)=>{
        const byteArray = new Uint8Array(response.data);
        if (markdownTypes.includes(fileExtension)) {
          let string = new TextDecoder().decode(byteArray);
          this._isMounted && this.setState({selFile: string});
        } else if (wopiTypes.includes(fileExtension) && this.props.attachment.driveFileId) {
          userService.getOfficeOnlineUrl(this.props.attachment.driveFileId, 'embedview').then((response:any) => {
            let token = getStoredData('token');
            let tokenttl = getStoredData('tokenttl');
            let previewData = response.data + '&token=' + token + '&tokenttl=' + tokenttl
            this._isMounted && this.setState({selFile: previewData});
          })
        } else {
          const blob = new Blob([byteArray], { type: this.props.attachment.contentType });
          this._isMounted && this.setState({selFile: URL.createObjectURL( blob )}, ()=> {
            this._isMounted && this.setState({showImage: true})
          })
        }
      }).catch((error)=>{
        console.log(error)
      })
    } else if (this.props.type === "TASK_ATTACHMENT") {
      userService.downloadTaskAttachment(this.props.item.id, this.props.message.id, this.props.attachment.id).then((response)=>{
        const byteArray = new Uint8Array(response.data);
        if (markdownTypes.includes(fileExtension)) {
          let string = new TextDecoder().decode(byteArray);
          this._isMounted && this.setState({selFile: string});
        } else if (wopiTypes.includes(fileExtension) && this.props.attachment.driveFileId) {
          userService.getOfficeOnlineUrl(this.props.attachment.driveFileId, 'embedview').then((response:any) => {
            let token = getStoredData('token');
            let tokenttl = getStoredData('tokenttl');
            let previewData = response.data + '&token=' + token + '&tokenttl=' + tokenttl
            this._isMounted && this.setState({selFile: previewData});
          })
        } else {
          const blob = new Blob([byteArray], { type: this.props.attachment.contentType });
          this._isMounted && this.setState({selFile: URL.createObjectURL( blob )}, ()=> {
            this._isMounted && this.setState({showImage: true})
          })
        }
      }).catch((error)=>{
        console.log(error)
      })
    }

    
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      setTimeout(()=>this.props.callbackFunction(),200)
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private close() {
    this._isMounted && this.setState({hidden:true})
  }

  public render() {
    const fileExtension = this.props.attachment.name.split('.').pop()

    return (
      <div>
      <Dialog
        hidden={this.state.hidden}
        dialogContentProps={{
          type: DialogType.close,
          title: <div className='d-flex align-items-center' style={{height: '30px', fontSize: '18px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}><Icon className='activity-icon mr-3' style={{width: '32px', minWidth: '32px'}} {...getFileTypeIconProps({ extension: fileExtension, size: 32, imageFileType: 'svg' }) } />{this.props.attachment.name}</div>,
          onDismiss: ()=> {this.close()},
          styles: {innerContent: {flexGrow: 1}, content: {display: 'flex', flexDirection: 'column', height: '100%'}}
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: { maxWidth: 640 } },
          dragOptions: undefined,
          className: "form-dialog-high",
          onDismiss: ()=> {this.close()}
        }}
      >
        <FluentProvider className='h-100' theme={webLightTheme}>
          { this.state.selFile ? 
            fileExtension === 'pdf' ? 
              <PdfRoot selFile={this.state.selFile}/> 
            : textTypes.includes(fileExtension) ? 
              <div style={{height: 'calc(100% - 44px)'}}>
                <iframe id="preview-frame" title="preview" onLoad={()=>{let iframe:any = document.getElementById('preview-frame'); if (iframe) {iframe.contentWindow.document.body.style.backgroundColor = 'white'; iframe.contentWindow.document.body.style.color = 'black'}}} src={this.state.selFile} frameBorder='0' style={{border:0, top:'0px', left:'0px', bottom:'0px', right:'0px', width:'100%', height:'100%', backgroundColor: 'white', colorScheme: 'light'}}></iframe>
              </div>
            : markdownTypes.includes(fileExtension) ? 
              <div className='markdown-frame'  style={{height: 'calc(100% - 44px)', justifyContent: 'start', maxWidth: '100%'}}>
                <div className='markdown-body' style={{width: '100%'}} dangerouslySetInnerHTML={this.convertToMarkdown(this.state.selFile)}>
                </div>
              </div>
            : wopiTypes.includes(fileExtension) && this.props.attachment.driveFileId ? 
              <div className='h-100'>
                <iframe id="preview-frame" title="preview" src={this.state.selFile} frameBorder='0' style={{border:0, top:'0px', left:'0px', bottom:'0px', right:'0px', width:'100%', height:'100%'}}></iframe>
              </div>
            : imageTypes.includes(fileExtension) ? 
            <div style={{height: 'calc(100% - 44px)'}}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: "relative",
                  overflow: "hidden"
                }}
              >
                <div id="container" style={{width: '100%', height: '100%', margin: '0 auto'}} />
                <Viewer
                  visible={this.state.showImage}
                  images={[{src: this.state.selFile, alt: 'Preview'}]}
                  noClose={true}
                  noNavbar={true}
                  drag={this.props.fullscreen}
                  showTotal={false}
                  //noToolbar={true}
                  container={document.getElementById("container")!}
                />
              </div>
            </div> 
            : <div className='w-100 h-100 d-flex'><span className='m-auto'>Can't preview this file</span></div> 
            : <div className='w-100 h-100 d-flex'><Spinner className='m-auto'/></div>
          }
        </FluentProvider>
      </Dialog>
      </div>
    )
  }

  private _getFileType(doc, fileExtension): { docType: string; icon: string } {
    var kind = doc.kind;

    if (kind === "dir") {
      const docType: string = "dir"
      return {
        docType,
        icon: "folder"
      }
    } else {
      const docType: string = "file"
      if (fileExtension === "mhtml") {
        fileExtension = "html";
      }
      if (fileExtension === "email") {
        fileExtension = "eml";
      }
      if (fileExtension === "shortcut") {
        fileExtension = "shortcut";
      }
      if (fileExtension === "bookmark") {
        fileExtension = "url";
      }
      if (fileExtension === "mdx") {
        fileExtension = "md";
      }

      return {
        docType,
        icon: fileExtension
      }
    }
  }

  private getFile() {
    const id = this.props.match['params'].id
    if (id && id.startsWith('File-')) {
      userService.getDocument(id).then((response)=>{
        if (id === this.props.match['params'].id) {
          let doc = response.data;
          const fileType = this._getFileType(doc, doc.type);
          doc.icon = fileType.icon;
          doc.fileExtension = doc.type;
          doc.activeRevisionId = doc.activeRevision
          //this._isMounted && this.setState({selFile: doc, revId: this.props.match['params'].rev, docId: this.props.match['params'].id})
        }
      }).catch((error)=>{
        console.log(error)
      })
    }
  }

  private convertToMarkdown(text) {
    try {
      var MarkdownIt = require('markdown-it');
      var emoji = require('markdown-it-emoji');
      var md = new MarkdownIt();
      md.use(emoji);
      var result = md.render(DOMPurify.sanitize(text));
      let markdown:any = { __html: result };
      return markdown;
    } catch {
      return text;
    }

  }

}

function readFile(file):any {
  return new Promise((resolve, reject) => {
    // Create file reader
    let reader = new FileReader()

    // Register event listeners
    reader.addEventListener("loadend", (e:any) => resolve(e.target.result))
    reader.addEventListener("error", reject)

    // Read file
    reader.readAsArrayBuffer(file)
  })
}

async function getAsByteArray(file) {
  return new Uint8Array(await readFile(file))
}
