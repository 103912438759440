import * as React from 'react';
import { history } from '../_helpers';
import { Resizable } from 're-resizable';
import { Nav, INavLink } from '@fluentui/react/lib/Nav';
import { ActionButton, ChoiceGroup, ConstrainMode, DefaultButton, DetailsListLayoutMode, IDetailsHeaderProps, IRenderFunction, SelectionMode, ShimmeredDetailsList, ThemeProvider, TooltipHost,} from '@fluentui/react';
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { Icon } from '@fluentui/react/lib/Icon';
import { IconButton, IIconProps } from '@fluentui/react';
import {createSelector} from 'reselect';
import Moment from 'moment';
import { userService } from '../_services';
import * as conversationsTypes from '../_data/conversations-types';
import i18n from 'i18next';
import { FilterColumn } from './FilterColumn';
import Tree from 'react-virtualized-tree';
import { Input, Label, Link, Persona } from '@fluentui/react-components';
import viewItemType from '../_constants/viewItemType.json'

export const getNodeRenderOptions = createSelector(
  (node: any) => (node.state || {}).expanded,
  node => (node.state || {}).favorite,
  node => (node.state || {}).deletable,
  node => node.hasChildren,
  node => node.id,
  node => node.type,
  node => node.hidden,
  (expanded, favorite, deletable, hasChildren, id, type, hidden = []) => ({
    hasChildren: !!hasChildren,
    isExpanded: !!expanded,
    isFavorite: !!favorite,
    isDeletable: !!deletable,
    id: id,
    type: type,
    hidden: !!hidden
  }),
);

export const updateNode = (originalNode, newState: any) =>
  ({
  
  node: {
    ...originalNode,
    state: {
      ...originalNode.state,
      ...newState,
    },
  },
  type: "2" //UPDATE
});

export interface Props {
  match: {
    url: string
  },
  location: any,
  searchType: string,
  userData: any,
  page: string,
  items: any,
  listExplorerRef: any,
}

export interface SidebarState {
  webUpdateAvailable: boolean;
  userData?: any;
  repoData?: any;
  itemTree: any[];
  customViews: any[];
  selItem: any;
  panelHeight: any;
  panelWidth: any;
  panel2Width: any;
  searchFilters: any;
  smartFoldersTree: any;
  tags: any;
  editTagDialog: boolean;
  selTag: any;
  newTag: string;
  selNav: string;
  firstSelNav: string;
  savingTag: boolean;
  selAction: string;
  minDate: Date;
  maxDate: Date;
  initialDate: Date;
  finalDate: Date;
  goBack: number;
  userName: string;
  settingsMenu: boolean;
  personaMenu: boolean;
  language: string;
  toolbarHeight: number;
  filterColumnOpen: boolean;
  filterName: string;
  searchQuery: string;
  views: any[];
  item: any;

  relationsCompanies: boolean;
  relationsContracts: boolean;
  relationsContacts: boolean;
  relationsProjects: boolean;
  currentFilters: any[];
  currentProjects: any[];
  currentContracts: any[];
  currentContacts: any[];
  currentCompanies: any[];
  showCurrentFilters: boolean;
  showCurrentProjects: boolean;
  showCurrentContracts: boolean;
  showCurrentContacts: boolean;
  showCurrentCompanies: boolean;
  searchNickname: string;
  searchDomain: string;
}

export class Sidebar extends React.Component<Props, SidebarState, { t:any, i18n: any }> {
  private _isMounted: boolean;
  private _windowWidth: number;
  private filtersAdded = false;

  private itemTypes = [
    {
      key: 'note',
      id: 'note',
      tag: 'note',
      name: i18n.t('app:note'),
      type: "tagType",
      iconName: 'QuickNote'
    },
    {
      key: 'email',
      id: 'email',
      tag: 'email',
      name: i18n.t('app:email'),
      type: "tagType",
      iconName: 'Mail'
    },
    {
      key: 'conversation',
      id: 'conversation',
      tag: 'conversation',
      name: i18n.t('app:conversation'),
      type: "tagType",
      iconName: 'OfficeChat'
    },
    {
      key: 'task',
      id: 'task',
      tag: 'task',
      name: 'Tasks',
      type: "tagType",
      iconName: 'IconSetsFlag'
    },
    {
      key: 'activity',
      id: 'activity',
      tag: 'activity',
      name: i18n.t('app:activity'),
      type: "tagType",
      iconName: 'LineChart'
    },
    {
      key: 'meeting',
      id: 'meeting',
      tag: 'meeting',
      name: 'Meetings/Calls',
      type: "tagType",
      iconName: 'ActivityFeed'
    },
    {
      key: 'file',
      id: 'file',
      tag: 'file',
      name: 'Files',
      type: "tagType",
      iconName: 'Documentation'
    }
  ]

  private projectStatusTypes = [
    {
      key: 'OPEN',
      id: 'OPEN',
      tag: 'OPEN',
      name: i18n.t('app:open'),
      type: "tagType",
      iconName: 'Work'
    },
    {
      key: 'CLOSE',
      id: 'CLOSE',
      tag: 'CLOSE',
      name: i18n.t('app:close'),
      type: "tagType",
      iconName: 'Work'
    }
  ]

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._windowWidth = window.innerWidth;

    var panelWidth = "240px"
    if (typeof(localStorage.getItem("sidebarPrefs")) === "string") {
      let panelPrefs = JSON.parse(localStorage.getItem("sidebarPrefs") || "[]");
      panelWidth = panelPrefs.width
    }

    var panel2Width = "240px"
    if (typeof(localStorage.getItem("sidebar2Prefs")) === "string") {
      let panelPrefs = JSON.parse(localStorage.getItem("sidebar2Prefs") || "[]");
      panel2Width = panelPrefs.width
    }

    this.state = {
      webUpdateAvailable: false,
      userData: null,
      repoData: null,
      goBack: 0,
      itemTree: [],
      customViews: [],
      selItem: null,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      panel2Width: panel2Width,
      searchFilters: [],
      smartFoldersTree: null,
      tags: null,
      editTagDialog: false,
      selTag: null,
      newTag: '',
      savingTag: false,
      selAction: "allActions",
      minDate: new Date(2020,0,1),
      maxDate: new Date(Date.now()),
      initialDate: new Date(2020,0,1),
      finalDate: new Date(Date.now()),
      selNav: props.closedByDefault ? '' : this._getSelNav(),
      firstSelNav: this._getSelNav(),
      userName: "",
      settingsMenu: false,
      personaMenu: false,
      language: i18n.language,
      toolbarHeight: 89,
      filterColumnOpen: false,
      filterName: '',
      searchQuery: '',
      views: [],
      item: null,

      relationsCompanies: true,
      relationsContracts: true,
      relationsContacts: true,
      relationsProjects: true,
      currentFilters: [],
      currentProjects: [],
      currentContracts: [],
      currentContacts: [],
      currentCompanies: [],
      showCurrentFilters: true,
      showCurrentProjects: false,
      showCurrentContracts: false,
      showCurrentContacts: false,
      showCurrentCompanies: false,
      searchNickname: '',
      searchDomain: '',
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    window.addEventListener("message", this.onMessageReceived.bind(this), false);

    var repoData = this.props.userData && this.props.userData.repository;
    if(repoData) {
      let views:any = this.getViews()
      Promise.all([views]).then((res)=>{
        let customViews = res[0]
        this._isMounted && this.setState({
          userData: this.props.userData,
          userName: this.props.userData.user.name + " " + this.props.userData.user.surname,
          repoData: repoData,
          views: customViews
        }, () => {
          this.getSidebarTree()
        })
      })

    }
    
    const self = this;
    if (window.innerWidth <= 980) {
      self.toggleNav("")
    }
    window.addEventListener('resize', function() {
      var toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
      self._isMounted && self.setState({toolbarHeight: toolbar!.offsetHeight})
      if (window.innerWidth <= 980 && window.innerWidth < self._windowWidth) {
        self.toggleNav("")
      }
      self._windowWidth = window.innerWidth;
    })
    
  }
  
  public componentDidUpdate(prevProps:any, prevState:any) {
    if (this.state.language !== i18n.language) {
      this._isMounted && this.setState({language: i18n.language});
    }

    if(this.props.page !== prevProps.page || this.props.userData !== prevProps.userData || this.props.pipelines !== prevProps.pipelines || this.state.searchQuery !== document.location.search) {
      var repoData = this.props.userData && this.props.userData.repository;
      if(repoData) {
        this._isMounted && this.setState({
          searchQuery: document.location.search,
        })
        if (this.props.userData !== prevProps.userData) {
          let views:any = this.getViews()
          Promise.all([views]).then((res)=>{
            let customViews = res[0]
            this._isMounted && this.setState({
              selNav: this.props.closedByDefault ? '' : this._getSelNav(),
              firstSelNav: this._getSelNav(),
              userData: this.props.userData,
              userName: this.props.userData.user.name + " " + this.props.userData.user.surname,
              repoData: repoData,
              views: customViews
            }, () => {
              this.getSidebarTree()
            })
          })
        } else {
          this._isMounted && this.setState({
            selNav: this.props.closedByDefault ? '' : this._getSelNav(),
            firstSelNav: this._getSelNav(),
            userData: this.props.userData,
            userName: this.props.userData.user.name + " " + this.props.userData.user.surname,
            repoData: repoData
          }, () => {
            this.getSidebarTree()
          })
        }

      }
    }

  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  onMessageReceived(event:any) {
    var data:any = event.data;

      if (data && data.msg === "webUpdateAvailable") {
        this._isMounted && this.setState({webUpdateAvailable: true})
      }
  }
  
  private _goBack() {
    history.back()
  }

  private getSidebarTree() {
    if(this.props.page === "projectsExplorer"
    || this.props.page === "clientsExplorer"
    || this.props.page === "milestonesExplorer"
    || this.props.page === "boardExplorer"
    || this.props.page === "pipelinesExplorer") {
      this._getProjectsTree();
    }
    else if (this.props.page === "projectData") {
      this._getProjectViewsTree();
    }
    else if(this.props.page === "contractsExplorer" || this.props.page === "contractsDashboard") {
      this._getContractsTree();
    }
    else if (this.props.page === "contractData") {
      this._getContractViewsTree();
    }
    else if (this.props.page === "companiesExplorer" || this.props.page === "companiesExplorerTrash"  || this.props.page === 'companiesPreview') {
      this._getCompaniesTree();
    }
    else if (this.props.page === "companyData") {
      this._getCompanyViewsTree();
    }
    else if (this.props.page === "contactsExplorer" || this.props.page === "contactsExplorerTrash") {
      this._getContactsTree();
    }
    else if (this.props.page === "contactData") {
      this._getContactViewsTree();
    }
    else if (this.props.page === "notes" || this.props.page === 'notesPreview') {
      this._getNotesTree();
    }
    else if (this.props.page === "conversations" || this.props.page === 'conversationsPreview') {
      this._getConversationsTree();
    }
    else if (this.props.page === "home") {
      this._getHomeTree();
    }
    else if (this.props.page === "pipelines") {
      this._getPipelinesTree();
    }
    else if (this.props.page === "search") {
      this._getSearchTree();
    }
    else if (this.props.page === "tasks" || this.props.page === "tasksPreview") {
      this._getTasksTree();
    }
    else if (this.props.page === "permissions") {
      this._getPermissionsTree();
    }
    else if (this.props.page === "timeTracker" || this.props.page === "timeSheet") {
      this._getTimeTrackingTree();
    }
    else if (this.props.page === "customFieldsProject" 
    || this.props.page === "customFieldsContract" 
    || this.props.page === "customFieldsContact" 
    || this.props.page === "customFieldsCompany") {
      this._getCustomFieldsTree();
    }
    else if (this.props.page === "emails" || this.props.page === 'emailsPreview') {
      this._getEmailsTree();
    }
    else if (this.props.page === "views") {
      this._getViewsTree();
    }
  }

  private _goBackToList() {
    const viewsNav = this.getViewsTitle()
    if(this.state.selNav === "projects" || viewsNav === "projects") history.push("/projects/pipeline")
    if(this.state.selNav === "contracts" || viewsNav === "contracts") history.push("/contracts")
    if(this.state.selNav === "contacts" || viewsNav === "contacts") history.push("/contacts")
    if(this.state.selNav === "companies" || viewsNav === "companies") history.push("/companies")
    if(this.state.selNav === "pipelines" || viewsNav === "pipelines") history.push("/pipelines")
    if(this.state.selNav === "notes" || viewsNav === "notes") history.push("/notes")
    if(this.state.selNav === "emails" || viewsNav === "emails") history.push("/emails")
    if(this.state.selNav === "conversations" || viewsNav === "conversations") history.push("/conversations")
    if(this.state.selNav === "tasks" || viewsNav === "tasks") history.push("/tasks")
    if(this.state.selNav === "permissions" || viewsNav === "permissions") history.push("/permissions")
  }

  private tree(title: string) {
    return <>
      <Resizable
      size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
      onResizeStop={(e, direction, ref, d) => {
        var currentWidth = this.state.panelWidth.match(/\d+/)[0];
        this._isMounted && this.setState({
          panelWidth: +currentWidth + +d.width + "px"
        }, () => {
          this._saveResizeState();
        });
      }}
      enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
      minWidth={240}
      maxWidth={480}
      handleStyles= {{
        right: {
          "right": "-6px",
          "width": "6px",
          "zIndex": 1
        }
      }}
      handleClasses={{"right": "resize-handle"}}
    >
      <nav className="tree">
        { this.props.page === 'views' ? <DefaultButton style={{position: 'absolute', zIndex: 1}} className="d-flex align-items-center mt-2 p-1 hover-button w-100" onClick={ this._goBackToList.bind(this) }  >
            <Icon className='ml-1 mr-2 pr-1' iconName='Back'/>
            <span className="title mb-0">{i18n.t('app:'+title)}</span>
          </DefaultButton>
        :
          <p className="title mb-0">{i18n.t('app:'+title)}</p>  
        }
        <ul className="ms-Nav-navItems list-page h-100 mx-0 mb-0 pl-0">
        <Tree
          nodes={this.state.itemTree}
          onChange={this.handleNodesChange}>
          {({onChange, style, node, index, ...rest}) => {

            const {hasChildren, isExpanded} = getNodeRenderOptions(node);
            const handleChange = () =>{
              onChange({...updateNode(node, {expanded: !isExpanded}) })
            }
            if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
            if(typeof(style.marginLeft) === "number") style.marginLeft = 0
            let item:any = node;
            return(item.type === 'separator' ? <>
              <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                  <hr/>
              </li>
            </>
            : item.type === 'separatorFilters' ? <>
              <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default', height: '61px'}} tabIndex={index}>
                  <div className='d-flex align-items-center pb-2'>
                      <p className='m-0 py-0'>{i18n.t('app:filters')}</p>
                      <TooltipHost key={'tag-tt-toggle-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight: window.location.search ? '42px' : '10px',  height: '32px'}}} content={i18n.t('app:expand/collapse')} setAriaDescribedBy={false}>
                        <button onClick={(ev)=>{this.toggleFilters()}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon iconName={this.state.itemTree[4] && (this.state.itemTree[4].isExpanded || this.state.itemTree[4].state.expanded) ? 'CollapseContent' : 'ExploreContent'}/></button>
                      </TooltipHost>
                      {window.location.search && <TooltipHost key={'tag-tt-remove-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '32px'}}} content={i18n.t('app:remove')} setAriaDescribedBy={false}>
                        <button onClick={(ev)=>{history.push('')}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon iconName='Delete'/></button>
                      </TooltipHost>}
                  </div>
              </li>
            </>
            : item.type === 'searchFilters' ?
              item.id === 'domainFilter' ?
                <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default', height: '61px'}} tabIndex={index}>
                  <ActionButton text={i18n.t('app:filterByDomain')} iconProps={{iconName: 'Add'}}/>
                </li>
              : item.id === 'searchType' ?
                <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                  <ChoiceGroup selectedKey={this.props.searchType} className="mx-3"
                    onChange={(ev, item: any)=>{
                      this.props.callbackFunction({searchType: item.key})
                    }}
                    options={[
                      { key: 'PROJECT', text: i18n.t('app:projects') },
                      { key: 'CONTRACT', text: i18n.t('app:contracts') },
                      { key: 'CONTACT', text: i18n.t('app:contacts') },
                      { key: 'COMPANY', text: i18n.t('app:companies') }
                    ]}
                  />
                </li>
              : item.id === 'nicknameFilter' ?
                <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default', height: '61px'}} tabIndex={index}>
                  <Label className='form-label mx-3 mt-3'>{i18n.t('app:nickName')}</Label>
                  <div className='mx-3'>
                    <Input key='nicknameFilter' className='w-100' value={this.state.searchNickname} onChange={(ev, data: any)=>{this._isMounted && this.setState({searchNickname: data.value})}}/>
                  </div>
                </li>
              : <div></div>
            : this.treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
          }
        </Tree>
        </ul>
      </nav>
    </Resizable>
    {this.state.filterColumnOpen && <FilterColumn type={this.state.filterName} save={(type,filters,checkbox)=>this._addFiltersToTree(type,filters,checkbox)} callback={(value)=>{this._isMounted && this.setState({filterColumnOpen: value}) }} pipelines={this.props.pipelines}/>}
    </>
  }

  private removeFrom(item: any, type: string) {
    if (type === 'Field') this.setState({currentFilters: this.state.currentFilters.filter((i)=>{return i.id !== item.id})})
    else if (type === 'Project') this.setState({currentProjects: this.state.currentProjects.filter((i)=>{return i.id !== item.id})})
    else if (type === 'Company') this.setState({currentCompanies: this.state.currentCompanies.filter((i)=>{return i.id !== item.id})})
    else if (type === 'Contact') this.setState({currentContacts: this.state.currentContacts.filter((i)=>{return i.id !== item.id})})
    else if (type === 'Contract') this.setState({currentContracts: this.state.currentContracts.filter((i)=>{return i.id !== item.id})})
    else this.setState({currentContacts: this.state.currentContacts.filter((i)=>{return i.id !== item.id})})
  }

  private treeData(title: string) {
    return <>
      <Resizable
      size={{ width: this.state.panel2Width, height: this.state.panelHeight }}
      onResizeStop={(e, direction, ref, d) => {
        var currentWidth = this.state.panel2Width.match(/\d+/)[0];
        this._isMounted && this.setState({
          panel2Width: +currentWidth + +d.width + "px"
        }, () => {
          this._saveResizeState2();
        });
      }}
      enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
      minWidth={240}
      maxWidth={480}
      handleStyles= {{
        right: {
          "right": "-6px",
          "width": "6px",
          "zIndex": 1
        }
      }}
      handleClasses={{"right": "resize-handle"}}
    >
      <nav className="tree project">
        <div style={{position: 'absolute', zIndex: 10, background: 'rgb(242, 242, 242)', width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.1'}}>
          <DefaultButton className="d-flex align-items-center my-2 p-1 hover-button w-100" onClick={ this._goBackToList.bind(this) }  >
            <Icon className='ml-1 mr-2 pr-1' iconName='Back'/>
            <span className="title mb-0">{i18n.t('app:'+title)}</span>
          </DefaultButton>
          <div className='align-items-center py-2' style={{padding: '4px 12px 0px 12px'}}>
            <div>
              <div className='d-flex align-items-center ml-4 pb-3'>
                <div className='mr-2' style={{background: 'white', borderRadius: '24px', height: '24px', width: '24px'}}>
                  { this.props.item && this.props.item.image ?
                      <img src={'data:image/png;base64,'+this.props.item.image} alt='Logo' style={{width: '24px', height: '24px', borderRadius: '2px'}}/>
                    :
                      <Persona className='project-persona' name={this.props.item ? this.props.item.name || (this.props.item.firstName || '') + ' ' + (this.props.item.lastName || '') : ''} size='small' primaryText='' textAlignment='center' avatar={{color: 'colorful'}}/>
                  }
                </div>
                <span className="m-0 pt-1" style={{fontSize: '14px', fontWeight: 600}}>{this.props.item ? (this.props.item.name || (this.props.item.firstName || '') + ' ' + (this.props.item.lastName || '')) : ''}</span>
              </div>
            </div>
          </div>
        </div>
        <ul className="ms-Nav-navItems h-100 m-0 pl-0">
          <Tree
            nodes={this.state.itemTree}
            onChange={this.handleNodesChange}>
            {({onChange, style, node, index, ...rest}) => {

              const {hasChildren, isExpanded} = getNodeRenderOptions(node);
              const handleChange = () =>{
                onChange({...updateNode(node, {expanded: !isExpanded}) })
              }
              if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
              if(typeof(style.marginLeft) === "number") style.marginLeft = 0
              let item:any = node;
              return(item.type === 'separator' ? <>
                <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                    <hr/>
                </li>
              </> 
              : item.type === 'separatorFilters' ? <>
                <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default', height: '61px'}} tabIndex={index}>
                    <div className='d-flex align-items-center pb-2'>
                        <p className='m-0 py-0'>{i18n.t('app:filters')}</p>
                        <TooltipHost key={'tag-tt-remove-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '32px'}}} content={i18n.t('app:remove')} setAriaDescribedBy={false}>
                          <button onClick={(ev)=>{history.push('')}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon iconName='Delete'/></button>
                        </TooltipHost>
                    </div>
                </li>
              </>
              : this.treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
            }
          </Tree>
        </ul>
      </nav>
      </Resizable>
      {this.state.filterColumnOpen && <FilterColumn type={this.state.filterName} save={(type,filters,checkbox)=>this._addFiltersToTree(type,filters,checkbox)} callback={(value)=>{this._isMounted && this.setState({filterColumnOpen: value}) }} pipelines={this.props.pipelines}/>}
    </>
  }

  private treeSearch(title: string) {

    const onRenderDetailsHeaderFields = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center'>
          {defaultRender!({
            ...props
          })}
          <TooltipHost
            content={i18n.t('app:openFields')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {position: 'relative', left: '-40px'} }}
          >
            <IconButton className='panel-icon' iconProps={{iconName: 'OpenPaneMirrored'}} styles={{icon: {fontSize: 20}}} onClick={()=>{this.setState({showCurrentFilters: true, filterColumnOpen: true, filterName: this.props.searchType})}}/>
          </TooltipHost>
        </div>
      );
    }

    const onRenderDetailsHeaderCompanies = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center'>
          {defaultRender!({
            ...props
          })}
          <TooltipHost
            content={i18n.t('app:showCompanies')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {position: 'relative', left: '-40px'} }}
          >
            <IconButton className='panel-icon' iconProps={{iconName: 'OpenPaneMirrored'}} styles={{icon: {fontSize: 20}}} onClick={()=>{this.setState({showCurrentCompanies: true, filterColumnOpen: true, filterName: 'companyIds'})}}/>
          </TooltipHost>
        </div>
      );
    }
  
    const onRenderDetailsHeaderContacts = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center'>
          {defaultRender!({
            ...props
          })}
          <TooltipHost
            content={i18n.t('app:showContacts')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {position: 'relative', left: '-40px'} }}
          >
            <IconButton className='panel-icon' iconProps={{iconName: 'OpenPaneMirrored'}} styles={{icon: {fontSize: 20}}} onClick={()=>{this.setState({showCurrentContacts: true, filterColumnOpen: true, filterName: 'contactIds'})}}/>
          </TooltipHost>
        </div>
      );
    }
  
    const onRenderDetailsHeaderProjects =(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center' style={{backgroundColor: 'white'}}>
          {defaultRender!({
            ...props
          })}
          <TooltipHost
            content={i18n.t('app:showProjects')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {position: 'relative', left: '-40px'} }}
          >
            <IconButton className='panel-icon' iconProps={{iconName: 'OpenPaneMirrored'}} styles={{icon: {fontSize: 20}}} onClick={()=>{this.setState({showCurrentProjects: true, filterColumnOpen: true, filterName: 'projectIds'})}}/>
          </TooltipHost>
        </div>
      );
    }
  
    const onRenderDetailsHeaderContracts =(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center' style={{backgroundColor: 'white'}}>
          {defaultRender!({
            ...props
          })}
          <TooltipHost
            content={i18n.t('app:showContracts')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {position: 'relative', left: '-40px'} }}
          >
            <IconButton className='panel-icon' iconProps={{iconName: 'OpenPaneMirrored'}} styles={{icon: {fontSize: 20}}} onClick={()=>{this.setState({showCurrentContracts: true, filterColumnOpen: true, filterName: 'contractIds'})}}/>
          </TooltipHost>
        </div>
      );
    }

    const columnsCompanies:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentCompanies: !this.state.showCurrentCompanies})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentCompanies ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:companies') + ' (' + this.state.currentCompanies.length + ')' }</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            }
            <div style={{marginLeft: '12px', textOverflow: 'ellipsis', overflowX: 'hidden'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)', textOverflow: 'ellipsis', overflowX: 'hidden'}}>{item.name}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)', textOverflow: 'ellipsis', overflowX: 'hidden'}}><Link target='_blank' rel="noreferrer noopener"  href={'https://'+item.domain}>{item.domain}</Link></p>
            </div>
            <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,item.id.split('-')[0])}}/>
            </TooltipHost>
          </div>
        </>)
      }
    }]
  
    const columnsFields:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentFilters: !this.state.showCurrentFilters})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentFilters ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:fields') + ' (' + this.state.currentFilters.length + ')' }</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        const dates = ['fromSignatureDate', 'toSignatureDate', 'fromStartDate', 'toStartDate', 'fromExpirationDate', 'toExpirationDate', 'birthDate', 'fromReceivedDateTime', 'toReceivedDateTime', 'fromSavedDateTime', 'toSavedDateTime', 'fromNoteDate', 'toNoteDate', 'fromCreatedDateTime', 'toCreatedDateTime', 'fromModifiedDateTime', 'toModifiedDateTime', 'fromDueDate', 'toDueDate', 'fromReminders', 'toReminders']
        const states = ['OPEN', 'CLOSE', 'INTERNAL', 'EXTERNAL']
        const pipelines = this.props.pipelines;
        let stageName = '';
        if (item.id === 'stage') {
          for (let p in pipelines) {
            for (let s in pipelines[p].stages) {
              if (item.text === pipelines[p].stages[s].id) stageName = pipelines[p].stages[s].name;
            }
          }
        }

        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100 py-2" style={{userSelect:'none'}}>
            <div style={{marginLeft: '12px', textOverflow: 'ellipsis', overflowX: 'hidden'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 600, color: 'rgb(50, 49, 48)', textOverflow: 'ellipsis', overflowX: 'hidden'}}>
                {item.id === 'description' && this.props.searchType !== 'TASK' ? i18n.t('app:notes') : i18n.t('app:'+item.id) }
              </p>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)', textOverflow: 'ellipsis', overflowX: 'hidden'}}>
                { dates.includes(item.id) ? Moment(item.text).format(i18n.t('Y-MMM-DD')) : states.includes(item.text) ? i18n.t('app:'+item.text) : item.id === 'stage' ? stageName :  item.text}
              </p>
            </div>
            {item && !item.byDefault && <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,item.type)}}/>
            </TooltipHost>}
            {item && item.byDefault && <TooltipHost
                content={i18n.t('app:byDefault')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto', width: '32px'}}}
            >
                <Icon iconName='Lock' style={{marginLeft: '10px'}}/>
            </TooltipHost>}
          </div>
        </>)
      }
    }]
  
    const columnsContacts:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentContacts: !this.state.showCurrentContacts})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentContacts ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:contacts') + ' (' + this.state.currentContacts.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            }
            <div style={{marginLeft: '12px', textOverflow: 'ellipsis', overflowX: 'hidden'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)', textOverflow: 'ellipsis', overflowX: 'hidden'}}>{item.firstName + ' ' + item.lastName}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)', textOverflow: 'ellipsis', overflowX: 'hidden'}}><Link target='_blank' rel="noreferrer noopener"  href={'mailto:'+(item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email)}>{item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email}</Link></p>
            </div>
            {item && !item.byDefault && <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,item.id.split('-')[0])}}/>
            </TooltipHost>}
          </div>
        </>)
      }
    }]
  
    const columnsProjects:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentProjects: !this.state.showCurrentProjects})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:remove')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentProjects ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:projects') + ' (' + this.state.currentProjects.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            }
            <div style={{marginLeft: '12px', textOverflow: 'ellipsis', overflowX: 'hidden'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)', textOverflow: 'ellipsis', overflowX: 'hidden'}}>{item.name}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)', textOverflow: 'ellipsis', overflowX: 'hidden'}}><Link target='_blank' href={'/projects/'+item.id}>Link</Link></p>
            </div>
            {item && !item.byDefault && <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,item.id.split('-')[0])}}/>
            </TooltipHost>}
          </div>
        </>)
      }
    }]
  
    const columnsContracts:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentContracts: !this.state.showCurrentContracts})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:remove')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentContracts ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:contracts') + ' (' + this.state.currentContracts.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            }
            <div style={{marginLeft: '12px', textOverflow: 'ellipsis', overflowX: 'hidden'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)', textOverflow: 'ellipsis', overflowX: 'hidden'}}>{item.name}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)', textOverflow: 'ellipsis', overflowX: 'hidden'}}><Link target='_blank' href={'/contracts/'+item.id}>Link</Link></p>
            </div>
            {item && !item.byDefault && <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,item.id.split('-')[0])}}/>
            </TooltipHost>}
          </div>
        </>)
      }
    }]

    return <>
      <Resizable
      size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
      onResizeStop={(e, direction, ref, d) => {
        var currentWidth = this.state.panelWidth.match(/\d+/)[0];
        this._isMounted && this.setState({
          panelWidth: +currentWidth + +d.width + "px"
        }, () => {
          this._saveResizeState();
        });
      }}
      enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
      minWidth={240}
      maxWidth={480}
      handleStyles= {{
        right: {
          "right": "-6px",
          "width": "6px",
          "zIndex": 1
        }
      }}
      handleClasses={{"right": "resize-handle"}}
    >
      <nav className="tree search">
        <p className="mb-0 mt-3 py-0 px-3" style={{fontSize: 16, fontWeight: 700}}>{i18n.t('app:search')}</p>
        <ChoiceGroup selectedKey={this.props.searchType} className="mx-3 mt-3"
          onChange={(ev, item: any)=>{
            this.props.callbackFunction({searchType: item.key})
            this._isMounted && this.setState({filterName: item.key})
          }}
          options={[
            { key: 'PROJECT', text: i18n.t('app:projects') },
            { key: 'CONTRACT', text: i18n.t('app:contracts') },
            { key: 'CONTACT', text: i18n.t('app:contacts') },
            { key: 'COMPANY', text: i18n.t('app:companies') },
            { key: 'NOTE', text: i18n.t('app:note') },
            { key: 'EMAIL', text: i18n.t('app:email') },
            { key: 'CONVERSATION', text: i18n.t('app:conversation') },
            { key: 'TASK', text: i18n.t('app:task') }
          ]}
        />
        <p className="mb-0 mt-3 py-0 px-3" style={{fontSize: 16, fontWeight: 700}}>{i18n.t('app:fields')}</p>
        <div className='relation-list mx-3 mb-3'>
          <ShimmeredDetailsList
            className={this.state.currentFilters.length === 0 || !this.state.showCurrentFilters ? 'mt-4 height-fix' : 'mt-4'}
            items={this.state.showCurrentFilters ? this.state.currentFilters : []}
            columns={columnsFields}
            onRenderDetailsHeader={onRenderDetailsHeaderFields}
            selectionMode={SelectionMode.none}
            selectionPreservedOnEmptyClick={true}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            ariaLabelForShimmer="Content is being fetched"
            enterModalSelectionOnTouch={true}
            shimmerLines={12}
            constrainMode={ConstrainMode.unconstrained}
          />
          {this.state.currentFilters.length === 0 && this.state.showCurrentFilters && <div className='d-flex' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
            <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
          </div>}
        </div>
        <p className="mb-0 mt-3 py-0 px-3" style={{fontSize: 16, fontWeight: 700}}>{i18n.t('app:relations')}</p>
        <div className='relation-list panel mx-3'>
          <ShimmeredDetailsList
            className={this.state.currentProjects.length === 0 || !this.state.showCurrentProjects ? 'mt-4 height-fix' : 'my-4'}
            items={this.state.showCurrentProjects ? this.state.currentProjects : []}
            columns={columnsProjects}
            onRenderDetailsHeader={onRenderDetailsHeaderProjects}
            selectionMode={SelectionMode.none}
            selectionPreservedOnEmptyClick={true}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            ariaLabelForShimmer="Content is being fetched"
            enterModalSelectionOnTouch={true}
            shimmerLines={12}
            constrainMode={ConstrainMode.unconstrained}
          />
          {this.state.currentProjects.length === 0 && this.state.showCurrentProjects && <div className='d-flex mb-3' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
            <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
          </div>}
          <ShimmeredDetailsList
            className={this.state.currentContracts.length === 0 || !this.state.showCurrentContracts ? 'mt-4 height-fix' : 'my-4'}
            items={this.state.showCurrentContracts ? this.state.currentContracts : []}
            columns={columnsContracts}
            onRenderDetailsHeader={onRenderDetailsHeaderContracts}
            selectionMode={SelectionMode.none}
            selectionPreservedOnEmptyClick={true}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            ariaLabelForShimmer="Content is being fetched"
            enterModalSelectionOnTouch={true}
            shimmerLines={12}
            constrainMode={ConstrainMode.unconstrained}
          />
          {this.state.currentContracts.length === 0 && this.state.showCurrentContracts && <div className='d-flex mb-3' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
            <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
          </div>}
          <ShimmeredDetailsList
            className={this.state.currentContacts.length === 0 || !this.state.showCurrentContacts ? 'mt-4 height-fix' : 'my-4'}
            items={this.state.showCurrentContacts ? this.state.currentContacts : []}
            columns={columnsContacts}
            onRenderDetailsHeader={onRenderDetailsHeaderContacts}
            selectionMode={SelectionMode.none}
            selectionPreservedOnEmptyClick={true}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            ariaLabelForShimmer="Content is being fetched"
            enterModalSelectionOnTouch={true}
            shimmerLines={12}
            constrainMode={ConstrainMode.unconstrained}
          />
          {this.state.currentContacts.length === 0 && this.state.showCurrentContacts && <div className='d-flex mb-3' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
            <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
          </div>}
          <ShimmeredDetailsList
            className={this.state.currentCompanies.length === 0 || !this.state.showCurrentCompanies ? 'mt-4 height-fix' : 'mt-4'}
            items={this.state.showCurrentCompanies ? this.state.currentCompanies : []}
            columns={columnsCompanies}
            onRenderDetailsHeader={onRenderDetailsHeaderCompanies}
            selectionMode={SelectionMode.none}
            selectionPreservedOnEmptyClick={true}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            ariaLabelForShimmer="Content is being fetched"
            enterModalSelectionOnTouch={true}
            shimmerLines={12}
            constrainMode={ConstrainMode.unconstrained}
          />
          {this.state.currentCompanies.length === 0 && this.state.showCurrentCompanies && <div className='d-flex mb-3' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
            <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
          </div>}
        </div>
      </nav>
    </Resizable>
    {this.state.filterColumnOpen && <FilterColumn currentFields={this.state.currentFilters} page={this.props.page} type={this.state.filterName} save={(type,filters,checkbox)=>this._addFiltersToSearchSidebar(type,filters)} callback={(value)=>{this._isMounted && this.setState({filterColumnOpen: value}) }} pipelines={this.props.pipelines}/>}
    </>
  }

  private treeSub(title: string) {
    return <>
      <Resizable
      size={{ width: this.state.panel2Width, height: this.state.panelHeight }}
      onResizeStop={(e, direction, ref, d) => {
        var currentWidth = this.state.panel2Width.match(/\d+/)[0];
        this._isMounted && this.setState({
          panel2Width: +currentWidth + +d.width + "px"
        }, () => {
          this._saveResizeState2();
        });
      }}
      enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
      minWidth={240}
      maxWidth={480}
      handleStyles= {{
        right: {
          "right": "-6px",
          "width": "6px",
          "zIndex": 1
        }
      }}
      handleClasses={{"right": "resize-handle"}}
    >
      <nav className="tree project">
        <div style={{position: 'absolute', zIndex: 10, background: 'rgb(242, 242, 242)', width: '100%'}}>
          <DefaultButton className="d-flex align-items-center my-2 p-1 hover-button w-100" onClick={ this._goBackToList.bind(this) }  >
            <Icon className='ml-1 mr-2 pr-1' iconName='Back'/>
            <span className="title mb-0">{i18n.t('app:'+title)}</span>
          </DefaultButton>
        </div>
      </nav>
      </Resizable>
      {this.state.filterColumnOpen && <FilterColumn type={this.state.filterName} save={(type,filters,checkbox)=>this._addFiltersToTree(type,filters,checkbox)} callback={(value)=>{this._isMounted && this.setState({filterColumnOpen: value}) }} pipelines={this.props.pipelines}/>}
    </>
  }

  private treeLine(style,index,node,isExpanded,hasChildren,handleChange) {
    let selected = false;
    if (node.type === 'view-projects') selected = this.props.page === node.page && document.location.search === node.query ? true : false;
    if (node.type === 'view-contacts') selected = this.props.page === node.page && document.location.search === node.query ? true : false;
    if (node.type === 'view-companies') selected = this.props.page === node.page && document.location.search === node.query ? true : false;
    if (node.type === 'view-notes' || node.type === 'view-tasks' || node.type === 'view-permissions' || node.type === 'view-emails') selected = this.props.page === node.page && document.location.search === node.query ? true : false;
    if (node.type === 'view-conversations') selected = this.props.page === node.page && document.location.search === node.query ? true : false;
    if (node.type === 'view-pipelines') selected = this.props.page === node.page && document.location.search === node.query ? true : false;
    if (node.type === 'contractsList') selected = this.props.page === node.page && document.location.search === node.query ? true : false;
    if (node.type === 'contractsDashboard') selected = this.props.page === 'contractsDashboard';
    else if (node.type === 'view-custom') selected = this.checkSelected();
    else if (node.type === 'projectsBoard') selected = this.props.page === 'boardExplorer';
    else if (node.type === 'projectsMilestone') selected = this.props.page === 'milestonesExplorer';
    else if (node.type === 'projectsCompany') selected = this.props.page === 'clientsExplorer';
    else if (node.type === 'projectsPipeline') selected = this.props.page === 'pipelinesExplorer';
    else if (node.type === 'projectsNothing') selected = this.props.page === 'projectsExplorer';
    else if (node.type === 'projectsList') selected = this.props.page === "projectsExplorer"
      || this.props.page === "clientsExplorer"
      || this.props.page === "milestonesExplorer"
      || this.props.page === "pipelinesExplorer"
    else if (node.type === 'pipelinesList') selected =  this.props.pipelineId === 'all'
    else if (node.type === 'pipelines') selected =  this.props.pipelineId === node.name
    else if (node.type === 'customFieldsProject') selected =  this.props.page === 'customFieldsProject'
    else if (node.type === 'customFieldsContract') selected =  this.props.page === 'customFieldsContract'
    else if (node.type === 'customFieldsContact') selected =  this.props.page === 'customFieldsContact'
    else if (node.type === 'customFieldsCompany') selected =  this.props.page === 'customFieldsCompany'
    else if (node.type === 'view-project') selected = document.location.search === node.query || ((!document.location.search || document.location.search === '?type=All') && node.query === '');
    else if (node.type === 'allViews-views') selected = this.props.page === "views";
    //else if (node.type) selected = this.checkSelFilter(node, node.type.replace('tag','').toLowerCase());

    const nodeCategoryEmpty = node.type === 'category' && node.children && node.children[0] && node.children[0].name === 'No filter'
    
    return (
      <>
        <li className={"ms-Nav-navItem " + (selected ? 'bg-selected' : '')} style={style} tabIndex={index} onClick={(ev) => { (node.type !== 'category') && this._goToPage(ev,node); if(!isExpanded) handleChange()}}>
          <div className={ "ms-Nav-compositeLink"} >
        
            <button className="chevronButton">
              { hasChildren && !isExpanded?
                <Icon iconName="ChevronRight"  onClick={(e) => {e.stopPropagation(); handleChange();
                }} className="ms-Nav-chevron d-inline-block" />
              : hasChildren && isExpanded?
                <Icon iconName="ChevronDown"  onClick={(e) => {e.stopPropagation();handleChange()}} className="ms-Nav-chevron d-inline-block" />
              :
                <div className="noChevron"></div>
              }
            </button>
            
            <button className="ms-Button ms-Button--action ms-Button--command ms-Nav-link pl-0">
              { this._onRenderLink(node) }
            </button>
            {node.type === 'view-custom' && this.checkSelected() &&
              <TooltipHost key='view-custom-tt' styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={i18n.t('app:addCustomView')} setAriaDescribedBy={false}>
                <button className='custom-view ms-Button ms-Button--action ms-Button--command ms-Nav-link' onClick={()=>{this.createView()}}><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Save'/></button>
              </TooltipHost>
            }
            {/*node.id && node.id.startsWith('View-') && node.type !== 'view-custom' && node.type.startsWith('view-') &&
              <TooltipHost styles={{root:{position:'fixed', right: 0, marginRight:'40px',  height: '24px'}}} content={i18n.t('app:edit')} setAriaDescribedBy={false}>
                <button onClick={(ev)=>{this.editView(node)}} className='custom-view filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Edit'/></button>
              </TooltipHost>
            */}
            {/*node.id && node.id.startsWith('View-') && node.type !== 'view-custom' && node.type.startsWith('view-') && !node.allQuickAccess &&
              <TooltipHost styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={i18n.t('app:remove')} setAriaDescribedBy={false}>
                <button onClick={(ev)=>{this.removeView(node)}} className='custom-view filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Remove'/></button>
              </TooltipHost>
            */}
            {node.type === 'category' &&
              <TooltipHost key={'category-tt-edit-'+node.id} styles={{root:{position:'fixed', right: nodeCategoryEmpty ? 0 : '6px', marginRight: nodeCategoryEmpty ? '10px' : '36px',  height: '24px'}}} content={i18n.t('app:edit')} setAriaDescribedBy={false}>
                <button onClick={(ev)=>{this._goToPage(ev,node)}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Edit'/></button>
              </TooltipHost>
            }
            {node.type === 'category' && !nodeCategoryEmpty &&
              <TooltipHost key={'category-tt-remove-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={i18n.t('app:remove')} setAriaDescribedBy={false}>
                <button onClick={(ev)=>{ev.preventDefault();ev.stopPropagation();this.removeFilterCategory(node.field)}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Delete'/></button>
              </TooltipHost>
            }
            {node.type === 'filtersHeader' &&
              <TooltipHost key={'filtersHeader-tt-remove-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={i18n.t('app:remove')} setAriaDescribedBy={false}>
                <button onClick={(ev)=>{history.push('')}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Delete'/></button>
              </TooltipHost>
            }
            {(node.type.includes('tag') || node.type === 'pipelineId' || node.type === 'contactType' || node.type === 'projectIds' || node.type === 'contractIds' || node.type === 'contactIds' || node.type === 'companyIds' || node.type === 'type' || node.type === 'status') &&
              <TooltipHost key={'tag-tt-edit-'+node.id} styles={{root:{position:'fixed', right: node.name === 'No filter' ? 0 : '6px', marginRight: node.name === 'No filter' ? '10px' : '36px',  height: '24px'}}} content={i18n.t('app:edit')} setAriaDescribedBy={false}>
                <button onClick={(ev)=>{this._goToPage(ev,node)}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Edit'/></button>
              </TooltipHost>
            }
            {node.name !== 'No filter' && (node.type.includes('tag') || node.type === 'pipelineId' || node.type === 'contactType' || node.type === 'projectIds' || node.type === 'contractIds' || node.type === 'contactIds' || node.type === 'companyIds' || node.type === 'type' || node.type === 'status') &&
              <TooltipHost key={'tag-tt-remove-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={i18n.t('app:remove')} setAriaDescribedBy={false}>
                <button onClick={(ev)=>{ev.preventDefault();ev.stopPropagation();this.removeFilter(node)}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Delete'/></button>
              </TooltipHost>
            }
          </div>
        </li>
      </>
    )
  }

  public render() {
    const { selNav, itemTree } = this.state;
    const helpIcon: IIconProps = { iconName: 'Help' };
    const settingsIcon: IIconProps = { iconName: 'Settings' };
    const self = this;
    function toggleSettingsMenu(){
      self._isMounted && self.setState({settingsMenu: !self.state.settingsMenu})
    }
    function togglePersonaMenu(){
      self._isMounted && self.setState({personaMenu: !self.state.personaMenu})
    }

    let navLinks:any = document.getElementsByClassName('folderLink')
    for (let i=0; i<navLinks.length; i++) {
      let link:any = navLinks[i]
      let grandParent = link.parentElement.parentElement;
      if (link.className.includes('selItem')) {
        grandParent.style.background = '#bfbfbf';
      } else {
        grandParent.style.background = 'none';
      }
    }

    let navLinks2:any = document.getElementsByClassName('link')
    for (let i=0; i<navLinks2.length; i++) {
      let link:any = navLinks2[i]
      let grandParent = link.parentElement.parentElement;
      if (link.className.includes('selItem')) {
        grandParent.style.background = '#bfbfbf';
      } else {
        grandParent.style.background = 'none';
      }
    }

    const settingsItems: IContextualMenuItem[] = [
      {
        key: 'updateAvailable',
        text: i18n.t('app:applyUpdateNow'),
        iconProps: { iconName: 'Important' },
        onClick: () => {this._applyUpdate()},
        className: 'important',
        hidden: !this.props.appUpdateAvailable && !this.props.webUpdateAvailable
      },
      {
        key: 'checkForUpdates',
        text: 'Check for updates',
        iconProps: { iconName: 'Sync' },
        onClick: () => {this._checkforUpdates()},
      },
      {
        key: 'language',
        text: i18n.t('app:language'),
        iconProps: { iconName: 'LocaleLanguage' },
        subMenuProps: {
          items: [
            {
              key: 'de',
              text: 'Deutsch',
              onClick: () => {i18n.changeLanguage('de')},
            },
            {
              key: 'en',
              text: 'English',
              onClick: () => {i18n.changeLanguage('en')},
            },
            {
              key: 'es',
              text: 'Español',
              onClick: () => {i18n.changeLanguage('es')},
            },
          ],
        },
        id: 'language-button'
      }
    ];

    const personaItems: IContextualMenuItem[] = [
      {
        itemType: ContextualMenuItemType.Header,
        key: 'userName',
        text: this.state.userName,
        className: "userName"
      },
      {
        itemType: ContextualMenuItemType.Header,
        key: 'userEmail',
        text: this.props.userData && this.props.userData.user.email,
        className: "userEmail"
      },
      {
        key: 'userSettings',
        text: i18n.t('app:account'),
        iconProps: { iconName: 'Contact' },
        onClick: () => {this._goToSettings()},
      },
      {
        key: 'signOut',
        text: i18n.t('app:signOut'),
        iconProps: { iconName: 'signOut' },
        onClick: () => userService.logout(),
      }
    ];

    return(
      <ThemeProvider>
        
        <div id="main-nav" className=" d-flex flex-row">
          <nav className="main">
              <Nav
                expandButtonAriaLabel="Expand or collapse"
                ariaLabel="Main navigation"
                selectedKey={ selNav || this.state.firstSelNav }
                styles={{
                  root: {
                    width: 48,
                    boxSizing: 'border-box',
                    overflowY: 'auto'
                  }
                }}
                groups={[
                  {
                    links: [
                      {
                        name: '',
                        url: '',
                        icon: 'Home',
                        key: 'home',
                        onClick: this._goToHome.bind(this),
                        ariaLabel: 'Home',
                        title: i18n.t('Home'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Work',
                        key: 'projects',
                        onClick: this._goToProjects.bind(this),
                        ariaLabel: 'Projects',
                        title: i18n.t('app:projects'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'TextDocument',
                        key: 'contracts',
                        onClick: this._goToContracts.bind(this),
                        ariaLabel: 'Contracts',
                        title: i18n.t('app:contracts'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Contact',
                        key: 'contacts',
                        onClick: this._goToContacts.bind(this),
                        ariaLabel: 'Contacts',
                        title: i18n.t('app:contacts'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'CityNext2',
                        key: 'companies',
                        onClick: this._goToCompanies.bind(this),
                        ariaLabel: 'Companies',
                        title: i18n.t('app:companies'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'D365ProjectOperationsIconNav',
                        key: 'pipelines',
                        onClick: this._goToPipelines.bind(this),
                        ariaLabel: 'Pipelines',
                        title: i18n.t('app:pipelines'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Quicknote',
                        key: 'notes',
                        onClick: this._goToNotes.bind(this),
                        ariaLabel: 'Notes',
                        title: i18n.t('app:notes'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'CannedChat',
                        key: 'conversations',
                        onClick: this._goToConversations.bind(this),
                        ariaLabel: 'Conversations',
                        title: i18n.t('app:conversations'),
                        //disabled: !this.props.isOnline || this.props.maintenance
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'IconSetsFlag',
                        key: 'tasks',
                        ariaLabel: 'tasks',
                        title: i18n.t('app:tasks'),
                        onClick: this._goToTasks.bind(this),
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Mail',
                        key: 'emails',
                        ariaLabel: 'emails',
                        title: i18n.t('app:emails'),
                        onClick: this._goToEmails.bind(this),
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Clock',
                        key: 'timeTracking',
                        onClick: this._goToTimeTracking.bind(this),
                        ariaLabel: 'Time tracking',
                        title: 'Time tracking',
                        //disabled: !this.props.isOnline || this.props.maintenance
                        hidden: true,
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Permissions',
                        key: 'permissions',
                        ariaLabel: 'permissions',
                        title: i18n.t('app:permissions'),
                        onClick: this._goToPermissions.bind(this),
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Search',
                        key: 'search',
                        ariaLabel: 'search',
                        title: i18n.t('app:search'),
                        onClick: this._goToSearch.bind(this),
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'Tag',
                        key: 'tags',
                        ariaLabel: 'tags',
                        title: i18n.t('app:tags'),
                        //onClick: this._goToTimeTracking.bind(this),
                        hidden: true,
                      },
                      {
                        name: '',
                        url: '',
                        icon: 'LineChart',
                        key: 'activity',
                        ariaLabel: 'activity',
                        title: i18n.t('app:activity'),
                        //onClick: this._goToTimeTracking.bind(this),
                        hidden: true,
                      },
                      {
                        name: 'customFields',
                        url: '',
                        icon: 'FieldNotChanged',
                        key: 'customFields',
                        ariaLabel: 'search',
                        title: i18n.t('app:customFields'),
                        onClick: this._goToCustomFields.bind(this),
                      },
                    ]
                  }
                ]}
              />
              <div className="fixedBottom">
              <IconButton
                  iconProps={helpIcon}
                  styles={{
                    root: {
                      width: 48,
                      boxSizing: 'border-box',
                      overflowY: 'auto'
                    }
                  }}
                  title={i18n.t('app:help')}
                  ariaLabel="Help"
                  onClick={ () => window.postMessage("supportToggle", '*') }
                />
                <div className="settings-wrap">
                  <IconButton
                    id="settings-button"
                    iconProps={settingsIcon}
                    styles={{
                      root: {
                        width: 48,
                        boxSizing: 'border-box',
                        overflowY: 'auto'
                      }
                    }}
                    title={i18n.t('app:settings')}
                    ariaLabel="Settings"
                    onClick={toggleSettingsMenu}
                  />
                  { this.state.webUpdateAvailable ?
                    <Icon iconName="CircleFill" className="webUpdateAvailable" />
                  : null }
                </div>
                <ContextualMenu
                  items={settingsItems}
                  hidden={!this.state.settingsMenu}
                  target={`#${"settings-button"}`}
                  onItemClick={()=> this._isMounted && this.setState({settingsMenu: true})}
                  onDismiss={()=> this._isMounted && this.setState({settingsMenu: false})}
                  className="contextualSettingsMenu"
                />
                <div className="persona-wrap">
                  <Persona 
                    id="persona-button"
                    style={{
                      width: 48,
                      height: 48,
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      padding: '10px 0 0 10px'
                    }}
                    name={this.state.userName} 
                    size='small'
                    primaryText=''
                    className="persona"
                    onClick={togglePersonaMenu}
                  />
                </div>
                <ContextualMenu
                  items={personaItems}
                  hidden={!this.state.personaMenu}
                  target={`#${"persona-button"}`}
                  onItemClick={()=> this._isMounted && this.setState({personaMenu: true})}
                  onDismiss={()=> this._isMounted && this.setState({personaMenu: false})}
                  className="contextualUserMenu"
                />
              </div>
          </nav>
          { selNav === "projects" && this.props.page !== 'projectData' ?
            this.tree('projects')
            : selNav === "projects" && this.props.page === 'projectData' ?
            this.treeData('projects')
          : selNav === "contracts" && this.props.page !== 'contractData' ?
            this.tree('contracts')
          : selNav === "contracts" && this.props.page === 'contractData' ?
            this.treeData('contracts')
          : selNav === "companies" && this.props.page !== 'companyData' ?
            this.tree('companies')
          : selNav === "companies" && this.props.page === 'companyData' ?
            this.treeData('companies')
          : selNav === "contacts" && this.props.page !== 'contactData' ?
            this.tree('contacts')
          : selNav === "contacts" && this.props.page === 'contactData' ?
            this.treeData('contacts')
          : selNav === "pipelines" ?
            this.tree('pipelines')
          : selNav === "notes" && this.props.page === 'note' ?
            this.treeSub('notes')
          : selNav === "notes" ?
            this.tree('notes')
          : selNav === "conversations" && this.props.page === 'conversation' ?
            this.treeSub('conversations')
          : selNav === "conversations" ?
            this.tree('conversations')
          : selNav === "emails" && this.props.page === 'email' ?
            this.treeSub('emails')
          : selNav === "emails" ?
            this.tree('emails')
          : selNav === "home" ?
            this.tree('home')
          : selNav === "timeTracking" ?
            this.tree('timeTracker')
          : selNav === "customFields" ?
            this.tree('customFields')
          : selNav === "search" ?
            this.treeSearch('search') //<TreeSidebar searchNickname={this.state.searchNickname} searchDomain={this.state.searchDomain} filtersIndex={3} title={'search'} page={this.props.page} itemTree={this.state.itemTree} handleNodesChange={this.handleNodesChange.bind(this)} toggleFilters={this.toggleFilters.bind(this)} filterColumnOpen={this.state.filterColumnOpen} filterName={this.state.filterName} _addFiltersToTree={this._addFiltersToTree.bind(this)} treeLine={this.treeLine.bind(this)} callbackFunction={this.props.callbackFunction} callback={(v)=>this._isMounted && this.setState(v)} pipelines={this.props.pipelines} searchType={this.props.searchType}/>
          : selNav === "tasks" && this.props.page === 'task' ?
            this.treeSub('tasks')
          : selNav === "tasks" ?
            this.tree('tasks')
          : selNav === "permissions" ?
            this.tree('permissions')
          : selNav === "views" ?
            this.tree(this.getViewsTitle())
          : null }
        </div>
      </ThemeProvider>
    )
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("sidebarPrefs", JSON.stringify(prefs))
  }

  private _saveResizeState2() {
    const { panelHeight, panel2Width } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panel2Width
    }

    localStorage.setItem("sidebar2Prefs", JSON.stringify(prefs))
  }

  private toggleNav(nav:any) {
    const { selNav } = this.state;
    if (selNav === nav) {
      nav = "";
    }
    this._isMounted && this.setState({ selNav: nav })
  }

  private removeFilterCategory(category) {
    if (this.props.page === 'search') {
      let itemTree = this.state.itemTree
      for (let i in itemTree) {
        if (itemTree[i].field === category) {
          itemTree[i].children = [
            {
              key: 'noFilter',
              id: 'noFilter',
              name: i18n.t('app:noFilter') ,
              type: 'tagUndefined',
              category: category,
              iconName: 'ClearFilter',
            }
          ];
        }
      }
      this._isMounted && this.setState({itemTree: itemTree})
    } else {
      let search = new URLSearchParams(document.location.search)
      search.delete(category)
      history.push('?'+search.toString())
    }
  }

  private removeFilter(item) {
    if (this.props.page === 'search') {
      let itemTree = this.state.itemTree
      for (let i in itemTree) {
        if (itemTree[i].children) {
          itemTree[i].children = itemTree[i].children.filter((c)=>{
            return c.id !== item.id
          })
          if (itemTree[i].children.length === 0) {
            itemTree[i].children = [
              {
                key: 'noFilter',
                id: 'noFilter',
                name: i18n.t('app:noFilter') ,
                type: 'tagUndefined',
                category: itemTree[i].field,
                iconName: 'ClearFilter',
              }
            ];
          }
        }
      }
      this._isMounted && this.setState({itemTree: itemTree})
    } else {
      let search = new URLSearchParams(document.location.search)
      let tags = search.get(item.category)?.split(',')
      if (tags) {
        let values = tags.filter((value)=>{
          return value !== item.id
        });
        search.delete(item.category)
        values.length > 0 && search.set(item.category, values.join(','))
        history.push('?'+search.toString())
      }
    }
  }

  private _goToPage(ev: React.MouseEvent<HTMLElement> | undefined, item?: any | undefined) {
    if (ev) {
      ev.preventDefault();
    }
    if (item) {
      if(item.type === "projectsList") history.push("/projects/list" + document.location.search)
      else if(item.type === "projectsNothing") history.push("/projects/list" + document.location.search)
      else if(item.type === "projectsCompany") history.push("/projects/clients" + document.location.search)
      else if(item.type === "projectsMilestone") history.push("/projects/stages" + document.location.search)
      else if(item.type === "projectsPipeline") history.push("/projects/pipeline" + document.location.search)
      else if(item.type === "projectsBoard" && this.props.pipelineId !== "all") history.push("/projects/board/" + document.location.search)
      else if(item.type === "contractsList") history.push("/contracts/list" + item.query)
      else if(item.type === "contractsDashboard") history.push("/contracts")
      else if(item.type === "companiesList") history.push("/companies" + document.location.search)
      else if(item.type === "companiesTrash") history.push("/companies/inactive" + document.location.search)
      else if(item.type === "contactsList") history.push("/contacts" + document.location.search)
      else if(item.type === "contactsTrash") history.push("/contacts/inactive" + document.location.search)
      else if(item.type === "pipelines") history.push(item.name + document.location.search)
      else if(item.type === "pipelinesList") history.push("/projects/pipeline" + document.location.search)
      else if(item.type === "homeList") history.push("/home" + document.location.search)
      else if(item.type === "viewAll") history.push("/projects/list")
      else if(item.type === "viewPipelines") history.push("/projects/pipeline" + document.location.search)
      else if(item.type === "viewPipeline") history.push("/projects/list/" + item.name + document.location.search)
      else if(item.type === "viewBoardPipeline") history.push("/projects/board/" + item.name)
      else if(item.type === "viewActivity") this.props.callbackFunction({query: 'all'})
      else if(item.type === "viewNotes") this.props.callbackFunction({query: 'notes'})
      else if(item.type === "viewConversations") this.props.callbackFunction({query: 'conversations'})
      else if(item.type === "viewFiles") this.props.callbackFunction({query: 'files'})
      else if(item.type === "view-projects") {
        if (item.page === 'projectsExplorer') history.push("/projects/list" + item.query)
        else if (item.page === 'clientsExplorer') history.push("/projects/clients" + item.query)
        else if (item.page === 'pipelinesExplorer') history.push("/projects/pipeline" + item.query)
        else if (item.page === 'milestonesExplorer') history.push("/projects/stages" + item.query)
        else if (item.page === 'boardExplorer') history.push("/projects/board" + item.query)
      }
      else if(item.type === "view-contacts") {
        if (item.page === 'contactsExplorer') history.push("/contacts" + item.query)
      }
      else if(item.type === "view-companies") {
        if (item.page === 'companiesExplorer') history.push("/companies" + item.query)
      }
      else if(item.type === "view-contracts") {
        if(item.page === "contractsExplorer") history.push("/contracts/list" + item.query)
      }
      else if(item.type === "view-notes") {
        if (item.page === 'notes') history.push("/notes" + item.query)
        else if (item.page === 'notesPreview') history.push("/notes/preview" + item.query)
      }
      else if(item.type === "view-conversations") {
        if (item.page === 'conversations') history.push("/conversations" + item.query)
        else if (item.page === 'conversationsPreview') history.push("/conversations/preview" + item.query)
      }
      else if(item.type === "view-tasks") {
        if (item.page === 'tasks') history.push("/tasks" + item.query)
        else if (item.page === 'tasksPreview') history.push("/tasks/preview" + item.query)
      }
      else if(item.type === "view-emails") {
        if (item.page === 'emails') history.push("/emails" + item.query)
        if (item.page === 'emailsPreview') history.push("/emails/preview" + item.query)
      }
      else if(item.type === "view-permissions") {
        if (item.page === 'permissions') history.push("/permissions" + item.query)
      }
      else if(item.type === "view-pipelines") {
        if (item.page === 'pipelines') history.push("/pipelines" + item.query)
      }
      else if (item.type === 'category' || item.type === 'tagButton'){
        this._isMounted && this.setState({filterColumnOpen: true, filterName: item.field})
      }
      else if (item.type.includes('tag') || item.type === 'pipelineId' || item.type === 'contactType' || item.type === 'projectIds' || item.type === 'contractIds' || item.type === 'contactIds' || item.type === 'companyIds' || item.type === 'type' || item.type === 'status'){
        this._isMounted && this.setState({filterColumnOpen: true, filterName: item.category})
      }
      else if(item.type === "view-project") {
        history.push(item.query)
      }
      else if(item.type === "homeProjects") history.push("/projects/list" )
      else if(item.type === "homeContracts") history.push("/contracts" )
      else if(item.type === "homeContacts") history.push("/contacts" )
      else if(item.type === "homeCompanies") history.push("/companies" )
      else if(item.type === "homePipelines") history.push("/pipelines" )
      else if(item.type === "homeNotes") history.push("/notes/preview" )
      else if(item.type === "homeConversations") history.push("/conversations/preview" )
      else if(item.type === "homeEmails") history.push("/emails/preview" )
      else if(item.type === "homeTasks") history.push("/tasks/preview" )
      else if(item.type === "homeSearch") history.push("/search" )
      else if(item.type === "homeTime") history.push("/time-sheet" )
      else if(item.type === "timeSheet") history.push("/time-sheet" )
      else if(item.type === "timeTracker") history.push("/time-tracker" )
      else if(item.type === "customFieldsProject") history.push("/custom-fields/project" )
      else if(item.type === "customFieldsContract") history.push("/custom-fields/contract" )
      else if(item.type === "customFieldsContact") history.push("/custom-fields/contact" )
      else if(item.type === "customFieldsCompany") history.push("/custom-fields/company" )
      else if(item.itemType && item.type === "allViews") history.push("/views/"+item.itemType)
    }
  }

  private handleNodesChange = itemTree => {
    this.setState({itemTree});
  }

  private _onRenderGroupHeader = (group: any): JSX.Element | null => {
    return(
      <p className="nav-repo-group m-0">{ group.name }</p>
    );
  }

  private _goToSettings() {
    const domain = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(1).join('.') : process.env.REACT_APP_DOMAIN;
    var url = 'https://account.' + domain;
    window.open(url, "_blank");
  }

  private _applyWebUpdate() {
    window.location.reload();
  }

  private _goToProjects() {
    const {repoData} = this.state;

    this.toggleNav("projects")
    if (window.location.href.indexOf("/projects") === -1
      && window.location.href.indexOf("/projects/clients") === -1
      && window.location.href.indexOf("/projects/stages") === -1
      && window.location.href.indexOf("/projects/board") === -1 ) {
      repoData && history.push('/projects/pipeline')
    }
  }

  private _goToContracts() {
    const {repoData} = this.state;

    this.toggleNav("contracts")
    if (window.location.href.indexOf("/contracts") === -1) {
      repoData && history.push('/contracts')
    }
  }

  private _goToCompanies() {
    const {repoData} = this.state;

    this.toggleNav("companies")
    if (window.location.href.indexOf("/companies") === -1) {
      repoData && history.push('/companies')
    }
  }

  private _goToContacts() {
    const {repoData} = this.state;

    this.toggleNav("contacts")
    if (window.location.href.indexOf("/contacts") === -1) {
      repoData && history.push('/contacts')
    }
  }

  private _goToPipelines() {
    const {repoData} = this.state;

    this.toggleNav("pipelines")
    if (window.location.href.indexOf("/pipelines") === -1) {
      repoData && history.push('/pipelines')
    }
  }

  private _goToNotes() {
    const {repoData} = this.state;

    this.toggleNav("notes")
    if (window.location.href.indexOf("/notes") === -1) {
      repoData && history.push('/notes/preview')
    }
  }

  private _goToConversations() {
    const {repoData} = this.state;

    this.toggleNav("conversations")
    if (window.location.href.indexOf("/conversations") === -1) {
      repoData && history.push('/conversations/preview')
    }
  }

  private _goToTimeTracking() {
    const {repoData} = this.state;

    this.toggleNav("timeTracking")
    if (window.location.href.indexOf("/time-tracker") === -1
    && window.location.href.indexOf("/time-sheet") === -1) {
      repoData && history.push('/time-sheet')
    }
  }

  private _goToCustomFields() {
    const {repoData} = this.state;

    this.toggleNav("customFields")
    if (window.location.href.indexOf("/custom-fields") === -1) {
      repoData && history.push('/custom-fields/project')
    }
  }

  private _goToSearch() {
    const {repoData} = this.state;

    this.toggleNav("search")
    if (window.location.href.indexOf("/search") === -1) {
      repoData && history.push('/search')
    }
  }

  private _goToTasks() {
    const {repoData} = this.state;

    this.toggleNav("tasks")
    if (window.location.href.indexOf("/tasks") === -1) {
      repoData && history.push('/tasks/preview')
    }
  }

  private _goToEmails() {
    const {repoData} = this.state;

    this.toggleNav("emails")
    if (window.location.href.indexOf("/emails") === -1) {
      repoData && history.push('/emails/preview')
    }
  }

  private _goToPermissions() {
    const {repoData} = this.state;

    this.toggleNav("permissions")
    if (window.location.href.indexOf("/permissions") === -1) {
      repoData && history.push('/permissions')
    }
  }

  private _goToHome() {
    const {repoData} = this.state;

    this.toggleNav("home")
    if (window.location.href.indexOf("/home") === -1) {
      repoData && history.push('/home')
    }
  }

  private _getSelNav() {
    if (window.location.href.indexOf("/projects") !== -1) {
      return  "projects";
    } else if (window.location.href.indexOf("/contracts") !== -1) {
      return  "contracts";
    } else if (window.location.href.indexOf("/companies") !== -1) {
      return  "companies";
    } else if (window.location.href.indexOf("/contacts") !== -1) {
      return  "contacts";
    } else if (window.location.href.indexOf("/pipelines") !== -1) {
      return  "pipelines";
    } else if (window.location.href.indexOf("/time") !== -1) {
      return  "timeTracking";
    } else if (window.location.href.indexOf("/custom-fields") !== -1) {
      return  "customFields";
    } else if (window.location.href.indexOf("/notes") !== -1) {
      return  "notes";
    } else if (window.location.href.indexOf("/conversations") !== -1) {
      return  "conversations";
    } else if (window.location.href.indexOf("/home") !== -1) {
      return  "home";
    } else if (window.location.href.indexOf("/search") !== -1) {
      return  "search";
    } else if (window.location.href.indexOf("/tasks") !== -1) {
      return  "tasks";
    } else if (window.location.href.indexOf("/emails") !== -1) {
      return  "emails";
    } else if (window.location.href.indexOf("/permissions") !== -1) {
      return  "permissions";
    } else if (window.location.href.indexOf("/views") !== -1) {
      return  "views";
    }
    return "";
  }

  private addTag(key:string, value:string) {
    //var queryString = "?";
    var params = new URLSearchParams(document.location.search);
    /*let index=0
    params.forEach((value, key)=>{
      if (index === 0) {
        //queryString += "?"
      } else queryString += "&"
      index ++;
      let field:string = params.get(key) || "";
      if (field && field.length > 0) {
        params.set(key,value)
        //queryString +=  key + "=" + value
      }
    })*/
    params.set(key,value)
    history.push("?"+params.toString())
  }

  private saveCustomView() {
    var page: string = '';
    if (this.props.page === 'companiesExplorer') page = '/companies' ;
    else if (this.props.page === 'contactsExplorer') page = '/contacts';
    else if (this.props.page === 'projectsExplorer') page = '/projects/list' + document.location.search;
    else if (this.props.page === 'clientsExplorer') page = '/projects/clients' + document.location.search;
    else if (this.props.page === 'pipelinesExplorer') page = '/projects/pipelines' + document.location.search;
    else if (this.props.page === 'milestonesExplorer') page = '/projects/stages' + document.location.search;
    else if (this.props.page === 'boardExplorer') page = '/projects/board' + document.location.search;
  }

  private _onRenderLink = (link: any): JSX.Element | null => {
    var currentPath = this.props.folderId
    return ( 
      link.type === "trash" ?
        <div className={"folderLink dir " + (link.id === currentPath ? "selItem":"")}>
          <Icon iconName="Trash" className="trash align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "pipelinesList" ?
        <div className={"folderLink " + (this.props.pipelineId === 'all' ? "selItem":"")}>
          <Icon iconName="D365ProjectOperationsIcon" className="recentFiles align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "pipelines" ?
        <div className={"folderLink " + (this.props.pipelineId === link.name ? "selItem":"")}>
          <Icon iconName="D365ProjectOperationsIcon" className="recentFiles align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "projectsList" ?
        <div className={"folderLink " + (this.props.page === "projectsExplorer"
        || this.props.page === "clientsExplorer"
        || this.props.page === "milestonesExplorer"
        || this.props.page === "pipelinesExplorer" ? "selItem":"")}>
          <Icon iconName="BulletedList" className="recentFiles align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "all" ?
      <div className={"folderLink selItem"}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewAll" ?
      <div className={"folderLink " + (this.props.page === "projectsExplorer" && this.props.pipelineId === "all" ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewPipelines" ?
      <div className={"folderLink " + (this.props.page === "pipelinesExplorer" && this.props.pipelineId === "all" ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewPipeline" ?
      <div className={"folderLink " + (this.props.page === "projectsExplorer" && this.props.pipelineId === link.name ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewBoardPipeline" ?
      <div className={"folderLink " + (this.props.page === "boardExplorer" && this.props.pipelineId === link.name ? "selItem":"")}>
        <Icon iconName="TripleColumn" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewActivity" ?
      <div className={"folderLink " + (this.props.page === "projectData" && this.props.query === "all" ? "selItem":"")}>
        <Icon iconName="LineChart" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewNotes" ?
      <div className={"folderLink " + (this.props.page === "projectData" && this.props.query === "notes" ? "selItem":"")}>
        <Icon iconName="QuickNote" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewConversations" ?
      <div className={"folderLink " + (this.props.page === "projectData" && this.props.query === "conversations" ? "selItem":"")}>
        <Icon iconName="QuickNote" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewFiles" ?
      <div className={"folderLink " + (this.props.page === "projectData" && this.props.query === "files" ? "selItem":"")}>
        <Icon iconName="Documentation" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "projectsNothing" ?
      <div className={"folderLink " + (this.props.page === "projectsExplorer" ? "selItem":"")}>
        <Icon iconName="FabricFolder" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "projectsCompany" ?
        <div className={"folderLink " + (this.props.page === "clientsExplorer" ? "selItem":"")}>
          <Icon iconName="CityNext2" className="recentFiles align-top" />
          <span>{ link.name }</span>
        </div>
      : link.type === "projectsMilestone" ?
      <div className={"folderLink " + (this.props.page === "milestonesExplorer" ? "selItem":"")}>
        <Icon iconName="CRMProcesses" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "projectsPipeline" ?
      <div className={"folderLink " + (this.props.page === "pipelinesExplorer" ? "selItem":"")}>
        <Icon iconName="D365ProjectOperationsIcon" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "projectsBoard" ?
      <div className={"folderLink " + (this.props.page === "boardExplorer" ? "selItem":"")}>
        <Icon iconName="TripleColumn" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "contractsList" ?
      <div className={"folderLink " + (this.props.page === link.page && document.location.search === link.query ? "selItem":"")}>
        <img alt='view' style={{height: '22px', width: '22px', marginLeft: '6px', marginRight: '12px'}} src={process.env.PUBLIC_URL + '/icons/contracts.svg' } />
        <span>{ link.name }</span>
      </div>
      : link.type === "contractsDashboard" ?
      <div className={"folderLink " + (this.props.page === "contractsDashboard" ? "selItem":"")}>
        <img alt='view' style={{height: '22px', width: '22px', marginLeft: '6px', marginRight: '12px'}} src={process.env.PUBLIC_URL + '/icons/contracts.svg' } />
        <span>{ link.name }</span>
      </div>
      : link.type === "companiesList" ?
      <div className={"folderLink " + (this.props.page === "companiesExplorer" ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "companiesTrash" ?
      <div className={"folderLink " + (this.props.page === "companiesExplorerTrash" ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "contactsList" ?
      <div className={"folderLink " + (this.props.page === "contactsExplorer" ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "contactsTrash" ?
      <div className={"folderLink " + (this.props.page === "contactsExplorerTrash" ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeList" ?
      <div className={"folderLink " + (this.props.page === "home" ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "custom" ?
       <div className={"folderLink "} style={{opacity:.6}}>
         <Icon iconName="SingleColumnEdit" className="recentFiles align-top" />
         <span >{ link.name }</span>
       </div>
      : link.type === "category" ?
      <div className="folderLink">
        { link.field === 'pipelineId' ? <img alt='pipelines' style={{height: '22px', width: '22px', marginLeft: '6px', marginRight: '12px'}} src={process.env.PUBLIC_URL + '/icons/pipelines.svg'} />
          : link.field === 'projectIds' ? <img alt='projects' style={{height: '22px', width: '22px', marginLeft: '6px', marginRight: '12px'}} src={process.env.PUBLIC_URL + '/icons/projects.svg'} />
          : link.field === 'contractIds' ? <img alt='contracts' style={{height: '22px', width: '22px', marginLeft: '6px', marginRight: '12px'}} src={process.env.PUBLIC_URL + '/icons/contracts.svg'} />
          : link.field === 'contactIds' ? <img alt='contacts' style={{height: '22px', width: '22px', marginLeft: '6px', marginRight: '12px'}} src={process.env.PUBLIC_URL + '/icons/contacts.svg'} />
          : link.field === 'companyIds' ? <img alt='companies' style={{height: '22px', width: '22px', marginLeft: '6px', marginRight: '12px'}} src={process.env.PUBLIC_URL + '/icons/companies.svg'} />
          : link.field === 'type' ? <img alt='contacts' style={{height: '22px', width: '22px', marginLeft: '6px', marginRight: '12px'}} src={process.env.PUBLIC_URL + '/icons/contacts.svg'} />
          : link.field === 'Company' ? <img alt='companies' style={{height: '22px', width: '22px', marginLeft: '6px', marginRight: '12px'}} src={process.env.PUBLIC_URL + '/icons/companies.svg'} />
          : link.field === 'status' ? <Icon iconName={'StatusCircleRing'} className="recentFiles align-top"/> 
          : <Icon iconName={'Contact'} className="recentFiles align-top"/> 
        }
        <span>{ link.name }</span>
      </div>
      : link.type === "tagMilestone" ?
      <div className={"folderLink " }>
        <Icon iconName="CircleShapeSolid" className="tag" 
          style={{color: link.name === 'Done' ? '#07c873': link.name === 'Pending' ? '#c4c4c4' : link.name === 'In progress' ? '#fec06e' : 'rgb(50, 49, 48)', fontSize:12, textAlign:'center'}} 
        />
        <span>{ link.name }</span>
      </div>
      : link.type === "tagCompany" ?
      <div className={"folderLink "}>
        <Icon iconName="Tag" className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "pipelineId" ?
      <div className={"folderLink"}>
        <Icon iconName="D365ProjectOperationsIcon" className="tag" />
        <span className='filterSpan'>{ link.name }</span>
      </div>
      : link.type === "status" ?
      <div className={"folderLink"}>
        <Icon iconName="Work" className="tag" />
        <span className='filterSpan'>{ link.name }</span>
      </div>
      : link.type === "contactType" ?
      <div className={"folderLink"}>
        <Icon iconName="Contact" className="tag" />
        <span className='filterSpan'>{ link.name }</span>
      </div>
      : link.type === "type" ?
      <div className={"folderLink"}>
        <Icon iconName={link.id === 'note' ? 'QuickNote' : link.id === 'email' ? 'Mail' : link.id === 'conversation' ? 'OfficeChat' : link.id === 'task' ? 'IconSetsFlag' : link.id === 'activity' ? 'LineChart' : link.id === 'meeting' ? 'ActivityFeed' : 'Documentation'} className="tag" />
        <span className='filterSpan'>{ link.name }</span>
      </div>
      : link.type === "projectIds" ?
      <div className={"folderLink"}>
        <Persona className='project-persona mx-2' name={link.name} primaryText='' size='extra-small' textAlignment='center' avatar={{ color: "colorful" }}/>
        <span className='filterSpan'>{ link.name }</span>
      </div>
      : link.type === "contractIds" ?
      <div className={"folderLink"}>
        <Persona className='project-persona mx-2' name={link.name} primaryText='' size='extra-small' textAlignment='center' avatar={{ color: "colorful" }}/>
        <span className='filterSpan'>{ link.name }</span>
      </div>
      : link.type === "contactIds" ?
      <div className={"folderLink"}>
        <Persona className='contact-persona mx-2' name={link.name} primaryText='' size='extra-small' textAlignment='center' avatar={{ color: "colorful" }}/>
        <span className='filterSpan'>{ link.name }</span>
      </div>
      : link.type === "companyIds" ?
      <div className={"folderLink"}>
        <Persona className='company-persona mx-2' name={link.name} primaryText='' size='extra-small' textAlignment='center' avatar={{ color: "colorful" }}/>
        <span className='filterSpan'>{ link.name }</span>
      </div>
      : link.type === "viewsHeader" ?
      <div className={"folderLink " + (this.checkSelFilter(link) ? "filter-selected" : "")} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
        <Icon iconName="View" className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "allViews" ?
      <div className={"folderLink"}>
        <Icon iconName="BulletedList" className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "allViews-views" ?
      <div className={"folderLink selItem"}>
        <Icon iconName="BulletedList" className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "viewsQuickAccess" ?
      <div className={"folderLink " + (this.checkSelFilter(link) ? "filter-selected" : "")} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
        <Icon iconName="FavoriteStar" className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "filtersHeader" ?
      <div className={"folderLink " + (this.checkSelFilter(link) ? "filter-selected" : "")} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
        <Icon iconName="Filter" className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "filterTags" ?
      <div className={"folderLink " + (this.checkSelFilter(link) ? "filter-selected" : "")} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
        <Icon iconName={link.iconName} className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "optionsHeader" ?
      <div className={"folderLink " + (this.checkSelFilter(link) ? "filter-selected" : "")} onClick={ evt => { evt.preventDefault(); this.setState({goBack: 0}) } }>
        <Icon iconName="FilterSettings" className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "view-projects" || link.type === "view-contracts" || link.type === "view-contacts" || link.type === "view-companies" || link.type === "view-notes" || link.type === "view-conversations" || link.type === "view-tasks" || link.type === "view-emails" || link.type === "view-permissions" || link.type === "view-pipelines" || link.type === "view-views" ?
      <div className={"view-folderlink folderLink " + (this.props.page === link.page && document.location.search === link.query ? "selItem":"")}>
        <img alt='view' style={{height: '22px', width: '22px', marginLeft: '6px', marginRight: '12px'}} src={process.env.PUBLIC_URL + '/icons/'+ (link.type === "view-projects" ? 'projects.svg' : link.type === "view-contracts" ? 'contracts.svg' : link.type === "view-companies" ? 'companies.svg' : link.type === "view-contacts" ? 'contacts.svg' : link.type === "view-pipelines" ? 'pipelines.svg' : link.type === "view-emails" ? 'emails.svg' : link.type === "view-notes" ? 'documents.svg' : link.type === "view-conversations" ? 'conversations.svg' : link.type === "view-tasks" ? 'tasks.svg' : '') } />
        <span className='treeSpan' title={link.name}>{ link.name }</span>
      </div>
      : link.type === "view-custom" ?
      <div className={"folderLink " + (this.checkSelected() ? "selItem" : "")} style={{opacity: (this.checkSelected() ? 1: .6)}} onClick={ evt => { evt.preventDefault(); if (this.checkSelected()) this.saveCustomView()}}>
        <Icon iconName="SingleColumnEdit" className="recentFiles align-top" />
        <span title={link.name}>{ link.name }</span>
      </div>
      : link.type === "view-custom-views" ?
      <div className={"folderLink "} style={{opacity: .6}} onClick={ evt => { evt.preventDefault(); if (this.checkSelected()) this.saveCustomView()}}>
        <Icon iconName="SingleColumnEdit" className="recentFiles align-top" />
        <span title={link.name}>{ link.name }</span>
      </div>
      : link.type === "view-project" ?
      <div className={"view-folderlink folderLink " + (document.location.search === link.query || ((!document.location.search || document.location.search === '?type=All') && link.query === '') ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span className='filterSpan' title={link.name}>{ link.name }</span>
      </div>
      : link.type === "tagType" ?
      <div className={"folderLink tagLink " + (this.checkSelFilter(link,'type') ? "selItem" : "")}>
        <Icon iconName={link.iconName} className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "tagButton" ?
      <div className={"folderLink "}>
        <Icon iconName={link.iconName} className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "tagUndefined" ?
      <div className={"folderLink "} style={{opacity: link.iconName === 'ClearFilter' ? .6 : 1}}>
        <Icon iconName={link.iconName || 'Tag'} className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeProjects" ?
      <div className={"folderLink "}>
        <Icon iconName='Work' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeContracts" ?
      <div className={"folderLink "}>
        <Icon iconName='TextDocument' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeContacts" ?
      <div className={"folderLink "}>
        <Icon iconName='Contact' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeCompanies" ?
      <div className={"folderLink "}>
        <Icon iconName='CityNext2' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homePipelines" ?
      <div className={"folderLink "}>
        <Icon iconName='D365ProjectOperationsIcon' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeNotes" ?
      <div className={"folderLink "}>
        <Icon iconName='QuickNote' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeConversations" ?
      <div className={"folderLink "}>
        <Icon iconName='CannedChat' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeTasks" ?
      <div className={"folderLink "}>
        <Icon iconName='IconSetsFlag' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeEmails" ?
      <div className={"folderLink "}>
        <Icon iconName='Mail' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeSearch" ?
      <div className={"folderLink "}>
        <Icon iconName='Search' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "homeTime" ?
      <div className={"folderLink "}>
        <Icon iconName='Clock' className="tag" />
        <span>{ link.name }</span>
      </div>
      : link.type === "timeSheet" ?
      <div className={"folderLink " + (this.props.page === link.type ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "timeTracker" ?
      <div className={"folderLink " + (this.props.page === link.type ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type && link.type.startsWith("customFields") ?
      <div className={"folderLink " + (this.props.page === link.type ? "selItem":"")}>
        <Icon iconName="BulletedList" className="recentFiles align-top" />
        <span>{ link.name }</span>
      </div>
      : link.type === "project" ?
      <div className={"folderLink " + (this.props.page === link.type ? "selItem":"")}>
        <Persona primaryText='' size='small' className="recentFiles align-top mr-2" name={link.name} />
        <span>{ link.name }</span>
      </div>
      : <div className="link">
        <span>{ link.name }</span>
      </div>
    )
  }

  private _getProjectsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];

    if(repoData) {
      this.filtersAdded = false;
      var internalRepos = [repoData]

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree.unshift({
          
          id: repo.id,
          hasChildren: true,
          isExpanded: true,
          state: {
            expanded: true
          },
          children: [
            {
              key: "quickAccess-" + repo.id,
              id: "quickAccess-" + repo.id,
              name: i18n.t('app:quickAccess'),
              isExpanded: true,
              type: "viewsQuickAccess",
              itemType: "project",
              state: {
                expanded: true
              },
              hasChildren: true,
              children: this.state.views
            },
            {
              key: "viewAll-" + repo.id,
              id: "viewAll-" + repo.id,
              name: i18n.t('app:views'),
              isExpanded: true,
              type: "viewsHeader",
              itemType: "project",
              state: {
                expanded: true
              },
              hasChildren: true,
              children: [
                {
                  key: "allViews",
                  id: "allViews",
                  name: i18n.t('app:allViews'),
                  type: 'allViews',
                  itemType: "project",
                  state: {},
                },
                {
                  key: "custom-" + repo.id,
                  id: "custom-" + repo.id,
                  name: i18n.t('app:addCustomView'),
                  type: "view-custom",
                  state: {}
                },
              ]
            },
          ]
        })
      }
      
      if (!this.filtersAdded) this._getTags(itemTree[0].children);
      this.filtersAdded = true;
    }
  }

  private _getContractsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];

    if(repoData) {
      this.filtersAdded = false;
      var internalRepos = [repoData]

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree.unshift({
          
          id: repo.id,
          hasChildren: true,
          isExpanded: true,
          state: {
            expanded: true
          },
          children: [
            {
              key: "quickAccess-" + repo.id,
              id: "quickAccess-" + repo.id,
              name: i18n.t('app:quickAccess'),
              isExpanded: true,
              type: "viewsQuickAccess",
              itemType: "contract",
              state: {
                expanded: true
              },
              hasChildren: true,
              children: this.state.views
            },
            {
              key: "viewAll-" + repo.id,
              id: "viewAll-" + repo.id,
              name: i18n.t('app:views'),
              isExpanded: true,
              type: "viewsHeader",
              itemType: "contract",
              state: {
                expanded: true
              },
              hasChildren: true,
              children: [
                {
                  key: "allViews",
                  id: "allViews",
                  name: i18n.t('app:allViews'),
                  type: 'allViews',
                  itemType: "contract",
                  state: {},
                },
                {
                  key: "custom-" + repo.id,
                  id: "custom-" + repo.id,
                  name: i18n.t('app:addCustomView'),
                  type: "view-custom",
                  state: {}
                },
              ]
            },
          ]
        })
      }
      
      if (!this.filtersAdded) this._getContractsFilters(itemTree[0].children);
      this.filtersAdded = true;
    }
  }

  private _getCustomViewsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    var folders: any = this.props.items
    if(repoData && folders) {
        // Sorting folders by name

        if (folders) {

          var internalRepos = [repoData]

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];

            itemTree.unshift({
              children: [
                {
                  key: "viewOptionsHeader-" + repo.id,
                  id: repo.id,
                  name: i18n.t('app:viewOptions'),
                  isExpanded: true,
                  type: "optionsHeader",
                  children: [
                    {
                      key: "viewHeader-" + repo.id,
                      id: repo.id,
                      name: 'View as',
                      isExpanded: true,
                      type: "header",
                      children: [
                        {
                          key: "projectslist-" + repo.id,
                          id: repo.id,
                          name: 'List view',
                          type: "projectsList",
                        },
                        {
                          key: "projectsboard-" + repo.id,
                          id: repo.id,
                          name: 'Board view',
                          type: "projectsBoard",
                        }
                      ]
                    },
                    {
                      key: "groupHeader-" + repo.id,
                      id: repo.id,
                      name: 'Group by',
                      isExpanded: true,
                      type: "header",
                      children: [
                        {
                          key: "projectsNothing-" + repo.id,
                          id: repo.id,
                          name: 'Nothing',
                          type: "projectsNothing",
                        },
                        {
                          key: "projectsCompany-" + repo.id,
                          id: repo.id,
                          name: 'Company',
                          type: "projectsCompany",
                        },
                        {
                          key: "projectspipelines-" + repo.id,
                          id: repo.id,
                          name: 'Pipeline',
                          type: "projectsPipeline",
                        },
                        {
                          key: "projectsmilestone-" + repo.id,
                          id: repo.id,
                          name: 'Stage',
                          type: "projectsMilestone",
                        },
                      ]
                    }
                  ]
                }
              ]
            })
          }

          this._isMounted && this.setState({
            customViews: itemTree
          });   
        }
      }
  }

  private _getProjectViewsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    
    if(repoData) {
      this.filtersAdded = false;
      var internalRepos = [repoData]

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "custom-" + repo.id,
            id: "custom-" + repo.id,
            name: i18n.t('app:addCustomView'),
            type: "view-custom",
          },
          {
            key: "viewAllHeader-" + repo.id,
            id: "viewAllHeader-" + repo.id,
            name: i18n.t('app:views'),
            isExpanded: true,
            type: "viewsHeader",
            state: {expanded: true},
            hasChildren: true,
            children: this.state.views
          }
        ]
      }

      if (!this.filtersAdded) this._getSearchFilters(itemTree);
      this.filtersAdded = true;
    }
  }

  private _getContractViewsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    
    if(repoData) {
      this.filtersAdded = false;
      var internalRepos = [repoData]

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "custom-" + repo.id,
            id: "custom-" + repo.id,
            name: i18n.t('app:addCustomView'),
            type: "view-custom",
          },
          {
            key: "viewAllHeader-" + repo.id,
            id: "viewAllHeader-" + repo.id,
            name: i18n.t('app:views'),
            isExpanded: true,
            type: "viewsHeader",
            state: {expanded: true},
            hasChildren: true,
            children: this.state.views
          }
        ]
      }

      if (!this.filtersAdded) this._getSearchFilters(itemTree);
      this.filtersAdded = true;
    }
  }

  private _getCompaniesTree = () => {

    const { repoData } = this.state;
    var itemTree: any = [];
    var items: any = this.props.items

    if(repoData && items) {
        this.filtersAdded = false;
        if (items) {

          var internalRepos = [repoData]

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];
  
            itemTree = [
              {
                key: "quickAccess-" + repo.id,
                id: "quickAccess-" + repo.id,
                name: i18n.t('app:quickAccess'),
                isExpanded: true,
                type: "viewsQuickAccess",
                itemType: "company",
                state: {
                  expanded: true
                },
                hasChildren: true,
                children: this.state.views
              },
              {
                key: "viewAll-" + repo.id,
                id: "viewAll-" + repo.id,
                name: i18n.t('app:views'),
                isExpanded: true,
                type: "viewsHeader",
                itemType: "company",
                state: {
                  expanded: true
                },
                hasChildren: true,
                children: [
                  {
                    key: "allViews",
                    id: "allViews",
                    name: i18n.t('app:allViews'),
                    type: 'allViews',
                    itemType: "company",
                    state: {},
                  },
                  {
                    key: "custom-" + repo.id,
                    id: "custom-" + repo.id,
                    name: i18n.t('app:addCustomView'),
                    type: "view-custom",
                    state: {}
                  },
                ]
              },
            ]
          }

          if (!this.filtersAdded) this._getCompaniesFilters(itemTree);
          this.filtersAdded = true;
        }
      }
  }

  private _getCompanyViewsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    
    if(repoData) {
      this.filtersAdded = false;
      var internalRepos = [repoData]

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "viewAllHeader-" + repo.id,
            id: "viewAllHeader-" + repo.id,
            name: i18n.t('app:views'),
            isExpanded: true,
            state: {expanded: true},
            hasChildren: true,
            type: "viewsHeader",
            children: this.state.views
          }
        ]
      }

      if (!this.filtersAdded) this._getSearchFilters(itemTree);
      this.filtersAdded = true;
    }
  }

  private _getContactsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    var items: any = this.props.items

    if(repoData && items) {
      this.filtersAdded = false;
      if (items) {

        var internalRepos = [repoData]

        for (let i = 0; i < internalRepos.length; i++) {
          let repo = internalRepos[i];

          itemTree = [
            {
              key: "quickAccess-" + repo.id,
              id: "quickAccess-" + repo.id,
              name: i18n.t('app:quickAccess'),
              isExpanded: true,
              type: "viewsQuickAccess",
              itemType: "contact",
              state: {
                expanded: true
              },
              hasChildren: true,
              children: this.state.views
            },
            {
              key: "viewAll-" + repo.id,
              id: "viewAll-" + repo.id,
              name: i18n.t('app:views'),
              isExpanded: true,
              type: "viewsHeader",
              itemType: "contact",
              state: {
                expanded: true
              },
              hasChildren: true,
              children: [
                {
                  key: "allViews",
                  id: "allViews",
                  name: i18n.t('app:allViews'),
                  type: 'allViews',
                  itemType: "contact",
                  state: {},
                },
                {
                  key: "custom-" + repo.id,
                  id: "custom-" + repo.id,
                  name: i18n.t('app:addCustomView'),
                  type: "view-custom",
                  state: {}
                },
              ]
            },
          ]
        }

        if (!this.filtersAdded) this._getContactsFilters(itemTree);
        this.filtersAdded = true;
      }
    }
  }

  private _getContactViewsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    
    if(repoData) {
      this.filtersAdded = false;
      var internalRepos = [repoData]

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "custom-" + repo.id,
            id: "custom-" + repo.id,
            name: i18n.t('app:addCustomView'),
            type: "view-custom",
          },
          {
            key: "viewAllHeader-" + repo.id,
            id: "viewAllHeader-" + repo.id,
            name: i18n.t('app:views'),
            isExpanded: true,
            state: {expanded: true},
            hasChildren: true,
            type: "viewsHeader",
            children: this.state.views
          }
        ]
      }

      if (!this.filtersAdded) this._getSearchFilters(itemTree);
      this.filtersAdded = true;
    }
  }

  private _getNotesTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    var items: any = this.props.items

    if(repoData && items) {
        this.filtersAdded = false;
        if (items) {

          var internalRepos = [repoData]

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];
  
            itemTree = [
              {
                key: "quickAccess-" + repo.id,
                id: "quickAccess-" + repo.id,
                name: i18n.t('app:quickAccess'),
                isExpanded: true,
                type: "viewsQuickAccess",
                itemType: "note",
                state: {
                  expanded: true
                },
                hasChildren: true,
                children: this.state.views
              },
              {
                key: "viewAll-" + repo.id,
                id: "viewAll-" + repo.id,
                name: i18n.t('app:views'),
                isExpanded: true,
                type: "viewsHeader",
                itemType: "note",
                state: {
                  expanded: true
                },
                hasChildren: true,
                children: [
                  {
                    key: "allViews",
                    id: "allViews",
                    name: i18n.t('app:allViews'),
                    type: 'allViews',
                    itemType: "note",
                    state: {},
                  },
                  {
                    key: "custom-" + repo.id,
                    id: "custom-" + repo.id,
                    name: i18n.t('app:addCustomView'),
                    type: "view-custom",
                    state: {}
                  },
                ]
              },
            ]
          }

          if (!this.filtersAdded) this._getNotesFilters(itemTree);
          this.filtersAdded = true;
        }
      }
  }

  private _getConversationsTree = () => {
    
    const { repoData } = this.state;
    var itemTree: any = [];
    var items: any = this.props.items

    if(repoData && items) {
        this.filtersAdded = false;
        if (items) {

          var internalRepos = [repoData]

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];
  
            itemTree = [
              {
                key: "quickAccess-" + repo.id,
                id: "quickAccess-" + repo.id,
                name: i18n.t('app:quickAccess'),
                isExpanded: true,
                type: "viewsQuickAccess",
                itemType: "conversation",
                state: {
                  expanded: true
                },
                hasChildren: true,
                children: this.state.views
              },
              {
                key: "viewAll-" + repo.id,
                id: "viewAll-" + repo.id,
                name: i18n.t('app:views'),
                isExpanded: true,
                type: "viewsHeader",
                itemType: "conversation",
                state: {
                  expanded: true
                },
                hasChildren: true,
                children: [
                  {
                    key: "allViews",
                    id: "allViews",
                    name: i18n.t('app:allViews'),
                    type: 'allViews',
                    itemType: "conversation",
                    state: {},
                  },
                  {
                    key: "custom-" + repo.id,
                    id: "custom-" + repo.id,
                    name: i18n.t('app:addCustomView'),
                    type: "view-custom",
                    state: {}
                  },
                ]
              },
            ]
          }

          if (!this.filtersAdded) this._getConversationsFilters(itemTree);
          this.filtersAdded = true;
        }
      }
  }

  private _getHomeTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];

    if(repoData) {
      var internalRepos = [repoData]

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];

        itemTree = [
          {
            key: "homelist-" + repo.id,
            id: "homelist-" + repo.id,
            name: 'Home',
            type: "homeList",
          },
          {
            key: "homelist-" + 1,
            id: "homelist-" + 1,
            name: 'Projects',
            type: "homeProjects"
          },
          {
            key: "homelist-" + 6,
            id: "homelist-" + 6,
            name: 'Contracts',
            type: "homeContracts"
          },
          {
            key: "homelist-" + 2,
            id: "homelist-" + 2,
            name: 'Contacts',
            type: "homeContacts"
          },
          {
            key: "homelist-" + 3,
            id: "homelist-" + 3,
            name: 'Companies',
            type: "homeCompanies"
          },
          {
            key: "homelist-" + 4,
            id: "homelist-" + 4,
            name: 'Pipelines',
            type: "homePipelines"
          },
          {
            key: "homelist-" + 6,
            id: "homelist-" + 6,
            name: 'Notes',
            type: "homeNotes"
          },
          {
            key: "homelist-" + 7,
            id: "homelist-" + 7,
            name: 'Conversations',
            type: "homeConversations"
          },
          {
            key: "homelist-" + 8,
            id: "homelist-" + 8,
            name: 'Tasks',
            type: "homeTasks"
          },
          {
            key: "homelist-" + 10,
            id: "homelist-" + 10,
            name: 'Emails',
            type: "homeEmails"
          },
          {
            key: "homelist-" + 9,
            id: "homelist-" + 9,
            name: 'Search',
            type: "homeSearch"
          },
          /*{
            key: "homelist-" + 5,
            id: "homelist-" + 5,
            name: 'Time tracking',
            type: "homeTime"
          }*/
        ]
      }
      this._isMounted && this.setState({
        itemTree: itemTree
      });  
    }
  }

  private _getPipelinesTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    
    if(repoData) {

      var internalRepos = [repoData]

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "quickAccess-" + repo.id,
            id: "quickAccess-" + repo.id,
            name: i18n.t('app:quickAccess'),
            isExpanded: true,
            type: "viewsQuickAccess",
            itemType: "pipeline",
            state: {
              expanded: true
            },
            hasChildren: true,
            children: this.state.views
          },
          {
            key: "viewAll-" + repo.id,
            id: "viewAll-" + repo.id,
            name: i18n.t('app:views'),
            isExpanded: true,
            type: "viewsHeader",
            itemType: "pipeline",
            state: {
              expanded: true
            },
            hasChildren: true,
            children: [
              {
                key: "allViews",
                id: "allViews",
                name: i18n.t('app:allViews'),
                type: 'allViews',
                itemType: "pipeline",
                state: {},
              },
              {
                key: "custom-" + repo.id,
                id: "custom-" + repo.id,
                name: i18n.t('app:addCustomView'),
                type: "view-custom",
                state: {}
              },
            ]
          },
        ]
      }

      this._isMounted && this.setState({
        itemTree: itemTree
      });
    }
  }

  private _getTimeTrackingTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    
    if(repoData) {

      var internalRepos = [repoData]

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "viewAllHeader-" + repo.id,
            id: "viewAllHeader-" + repo.id,
            name: i18n.t('app:views'),
            isExpanded: true,
            state: {expanded: true},
            hasChildren: true,
            type: "header",
            children: [
              {
                key: "sheet-" + repo.id,
                id: "sheet-" + repo.id,
                name: 'My time sheet',
                type: "timeSheet",
              },
              {
                key: "tracker-" + repo.id,
                id: "tracker-" + repo.id,
                name: 'My time tracker',
                type: "timeTracker",
              },
              {
                key: "custom-" + repo.id,
                id: "custom-" + repo.id,
                name: 'Custom view',
                type: "custom",
              }
            ]
          }
        ]
      }

      this._isMounted && this.setState({
        itemTree: itemTree
      });
    }
  }

  private _getCustomFieldsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    
    if(repoData) {

      var internalRepos = [repoData]

      for (let i = 0; i < internalRepos.length; i++) {
        let repo = internalRepos[i];
        itemTree = [
          {
            key: "viewAllHeader-" + repo.id,
            id: "viewAllHeader-" + repo.id,
            name: i18n.t('app:groups'),
            isExpanded: true,
            state: {expanded: true},
            hasChildren: true,
            type: "header",
            children: [
              {
                key: "projectFields",
                id: "projectFields",
                name: i18n.t('app:project'),
                type: "customFieldsProject",
              },
              {
                key: "contractFields",
                id: "contractFields",
                name: i18n.t('app:contract'),
                type: "customFieldsContract",
              },
              {
                key: "contactFields",
                id: "contactFields",
                name: i18n.t('app:contact'),
                type: "customFieldsContact",
              },
              {
                key: "companyfields",
                id: "companyfields",
                name: i18n.t('app:company'),
                type: "customFieldsCompany",
              },
            ]
          }
        ]
      }

      this._isMounted && this.setState({
        itemTree: itemTree
      });
    }
  }

  private _getEmailsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    var items: any = this.props.items
    
    if(repoData && items) {
        this.filtersAdded = false;
        if (items) {

          var internalRepos = [repoData]

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];
  
            itemTree = [
              {
                key: "quickAccess-" + repo.id,
                id: "quickAccess-" + repo.id,
                name: i18n.t('app:quickAccess'),
                isExpanded: true,
                type: "viewsQuickAccess",
                itemType: "email",
                state: {
                  expanded: true
                },
                hasChildren: true,
                children: this.state.views
              },
              {
                key: "viewAll-" + repo.id,
                id: "viewAll-" + repo.id,
                name: i18n.t('app:views'),
                isExpanded: true,
                type: "viewsHeader",
                itemType: "email",
                state: {
                  expanded: true
                },
                hasChildren: true,
                children: [
                  {
                    key: "allViews",
                    id: "allViews",
                    name: i18n.t('app:allViews'),
                    type: 'allViews',
                    itemType: "email",
                    state: {},
                  },
                  {
                    key: "custom-" + repo.id,
                    id: "custom-" + repo.id,
                    name: i18n.t('app:addCustomView'),
                    type: "view-custom",
                    state: {}
                  },
                ]
              },
            ]
          }

          if (!this.filtersAdded) this._getTasksFilters(itemTree);
          this.filtersAdded = true;
        }
      }
  }

  private _getViewsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    if(repoData) {
        this.filtersAdded = false;
        itemTree = [
          {
            key: "quickAccess",
            id: "quickAccess",
            name: i18n.t('app:quickAccess'),
            isExpanded: true,
            type: "viewsQuickAccess",
            itemType: this.props.itemType,
            state: {
              expanded: true
            },
            hasChildren: true,
            children: this.state.views,
          },
          {
            key: "viewAll",
            id: "viewAll",
            name: i18n.t('app:views'),
            isExpanded: true,
            type: "viewsHeader",
            itemType: this.props.itemType,
            state: {
              expanded: true
            },
            hasChildren: true,
            children: [
              {
                key: "allViews",
                id: "allViews",
                name: i18n.t('app:allViews'),
                type: 'allViews-views',
                itemType: this.props.itemType,
                state: {},
              },
              {
                key: "custom",
                id: "custom",
                name: i18n.t('app:addCustomView'),
                type: "view-custom-views",
                state: {}
              },
            ]
          },
        ]
        this._isMounted && this.setState({ itemTree: itemTree.slice(0)})
        this.filtersAdded = true;
      }
  }

  private _getSearchTree = () => {

    const { repoData } = this.state;
    var itemTree: any = [];
    var items: any = this.props.items

    if(repoData && items) {
        this.filtersAdded = false;
        if (items) {
          itemTree = [];
          if (!this.filtersAdded) this._getSearchPageFilters(itemTree);
          this.filtersAdded = true;
        }
      }
  }

  private _getTasksTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    var items: any = this.props.items
    
    if(repoData && items) {
        this.filtersAdded = false;
        if (items) {

          var internalRepos = [repoData]

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];
  
            itemTree = [
              {
                key: "quickAccess-" + repo.id,
                id: "quickAccess-" + repo.id,
                name: i18n.t('app:quickAccess'),
                isExpanded: true,
                type: "viewsQuickAccess",
                itemType: "task",
                state: {
                  expanded: true
                },
                hasChildren: true,
                children: this.state.views
              },
              {
                key: "viewAll-" + repo.id,
                id: "viewAll-" + repo.id,
                name: i18n.t('app:views'),
                isExpanded: true,
                type: "viewsHeader",
                itemType: "task",
                state: {
                  expanded: true
                },
                hasChildren: true,
                children: [
                  {
                    key: "allViews",
                    id: "allViews",
                    name: i18n.t('app:allViews'),
                    type: 'allViews',
                    itemType: "task",
                    state: {},
                  },
                  {
                    key: "custom-" + repo.id,
                    id: "custom-" + repo.id,
                    name: i18n.t('app:addCustomView'),
                    type: "view-custom",
                    state: {}
                  },
                ]
              },
            ]
          }

          if (!this.filtersAdded) this._getTasksFilters(itemTree);
          this.filtersAdded = true;
        }
      }
  }

  private _getPermissionsTree = () => {
    const { repoData } = this.state;
    var itemTree: any = [];
    var items: any = this.props.items
    
    if(repoData && items) {
        this.filtersAdded = false;
        if (items) {

          var internalRepos = [repoData]

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];
  
            itemTree = [
              {
                key: "quickAccess-" + repo.id,
                id: "quickAccess-" + repo.id,
                name: i18n.t('app:quickAccess'),
                isExpanded: true,
                type: "viewsQuickAccess",
                itemType: "permission",
                state: {
                  expanded: true
                },
                hasChildren: true,
                children: this.state.views
              },
              {
                key: "viewAll-" + repo.id,
                id: "viewAll-" + repo.id,
                name: i18n.t('app:views'),
                isExpanded: true,
                type: "viewsHeader",
                itemType: "permission",
                state: {
                  expanded: true
                },
                hasChildren: true,
                children: [
                  {
                    key: "allViews",
                    id: "allViews",
                    name: i18n.t('app:allViews'),
                    type: 'allViews',
                    itemType: "permission",
                    state: {},
                  },
                  {
                    key: "custom-" + repo.id,
                    id: "custom-" + repo.id,
                    name: i18n.t('app:addCustomView'),
                    type: "view-custom",
                    state: {}
                  },
                ]
              },
            ]
          }

          if (!this.filtersAdded) this._getTasksFilters(itemTree);
          this.filtersAdded = true;
        }
      }
  }

  private _addFiltersToTree = (type, filters, checkbox) => {
    var params = new URLSearchParams(document.location.search);
    var tree = this.state.itemTree.slice(0);
    for (let i=0; i< tree.length; i++) {
      if (tree[i].field === type) {
        if (filters && filters.length > 0) {
          tree[i].children = filters;
        } else {
          tree[i].children = [ 
          {
            key: checkbox,
            id: checkbox,
            name: checkbox === 'none' ? i18n.t('app:none') : checkbox === 'all' ? i18n.t('app:all') : i18n.t('app:noFilter') ,
            type: 'tagUndefined',
            category: type,
            iconName: checkbox === 'none' ? 'Tag' : checkbox === 'all' ? 'Tag' : 'ClearFilter' ,
          }]
        }
        if (this.props.page !== 'search') {
          let query = ''
          if (checkbox === 'noFilter') {
            params.delete(type)
          } else if (checkbox === 'none') {
            params.set(type,checkbox)
          } else if (checkbox === 'all') {
            params.set(type,checkbox)
          } else {
            tree[i].children.map(tag=>{
              if (tag.id !== 'addTag') query === '' ? query = tag.id : query += (','+tag.id)
              return null;
            })
            params.set(type,query)
          }
        } else {
          this.setState({itemTree: tree})
        }
      }
    }
    this.props.page !== 'search' && history.push("?"+params.toString())
  }

  private _addFiltersToSearchSidebar = (type, filters) => {
    if (type === 'projectIds') this.setState({currentProjects: filters})
    else if (type === 'contractIds') this.setState({currentContracts: filters})
    else if (type === 'contactIds') this.setState({currentContacts: filters})
    else if (type === 'companyIds') this.setState({currentCompanies: filters})
    else if (type === 'PROJECT' || type === 'CONTRACT' || type === 'CONTACT' || type === 'COMPANY' || type === 'NOTE' || type === 'EMAIL' || type === 'CONVERSATION' || type === 'TASK') this.setState({currentFilters: filters})
  }

  private _dateCreated = (date: Date | null | undefined) => {
    if(date) {
      this._isMounted && this.setState({ initialDate: date},
        () => {
          var searchQuery = this.props.searchQuery
          const params = new URLSearchParams(searchQuery);

          var startDate = params.get("start_date");
          if(startDate) {
            params.delete("start_date")
            searchQuery = params.toString();
          }

          //this.props.callbackFunction({searchQuery:queryString})
          this.props.listExplorerRef.addTag({key:'Time: '+date.toDateString(),name:'Time: '+date.toDateString()})
          /*history.push({
            pathname: '/projects',
            search: queryString
          })*/
        })
    }
  }

  private _lastActivity = (date: Date | null | undefined) => {
    if(date) {
      this._isMounted && this.setState({ finalDate: date},
        () => {
          var searchQuery = this.props.searchQuery
          const params = new URLSearchParams(searchQuery);

          var endDate = params.get("end_date");
          if(endDate) {
            params.delete("end_date")
            searchQuery = params.toString();
          }

          var endOfTheDay = new Date(date);
          endOfTheDay.setHours(23,59,59,999);

          //this.props.callbackFunction({searchQuery:queryString})
          this.props.listExplorerRef.addTag({key:'Last activity: '+endOfTheDay.toDateString(),name:'Last activity: '+endOfTheDay.toDateString()})
          /*history.push({
            pathname: '/projects',
            search: queryString
          })*/
        })
    }
  }

  private _getSearchFilters(tree) {
    this._isMounted && this.setState({ itemTree: tree.slice(0)})
  }

  private _getTags(tree) {
    const {repoData} = this.state;

    var tagsTree: any = [{
      children:[]
      }];

    var getTags = async () => {
      let repo = repoData;
      var tags: any = [
        {
          key: "separator2-" + repo.id,
          id: "separator2-" + repo.id,
          type: "separator"
        },
        {
          key: "separator3-" + repo.id,
          id: "separator3-" + repo.id,
          type: "separatorFilters"
        },
        {
          key: "projectsHeader-" + repo.id,
          id: repo.id,
          name: 'Pipeline',
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'pipelineId',
          children: []
        },
        {
          key: "projectStatusCategory",
          id: 'projectStatusCategory',
          name: i18n.t('app:status'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'status',
          children: []
        },
        {
          key: "contractsRelationCategory",
          id: 'contractsRelationCategory',
          name: i18n.t('app:contracts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contractIds',
          children: []
        },
        {
          key: "contactsRelationCategory",
          id: 'contactsRelationCategory',
          name: i18n.t('app:contacts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contactIds',
          children: []
        },
        {
          key: "companiesRelationCategory",
          id: 'companiesRelationCategory',
          name: i18n.t('app:companies'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'companyIds',
          children: []
        },
      ];

      await this.addFilterCategory(tags, 2, 'pipelineId', 'pipelineId')
      await this.addFilterCategory(tags, 3, 'status', 'status')
      await this.addFilterCategory(tags, 4, 'contractIds', 'contractIds')
      await this.addFilterCategory(tags, 5, 'contactIds', 'contactIds')
      await this.addFilterCategory(tags, 6, 'companyIds', 'companyIds')

      if(tags.length === 0) {
        tags.push({
          key: "empty " + repo.id,
          name: i18n.t('app:empty'),
          url: '',
          isExpanded :false,
          type: "empty",
          status: "active",
          repo: repo.id,
          search: "",
          disabled: true
        })
      }

      var searchFilters = this.state.searchFilters;
      if(searchFilters.length > 0) {
        var selRepo: any = searchFilters.filter(searchFilter => {
          return searchFilter.children[0].id === repo.id
        })

        selRepo[0].children = tags
      }

      tagsTree[0].children.push ({
          key: repo.id,
          name: repo.name,
          isExpanded: false,
          children: tags,
          type: "repoFilter"
        })
    }

    getTags().then(() => {
      tagsTree[0].children.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });
      const treeIds = tree.map(item=>{return item.id})
      for (let i in tagsTree[0].children[0].children) {
        if (!treeIds.includes(tagsTree[0].children[0].children[i].id)) {
          tree.push(tagsTree[0].children[0].children[i])
        }
      }
      this._isMounted && this.setState({ itemTree: tree.slice(0)})
    });
  }

  private async addFilterCategory(tags, index, param, type) {
    await new Promise((resolve) => {
      const params = new URLSearchParams(document.location.search);
      const relations = param === 'projectIds' || param === 'contractIds' || param === 'contactIds' || param === 'companyIds'
      let field: any = params.get(param) || '';
      let fields:any[] = field.split(',');
      if (field === 'all' || field === 'none' || field === '') {
        tags[index].children.push({
          key: field,
          id: field,
          name: field === 'none' ? i18n.t('app:none') : field === 'all' ? i18n.t('app:all') : i18n.t('app:noFilter') ,
          type: 'tagUndefined',
          category: type,
          iconName: field === 'none' ? 'Tag' : field === 'all' ? 'Tag' : 'ClearFilter' ,
        })
        resolve(true)
      } else if (param === 'pipelineId') {
        let items:any[] = this.props.pipelines.filter((item)=>{return item.id === field});
        if (field === 'all' || field === 'none' || field === '') {
          tags[index].children.push({
            key: field,
            id: field,
            name: field === 'none' ? i18n.t('app:none') : field === 'all' ? i18n.t('app:all') : i18n.t('app:noFilter') ,
            type: 'tagUndefined',
            category: type,
            iconName: field === 'none' ? 'Tag' : field === 'all' ? 'Tag' : 'ClearFilter' ,
          })
        } else if (items && items.length > 0 && field.length > 0) {
          items.map((item:any)=>{
            tags[index].children.push({
              key: item.id,
              id: item.id,
              name: item.name,
              type: type,
              category: type,
            })
            return null;
          })
        }
        resolve(true)
      } else if (param === 'status') {
        let items:any[] = [];
        let fields = field.split(',')
        for (let i in this.projectStatusTypes) {
          for (let f in fields) {
            if (this.projectStatusTypes[i].id === fields[f]) items.push(this.projectStatusTypes[i])
          }
        }
        if (field === 'all' || field === 'none' || field === '') {
          tags[index].children.push({
            key: field,
            id: field,
            name: field === 'none' ? i18n.t('app:none') : field === 'all' ? i18n.t('app:all') : i18n.t('app:noFilter') ,
            type: 'tagUndefined',
            category: type,
            iconName: field === 'none' ? 'Tag' : field === 'all' ? 'Tag' : 'ClearFilter' ,
          })
        } else if (items && items.length > 0 && field.length > 0) {
          items.map((item:any)=>{
            tags[index].children.push({
              key: item.id,
              id: item.id,
              name: item.name,
              type: type,
              category: type,
              iconName: item.iconName
            })
            return null;
          })
        }
        resolve(true)
      } else if (param === 'type') {
        let items:any[] = [];
        let fields = field.split(',')
        for (let i in this.itemTypes) {
          for (let f in fields) {
            if (this.itemTypes[i].id === fields[f]) items.push(this.itemTypes[i])
          }
        }
        if (field === 'all' || field === 'none' || field === '') {
          tags[index].children.push({
            key: field,
            id: field,
            name: field === 'none' ? i18n.t('app:none') : field === 'all' ? i18n.t('app:all') : i18n.t('app:noFilter') ,
            type: 'tagUndefined',
            category: type,
            iconName: field === 'none' ? 'Tag' : field === 'all' ? 'Tag' : 'ClearFilter' ,
          })
        } else if (items && items.length > 0 && field.length > 0) {
          items.map((item:any)=>{
            tags[index].children.push({
              key: item.id,
              id: item.id,
              name: item.name,
              type: type,
              category: type,
            })
            return null;
          })
        }
        resolve(true)
      } else if (param === 'projectIds') {
        userService.getProjects(field).then((response)=>{
          let items:any[] = response.data;
          if (field === 'all' || field === 'none' || field === '') {
            tags[index].children.push({
              key: field,
              id: field,
              name: field === 'none' ? i18n.t('app:none') : field === 'all' ? i18n.t('app:all') : i18n.t('app:noFilter') ,
              type: 'tagUndefined',
              category: type,
              iconName: field === 'none' ? 'Tag' : field === 'all' ? 'Tag' : 'ClearFilter' ,
            })
          } else if (relations && items && items.length > 0 && field.length > 0) {
            items.map((item:any)=>{
              tags[index].children.push({
                key: item.id,
                id: item.id,
                name: item.name,
                type: type,
                category: type,
              })
              return null;
            })
          }
          resolve(true)
        })
      } else if (param === 'contractIds') {
        userService.getContracts(field).then((response)=>{
          let items:any[] = response.data;
          if (field === 'all' || field === 'none' || field === '') {
            tags[index].children.push({
              key: field,
              id: field,
              name: field === 'none' ? i18n.t('app:none') : field === 'all' ? i18n.t('app:all') : i18n.t('app:noFilter') ,
              type: 'tagUndefined',
              category: type,
              iconName: field === 'none' ? 'Tag' : field === 'all' ? 'Tag' : 'ClearFilter' ,
            })
          } else if (relations && items && items.length > 0 && field.length > 0) {
            items.map((item:any)=>{
              tags[index].children.push({
                key: item.id,
                id: item.id,
                name: item.name,
                type: type,
                category: type,
              })
              return null;
            })
          }
          resolve(true)
        })
      } else if (param === 'contactIds') {
        userService.getContacts(field).then((response)=>{
          let items:any[] = response.data;
          if (field === 'all' || field === 'none' || field === '') {
            tags[index].children.push({
              key: field,
              id: field,
              name: field === 'none' ? i18n.t('app:none') : field === 'all' ? i18n.t('app:all') : i18n.t('app:noFilter') ,
              type: 'tagUndefined',
              category: type,
              iconName: field === 'none' ? 'Tag' : field === 'all' ? 'Tag' : 'ClearFilter' ,
            })
          } else if (relations && items && items.length > 0 && field.length > 0) {
            items.map((item:any)=>{
              tags[index].children.push({
                key: item.id,
                id: item.id,
                name: (item.firstName || '') + ' ' + (item.lastName || ''),
                type: type,
                category: type,
              })
              return null;
            })
          }
          resolve(true)
        })
      } else if (param === 'companyIds') {
        userService.getCompanies(field).then((response)=>{
          let items:any[] = response.data;
          if (field === 'all' || field === 'none' || field === '') {
            tags[index].children.push({
              key: field,
              id: field,
              name: field === 'none' ? i18n.t('app:none') : field === 'all' ? i18n.t('app:all') : i18n.t('app:noFilter') ,
              type: 'tagUndefined',
              category: type,
              iconName: field === 'none' ? 'Tag' : field === 'all' ? 'Tag' : 'ClearFilter' ,
            })
          } else if (relations && items && items.length > 0 && field.length > 0) {
            items.map((item:any)=>{
              tags[index].children.push({
                key: item.id,
                id: item.id,
                name: item.name,
                type: type,
                category: type,
              })
              return null;
            })
          }
          resolve(true)
        })
      } else if (param === 'typesRelations') {
        let items:any[] = conversationsTypes.data
        if (field === 'all' || field === 'none' || field === '') {
          tags[index].children.push({
            key: 'tag' + field,
            id: 'tag' + field,
            name: field === 'none' ? i18n.t('app:none') : field === 'all' ? i18n.t('app:all') : i18n.t('app:noFilter') ,
            type: 'tagUndefined',
            category: type,
            iconName: field === 'none' ? 'Tag' : field === 'all' ? 'Tag' : 'ClearFilter' ,
          })
        } else if (relations && items && items.length > 0 && field.length > 0) {
          items.map((item:any)=>{
            tags[index].children.push({
              key: item.id,
              id: item.id,
              name: item.name,
              type: type,
              category: type,
            })
            return null;
          })
        }
        resolve(true)
      }
      else if (fields && fields.length > 0 && field.length > 0) {
        fields.map((item:string)=>{
          if (field) {
            tags[index].children.push({
              key: item,
              id: item,
              name: field,
              type: type,
              category: type,
            })
          } else {
            tags[index].children.push({
              key: field,
              id: field,
              name: field === 'none' ? i18n.t('app:none') : field === 'all' ? i18n.t('app:all') : i18n.t('app:noFilter') ,
              type: 'tagUndefined',
              category: type,
              iconName: field === 'none' ? 'Tag' : field === 'all' ? 'Tag' : 'ClearFilter' ,
            })
          }
          return null;
        })
        resolve(true)
      } else {
        resolve(true)
      }
    })
    return tags
  }

  private async addSearchFilterCategory(tags, index, param, type) {
    await new Promise((resolve) => {
      const params = new URLSearchParams(document.location.search);
      let projects = [];
      let contracts = [];
      let contacts = [];
      let companies = [];
      let field: any = params.get(param) || '';
      if (param === 'projectIds' && field.length > 0) {
        userService.getProjects(field).then((response)=>{
          projects = response.data;
          this.setState({currentProjects: projects})
          resolve(true)
        })
      } else if (param === 'contractIds' && field.length > 0) {
        userService.getContracts(field).then((response)=>{
          contracts = response.data;
          this.setState({currentContracts: contracts})
          resolve(true)
        })
      } else if (param === 'contactIds' && field.length > 0) {
        userService.getContacts(field).then((response)=>{
          contacts = response.data;
          this.setState({currentContacts: contacts})
          resolve(true)
        })
      } else if (param === 'companyIds' && field.length > 0) {
        userService.getCompanies(field).then((response)=>{
          companies = response.data;
          this.setState({currentCompanies: companies})
          resolve(true)
        })
      } else {
        resolve(true)
      }
    })
    return tags
  }

  private async addSearchFields(tags, index, param, type) {
    await new Promise((resolve) => {
      const params = new URLSearchParams(document.location.search);
      const keys:any = params.keys();
      let currentFilters:any[] = [];
      for (const key of keys) {
        if (key !== 'entityType' && key !== 'projectIds' && key !== 'contractIds' && key !== 'contactIds' && key !== 'companyIds') {
          let field: any = params.get(key) || '';
          currentFilters.push({id: key, key: key, text: field, type: 'Field'})
        }
      }
      this._isMounted && this.setState({currentFilters: currentFilters})
      resolve(true)
    })
    return tags
  }

  private _getContractsFilters(tree) {
    const {repoData} = this.state;
    let repo = repoData;
    var tagsTree: any = [{
      children:[]
      }];

    var getTags = async () => {
      var tags: any = [
        {
          key: "separator2-" + repo.id,
          id: "separator2-" + repo.id,
          type: "separator"
        },
        {
          key: "separator3-" + repo.id,
          id: "separator3-" + repo.id,
          type: "separatorFilters"
        },
        {
          key: "projectRelationCategory",
          id: 'projectRelationCategory',
          name: i18n.t('app:projects'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'projectIds',
          children: []
        },
        {
          key: "contactsRelationCategory",
          id: 'contactsRelationCategory',
          name: i18n.t('app:contacts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contactIds',
          children: []
        },
        {
          key: "companiesRelationCategory",
          id: 'companiesRelationCategory',
          name: i18n.t('app:companies'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'companyIds',
          children: []
        }
      ];

      await this.addFilterCategory(tags, 2, 'projectIds', 'projectIds')
      await this.addFilterCategory(tags, 3, 'contactIds', 'contactIds')
      await this.addFilterCategory(tags, 4, 'companyIds', 'companyIds')

      if(tags.length === 0) {
        tags.push({
          key: "empty " + repo.id,
          name: i18n.t('app:empty'),
          url: '',
          isExpanded :false,
          type: "empty",
          status: "active",
          repo: repo.id,
          search: "",
          disabled: true
        })
      }

      var searchFilters = this.state.searchFilters;
      if(searchFilters.length > 0) {
        var selRepo: any = searchFilters.filter(searchFilter => {
          return searchFilter.children[0].id === repo.id
        })

        selRepo[0].children = tags
      }

      tagsTree[0].children.push ({
          key: repo.id,
          name: repo.name,
          isExpanded: false,
          children: tags,
          type: "repoFilter"
        })
    }

    getTags().then(() => {
      tagsTree[0].children.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });
      const treeIds = tree.map(item=>{return item.id})
      for (let i in tagsTree[0].children[0].children) {
        if (!treeIds.includes(tagsTree[0].children[0].children[i].id)) {
          tree.push(tagsTree[0].children[0].children[i])
        }
      }
      this._isMounted && this.setState({ itemTree: tree.slice(0)})
    });
  }

  private _getContactsFilters(tree) {
    const {repoData} = this.state;
    let repo = repoData;
    var tagsTree: any = [{
      children:[]
      }];

    var getTags = async () => {
      var tags: any = [
        {
          key: "separator2-" + repo.id,
          id: "separator2-" + repo.id,
          type: "separator"
        },
        {
          key: "separator3-" + repo.id,
          id: "separator3-" + repo.id,
          type: "separatorFilters"
        },
        {
          key: "contactTypeCategory",
          id: 'contactTypeCategory',
          name: i18n.t('app:type'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contactType',
          children: []
        },
        {
          key: "projectRelationCategory",
          id: 'projectRelationCategory',
          name: i18n.t('app:projects'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'projectIds',
          children: []
        },
        {
          key: "contractsRelationCategory",
          id: 'contractsRelationCategory',
          name: i18n.t('app:contracts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contractIds',
          children: []
        },
        {
          key: "companiesRelationCategory",
          id: 'companiesRelationCategory',
          name: i18n.t('app:companies'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'companyIds',
          children: []
        }
      ];

      await this.addFilterCategory(tags, 2, 'contactType', 'contactType')
      await this.addFilterCategory(tags, 3, 'projectIds', 'projectIds')
      await this.addFilterCategory(tags, 4, 'contractIds', 'contractIds')
      await this.addFilterCategory(tags, 5, 'companyIds', 'companyIds')

      if(tags.length === 0) {
        tags.push({
          key: "empty " + repo.id,
          name: i18n.t('app:empty'),
          url: '',
          isExpanded :false,
          type: "empty",
          status: "active",
          repo: repo.id,
          search: "",
          disabled: true
        })
      }

      var searchFilters = this.state.searchFilters;
      if(searchFilters.length > 0) {
        var selRepo: any = searchFilters.filter(searchFilter => {
          return searchFilter.children[0].id === repo.id
        })

        selRepo[0].children = tags
      }

      tagsTree[0].children.push ({
          key: repo.id,
          name: repo.name,
          isExpanded: false,
          children: tags,
          type: "repoFilter"
        })
    }

    getTags().then(() => {
      tagsTree[0].children.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });
      const treeIds = tree.map(item=>{return item.id})
      for (let i in tagsTree[0].children[0].children) {
        if (!treeIds.includes(tagsTree[0].children[0].children[i].id)) {
          tree.push(tagsTree[0].children[0].children[i])
        }
      }
      this._isMounted && this.setState({ itemTree: tree.slice(0)})
    });
  }

  private _getCompaniesFilters(tree) {
    const {repoData} = this.state;
    let repo = repoData;
    var tagsTree: any = [{
      children:[]
      }];

    var getTags = async () => {
      var tags: any = [
        {
          key: "separator2-" + repo.id,
          id: "separator2-" + repo.id,
          type: "separator"
        },
        {
          key: "separator3-" + repo.id,
          id: "separator3-" + repo.id,
          type: "separatorFilters"
        },
        {
          key: "projectRelationCategory",
          id: 'projectRelationCategory',
          name: i18n.t('app:projects'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'projectIds',
          children: []
        },
        {
          key: "contractsRelationCategory",
          id: 'contractsRelationCategory',
          name: i18n.t('app:contracts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contractIds',
          children: []
        },
        {
          key: "contactsRelationCategory",
          id: 'contactsRelationCategory',
          name: i18n.t('app:contacts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contactIds',
          children: []
        },
      ];

      await this.addFilterCategory(tags, 2, 'projectIds', 'projectIds')
      await this.addFilterCategory(tags, 3, 'contractIds', 'contractIds')
      await this.addFilterCategory(tags, 4, 'contactIds', 'contactIds')

      if(tags.length === 0) {
        tags.push({
          key: "empty " + repo.id,
          name: i18n.t('app:empty'),
          url: '',
          isExpanded :false,
          type: "empty",
          status: "active",
          repo: repo.id,
          search: "",
          disabled: true
        })
      }

      var searchFilters = this.state.searchFilters;
      if(searchFilters.length > 0) {
        var selRepo: any = searchFilters.filter(searchFilter => {
          return searchFilter.children[0].id === repo.id
        })

        selRepo[0].children = tags
      }

      tagsTree[0].children.push ({
          key: repo.id,
          name: repo.name,
          isExpanded: false,
          children: tags,
          type: "repoFilter"
        })
    }

    getTags().then(() => {
      tagsTree[0].children.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });
      const treeIds = tree.map(item=>{return item.id})
      for (let i in tagsTree[0].children[0].children) {
        if (!treeIds.includes(tagsTree[0].children[0].children[i].id)) {
          tree.push(tagsTree[0].children[0].children[i])
        }
      }
      this._isMounted && this.setState({ itemTree: tree.slice(0)})
    });
  }

  private _getNotesFilters(tree) {
    const {repoData} = this.state;
    let repo = repoData;
    var tagsTree: any = [{
      children:[]
      }];

    var getTags = async () => {
      var tags: any = [
        {
          key: "separator2-" + repo.id,
          id: "separator2-" + repo.id,
          type: "separator"
        },
        {
          key: "separator3-" + repo.id,
          id: "separator3-" + repo.id,
          type: "separatorFilters"
        },
        {
          key: "projectRelationCategory",
          id: 'projectRelationCategory',
          name: i18n.t('app:projects'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'projectIds',
          children: []
        },
        {
          key: "contractsRelationCategory",
          id: 'contractsRelationCategory',
          name: i18n.t('app:contracts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contractIds',
          children: []
        },
        {
          key: "contactsRelationCategory",
          id: 'contactsRelationCategory',
          name: i18n.t('app:contacts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contactIds',
          children: []
        },
        {
          key: "companiesRelationCategory",
          id: 'companiesRelationCategory',
          name: i18n.t('app:companies'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'company',
          children: []
        }
      ];

      await this.addFilterCategory(tags, 2, 'projectIds', 'projectIds')
      await this.addFilterCategory(tags, 3, 'contractIds', 'contractIds')
      await this.addFilterCategory(tags, 4, 'contactIds', 'contactIds')
      await this.addFilterCategory(tags, 5, 'companyIds', 'companyIds')

      if(tags.length === 0) {
        tags.push({
          key: "empty " + repo.id,
          name: i18n.t('app:empty'),
          url: '',
          isExpanded :false,
          type: "empty",
          status: "active",
          repo: repo.id,
          search: "",
          disabled: true
        })
      }

      var searchFilters = this.state.searchFilters;
      if(searchFilters.length > 0) {
        var selRepo: any = searchFilters.filter(searchFilter => {
          return searchFilter.children[0].id === repo.id
        })

        selRepo[0].children = tags
      }

      tagsTree[0].children.push ({
          key: repo.id,
          name: repo.name,
          isExpanded: false,
          children: tags,
          type: "repoFilter"
        })
    }

    getTags().then(() => {
      tagsTree[0].children.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });
      const treeIds = tree.map(item=>{return item.id})
      for (let i in tagsTree[0].children[0].children) {
        if (!treeIds.includes(tagsTree[0].children[0].children[i].id)) {
          tree.push(tagsTree[0].children[0].children[i])
        }
      }
      this._isMounted && this.setState({ itemTree: tree.slice(0)})
    });
  }

  private _getConversationsFilters(tree) {
    const {repoData} = this.state;
    let repo = repoData;
    var tagsTree: any = [{
      children:[]
      }];

    var getTags = async () => {
      var tags: any = [
        {
          key: "separator2-" + repo.id,
          id: "separator2-" + repo.id,
          type: "separator"
        },
        {
          key: "separator3-" + repo.id,
          id: "separator3-" + repo.id,
          type: "separatorFilters"
        },
        {
          key: "typestRelationCategory",
          id: 'typestRelationCategory',
          name: i18n.t('app:types'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'typesRelations',
          children: []
        },
        {
          key: "projectRelationCategory",
          id: 'projectRelationCategory',
          name: i18n.t('app:projects'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'projectIds',
          children: []
        },
        {
          key: "contractsRelationCategory",
          id: 'contractsRelationCategory',
          name: i18n.t('app:contracts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contractIds',
          children: []
        },
        {
          key: "contactsRelationCategory",
          id: 'contactsRelationCategory',
          name: i18n.t('app:contacts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contactIds',
          children: [],
        },
        {
          key: "companiesRelationCategory",
          id: 'companiesRelationCategory',
          name: i18n.t('app:companies'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'companyIds',
          children: []
        }
      ];

      await this.addFilterCategory(tags, 2, 'typesRelations', 'typesRelations')
      await this.addFilterCategory(tags, 3, 'projectIds', 'projectIds')
      await this.addFilterCategory(tags, 4, 'contractIds', 'contractIds')
      await this.addFilterCategory(tags, 5, 'contactIds', 'contactIds')
      await this.addFilterCategory(tags, 6, 'companyIds', 'companyIds')

      if(tags.length === 0) {
        tags.push({
          key: "empty " + repo.id,
          name: i18n.t('app:empty'),
          url: '',
          isExpanded :false,
          type: "empty",
          status: "active",
          repo: repo.id,
          search: "",
          disabled: true
        })
      }

      var searchFilters = this.state.searchFilters;
      if(searchFilters.length > 0) {
        var selRepo: any = searchFilters.filter(searchFilter => {
          return searchFilter.children[0].id === repo.id
        })

        selRepo[0].children = tags
      }

      tagsTree[0].children.push ({
          key: repo.id,
          name: repo.name,
          isExpanded: false,
          children: tags,
          type: "repoFilter"
        })
    }

    getTags().then(() => {
      tagsTree[0].children.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });
      const treeIds = tree.map(item=>{return item.id})
      for (let i in tagsTree[0].children[0].children) {
        if (!treeIds.includes(tagsTree[0].children[0].children[i].id)) {
          tree.push(tagsTree[0].children[0].children[i])
        }
      }
      this._isMounted && this.setState({ itemTree: tree.slice(0)})
    });
  }

  private _getSearchPageFilters(tree) {
    const {repoData} = this.state;
    let repo = repoData;
    var tagsTree: any = [{
      children:[]
      }];

    var getTags = async () => {
      var tags: any = [
        {
          key: 'searchType',
          id: 'searchType',
          type: "searchFilters",
        },
        {
          key: "separator2-" + repo.id,
          id: "separator2-" + repo.id,
          type: "separator"
        },
        {
          key: "separator3-" + repo.id,
          id: "separator3-" + repo.id,
          type: "separatorFilters"
        },
        {
          key: "projectRelationCategory",
          id: 'projectRelationCategory',
          name: i18n.t('app:projects'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'projectIds',
          children: []
        },
        {
          key: "contractsRelationCategory",
          id: 'contractsRelationCategory',
          name: i18n.t('app:contracts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contractIds',
          children: []
        },
        {
          key: "contactsRelationCategory",
          id: 'contactsRelationCategory',
          name: i18n.t('app:contacts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contactIds',
          children: []
        },
        {
          key: "companiesRelationCategory",
          id: 'companiesRelationCategory',
          name: i18n.t('app:companies'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'companyIds',
          children: []
        },
        {
          key: 'nicknameFilter',
          id: 'nicknameFilter',
          name: i18n.t('app:nickName'),
          type: "searchFilters",
        },
        {
          key: 'domainFilter',
          id: 'domainFilter',
          name: i18n.t('app:domain'),
          type: "searchFilters",
        },
      ];

      await this.addSearchFields(tags, 2, 'field', 'Field')
      await this.addSearchFilterCategory(tags, 3, 'projectIds', 'projectIds')
      await this.addSearchFilterCategory(tags, 4, 'contractIds', 'contractIds')
      await this.addSearchFilterCategory(tags, 5, 'contactIds', 'contactIds')
      await this.addSearchFilterCategory(tags, 6, 'companyIds', 'companyIds')

      if(tags.length === 0) {
        tags.push({
          key: "empty " + repo.id,
          name: i18n.t('app:empty'),
          url: '',
          isExpanded :false,
          type: "empty",
          status: "active",
          repo: repo.id,
          search: "",
          disabled: true
        })
      }

      var searchFilters = this.state.searchFilters;
      if(searchFilters.length > 0) {
        var selRepo: any = searchFilters.filter(searchFilter => {
          return searchFilter.children[0].id === repo.id
        })

        selRepo[0].children = tags
      }

      tagsTree[0].children.push ({
          key: repo.id,
          name: repo.name,
          isExpanded: false,
          children: tags,
          type: "repoFilter"
        })
    }

    getTags().then(() => {
      tagsTree[0].children.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });
      const treeIds = tree.map(item=>{return item.id})
      for (let i in tagsTree[0].children[0].children) {
        if (!treeIds.includes(tagsTree[0].children[0].children[i].id)) {
          tree.push(tagsTree[0].children[0].children[i])
        }
      }
      const search = new URLSearchParams(document.location.search)
      let filterName = search.get('entityType') || 'PROJECT'
      this._isMounted && this.setState({
        itemTree: tree.slice(0),
        filterColumnOpen: this.props.page === 'search',
        filterName: this.props.page === 'search' ? filterName : '',
      })
    });
  }

  private _getTasksFilters(tree) {
    const {repoData} = this.state;
    let repo = repoData;
    var tagsTree: any = [{
      children:[]
      }];

    var getTags = async () => {
      var tags: any = [
        {
          key: "separator2-" + repo.id,
          id: "separator2-" + repo.id,
          type: "separator"
        },
        {
          key: "separator3-" + repo.id,
          id: "separator3-" + repo.id,
          type: "separatorFilters"
        },
        {
          key: "projectRelationCategory",
          id: 'projectRelationCategory',
          name: i18n.t('app:projects'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'projectIds',
          children: []
        },
        {
          key: "contractsRelationCategory",
          id: 'contractsRelationCategory',
          name: i18n.t('app:contracts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contractIds',
          children: []
        },
        {
          key: "contactsRelationCategory",
          id: 'contactsRelationCategory',
          name: i18n.t('app:contacts'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'contactIds',
          children: []
        },
        {
          key: "companiesRelationCategory",
          id: 'companiesRelationCategory',
          name: i18n.t('app:companies'),
          isExpanded: true,
          state: {expanded: true},
          hasChildren: true,
          type: "category",
          field: 'companyIds',
          children: []
        }
      ];

      await this.addFilterCategory(tags, 2, 'projectIds', 'projectIds')
      await this.addFilterCategory(tags, 3, 'contractIds', 'contractIds')
      await this.addFilterCategory(tags, 4, 'contactIds', 'contactIds')
      await this.addFilterCategory(tags, 5, 'companyIds', 'companyIds')

      if(tags.length === 0) {
        tags.push({
          key: "empty " + repo.id,
          name: i18n.t('app:empty'),
          url: '',
          isExpanded :false,
          type: "empty",
          status: "active",
          repo: repo.id,
          search: "",
          disabled: true
        })
      }

      var searchFilters = this.state.searchFilters;
      if(searchFilters.length > 0) {
        var selRepo: any = searchFilters.filter(searchFilter => {
          return searchFilter.children[0].id === repo.id
        })

        selRepo[0].children = tags
      }

      tagsTree[0].children.push ({
          key: repo.id,
          name: repo.name,
          isExpanded: false,
          children: tags,
          type: "repoFilter"
        })
    }

    getTags().then(() => {
      tagsTree[0].children.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });
      const treeIds = tree.map(item=>{return item.id})
      for (let i in tagsTree[0].children[0].children) {
        if (!treeIds.includes(tagsTree[0].children[0].children[i].id)) {
          tree.push(tagsTree[0].children[0].children[i])
        }
      }
      this._isMounted && this.setState({ itemTree: tree.slice(0)})
    });
  }

  private checkSelFilter(item, field?){
    const params = new URLSearchParams(document.location.search);
    if (field && params.get(field) === item.name ) {
      return true
    } else {
      return false
    }
  }

  private checkSelected(){
    const {views} = this.state;
    if (this.props.page === 'projectData' || this.props.page === 'contactData' || this.props.page === 'companyData' || this.props.page === 'contractData') {
      for (let i in views) if (views[i].query === window.location.search) return false;
      return true;
    } else {
      let defaultViews = this.state.itemTree ? this.state.itemTree[1] ? this.state.itemTree[1].children : [] : [];
      for (let i in views) if (this.props.page === views[i].page && views[i].query === window.location.search) return false;
      for (let i in defaultViews) if (this.props.page === defaultViews[i].page && defaultViews[i].query === window.location.search) return false;
      return true;
    }
  }

  private findById = (o, id) => {
    //Early return
    if( o && o.id === id ){
      return o;
    }
    var result, p; 
    for (p in o) {
        if( o.hasOwnProperty(p) && typeof o[p] === 'object' ) {
            result = this.findById(o[p], id);
            if(result){
                return result;
            }
        }
    }
    return result;
  }

  private toggleFilters() {
    let tree = this.state.itemTree
    if (this.props.page === 'search') {
      if (tree[3].isExpanded || tree[3].state.expanded){
        for (let i=3; i<7; i++) {
          if (tree[i].state) tree[i].isExpanded = false;
          if (tree[i].state) tree[i].state.expanded = false;
        }
      } else {
        for (let i=3; i<7; i++) {
          if (tree[i].state) tree[i].isExpanded = true;
          if (tree[i].state) tree[i].state.expanded = true;
        }
      }
    } else {
      if (tree[4].isExpanded || tree[4].state.expanded){
        for (let i=4; i<tree.length; i++) {
          tree[i].isExpanded = false;
          tree[i].state.expanded = false;
        }
      } else {
        for (let i=4; i<tree.length; i++) {
          tree[i].isExpanded = true;
          tree[i].state.expanded = true;
        }
      }
    }

    this._isMounted && this.setState({itemTree: tree})
  }

  private getItemType() {
    let type = this.props.itemType ? this.props.itemType.toUpperCase() : this.props.page
    return viewItemType[type]
  }

  private createView() {
    this.props.headerRef && this.props.headerRef._showFormView()
  }

  private editView(view:any) {
    this.props.headerRef && this.props.headerRef._showFormViewEdit(view)
  }

  private deleteView(view:any) {
    this.props.headerRef && this.props.headerRef._showFormViewDelete(view)
  }

  private removeView(node:any) {
    const itemType = this.getItemType()
    userService.getViews(itemType).then((response)=>{
      const views = response.data;
      for (let i in views) {
        if (views[i] && views[i].id === node.id && this.props.userData) {
          let view:any = views[i];
          view.quickAccessUserIds = view.quickAccessUserIds.filter((id)=>{return id !== this.props.userData.user.id})
          const data = {
            name: view.name,
            page: view.page,
            query: view.query,
            itemType: view.itemType,
            quickAccessUserIds: view.quickAccessUserIds,
            allQuickAccess: view.allQuickAccess
          }
          userService.editView(view.id, data).then(()=>{
            this.refreshTree()
          })
        }
      }
    })
  }

  private setViews(customViews) {
    return this.props.page !== 'projectData' && this.props.page !== 'contactData' && this.props.page !== 'companyData' && this.props.page !== 'contractData' ?
    this.props.page === 'notes' || this.props.page === 'notesPreview' || this.getViewsTitle() === 'notes' ? [
      {
        key: "notesPreview",
        id: "notesPreview",
        name: i18n.t('app:panel'),
        type: 'view-notes',
        page: 'notesPreview',
        query: '',
        state: {}
      },
      {
        key: "viewNotes",
        id: "viewNotes",
        name: i18n.t('app:list'),
        type: 'view-notes',
        page: 'notes',
        query: '',
        state: {}
      },
    ].concat(customViews.map((item)=>{return {...item, type: 'view-notes'}})) : this.props.page === 'conversations' || this.props.page === 'conversationsPreview'  || this.getViewsTitle() === 'conversations' ? [
      {
        key: "conversationsPreview",
        id: "conversationsPreview",
        name: i18n.t('app:panel'),
        type: 'view-conversations',
        page: 'conversationsPreview',
        query: '',
        state: {}
      },
      {
        key: "viewConversations",
        id: "viewConversations",
        name: i18n.t('app:list'),
        type: 'view-conversations',
        page: 'conversations',
        query: '',
        state: {}
      },
    ].concat(customViews.map((item)=>{return {...item, type: 'view-conversations'}})) : this.props.page === 'tasks' || this.props.page === 'tasksPreview'  || this.getViewsTitle() === 'tasks' ? [
      {
        key: "tasksPreview",
        id: "tasksPreview",
        name: i18n.t('app:panel'),
        type: 'view-tasks',
        page: 'tasksPreview',
        query: '',
        state: {}
      },
      {
        key: "tasks",
        id: "tasks",
        name: i18n.t('app:list'),
        type: 'view-tasks',
        page: 'tasks',
        query: '',
        state: {}
      },
    ].concat(customViews.map((item)=>{return {...item, type: 'view-tasks'}})) : this.props.page === 'emails' || this.props.page === 'emailsPreview'  || this.getViewsTitle() === 'emails' ? [
      {
        key: "emailsPreview",
        id: "emailsPreview",
        name: i18n.t('app:panel'),
        type: 'view-emails',
        page: 'emailsPreview',
        query: '',
        state: {}
      },
      {
        key: "emails",
        id: "emails",
        name: i18n.t('app:list'),
        type: 'view-emails',
        page: 'emails',
        query: '',
        state: {}
      },
    ].concat(customViews.map((item)=>{return {...item, type: 'view-emails'}})) : this.props.page === 'permissions' || this.getViewsTitle() === 'permissions' ? [
      {
        key: "permissions",
        id: "permissions",
        name: i18n.t('app:permissions'),
        type: 'view-permissions',
        page: 'permissions',
        query: '',
        state: {}
      },
    ].concat(customViews.map((item)=>{return {...item, type: 'view-permissions'}})) : this.props.page === 'contactsExplorer' || this.getViewsTitle() === 'contacts' ? [
      {
        key: "viewContacts",
        id: "viewContacts",
        name: i18n.t('app:allContacts'),
        type: 'view-contacts',
        page: 'contactsExplorer',
        query: '',
        state: {}
      },
    ].concat(customViews.map((item)=>{return {...item, type: 'view-contacts'}})) : this.props.page === 'companiesExplorer' || this.props.page === 'companiesPreview' || this.getViewsTitle() === 'companies' ? [
      {
        key: "viewCompanies",
        id: "viewCompanies",
        name: i18n.t('app:allCompanies'),
        type: 'view-companies',
        page: 'companiesExplorer',
        query: '',
        state: {}
      },
    ].concat(customViews.map((item)=>{return {...item, type: 'view-companies'}})) : this.props.page === 'contractsExplorer' || this.props.page === 'contractsDashboard' || this.getViewsTitle() === 'contracts' ? [
      {
        key: "contractsDashboard",
        id: "contractsDashboard",
        name: i18n.t('app:dashboard'),
        type: 'contractsDashboard',
        page: 'contractsDashboard',
        query: '',
        state: {}
      },
      {
        key: "viewContracts",
        id: "viewContracts",
        name: i18n.t('app:expiresIn90'),
        type: 'contractsList',
        page: 'contractsExplorer',
        query: '?expiredIn=90',
        state: {}
      },
      {
        key: "viewContracts",
        id: "viewContracts",
        name: i18n.t('app:expiresIn60'),
        type: 'contractsList',
        page: 'contractsExplorer',
        query: '?expiredIn=60',
        state: {}
      },
      {
        key: "viewContracts",
        id: "viewContracts",
        name: i18n.t('app:expiresIn30'),
        type: 'contractsList',
        page: 'contractsExplorer',
        query: '?expiredIn=30',
        state: {}
      },
      {
        key: "viewContracts",
        id: "viewContracts",
        name: i18n.t('app:expired'),
        type: 'contractsList',
        page: 'contractsExplorer',
        query: '?expired=true',
        state: {}
      },
    ].concat(customViews.map((item)=>{return {...item, type: 'view-contracts'}})) : this.props.page === 'pipelines' || this.getViewsTitle() === 'pipelines' ? [
      {
        key: "viewPipelines",
        id: "viewPipelines",
        name: i18n.t('app:allPipelines'),
        type: 'view-pipelines',
        page: 'pipelines',
        query: '',
        state: {}
      },
    ].concat(customViews.map((item)=>{return {...item, type: 'view-pipelines'}})) : [
      {
        key: "viewProjects",
        id: "viewProjects",
        name: i18n.t('app:allProjects'),
        type: 'view-projects',
        page: 'pipelinesExplorer',
        query: '',
        state: {}
      },
    ].concat(customViews.map((item)=>{return {...item, type: 'view-projects'}})) : [
      {
        key: "viewActivity",
        id: "viewActivity",
        name: 'All',
        type: "view-project",
        query: ''
      },
      {
        key: "?type=email",
        id: "?type=email",
        name: i18n.t('app:emails'),
        type: "view-project",
        query: '?type=email'
      },
      {
        key: "?type=note",
        id: "?type=note",
        name: i18n.t('app:notes'),
        type: "view-project",
        query: '?type=note'
      },
      {
        key: "?type=conversation",
        id: "?type=conversation",
        name: i18n.t('app:conversations'),
        type: "view-project",
        query: '?type=conversation'
      },
      {
        key: "?type=task",
        id: "?type=task",
        name: i18n.t('app:tasks'),
        type: "view-project",
        query: '?type=task'
      }
    ]
  }

  private getViews() {
    const itemType = this.getItemType()
    if (itemType) {
      return userService.getViews(itemType).then((response)=>{
        const customViews = response.data.sort((a,b) => {
          if(a.name.toLowerCase() < b.name.toLowerCase())
              return -1;
          if(b.name.toLowerCase() < a.name.toLowerCase())
              return 1;
  
          return 0;
        })
        return this.setViews(customViews)
      })
    } else {
      return new Promise((resolve)=>{
        resolve([])
      })
    }

  }

  private refreshTree() {
    let views:any = this.getViews()
    Promise.all([views]).then((res)=>{
      let customViews = res[0].map((item)=>{return {...item}})
      this._isMounted && this.setState({
        views: customViews
      }, () => {
        this.getSidebarTree()
      })
    })
  }

  private getViewsTitle() { 
    const itemType = (this.getItemType() || 'Views').toLowerCase()
    if (itemType === 'company') return 'companies'
    else return itemType + 's'
  }

  private _applyUpdate() {
    window.location.reload();
  }

  private _checkforUpdates() {
    console.log("Check for updates")

    window.postMessage(
      {
        msg: "updateServiceWorker"
      }, '*');
  }

}