import * as React from 'react';
import { useParams } from 'react-router-dom';
import i18n from "i18next";
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import { Resizable } from 're-resizable';
import { Selection, ActionButton, DetailsList, ScrollablePane, Icon, ScrollbarVisibility, IRenderFunction, IDetailsListCheckboxProps, CheckboxVisibility, Spinner, SpinnerSize, IContextualMenuProps, DirectionalHint, ContextualMenu, IDetailsHeaderProps, StickyPositionType, Sticky, MessageBar, MessageBarType } from '@fluentui/react';
import { EmailViewer } from '../_components/_cards/EmailViewer';
import { CreateCard } from '../_components/_cards/CreateCard';
import { userService } from '../_services';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { ConversationViewer } from '../_components/_cards/ConversationViewer';
import { NoteViewer } from '../_components/_cards/NoteViewer';
import { AlertInvalidProject } from '../_components/_alerts/AlertInvalidProject';
import { AlertInvalidContract } from '../_components/_alerts/AlertInvalidContract';
import { AlertInvalidContact } from '../_components/_alerts/AlertInvalidContact';
import { AlertInvalidCompany } from '../_components/_alerts/AlertInvalidCompany';
import { TaskViewer } from '../_components/_cards/TaskViewer';
import { DetailsPanel } from '../_components/DetailsPanel';
import Moment from 'moment';
import { _getActivityAction, _getActivityTitle } from '../_helpers/activityFunctions';
import { EditCard } from '../_components/_cards/EditCard';
import { Label } from '@fluentui/react-components';

export interface Props {
  routeProps: any
}

export interface States {
  itemId: string;
  showRightPanel: boolean;
  item: any;
  syncStatus: any;
  breadcrumbPath: any[];
  updated: boolean;
  query:string;
  selItem: any;
  panelHeight: any;
  panelWidth: any;
  activities: any;
  activitiesFull: any;
  newToOld: boolean;
  allCardsCollapsed: boolean;
  cardsSortedBy: string;
  showLastItems: any[];
  searchQuery: string;
  errorAlert: string;
  customProperties: any[];
  contextMenuProps: any;
  possibleContacts: any[];
  pipelines: any[];
  alertMessage: any;
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class EntityPage extends React.Component<Props, States> {
  private _isMounted: boolean;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private _selection: Selection;

  private updateSelection() {
    let selItem:any = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null;
    if (selItem && selItem.type === 'EMAIL' && !selItem.body) {
      let id = selItem.id.startsWith('Email-') ? selItem.id : selItem.entity?.id
      userService.getEmailContent(id).then((response)=>{
        let item = response.data
        if (item.attachments && item.attachments.length > 0) {
          let nAttachments = response.data.attachments.length;
          let index = 0;
          for (let i in item.attachments) {
            if (item.attachments[i].inline) {
              userService.downloadAttachment(id, item.attachments[i].id).then((response)=>{
                index += 1;
                var replace = "src=\"cid:"+item.attachments[i].name+".*?\"";
                var re = new RegExp(replace,"g");
                const byteArray = new Uint8Array(response.data);
                var blob = new Blob([byteArray], { type: item.attachments[i].contentType });
                const url = URL.createObjectURL( blob );
                item.body.content = item.body.content.replace(re, 'src=' + url)
                if (index >= nAttachments) {
                  this.updateEmail(item, selItem)
                }

              }).catch((error)=> {
                console.log(error)
                index += 1;
                item.attachments[i].data = response.data;
                if (index >= nAttachments) {
                  this.updateEmail(item, selItem)
                }
              })
            } else {
              index += 1;
              item.attachments[i].data = response.data;
              if (index >= nAttachments) {
                this.updateEmail(item, selItem)
              }
            }
          }
        } else {
          this.updateEmail(item, selItem)
        }
      }).catch((error)=>{
        console.log(error)
        let activities = this.state.activities;
        for (let i in activities) {
          if (activities[i].id === selItem.id) {
            activities[i] = {...selItem, noPermission: true};
            selItem = activities[i]
          }
        }
        let newSelItem:any = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null
        this._isMounted && this.setState({
          activities: activities,
          selItem: newSelItem && newSelItem.id === selItem.id ? selItem : newSelItem
        });
      })
    } else if (selItem && selItem.type === 'NOTE' && !selItem.content) {
      let id = selItem.id.startsWith('Note-') ? selItem.id : selItem.entity?.id
      userService.getNoteContent(id).then((response)=>{
        let activities = this.state.activities;
        for (let i in activities) {
          if (activities[i].id === selItem.id) {
            if (selItem.id.startsWith('Note-')) {
              activities[i] = {...response.data, type: 'NOTE', key: selItem.id};
            } else {
              activities[i].entity = {...response.data, type: 'NOTE', key: selItem.id};
            }
            selItem = activities[i]
          }
        }
        let newSelItem:any = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null
        this._isMounted && this.setState({
          activities: activities,
          selItem: newSelItem && newSelItem.id === selItem.id ? selItem : newSelItem
        });
      }).catch((error)=>{
        console.log(error)
        let activities = this.state.activities;
        for (let i in activities) {
          if (activities[i].id === selItem.id) {
            activities[i] = {...selItem, noPermission: true};
            selItem = activities[i]
          }
        }
        let newSelItem:any = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null
        this._isMounted && this.setState({
          activities: activities,
          selItem: newSelItem && newSelItem.id === selItem.id ? selItem : newSelItem
        });
      })
    } else if (selItem && selItem.type === 'CONVERSATION' && !selItem.content) {
      let id = selItem.id.startsWith('Conversation-') ? selItem.id : selItem.entity?.id
      userService.getConversationContent(id).then((response)=>{
        userService.getConversationMessages(id, 0).then((res)=>{
          let activities = this.state.activities;
          let messages:any = res.data.content;
          let requests:any = [];
          for (let i in messages) {
            for (let j in messages[i].attachments) {
              if (messages[i].attachments[j] && messages[i].attachments[j].inline) {
                requests.push(userService.downloadConversationAttachment(id, messages[i].id, messages[i].attachments[j].id).then((response)=>{
                  const byteArray = new Uint8Array(response.data);
                  const blob = new Blob([byteArray], { type: messages[i].attachments[j].contentType });
                  const url = URL.createObjectURL( blob );
                  messages[i].attachments[j].blob = url;
                }).catch((error)=>{
                  console.log(error)
                }))
              }
            }
          }
          Promise.all(requests).then(() => {
            for (let i in activities) {
              if (activities[i].id === selItem.id) {
                if (selItem.id.startsWith('Conversation-')) {
                  activities[i] = {...response.data, type: 'CONVERSATION', key: id, messages: messages, totalPages: res.data.totalPages, page: 0};
                } else {
                  activities[i].entity = {...response.data, type: 'CONVERSATION', key: id, messages: messages, totalPages: res.data.totalPages, page: 0};
                }
                selItem = activities[i]
              }
            }
            let newSelItem:any = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null
            this._isMounted && this.setState({
              activities: activities,
              selItem: newSelItem && newSelItem.id === selItem.id ? selItem : newSelItem
            });
          })
        })
      }).catch((error)=>{
        console.log(error)
        let activities = this.state.activities;
        for (let i in activities) {
          if (activities[i].id === selItem.id) {
            activities[i] = {...selItem, noPermission: true};
            selItem = activities[i]
          }
        }
        let newSelItem:any = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null
        this._isMounted && this.setState({
          activities: activities,
          selItem: newSelItem && newSelItem.id === selItem.id ? selItem : newSelItem
        });
      })
    } else if (selItem && selItem.type === 'TASK' && !selItem.page && !selItem.messages) {
      let id = selItem.id.startsWith('Task-') ? selItem.id : selItem.entity?.id
      userService.getTaskMessages(id, 0).then((res)=>{
        let items = this.state.activities;
        let messages:any = res.data.content;
        let requests:any = [];
        for (let i in messages) {
          for (let j in messages[i].attachments) {
            if (messages[i].attachments[j] && messages[i].attachments[j].inline) {
              requests.push(userService.downloadTaskAttachment(id, messages[i].id, messages[i].attachments[j].id).then((response)=>{
                const byteArray = new Uint8Array(response.data);
                const blob = new Blob([byteArray], { type: messages[i].attachments[j].contentType });
                const url = URL.createObjectURL( blob );
                messages[i].attachments[j].blob = url;
              }).catch((error)=>{
                console.log(error)
              }))
            }
          }
        }
        Promise.all(requests).then(() => {
          for (let i in items) {
            if (items[i].id === selItem.id) {
              if (selItem.id.startsWith('Task-')) {
                items[i] = {...selItem, type: 'TASK', key: id, messages: messages, totalPages: res.data.totalPages, page: 0};
              } else {
                items[i].entity = {...selItem.entity, type: 'TASK', key: id, messages: messages, totalPages: res.data.totalPages, page: 0};
              }
              selItem = items[i]
            }
          }
          let newSelItem:any = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null
          this._isMounted && this.setState({
            activities: items,
            selItem: newSelItem && newSelItem.id === selItem.id ? selItem : newSelItem
          });
        })
      }).catch((error)=>{
        console.log(error)
        let activities = this.state.activities;
        for (let i in activities) {
          if (activities[i].id === selItem.id) {
            activities[i] = {...selItem, noPermission: true};
            selItem = activities[i]
          }
        }
        let newSelItem:any = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null
        this._isMounted && this.setState({
          activities: activities,
          selItem: newSelItem && newSelItem.id === selItem.id ? selItem : newSelItem
        });
      })
    }
    else {
      this._isMounted && this.setState({
        selItem: selItem
      });
    }
  }

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.updateSelection()
      },
      onItemsChanged: () => {
        this.updateSelection()
      }
    });

    var panelWidth = "240px"
    if (typeof(localStorage.getItem("sidebar3Prefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("sidebar3Prefs") || "[]");
      panelWidth = panelPrefs.width
    }

    this.state = {
      itemId: this.props.params.id,
      showRightPanel: localStorage.detailsPanelOpen ? localStorage.detailsPanelOpen === 'true' : true,
      item: null,
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      },
      breadcrumbPath: [],
      updated: false,
      query: 'all',
      selItem: null,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      activities: [],
      activitiesFull: [],
      newToOld: true,
      allCardsCollapsed: false,
      cardsSortedBy: 'By date',
      showLastItems: [
        {key: 'allActivities', title: 'Show all in Main view'}
      ],
      searchQuery: '',
      errorAlert: '',
      customProperties: [],
      contextMenuProps: null,
      possibleContacts: [],
      pipelines: [],
      alertMessage: null
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    if (this.props.pusher && this.props.userData && this._isMounted) {
      this.getCurrentContent();
      const params = new URLSearchParams(document.location.search);
      const types = params.get('type')?.split(',') || [];
      if (this.props.page === 'projectData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "PROJECT"})
      else if (this.props.page === 'contractData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTRACT"})
      else if (this.props.page === 'contactData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTACT"})
      else if (this.props.page === 'companyData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "COMPANY"})
      if (types.includes('note')) this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "NOTE"})
      if (types.includes('email'))this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "EMAIL"})
      if (types.includes('conversation'))this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONVERSATION"})
      if (types.includes('task'))this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "TASK"})
      this.props.pusher.connect()
    }

    var listEl = document.getElementsByClassName("list-sidebar")[0]
    if(listEl) listEl.addEventListener("keydown", this._handleListKeyDown);

  }
  
  public componentDidUpdate(prevProps:any) {
    if (this.props.params.id !== this.state.itemId || this.state.searchQuery !== document.location.search || ((prevProps.userData !== this.props.userData) && this.props.userData)) {
      this.setState({searchQuery: document.location.search, itemId: this.props.params.id})
      this.getCurrentContent();
    }

    if (this.props.params.id !== this.state.itemId || this.state.searchQuery !== document.location.search) {
      const params = new URLSearchParams(document.location.search);
      const types = params.get('type')?.split(',') || [];
      if (this.props.pusher) {
        this.props.pusher.disconnect();
        if (this.props.page === 'projectData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "PROJECT"})
        else if (this.props.page === 'contractData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTRACT"})
        else if (this.props.page === 'contactData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTACT"})
        else if (this.props.page === 'companyData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "COMPANY"})
        if (types.includes('note')) this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "NOTE"})
        if (types.includes('email'))this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "EMAIL"})
        if (types.includes('conversation'))this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONVERSATION"})
        if (types.includes('task'))this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "TASK"})
        this.props.pusher.connect()
      }
    }

    if (prevProps.pusher !== this.props.pusher) {
      const params = new URLSearchParams(document.location.search);
      const types = params.get('type')?.split(',') || [];
      if (this.props.pusher && this.props.userData && this._isMounted) {
        if (this.props.page === 'projectData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "PROJECT"})
        else if (this.props.page === 'contractData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTRACT"})
        else if (this.props.page === 'contactData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTACT"})
        else if (this.props.page === 'companyData') this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "COMPANY"})
        if (types.includes('note')) this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "NOTE"})
        if (types.includes('email'))this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "EMAIL"})
        if (types.includes('conversation'))this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONVERSATION"})
        if (types.includes('task'))this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "TASK"})
        this.props.pusher.connect()
      }
    }

    if (prevProps.pusherMessage !== this.props.pusherMessage) {
      const { pusherMessage } = this.props;
      if(pusherMessage && pusherMessage.message) {
        console.log(pusherMessage)
        if (pusherMessage.message.itemType === 'NOTE') {
          if (pusherMessage.message.action === 'DELETE') {
            let items = this.state.activities;
            items = items.filter((item)=>{return item.id !== pusherMessage.message.itemId})
            this._isMounted && this.setState({activities: items})
          } else if (pusherMessage.message.action === 'UPDATE') {
            let items = this.state.activities;
            for (let i in items) {
              if (items[i].id === pusherMessage.message.itemId) {
                userService.getNote(pusherMessage.message.itemId).then((response)=>{
                  if (response.data) {
                    items[i] = response.data
                    items[i].key = items[i].id;
                    items[i].type = 'NOTE';
                    items[i].date = items[i].modifiedDateTime;
                    this._isMounted && this.setState({activities: items.sort((a,b) => { return (a?.date >= b?.date ? -1 : 1)})}, ()=>{
                      this.updateSelection()
                    })
                  }
                })
                break;
              }
            }
          } else if (pusherMessage.message.action === 'CREATE') {
            userService.getNote(pusherMessage.message.itemId).then((response)=>{
              if (response.data) {
                let item = response.data
                if (item.projectIds.includes(this.props.params.id) 
                 || item.contractIds.includes(this.props.params.id)
                 || item.contactIds.includes(this.props.params.id) 
                 || item.companyIds.includes(this.props.params.id)) {
                  item.key = item.id;
                  item.type = 'NOTE';
                  item.date = item.modifiedDateTime;
                  let items = this.state.activities;
                  items.push(item)
                  this._isMounted && this.setState({activities: items.sort((a,b) => { return (a?.date >= b?.date ? -1 : 1)})}, ()=>{
                    this.updateSelection()
                  })
                }
              }
            })
          }
        } else if (pusherMessage.message.itemType === 'EMAIL') {
          if (pusherMessage.message.action === 'DELETE') {
            let items = this.state.activities;
            items = items.filter((item)=>{return item.id !== pusherMessage.message.itemId})
            this._isMounted && this.setState({activities: items})
          } else if (pusherMessage.message.action === 'UPDATE') {
            let items = this.state.activities;
            for (let i in items) {
              if (items[i].id === pusherMessage.message.itemId) {
                userService.getEmail(pusherMessage.message.itemId).then((response)=>{
                  if (response.data) {
                    items[i] = response.data
                    items[i].key = items[i].id;
                    items[i].type = 'EMAIL';
                    items[i].date = items[i].savedDateTime;
                    this._isMounted && this.setState({activities: items.sort((a,b) => { return (a?.date >= b?.date ? -1 : 1)})}, ()=>{
                      this.updateSelection()
                    })
                  }
                })
                break;
              }
            }
          } else if (pusherMessage.message.action === 'CREATE') {
            userService.getEmail(pusherMessage.message.itemId).then((response)=>{
              if (response.data) {
                let item = response.data
                if (item.contactIds.includes(this.props.params.id)) {
                  item.key = item.id;
                  item.type = 'EMAIL';
                  item.date = item.savedDateTime;
                  let items = this.state.activities;
                  items.push(item)
                  this._isMounted && this.setState({activities: items.sort((a,b) => { return (a?.date >= b?.date ? -1 : 1)})}, ()=>{
                    this.updateSelection()
                  })
                }
              }
            })
          }
        } /*else if (pusherMessage.message.itemType === 'CONTACT') {
          if (pusherMessage.message.action === 'DELETE') {
            this.getCurrentContent();
          } else if (pusherMessage.message.action === 'UPDATE') {
            userService.getContacts(pusherMessage.message.itemId).then((response)=>{
              if (response.data && response.data.length > 0) {
                let item = response.data[0]
                this._isMounted && this.setState({item: item})
              }
            })
          }
        }*/
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    if (this.props.pusher) {
      this.props.pusher.disconnect();
    }
    var listEl = document.getElementsByClassName("list-sidebar")[0]
    if(listEl) listEl.removeEventListener("keydown", this._handleListKeyDown, false);
  }

  private updateEmail(item, selItem) {
    let activities = this.state.activities;
    for (let i in activities) {
      if (activities[i].id === selItem.id) {
        if (selItem.id.startsWith('Email-')) {
          activities[i] = {...item, type: 'EMAIL', key: selItem.id};
        } else {
          activities[i].entity = {...item, type: 'EMAIL', key: selItem.id};
        }
        selItem = activities[i]
      }
    }
    let newSelItem:any = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null
    this._isMounted && this.setState({
      activities: activities,
      selItem: newSelItem && newSelItem.id === selItem.id ? selItem : newSelItem
    });
  }

  private _handleListKeyDown = (event) => {
    if (event.repeat) { return }
    const selection:any[] = this._selection.getSelection();
    const selectedCount = selection.length;
    switch( event.code ) {
      case 'Delete': // Delete
        if(event.target.className.indexOf("ms-DetailsRow") !== -1 || event.target.className.indexOf("ms-GroupHeader") !== -1) {
          if (this.state.selItem && this.state.selItem.type === "NOTE" && selectedCount === 1) this.headerRef._showNoteFormDelete()
          else if (this.state.selItem && this.state.selItem.type === "CONVERSATION" && selectedCount === 1) this.headerRef._showConversationFormDelete()
          else if (this.state.selItem && this.state.selItem.type === "EMAIL" && selectedCount === 1) this.headerRef._showEmailFormDelete()
        }
        break;
      case 'F2': //Edit
        if(event.target.className.indexOf("ms-DetailsRow") !== -1 || event.target.className.indexOf("ms-GroupHeader") !== -1) {
          if(event.target.className.indexOf("ms-DetailsRow") !== -1 || event.target.className.indexOf("ms-GroupHeader") !== -1) {
            if (this.state.selItem && this.state.selItem.type === "NOTE" && selectedCount === 1) this.headerRef._showNoteFormEdit()
            else if (this.state.selItem && this.state.selItem.type === "CONVERSATION" && selectedCount === 1) this.headerRef._showConversationFormEdit()
          }
        }
        break;
      default:
      break;
    }
  }

  private updateConversation(conversation:any) {
    let activites = this.state.activities;
    for (let i in activites) {
      if (activites[i].id === conversation.id) {
        activites[i] = conversation;
      }
    }
    this._isMounted && this.setState({activities: activites})
  }

  private getEntityType() {
    if (this.props.page === 'projectData') return 'PROJECT'
    else if (this.props.page === 'contractData') return 'CONTRACT'
    else if (this.props.page === 'contactData') return 'CONTACT'
    else if (this.props.page === 'companyData') return 'COMPANY'
    return ''
  }

  private async getEntities(id:string) {
    return await new Promise((resolve) => {
      if (this.props.page === 'projectData') {
        userService.getProjects(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'contractData') {
        userService.getContracts(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'contactData') {
        userService.getContacts(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'companyData') {
        userService.getCompanies(id).then((res)=>{resolve(res)})
      }
    })
  }

  private async getEntityEmails(id:string) {
    return await new Promise((resolve) => {
      if (this.props.page === 'projectData') {
        userService.getProjectEmails(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'contractData') {
        userService.getContractEmails(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'contactData') {
        userService.getContactEmails(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'companyData') {
        userService.getCompanyEmails(id).then((res)=>{resolve(res)})
      }
    })
  }

  private async getEntityNotes(id:string) {
    return await new Promise((resolve) => {
      if (this.props.page === 'projectData') {
        userService.getProjectNotes(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'contractData') {
        userService.getContractNotes(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'contactData') {
        userService.getContactNotes(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'companyData') {
        userService.getCompanyNotes(id).then((res)=>{resolve(res)})
      }
    })
  }

  private async getEntityConversations(id:string) {
    return await new Promise((resolve) => {
      if (this.props.page === 'projectData') {
        userService.getProjectConversations(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'contractData') {
        userService.getContractConversations(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'contactData') {
        userService.getContactConversations(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'companyData') {
        userService.getCompanyConversations(id).then((res)=>{resolve(res)})
      }
    })
  }

  private async getEntityTasks(id:string) {
    return await new Promise((resolve) => {
      if (this.props.page === 'projectData') {
        userService.getProjectTasks(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'contractData') {
        userService.getContractTasks(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'contactData') {
        userService.getContactTasks(id).then((res)=>{resolve(res)})
      }
      else if (this.props.page === 'companyData') {
        userService.getCompanyTasks(id).then((res)=>{resolve(res)})
      }
    })
  }

  private async getCurrentContent() {
    this._isMounted && this.setState({
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      },
      item: null
    })
    
    let activity:any[] = [];
    let customProperties:any[] = [];
    const params = new URLSearchParams(document.location.search);
    const types = params.get('type')?.split(',') || null;

    userService.getPermissions(this.props.params.id).then((response)=>{
      const permissionsUsers = response.data.userPermissions;
      const permissionsGroups = response.data.groupPermissions;
      const permissionLevels = {
        'ADMIN': 0,
        'EDITOR': 1,
        'RESTRICTED_EDITOR': 2,
        'VIEWER': 3,
        'PREVIEWER': 4
      }
      let highestPermission = 'PREVIEWER';

      let noPermission = true;
      if (this.props.userData && this.props.userData.user.role === 'ADMIN') {
        noPermission = false;
        highestPermission = 'ADMIN'
      } else {
        if (permissionsUsers && permissionsUsers.length > 0) {
          for (let i in permissionsUsers) {
            if (permissionsUsers[i].userId === this.props.userData.user.id) {
              noPermission = false;
              if (permissionLevels[permissionsUsers[i].permissionRole] < permissionLevels[highestPermission]) highestPermission = permissionsUsers[i].permissionRole
            }
          }
        }
        if (permissionsGroups && permissionsGroups.length > 0) {
          for (let i in permissionsGroups) {
            if (this.props.userData && this.props.userData.groups.includes(permissionsGroups[i].groupId)){
              noPermission = false;
              if (permissionLevels[permissionsGroups[i].permissionRole] < permissionLevels[highestPermission]) highestPermission = permissionsGroups[i].permissionRole
            }
          }
        }
      }

      
      if (!noPermission) {
        let entity: any;
        let promises:any[] = []
        promises.push(this.getEntities(this.props.params.id).then(async (response:any)=>{
          if (response.data) {
            entity = response.data[0];
            entity.companyIds?.length > 0 && await new Promise((resolve) => {
              userService.getCompanies(entity.companyIds.join(',')).then((res)=>{
                entity.companyRelations = res.data.sort((a,b) => {return (a.name || '').localeCompare(b.name || '')});
                resolve(true);
              }).catch((error)=>{
                console.log(error);
                resolve(true);
              })
            })
            entity.contactIds?.length > 0 && await new Promise((resolve) => {
              userService.getContacts(entity.contactIds.join(',')).then((res)=>{
                entity.contactRelations = res.data.sort((a,b) => {return (a.name || '').localeCompare(b.name || '')});
                resolve(true);
              }).catch((error)=>{
                console.log(error);
                resolve(true);
              })
            })
            entity.contractIds?.length > 0 && await new Promise((resolve) => {
              userService.getContracts(entity.contractIds.join(',')).then((res)=>{
                entity.contractRelations = res.data.sort((a,b) => {return (a.name || '').localeCompare(b.name || '')});
                resolve(true);
              }).catch((error)=>{
                console.log(error);
                resolve(true);
              })
            })
            entity.projectIds?.length > 0 && await new Promise((resolve) => {
              userService.getProjects(entity.projectIds.join(',')).then((res)=>{
                entity.projectRelations = res.data.sort((a,b) => {return (a.name || '').localeCompare(b.name || '')});
                resolve(true);
              }).catch((error)=>{
                console.log(error);
                resolve(true);
              })
            })
          }
        }).catch((error)=>{
          console.log(error);
          this._isMounted && this.setState({
            errorAlert: 'invalidEntity',
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        }))
        activity = []
        let emailsActivity:any[] = []
        let notesActivity:any[] = []
        let conversationsActivity:any[] = []
        let tasksActivity:any[] = []
        if (!types) {
          promises.push(userService.searchActivity('?page=0', {
            application: 'WORKFLOW',
            ids : [this.props.params.id]
          }).then(async (response:any)=>{
            activity = response.data.content
          }).catch((error)=>{
            console.log(error);
            this._isMounted && this.setState({
              errorAlert: 'invalidEntity',
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            })
          }))
          promises.push(this.getEntityEmails(this.props.params.id).then((response:any)=>{emailsActivity = response.data.content}))
          promises.push(this.getEntityNotes(this.props.params.id).then((response:any)=>{notesActivity = response.data.content}))
          promises.push(this.getEntityConversations(this.props.params.id).then((response:any)=>{conversationsActivity = response.data.content}))
          promises.push(this.getEntityTasks(this.props.params.id).then((response:any)=>{tasksActivity = response.data.content}))
        }
        if (types?.includes('email')) promises.push(this.getEntityEmails(this.props.params.id).then(async (response:any)=>{
          activity = [...activity, ...response.data.content.map((item)=>{return item ? {...item, type: 'EMAIL', key: item.id, date: item.savedDateTime} : null})]
        }).catch((error)=>{
          console.log(error);
          this._isMounted && this.setState({
            errorAlert: 'invalidEntity',
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        }))
        if (types?.includes('note')) promises.push(this.getEntityNotes(this.props.params.id).then(async (response:any)=>{
          activity = [...activity, ...response.data.content.map((item)=>{return item ? {...item, type: 'NOTE', key: item.id, date: item.modifiedDateTime} : null})]
        }).catch((error)=>{
          console.log(error);
          this._isMounted && this.setState({
            errorAlert: 'invalidEntity',
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        }))
        if (types?.includes('conversation')) promises.push(this.getEntityConversations(this.props.params.id).then(async (response:any)=>{
          activity = [...activity, ...response.data.content.map((item)=>{return item ? {...item, type: 'CONVERSATION', key: item.id, date: item.modifiedDateTime} : null})]
        }).catch((error)=>{
          console.log(error);
          this._isMounted && this.setState({
            errorAlert: 'invalidEntity',
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        }))
        if (types?.includes('task')) promises.push(this.getEntityTasks(this.props.params.id).then(async (response:any)=>{
          activity = [...activity, ...response.data.content.map((item)=>{return item ? {...item, type: 'TASK', key: item.id, date: item.modifiedDateTime} : null})]
        }).catch((error)=>{
          console.log(error);
          this._isMounted && this.setState({
            errorAlert: 'invalidEntity',
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        }))
        promises.push(userService.getCustomProperties('', this.getEntityType()).then(async (response:any)=>{
          customProperties = response.data.map(item=>{return {...item, id: item.id, name: item.name, position: item.customPropertyTypes.find(x=>{return x.entityType === this.getEntityType()}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === this.getEntityType()}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}});
          customProperties = customProperties.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
        }).catch((error)=>{
          console.log(error);
          this._isMounted && this.setState({
            errorAlert: 'invalidEntity',
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          })
        }))
        let pipelines: any[];
        if ((this.props.params.id || '').startsWith('Project-')) {
          promises.push(userService.getPipelines().then(response => {
            pipelines = response.data
          }))
        }
        let contacts:any[] = []
        promises.push(userService.getRepoUsers('ADMIN,INTERNAL').then((response)=>{
          contacts = response.data;
          for (let i=0; i<contacts.length; i++) {
              contacts[i].key = contacts[i].id
              contacts[i].text = contacts[i].name + (contacts[i].surname ? (' ' + contacts[i].surname) : '')
          }
        }).catch((error)=>{
          console.log(error);
        }))
        Promise.all(promises).then((res) => {
          if (!types) {
            for (let i in activity) {
              let found = false;
              if (!found) {
                if (activity[i].targets[0].id === this.props.params.id) {
                  activity[i].entity = entity
                  activity[i].key = activity[i].id
                  let type = (this.props.params.id.split('-')[0] || '').toUpperCase()
                  activity[i].type = type.startsWith('U') ? 'CONTACT' : type
                  //activity[i].key = activity[i].id + activity[i].activity.id
                  found = true
                }
              }

              if (!found) {
                for (let j in emailsActivity) {
                  if (activity[i].targets[0].id === emailsActivity[j].id || activity[i].source?.id === emailsActivity[j].id ) {
                    //activity[i] = {...emailsActivity[j], activity: activity[i]}
                    activity[i].entity = emailsActivity[j]
                    activity[i].key = activity[i].id
                    activity[i].type = 'EMAIL'
                    found = true
                    break;
                  }
                }
              }
              if (!found) {
                for (let j in notesActivity) {
                  if (activity[i].targets[0].id === notesActivity[j].id || activity[i].source?.id === notesActivity[j].id ) {
                    //activity[i] = {...notesActivity[j], activity: activity[i]}
                    activity[i].entity = notesActivity[j]
                    activity[i].type = 'NOTE'
                    activity[i].key = activity[i].id
                    activity[i].noteType = notesActivity[j].noteType
                    found = true
                    break;
                  }
                }
              }
              if (!found) {
                for (let j in conversationsActivity) {
                  if (activity[i].targets[0].id === conversationsActivity[j].id || activity[i].source?.id === conversationsActivity[j].id ) {
                    //activity[i] = {...conversationsActivity[j], activity: activity[i]}
                    activity[i].entity = conversationsActivity[j]
                    activity[i].key = activity[i].id
                    activity[i].type = 'CONVERSATION'
                    activity[i].conversationType = conversationsActivity[j].conversationType
                    found = true
                    break;
                  }
                }
              }
              if (!found) {
                for (let j in tasksActivity) {
                  if (activity[i].targets[0].id === tasksActivity[j].id || activity[i].source?.id === tasksActivity[j].id ) {
                    //activity[i] = {...tasksActivity[j], activity: activity[i]}
                    activity[i].entity = tasksActivity[j]
                    activity[i].key = activity[i].id
                    activity[i].type = 'TASK'
                    found = true
                    break;
                  }
                }
              }
            }
          }
          this._isMounted && this.setState({
            activitiesFull: activity.sort((a,b) => { return (a?.date >= b?.date ? -1 : 1)}),
            activities: activity.sort((a,b) => { return (a?.date >= b?.date ? -1 : 1)}),
            //updated: true,
            //item: entity,
            pipelines: pipelines,
            customProperties: customProperties,
            possibleContacts: contacts,
            breadcrumbPath: this.props.page === 'projectData' ? [
              {
              text: i18n.t('app:projects'),
              key: 'projects',
              onClick: ()=>{history.push('/projects')}
              },
              {
              text: entity.name,
              key: 'project',
              onClick: ()=>{history.push('/projects/'+this.props.params.id)}
              }
            ] : this.props.page === 'contractData' ? [
              {
                text: i18n.t('app:contracts'),
                key: 'contracts',
                onClick: ()=>{history.push('/contracts')}
                },
                {
                text: entity.name,
                key: 'contract',
                onClick: ()=>{history.push('/contracts/'+this.props.params.id)}
                }
            ] : this.props.page === 'contactData' ? [
              {
                text: i18n.t('app:contacts'),
                key: 'contacts',
                onClick: ()=>{history.push('/contacts')}
                },
                {
                text: entity.firstName + ' ' + entity.lastName,
                key: 'contact',
                onClick: ()=>{history.push('/contacts/'+this.props.params.id)}
                }
            ] : this.props.page === 'companyData' ? [
              {
                text: i18n.t('app:companies'),
                key: 'companies',
                onClick: ()=>{history.push('/companies')}
                },
                {
                text: entity.name,
                key: 'company',
                onClick: ()=>{history.push('/companies/'+this.props.params.id)}
                }
            ] : [],
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            },
            selItem: null
          }, () => {
            this._isMounted && this.setState({
              item: entity
            })
            this.updateSelection()
          })
        })
      }
      else {
        this._isMounted && this.setState({
          errorAlert: 'noPermission',
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          }
        })
      }
    }).catch((error)=>{
      console.log(error);
      this._isMounted && this.setState({
        errorAlert: 'invalidEntity' ,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      })
    })
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private sortItems(field:string) {
    const { newToOld, activities } = this.state;
    let sorted = _copyAndSort(activities, field, newToOld)
    this._isMounted && this.setState({
      newToOld: !newToOld,
      activities: sorted,
      cardsSortedBy: field === 'date' ? 'By date' : field === 'title' ? 'By title' : 'By date'
    })
  }

  private _onContextMenu(item, index, ev: MouseEvent | any) {
    ev.preventDefault();
    const selection:any[] = this._selection.getSelection();
    const selectedCount = selection.length;

    const contextMenuProps: IContextualMenuProps = {
      target: ev.target as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      items: item.type === 'CONVERSATION' ? [
        {
          key:'editConversation',
          name:i18n.t('app:editConversation'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showConversationFormEdit(),
        },
        {
          key:'deleteConversation',
          name:i18n.t('app:deleteConversation'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showConversationFormDelete(),
        }
      ] : item.type === 'NOTE' ? [
        {
          key:'editNote',
          name:i18n.t('app:editNote'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showNoteFormEdit(),
        },
        {
          key:'deleteNote',
          name:i18n.t('app:deleteNote'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showNoteFormDelete(),
        }
      ] : item.type === 'EMAIL' ? [
        {
          key:'deleteEmail',
          name:i18n.t('app:deleteEmail'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showEmailFormDelete(),
        }
      ] : item.type === 'TASK' ? [
        {
          key:'editTask',
          name:i18n.t('app:editTask'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showTaskFormEdit(),
        },
        {
          key:'deleteTask',
          name:i18n.t('app:deleteTask'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showTaskFormDelete(),
        }
      ] : [],
      onDismiss: () => {
        this.setState({
          contextMenuProps: undefined
        });
      }
    }
    
    this._isMounted && this.setState({contextMenuProps: contextMenuProps})
    return true;

  }

  public render() {
    const { itemId, item, breadcrumbPath, query, activities, selItem, cardsSortedBy, customProperties } = this.state
    return(
      <div>
        <Header
          ref={instance => { this.headerRef = instance; }}
          userData={ this.props.userData }
          page={this.props.page}
          items={[]}
          selItem={selItem?.entity || selItem || item}
          callbackFunction={this.callbackFunction}
          showRightPanel={ this.state.showRightPanel }
          syncStatus={this.state.syncStatus}
          breadcrumbPath={breadcrumbPath}
          sidebarRef={ this.sidebarRef }
          getCurrentContent={this.getCurrentContent.bind(this)}
          customProperties={customProperties}
          id={itemId}
          parentId={this.props.params.id}
          selection={this._selection.getSelection()}
        />
        <div className="content-wrap d-flex flex-row" >
          <Sidebar
            ref={(instance:any) => { this.sidebarRef = instance; }}
            headerRef={ this.headerRef }
            userData={ this.props.userData }
            page={this.props.page}
            callbackFunction={this.callbackFunction}
            item={item}
            query={query}
            id={itemId}
          />
          <div className='d-flex flex-grow-1' style={{overflowX: 'auto', overflowY: 'hidden', height: 'calc(100vh - 129px)'}}>
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this._isMounted && this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                }, () => {
                  this._saveResizeState();
                });
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={254}
              //maxWidth={400}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree second list-sidebar">
                <ScrollablePane style={{width: 'calc(100% - 1px)', background: '#f2f2f2'}} scrollbarVisibility={ScrollbarVisibility.always}>
                <DetailsList
                  className='list-sidebar'
                  items={activities}
                  checkboxVisibility={CheckboxVisibility.always}
                  onRenderCheckbox={checkboxColumn}
                  onRenderMissingItem={()=>{console.log('hello')}}
                  onRenderDetailsHeader={onRenderDetailsHeader}
                  onItemContextMenu={(item,index,ev) => this._onContextMenu(item,index,ev)}
                  onItemInvoked={(item) => {
                    if (item.entity) {
                      if ((item.entity.id).startsWith('Note-')) history.push('/notes/id/'+item.entity.id)
                      else if ((item.entity.id).startsWith('Email-')) history.push('/emails/id/'+item.entity.id)
                      else if ((item.entity.id).startsWith('Conversation-')) history.push('/conversations/id/'+item.entity.id)
                      else if ((item.entity.id).startsWith('Task-')) history.push('/tasks/id/'+item.entity.id)
                    } else {
                      if ((item.id).startsWith('Note-')) history.push('/notes/id/'+item.id)
                      else if ((item.id).startsWith('Email-')) history.push('/emails/id/'+item.id)
                      else if ((item.id).startsWith('Conversation-')) history.push('/conversations/id/'+item.id)
                      else if ((item.id).startsWith('Task-')) history.push('/tasks/id/'+item.id)
                    }
                  }}
                  columns={[
                    {
                      key: 'title',
                      name: 'Title',
                      fieldName: 'title',
                      minWidth: 1,
                      isRowHeader: true,
                      data: 'string',
                      isPadded: true,
                      onRender: (item: any) => {
                        return (
                          <>
                            <div className='d-flex align-items-center'  style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                              {
                                item.type === 'EMAIL' ?
                                <Icon className='activity-icon' styles={{root: {position:'absolute', left: '-37px', top: '4px', display: (this._selection.getSelection()[0] && this._selection.getSelection()[0].key === item.key) || this._selection.getSelection().length > 1  ? 'none' : 'block'}}} style={{width: '32px', minWidth: '32px'}} {...getFileTypeIconProps({ extension: '.eml', size: 32, imageFileType: 'svg' }) } />
                              : item.type === 'CONVERSATION' ?
                                <img className='activity-icon' style={{transform: 'scale(1, 1.3)', height: '24px', width: '24px', minWidth: '24px', position:'absolute', left: '-33px', top: '11px',  display: (this._selection.getSelection()[0] && this._selection.getSelection()[0].key === item.key) || this._selection.getSelection().length > 1  ? 'none' : 'block'}} alt='Note' src={process.env.PUBLIC_URL + '/icons/conversations.svg'} />
                              : item.type === 'NOTE' ?
                                <img className='activity-icon' style={{height: '24px', width: '24px', minWidth: '24px', position:'absolute', left: '-33px', top: '8px', display: (this._selection.getSelection()[0] && this._selection.getSelection()[0].key === item.key) || this._selection.getSelection().length > 1  ? 'none' : 'block'}} alt='Note' src={process.env.PUBLIC_URL + '/icons/documents.svg'} />
                              : item.type === 'TASK' ?
                                <img className='activity-icon' style={{height: '24px', width: '24px', minWidth: '24px', position:'absolute', left: '-33px', top: '8px', display: (this._selection.getSelection()[0] && this._selection.getSelection()[0].key === item.key) || this._selection.getSelection().length > 1  ? 'none' : 'block'}} alt='Task' src={process.env.PUBLIC_URL + '/icons/tasks.svg'} />
                              : item.type === 'PROJECT' ?
                                <img className='activity-icon' style={{height: '24px', width: '24px', minWidth: '24px', position:'absolute', left: '-33px', top: '8px', display: (this._selection.getSelection()[0] && this._selection.getSelection()[0].key === item.key) || this._selection.getSelection().length > 1  ? 'none' : 'block'}} alt='Task' src={process.env.PUBLIC_URL + '/icons/projects.svg'} />
                              : item.type === 'CONTRACT' ?
                                <img className='activity-icon' style={{height: '24px', width: '24px', minWidth: '24px', position:'absolute', left: '-33px', top: '8px', display: (this._selection.getSelection()[0] && this._selection.getSelection()[0].key === item.key) || this._selection.getSelection().length > 1  ? 'none' : 'block'}} alt='Task' src={process.env.PUBLIC_URL + '/icons/contracts.svg'} />
                              : item.type === 'CONTACT' ?
                                <img className='activity-icon' style={{height: '24px', width: '24px', minWidth: '24px', position:'absolute', left: '-33px', top: '8px', display: (this._selection.getSelection()[0] && this._selection.getSelection()[0].key === item.key) || this._selection.getSelection().length > 1  ? 'none' : 'block'}} alt='Task' src={process.env.PUBLIC_URL + '/icons/contacts.svg'} />
                              : item.type === 'COMPANY' ?
                                <img className='activity-icon' style={{height: '30px', width: '30px', minWidth: '30px', position:'absolute', left: '-31px', top: '6px', display: (this._selection.getSelection()[0] && this._selection.getSelection()[0].key === item.key) || this._selection.getSelection().length > 1  ? 'none' : 'block'}} alt='Task' src={process.env.PUBLIC_URL + '/icons/companies.svg'} />
                              //: (item.id || '').startsWith('ActF-') ?
                                //<Icon iconName={item.icon} style={{fontSize: '20px'}}/>
                              : 
                                <Icon iconName={item.icon} style={{fontSize: '20px'}}/>
                              }
                              <div className='align-items-center ml-1 flex-grow-1' style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                {(item.id || '').startsWith('ActF-') &&
                                  <div className='d-flex'>
                                    <span className='' style={{fontSize:12, color: '#424242', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{this.getContactName(item.userId) + ' ' +_getActivityAction(item)}</span>
                                  </div>
                                }
                                <div className='d-flex'>
                                  <span className='span-title mr-2' style={{textOverflow: 'ellipsis', overflowX: 'hidden'}}>{item.entity?.name || item.name || item.entity?.firstName || item.firstName || (item.entity?.content && item.entity?.content.subject ? item.entity?.content.subject : item.entity?.subject) || (item.content && item.content.subject ? item.content.subject : item.subject)}</span>
                                </div>
                                {!(item.id || '').startsWith('ActF-') &&
                                  <div className='d-flex'>
                                    <span className='mr-2' style={{fontSize:12, textOverflow: 'ellipsis', overflowX: 'hidden'}}>{this.getContactName(item.entity?.savedByUserId || item.entity?.createdByUserId || item.savedByUserId || item.createdByUserId)}</span>
                                  </div>
                                }
                                <div className='d-flex'>
                                  {item.bodyPreview && <span className='pr-2' style={{fontSize:12, color: '#424242', overflowX: 'hidden', textOverflow: 'ellipsis', maxWidth: 'calc(100% - 80px)'}}>{item.bodyPreview || ''}</span>}
                                  {item.type === 'NOTE' && <div className='d-flex align-items-center w-100' style={{}}>
                                    {item.type === 'NOTE' && <Icon iconName={item.noteType === 'PLAIN' ? 'QuickNote' : item.noteType === 'SMS' ? 'OfficeChat' : item.noteType === 'LINKEDIN' ? 'LinkedInLogo' : item.noteType === 'WHATSAPP' ? 'Phone' : item.noteType === 'CALL' ? 'Phone' : item.noteType === 'EMAIL' ? 'Mail' : ''} style={{fontSize: '12px', color: 'rgb(16, 110, 190)'}}/>}
                                    <span className='ml-1 pr-2' style={{fontSize:12, color: '#424242', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{item.noteType === 'PLAIN' ? 'Standard note' : item.noteType === 'SMS' ? 'SMS' : item.noteType === 'LINKEDIN' ? 'LinkedIn' : item.noteType === 'WHATSAPP' ? 'Whatsapp' : item.noteType === 'CALL' ? 'Call' : item.noteType === 'EMAIL' ? 'Email' : ''}</span>
                                    <span className='ml-auto mr-3' style={{fontSize:12, color: '#424242'}}>{item.entity ? Moment(item.date).format(i18n.t('Y-MMM-DD HH:mm')) : item.content && item.content.modifiedDateTime ? Moment(item.content.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) : item.savedDateTime ? Moment(item.savedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) : Moment(item.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm'))}</span>
                                  </div>}
                                  {item.type === 'CONVERSATION' && <div className='d-flex align-items-center w-100' style={{}}>
                                    {item.type === 'CONVERSATION' && <Icon iconName={item.conversationType === 'GENERAL' ? 'Chat' : item.conversationType === 'CONVERSATION' ? 'OfficeChat' : item.conversationType === 'ANNOUNCEMENT' ? 'Megaphone' : item.conversationType === 'APPROVAL' ? 'DocumentApproval' : item.conversationType === 'REVIEW' ? 'CheckboxComposite' : item.conversationType === 'MEETING' ? 'JoinOnlineMeeting' : ''} style={{fontSize: '12px', color: 'rgb(16, 110, 190)'}}/>}
                                    <span className='ml-1 pr-2' style={{fontSize:12, color: '#424242', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{item.conversationType === 'GENERAL' ? 'General' : item.conversationType === 'CONVERSATION' ? 'Conversation' : item.conversationType === 'ANNOUNCEMENT' ? 'Announcement' : item.conversationType === 'APPROVAL' ? 'Approval' : item.conversationType === 'REVIEW' ? 'Review' : item.conversationType === 'MEETING' ? 'Meeting' : ''}</span>
                                    <span className='ml-auto mr-3' style={{fontSize:12, color: '#424242'}}>{item.entity ? Moment(item.date).format(i18n.t('Y-MMM-DD HH:mm')) : item.content && item.content.modifiedDateTime ? Moment(item.content.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) : item.savedDateTime ? Moment(item.savedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) : Moment(item.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm'))}</span>
                                  </div>}
                                  {item.type === 'TASK' && <div className='d-flex align-items-center w-100' style={{}}>
                                    <div className='d-flex align-items-center w-100'>
                                      {this.getImportance(item.entity || item)}
                                      <span className='ml-auto mr-3' style={{fontSize:12, color: '#424242'}}>{item.entity ? Moment(item.date).format(i18n.t('Y-MMM-DD HH:mm')) : item.content && item.content.modifiedDateTime ? Moment(item.content.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) : item.savedDateTime ? Moment(item.savedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) : Moment(item.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm'))}</span>
                                    </div>
                                  </div>}
                                  {(item.type === 'PROJECT' || item.type === 'CONTRACT' || item.type === 'CONTACT' || item.type === 'COMPANY') && <div className='d-flex align-items-center w-100' style={{}}>
                                    <span className='pr-2' style={{fontSize:12, color: '#424242', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{item.entity?.status ? i18n.t('app:'+item.entity?.status) : item.entity?.domain ? item.entity?.domain : item.entity?.emailAddresses ? item.entity?.emailAddresses[0].email : ''}</span>
                                    <span className='ml-auto mr-3' style={{fontSize:12, color: '#424242'}}>{Moment(item.date).format(i18n.t('Y-MMM-DD HH:mm'))}</span>
                                  </div>}
                                  {item.type !== 'NOTE' && item.type !== 'CONVERSATION' && !(item.id || '').startsWith('ActF-') && <span className='ml-auto mr-3' style={{fontSize:12, color: '#424242'}}>{item.content && item.content.modifiedDateTime ? Moment(item.content.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) : item.savedDateTime ? Moment(item.savedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) : Moment(item.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm'))}</span>}
                                  {/*(item.id || '').startsWith('ActF-') && <div className='d-flex align-items-center w-100' style={{}}>
                                    {item.type === 'NOTE' && <Icon iconName={item.noteType === 'PLAIN' ? 'QuickNote' : item.noteType === 'SMS' ? 'OfficeChat' : item.noteType === 'LINKEDIN' ? 'LinkedInLogo' : item.noteType === 'WHATSAPP' ? 'Phone' : item.noteType === 'CALL' ? 'Phone' : item.noteType === 'EMAIL' ? 'Mail' : ''} style={{fontSize: '12px', color: 'rgb(16, 110, 190)'}}/>}
                                    <span className='pr-2' style={{fontSize:12, color: '#424242', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{this.getContactName(item.userId)}</span>
                                    <span className='ml-auto mr-3' style={{fontSize:12, color: '#424242'}}>{Moment(item.date).format(i18n.t('Y-MMM-DD HH:mm'))}</span>
                                  </div>*/}
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      },
                      onRenderHeader: (item: any)=>{
                        return(
                          <div className='d-flex align-items-center w-100'>
                            <span style={{marginRight: '28px'}}> 
                            Title
                            </span>
                            <ActionButton 
                              className='ml-auto mr-3 px-0' 
                              style={{color: 'rgb(50, 49, 48)'}} 
                              iconProps={{iconName:'SortLines'}}
                              text={cardsSortedBy} 
                              menuProps={{items: [
                                {
                                  key: 'byDate',
                                  text: 'By date',
                                  iconProps: {iconName:'Calendar'},
                                  onClick:()=>this.sortItems('date')
                                },
                                {
                                  key: 'byName',
                                  text: 'By title',
                                  iconProps: {iconName:'HalfAlpha'},
                                  onClick:()=>this.sortItems('title')
                                }
                              ]}}
                            />
                          </div>
                      )},

                    },
                  ]}
                  selection={ this._selection }
                  setKey="set"
                />
                </ScrollablePane>
                { this.state.contextMenuProps ?
                  <ContextualMenu {...this.state.contextMenuProps} />
                : null }
                {(!activities || activities.length === 0) && this.state.syncStatus.message === 'Synced' && <div style={{opacity: .8}} className='mt-5 pt-2 text-center'>No items</div> }
                {(!activities || activities.length === 0) && this.state.syncStatus.message === 'Syncing' && <Spinner className='mt-5 pt-2 text-center' size={SpinnerSize.medium}/> }
              </nav>
            </Resizable>
            <div className="list mr-auto flex-grow-1 d-flex flex-column" style={{minWidth: '420px'}}>
              <div style={{overflowY: 'hidden', overflowX: 'auto', minWidth: '400px', height: '100%'}}>
              <div className='d-flex header-card py-2'>
                <div className='d-flex flex-grow-1 align-items-center ml-1'>
                  {this.state.selItem ? 
                    <>
                      {
                        this.state.selItem.type === 'EMAIL' ?
                        <Icon className='activity-icon mr-3' style={{width: '32px', minWidth: '32px'}} {...getFileTypeIconProps({ extension: '.eml', size: 32, imageFileType: 'svg' }) } />
                      : this.state.selItem.type === 'CONVERSATION' ?
                        <img className='activity-icon mr-3' style={{transform: 'scale(1, 1.3)', height: '24px', width: '24px', minWidth: '24px'}} alt='Note' src={process.env.PUBLIC_URL + '/icons/conversations.svg'} />
                      : this.state.selItem.type === 'NOTE' ?
                        <img className='activity-icon mr-3' style={{height: '24px', width: '24px', minWidth: '24px'}} alt='Note' src={process.env.PUBLIC_URL + '/icons/documents.svg'} />
                      : this.state.selItem.type === 'TASK' ?
                        <img className='activity-icon mr-3' style={{height: '24px', width: '24px', minWidth: '24px'}} alt='Task' src={process.env.PUBLIC_URL + '/icons/tasks.svg'} />
                      : this.state.selItem.type === 'PROJECT' ?
                        <img className='activity-icon mr-3' style={{height: '24px', width: '24px', minWidth: '24px'}} alt='Project' src={process.env.PUBLIC_URL + '/icons/projects.svg'} />
                      : this.state.selItem.type === 'CONTRACT' ?
                        <img className='activity-icon mr-3' style={{height: '24px', width: '24px', minWidth: '24px'}} alt='Contract' src={process.env.PUBLIC_URL + '/icons/contracts.svg'} />
                      : this.state.selItem.type === 'CONTACT' ?
                        <img className='activity-icon mr-3' style={{height: '24px', width: '24px', minWidth: '24px'}} alt='Contact' src={process.env.PUBLIC_URL + '/icons/contacts.svg'} />
                      : this.state.selItem.type === 'COMPANY' ?
                        <img className='activity-icon mr-3' style={{height: '24px', width: '24px', minWidth: '24px'}} alt='Company' src={process.env.PUBLIC_URL + '/icons/companies.svg'} />
                      : (this.state.selItem.id || '').startsWith('ActF-') && this.state.selItem.operationType === 'CREATE' ?
                        <Icon className='activity-icon mr-3' style={{fontSize: '20px', width: '20px', minWidth: '20px', color: 'rgb(16, 110, 190)'}} iconName='Add' />
                      : (this.state.selItem.id || '').startsWith('ActF-') && this.state.selItem.operationType === 'UPDATE'  ?
                        <Icon className='activity-icon mr-3' style={{fontSize: '20px', width: '20px', minWidth: '20px', color: 'rgb(16, 110, 190)'}} iconName='Edit' />
                      : (this.state.selItem.id || '').startsWith('ActF-') && this.state.selItem.operationType === 'DELETE'  ?
                        <Icon className='activity-icon mr-3' style={{fontSize: '20px', width: '20px', minWidth: '20px', color: 'rgb(16, 110, 190)'}} iconName='Delete' />
                      : 
                        i18n.t('app:mainView')
                      }                    
                      <span style={{fontWeight: 600}}>{this.state.selItem.entity?.subject || this.state.selItem.subject || this.state.selItem.entity?.name || (this.state.selItem.entity?.firstName ? this.state.selItem.entity?.firstName + ' ' + this.state.selItem.entity?.lastName : '')}</span>
                    </>
                    :
                    i18n.t('app:mainView')
                  }
                </div>
              </div>
              {this.state.alertMessage ?
                <div id="alertMessage">
                  <MessageBar
                    messageBarType={this.state.alertMessage.type}
                    isMultiline={true}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => this.setState({alertMessage: null})}
                  >
                    <div dangerouslySetInnerHTML={{__html: this.state.alertMessage.text}}></div>
                  </MessageBar>
                </div>
              : null }
              <div className='flex-grow-1' style={{height: 'calc(100% - 44px)'}}>
                { this.state.selItem ?
                  <div className='h-100' key={this.state.selItem.key}>
                    {this.activityCard(this.state.selItem.entity || this.state.selItem, this.state.selItem.entity ? this.state.selItem : null)}
                  </div>
                : this._selection && this._selection.getSelection().length > 1 ?
                <div className='text-center h-100  d-flex flex-column' style={{backgroundColor: 'rgb(250, 249, 248)'}}> 
                  <div className='text-center text-secondary m-auto'>
                    <Icon style={{fontSize: '24px'}} iconName='Documentation'/>
                    <p>{this._selection.getSelection().length + ' ' + i18n.t('app:itemsSelected')}</p>
                  </div>
                </div> 
                :
                <div className='text-center h-100  d-flex flex-column' style={{backgroundColor: 'rgb(250, 249, 248)'}}> 
                  <div className='text-center text-secondary m-auto'>
                    <Icon style={{fontSize: '24px'}} iconName='Preview'/>
                    <p>{i18n.t('app:noItemSelected')}</p>
                  </div>
                </div> 
                }
                </div>
              </div>
            </div>
          </div>
          <DetailsPanel
            appPlatform={ this.props.appPlatform }
            userData={ this.props.userData }
            showRightPanel={ this.state.showRightPanel }
            callbackFunction={this.callbackFunction}
            page={this.props.page}
            id={itemId}
            selItem={selItem?.entity || selItem || item}
            selActivity={selItem?.entity ? selItem : null}
            items={this.state.activities}
            customProperties={customProperties}
            headerRef={this.headerRef}
            getCurrentContent={this.getCurrentContent.bind(this)}
            getImportance={this.getImportance.bind(this)}
            possibleContacts={this.state.possibleContacts}
            pipelines={this.state.pipelines}
          />
        </div>
        {this.state.errorAlert && this.state.errorAlert.length > 0 && this.props.page === 'projectData' && <AlertInvalidProject errorAlert={this.state.errorAlert} callbackFunction={this.callbackFunction}/>}
        {this.state.errorAlert && this.state.errorAlert.length > 0 && this.props.page === 'contractData' && <AlertInvalidContract errorAlert={this.state.errorAlert} callbackFunction={this.callbackFunction}/>}
        {this.state.errorAlert && this.state.errorAlert.length > 0 && this.props.page === 'contactData' && <AlertInvalidContact errorAlert={this.state.errorAlert} callbackFunction={this.callbackFunction}/>}
        {this.state.errorAlert && this.state.errorAlert.length > 0 && this.props.page === 'companyData' && <AlertInvalidCompany errorAlert={this.state.errorAlert} callbackFunction={this.callbackFunction}/>}
      </div>
    )
  }

  private getImportance(item:any) {

    const tags = {
      'URGENT': '#d69ca5',
      'LATER': '#f4bfab',
      'FOLLOW_UP': '#f9e2ae',
      'MAYBE': '#bdd99b',
    }

    return (
      <div className='d-flex align-items-center'>
        <Icon 
          iconName="CircleShapeSolid" 
          className="tag mr-1" 
          style={{
            fontSize: 12,
            textAlign:'center',
            color: tags[item.taskImportance] || 'black'
          }} 
        />
        <span style={{paddingBottom: '2px', fontSize: '12px', color: 'rgb(66, 66, 66)'}}>{i18n.t('app:' + item.taskImportance)}</span>
      </div> 
    )
  }

  private updateTask(task:any, ) {
    let items = this.state.activities;
    for (let i in items) {
      if (items[i].id === task.id) {
        items[i] = task;
      }
    }
    this._isMounted && this.setState({activities: items})
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("sidebar3Prefs", JSON.stringify(prefs))
  }

  private activityCard(item, activity?) {
    let renderedField:any = ''
    if (item.noPermission) {
      return (<div className='text-center h-100  d-flex flex-column' style={{backgroundColor: 'rgb(250, 249, 248)'}}> 
      <div className='text-center text-secondary m-auto'>
        <Icon style={{fontSize: '24px'}} iconName='Preview'/>
        <p>{i18n.t('app:noPermissionToView')}</p>
      </div>
    </div>)
    } else if (item.type === 'EMAIL') {
      return (
        <div className='h-100'>
          <EmailViewer
            allCardsCollapsed={this.state.allCardsCollapsed}
            item={item}
          />
        </div>
      )
    } else if (item.type === 'CONVERSATION') {
      return (
        <div className='h-100'>
          <ConversationViewer
            allCardsCollapsed={this.state.allCardsCollapsed}
            item={item}
            userData={this.props.userData}
            headerRef={this.headerRef}
            updateConversation={this.updateConversation.bind(this)}
            possibleContacts={this.state.possibleContacts}
            target={activity?.targets[0].id}
          />
        </div>
      )
    } else if (item.type === 'NOTE') {
      return (
        <div className='h-100'>
          <NoteViewer
            allCardsCollapsed={this.state.allCardsCollapsed}
            item={item}
            possibleContacts={this.state.possibleContacts}
          />
        </div>
      )
    } else if (item.type === 'TASK') {
      return (
        <div className='h-100'>
          <TaskViewer
            item={item}
            userData={this.props.userData}
            headerRef={this.headerRef}
            updateTask={this.updateTask.bind(this)}
            possibleContacts={this.state.possibleContacts}
            getImportance={this.getImportance.bind(this)}
            target={activity?.targets[0].id}
          />
        </div>
      )
    } else if (item.operationType === 'UPDATE') {
      return (
        <div className='h-100'>
          <EditCard item={item} users={this.state.possibleContacts}/>
        </div>
      )
    } else if (item.operationType === 'CREATE') {
      return (
        <div className='h-100'>
          <CreateCard item={item} users={this.state.possibleContacts}/>
        </div>
      )
    } else if (activity?.type === 'PROJECT' || activity?.type === 'CONTRACT' || activity?.type === 'CONTACT' || activity?.type === 'COMPANY') {
      let finalChanges:any[] = [];
      let currentFieldName: string = '';
      let currentField:any = null;
      console.log(activity.changes)
      if (activity.changes) {
        for (let i in activity.changes) {
          if (activity.changes[i].field !== 'birthDate') {
            const field = activity.changes[i].field.split('.');
            if (field[0] === 'description') field[0] = 'notes'
            if (field.length > 1) {
              if (field[0] === currentFieldName && currentField && currentField.id === field[1]) {
                if (activity.changes[i].oldValues) currentField.oldValues.push([field[2], activity.changes[i].oldValues[0]]) 
                if (activity.changes[i].newValues) currentField.newValues.push([field[2], activity.changes[i].newValues[0]]) 
              } else {
                currentFieldName = field[0]
                currentField = {
                  name: field[0],
                  id: field[1],
                  oldValues: activity.changes[i].oldValues ? [[field[2], activity.changes[i].oldValues[0]]] : [],
                  newValues: activity.changes[i].newValues ? [[field[2], activity.changes[i].newValues[0]]] : [],
                }
                finalChanges.push(currentField)
              }
            } else {
              currentFieldName = field[0]
              finalChanges.push({
                name: field[0],
                oldValues: activity.changes[i].oldValues ? [['',activity.changes[i].oldValues[0]]] : [],
                newValues: activity.changes[i].newValues ? [['',activity.changes[i].newValues[0]]] : [],
              })
            }
          }
        }
      }
      return (
        <div className='h-100' style={{overflowY: 'auto'}}>
          <div className='m-3'>
            <p>{this.getContactName(activity.userId) + ' ' + _getActivityAction(activity)}.</p>
          </div>
          {
            finalChanges.map((change,i) =>{
              if (change.oldValues && change.oldValues.length > 0 && change.newValues && change.newValues.length > 0) {
                return <div key={i} className='m-3'>
                  <div className='mb-1'>
                    <Label className='form-label-noWidth' style={{color: 'rgb(16, 110, 190)'}}>{i18n.t('app:'+change.name)} {' updated'}</Label>
                  </div>
                  <div>
                    <Label className='form-label-noWidth mr-3'>Before:</Label>
                    <div>
                      {change.oldValues && change.oldValues.map((change,i)=>{
                        return <p key={i} className='mb-1'>{change[0] ? i18n.t('app:'+change[0]) + ': ' + change[1] : change[1]}</p>
                      })}
                    </div>
                  </div>
                  <div>
                    <Label className='form-label-noWidth mt-2 mr-3'>After:</Label>
                    <div>
                      {change.newValues && change.newValues.map((change,i)=>{
                        return <p key={i} className='mb-1'>{change[0] ? i18n.t('app:'+change[0]) + ': ' + change[1] : change[1]}</p>
                      })}
                    </div>
                  </div>
                </div>
              } else if (change.oldValues && change.oldValues.length > 0) {
                return <div className='m-3'>
                  <div className='mb-1'>
                    <Label className='form-label-noWidth' style={{color: 'rgb(16, 110, 190)'}}>{i18n.t('app:'+change.name)} {' deleted'}</Label>
                  </div>
                  <div>
                    <Label className='form-label-noWidth mr-3'>Before:</Label>
                    <div>
                      {change.oldValues && change.oldValues.map((change, i)=>{
                        return <p key={i} className='mb-1'>{change[0] ? i18n.t('app:'+change[0]) + ': ' + change[1] : change[1]}</p>
                      })}
                    </div>
                  </div>
                </div>
              } else if (change.newValues && change.newValues.length > 0) {
                return <div className='m-3'>
                  <div className='mb-1'>
                    <Label className='form-label-noWidth' style={{color: 'rgb(16, 110, 190)'}}>{i18n.t('app:'+change.name)} {' added'}</Label>
                  </div>
                  <div>
                    <Label className='form-label-noWidth mr-3'>New:</Label>
                    <div>
                      {change.newValues && change.newValues.map((change, i)=>{
                        return <p key={i} className='mb-1'>{change[0] ? i18n.t('app:'+change[0]) + ': ' + change[1] : change[1]}</p>
                      })}
                    </div>
                  </div>
                </div>
              }
            })
          }
        </div>
      )
    } else {
      return ( this._selection && this._selection.getSelection().length > 1 ?
        <div className='text-center h-100  d-flex flex-column' style={{backgroundColor: 'rgb(250, 249, 248)'}}> 
          <div className='text-center text-secondary m-auto'>
            <Icon style={{fontSize: '24px'}} iconName='Documentation'/>
            <p>{this._selection.getSelection().length + ' ' + i18n.t('app:itemsSelected')}</p>
          </div>
        </div> 
        :
        <div className='text-center h-100  d-flex flex-column' style={{backgroundColor: 'rgb(250, 249, 248)'}}> 
          <div className='text-center text-secondary m-auto'>
            <Icon style={{fontSize: '24px'}} iconName='Preview'/>
            <p>{i18n.t('app:noItemSelected')}</p>
          </div>
        </div>
      )
    }
  }

  private getContactName(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })

    return user ? (user.name || '') + ' ' + (user.surname || '') : ''
  }
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean, secondColumnKey?: string): T[] {
  const key = columnKey as keyof any;
  const key2 = secondColumnKey as keyof any;
  if (key === 'amount') {
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? parseFloat(a[key].split(' ')[0]) < parseFloat(b[key].split(' ')[0]) : parseFloat(a[key].split(' ')[0]) > parseFloat(b[key].split(' ')[0])) ? 1 : -1));
  } else {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (!a) return -1;
      if (!b) return 1;
      if (a[key2] < b[key2]) return -1;
      if (a[key2] > b[key2]) return 1;
      else if (a[key] < b[key]) return isSortedDescending ? 1 : -1;
      else if (a[key] > b[key]) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }
}

function checkboxColumn(props: any, defaultRender?: IRenderFunction<IDetailsListCheckboxProps>): JSX.Element {
  return  (
    <div className='d-flex align-items-center justifty-content-center h-100 w-100'>
      {defaultRender!({
        ...props
      })}
    </div>
  )
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}

export default withParams(EntityPage);
