import { Selection, CheckboxVisibility, DefaultButton, DetailsList, IconButton, PrimaryButton, SearchBox, SelectionMode, Icon, ComboBox, Dropdown, DatePicker } from '@fluentui/react';
import { Input, Label, Persona, Textarea } from '@fluentui/react-components';
import { Resizable } from 're-resizable';
import * as React from 'react';
import i18n from '../i18n';
import { userService } from '../_services';
import * as conversationsTypes from '../_data/conversations-types';

export interface Props {
  type: string;
  items: any;
  save:any;
  callback: any;
}

export interface States {
  panelWidth: any;
  panelHeight: any;
  items: any;
  itemsFull: any;
  itemsChecked: any;
  items2: any;
  id: string;
  name: string;
  nickname: string;
  phoneNumber: string;
  emailAddress: string;
  note: string;
  linkedin: string;
  domain: string;
  url: string;
  country: string;
  status: string;
  pipelines: any;
  pipeline: string;
  stages: any;
  stage: string;
  fileId: string;
  birthDate: any;
  jobTitle: string;
  department: string;
  contactType: string;
  fromSignatureDate: any;
  toSignatureDate: any;
  fromStartDate: any;
  toStartDate: any;
  fromExpirationDate: any;
  toExpirationDate: any;
  subject: string;
  content: string;
  from: string;
  to: string;
  createdByUserId: string;
  modifiedByUserId: string;
  fromReceivedDateTime: any;
  toReceivedDateTime: any;
  fromSavedDateTime: any;
  toSavedDateTime: any;
  fromNoteDate: any;
  toNoteDate: any;
  fromCreatedDateTime: any;
  toCreatedDateTime: any;
  fromModifiedDateTime: any;
  toModifiedDateTime: any;
  noteType: string;
  conversationType: string;
  fromDueDate: any;
  toDueDate: any;
  taskImportance: string;
  fromReminders: any;
  toReminders: any;
  assignedUsers: any[];
  description: string;
}

export class FilterColumn extends React.Component<Props, States> {
  private _isMounted: boolean;
  private _selection: Selection;
  private _selection2: Selection;

  private contactTypes = [
    {
      "id": 'INTERNAL',
      "key": 'INTERNAL',
      "tag": 'INTERNAL',
      "name": i18n.t('app:internal')
    },
    {
      "id": 'NONE',
      "key": 'NONE',
      "tag": 'NONE',
      "name": i18n.t('app:NONE')
    },
  ]

  private itemTypes = [
    {
      key: 'note',
      id: 'note',
      tag: 'note',
      name: i18n.t('app:note'),
      type: "tagType",
      iconName: 'QuickNote'
    },
    {
      key: 'email',
      id: 'email',
      tag: 'email',
      name: i18n.t('app:email'),
      type: "tagType",
      iconName: 'Mail'
    },
    {
      key: 'conversation',
      id: 'conversation',
      tag: 'conversation',
      name: i18n.t('app:conversation'),
      type: "tagType",
      iconName: 'OfficeChat'
    },
    {
      key: 'task',
      id: 'task',
      tag: 'task',
      name: 'Tasks',
      type: "tagType",
      iconName: 'IconSetsFlag'
    },
    {
      key: 'activity',
      id: 'activity',
      tag: 'activity',
      name: i18n.t('app:activity'),
      type: "tagType",
      iconName: 'LineChart'
    },
    {
      key: 'meeting',
      id: 'meeting',
      tag: 'meeting',
      name: 'Meetings/Calls',
      type: "tagType",
      iconName: 'ActivityFeed'
    },
    {
      key: 'file',
      id: 'file',
      tag: 'file',
      name: 'Files',
      type: "tagType",
      iconName: 'Documentation'
    }
  ]

  private projectStatusTypes = [
    {
      key: 'OPEN',
      id: 'OPEN',
      tag: 'OPEN',
      name: i18n.t('app:open'),
    },
    {
      key: 'CLOSE',
      id: 'CLOSE',
      tag: 'CLOSE',
      name: i18n.t('app:close'),
    }
  ]

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props;

    this._selection = new Selection({
      onSelectionChanged: () => {
        if (this._selection.getSelection().length > 0 ) {
          this._selection2.setAllSelected(false);
        } else if (this._selection.getSelection().length === 0 && this._selection2.getSelection().length === 0) {
          this._selection2.setIndexSelected(0,true,false);
        }
      }
    });

    this._selection2 = new Selection({
      onSelectionChanged: () => {
        setTimeout(()=>{
          if (this._selection2.getSelection().length === 1 ) {
            this._selection.setAllSelected(false);
          }
          else if (this._selection.getSelection().length === 0 && this._selection2.getSelection().length === 0) {
            this._selection2.setIndexSelected(0,true,false);
          }
        },1)
      }
    });

    var panelWidth = "300px"

    this.state = {
      panelHeight: 'auto',
      panelWidth: panelWidth,
      items: this.props.items,
      itemsFull: null,
      itemsChecked: [],
      items2: [
        { key: 'noFilter', text: i18n.t('app:noFilter') },
        { key: 'all', text: i18n.t('app:all') },
        { key: 'none', text: i18n.t('app:none')}
      ],
      id: '',
      name: '',
      nickname: '',
      phoneNumber: '',
      emailAddress:'',
      note: '',
      linkedin: '',
      domain: '',
      url: '',
      country: '',
      status: 'ANY',
      pipelines: [],
      pipeline: '',
      stages: [],
      stage: '',
      fileId: '',
      birthDate: '',
      jobTitle: '',
      department: '',
      contactType: '',
      fromSignatureDate: '',
      toSignatureDate: '',
      fromStartDate: '',
      toStartDate: '',
      fromExpirationDate: '',
      toExpirationDate: '',
      subject: '',
      content: '',
      from: '',
      to: '',
      createdByUserId: '',
      modifiedByUserId: '',
      fromReceivedDateTime: '',
      toReceivedDateTime: '',
      fromSavedDateTime: '',
      toSavedDateTime: '',
      fromNoteDate: '',
      toNoteDate: '',
      fromCreatedDateTime: '',
      toCreatedDateTime: '',
      fromModifiedDateTime: '',
      toModifiedDateTime: '',
      noteType: 'ANY',
      conversationType: '',
      fromDueDate: '',
      toDueDate: '',
      taskImportance: '',
      fromReminders: '',
      toReminders: '',
      assignedUsers: [],
      description: ''
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this.getContent()
    this.getCurrentFields()
  }
  
  public componentDidUpdate(prevProps:any) {
    if (prevProps.type !== this.props.type) {
      this.getContent()
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private getContent() {
    this._getItemsByType(this.props.type).then(()=>{
      var params = new URLSearchParams(document.location.search);
      let field:string = params.get(this.props.type) || "";
      let fields:string[] = field.split(',');
      if (field === 'all') {
        this._selection2.setAllSelected(false);this._selection2.setIndexSelected(1, true,false)
      } else if (field === 'none') {
        this._selection2.setAllSelected(false);this._selection2.setIndexSelected(2, true,false)
      } else if (fields && fields.length > 0 && field.length > 0) {
        setTimeout(()=>{
          let items:any = this._selection.getItems();
          for (let i=0; i < items.length; i++) {
            if (fields.includes(items[i].id)) {
              this._selection.setIndexSelected(i, true, false)
            }
          }
        },1)
      } else {
        this._selection2.setAllSelected(false);this._selection2.setIndexSelected(0, true,false)
      }
    })
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("sidebarPrefs", JSON.stringify(prefs))
  }

  private async _getItemsByType(type:string) {
    if (type === 'pipelineId') this._isMounted && this.setState({itemsFull: this.props.pipelines.slice(0), items: this.props.pipelines.slice(0)})
    else if (type === 'contactType') this._isMounted && this.setState({itemsFull: this.contactTypes.slice(0), items: this.contactTypes.slice(0)})
    else if (type === 'type') this._isMounted && this.setState({itemsFull: this.itemTypes.slice(0), items: this.itemTypes.slice(0)})
    else if (type === 'status') this._isMounted && this.setState({itemsFull: this.projectStatusTypes.slice(0), items: this.projectStatusTypes.slice(0)})
    else if (type === 'projectIds') {
      await new Promise((resolve) => {
        userService.getProjects().then((response)=>{
          this._isMounted && this.setState({itemsFull: response.data.slice(0), items: response.data.slice(0)})
          resolve(true);
        }).catch((error)=>{
          console.log(error)
          resolve(true);
        })
      })
    } else if (type === 'contractIds') {
      await new Promise((resolve) => {
        userService.getContracts().then((response)=>{
          this._isMounted && this.setState({itemsFull: response.data.slice(0), items: response.data.slice(0)})
          resolve(true);
        }).catch((error)=>{
          console.log(error)
          resolve(true);
        })
      })
    } else if (type === 'contactIds') {
      await new Promise((resolve) => {
        userService.getContacts().then((response)=>{
          this._isMounted && this.setState({itemsFull: response.data.slice(0), items: response.data.slice(0)})
          resolve(true);
        }).catch((error)=>{
          console.log(error)
          resolve(true);
        })
      })
    } else if (type === 'companyIds') {
      await new Promise((resolve) => {
        userService.getCompanies().then((response)=>{
          this._isMounted && this.setState({itemsFull: response.data.slice(0), items: response.data.slice(0)})
          resolve(true);
        }).catch((error)=>{
          console.log(error)
          resolve(true);
        })
      })
    } else if (type === 'typesRelations') {
      let items:any[] = conversationsTypes.data
      this._isMounted && this.setState({itemsFull: items.slice(0), items: items.slice(0)})
    }
  }

  private _filterList(value:string) {
    if (value === '') {
      this._isMounted && this.setState({items: this.state.itemsFull})
    } else {
      var items = this.state.itemsFull.filter((item)=> {
        return item.tag ? item.tag.replace(this.props.type+':','').toLowerCase().includes(value.toLowerCase()) : item.name ? item.name.toLowerCase().includes(value.toLowerCase()) : ((item.firstName || '') + ' ' + (item.lastName || '')).toLowerCase().includes(value.toLowerCase()) ;
      })
      this._isMounted && this.setState({items: items})
    }
    
  }

  private selectionToFilters() {
    const selection:any = this._selection.getSelection();
    let filters:any = [];
    for (let i=0; i<selection.length; i++) {
      filters.push({
          key: selection[i].id,
          id: selection[i].id,
          name: selection[i].name ? selection[i].name : (selection[i].firstName || '') + ' ' + (selection[i].lastName || '') ,
          type: this.props.type,
          category: this.props.type,
          iconName: 'Documentation'
      })
    }
    return filters;
  }

  private getCurrentFields() {
    const currentFields = this.props.currentFields;
    const pipelines = this.props.pipelines;
    let stageName = '';
    if (currentFields) {
      for (let i in currentFields) {
        if (currentFields[i].id === 'id') this._isMounted && this.setState({id: currentFields[i].text})
        else if (currentFields[i].id === 'name') this._isMounted && this.setState({name: currentFields[i].text})
        else if (currentFields[i].id === 'nickname') this._isMounted && this.setState({nickname: currentFields[i].text})
        else if (currentFields[i].id === 'description') this._isMounted && this.setState({note: currentFields[i].text})
        else if (currentFields[i].id === 'domain') this._isMounted && this.setState({domain: currentFields[i].text})
        else if (currentFields[i].id === 'emailAddress') this._isMounted && this.setState({emailAddress: currentFields[i].text})
        else if (currentFields[i].id === 'phoneNumber') this._isMounted && this.setState({phoneNumber: currentFields[i].text})
        else if (currentFields[i].id === 'linkedin') this._isMounted && this.setState({linkedin: currentFields[i].text})
        else if (currentFields[i].id === 'url') this._isMounted && this.setState({url: currentFields[i].text})
        else if (currentFields[i].id === 'country') this._isMounted && this.setState({country: currentFields[i].text})
        else if (currentFields[i].id === 'status') this._isMounted && this.setState({status: currentFields[i].text})
        else if (currentFields[i].id === 'stage') {
          for (let p in pipelines) {
            for (let s in pipelines[p].stages) {
              if (currentFields[i].text === pipelines[p].stages[s].id) stageName = pipelines[p].stages[s].name;
            }
          }
          this._isMounted && this.setState({stage: currentFields[i].text, stages: [{key: currentFields[i].text, text: stageName}]})
        }
        else if (currentFields[i].id === 'fileId') this._isMounted && this.setState({fileId: currentFields[i].text})
        else if (currentFields[i].id === 'fromSignatureDate') this._isMounted && this.setState({fromSignatureDate: currentFields[i].text})
        else if (currentFields[i].id === 'toSignatureDate') this._isMounted && this.setState({toSignatureDate: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'fromStartDate') this._isMounted && this.setState({fromStartDate: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'toStartDate') this._isMounted && this.setState({toStartDate: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'fromExpirationDate') this._isMounted && this.setState({fromExpirationDate: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'toExpirationDate') this._isMounted && this.setState({toExpirationDate: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'birthDate') this._isMounted && this.setState({birthDate: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'jobTitle') this._isMounted && this.setState({jobTitle: currentFields[i].text})
        else if (currentFields[i].id === 'department') this._isMounted && this.setState({department: currentFields[i].text})
        else if (currentFields[i].id === 'contactType') this._isMounted && this.setState({contactType: currentFields[i].text})
        else if (currentFields[i].id === 'subject') this._isMounted && this.setState({subject: currentFields[i].text})
        else if (currentFields[i].id === 'content') this._isMounted && this.setState({content: currentFields[i].text})
        else if (currentFields[i].id === 'from') this._isMounted && this.setState({from: currentFields[i].text})
        else if (currentFields[i].id === 'to') this._isMounted && this.setState({to: currentFields[i].text})
        else if (currentFields[i].id === 'fromReceivedDateTime') this._isMounted && this.setState({fromReceivedDateTime: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'toReceivedDateTime') this._isMounted && this.setState({toReceivedDateTime: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'fromSavedDateTime') this._isMounted && this.setState({fromSavedDateTime: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'toSavedDateTime') this._isMounted && this.setState({toSavedDateTime: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'from') this._isMounted && this.setState({fromCreatedDateTime: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'fromCreatedDateTime') this._isMounted && this.setState({fromCreatedDateTime: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'toCreatedDateTime') this._isMounted && this.setState({toCreatedDateTime: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'fromModifiedDateTime') this._isMounted && this.setState({fromModifiedDateTime: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'toModifiedDateTime') this._isMounted && this.setState({toModifiedDateTime: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'fromNoteDate') this._isMounted && this.setState({fromNoteDate: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'toNoteDate') this._isMounted && this.setState({toNoteDate: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'noteType') this._isMounted && this.setState({noteType: currentFields[i].text})
        else if (currentFields[i].id === 'conversationType') this._isMounted && this.setState({conversationType: currentFields[i].text})
        else if (currentFields[i].id === 'fromDueDate') this._isMounted && this.setState({fromDueDate: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'toDueDate') this._isMounted && this.setState({toDueDate: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'fromReminders') this._isMounted && this.setState({fromReminders: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'toReminders') this._isMounted && this.setState({toReminders: new Date(parseInt(currentFields[i].text || ''))})
        else if (currentFields[i].id === 'taskImportance') this._isMounted && this.setState({taskImportance: currentFields[i].text})
        else if (currentFields[i].id === 'createdByUserId') this._isMounted && this.setState({createdByUserId: currentFields[i].text})
        else if (currentFields[i].id === 'modifiedByUserId') this._isMounted && this.setState({modifiedByUserId: currentFields[i].text})
      }
    }
  }

  private getFields() {

    const {id, name, nickname, note, domain, emailAddress, phoneNumber, linkedin, url, country, status, stage, fileId, fromExpirationDate, fromSignatureDate, fromStartDate, toExpirationDate, toSignatureDate, toStartDate, birthDate, jobTitle, department, contactType, content, subject, from, to, fromReceivedDateTime, fromSavedDateTime, toReceivedDateTime, toSavedDateTime, fromCreatedDateTime, fromModifiedDateTime, fromNoteDate, toCreatedDateTime, toModifiedDateTime, toNoteDate, createdByUserId, modifiedByUserId, noteType, conversationType, toDueDate, fromDueDate, taskImportance, toReminders, fromReminders, description} = this.state;
    let fields:any[] = [];

    if (id.length > 0) {
      fields.push({key: 'id', id: 'id', text: id, type: 'Field'})
    }
    if (name.length > 0 && (this.props.type === 'PROJECT' || this.props.type === 'CONTRACT' || this.props.type === 'CONTACT' || this.props.type === 'COMPANY')) {
      fields.push({key: 'name', id: 'name', text: name, type: 'Field'})
    }
    if (nickname.length > 0 && (this.props.type === 'PROJECT' || this.props.type === 'CONTRACT' || this.props.type === 'CONTACT' || this.props.type === 'COMPANY')) {
      fields.push({key: 'nickname', id: 'nickname', text: nickname, type: 'Field'})
    }
    if (note.length > 0 && (this.props.type === 'PROJECT' || this.props.type === 'CONTRACT' || this.props.type === 'CONTACT' || this.props.type === 'COMPANY')) {
      fields.push({key: 'description', id: 'description', text: note, type: 'Field'})
    }
    if (domain.length > 0 && this.props.type === 'COMPANY') {
      fields.push({key: 'domain', id: 'domain', text: domain, type: 'Field'})
    }
    if (emailAddress.length > 0 && (this.props.type === 'CONTACT' || this.props.type === 'COMPANY')) {
      fields.push({key: 'emailAddress', id: 'emailAddress', text: emailAddress, type: 'Field'})
    }
    if (phoneNumber.length > 0 && (this.props.type === 'CONTACT' || this.props.type === 'COMPANY')) {
      fields.push({key: 'phoneNumber', id: 'phoneNumber', text: phoneNumber, type: 'Field'})
    }
    if (linkedin.length > 0 && (this.props.type === 'CONTACT' || this.props.type === 'COMPANY')) {
      fields.push({key: 'linkedin', id: 'linkedin', text: linkedin, type: 'Field'})
    }
    if (url.length > 0 && (this.props.type === 'CONTACT' || this.props.type === 'COMPANY')) {
      fields.push({key: 'url', id: 'url', text: url, type: 'Field'})
    }
    if (country.length > 0 && this.props.type === 'COMPANY') {
      fields.push({key: 'country', id: 'country', text: country, type: 'Field'})
    }
    if (status.length > 0 && status !== 'ANY' && (this.props.type === 'PROJECT' || this.props.type === 'TASK')) {
      fields.push({key: 'status', id: 'status', text: status, type: 'Field'})
    }
    if (stage.length > 0 && (this.props.type === 'PROJECT' || this.props.type === 'CONTRACT')) {
      fields.push({key: 'stage', id: 'stage', text: stage, type: 'Field'})
    }
    if (fileId.length > 0 && this.props.type === 'CONTRACT') {
      fields.push({key: 'fileId', id: 'fileId', text: fileId, type: 'Field'})
    }
    if (fromExpirationDate.toString().length > 0 && this.props.type === 'CONTRACT') {
      fields.push({key: 'fromExpirationDate', id: 'fromExpirationDate', text: new Date(fromExpirationDate).getTime(), type: 'Field'})
    }
    if (toExpirationDate.toString().length > 0 && this.props.type === 'CONTRACT') {
      fields.push({key: 'toExpirationDate', id: 'toExpirationDate', text: new Date(toExpirationDate).getTime(), type: 'Field'})
    }
    if (fromSignatureDate.toString().length > 0 && this.props.type === 'CONTRACT') {
      fields.push({key: 'fromSignatureDate', id: 'fromSignatureDate', text: new Date(fromSignatureDate).getTime(), type: 'Field'})
    }
    if (toSignatureDate.toString().length > 0 && this.props.type === 'CONTRACT') {
      fields.push({key: 'toSignatureDate', id: 'toSignatureDate', text: new Date(toSignatureDate).getTime(), type: 'Field'})
    }
    if (fromStartDate.toString().length > 0 && this.props.type === 'CONTRACT') {
      fields.push({key: 'fromStartDate', id: 'fromStartDate', text: new Date(fromStartDate).getTime(), type: 'Field'})
    }
    if (toStartDate.toString().length > 0 && this.props.type === 'CONTRACT') {
      fields.push({key: 'toStartDate', id: 'toStartDate', text: new Date(toStartDate).getTime(), type: 'Field'})
    }
    if (birthDate.toString().length > 0 && this.props.type === 'CONTACT') {
      fields.push({key: 'birthDate', id: 'birthDate', text: new Date(birthDate).getTime(), type: 'Field'})
    }
    if (jobTitle.length > 0 && this.props.type === 'CONTACT') {
      fields.push({key: 'jobTitle', id: 'jobTitle', text: jobTitle, type: 'Field'})
    }
    if (department.length > 0 && this.props.type === 'CONTACT') {
      fields.push({key: 'department', id: 'department', text: department, type: 'Field'})
    }
    if (contactType.length > 0 && this.props.type === 'CONTACT') {
      fields.push({key: 'contactType', id: 'contactType', text: contactType, type: 'Field'})
    }
    if (subject.length > 0 && (this.props.type === 'EMAIL' || this.props.type === 'NOTE' || this.props.type === 'CONVERSATION' || this.props.type === 'TASK')) {
      fields.push({key: 'subject', id: 'subject', text: subject, type: 'Field'})
    }
    if (content.length > 0 && (this.props.type === 'EMAIL' || this.props.type === 'NOTE' || this.props.type === 'CONVERSATION' || this.props.type === 'TASK')) {
      fields.push({key: 'content', id: 'content', text: content, type: 'Field'})
    }
    if (from.length > 0 && this.props.type === 'EMAIL') {
      fields.push({key: 'from', id: 'from', text: from, type: 'Field'})
    }
    if (to.length > 0 && this.props.type === 'EMAIL') {
      fields.push({key: 'to', id: 'to', text: to, type: 'Field'})
    }
    if (fromReceivedDateTime.toString().length > 0 && this.props.type === 'EMAIL') {
      fields.push({key: 'fromReceivedDateTime', id: 'fromReceivedDateTime', text: new Date(fromReceivedDateTime).getTime(), type: 'Field'})
    }
    if (toReceivedDateTime.toString().length > 0 && this.props.type === 'EMAIL') {
      fields.push({key: 'toReceivedDateTime', id: 'toReceivedDateTime', text: new Date(toReceivedDateTime).getTime(), type: 'Field'})
    }
    if (fromSavedDateTime.toString().length > 0 && this.props.type === 'EMAIL') {
      fields.push({key: 'fromSavedDateTime', id: 'fromSavedDateTime', text: new Date(fromSavedDateTime).getTime(), type: 'Field'})
    }
    if (toSavedDateTime.toString().length > 0 && this.props.type === 'EMAIL') {
      fields.push({key: 'toSavedDateTime', id: 'toSavedDateTime', text: new Date(toSavedDateTime).getTime(), type: 'Field'})
    }
    if (fromNoteDate.toString().length > 0 && this.props.type === 'NOTE') {
      fields.push({key: 'fromNoteDate', id: 'fromNoteDate', text: new Date(fromNoteDate).getTime(), type: 'Field'})
    }
    if (toNoteDate.toString().length > 0 && this.props.type === 'NOTE') {
      fields.push({key: 'toNoteDate', id: 'toNoteDate', text: new Date(toNoteDate).getTime(), type: 'Field'})
    }
    if (fromCreatedDateTime.toString().length > 0 && (this.props.type === 'NOTE' || this.props.type === 'CONVERSATION' || this.props.type === 'TASK')) {
      fields.push({key: 'fromCreatedDateTime', id: 'fromCreatedDateTime', text: new Date(fromCreatedDateTime).getTime(), type: 'Field'})
    }
    if (toCreatedDateTime.toString().length > 0 && (this.props.type === 'NOTE' || this.props.type === 'CONVERSATION' || this.props.type === 'TASK')) {
      fields.push({key: 'toCreatedDateTime', id: 'toCreatedDateTime', text: new Date(toCreatedDateTime).getTime(), type: 'Field'})
    }
    if (fromModifiedDateTime.toString().length > 0 && (this.props.type === 'NOTE' || this.props.type === 'CONVERSATION' || this.props.type === 'TASK')) {
      fields.push({key: 'fromModifiedDateTime', id: 'fromModifiedDateTime', text: new Date(fromModifiedDateTime).getTime(), type: 'Field'})
    }
    if (toModifiedDateTime.toString().length > 0 && (this.props.type === 'NOTE' || this.props.type === 'CONVERSATION' || this.props.type === 'TASK')) {
      fields.push({key: 'toModifiedDateTime', id: 'toModifiedDateTime', text: new Date(toModifiedDateTime).getTime(), type: 'Field'})
    }
    if (noteType.length > 0 && this.props.type === 'NOTE' && noteType !== 'ANY') {
      fields.push({key: 'noteType', id: 'noteType', text: noteType, type: 'Field'})
    }
    if (conversationType.length > 0 && this.props.type === 'CONVERSATION' && conversationType !== 'ANY') {
      fields.push({key: 'conversationType', id: 'conversationType', text: conversationType, type: 'Field'})
    }
    if (fromDueDate.toString().length > 0 && this.props.type === 'TASK') {
      fields.push({key: 'fromDueDate', id: 'fromDueDate', text: new Date(fromDueDate).getTime(), type: 'Field'})
    }
    if (toDueDate.toString().length > 0 && this.props.type === 'TASK') {
      fields.push({key: 'toDueDate', id: 'toDueDate', text: new Date(toDueDate).getTime(), type: 'Field'})
    }
    if (fromReminders.toString().length > 0 && this.props.type === 'TASK') {
      fields.push({key: 'fromReminders', id: 'fromReminders', text: new Date(fromReminders).getTime(), type: 'Field'})
    }
    if (toReminders.toString().length > 0 && this.props.type === 'TASK') {
      fields.push({key: 'toReminders', id: 'toReminders', text: new Date(toReminders).getTime(), type: 'Field'})
    }
    if (taskImportance.length > 0 && this.props.type === 'TASK') {
      fields.push({key: 'taskImportance', id: 'taskImportance', text: taskImportance, type: 'Field'})
    }
    if (createdByUserId.length > 0 && this.props.type === 'TASK') {
      fields.push({key: 'createdByUserId', id: 'createdByUserId', text: createdByUserId, type: 'Field'})
    }
    if (modifiedByUserId.length > 0 && this.props.type === 'TASK') {
      fields.push({key: 'modifiedByUserId', id: 'modifiedByUserId', text: modifiedByUserId, type: 'Field'})
    }
    if (description.length > 0 && this.props.type === 'TASK') {
      fields.push({key: 'description', id: 'description', text: description, type: 'Field'})
    }
    return fields;
  }

  private updatePipeline(data) {
    if (data) {
      let pipeline = this.props.pipelines.find((p)=>{return p.id === data.id })
      this._isMounted && this.setState({  
        pipeline: data.key,
        stages: pipeline.stages.map(s => {return {...s, text: s.name, key: s.id}}).sort((a,b) => { return (a.position >= b.position ? 1 : -1)}),
        stage: ''
      })
    }
  }

  public render() {

    const noteTypes:any[] = [
      {key: 'ANY', text: '', icon: ''},
      {key: 'PLAIN', text: 'Standard note', icon: 'QuickNote'},
      {key: 'SMS', text: 'SMS', icon: 'OfficeChat'},
      {key: 'LINKEDIN', text: 'LinkedIn', icon: 'LinkedInLogo'},
      {key: 'WHATSAPP', text: 'WhatsApp', icon: 'Phone'},
      {key: 'CALL', text: 'Call', icon: 'Phone'},
      {key: 'EMAIL', text: 'Email', icon: 'Mail'},
    ]

    const conversationTypes:any[] = [
      {key: 'GENERAL', text: 'General', icon: 'Chat'},
      {key: 'CONVERSATION', text: 'Conversation', icon: 'OfficeChat'},
      {key: 'ANNOUNCEMENT', text: 'Announcement', icon: 'Megaphone'},
      {key: 'APPROVAL', text: 'Approval', icon: 'DocumentApproval'},
      {key: 'REVIEW', text: 'Review', icon: 'CheckboxComposite'},
      {key: 'MEETING', text: 'Meeting', icon: 'JoinOnlineMeeting'},
    ]

    const taskImportances:any = [
      {key: 'URGENT', text: 'To do urgent', color: '#d69ca5'},
      {key: 'LATER', text: 'To do later', color: '#f4bfab'},
      {key: 'FOLLOW_UP', text: 'To follow-up', color: '#f9e2ae'},
      {key: 'MAYBE', text: 'Maybe', color: '#bdd99b'},
    ]

    return(
      <Resizable
        size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
        onResizeStop={(e, direction, ref, d) => {
          var currentWidth = this.state.panelWidth.match(/\d+/)[0];
          this._isMounted && this.setState({
            panelWidth: +currentWidth + +d.width + "px"
          }, () => {
            this._saveResizeState();
          });
        }}
        enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
        minWidth={254}
        maxWidth={400}
        handleStyles= {{
          right: {
            "right": "-6px",
            "width": "6px",
            "zIndex": 1
          }
        }}
        handleClasses={{"right": "resize-handle"}}
      >
        <nav className="tree second" style={{background: '#f2f2f2'}}>
          <div className='mx-2 h-100 d-flex ' style={{background: 'white', flexDirection: 'column'}}>
            {this.props.type !== 'PROJECT' && this.props.type !== 'CONTRACT' && this.props.type !== 'CONTACT' && this.props.type !== 'COMPANY' && this.props.type !== 'NOTE' && this.props.type !== 'EMAIL' && this.props.type !== 'CONVERSATION' && this.props.type !== 'TASK' ? <>
              <p className="ml-1 mb-0" style={{position: 'absolute'}}>{i18n.t('app:filterBy') + ' ' + i18n.t('app:'+this.props.type)}</p>
              <div className='text-right pt-2' style={{minHeight: '46px', height: '46px', borderBottom: '1px solid #f2f2f2'}}>
                <IconButton className='close-button mr-2' iconProps={{iconName: 'Cancel'}} onClick={()=>this.props.callback(false)}/>
              </div>
              <div style={{minHeight: '48px', height: '48px'}}>
                <SearchBox iconProps={{iconName: 'Filter'}} className='searchbox-card mt-2 mx-3 mb-2' onChange={(e,v)=>{this._filterList(v || '')}}/>
              </div>
              <div className='flex-grow-1' style={{overflowY: 'auto', borderTop: '1px solid #f2f2f2'}}>
              {this.props.page !== 'search' && <>
              <p className='mx-1 pt-3' style={{height: '20px', fontWeight: 600}}>Select in bulk:</p>
              <div className='pb-2'>
                <DetailsList
                  selection={this._selection2}
                  className='list-sidebar filter mt-1'
                  checkboxVisibility={CheckboxVisibility.always}
                  compact={true}
                  isHeaderVisible={false}
                  selectionMode={SelectionMode.single}
                  items={this.state.items2}
                  selectionPreservedOnEmptyClick={true}
                  columns={[ {key: 'text', name: 'Text', minWidth: 0, onRender: (item)=>{return  <span style={{fontSize: 14}}>{item.text}</span>} } ]}   
                />
              </div>
              <hr style={{borderColor: '#f2f2f2', marginTop: '8px'}}/>
              <span className='mx-3' style={{height: '20px', fontWeight: 600}}>{i18n.t('app:selectIndividually')}:</span>
              </>}
              <div className='flex-grow-1'>
                { this.state.items && this.state.items.length > 0 ?
                  <DetailsList
                    selection={this._selection}
                    className='list-sidebar filter mt-1'
                    checkboxVisibility={CheckboxVisibility.always}
                    compact={true}
                    isHeaderVisible={false}
                    items={this.state.items}
                    columns={[ {key: 'name', name: 'Name', minWidth: 0, onRender: (item)=>{
                      if (this.props.type === 'projectIds' || this.props.type === 'contractIds' || this.props.type === 'contactIds' || this.props.type === 'companyIds') {
                        return <div title={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} className='d-flex align-items-center'><Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='extra-small' primaryText='' avatar={{ color: "colorful"}}/><span style={{fontSize: 14, textOverflow: 'ellipsis', overflowX: 'hidden'}}>{item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))}</span></div>
                      }
                      if (this.props.type === 'typesRelations') {
                        return  <span title={item.name} style={{fontSize: 14}}>
                          <Icon iconName={item.icon} className="mr-2" />
                          {item.name}
                          </span>
                      }
                      return  <span title={item.name} style={{fontSize: 14}}>{item.name}</span>
                    } } ]}   
                  />
                  : <p className='mt-1 text-center' style={{opacity:.7, fontWeight: 400}}>Empty</p>
                }
              </div>
            </div>
            </>
            : this.props.type === 'PROJECT' ?
            <>
              <p className="ml-1 mb-0" style={{position: 'absolute'}}>{i18n.t('app:filterBy') + ' ' + i18n.t('app:fields')}</p>
              <div className='text-right pt-2' style={{minHeight: '46px', height: '46px', borderBottom: '1px solid #f2f2f2'}}>
                <IconButton className='close-button mr-2' iconProps={{iconName: 'Cancel'}} onClick={()=>this.props.callback(false)}/>
              </div>
              <div className='flex-grow-1 p-3' style={{overflowY: 'auto'}}>
                <Label className='mr-3' style={{fontWeight: 600}}>{i18n.t('app:id')}</Label>
                <div>
                  <Input className='w-100' value={this.state.id} onChange={(e,data) => {this._isMounted && this.setState({id: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:name')}</Label>
                <div>
                  <Input className='w-100' value={this.state.name} onChange={(e,data) => {this._isMounted && this.setState({name: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:nickName')}</Label>
                <div>
                  <Input className='w-100' value={this.state.nickname} onChange={(e,data) => {this._isMounted && this.setState({nickname: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:status')}</Label>
                <div>
                  <Dropdown className='flex-grow-1' styles={{ title: {borderRadius: '4px', paddingRight: '24px'}}} selectedKey={this.state.status} onChange={(e, data:any) => this._isMounted && this.setState({status: data.key})} options={[{key: 'ANY', text: ''}, {key: 'OPEN', text: i18n.t('app:open')}, {key: 'CLOSE', text: i18n.t('app:close')}]}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:pipeline')}</Label>
                <div className='d-flex align-items-center flex-grow-1' style={{position: 'relative'}}>
                  <ComboBox className='flex-grow-1' styles={{ root: {borderRadius: '4px'}, input: {paddingRight: '24px'}}} allowFreeform selectedKey={this.state.pipeline} onChange={(e, data:any) => this.updatePipeline(data)} options={this.props.pipelines}/>
                  {this.state.pipeline && <IconButton style={{position: 'absolute', right: '40px', height: '28px', width: '0px', fontSize: '12px', background: 'transparent'}} styles={{icon: {fontSize: '12px', color: 'rgb(96, 94, 92)'}}} iconProps={{iconName: 'Clear'}} onClick={(e)=>this._isMounted && this.setState({stage: '', stages: [], pipeline: ''})}/>}
                </div>  
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:stage')}</Label>
                <div>
                  <Dropdown styles={{ title: {borderRadius: '4px', paddingRight: '24px'}}} selectedKey={this.state.stage} onChange={(e, data:any) => this._isMounted && this.setState({stage: data.id})} options={this.state.stages} disabled={this.state.pipeline === ''}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:notes')}</Label>
                <div>
                  <Textarea className='w-100' value={this.state.note} onChange={(e,data) => {this._isMounted && this.setState({note: data.value})}}/>
                </div>
            </div>
            </>
            : this.props.type === 'CONTRACT' ?
            <>
              <p className="ml-1 mb-0" style={{position: 'absolute'}}>{i18n.t('app:filterBy') + ' ' + i18n.t('app:fields')}</p>
              <div className='text-right pt-2' style={{minHeight: '46px', height: '46px', borderBottom: '1px solid #f2f2f2'}}>
                <IconButton className='close-button mr-2' iconProps={{iconName: 'Cancel'}} onClick={()=>this.props.callback(false)}/>
              </div>
              <div className='flex-grow-1 p-3' style={{overflowY: 'auto'}}>
                <Label className='mr-3' style={{fontWeight: 600}}>{i18n.t('app:id')}</Label>
                <div>
                  <Input className='w-100' value={this.state.id} onChange={(e,data) => {this._isMounted && this.setState({id: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:name')}</Label>
                <div>
                  <Input className='w-100' value={this.state.name} onChange={(e,data) => {this._isMounted && this.setState({name: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:nickName')}</Label>
                <div>
                  <Input className='w-100' value={this.state.nickname} onChange={(e,data) => {this._isMounted && this.setState({nickname: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fileId')}</Label>
                <div>
                  <Input className='w-100' value={this.state.fileId} onChange={(e,data) => {this._isMounted && this.setState({fileId: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromSignatureDate')}</Label>
                <div>
                 <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromSignatureDate} onSelectDate={(date)=>this.setState({fromSignatureDate: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toSignatureDate')}</Label>
                <div>
                 <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toSignatureDate} onSelectDate={(date)=>this.setState({toSignatureDate: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromStartDate')}</Label>
                <div>
                 <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromStartDate} onSelectDate={(date)=>this.setState({fromStartDate: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toStartDate')}</Label>
                <div>
                 <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toStartDate} onSelectDate={(date)=>this.setState({toStartDate: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromExpirationDate')}</Label>
                <div>
                 <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromExpirationDate} onSelectDate={(date)=>this.setState({fromExpirationDate: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toExpirationDate')}</Label>
                <div>
                 <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toExpirationDate} onSelectDate={(date)=>this.setState({toExpirationDate: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:pipeline')}</Label>
                <div className='d-flex align-items-center flex-grow-1' style={{position: 'relative'}}>
                  <ComboBox className='flex-grow-1' styles={{ root: {borderRadius: '4px'}, input: {paddingRight: '24px'}}} allowFreeform selectedKey={this.state.pipeline} onChange={(e, data:any) => this.updatePipeline(data)} options={this.props.pipelines}/>
                  {this.state.pipeline && <IconButton style={{position: 'absolute', right: '40px', height: '28px', width: '0px', fontSize: '12px', background: 'transparent'}} styles={{icon: {fontSize: '12px', color: 'rgb(96, 94, 92)'}}} iconProps={{iconName: 'Clear'}} onClick={(e)=>this._isMounted && this.setState({stage: '', stages: [], pipeline: ''})}/>}
                </div>  
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:stage')}</Label>
                <div>
                  <Dropdown styles={{ title: {borderRadius: '4px', paddingRight: '24px'}}} selectedKey={this.state.stage} onChange={(e, data:any) => this._isMounted && this.setState({stage: data.id})} options={this.state.stages} disabled={this.state.pipeline === ''}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:notes')}</Label>
                <div>
                  <Textarea className='w-100' value={this.state.note} onChange={(e,data) => {this._isMounted && this.setState({note: data.value})}}/>
                </div>
              </div>
            </>
            : this.props.type === 'CONTACT' ?
            <>
              <p className="ml-1 mb-0" style={{position: 'absolute'}}>{i18n.t('app:filterBy') + ' ' + i18n.t('app:fields')}</p>
              <div className='text-right pt-2' style={{minHeight: '46px', height: '46px', borderBottom: '1px solid #f2f2f2'}}>
                <IconButton className='close-button mr-2' iconProps={{iconName: 'Cancel'}} onClick={()=>this.props.callback(false)}/>
              </div>
              <div className='flex-grow-1 p-3' style={{overflowY: 'auto'}}>
                <Label className='mr-3' style={{fontWeight: 600}}>{i18n.t('app:id')}</Label>
                <div>
                  <Input className='w-100' value={this.state.id} onChange={(e,data) => {this._isMounted && this.setState({id: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:name')}</Label>
                <div>
                  <Input className='w-100' value={this.state.name} onChange={(e,data) => {this._isMounted && this.setState({name: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:nickName')}</Label>
                <div>
                  <Input className='w-100' value={this.state.nickname} onChange={(e,data) => {this._isMounted && this.setState({nickname: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:phoneNumber')}</Label>
                <div>
                  <Input className='w-100' value={this.state.phoneNumber} onChange={(e,data) => {this._isMounted && this.setState({phoneNumber: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:email')}</Label>
                <div>
                  <Input className='w-100' value={this.state.emailAddress} onChange={(e,data) => {this._isMounted && this.setState({emailAddress: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:linkedIn')}</Label>
                <div>
                  <Input className='w-100' value={this.state.linkedin} onChange={(e,data) => {this._isMounted && this.setState({linkedin: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:URL')}</Label>
                <div>
                  <Input className='w-100' value={this.state.url} onChange={(e,data) => {this._isMounted && this.setState({url: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:jobTitle')}</Label>
                <div>
                  <Input className='w-100' value={this.state.jobTitle} onChange={(e,data) => {this._isMounted && this.setState({jobTitle: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:department')}</Label>
                <div>
                  <Input className='w-100' value={this.state.department} onChange={(e,data) => {this._isMounted && this.setState({department: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:birthDate')}</Label>
                <div>
                 <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.birthDate} onSelectDate={(date)=>this.setState({birthDate: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:contactType')}</Label>
                <div>
                  <Dropdown className='flex-grow-1' styles={{ title: {borderRadius: '4px', paddingRight: '24px'}}} selectedKey={this.state.contactType} onChange={(e, data:any) => this._isMounted && this.setState({contactType: data.key})} options={[{key: 'ANY', text: i18n.t('app:any')}, {key: 'INTERNAL', text: i18n.t('app:INTERNAL')}, {key: 'NONE', text: i18n.t('app:NONE')}]}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:notes')}</Label>
                <div>
                  <Textarea className='w-100' value={this.state.note} onChange={(e,data) => {this._isMounted && this.setState({note: data.value})}}/>
                </div>
              </div>
            </>
            : this.props.type === 'COMPANY' ?
            <>
              <p className="ml-1 mb-0" style={{position: 'absolute'}}>{i18n.t('app:filterBy') + ' ' + i18n.t('app:fields')}</p>
              <div className='text-right pt-2' style={{minHeight: '46px', height: '46px', borderBottom: '1px solid #f2f2f2'}}>
                <IconButton className='close-button mr-2' iconProps={{iconName: 'Cancel'}} onClick={()=>this.props.callback(false)}/>
              </div>
              <div className='flex-grow-1 p-3' style={{overflowY: 'auto'}}>
                <Label className='mr-3' style={{fontWeight: 600}}>{i18n.t('app:id')}</Label>
                <div>
                  <Input className='w-100' value={this.state.id} onChange={(e,data) => {this._isMounted && this.setState({id: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:name')}</Label>
                <div>
                  <Input className='w-100' value={this.state.name} onChange={(e,data) => {this._isMounted && this.setState({name: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:domain')}</Label>
                <div>
                  <Input className='w-100' value={this.state.domain} onChange={(e,data) => {this._isMounted && this.setState({domain: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:nickName')}</Label>
                <div>
                  <Input className='w-100' value={this.state.nickname} onChange={(e,data) => {this._isMounted && this.setState({nickname: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:phoneNumber')}</Label>
                <div>
                  <Input className='w-100' value={this.state.phoneNumber} onChange={(e,data) => {this._isMounted && this.setState({phoneNumber: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:email')}</Label>
                <div>
                  <Input className='w-100' value={this.state.emailAddress} onChange={(e,data) => {this._isMounted && this.setState({emailAddress: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:linkedIn')}</Label>
                <div>
                  <Input className='w-100' value={this.state.linkedin} onChange={(e,data) => {this._isMounted && this.setState({linkedin: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:URL')}</Label>
                <div>
                  <Input className='w-100' value={this.state.url} onChange={(e,data) => {this._isMounted && this.setState({url: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:country')}</Label>
                <div>
                  <Input className='w-100' value={this.state.country} onChange={(e,data) => {this._isMounted && this.setState({country: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:notes')}</Label>
                <div>
                  <Textarea className='w-100' value={this.state.note} onChange={(e,data) => {this._isMounted && this.setState({note: data.value})}}/>
                </div>
              </div>
            </>
            : this.props.type === 'EMAIL'?
            <>
              <p className="ml-1 mb-0" style={{position: 'absolute'}}>{i18n.t('app:filterBy') + ' ' + i18n.t('app:fields')}</p>
              <div className='text-right pt-2' style={{minHeight: '46px', height: '46px', borderBottom: '1px solid #f2f2f2'}}>
                <IconButton className='close-button mr-2' iconProps={{iconName: 'Cancel'}} onClick={()=>this.props.callback(false)}/>
              </div>
              <div className='flex-grow-1 p-3' style={{overflowY: 'auto'}}>
                <Label className='mr-3' style={{fontWeight: 600}}>{i18n.t('app:id')}</Label>
                <div>
                  <Input className='w-100' value={this.state.id} onChange={(e,data) => {this._isMounted && this.setState({id: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:subject')}</Label>
                <div>
                  <Input className='w-100' value={this.state.subject} onChange={(e,data) => {this._isMounted && this.setState({subject: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:from')}</Label>
                <div>
                  <Input className='w-100' value={this.state.from} onChange={(e,data) => {this._isMounted && this.setState({from: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:to')}</Label>
                <div>
                  <Input className='w-100' value={this.state.to} onChange={(e,data) => {this._isMounted && this.setState({to: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromSavedDateTime')}</Label>
                <div>
                 <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromSavedDateTime} onSelectDate={(date)=>this.setState({fromSavedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toSavedDateTime')}</Label>
                <div>
                 <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toSavedDateTime} onSelectDate={(date)=>this.setState({toSavedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromReceivedDateTime')}</Label>
                <div>
                 <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromReceivedDateTime} onSelectDate={(date)=>this.setState({fromReceivedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toReceivedDateTime')}</Label>
                <div>
                 <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toReceivedDateTime} onSelectDate={(date)=>this.setState({toReceivedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:content')}</Label>
                <div>
                  <Textarea className='w-100' value={this.state.content} onChange={(e,data) => {this._isMounted && this.setState({content: data.value})}}/>
                </div>
              </div>
            </>
            : this.props.type === 'NOTE'?
            <>
              <p className="ml-1 mb-0" style={{position: 'absolute'}}>{i18n.t('app:filterBy') + ' ' + i18n.t('app:fields')}</p>
              <div className='text-right pt-2' style={{minHeight: '46px', height: '46px', borderBottom: '1px solid #f2f2f2'}}>
                <IconButton className='close-button mr-2' iconProps={{iconName: 'Cancel'}} onClick={()=>this.props.callback(false)}/>
              </div>
              <div className='flex-grow-1 p-3' style={{overflowY: 'auto'}}>
                <Label className='mr-3' style={{fontWeight: 600}}>{i18n.t('app:id')}</Label>
                <div>
                  <Input className='w-100' value={this.state.id} onChange={(e,data) => {this._isMounted && this.setState({id: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:subject')}</Label>
                <div>
                  <Input className='w-100' value={this.state.subject} onChange={(e,data) => {this._isMounted && this.setState({subject: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:type')}</Label>
                <div>
                  <Dropdown className='flex-grow-1' styles={{ title: {borderRadius: '4px', paddingRight: '24px'}}} selectedKey={this.state.noteType} onChange={(e, data:any) => this._isMounted && this.setState({noteType: data.key})} options={noteTypes}
                    onRenderOption={(item:any)=>{return <div className='d-flex align-items-center' style={{height:'32px'}}><Icon className='mr-2' iconName={item.icon}/><span>{item.text}</span></div>}}
                    onRenderTitle={(item:any)=>{return <div className='d-flex align-items-center' style={{height:'32px'}}><Icon className='mr-2' iconName={item[0].icon}/><span>{item[0].text}</span></div>}}
                  />
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromNoteDate')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromNoteDate} onSelectDate={(date)=>this.setState({fromNoteDate: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toNoteDate')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toNoteDate} onSelectDate={(date)=>this.setState({toNoteDate: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromCreatedDateTime')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromCreatedDateTime} onSelectDate={(date)=>this.setState({fromCreatedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toCreatedDateTime')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toCreatedDateTime} onSelectDate={(date)=>this.setState({toCreatedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromModifiedDateTime')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromModifiedDateTime} onSelectDate={(date)=>this.setState({fromModifiedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toModifiedDateTime')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toModifiedDateTime} onSelectDate={(date)=>this.setState({toModifiedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:content')}</Label>
                <div>
                  <Textarea className='w-100' value={this.state.content} onChange={(e,data) => {this._isMounted && this.setState({content: data.value})}}/>
                </div>
              </div>
            </>
            : this.props.type === 'CONVERSATION'?
            <>
              <p className="ml-1 mb-0" style={{position: 'absolute'}}>{i18n.t('app:filterBy') + ' ' + i18n.t('app:fields')}</p>
              <div className='text-right pt-2' style={{minHeight: '46px', height: '46px', borderBottom: '1px solid #f2f2f2'}}>
                <IconButton className='close-button mr-2' iconProps={{iconName: 'Cancel'}} onClick={()=>this.props.callback(false)}/>
              </div>
              <div className='flex-grow-1 p-3' style={{overflowY: 'auto'}}>
                <Label className='mr-3' style={{fontWeight: 600}}>{i18n.t('app:content')}</Label>
                <div>
                  <Textarea className='w-100' value={this.state.content} onChange={(e,data) => {this._isMounted && this.setState({content: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:id')}</Label>
                <div>
                  <Input className='w-100' value={this.state.id} onChange={(e,data) => {this._isMounted && this.setState({id: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:subject')}</Label>
                <div>
                  <Input className='w-100' value={this.state.subject} onChange={(e,data) => {this._isMounted && this.setState({subject: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:type')}</Label>
                <div>
                  <Dropdown className='flex-grow-1' styles={{ title: {borderRadius: '4px', paddingRight: '24px'}}} selectedKey={this.state.conversationType} onChange={(e, data:any) => this._isMounted && this.setState({conversationType: data.key})} options={conversationTypes}
                    onRenderOption={(item:any)=>{return <div className='d-flex align-items-center' style={{height:'32px'}}><Icon className='mr-2' iconName={item.icon}/><span>{item.text}</span></div>}}
                    onRenderTitle={(item:any)=>{return <div className='d-flex align-items-center' style={{height:'32px'}}><Icon className='mr-2' iconName={item[0].icon}/><span>{item[0].text}</span></div>}}
                  />
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromCreatedDateTime')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromCreatedDateTime} onSelectDate={(date)=>this.setState({fromCreatedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toCreatedDateTime')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toCreatedDateTime} onSelectDate={(date)=>this.setState({toCreatedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromModifiedDateTime')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromModifiedDateTime} onSelectDate={(date)=>this.setState({fromModifiedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toModifiedDateTime')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toModifiedDateTime} onSelectDate={(date)=>this.setState({toModifiedDateTime: date})}/>
                </div>
              </div>
            </>
            : this.props.type === 'TASK'?
            <>
              <p className="ml-1 mb-0" style={{position: 'absolute'}}>{i18n.t('app:filterBy') + ' ' + i18n.t('app:fields')}</p>
              <div className='text-right pt-2' style={{minHeight: '46px', height: '46px', borderBottom: '1px solid #f2f2f2'}}>
                <IconButton className='close-button mr-2' iconProps={{iconName: 'Cancel'}} onClick={()=>this.props.callback(false)}/>
              </div>
              <div className='flex-grow-1 p-3' style={{overflowY: 'auto'}}>
                <Label className='mr-3' style={{fontWeight: 600}}>{i18n.t('app:content')}</Label>
                <div>
                  <Textarea className='w-100' value={this.state.content} onChange={(e,data) => {this._isMounted && this.setState({content: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:id')}</Label>
                <div>
                  <Input className='w-100' value={this.state.id} onChange={(e,data) => {this._isMounted && this.setState({id: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:subject')}</Label>
                <div>
                  <Input className='w-100' value={this.state.subject} onChange={(e,data) => {this._isMounted && this.setState({subject: data.value})}}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromCreatedDateTime')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromCreatedDateTime} onSelectDate={(date)=>this.setState({fromCreatedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toCreatedDateTime')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toCreatedDateTime} onSelectDate={(date)=>this.setState({toCreatedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromModifiedDateTime')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromModifiedDateTime} onSelectDate={(date)=>this.setState({fromModifiedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toModifiedDateTime')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toModifiedDateTime} onSelectDate={(date)=>this.setState({toModifiedDateTime: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromDueDate')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromDueDate} onSelectDate={(date)=>this.setState({fromDueDate: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toDueDate')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toDueDate} onSelectDate={(date)=>this.setState({toDueDate: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:fromReminders')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.fromReminders} onSelectDate={(date)=>this.setState({fromReminders: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:toReminders')}</Label>
                <div>
                  <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={this.state.toReminders} onSelectDate={(date)=>this.setState({toReminders: date})}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:taskImportance')}</Label>
                <div>
                  <Dropdown className='flex-grow-1' styles={{ root: {borderRadius: '4px'}}} selectedKey={this.state.taskImportance} onChange={(e, data:any) => this._isMounted && this.setState({taskImportance: data.key})} options={taskImportances} 
                    onRenderOption={(item:any)=>{return <div className='d-flex align-items-center' style={{height:'32px'}}><Icon className='mr-2' iconName='CircleFill' style={{color: item.color}}/><span>{item.text}</span></div>}}
                    onRenderTitle={(items:any)=>{return <div className='d-flex align-items-center' style={{height:'32px'}}><Icon className='mr-2' iconName='CircleFill' style={{color: items[0].color}}/><span>{items[0].text}</span></div>}}
                  />
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:status')}</Label>
                <div>
                  <Dropdown className='flex-grow-1' styles={{ title: {borderRadius: '4px', paddingRight: '24px'}}} selectedKey={this.state.status} onChange={(e, data:any) => this._isMounted && this.setState({status: data.key})} options={[{key: 'ANY', text: ''}, {key: 'OPEN', text: i18n.t('app:open')}, {key: 'CLOSE', text: i18n.t('app:close')}]}/>
                </div>
                <Label className='mr-3 mt-3' style={{fontWeight: 600}}>{i18n.t('app:description')}</Label>
                <div>
                  <Textarea className='w-100' value={this.state.description} onChange={(e,data) => {this._isMounted && this.setState({description: data.value})}}/>
                </div>
              </div>
            </>
            : null }
            <div className='px-3 py-2 d-flex align-items-center' style={{borderTop: '1px solid rgb(237, 235, 233)'}}>
              <DefaultButton className='ml-auto' text={i18n.t('app:close')} onClick={()=>this.props.callback(false)}/>
              <PrimaryButton className='ml-3' text={i18n.t('app:apply')} onClick={()=> {this.props.save(this.props.type, this.props.page === 'search' ? this.props.type === 'PROJECT' || this.props.type === 'CONTRACT' || this.props.type === 'CONTACT' || this.props.type === 'COMPANY' || this.props.type === 'NOTE' || this.props.type === 'EMAIL' || this.props.type === 'CONVERSATION' || this.props.type === 'TASK' ? this.getFields() :  this._selection.getSelection() : this.selectionToFilters(), (this._selection2.getSelection().length === 1 && this._selection2.getSelection()[0].key) || '')} }/>
            </div>
          </div>
        </nav>
      </Resizable>
    )
  }

}
