import { Icon, IconButton, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import { _getActivityDescription, _getActivityTitle } from '../../_helpers/activityFunctions';
import Moment from 'moment';
import i18n from "i18next";
import { FluentProvider, Link, Persona } from '@fluentui/react-components';

export interface Props {
  allCardsCollapsed: boolean;
  item:any;
}

export interface States {
  collapsed: boolean;
}

export class CreateCard extends React.Component<Props, States> {
  private _isMounted: boolean;
  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      collapsed: this.props.allCardsCollapsed
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate(prevProps:any) {
    if (prevProps.allCardsCollapsed !== this.props.allCardsCollapsed) {
      this._isMounted && this.setState({collapsed: this.props.allCardsCollapsed})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private _getContactLink(users, item) {
    return <Link href={'/contacts/'+item.userId}>{getContactName(users, item.userId)}</Link>
  }

  private getContactNameTooltip(id: string) {
    let user = this.props.users.find((item) => {
      return item.id === id;
    })

    return (
      user ? <TooltipHost
        content= {
          <div className='m-1'>
            <FluentProvider>
              <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
              <Persona className='mb-2' name={(user.name || '') + ' ' + (user.surname || '')} secondaryText={user.email} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
            </FluentProvider>
          </div>
        }
      >
        <Link href={'/contacts/'+id}>{(user.name || '') + ' ' + (user.surname || '')}</Link>
      </TooltipHost> : ''
    )
  }
  

  public render() {

    const {item} = this.props;

    return(
      <>
          <div className='description m-3 ml-5 pl-2 pr-3 pb-3'>
            { item.targets[0].modelType === 'MESSAGE' ?
              <span>
                {this.getContactNameTooltip(item.userId)} {_getActivityDescription(item)} <Link href={item.source.modelType === 'CONVERSATION' ? '/conversations/id/'+item.source.id : '/tasks/id/'+item.source.id}>{item.source.modelType === 'CONVERSATION' ? i18n.t('app:conversation') : i18n.t('app:task')}</Link>.
              </span>
            :
              <span>
                {_getActivityDescription(item)} {this.getContactNameTooltip(item.userId)}.
              </span>
            }
          </div>
      </>
    )
  }
}

function getContactName(users, id: string) {
  let user = users.find((item) => {
    return item.id === id;
  })

  return user ? (user.name || '') + ' ' + (user.surname || '') : ''
}
