import { ActionButton, Callout, ChoiceGroup, Dropdown, Icon, IconButton, Label, Link, Panel, PanelType, PrimaryButton, SearchBox, TextField, Toggle } from '@fluentui/react';
import * as React from 'react';
import { history } from '../_helpers';
import i18n from 'i18next';
import UAParser from 'ua-parser-js';

const apps = [
  {
    key: 'home',
    name: 'Home',
    icon: 'home_icon.png',
    devApp: false,
  },
  {
    key: 'drive',
    name: 'Drive',
    icon: 'drive_icon.png',
    devApp: false,
  },
  {
    key: 'workflow',
    name: 'Workflow',
    icon: 'workflow_icon.png',
    devApp: true,
  },
  {
    key: 'messages',
    name: 'Messages',
    icon: 'messages_icon.png',
    devApp: true,
  },
  {
    key: 'portal',
    name: 'Client portal',
    icon: 'portal_icon.png',
    devApp: true,
  },
  {
    key: 'account',
    name: 'Account',
    icon: 'drive_icon.png',
    devApp: false,
  },
  {
    key: 'admin',
    name: 'Admin',
    icon: 'drive_icon.png',
    devApp: false,
  }
]

export interface Props {

}

export interface States {
  searchMenu: boolean;
  searchInside: boolean;
  searchType: string;
  searchByName: string;
  searchLocation: string;
  advancedSearchName: string;
  advancedSearchContent: string;
  appsPanel: boolean;
  appFilter: string;
  filteredApps: any[];
}

export class Titlebar extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      searchMenu: false,
      searchInside: true,
      searchType: 'PROJECT',
      searchByName: 'searchByName',
      searchLocation: localStorage.searchLocation || 'everywhere',
      advancedSearchName: '',
      advancedSearchContent: '',
      appsPanel: false,
      appFilter: '',
      filteredApps: apps.slice(0)
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    document.addEventListener("keydown", this._handleKeyDown);
  }
  
  public componentDidUpdate(prevProps:any) {

  }

  public componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keydown", this._handleKeyDown, false);
  }

  public render() {
    var parser = new UAParser.UAParser();
    var os = parser.getResult().os;
    
    const domain = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(1).join('.') : 'synergyos.com';
    const devAppsEnabled = domain === 'greendation.org' || domain === 'synergyos.com'

    return(
      <div id="top-titlebar" className="d-flex">
      <div className="d-flex title align-items-center mr-3" style={{minWidth: '25%'}}>
        <IconButton onClick={()=>{this._isMounted && this.setState({appsPanel: true})}} className='header-waffle' iconProps={{iconName:'WaffleOffice365'}} styles={{root: {width: '20px', height: '20px'}, icon: {fontSize: 16}}}/>
        <img src={ process.env.PUBLIC_URL + "/icon400.png" } className="img-fluid mx-3" alt="Synergy Workflow logo" />
        <span>
          <Link onClick={() => history.push("/")}>
            Workflow
          </Link>
        </span>
      </div>
      {!this.props.disabledSearch && <div className='d-flex flex-grow-1 align-items-center mx-auto' style={{position: 'relative', marginLeft: 'auto'}}>
        <Dropdown id='search-dropdown' className={'mx-auto search-dropdown' + (this.state.searchMenu ? ' selected' : '')} onClick={()=>{this.openSearchMenu()}}
          onRenderPlaceholder={()=>{
            return <div className='d-flex align-items-center'>
              <Icon className='mr-2 pl-1' iconName='Search' styles={{root: {fontSize: 16, color: 'rgb(0, 120, 212)'}}}/>
              <span style={{position: 'relative', top: '-1px'}}>{i18n.t('app:search')}</span>
            </div>
          }}
          onRenderCaretDown={()=>{
            return <span style={{position: 'relative', top: '-1px', fontSize: 12, color: 'rgb(96, 94, 92)'}}>{os.name === "Windows"  ? i18n.t('app:ctrl') + ' + F' : 'Cmd + F'}</span>
          }}
          styles={{ 
            root: { maxWidth: this.state.searchMenu ? '500px' : '360px', width: '100%', display: window.innerWidth < 660 ? 'none' : 'flex' },
          }}
          options={[]}
        />
        {this.state.searchMenu === true && window.innerWidth >= 660 && 
          <Callout
            role="dialog"
            gapSpace={0}
            isBeakVisible={false}
            target={`#search-dropdown`}
            onDismiss={()=>{this._isMounted && this.setState({searchMenu: false},()=>{this._focusSelElements()})}}
            setInitialFocus
            calloutWidth={document.getElementById('search-dropdown') ? document.getElementById('search-dropdown')!.offsetWidth : 500}
          >
            <div className='mx-auto search-menu p-3'>
              <Label id="search-menu" className='py-0'>{i18n.t('app:search')}</Label>
              <ChoiceGroup
                className='mt-2'
                onChange={(ev, item: any)=>{
                  this._isMounted && this.setState({searchType: item.key});
                }}
                selectedKey={this.state.searchType}
                options={[
                  { key: 'PROJECT', text: i18n.t('app:projects') },
                  { key: 'CONTRACT', text: i18n.t('app:contracts') },
                  { key: 'CONTACT', text: i18n.t('app:contacts') },
                  { key: 'COMPANY', text: i18n.t('app:companies') }
                ]}
                styles={{flexContainer: {display: 'flex', columnGap: '12px'}}}
              />
              <div className='filter-name mt-3'>
                <Dropdown
                  placeholder={"Select an option"}
                  selectedKey={this.state.searchByName}
                  options={[
                    { key: 'searchByName', text:i18n.t('app:name')},
                    { key: 'searchByID', text: i18n.t('app:id')}
                  ]}
                  dropdownWidth={155}
                  onChange={ (e,option:any)=>{ this._isMounted && this.setState({searchByName: option.key})} }
                  style={{top:0, position:'absolute', zIndex:2, width:'155px'}}
                  
                />
                <form name="advancedSearch" onSubmit={(e)=>{e.preventDefault(); this._goSearch()}} >
                <TextField id='searchName' className='d-inline mt-3' underlined name={i18n.t('app:name')} value={ this.state.advancedSearchName } onChange={ this.handleAdvancedSearchName } autoFocus />
                </form>
              </div>
              {(this.props.fileExplorerType === 'fileExplorer' || this.props.fileExplorerType === 'viewer') && <ChoiceGroup 
                selectedKey={this.state.searchLocation}
                className='mt-3'
                onChange={(ev, item: any)=>{
                  this._isMounted && this.setState({searchLocation: item.key});
                  localStorage.setItem('searchLocation', item.key)
                }}
                options={[
                  { key: 'everywhere', text: i18n.t('app:everywhere') },
                  { key: 'inThisFolderOnly', text: i18n.t('app:inThisFolderOnly') }
                ]}
                label={i18n.t('app:searchIn')}
              />}
              {this.state.searchLocation === 'inThisFolderOnly' && <div className='d-flex align-items-center mt-2' style={{fontSize:14}}>
                <span className='mr-3' >{i18n.t('app:includeSubfolders')}</span>
                <Toggle checked={this.state.searchInside} className="my-auto"
                  onChange={(ev, value: any)=>{this._isMounted && this.setState({searchInside: value})} }
                />
              </div>}
              <div className="d-flex mt-auto w-100">
                <div className="mt-3 text-right d-flex align-items-center w-100">
                  <ActionButton onClick={()=>{this.openSearchMenu()}} disabled={!this.state.advancedSearchName} style={{height: '32px'}} iconProps={{iconName: 'Cancel'}} ariaLabel="Reset search" className="clear-search ml-auto" >
                    <span className='align-items-center' style={{fontWeight:500, padding:'0 4px'}}>{i18n.t('app:resetSearch')}</span>
                  </ActionButton>
                  <PrimaryButton onClick={()=>{this._goSearch()}} style={{height: '32px'}} text={i18n.t('app:search')} className="ml-3"/>
                </div>
              </div>
            </div>
          </Callout>
        }
      </div>}
      <div className='d-flex align-items-center ml-auto pr-2' style={{minWidth: '25%'}}>
        <Link className='d-flex align-items-center ml-auto' href='https://www.synergyos.com'><img src="/img/synergy_logo_white.png" height="14px" alt="Synergy Inc. logo" /></Link> 
      </div>

      <Panel
          isOpen={this.state.appsPanel}
          onDismiss={()=>{this._isMounted && this.setState({appsPanel: false})}}
          closeButtonAriaLabel="Close"
          isLightDismiss
          hasCloseButton={false}
          //styles={{main: {inset:'0px auto 0px 0px'}}}
          styles={{commands: {padding: 0}, main: {marginTop: '0', background: '#faf9f8'}, content: {padding: '16px'}}}
          type={PanelType.customNear}
          customWidth={'340px'}
          onRenderHeader={()=>{
            return (
              <div className='d-flex align-items-center' style={{height: '40px'}}>
                <IconButton onClick={()=>{this._isMounted && this.setState({appsPanel: false})}} className='panel-waffle' iconProps={{iconName:'WaffleOffice365'}} styles={{icon: {fontSize: 16}}}/>
                {localStorage.getItem('companyLogo2') && localStorage.getItem('companyLogo2') !== 'null' ? <img className='ml-3' alt="SynergyOS" src={'data:image/png;base64,'+localStorage.getItem('companyLogo2') } style={{height: '40px'}}/>
                : <img className='ml-3' alt="SynergyOS" src={process.env.PUBLIC_URL + '/img/synergy_logo_colour.png'} style={{height: '16px'}}/> }
              </div>
            )
          }}
        >
          <div className='d-flex' style={{flexDirection: 'column'}}>
          <SearchBox className='mb-3' placeholder={i18n.t('app:search')} value={this.state.appFilter} onChange={(e,v)=>{this.filterApps(v)}} iconProps={{iconName: "Filter"}} styles={{root: {background: 'transparent', borderColor: '#bfbfbf'}}} />
            <span className='mb-3' style={{fontWeight: 600, fontSize: 18}}>Apps</span>

            {this.state.filteredApps && this.state.filteredApps.map((app)=>{
              return(
                app.devApp === false ? 
                <Link key={app.key} href={'https://'+app.key+'.'+domain} className='app-selection d-flex align-items-center'>
                  <div className='app-icon d-flex'>
                      <img className='m-auto' alt={app.key} src={process.env.PUBLIC_URL + "/img/"+app.key+"_icon.png"}/>
                  </div>
                  <span className='text-center'>{app.name}</span>
                </Link> 
                :
                <Link key={app.key} href={devAppsEnabled ? 'https://'+app.key+'.'+domain : '#'} className={'app-selection d-flex align-items-center' + (devAppsEnabled ? '' : ' disabled')}>
                <div className='app-icon d-flex'>
                    <img className='m-auto' alt={app.key} src={process.env.PUBLIC_URL + "/img/"+app.key+"_icon.png"}/>
                </div>
                <span className='text-center'>{app.name}</span>
              </Link> 
              )
            })}
          </div>
        </Panel>

    </div>
    )
  }

  private _handleKeyDown = (event) => {
    var parser = new UAParser.UAParser();
    var os = parser.getResult().os;
    if ((os.name === "Windows" && (event.ctrlKey || event.altKey) && event.keyCode === 70)
      || (os.name === "Mac OS" && (event.metaKey || event.altKey)  && event.keyCode === 70)) {
      event.preventDefault();
      this.openSearchMenu();
    }
  }

  private handleAdvancedSearchName = (ev: any, newValue?: string) => {
    this._isMounted && this.setState({
      advancedSearchName: newValue || ""
    });
  }

  private handleAdvancedSearchContent = (ev: any, newValue?: string) => {
    this._isMounted && this.setState({
      advancedSearchContent: newValue || ""
    });
  }


  private openSearchMenu() {
    this._isMounted && this.setState({searchMenu: true}, ()=>{
      this._isMounted && this.setState({
        advancedSearchName: '',
        advancedSearchContent: '',
        searchType: 'PROJECT',
        searchInside: true,
        searchByName: 'searchByName',
        searchLocation: (this.props.fileExplorerType === 'fileExplorer' || this.props.fileExplorerType === 'viewer') ? (localStorage.searchLocation || 'everywhere') : 'everywhere'
      },()=>{
        setTimeout(()=>document.getElementById('searchName') && document.getElementById('searchName')!.focus(),100)
      })
    })
  }

  private _goSearch() {
    var searchQuery: string = "?entityType="+this.state.searchType
    searchQuery += this.state.searchByName === 'searchByName' ? "&name=" + this.state.advancedSearchName : "&id=" + this.state.advancedSearchName
    history.push("/search" + searchQuery)
    this._isMounted && this.setState({searchMenu: false})
  }

  private _focusSelElements() {
    var elements: any = document.getElementsByClassName('ms-DetailsRow is-selected')
    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        let el = elements[i]
        el.focus()
      }
    }
  }

  private filterApps(value):any {
    if (value !== '') {
      let filteredApps = apps.filter((app)=>{
        return app.name.toLowerCase().includes(value.toLowerCase())
      })
      this._isMounted && this.setState({appFilter: value || '', filteredApps: filteredApps})
    } else {
      this._isMounted && this.setState({appFilter: value || '', filteredApps: apps})
    }
  }

}
