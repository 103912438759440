import { userService } from "../_services/user.service";

const permissionLevels = {
  'ADMIN': 0,
  'EDITOR': 1,
  'RESTRICTED_EDITOR': 2,
  'VIEWER': 3,
  'PREVIEWER': 4
}

export async function _getMainPermissions(userData, id) {
    return userService.getPermissions(id).then((response)=>{
      const permissionsUsers = response.data.userPermissions;
      const permissionsGroups = response.data.groupPermissions;

      let highestPermission = 'PREVIEWER';

      if (userData && userData.user.role === 'ADMIN') {
        highestPermission = 'ADMIN'
      } else {
        if (permissionsUsers && permissionsUsers.length > 0) {
          for (let i in permissionsUsers) {
            if (permissionsUsers[i].userId === userData.user.id) {
              if (permissionLevels[permissionsUsers[i].permissionRole] < permissionLevels[highestPermission]) highestPermission = permissionsUsers[i].permissionRole
            }
          }
        }
        if (permissionsGroups && permissionsGroups.length > 0) {
          for (let i in permissionsGroups) {
            if (userData && userData.groups.includes(permissionsGroups[i].groupId)){
              if (permissionLevels[permissionsGroups[i].permissionRole] < permissionLevels[highestPermission]) highestPermission = permissionsGroups[i].permissionRole
            }
          }
        }
      }
      return permissionLevels[highestPermission]
    }).catch((error)=>{
      throw error
    })
}

export async function _getSubPermissions(userData, item, itemId) {
  if (item.contactIds.includes(userData.user.id)) {
    return 1;
  }
  else if (item.visibility === 'PUBLIC' || item.visibility === 'PRIVATE') {
    return 1;
  } else if (item.visibility === 'INHERIT' && itemId) {
    if (item.companyIds.includes(itemId) ||
        item.projectIds.includes(itemId) ||
        item.contactIds.includes(itemId) ||
        item.contractIds.includes(itemId)) {
      return _getMainPermissions(userData, itemId)
    }
  } else {
    return 10
  }
}