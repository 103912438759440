import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import { FluentProvider, Input, Label, Textarea, webLightTheme } from '@fluentui/react-components';
import * as React from 'react';
import i18n from '../../i18n';
import { userService } from '../../_services';

export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  customFields: any[];
  chosenFields:any;
  chosenFieldsKeys: any;
  items: any[];
  propertiesOrder: any[];
  customProperties: any[];
  canSubmit: boolean;
  error:any;
  stages: any;
  name: string;
  description: string;
  changed: boolean;
}

export class FormPipelineNew extends React.Component<Props, States> {
  private _isMounted: boolean;
  private _currentKey: number;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._currentKey = 1;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      customFields: [{name:"", value: ""}],
      chosenFields: [],
      chosenFieldsKeys: [],
      items: [],
      propertiesOrder: [],
      customProperties: [],
      canSubmit: true,
      error: "",
      stages: [{key: 1, text: ''}],
      name: '',
      description: '',
      changed: false,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      this.props.callback({showNewPipelineForm: false})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private _handleKeyDown(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 65 && event.keyCode <= 90)){
      this._isMounted && this.setState({changed:true})
    }
  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      this._isMounted && this.setState({hidden:true})
    }
  }

  private _updateName(data:any) {
    this._isMounted && this.setState({name: data.value, changed: true})
  }

  private _updateDecription(data:any) {
    this._isMounted && this.setState({description: data.value, changed: true})
  }

  private _submit(e) {
    if (e) e.preventDefault();
    const {loading} = this.state;
    if (loading) return;
    this._isMounted && this.setState({error: ''})
    if (this.state.name.length === 0) {
      this._isMounted && this.setState({error: 'A pipeline must have a name'})
    } else {
      this._isMounted && this.setState({loading: true})
      let pipeline = {
        name: this.state.name,
        description: this.state.description
      }
      userService.createPipeline(pipeline).then((response)=>{
        this._isMounted && this.setState({hidden: true})
        this.props.callback({showEditPipelineForm: true, pipelineItem: {pipelineName: response.data.name, pipelineId: response.data.id, pipeline: response.data}})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        console.log(error)
        this._isMounted && this.setState({loading: false, error: 'Error creating pipeline. Please try again.'})
      })
    }


  }

  public render() {

    const { hidden, loading, error } = this.state;

    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Pipeline' src={process.env.PUBLIC_URL + 'icons/pipelines.svg'} />{i18n.t('app:createPipeline')}</div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: 'overlay'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog small-dialog",
            onDismiss: ()=> {this.close()}
          }}
        >
          <div className='' style={{borderBottom: '1px solid #bfbfbf', width: 'calc(100% - 48px)', position: 'sticky', left: '24px', top: 0, zIndex: 1}}></div>
          {error && error.length > 0 && <div className='mx-4'><MessageBar style={{position: 'sticky', top: 0}} messageBarType={MessageBarType.error}>{error}</MessageBar></div>}
          <FluentProvider theme={webLightTheme} className='mt-4 mx-4'>
            <form id='form' onSubmit={this._submit.bind(this)}>
              <input className='d-none' type='submit'></input>
              <div className='mt-3 d-flex'>
                <div className='flex-grow-1'>
                  <div className='d-flex align-items-center mt-2'>
                    <Icon iconName='TextField' style={{marginTop: '3px', marginRight: '6px'}}/>
                    <Label className='form-label' style={{ marginTop: '2px'}} required>{i18n.t('app:name')}</Label>
                    <Input className='flex-grow-1' value={this.state.name} onChange={(e,v:any)=>this._updateName(v)} required autoFocus/>
                  </div>
                </div>
              </div>
              <div className='my-4 d-flex'>
                <div className='flex-grow-1'>
                  <div className='d-flex mt-2'>
                    <Icon iconName='TextField' style={{marginTop: '3px', marginRight: '6px'}}/>
                    <span className='form-label' style={{ marginTop: '2px'}}>{i18n.t('app:description')}</span>
                    <Textarea className='flex-grow-1' value={this.state.description} onChange={(e,v:any)=>this._updateDecription(v)}/>
                  </div>
                </div>
              </div>
            </form>
          </FluentProvider>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this.close()}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton form='form' type='submit' text={i18n.t('app:save')} disabled={loading || !this.state.name} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

}
