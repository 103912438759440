import { Icon } from '@fluentui/react';
import { Link } from '@fluentui/react-components';
import * as React from 'react';
import i18n from '../i18n';


export interface Props {
  fieldType: string;
  name: string;
}

export interface States {
  hidden: boolean;
}

export class CustomFieldListed extends React.Component<Props, States> {

  props: any;

  public render() {

    const { item, field } = this.props;

    let options:any = [];
    let values:any = [];
    if (field && field.possibleValues) {
      for (let key in item.values) {
        values.push(item.values[key]) 
      }
      for (let key in field.possibleValues) {
        if (values.includes(key)) options.push(field.possibleValues[key]) 
      }
      options = options.sort((a,b) => { return (a.localeCompare(b))})
    }

    return (
      this.props.field && <div className='d-flex mt-2 mb-2'>
        <Icon 
          iconName={field.fieldType === 'STRING' ? 'TextField' 
          : field.fieldType === 'NUMBER' ? 'NumberField' 
          : field.fieldType === 'BOOLEAN' ? 'CheckboxComposite' 
          : field.fieldType === 'DATE' ? 'Calendar' 
          : field.fieldType === 'TAG' ? 'Dropdown'
          : field.fieldType === 'FILE' ? 'Document'
          : 'TextField'} 
          className='mr-3 field-icon'
          style={{marginTop: '10px'}}
        />
        <div>
          <div className="text-secondary mr-2">{ field.name + ': ' || "" } </div>
          {
            field.fieldType === 'STRING' ? <div>{ item.values[0] && (item.values[0].startsWith('https://') || item.values[0].startsWith('www.')) ? 
              <Link className='field-value' href={item.values[0]} title={ item.values[0] } target='_blank' rel='noreferrer'>{ item.values[0] }</Link> 
            :
              <div className="field-value">{ item.values[0] || "" }</div> }</div>
          : field.fieldType === 'NUMBER' ? <div>{item.values[0] || ""}</div>
          : field.fieldType === 'FILE' ? <div>{item.values[0] || ""}</div>
          : field.fieldType === 'BOOLEAN' ? <div>{item.values[0] ? i18n.t('app:yes') : i18n.t('app:no')}</div>
          : field.fieldType === 'DATE' ? <div>{new Date(item.values[0] || "").toUTCString()}</div>
          : field.fieldType === 'TAG' ? <div>
            {options.map((value, i)=>{
              return <div key={i}>{value}</div>
            })}
            </div>
          : <div></div>
          }
        </div>
      </div>
    )
  }

}
