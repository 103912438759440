import { userService } from "../_services/user.service";
import * as XLSX from 'xlsx';
import ReactDOM from 'react-dom';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, PrimaryButton, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import i18n from "../i18n";
import { FluentProvider, Input, Persona, webLightTheme } from "@fluentui/react-components";

export function _importProjects() {
  var showDialog = true;
  var file:any;
  var fileExtension = '';
  var sheetFileProjects:any = [];

  var _import = () => {
    var reader = new FileReader();
    reader.onload = function(e:any) {
      var data = e.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'array', raw: true, cellFormula: false });
      var json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
      //showDialog = false;
      userService.getCustomProperties('','PROJECT').then((response) =>{
        let customProperties = response.data.map(item=>{return {...item, id: item.id, name: item.name, position: item.customPropertyTypes.find(x=>{return x.entityType === 'PROJECT'}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === 'PROJECT'}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}});
        customProperties = customProperties.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
        sheetFileProjects = json.map((item:any, index)=>{return {
          id: index, 
          name: item.name, 
          stage: item.stage_id, 
          nickname: item.nickname, 
          description: item.notes, 
          status: 'OPEN',
          customPropertyValues: customProperties.filter(p=>{
            return item[p.name] && (item[p.name].length > 0 || item[p.name] > 0)
          }).map(p=>{
            return {customPropertyId: p.id, values: [item[p.name]]}
          })
        }});
        renderDialog();
      }).catch(error=>{
        console.log(error)
      })
    };
    reader.readAsArrayBuffer(file)
    
  }

  var _showFilePicker = () => {
    var input = document.createElement('input');
    input.type = 'file';
    input.accept= '.xls, .xlsx, .xlsm, .csv,'

    input.onchange = e => {
      let event:any = e as Event;
      event.stopPropagation();
      event.preventDefault();

      file = event.target.files[0]
      fileExtension = file.name.split('.').pop()
      renderDialog()
    }

    input.click();
  }

  var onDragEnter = (event) => {
    if (event.target.className.indexOf("droptarget")!== -1) {
      event.target.style.background = "#C7E0F4";
    }
  }

  var onDragLeave = (event) => {
    if (event.target.className.indexOf("droptarget")!== -1) {
      event.target.style.background = "transparent";
    }
  }

  var onDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  var onFileDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.target.className.indexOf("droptarget")!== -1) {
      event.target.style.background = "transparent";
    }

    if(event.dataTransfer.files.length === 1) {
      file = event.dataTransfer.files[0]
      fileExtension = file.name.split('.').pop()

      renderDialog()
    }
  }

  async function createProject(item) {
    return await new Promise((resolve, reject) => {
      userService.createProject(item)
      .then((response: any) => {
        resolve(response)
      }).catch(error => {
        resolve(error)
      })
    })
  }

  async function _createFromFile() {
    for (let i in sheetFileProjects) {
      let item:any = sheetFileProjects[i];
      await createProject(item).then((result:any)=>{
        if (result.status === 409 ) sheetFileProjects[i].state = 'alreadyExists'
        else if (result.status === 201 ) sheetFileProjects[i].state = 'created'
        else sheetFileProjects[i].state = 'failed'
      })
    }
    showDialog = false; renderDialog();
  }

  function updateValue(id:string, field: string, value: string) {
    for (let i in sheetFileProjects) {
      if (sheetFileProjects[i].id === id) {
        sheetFileProjects[i][field] = value;
        renderDialog();
      }
    }
  }

  var div = document.createElement('div');
  var renderDialog = () => {

    var columns:any = [
      {
        key: 'icon',
        name: '#',
        fieldName: 'icon',
        minWidth: 1,
        maxWidth: 1,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        //onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '28px'}}/>
            :
              <div style={{borderRadius: '24px', height: '28px'}}><Persona className='project-persona' name={item ? item.name : ''} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful", shape: "square" }}/></div>
            )
        }
      },
      {
        key: 'name',
        name: i18n.t('app:name'),
        fieldName: 'name',
        minWidth: 170,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        //isPadded: true,
        onRender: (item: any) => {
          return (
            <Input appearance='underline' value={item.name || ''} onChange={(e,data)=>{updateValue(item.id, 'name', data.value)}}/>
          )
        }
      },
      {
        key: 'stage',
        name: i18n.t('app:stage'),
        fieldName: 'stage',
        minWidth: 170,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        //isPadded: true,
        onRender: (item: any) => {
          return (
            <Input appearance='underline' value={item.stage || ''} onChange={(e,data)=>{updateValue(item.id, 'stage', data.value)}}/>
          )
        }
      },
      {
        key: 'nickname',
        name: i18n.t('app:nickName'),
        fieldName: 'nickname',
        minWidth: 170,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        //isPadded: true,
        onRender: (item: any) => {
          return (
            <Input appearance='underline' value={item.nickname || ''} onChange={(e,data)=>{updateValue(item.id, 'nickname', data.value)}}/>
          )
        }
      },
      {
        key: 'notes',
        name: i18n.t('app:notes'),
        fieldName: 'description',
        minWidth: 170,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        //isPadded: true,
        onRender: (item: any) => {
          return (
            <Input appearance='underline' value={item.description || ''} onChange={(e,data)=>{updateValue(item.id, 'description', data.value)}}/>
          )
        }
      },
    ]
    
    ReactDOM.render(
      sheetFileProjects.length === 0 ? 
      <Dialog
          hidden={!showDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: <div>{i18n.t('app:importProjects')}</div>
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "alert-dialog-default"
          }}
          onDismiss={() => {showDialog = false; renderDialog()}}
        >

        <div className="dialog-content-wrap">
          {!file ? <div className={"file-droptarget droptarget"} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={(e) => onFileDrop(e)}>
            <p>{i18n.t('app:dragDrop')}<br />
            <span className="text-uppercase d-inline-block my-2">- {i18n.t('app:or')} -</span><br />
            <PrimaryButton onClick={ _showFilePicker }>
              {i18n.t('app:selectFile')}
            </PrimaryButton>
            </p>
          </div>
          :
          <div className={"file-droptarget pointer-events-enabled addedFile"}>
            <p><Icon {...getFileTypeIconProps({ extension: fileExtension, size: 24, imageFileType: 'svg' }) } /> { file.name } <br />
            <span onClick={ () => {file = null; renderDialog()}} className="remove">{i18n.t('app:remove')}</span>
            </p>
          </div>}
          <DialogFooter className="mt-4">
            <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('app:cancel')}  />
            <PrimaryButton onClick={() => _import()} text={i18n.t('app:next')} disabled={!file}/>
          </DialogFooter>
        </div>

      </Dialog>
      :
      <Dialog
        hidden={!showDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: <div>{i18n.t('app:importProjects')}</div>
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: { maxWidth: 640 } },
          dragOptions: undefined,
          className: "form-dialog"
        }}
        onDismiss={() => {showDialog = false; renderDialog()}}
      >

        <div className="dialog-content-wrap">
          <FluentProvider theme={webLightTheme}>
            <ShimmeredDetailsList columns={columns} className='mx-4 import-list' items={sheetFileProjects} selectionMode={SelectionMode.none} />
          </FluentProvider>
          <DialogFooter className="mt-4">
            <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('app:cancel')}  />
            <PrimaryButton onClick={() => _createFromFile()} text={i18n.t('app:create')} disabled={!file}/>
          </DialogFooter>
        </div>

      </Dialog>
      , div
    )
  }

  renderDialog();

  }