import { ConstrainMode, DefaultButton, DetailsListLayoutMode, Dialog, DialogFooter, DialogType, Dropdown, Icon, IconButton, IDetailsHeaderProps, IPersonaProps, IRenderFunction, ListPeoplePicker, PrimaryButton, ScrollablePane, SelectionMode, ShimmeredDetailsList, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import i18n from '../../i18n';
import { FluentProvider, Input, Label, Link, Persona, SelectTabEvent, Tab, TabList, webLightTheme } from '@fluentui/react-components';
import { userService } from '../../_services';
import * as DOMPurify from 'dompurify';
import { MentionsInput, Mention } from 'react-mentions'

export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean,
  loading: boolean,
  name: string,
  note: string,
  tag: string,
  tags: any[],
  changed: boolean,
  currentProjects: any[];
  currentContacts: any[];
  currentContracts: any[];
  currentCompanies: any[];
  possibleProjects: any[];
  possibleContacts: any[];
  possibleContracts: any[];
  possibleCompanies: any[];
  showCurrentProjects: boolean;
  showCurrentContacts: boolean;
  showCurrentContracts: boolean;
  showCurrentCompanies: boolean;
  searchProjects: string;
  searchContacts: string;
  searchContracts: string;
  searchCompanies: string;
  loadingEntities: boolean;
  entitiesOpened: boolean;
  visibility: string;
  markdown: any;
  tabSelected: string;
}

export class FormNoteEdit extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      name: this.props.item.subject,
      note: this.props.item.content,
      tag: this.props.item.noteType,
      tags: [
        {key: 'PLAIN', text: 'Standard note', icon: 'QuickNote'},
        {key: 'SMS', text: 'SMS', icon: 'OfficeChat'},
        {key: 'LINKEDIN', text: 'LinkedIn', icon: 'LinkedInLogo'},
        {key: 'WHATSAPP', text: 'WhatsApp', icon: 'Phone'},
        {key: 'CALL', text: 'Call', icon: 'Phone'},
        {key: 'EMAIL', text: 'Email', icon: 'Mail'},
      ],
      changed: false,
      currentProjects: [],
      currentContacts: [],
      currentContracts: [],
      currentCompanies: [],
      possibleProjects: [],
      possibleContacts: [],
      possibleContracts: [],
      possibleCompanies: [],
      showCurrentProjects: true,
      showCurrentContacts: true,
      showCurrentContracts: true,
      showCurrentCompanies: true,
      searchProjects: '',
      searchContacts: '',
      searchContracts: '',
      searchCompanies: '',
      loadingEntities: true,
      entitiesOpened: true,
      visibility: this.props.item.visibility || 'PUBLIC',
      markdown: null,
      tabSelected: 'write'
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this.getEntities().then(()=>{
      this._isMounted && this.setState({loadingEntities: false})
    })
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      setTimeout(()=>this.props.callback({showNoteFormEdit: false}),200)
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private callbackFunction(childData) {
    this._isMounted && this.setState(childData)
  }

  private async getEntities() {
    userService.getNoteContent(this.props.item.id).then((response)=>{
        this.setState({note: response.data.content})
    }).catch((error)=>{
      this._isMounted && this.setState({loading: false})
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else {
        this.props.showAlertDialog('Error', "An error occurred trying to fetch the note content. Please try again.")
      }
    })
    await new Promise((resolve) => {
        userService.getCompanies().then((response)=>{
            let companies = response.data;
            for (let i=0; i<companies.length; i++) {
                companies[i].key = companies[i].id
                companies[i].text = companies[i].name
                if (this.props.item.companyIds.includes(companies[i].id)) {
                  this.addCompany(companies[i])
                }
            }
            this.setState({possibleCompanies: companies})
            resolve(true);
        }).catch(()=>{
            resolve(true);
        })
    })
    await new Promise((resolve) => {
        userService.getContacts().then((response)=>{
            let contacts = response.data;
            for (let i=0; i<contacts.length; i++) {
                contacts[i].key = contacts[i].id
                contacts[i].text = contacts[i].firstName + (contacts[i].lastName ? (' ' + contacts[i].lastName) : '')
                if (contacts[i].id === this.props.item.createdByUserId) {
                  this.addContact(contacts[i], true)
                }
                else if (this.props.item.contactIds.includes(contacts[i].id)) {
                  this.addContact(contacts[i])
                }
            }
            this.setState({possibleContacts: contacts.map((item)=>{return {...item, name: (item.firstName || '') + ' ' + (item.lastName || ''), display: (item.firstName || '') + ' ' + (item.lastName || ''), email: (item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email) || ''}})})
            resolve(true);
        }).catch(()=>{
            resolve(true);
        })
    })
    await new Promise((resolve) => {
        userService.getContracts().then((response)=>{
            let contracts = response.data;
            for (let i=0; i<contracts.length; i++) {
                contracts[i].key = contracts[i].id
                contracts[i].text = contracts[i].name
                if (this.props.item.contractIds.includes(contracts[i].id)) {
                  this.addContract(contracts[i])
                }
            }
            this.setState({possibleContracts: contracts})
            resolve(true);
        }).catch(()=>{
            resolve(true);
        })
    })
    await new Promise((resolve) => {
        userService.getProjects().then((response)=>{
            let projects = response.data;
            for (let i=0; i<projects.length; i++) {
                projects[i].key = projects[i].id
                projects[i].text = projects[i].name
                if (this.props.item.projectIds.includes(projects[i].id)) {
                  this.addProject(projects[i])
                }
            }
            this.setState({possibleProjects: projects})
            resolve(true);
        }).catch(()=>{
            resolve(true);
        })
    })
  }

  private addCompany(option: any, byDefault = false){
    if (option) {
        let companies = this.state.currentCompanies
        let repeated = false;
        for (let i in companies) {
            if (companies[i].key === option.key )repeated = true;
        }

        if (!repeated) {
            option.byDefault = byDefault
            companies.push(option)
            byDefault ? this.setState({currentCompanies: companies}) : this.setState({currentCompanies: companies, changed: true})
        }
    }
  }

  private addContact(option: any, byDefault = false){
    if (option) {
        let contacts = this.state.currentContacts
        let repeated = false;
        for (let i in contacts) {
            if (contacts[i].key === option.key )repeated = true;
        }

        if (!repeated) {
            option.byDefault = byDefault
            contacts.push(option)
            byDefault ? this.setState({currentContacts: contacts}) : this.setState({currentContacts: contacts, changed: true})
        }
    }
  }

  private addContract(option: any, byDefault = false){
    if (option) {
        let contracts = this.state.currentContracts
        let repeated = false;
        for (let i in contracts) {
            if (contracts[i].key === option.key )repeated = true;
        }

        if (!repeated) {
            option.byDefault = byDefault
            contracts.push(option)
            byDefault ? this.setState({currentContracts: contracts}) : this.setState({currentContracts: contracts, changed: true})
        }
    }
  }

  private addProject(option: any, byDefault = false){
    if (option) {
        let projects = this.state.currentProjects
        let repeated = false;
        for (let i in projects) {
            if (projects[i].key === option.key )repeated = true;
        }

        if (!repeated) {
            option.byDefault = byDefault
            projects.push(option)
            byDefault ? this.setState({currentProjects: projects}) : this.setState({currentProjects: projects, changed: true})
        }
    }
  }

  private removeFrom(item, type) {
    if (type === 'companies') {
        let items = this.state.currentCompanies.filter((company)=>{
            return item.key !== company.key
        })
        this.setState({currentCompanies: items, changed: true})
    } else if (type === 'contacts') {
        let items = this.state.currentContacts.filter((company)=>{
            return item.key !== company.key
        })
        this.setState({currentContacts: items, changed: true})
    } else if (type === 'contracts') {
        let items = this.state.currentContracts.filter((company)=>{
            return item.key !== company.key
        })
        this.setState({currentContracts: items, changed: true})
    } else if (type === 'projects') {
        let items = this.state.currentProjects.filter((company)=>{
            return item.key !== company.key
        })
        this.setState({currentProjects: items, changed: true})
    }
  }

  private updateTag(data) {
    if (data) {
      this._isMounted && this.setState({  
        tag: data.key,
        changed: true
      })
    }
  }

  private getMarkdown() {
    let autocomplete:any = document.getElementById('contacts-autocomplete-input')
    let note = autocomplete?.value || ''
    //let rawMarkup = marked.parse(DOMPurify.sanitize(note));
    var MarkdownIt = require('markdown-it');
    var emoji = require('markdown-it-emoji');
    var md = new MarkdownIt();
    md.use(emoji);
    var result = md.render(DOMPurify.sanitize(note));
    let markdown = { __html: result };
    this.setState({markdown: markdown})
  }

  private _handleTabSelect = (e:SelectTabEvent, data: any): void => {
    if (data.value === 'preview' && this.state.tabSelected === 'write') {
      this.getMarkdown();
    }
    this._isMounted && this.setState({
      tabSelected: data.value
    });
  };

  private _submit(e) {
    if (e) e.preventDefault();

    const {name, tag, visibility, currentCompanies, currentContacts, currentProjects, currentContracts} = this.state;
    const contacts:string[] = currentContacts.map((item)=>{return item.id})
    const companies:string[] = currentCompanies.map((item)=>{return item.id})
    const projects:string[] = currentProjects.map((item)=>{return item.id})
    const contracts:string[] = currentContracts.map((item)=>{return item.id})
    const associateEntityRequest = {companyIds: companies, contactIds: contacts, projectIds: projects, contractIds: contracts}

    let autocomplete:any = document.getElementById('contacts-autocomplete-input')
    let note = autocomplete?.value || ''

    this._isMounted && this.setState({loading: true})
  
    userService.updateNote(this.props.item.id, name, note, tag, associateEntityRequest).then((response)=>{
      userService.updateNoteRelations(this.props.item.id, associateEntityRequest).then((response)=>{
        if (this.props.item.visibility !== visibility) {
          userService.updateNoteVisibility(this.props.item.id, visibility).then((response)=>{
            this._isMounted && this.setState({loading: false, hidden:true})
            this.props.getCurrentContent(true);
          }).catch((error)=>{
            this._isMounted && this.setState({loading: false})
            if (error && error.data && error.data.code === 400 && error.data.message) {
              this.props.showAlertDialog('Error', error.data.message)
            } else if (error && error.data && error.data.message) {
              this.props.showAlertDialog('Error', error.data.message)
            } else {
              this.props.showAlertDialog('Error', "An error occurred trying to update the note. Please try again.")
            }
          })
        } else {
          this._isMounted && this.setState({loading: false, hidden:true})
          this.props.getCurrentContent(true);
        }
      }).catch((error)=>{
      this._isMounted && this.setState({loading: false})
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else {
        this.props.showAlertDialog('Error', "An error occurred trying to update the note. Please try again.")
      }
    })
    }).catch((error)=>{
      this._isMounted && this.setState({loading: false})
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else {
        this.props.showAlertDialog('Error', "An error occurred trying to update the note. Please try again.")
      }
    })
  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      this._isMounted && this.setState({hidden:true})
    }
  }

  public render() {

    const { hidden, loading, name, tag, tags, note } = this.state;

    const columnsCompanies:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this.setState({showCurrentCompanies: !this.state.showCurrentCompanies})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentCompanies ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:companies') + ' (' + this.state.currentCompanies.length + ')' }</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
            <div>
              <div className='d-flex align-items-center'>
                  <p title={item.name} className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)', maxWidth: '180px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                      {item.name}
                  </p>
                  { item.new && <TooltipHost content={<div className='p-1'>{i18n.t('app:newCompany')}.</div>} calloutProps={{ gapSpace: 0 }}>
                      <span className='new-badge'>{i18n.t('app:new')}</span>
                  </TooltipHost> }
              </div>
              <p title={item.domain} className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}><Link target='_blank' href={'https://'+item.domain}>{item.domain}</Link></p>
            </div>
            {item && !item.byDefault && <TooltipHost
                content={i18n.t('app:remove')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto'}}}
              >
                <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,'companies')}}/>
              </TooltipHost>}
            {item && item.byDefault && <TooltipHost
              content={i18n.t('app:byDefault')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto', width: '32px'}}}
            >
              <Icon iconName='Lock' style={{marginLeft: '10px'}}/>
            </TooltipHost>}
          </div>
        </>)
      }
    }]
    
    const columnsContacts:any[] = [{
        key: 'name',
        name: <div 
        onClick={()=>{this.setState({showCurrentContacts: !this.state.showCurrentContacts})}} 
        className='d-flex align-items-center relation-header'>
          <TooltipHost
            content={i18n.t('app:expand/collapse')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {marginLeft: 'auto', height: '36px' }}}
          >
            <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentContacts ? 'ChevronDown' : 'ChevronRight'}}/>
          </TooltipHost>
          <span>{i18n.t('app:contacts') + ' (' + this.state.currentContacts.length + ')'}</span>
        </div>,
        fieldName: 'name',
        //isSorted: true,
        isPadded: false,
        onRender: (item)=>{
          return (<>
            <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
              <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
              <div>
                <div className='d-flex align-items-center'>
                    <p title={item.firstName + (item.lastName ? ' ' + item.lastName : '')} className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)', maxWidth: '180px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {item.firstName + (item.lastName ? ' ' + item.lastName : '')}
                    </p>
                    { item.mentioned && <TooltipHost content={<div className='p-1'>{i18n.t('app:mentionedInText')}</div>} calloutProps={{ gapSpace: 0 }}>
                        <span className='new-badge'>{i18n.t('app:mentioned')}</span>
                    </TooltipHost> }
                </div>
                <p title={(item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email) || item.email} className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}><Link target='_blank' href={'mailto:'+((item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email) || item.email)}>{(item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email) || item.email}</Link></p>
              </div>
              {item && !item.byDefault && <TooltipHost
                content={i18n.t('app:remove')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto'}}}
              >
                <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,'contacts')}}/>
              </TooltipHost>}
                {item && item.byDefault && <TooltipHost
                content={i18n.t('app:byDefault')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto', width: '32px'}}}
              >
                <Icon iconName='Lock' style={{marginLeft: '10px'}}/>
              </TooltipHost>}
            </div>
          </>)
        }
    }]

    const columnsContracts:any[] = [{
        key: 'name',
        name: <div 
        onClick={()=>{this.setState({showCurrentContracts: !this.state.showCurrentContracts})}} 
        className='d-flex align-items-center relation-header'>
          <TooltipHost
            content={i18n.t('app:expand/collapse')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {marginLeft: 'auto', height: '36px' }}}
          >
            <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentContracts ? 'ChevronDown' : 'ChevronRight'}}/>
          </TooltipHost>
          <span>{i18n.t('app:contracts') + ' (' + this.state.currentContracts.length + ')'}</span>
        </div>,
        fieldName: 'name',
        //isSorted: true,
        isPadded: false,
        onRender: (item)=>{
          return (<>
            <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
                <div className='d-flex align-items-center'>
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
                <div>
                    <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name}</p>
                    <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}><Link target='_blank' href={'/contracts/'+item.id}>Link</Link></p>
                </div>
              </div>
              {item && !item.byDefault && <TooltipHost
                content={i18n.t('app:remove')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto'}}}
              >
                <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,'contracts')}}/>
              </TooltipHost>}
              {item && item.byDefault && <TooltipHost
                content={i18n.t('app:byDefault')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto', width: '32px'}}}
              >
                <Icon iconName='Lock' style={{marginLeft: '10px'}}/>
              </TooltipHost>}
            </div>
          </>)
        }
    }]
    
    const columnsProjects:any[] = [{
        key: 'name',
        name: <div 
        onClick={()=>{this.setState({showCurrentProjects: !this.state.showCurrentProjects})}} 
        className='d-flex align-items-center relation-header'>
          <TooltipHost
            content={i18n.t('app:remove')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {marginLeft: 'auto', height: '36px' }}}
          >
            <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentProjects ? 'ChevronDown' : 'ChevronRight'}}/>
          </TooltipHost>
          <span>{i18n.t('app:projects') + ' (' + this.state.currentProjects.length + ')'}</span>
        </div>,
        fieldName: 'name',
        //isSorted: true,
        isPadded: false,
        onRender: (item)=>{
          return (<>
            <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
              <div className='d-flex align-items-center'>
              <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
              <div>
                <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name}</p>
                <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}><Link target='_blank' href={'/projects/'+item.id}>Link</Link></p>
              </div>
              </div>
              {item && !item.byDefault && <TooltipHost
                content={i18n.t('app:remove')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto'}}}
              >
                <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,'projects')}}/>
              </TooltipHost>}
              {item && item.byDefault && <TooltipHost
                content={i18n.t('app:byDefault')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto', width: '32px'}}}
              >
                <Icon iconName='Lock' style={{marginLeft: '10px'}}/>
              </TooltipHost>}
            </div>
          </>)
        }
    }]

    const filterCompaniesByText = (
        filterText: string,
        currentPersonas: IPersonaProps[],
        limitResults?: number,
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = this.state.possibleCompanies.filter(item => doesTextStartWith(item, filterText));
        
            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const filterContactsByText = (
        filterText: string,
        currentPersonas: IPersonaProps[],
        limitResults?: number,
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = this.state.possibleContacts.filter(item => doesTextStartWith(item, filterText));
        
            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const filterContractsByText = (
        filterText: string,
        currentPersonas: IPersonaProps[],
        limitResults?: number,
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = this.state.possibleContracts.filter(item => doesTextStartWith(item, filterText));
        
            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const filterProjectsByText = (
        filterText: string,
        currentPersonas: IPersonaProps[],
        limitResults?: number,
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = this.state.possibleProjects.filter(item => doesTextStartWith(item, filterText));
        
            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
        return personasToReturn;
    };

    const onRenderDetailsHeaderCompanies = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
        return (
        <>
            <div className='d-flex align-items-center'>
                {defaultRender!({
                ...props
                })}
            </div>
            { this.state.showCurrentCompanies && <div style={{background:'white'}}>
                <ListPeoplePicker disabled={this.state.loadingEntities} styles={{text: {borderColor:'#bfbfbf', borderRadius: 'none', margin: '0 10px'}, root: {padding:'10px 0'} }} onRenderSuggestionsItem={ (item:any)=>{
                    return (
                        <FluentProvider className='d-flex align-items-center w-100 px-2' style={{background: 'transparent'}}>
                          <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
                            <div>
                                <p className='p-0 m-0 text-left' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.text}</p>
                                <p className='p-0 m-0 text-left' style={{color:'rgb(96, 94, 92)', fontSize: 12}}><Link target='_blank' href={'https://'+item.domain}>{item.domain}</Link></p>
                            </div>
                        </FluentProvider>
                  )
                }} onItemSelected={(item)=>{this.addCompany(item); return null;}} onResolveSuggestions={(f,i:any)=>filterCompaniesByText(f,i)}/>
            </div>}
        </>
        );
    }

    const onRenderDetailsHeaderContacts = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
        return (
        <>
            <div className='d-flex align-items-center'>
                {defaultRender!({
                ...props
                })}
            </div>
            { this.state.showCurrentContacts && <div style={{background:'white'}}>
                <ListPeoplePicker disabled={this.state.loadingEntities} styles={{text: {borderColor:'#bfbfbf', borderRadius: 'none', margin: '0 10px'}, root: {padding:'10px 0'} }} onRenderSuggestionsItem={ (item:any)=>{
                    return (
                        <FluentProvider className='d-flex align-items-center w-100 px-2' style={{background: 'transparent'}}>
                          <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
                            <div>
                                <p className='p-0 m-0 text-left' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.text}</p>
                                <p className='p-0 m-0 text-left' style={{color:'rgb(96, 94, 92)', fontSize: 12}}><Link target='_blank' href={'mailto:'+((item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email) || item.email)}>{(item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email) || item.email}</Link></p>
                            </div>
                        </FluentProvider>
                    )
                }} onItemSelected={(item)=>{this.addContact(item); return null;}} onResolveSuggestions={(f,i:any)=>filterContactsByText(f,i)}/>
            </div>}
        </>
        );
    }

    const onRenderDetailsHeaderContracts = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
        return (
        <>
            <div className='d-flex align-items-center'>
                {defaultRender!({
                ...props
                })}
            </div>
            { this.state.showCurrentContracts && <div style={{background:'white'}}>
                <ListPeoplePicker disabled={this.state.loadingEntities} styles={{text: {borderColor:'#bfbfbf', borderRadius: 'none', margin: '0 10px'}, root: {padding:'10px 0'} }} onRenderSuggestionsItem={ (item:any)=>{
                    return (
                        <FluentProvider className='d-flex align-items-center w-100 px-2' style={{background: 'transparent'}}>
                          <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
                            <div>
                                <p className='p-0 m-0 text-left' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.text}</p>
                                <p className='p-0 m-0 text-left' style={{color:'rgb(96, 94, 92)', fontSize: 12}}><Link target='_blank' href={'/contracts/'+item.id}>{i18n.t('app:Link')}</Link></p>
                            </div>
                        </FluentProvider>
                    )
                }} onItemSelected={(item)=>{this.addContract(item); return null;}} onResolveSuggestions={(f,i:any)=>filterContractsByText(f,i)}/>
            </div>}
        </>
        );
    }

    const onRenderDetailsHeaderProjects =(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
        return (
        <>
            <div className='d-flex align-items-center' style={{backgroundColor: 'white'}}>
                {defaultRender!({
                ...props
                })}
            </div>
            { this.state.showCurrentProjects && <div style={{background:'white'}}>
                <ListPeoplePicker disabled={this.state.loadingEntities} styles={{text: {borderColor:'#bfbfbf', borderRadius: 'none', margin: '0 10px'}, root: {padding:'10px 0'} }} onRenderSuggestionsItem={ (item:any)=>{
                    return (
                        <FluentProvider className='d-flex align-items-center w-100 px-2' style={{background: 'transparent'}}>
                          <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
                            <div>
                                <p className='p-0 m-0 text-left' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.text}</p>
                                <p className='p-0 m-0 text-left' style={{color:'rgb(96, 94, 92)', fontSize: 12}}><Link target='_blank' href={'/projects/'+item.id}>{i18n.t('app:Link')}</Link></p>
                            </div>
                        </FluentProvider>
                  )
                }} onItemSelected={(item)=>{this.addProject(item); return null;}} onResolveSuggestions={(f,i:any)=>filterProjectsByText(f,i)}/>
            </div>}
        </>
        );
    }

    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Note'  src={process.env.PUBLIC_URL + '/icons/documents.svg'} />{i18n.t('app:editNote')}</div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: 'none', background: '#f2f2f2'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog full-height " + (this.state.entitiesOpened ? 'big-width' : ''),
            onDismiss: ()=> {this.close()},
          }}
        >
          <div className='' style={{borderBottom: '1px solid #bfbfbf', position: 'sticky', top: 0, zIndex: 1}}></div>
          <FluentProvider theme={webLightTheme} className='w-100' style={{display: 'flex', height: '100%', backgroundColor: '#f2f2f2'}}>
            <div style={{width: this.state.entitiesOpened ? '66.66%' : '100%'}}>
              <div className='d-flex align-items-center py-4 px-3 pr-1' style={{height: '32px', borderBottom: '1px solid #bfbfbf'}}>
                <span style={{fontWeight: 600}}>{i18n.t('app:note')}</span>
                <TooltipHost
                  content={this.state.entitiesOpened ? i18n.t('app:closePanel') : i18n.t('app:openPanel')}
                  calloutProps={{ gapSpace: 0 }}
                  styles={{root: {marginLeft: 'auto' }}}
                >
                  <IconButton className='pr-0' iconProps={{iconName: this.state.entitiesOpened ? 'OpenPane' : 'ClosePane'}} styles={{icon: {fontSize: '20px'}}} onClick={()=>{this.setState({entitiesOpened: !this.state.entitiesOpened})}}/>
                </TooltipHost>
              </div>
              <ScrollablePane className='relation-list' style={{top: '50px', width: this.state.entitiesOpened ? '66.66%' : '100%'}}>
                <form id='form' className='px-0 pb-3 h-100 d-flex flex-column' onSubmit={this._submit.bind(this)}>
                  <input className='d-none' type='submit'></input>
                  <div className='d-flex'>
                    <div className='mt-3 w-75'>
                      <Icon iconName='TextField' style={{marginRight: '10px', verticalAlign: 'middle'}}/>
                      <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:subject')}</Label>
                      <div className='d-flex'>
                        <Input className='flex-grow-1' value={name} onChange={(e,data)=>{this._isMounted && this.setState({name: data.value, changed: true})}} required autoFocus/>
                      </div>
                    </div>
                    <div className='mt-3 ml-3 w-25'>
                      <div className='d-flex align-items-center'>
                        <Icon iconName='Dropdown' style={{marginRight: '10px', verticalAlign: 'middle'}}/>
                        <Label className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:type')}</Label>
                      </div>
                      <div className='d-flex align-items-center flex-grow-1' style={{position: 'relative'}}>
                        <Dropdown className='flex-grow-1' styles={{ root: {borderRadius: '4px'}}} selectedKey={tag} onChange={(e, data:any) => this.updateTag(data)} options={tags} 
                          onRenderOption={(item:any)=>{return <div className='d-flex align-items-center' style={{height:'32px'}}><Icon className='mr-2' iconName={item.icon}/><span>{item.text}</span></div>}}
                          onRenderTitle={(item:any)=>{return <div className='d-flex align-items-center' style={{height:'32px'}}><Icon className='mr-2' iconName={item[0].icon}/><span>{item[0].text}</span></div>}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mt-3'>
                    <Icon iconName='Dropdown' style={{marginRight: '10px', verticalAlign: 'middle'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:permissions')}</Label>
                    <div className='d-flex'>
                      <Dropdown className='flex-grow-1' selectedKey={this.state.visibility} onChange={(e,data:any)=>{this._isMounted && this.setState({visibility: data?.key, changed: true})}} options={[{key: 'PUBLIC', text: i18n.t('app:publicVisibility')}, {key: 'INHERIT', text: i18n.t('app:inheritVisibility')}, {key: 'PRIVATE', text: i18n.t('app:privateVisibility')}]}/>
                    </div>
                  </div>
                  <div className='mt-3'>
                    <Icon iconName='TextField' style={{marginRight: '10px', verticalAlign: 'middle'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:content')}</Label>
                  </div>
                  <TabList className='mb-2' selectedValue={this.state.tabSelected} onTabSelect={this._handleTabSelect}>
                    <Tab value={'write'}>{i18n.t('app:write')}</Tab>
                    <Tab value={'preview'}>{i18n.t('app:preview')}</Tab>
                  </TabList>
                  {this.state.possibleContacts && this.state.possibleContacts.length > 0 ?
                    <>
                      <div className='flex-grow-1 markdown-frame' style={{overflowY: 'auto', display: this.state.tabSelected === 'preview' ? 'block' : 'none', backgroundColor: 'white', borderRadius: '4px', border: '1px solid #d1d1d1', padding: '6px 12px'}}>
                        <div className='markdown-body' dangerouslySetInnerHTML={this.state.markdown}>
                        </div>
                      </div>
                      <div className='flex-grow-1' style={{display: this.state.tabSelected === 'preview' ? 'none' : 'flex'}}>
                        <div className="w-100">
                          <MentionsInput
                            className="mentions"
                            id='contacts-autocomplete-input'
                            value={note} 
                            onChange={(e,v)=>{this._isMounted && this.setState({note: v || ''})}}
                            allowSuggestionsAboveCursor
                            allowSpaceInQuery
                          >
                            <Mention
                              trigger="@"
                              data={(search)=>{return search && search.length > 0 ? this.state.possibleContacts.filter((item)=>{return item.name.toLowerCase().startsWith(search.toLowerCase())}) : []}}
                              renderSuggestion={(props)=>{
                                return accountItem(props)
                              }}
                              appendSpaceOnAdd
                              onAdd={(id)=>{
                                let contact = this.state.possibleContacts.find((item)=>{
                                  return item.id === id
                                })
                                if (contact) this.addContact({...contact, mentioned: true})
                              }}
                              displayTransform={(id, display)=>{return '[@'+display+'](/contacts/'+id+')'}}
                              //markup='@[__display__](__id__)'
                            />
                          </MentionsInput>
                        </div>
                      </div>
                    </>
                  : <Spinner className='my-auto'/>}
                  <p className='m-0 mt-2'>{i18n.t('app:supports')} <Link target='_blank' href='https://www.markdownguide.org/basic-syntax/#overview' >Markdown</Link>.</p>
                </form>
              </ScrollablePane>
            </div>
            <div style={{borderLeft: '1px solid #bfbfbf', width: '33.3%', display: this.state.entitiesOpened ? 'block' : 'none'}}>
              <div className='d-flex align-items-center' style={{borderBottom: '1px solid #bfbfbf', height: '49px'}}>
                <span className='px-4' style={{fontWeight: 600}}>{i18n.t('app:relations')}</span>
              </div>
              <ScrollablePane className='relation-list' style={{left:'66.66%', width: '33.3%', top: '50px'}}>
                <ShimmeredDetailsList
                className={this.state.currentProjects.length === 0 || !this.state.showCurrentProjects ? 'mt-3 mb-2 height-fix' : 'mt-3'}
                items={this.state.showCurrentProjects ? this.state.currentProjects : []}
                columns={columnsProjects}
                onRenderDetailsHeader={onRenderDetailsHeaderProjects}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                shimmerLines={12}
                constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentProjects.length === 0 && this.state.showCurrentProjects && <div className='d-flex mb-3' style={{height: '47px', background: 'white', fontSize:12, color: '#bfbfbf', marginTop: '54px'}}>
                  <span className='m-auto'>{this.state.loadingEntities ? <Spinner size={SpinnerSize.small}/> : i18n.t('app:empty')}</span>
                </div>}
                <ShimmeredDetailsList
                className={this.state.currentContracts.length === 0 || !this.state.showCurrentContracts ? 'mt-3 mb-2 height-fix' : 'mt-3'}
                items={this.state.showCurrentContracts ? this.state.currentContracts : []}
                columns={columnsContracts}
                onRenderDetailsHeader={onRenderDetailsHeaderContracts}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                shimmerLines={12}
                constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentContracts.length === 0 && this.state.showCurrentContracts && <div className='d-flex' style={{height: '47px', background: 'white', fontSize:12, color: '#bfbfbf', marginTop: '54px'}}>
                    <span className='m-auto'>{this.state.loadingEntities ? <Spinner size={SpinnerSize.small}/> : i18n.t('app:empty')}</span>
                </div>}
                <ShimmeredDetailsList
                className={this.state.currentContacts.length === 0 || !this.state.showCurrentContacts ? 'mt-3 mb-2 height-fix' : 'mt-3'}
                items={this.state.showCurrentContacts ? this.state.currentContacts : []}
                columns={columnsContacts}
                onRenderDetailsHeader={onRenderDetailsHeaderContacts}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                shimmerLines={12}
                constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentContacts.length === 0 && this.state.showCurrentContacts && <div className='d-flex' style={{height: '47px', background: 'white', fontSize:12, color: '#bfbfbf', marginTop: '54px'}}>
                    <span className='m-auto'>{this.state.loadingEntities ? <Spinner size={SpinnerSize.small}/> : i18n.t('app:empty')}</span>
                </div>}
                <ShimmeredDetailsList
                className={this.state.currentCompanies.length === 0 || !this.state.showCurrentCompanies ? 'mt-3 mb-2 height-fix' : 'my-3'}
                items={this.state.showCurrentCompanies ? this.state.currentCompanies : []}
                columns={columnsCompanies}
                onRenderDetailsHeader={onRenderDetailsHeaderCompanies}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                shimmerLines={12}
                constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentCompanies.length === 0 && this.state.showCurrentCompanies && <div className='d-flex mb-3' style={{height: '47px', background: 'white', fontSize:12, color: '#bfbfbf', marginTop: '54px'}}>
                    <span className='m-auto'>{this.state.loadingEntities ? <Spinner size={SpinnerSize.small}/> : i18n.t('app:empty')}</span>
                </div>}
              </ScrollablePane>
            </div>
          </FluentProvider>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=>{this.close()}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton form='form' type='submit' text={i18n.t('app:save')} disabled={loading} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

}

function accountItem( item ) {
  return (
    <div className='d-flex align-items-center w-100 px-2'>
      <Persona name={item.name} size='small' primaryText={''} avatar={{ color: "colorful"}}/>
        <div>
          <p className='p-0 m-0 text-left' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.text}</p>
          <p className='p-0 m-0 text-left' style={{color:'rgb(96, 94, 92)', fontSize: 12}}><Link target='_blank' href={'mailto:'+item.email}>{item.email}</Link></p>
        </div>
    </div>
  );
}

function doesTextStartWith(item: any, filterText: string): boolean {
  let email = item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email
  return item.text.toLowerCase().indexOf(filterText.toLowerCase()) > -1 || (email && email.toLowerCase().indexOf(filterText.toLowerCase()) > -1);
}

function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
  return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
  if (!personas || !personas.length || personas.length === 0) {
      return false;
  }
  return personas.filter(item => item.text === persona.text).length > 0;
}