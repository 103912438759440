import { ActionButton, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, DropdownMenuItemType, Icon, IconButton, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import i18n from '../../i18n';
import { userService } from '../../_services/user.service';
import { InfoButton } from '@fluentui/react-components/unstable';
import { FluentProvider, Input, Label, Textarea, webLightTheme } from '@fluentui/react-components';
import { CustomFieldPreview } from '../CustomFieldPreview';
import uuid from 'uuid-random';
import { _reorderCustomFields } from '../../_helpers/reorderCustomFields';


export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean,
  loading: boolean,
  name: string,
  description: string,
  fieldGroups: any,
  fieldGroupsKeys: any,
  fieldGroupsKeysOld: any,
  fieldType: any,
  multipleOptions: any
  changed: boolean
}

const fieldGroups = [
  {key: "COMPANY", text: i18n.t('app:company')},
  {key: "CONTACT", text: i18n.t('app:contact')},
  {key: "CONTRACT", text: i18n.t('app:contract')},
  {key: "PROJECT", text: i18n.t('app:project')}
]

const fieldTypes = [
  {key: 'textInput', text: i18n.t('app:textInput'), itemType: DropdownMenuItemType.Header},
  {key: "singleLine", text: i18n.t('app:singleLine')},
  {key: "multiLine", text: i18n.t('app:multiLine')},
  {key: "number", text: i18n.t('app:number')},
  {key: 'options', text: i18n.t('app:options'), itemType: DropdownMenuItemType.Header},
  {key: "checkbox", text: i18n.t('app:checkbox')},
  {key: "dropdownSelection", text: i18n.t('app:dropdownSelection')},
  {key: "multiSelection", text: i18n.t('app:multiSelection')},
  {key: "datePicker", text: i18n.t('app:datePicker')},
  {key: 'other', text: i18n.t('app:other'), itemType: DropdownMenuItemType.Header},
  {key: "contact", text: i18n.t('app:contact')},
  {key: "file", text: i18n.t('app:file')},
]

export class FormCustomFieldsEdit extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    let type = 'singleLine';
    if (this.props.item.fieldType === 'FILE') {
      type = 'file'
    } else if (this.props.item.fieldType === 'CONTACT') {
      type = 'contact'
    } else if (this.props.item.fieldType === 'TAG' && this.props.item.multipleValues) {
      type = 'multiSelection'
    } else if (this.props.item.fieldType === 'TAG') {
      type = 'dropdownSelection'
    } else if (this.props.item.fieldType === 'DATE') {
      type = 'datePicker'
    } else if (this.props.item.fieldType === 'BOOLEAN') {
      type = 'checkbox'
    } else if (this.props.item.fieldType === 'NUMBER') {
      type = 'number'
    } else if (this.props.item.fieldType === 'STRING' && this.props.item.multiLine) {
      type = 'multiLine'
    } else if (this.props.item.fieldType === 'STRING') {
      type = 'singleLine'
    }

    let values:any[] = [];
    if (this.props.item.possibleValues) {
      for (let key in this.props.item.possibleValues) {
        values.push({key: key, text: this.props.item.possibleValues[key]})
      }
    }

    let entityTypes =  this.props.item.customPropertyTypes.map(entity => {return entity.entityType})

    this.state = {
      hidden: false,
      loading: false,
      name: this.props.item.name,
      description: this.props.item.description,
      fieldGroups: fieldGroups.filter(k =>  entityTypes.includes(k.key)),
      fieldGroupsKeys: entityTypes,
      fieldGroupsKeysOld: entityTypes,
      fieldType: type,
      multipleOptions: this.props.item.possibleValues ? values : [{key: uuid(), text: ''}],
      changed: false,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      setTimeout(()=>this.props.callback({showEditCustomFieldsForm: false}),200)
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private _changeGroups(e, option) {
    let selected = option?.selected
    if (option) {
      var keys = selected ? [...this.state.fieldGroupsKeys, option!.key as string] : this.state.fieldGroupsKeys.filter(k => k !== option!.key)
      var options = selected ? [...this.state.fieldGroups, option] : this.state.fieldGroups.filter(k => k.key !== option!.key)
      this._isMounted && this.setState({fieldGroupsKeys: keys, fieldGroups: options, changed: true})
    }
  }

  private _updateMultipleOptions(index, value) {
    let options = this.state.multipleOptions;
    options[index].text = value;
    this._isMounted && this.setState({multipleOptions: options, changed: true});
  }

  private _addOption() {
    let options = this.state.multipleOptions;
    options.push({key: uuid(), text: ''})
    this._isMounted && this.setState({multipleOptions: options, changed: true});
  }

  private _deleteOption(index) {
    let options = this.state.multipleOptions;
    options.splice(index, 1);
    this._isMounted && this.setState({multipleOptions: options, changed: true});
  }

  private _updateFieldType(option) {
    if (option.key === 'dropdownSelection' || option.key === 'multiSelection') {
      this._isMounted && this.setState({fieldType: option.key || 'singleLine', changed: true})
    } else {
      this._isMounted && this.setState({fieldType: option.key || 'singleLine', multipleOptions: [{id: '1', key: '1', text: ''}], changed: true})
    }
  }

  private _submit(e) {
    if (e) e.preventDefault();
    const {name, description, fieldType, multipleOptions, fieldGroups, loading} = this.state;
    if (loading) return;
    let type = '';
    let multipleValues = false;
    let multiLine = false;
    if (fieldType === 'singleLine') {
      type = 'STRING';
    } else if (fieldType === 'multiLine') {
      type = 'STRING';
      multiLine = true;
    } else if (fieldType === 'number') {
      type = 'NUMBER';
    } else if (fieldType === 'checkbox') {
      type = 'BOOLEAN';
    } else if (fieldType === 'datePicker') {
      type = 'DATE';
    } else if (fieldType === 'dropdownSelection') {
      type = 'TAG';
    } else if (fieldType === 'multiSelection') {
      type = 'TAG';
      multipleValues = true;
    } else if (fieldType === 'contact') {
      type = 'CONTACT';
    } else if (fieldType === 'file') {
      type = 'FILE';
    }

    let values:any = {};
    if (multipleOptions) {
      for (let i in multipleOptions) {
        values[multipleOptions[i].key] = multipleOptions[i].text;
      }
    }

    const property = {
      name: name,
      description: description,
      entityTypes: fieldGroups.map(type => {return type.key}),
      fieldType: type,
      possibleValues: type === 'TAG' ? values : null,
      multipleValues: multipleValues,
      multiLine: multiLine
    }

    this._isMounted && this.setState({loading:true});
    userService.editCustomProperty(this.props.item.id, property)
    .then(()=>{
      this.setState({loading:false, hidden: true})
      let company = false;
      let contact = false;
      let project = false;
      let fields = this.state.fieldGroupsKeysOld.filter(x=>{return !this.state.fieldGroupsKeys.includes(x)})
      for (let i in fields) {
        if (fields[i] === 'COMPANY') company = true;
        else if (fields[i] === 'CONTACT') contact = true;
        else if (fields[i] === 'PROJECT') project = true;
      }
      _reorderCustomFields(company, contact, project)
      this.props.getCurrentContent(true);
    }).catch((error)=>{
      console.log(error)
      this.setState({loading:false})
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else {
        this.props.showAlertDialog('Error', "An error occurred trying to update the field. Please try again.")
      }
    })

  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      this._isMounted && this.setState({hidden:true})
    }
  }

  public render() {

    const { hidden, loading, name, description, fieldType, fieldGroupsKeys, multipleOptions } = this.state;
    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Fields' src={process.env.PUBLIC_URL + '/icons/filters.svg'} />{i18n.t('app:editCustomField')}</div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: 'overlay'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog",
            onDismiss: ()=> {this.close()}
          }}
        >
          <div className='mb-3' style={{borderBottom: '1px solid #bfbfbf', width: 'calc(100% - 48px)', position: 'sticky', left: '24px', top: 0, zIndex: 1}}></div>
          <FluentProvider theme={webLightTheme}>
            <form id='form' className='px-4 pb-4' onSubmit={this._submit.bind(this)}>
              <input className='d-none' type='submit'></input>
              <div className='d-flex mt-2'>
                <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:name')}</Label>
                <Input className='flex-grow-1' value={name} onChange={(e,data)=>{this._isMounted && this.setState({name: data.value, changed: true})}} required autoFocus/>
              </div>
              <div className='d-flex mt-3'>
                <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                <Label className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:description')}</Label>
                <Textarea className='flex-grow-1' value={description} onChange={(e,data)=>{this._isMounted && this.setState({description: data.value, changed: true})}}/>
              </div>
              <div className='d-flex mt-3'>
                <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:group')}</Label>
                <Dropdown
                  className='flex-grow-1'
                  options={fieldGroups}
                  selectedKeys={fieldGroupsKeys}
                  placeholder={i18n.t('app:selectGroup')}
                  multiSelect
                  onChange={(e,option)=>this._changeGroups(e,option)}
                  styles={{ title: {borderRadius: '4px'}}}
                />
              </div>
              <div className='d-flex mt-3'>
                <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:fieldType')}</Label>
                <Dropdown 
                  className='flex-grow-1'
                  options={fieldTypes}
                  selectedKey={fieldType}
                  onChange={(e,option:any)=>{this._updateFieldType(option)}}
                  styles={{ title: {borderRadius: '4px'}}}
                />
              </div>
              { fieldType === 'dropdownSelection' || fieldType === 'multiSelection' ?
                  <div className='mt-3 d-flex'>
                  <div className='d-flex'>
                    <Icon iconName='Dropdown' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:options')}</span>
                  </div>
                  <div className='flex-grow-1'>
                    {this.state.multipleOptions.map((item,index) => {
                      return (
                        <div key={item.id || item.key} >
                            <div className='d-flex align-items-center pb-3 mb-3' style={{borderBottom: '1px solid lightgrey'}}>
                              <Input
                                className='flex-grow-1 mr-2'
                                style={{width: '142px'}}
                                value={this.state.multipleOptions[index].text}
                                onChange={(e,data)=>{this._updateMultipleOptions(index, data.value)}}
                                placeholder={i18n.t('app:option') + ' ' + (index+1)}
                                required
                              />
                              <IconButton className='ml-auto' onClick={()=>this._deleteOption(index)} iconProps={{iconName: 'Delete'}} /> 
                            </div>
                      </div>
                      )
                    })}
                    <ActionButton className={this.state.multipleOptions.length > 0 ? '' : ''} styles={{root: {height: '28px'}}} onClick={()=> this._addOption()} iconProps={{iconName: 'Add'}} text={i18n.t('app:addOption')}/>
                  </div>
                </div>
              : null }
              {
                <div className='mt-4'>
                  <span className='' style={{fontWeight: 600}}>{i18n.t('app:preview')}</span>
                  <InfoButton content={<>{i18n.t('app:previewFieldInfo')}</>} />
                  <div className='mt-2 border-line p-4' style={{borderRadius: '4px'}}>
                    <CustomFieldPreview name={name} fieldType={fieldType} possibleValues={multipleOptions}/>
                  </div>
                </div>
              }
            </form>
          </FluentProvider>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this.close()}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton form='form' type='submit' text={i18n.t('app:save')} disabled={loading || !fieldGroupsKeys || fieldGroupsKeys.length === 0} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

}
