import React from 'react';
import { Router, Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './_helpers';
import { alertActions } from './_actions';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { LoginPage } from './_pages/LoginPage';
import { userService } from './_services/user.service';
import ProjectsPage from './_pages/ProjectsPage';
import { CompaniesPage } from './_pages/CompaniesPage';
import { registerIcons } from '@fluentui/react';
import Cookies from 'js-cookie'
import { ContactsPage } from './_pages/ContactsPage';
import { HomePage } from './_pages/HomePage';
import { PipelinesPage } from './_pages/PipelinesPage';
import { D365ProjectOperationsIcon } from '@fluentui/react-icons-mdl2-branded';
import { TimeTrackingPage } from './_pages/TimeTrackingPage';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { CustomFieldsPage } from './_pages/CustomFieldsPage';
import ContractsPage from './_pages/ContractsPage';
import NotesPage from './_pages/NotesPage';
import ConversationsPage from './_pages/ConversationsPage';
import { io } from 'socket.io-client';
import { SearchPage } from './_pages/SearchPage';
import EntityPage from './_pages/EntityPage';
import { TasksPage } from './_pages/TasksPage';
import { PermissionsPage } from './_pages/PermissionsPage';
import { EmailsPage } from './_pages/EmailsPage';
import SubEntityPage from './_pages/SubEntityPage';
import ViewsPage from './_pages/ViewsPage';

export interface Props {
  dispatch?: any;
}

export interface appState {
  userData?: any;
  initialRepo?: any;
  foldersList?: any;
  repoUsers?: any;
  database?: any;
  isOnline: boolean;
  driveUpdateRequired: boolean;
  toolsUpdateRequired: boolean;
  updatingTools: boolean;
  maintenance: boolean;
  showMaintenanceDialog: boolean;
  noSynergyToolsRunning: boolean;
  tryingToRunSynergyTools: boolean;
  socketMessage: any;
  pusherMessage: any;
  pusherStarted: boolean;
  editMessage: boolean;
  editMessageData: any;
  newEditMessage: string;
  saveAsMajorVersion: boolean;
  editingMessage: boolean;
  pusher: any;
}

const appPlatform = () => {
  return "webapp"
}

const getStoredData = (name: string) => {
  var cookie = Cookies.get(name)
  return cookie
}

//Ignore ReactDOM warning for React 18
const consoleWarn = console.error;
const SUPPRESSED_WARNINGS = ['ReactDOM.render is no longer supported in React 18.'];
console.error = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
        consoleWarn(msg, ...args);
    }
};

class App extends React.Component<Props, appState> {
  private _socket: any;
  private _pusher: any = null;
  private _tokenSchedule = false;

  constructor(props: Props) {
    super(props);
    const { dispatch } = this.props;
    history.listen(() => {
        // clear alert on location change
        dispatch(alertActions.clear());
    });
    
    initializeIcons();
    initializeFileTypeIcons();
    registerIcons({
      icons: {
        D365ProjectOperationsIcon: <D365ProjectOperationsIcon className='brand-icon'/>,
        D365ProjectOperationsIconNav: <D365ProjectOperationsIcon className='brand-icon-nav'/>,
        DriveSynergyOS: <img src={process.env.PUBLIC_URL + '/img/drive_icon.png'} style={{width: '20px', height: '20px'}}/>
      },
    });

    let token = getStoredData("token");
    if(token) {
        this._getUserData()
    } else {
        var pathname = window.location.pathname;
        var search = window.location.search;
        var prevPath = pathname + search;

        if (pathname.indexOf("/login") === -1 && prevPath !== "/index.html") localStorage.setItem("prevPath", prevPath)
        history.replace('/login')
    }
    // Clear importing files list from local storage
    localStorage.removeItem("uploadsQueue")
    this.state = {
      userData: null,
      initialRepo: null,
      foldersList: null,
      repoUsers: [],
      database: {
          /*processingUploadsDb: new PouchDB('synergy_processing_uploads_db'),
          queuedUploadsDb: new PouchDB('synergy_queued_uploads_db'),
          successfulUploadsDb: new PouchDB('synergy_successful_uploads_db'),
          errorUploadsDb: new PouchDB('synergy_error_uploads_db')*/
      },
      isOnline: navigator.onLine,
      driveUpdateRequired: false,
      toolsUpdateRequired: false,
      updatingTools: false,
      maintenance: false,
      showMaintenanceDialog: false,
      noSynergyToolsRunning: false,
      tryingToRunSynergyTools: false,
      socketMessage: null,
      pusherMessage: null,
      pusherStarted: false,
      editMessage: false,
      editMessageData: null,
      newEditMessage: i18n.t('app:minorVersion'),
      saveAsMajorVersion: false,
      editingMessage: false,
      pusher: null
    }
    localStorage.removeItem("lastFocusedItems")
  }
  
  public resetDataOnLogout() {
    console.log("Logout: reset app data")
    this.setState({
      userData: null,
      initialRepo: null,
      foldersList: null,
      repoUsers: [],
      database: {
          /*processingUploadsDb: new PouchDB('synergy_processing_uploads_db'),
          queuedUploadsDb: new PouchDB('synergy_queued_uploads_db'),
          successfulUploadsDb: new PouchDB('synergy_successful_uploads_db'),
          errorUploadsDb: new PouchDB('synergy_error_uploads_db')*/
      },
      isOnline: navigator.onLine,
      driveUpdateRequired: false,
      toolsUpdateRequired: false,
      updatingTools: false,
      maintenance: false,
      showMaintenanceDialog: false,
      noSynergyToolsRunning: false,
      tryingToRunSynergyTools: false,
      socketMessage: null,
      pusherMessage: null,
      pusherStarted: false,
      editMessage: false,
      editMessageData: null,
      newEditMessage: i18n.t('app:minorVersion'),
      saveAsMajorVersion: false,
      editingMessage: false
    }, () => {
        //this.disconnectPusher()
    })
  }

  private initPusherNotifications() { 
    let token = getStoredData("token");
    this._pusher = io(process.env.REACT_APP_SYNERGY_API_URL + (userService.getServerDomain() || ""),
        {
            'autoConnect': false,
            'path': '/pusher',
            'reconnection': true,
            'reconnectionAttempts': Infinity,
            'reconnectionDelayMax': 5000,
            'secure': true,
            'timeout': 10000,
            'transports': ['websocket', 'polling']
        }
    );

    this._pusher.on('connect', () => {      
    })

    this._pusher.on('error', (error) => {
      console.log('Pusher error:', error);
    });

    this._pusher.on('reconnect', () => {
      console.log("Pusher reconnect")
    })

    this._pusher.on('connect_error', (error) => {
      console.log('Pusher connect error:', error);
    });

    this._pusher.on('reconnect_attempt', function() {
      console.log('Pusher reconnect atempt'); 
    });

    this._pusher.on('reconnecting', function() {
      console.log('Pusher reconnecting'); 
    });

    this._pusher.on('reconnect_error', (obj) => {
      console.log('Pusher reconnection error'); 
    });     

    this._pusher.on('reconnect_failed', () => {
        console.log('Pusher reconnection failed');
    });

    this._pusher.on('disconnect', (reason) => {
      console.log('Pusher disconnected, reason:', reason);
    })

    this._pusher.on('user joined', (data) => {
      console.log(data.username + ' joined pusher room: ' + data.roomname);
    });

    this._pusher.on('new message', (data: any) => {
      this.setState({pusherMessage: data})
    })
    this.setState({pusher: this._pusher})
  }

  private _getUserData = ()  => {
    checkTokenExpirationDate().then(()=>{
      if (this._tokenSchedule === false) setInterval(checkTokenExpirationDate,600000)
      this._tokenSchedule = true;
      userService.getUserData().then((response:any) => {
        var user = response.data
        userService.getServerInfo(user.repository.deploymentDomain).then((res)=>{
          localStorage.setItem('companyLogo1', res.data.imageLarge);
          localStorage.setItem('companyLogo2', res.data.imageMedium);
          localStorage.setItem('companyLogo3', res.data.imageSmall);
        }).catch((error) => {
          if (error && error.status === 404 && user.repository.deploymentDomain === 'synergy.page') {
            userService.getServerInfo('dev.synergy.page').then((res)=>{
              localStorage.setItem('companyLogo1', res.data.imageLarge);
              localStorage.setItem('companyLogo2', res.data.imageMedium);
              localStorage.setItem('companyLogo3', res.data.imageSmall);
            })
          }
        })
        var  initialRepo = user.repository.id;

        this.setState({
          userData: user,
          initialRepo: initialRepo,
        }, ()=>{
          if(!this._pusher) {
            this.setState({pusherStarted: false}, () => {
                this.initPusherNotifications()
            })
        }
        })
        var prevPath = localStorage.getItem("prevPath");

        if (window.location.pathname === ("/projects")
        || window.location.pathname.includes("/login")
        || window.location.href.indexOf("null") !== -1) {
            if (prevPath && prevPath !== "/index.html") history.push(prevPath)
            else history.push('/home')
            localStorage.removeItem("prevPath")
        }
      }).catch(error => {
          console.log(error)
      })
    })
}
  
  render() {

    const { initialRepo } = this.state

    return (
      <FluentProvider theme={webLightTheme}>
        <Router location={history.location} navigator={history}>
          <Routes>
            <Route path="/login" element={<LoginPage appPlatform={appPlatform()} resetDataOnLogout={this.resetDataOnLogout.bind(this)} getUserData={this._getUserData} />} />
            <Route path="/projects/list" element={<ProjectsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="projectsExplorer" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects/clients" element={<ProjectsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="clientsExplorer" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects/stages" element={<ProjectsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="milestonesExplorer" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects/board" element={<ProjectsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="boardExplorer" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects/pipeline" element={<ProjectsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="pipelinesExplorer" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects/import" element={<ProjectsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="projectsImport" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects/:id" element={<EntityPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="projectData" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/projects" element={initialRepo !== null ? <Navigate to={{ pathname: '/projects/pipeline'}} /> : null}/>
            <Route path="/contracts" element={<ContractsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="contractsDashboard" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/contracts/list" element={<ContractsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="contractsExplorer" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/contracts/:id" element={<EntityPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="contractData" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/companies" element={<CompaniesPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="companiesExplorer" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/companies/preview" element={<CompaniesPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="companiesPreview" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/companies/inactive" element={<CompaniesPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="companiesExplorerTrash" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/companies/:id" element={<EntityPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="companyData" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/contacts" element={<ContactsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="contactsExplorer" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/contacts/inactive" element={<ContactsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="contactsExplorerTrash" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/contacts/:id" element={<EntityPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="contactData" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/pipelines" element={<PipelinesPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="pipelines" userData={this.state.userData}/>} />
            <Route path="/time-sheet" element={<TimeTrackingPage appPlatform={appPlatform()} isOnline={this.state.isOnline} userData={this.state.userData} page="timeSheet" />} />
            <Route path="/time-tracker" element={<TimeTrackingPage appPlatform={appPlatform()} isOnline={this.state.isOnline} userData={this.state.userData} page="timeTracker" />} />
            <Route path="/custom-fields/project" element={<CustomFieldsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} userData={this.state.userData} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="customFieldsProject" />} />
            <Route path="/custom-fields/contract" element={<CustomFieldsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} userData={this.state.userData} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="customFieldsContract" />} />
            <Route path="/custom-fields/contact" element={<CustomFieldsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} userData={this.state.userData} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="customFieldsContact" />} />
            <Route path="/custom-fields/company" element={<CustomFieldsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} userData={this.state.userData} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="customFieldsCompany" />} />
            <Route path="/search" element={<SearchPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="search" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/notes" element={<NotesPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="notes" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/notes/preview" element={<NotesPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="notesPreview" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/notes/id/:id" element={<SubEntityPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="note" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/conversations" element={<ConversationsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="conversations" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/conversations/preview" element={<ConversationsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusherMessage} page="conversationsPreview" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/conversations/id/:id" element={<SubEntityPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="conversation" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/tasks" element={<TasksPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="tasks" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/tasks/preview" element={<TasksPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="tasksPreview" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/tasks/id/:id" element={<SubEntityPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="task" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/emails" element={<EmailsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="emails" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/emails/preview" element={<EmailsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="emailsPreview" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/emails/id/:id" element={<SubEntityPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="email" userData={this.state.userData} foldersList={this.state.foldersList} repoUsers={this.state.repoUsers} database={this.state.database} />} />
            <Route path="/home" element={<HomePage appPlatform={appPlatform()} isOnline={this.state.isOnline} userData={this.state.userData} />} />
            <Route path="/permissions" element={<PermissionsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="permissions" userData={this.state.userData}/>} />
            <Route path="/views/:id" element={<ViewsPage appPlatform={appPlatform()} isOnline={this.state.isOnline} maintenance={this.state.maintenance} socket={this.state.socketMessage} pusher={this.state.pusher} pusherMessage={this.state.pusherMessage} page="views" userData={this.state.userData} />} />
            <Route path="/" element={initialRepo !== null ? <Navigate replace to={{ pathname: '/home'}} /> : null}/>
          </Routes>
        </Router>
      </FluentProvider>
    );
  }
 
}

function mapStateToProps(state: any) {
  const { alert } = state;
  return {
      alert
  };
}

const connectedApp = withTranslation()(connect(mapStateToProps)(App));
export { connectedApp as App };

async function checkTokenExpirationDate() {
  await new Promise((resolve) => {
      try {
          console.log('Checking token expiration date')
          let tokenttl = getStoredData("tokenttl");
          let currentDate = new Date().getTime();
          let pathname = window.location.pathname;
          if (pathname.indexOf("/login") === -1 && pathname.indexOf("/auth") === -1) {
              if ((tokenttl - currentDate) < (24*60*60*1000)) { 
                  console.log('Trying to refresh token')
                  userService.refreshToken().then(()=>{
                      resolve(true);
                  });
              } else {
                  console.log("Token expiration date is valid")
                  resolve(true);
              }
          } else {
              resolve(true);
          }
      } catch{
          resolve(true);
      }
  });
}
