import { ActionButton, ConstrainMode, DefaultButton, DetailsListLayoutMode, Dialog, DialogFooter, DialogType, Icon, IconButton, IDetailsHeaderProps, IRenderFunction, Pivot, PivotItem, PrimaryButton, ScrollablePane, SelectionMode, ShimmeredDetailsList, Spinner, SpinnerSize, TooltipHost, } from '@fluentui/react';
import { FluentProvider, Input, Link, Persona, webLightTheme } from '@fluentui/react-components';
import * as React from 'react';
import i18n from '../../i18n';
import { userService } from '../../_services';

export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  loadingEntities: boolean;
  projects: any[];
  contracts: any[];
  contacts: any[];
  companies: any[];
  filteredCompanies: any[];
  filteredContracts: any[];
  filteredContacts: any[];
  filteredProjects: any[];
  relationsCompanies: boolean;
  relationsContracts: boolean;
  relationsContacts: boolean;
  relationsProjects: boolean;
  currentProjects: any[];
  currentContracts: any[];
  currentContacts: any[];
  currentCompanies: any[];
  showCurrentProjects: boolean;
  showCurrentContracts: boolean;
  showCurrentContacts: boolean;
  showCurrentCompanies: boolean;
  entityType: string;
  filter:string;
  sortedAZ: boolean;
  changed: boolean;
}

export class FormRelations extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      hidden: false,
      loading: false,
      loadingEntities: false,
      projects: [],
      contracts: [],
      contacts: [],
      companies: [],
      filteredCompanies: [],
      filteredContacts: [],
      filteredContracts: [],
      filteredProjects: [],
      relationsCompanies: true,
      relationsContracts: true,
      relationsContacts: true,
      relationsProjects: true,
      currentProjects: [],
      currentContracts: [],
      currentContacts: [],
      currentCompanies: [],
      showCurrentProjects: true,
      showCurrentContracts: true,
      showCurrentContacts: true,
      showCurrentCompanies: true,
      entityType: 'projects',
      filter: '',
      sortedAZ: true,
      changed: false,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    let id = this.props.item.id || '';
    if (id.startsWith('Company-')) this.setState({entityType: 'projects', loadingEntities: true});
    else if (id.startsWith('Contact-') || id.startsWith('User') || id.startsWith('U')) this.setState({entityType: 'projects', loadingEntities: true});
    else if (id.startsWith('Project-')) this.setState({entityType: 'contracts', loadingEntities: true});
    else if (id.startsWith('Contract-')) this.setState({entityType: 'projects', loadingEntities: true});
    !id.startsWith('Company-') && userService.getCompanies().then((response)=>{
      let data = response.data.sort((a,b) => {return (a.name || '').localeCompare(b.name || '')});
      this._isMounted && this.setState({
        companies: data, 
        filteredCompanies: data, 
        currentCompanies: this.props.item.companies || data.filter(item=>{return this.props.item.companyIds.includes(item.id)}),
        loadingEntities: false})
    }).catch(()=>{
      this._isMounted && this.setState({loadingEntities: false})
    })
    !id.startsWith('Contact-') && !id.startsWith('User-') && !id.startsWith('U') && userService.getContacts().then((response)=>{
      let data = response.data.sort((a,b) => {return ((a.firstName || '') + ' ' + (a.lastName ? ' ' + a.lastName : '')).localeCompare((b.firstName || '') + (b.lastName ? ' ' + b.lastName : ''))});
      this._isMounted && this.setState({
        contacts: data, 
        filteredContacts: data,
        currentContacts: this.props.item.contacts || data.filter(item=>{return this.props.item.contactIds.includes(item.id)}).map((item) => {return {...item, byDefault: (item.id === this.props.item.createdByUserId || item.id === this.props.item.savedByUserId )}}),
        loadingEntities: false})
    }).catch(()=>{
      this._isMounted && this.setState({loadingEntities: false})
    })
    !id.startsWith('Project-') && userService.getProjects().then((response)=>{
      let data = response.data.sort((a,b) => {return (a.name || '').localeCompare(b.name || '')});
      this._isMounted && this.setState({
        projects: data, 
        filteredProjects: data, 
        currentProjects: this.props.item.projects || data.filter(item=>{return this.props.item.projectIds.includes(item.id)}),
        loadingEntities: false})
    }).catch(()=>{
      this._isMounted && this.setState({loadingEntities: false})
    })
    !id.startsWith('Contract-') && userService.getContracts().then((response)=>{
      let data = response.data.sort((a,b) => {return (a.name || '').localeCompare(b.name || '')});
      this._isMounted && this.setState({
        contracts: data, 
        filteredContracts: data, 
        currentContracts: this.props.item.contracts || data.filter(item=>{return this.props.item.contractIds.includes(item.id)}),
        loadingEntities: false})
    }).catch(()=>{
      this._isMounted && this.setState({loadingEntities: false})
    })

  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      this.props.callback({showFormRelations: false})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      this._isMounted && this.setState({hidden:true})
    }
  }

  private _submit() {
    const {loading} = this.state;
    if (loading) return;
    this._isMounted && this.setState({loading: true})

    const {currentProjects, currentContacts, currentCompanies, currentContracts} = this.state;
    let id = this.props.item.id || '';

    if (id.startsWith('Company-')) {
      let relations = {
        contactIds: currentContacts ? currentContacts.map(item=>{return item.id}) : [],
        contractIds: currentContracts ? currentContracts.map(item=>{return item.id}) : [],
        projectIds: currentProjects ? currentProjects.map(item=>{return item.id}) : []
      }
      userService.updateCompanyRelations(this.props.item.id, relations).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else if (id.startsWith('Contact-') || id.startsWith('User-') || id.startsWith('U')) {
      let relations = {
        companyIds: currentCompanies ? currentCompanies.map(item=>{return item.id}) : [],
        contractIds: currentContracts ? currentContracts.map(item=>{return item.id}) : [],
        projectIds: currentProjects ? currentProjects.map(item=>{return item.id}) : []
      }
      userService.updateContactRelations(this.props.item.id, relations).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else if (id.startsWith('Contract-')) {
      let relations = {
        companyIds: currentCompanies ? currentCompanies.map(item=>{return item.id}) : [],
        contactIds: currentContacts ? currentContacts.map(item=>{return item.id}) : [],
        projectIds: currentProjects ? currentProjects.map(item=>{return item.id}) : []
      }
      userService.updateContractRelations(this.props.item.id, relations).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else if (id.startsWith('Email-')) {
      let relations = {
        companyIds: currentCompanies ? currentCompanies.map(item=>{return item.id}) : [],
        contactIds: currentContacts ? currentContacts.map(item=>{return item.id}) : [],
        contractIds: currentContracts ? currentContracts.map(item=>{return item.id}) : [],
        projectIds: currentProjects ? currentProjects.map(item=>{return item.id}) : [],
      }
      userService.updateEmailRelations(this.props.item.id, relations).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else if (id.startsWith('Note-')) {
      let relations = {
        companyIds: currentCompanies ? currentCompanies.map(item=>{return item.id}) : [],
        contactIds: currentContacts ? currentContacts.map(item=>{return item.id}) : [],
        contractIds: currentContracts ? currentContracts.map(item=>{return item.id}) : [],
        projectIds: currentProjects ? currentProjects.map(item=>{return item.id}) : [],
      }
      userService.updateNoteRelations(this.props.item.id, relations).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else if (id.startsWith('Conversation-')) {
      let relations = {
        companyIds: currentCompanies ? currentCompanies.map(item=>{return item.id}) : [],
        contactIds: currentContacts ? currentContacts.map(item=>{return item.id}) : [],
        contractIds: currentContracts ? currentContracts.map(item=>{return item.id}) : [],
        projectIds: currentProjects ? currentProjects.map(item=>{return item.id}) : [],
      }
      userService.updateConversationRelations(this.props.item.id, relations).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else if (id.startsWith('Task-')) {
      let relations = {
        companyIds: currentCompanies ? currentCompanies.map(item=>{return item.id}) : [],
        contactIds: currentContacts ? currentContacts.map(item=>{return item.id}) : [],
        contractIds: currentContracts ? currentContracts.map(item=>{return item.id}) : [],
        projectIds: currentProjects ? currentProjects.map(item=>{return item.id}) : [],
      }
      userService.updateTaskRelations(this.props.item.id, relations).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else {
      let relations = {
        companyIds: currentCompanies ? currentCompanies.map(item=>{return item.id}) : [],
        contactIds: currentContacts ? currentContacts.map(item=>{return item.id}) : [],
        contractIds: currentContracts ? currentContracts.map(item=>{return item.id}) : [],
      }
      userService.updateProjectRelations(this.props.item.id, relations).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    }
  }

  private sortItems(items, asc:boolean) {
    if (asc) {
      return items.slice(0).sort((a: any, b: any) => {
        if (a.name < b.name || (a.firstName + ' ' + a.lastName) < (b.firstName + ' ' + b.lastName)) return -1;
        if (a.name > b.name || (a.firstName + ' ' + a.lastName) > (b.firstName + ' ' + b.lastName)) return 1;
        else return 0;
      })
    } else {
      return items.slice(0).sort((a: any, b: any) => {
        if (a.name < b.name || (a.firstName + ' ' + a.lastName) < (b.firstName + ' ' + b.lastName)) return 1;
        if (a.name > b.name || (a.firstName + ' ' + a.lastName) > (b.firstName + ' ' + b.lastName)) return -1;
        else return 0;
      })
    }

  }

  private addTo(item: any, type: string) {
    if(!this.isSelected(item,type)) {
      if (type === 'Project') this.setState({currentProjects: this.sortItems([...this.state.currentProjects, item], this.state.sortedAZ), showCurrentProjects: true, changed: true})
      else if (type === 'Company') this.setState({currentCompanies: this.sortItems([...this.state.currentCompanies, item], this.state.sortedAZ), showCurrentCompanies: true, changed: true})
      else if (type === 'Contact') this.setState({currentContacts: this.sortItems([...this.state.currentContacts, item], this.state.sortedAZ), showCurrentContacts: true, changed: true})
      else if (type === 'Contract') this.setState({currentContracts: this.sortItems([...this.state.currentContracts, item], this.state.sortedAZ), showCurrentContracts: true, changed: true})
      else this.setState({currentContacts: this.sortItems([...this.state.currentContacts, item], this.state.sortedAZ), showCurrentContacts: true, changed: true})
    }
  }

  private removeFrom(item: any, type: string) {
    if (type === 'Project') this.setState({currentProjects: this.state.currentProjects.filter((i)=>{return i.id !== item.id}), changed: true})
    else if (type === 'Company') this.setState({currentCompanies: this.state.currentCompanies.filter((i)=>{return i.id !== item.id}), changed: true})
    else if (type === 'Contact') this.setState({currentContacts: this.state.currentContacts.filter((i)=>{return i.id !== item.id}), changed: true})
    else if (type === 'Contract') this.setState({currentContracts: this.state.currentContracts.filter((i)=>{return i.id !== item.id}), changed: true})
    else this.setState({currentContacts: this.state.currentContacts.filter((i)=>{return i.id !== item.id}), changed: true})
  }

  private isSelected(item: any, type: string) {
    if (type === 'Project') return this.state.currentProjects.filter((i)=>{return i.id === item.id}).length > 0
    else if (type === 'Company') return this.state.currentCompanies.filter((i)=>{return i.id === item.id}).length > 0
    else if (type === 'Contact') return this.state.currentContacts.filter((i)=>{return i.id === item.id}).length > 0
    else if (type === 'Contract') return this.state.currentContracts.filter((i)=>{return i.id === item.id}).length > 0
    else return this.state.currentContacts.filter((i)=>{return i.id === item.id}).length > 0
  }

  private changeEntityType(type: string) {
    this.filter('')
    this.setState({entityType: type})
  }

  private filter(filter:string) {
    const {entityType} = this.state;
    let value = filter.toLowerCase()
    if (value && value.length > 0) {
      if (entityType === 'projects') {
        let items = this.state.projects.filter((item)=>{
          return item.name.toLowerCase().includes(value)
        })
        this._isMounted && this.setState({filteredProjects: items, filter: value})
      } else if (entityType === 'companies') {
        let items = this.state.companies.filter((item)=>{
          return item.name.toLowerCase().includes(value)
        })
        this._isMounted && this.setState({filteredCompanies: items, filter: value})
      } else if (entityType === 'contacts') {
        let items = this.state.contacts.filter((item)=>{
          let name = item.firstName + ' ' + item.lastName;
          return name.toLowerCase().includes(value)
        })
        this._isMounted && this.setState({filteredContacts: items, filter: value})
      } else if (entityType === 'contracts') {
        let items = this.state.contracts.filter((item)=>{
          return item.name.toLowerCase().includes(value)
        })
        this._isMounted && this.setState({filteredContracts: items, filter: value})
      }
    } else {
      if (entityType === 'projects') {
        this._isMounted && this.setState({filteredProjects: this.state.projects, filter: value})
      } else if (entityType === 'companies') {
        this._isMounted && this.setState({filteredCompanies: this.state.companies, filter: value})
      } else if (entityType === 'contacts') {
        this._isMounted && this.setState({filteredContacts: this.state.contacts, filter: value})
      } else if (entityType === 'contracts') {
        this._isMounted && this.setState({filteredContracts: this.state.contracts, filter: value})
      }
    }
  }

  private toggleAll() {
    if (this.state.showCurrentCompanies && this.state.showCurrentContacts && this.state.showCurrentProjects) {
      this._isMounted && this.setState({showCurrentCompanies: false, showCurrentContacts: false, showCurrentProjects: false, showCurrentContracts: false})
    } else {
      this._isMounted && this.setState({showCurrentCompanies: true, showCurrentContacts: true, showCurrentProjects: true, showCurrentContracts: true})
    }
  }

  private toggleSort() {
    this._isMounted && this.setState({
      sortedAZ: !this.state.sortedAZ,
      currentCompanies: this.sortItems(this.state.currentCompanies, !this.state.sortedAZ),
      currentContacts: this.sortItems(this.state.currentContacts, !this.state.sortedAZ),
      currentContracts: this.sortItems(this.state.currentContracts, !this.state.sortedAZ),
      currentProjects: this.sortItems(this.state.currentProjects, !this.state.sortedAZ),
    })
  }

  public render() {

    const { hidden, loading } = this.state;

    const columns:any[] = [{
      key: 'name',
      name: this.state.entityType === 'projects' ? i18n.t('app:projects') : this.state.entityType === 'contracts' ? i18n.t('app:contracts') : this.state.entityType === 'companies' ? i18n.t('app:companies') : i18n.t('app:contacts'),
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-2 align-items-center" style={{userSelect:'none'}} onClick={()=>{this.addTo(item,item.id.split('-')[0])}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '28px'}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name || (item.firstName + ' ' + item.lastName)}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}>
                {item.domain ? 
                  <Link target='_blank' rel="noreferrer noopener"  href={'https://'+item.domain}>{item.domain}</Link> 
                : item.emailAddresses && item.emailAddresses[0] ?
                  <Link target='_blank' rel="noreferrer noopener"  href={'mailto:'+(item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email)}>
                    {item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email}
                  </Link>
                : item.id.startsWith('Project-') ? <Link target='_blank' rel="noreferrer noopener"  href={'/projects/'+item.id}>Link</Link>
                : item.id.startsWith('Contract-') ? <Link target='_blank' rel="noreferrer noopener"  href={'/contracts/'+item.id}>Link</Link>
                : null }
                </p>
            </div>
            { !this.isSelected(item,item.id.split('-')[0]) ? 
             <TooltipHost
              content={i18n.t('app:add')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
              >
              <IconButton style={{color: 'rgb(0, 120, 212)'}} className='ml-auto' iconProps={{iconName: 'Add'}} />
            </TooltipHost>
            :
            <IconButton disabled style={{opacity: 1, background: 'transparent', color: 'green', width:'30px', fontSize:18, textAlign: 'center', verticalAlign: 'middle'}} className='ml-auto icon-disabled' iconProps={{iconName:'CheckMark'}} />
          }
          </div>
        </>)
      }
    }]

    const columnsCompanies:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentCompanies: !this.state.showCurrentCompanies})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentCompanies ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:companies') + ' (' + this.state.currentCompanies.length + ')' }</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}><Link target='_blank' rel="noreferrer noopener"  href={'https://'+item.domain}>{item.domain}</Link></p>
            </div>
            {item && !item.byDefault && <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,item.id.split('-')[0])}}/>
            </TooltipHost>}
            {item && item.byDefault && <TooltipHost
                content={i18n.t('app:byDefault')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto', width: '32px'}}}
            >
                <Icon iconName='Lock' style={{marginLeft: '10px'}}/>
            </TooltipHost>}
          </div>
        </>)
      }
    }]
  
    const columnsContacts:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentContacts: !this.state.showCurrentContacts})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentContacts ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:contacts') + ' (' + this.state.currentContacts.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.firstName + ' ' + item.lastName}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}><Link target='_blank' rel="noreferrer noopener"  href={'mailto:'+(item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email)}>{item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email}</Link></p>
            </div>
            {item && !item.byDefault && <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,item.id.split('-')[0])}}/>
            </TooltipHost>}
            {item && item.byDefault && <TooltipHost
                content={i18n.t('app:byDefault')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto', width: '32px'}}}
            >
                <Icon iconName='Lock' style={{marginLeft: '10px'}}/>
            </TooltipHost>}
          </div>
        </>)
      }
    }]
  
    const columnsProjects:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentProjects: !this.state.showCurrentProjects})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:remove')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentProjects ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:projects') + ' (' + this.state.currentProjects.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
          <div className='d-flex align-items-center'>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}><Link target='_blank' href={'/projects/'+item.id}>Link</Link></p>
            </div>
            </div>
            {item && !item.byDefault && <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,item.id.split('-')[0])}}/>
            </TooltipHost>}
            {item && item.byDefault && <TooltipHost
                content={i18n.t('app:byDefault')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto', width: '32px'}}}
            >
                <Icon iconName='Lock' style={{marginLeft: '10px'}}/>
            </TooltipHost>}
          </div>
        </>)
      }
    }]

    const columnsContracts:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentContracts: !this.state.showCurrentContracts})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:remove')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentContracts ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:contracts') + ' (' + this.state.currentContracts.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
          <div className='d-flex align-items-center'>
            { item && item.image ?
                <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: item.id.startsWith('Project-') ? '2px' : item.id.startsWith('Company-') ? '0' : '24px'}}/>
              :
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            }
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}><Link target='_blank' href={'/contracts/'+item.id}>Link</Link></p>
            </div>
            </div>
            {item && !item.byDefault && <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,item.id.split('-')[0])}}/>
            </TooltipHost>}
            {item && item.byDefault && <TooltipHost
                content={i18n.t('app:byDefault')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto', width: '32px'}}}
            >
                <Icon iconName='Lock' style={{marginLeft: '10px'}}/>
            </TooltipHost>}
          </div>
        </>)
      }
    }]

    const onRenderDetailsHeaderCompanies = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center'>
          {defaultRender!({
            ...props
          })}
          <TooltipHost
            content={i18n.t('app:showCompanies')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {position: 'relative', left: '-40px'} }}
          >
            <IconButton className='panel-icon' iconProps={{iconName: 'OpenPaneMirrored'}} styles={{icon: {fontSize: 20}}} onClick={()=>{this.changeEntityType('companies'); this.setState({showCurrentCompanies: true})}}/>
          </TooltipHost>
        </div>
      );
    }

    const onRenderDetailsHeaderContacts = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center'>
          {defaultRender!({
            ...props
          })}
          <TooltipHost
            content={i18n.t('app:showContacts')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {position: 'relative', left: '-40px'} }}
          >
            <IconButton className='panel-icon' iconProps={{iconName: 'OpenPaneMirrored'}} styles={{icon: {fontSize: 20}}} onClick={()=>{this.changeEntityType('contacts'); this.setState({showCurrentContacts: true})}}/>
          </TooltipHost>
        </div>
      );
    }

    const onRenderDetailsHeaderProjects =(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center' style={{backgroundColor: 'white'}}>
          {defaultRender!({
            ...props
          })}
          <TooltipHost
            content={i18n.t('app:showProjects')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {position: 'relative', left: '-40px'} }}
          >
            <IconButton className='panel-icon' iconProps={{iconName: 'OpenPaneMirrored'}} styles={{icon: {fontSize: 20}}} onClick={()=>{this.changeEntityType('projects'); this.setState({showCurrentProjects: true})}}/>
          </TooltipHost>
        </div>
      );
    }

    const onRenderDetailsHeaderContracts =(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center' style={{backgroundColor: 'white'}}>
          {defaultRender!({
            ...props
          })}
          <TooltipHost
            content={i18n.t('app:showContracts')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {position: 'relative', left: '-40px'} }}
          >
            <IconButton className='panel-icon' iconProps={{iconName: 'OpenPaneMirrored'}} styles={{icon: {fontSize: 20}}} onClick={()=>{this.changeEntityType('contracts'); this.setState({showCurrentContracts: true})}}/>
          </TooltipHost>
        </div>
      );
    }

    let id = this.props.item.id || '';

    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Relations'  src={process.env.PUBLIC_URL + '/icons/relations.svg'} />{i18n.t('app:editRelations')}</div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: 'none', background: '#f2f2f2'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog full-height",
            onDismiss: ()=> {this.close()},
          }}
        >
          <div className='' style={{borderBottom: '1px solid #bfbfbf', position: 'sticky', top: 0, zIndex: 1}}></div>
          <FluentProvider theme={webLightTheme} className='w-100' style={{display: 'flex', height: '100%', backgroundColor: '#f2f2f2'}}>
            <div className='w-50'>
              <div className='d-flex align-items-center py-4 px-2' style={{height: '32px', borderBottom: '1px solid #bfbfbf'}}>
                <TooltipHost
                  content={i18n.t('app:expand/collapseAll')}
                  calloutProps={{ gapSpace: 0 }}
                  styles={{root: {marginRight: '8px'}}}
                >
                  <IconButton
                    onClick={()=>this.toggleAll()} 
                    className='expand-icon' 
                    iconProps={{iconName: this.state.showCurrentCompanies && this.state.showCurrentContacts && this.state.showCurrentProjects && this.state.showCurrentContracts ? 'CollapseContent' : 'ExploreContent', styles: {root: {fontSize: 18, color: 'rgb(0, 120, 212)'}}}} 
                  />
                </TooltipHost>
                <span style={{fontWeight: 600}}>{i18n.t('app:relations')}</span>
                <TooltipHost
                  key={'sort'}
                  content={i18n.t('app:switchOrder')}
                  id={'sort'}
                  calloutProps={{ gapSpace: 0 }}
                  styles={{root: {marginLeft: 'auto'}}}
                >
                  <ActionButton
                    onClick={()=>this.toggleSort()} 
                    className='expand-icon'
                    text={i18n.t('app:ByName')}
                    iconProps={{iconName: this.state.sortedAZ ? 'SortDown' : 'SortUp'}}
                    style={{height: '32px'}} 
                  />
                </TooltipHost>
              </div>
              <ScrollablePane className='relation-list w-50' style={{top: '50px'}}>
                {!id.startsWith('Project-') && <>
                  <ShimmeredDetailsList
                    className={this.state.currentProjects.length === 0 || !this.state.showCurrentProjects ? 'mt-4 height-fix' : 'my-4'}
                    items={this.state.showCurrentProjects ? this.state.currentProjects : []}
                    columns={columnsProjects}
                    onRenderDetailsHeader={onRenderDetailsHeaderProjects}
                    selectionMode={SelectionMode.none}
                    selectionPreservedOnEmptyClick={true}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    ariaLabelForShimmer="Content is being fetched"
                    enterModalSelectionOnTouch={true}
                    shimmerLines={12}
                    constrainMode={ConstrainMode.unconstrained}
                  />
                  {this.state.currentProjects.length === 0 && this.state.showCurrentProjects && <div className='d-flex mb-4' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                    <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
                  </div>}
                </>}
                {!id.startsWith('Company-') && <>
                <ShimmeredDetailsList
                  className={this.state.currentCompanies.length === 0 || !this.state.showCurrentCompanies ? 'mt-4 height-fix' : 'mt-4'}
                  items={this.state.showCurrentCompanies ? this.state.currentCompanies : []}
                  columns={columnsCompanies}
                  onRenderDetailsHeader={onRenderDetailsHeaderCompanies}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={true}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  ariaLabelForShimmer="Content is being fetched"
                  enterModalSelectionOnTouch={true}
                  shimmerLines={12}
                  constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentCompanies.length === 0 && this.state.showCurrentCompanies && <div className='d-flex' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                  <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
                </div>}
                </>}
                {!id.startsWith('Contact-') && !id.startsWith('User-') && !id.startsWith('U') && <>
                <ShimmeredDetailsList
                  className={this.state.currentContacts.length === 0 || !this.state.showCurrentContacts ? 'mt-4 height-fix' : 'my-4'}
                  items={this.state.showCurrentContacts ? this.state.currentContacts : []}
                  columns={columnsContacts}
                  onRenderDetailsHeader={onRenderDetailsHeaderContacts}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={true}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  ariaLabelForShimmer="Content is being fetched"
                  enterModalSelectionOnTouch={true}
                  shimmerLines={12}
                  constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentContacts.length === 0 && this.state.showCurrentContacts && <div className='d-flex mb-4' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                  <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
                </div>}
                </>}
                {!id.startsWith('Contract-') && <>
                <ShimmeredDetailsList
                  className={this.state.currentContracts.length === 0 || !this.state.showCurrentContracts ? 'mt-4 height-fix' : 'my-4'}
                  items={this.state.showCurrentContracts ? this.state.currentContracts : []}
                  columns={columnsContracts}
                  onRenderDetailsHeader={onRenderDetailsHeaderContracts}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={true}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  ariaLabelForShimmer="Content is being fetched"
                  enterModalSelectionOnTouch={true}
                  shimmerLines={12}
                  constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentContracts.length === 0 && this.state.showCurrentContracts && <div className='d-flex mb-4' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                  <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
                </div>}
                </>}
              </ScrollablePane>
            </div>
            <div className='w-50' style={{borderLeft: '1px solid #bfbfbf'}}>
              <div className='d-flex align-items-center' style={{borderBottom: '1px solid #bfbfbf', height: '49px'}}>
                <Pivot selectedKey={this.state.entityType} className='text-center mx-auto' onLinkClick={(item:any)=>{this.changeEntityType(item.props.itemKey)}}>
                  {!id.startsWith('Project-') &&  <PivotItem itemKey="projects" headerText="Projects"/>}
                  {!id.startsWith('Contract-') && <PivotItem itemKey="contracts" headerText="Contracts"/>}
                  {!id.startsWith('Contact-') && !id.startsWith('User-') && !id.startsWith('U') && <PivotItem itemKey="contacts" headerText="Contacts"/>}
                  {!id.startsWith('Company-') && <PivotItem itemKey="companies" headerText="Companies"/>}
                </Pivot>
              </div>
              {/*<div className='d-flex align-items-center py-2 px-4' style={{borderBottom: '1px solid #bfbfbf'}}>
                <Icon className='mr-2' iconName={this.state.entityType === 'projects' ? 'Work' : this.state.entityType === 'companies' ? 'CityNext2' : 'Contact'}/><span style={{fontWeight: 600}}>
                  {this.state.entityType === 'projects' ? i18n.t('app:addingProjects') : this.state.entityType === 'companies' ? i18n.t('app:addingCompanies') : i18n.t('app:addingContacts')}
                  </span>
              </div>*/}
              <div className='d-flex mt-4 mx-3'>
                <Input 
                  contentBefore={<Icon iconName='Filter' />}
                  className='filter-entities flex-grow-1' 
                  placeholder={this.state.entityType === 'projects' ? i18n.t('app:filterProjects') : this.state.entityType === 'contracts' ? i18n.t('app:filterContracts') : this.state.entityType === 'companies' ? i18n.t('app:filterCompanies') : i18n.t('app:filterContacts')}
                  value={this.state.filter} 
                  onChange={(e,data)=>{this.filter(data.value)}}
                />
                {/*<ActionButton 
                  className='action-border ml-auto' 
                  style={{height: '32px'}} 
                  iconProps={{iconName:'Add'}} 
                  title={this.state.entityType === 'projects' ? i18n.t('app:newProject') : this.state.entityType === 'contracts' ? i18n.t('app:newContract') : this.state.entityType === 'companies' ? i18n.t('app:newCompany') : i18n.t('app:newContact')}
                  text={this.state.entityType === 'projects' ? i18n.t('app:newProject') : this.state.entityType === 'contracts' ? i18n.t('app:newContract') : this.state.entityType === 'companies' ? i18n.t('app:newCompany') : i18n.t('app:newContact')}
                />*/}
              </div>
              <ScrollablePane className='relation-list w-50' style={{left:'50%', top: '130px'}}>
              <ShimmeredDetailsList
                className=''
                items={this.state.entityType === 'projects' ? this.state.filteredProjects : this.state.entityType === 'contracts' ? this.state.filteredContracts : this.state.entityType === 'companies' ? this.state.filteredCompanies : this.state.filteredContacts}
                columns={columns}
                //compact={true}
                //onRenderDetailsHeader={onRenderDetailsHeader}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                shimmerLines={12}
                constrainMode={ConstrainMode.unconstrained}
              />
              </ScrollablePane>
            </div>
          </FluentProvider>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=>{this.close()}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton onClick={()=>{this._submit()}} text={i18n.t('app:save')} disabled={loading} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }
}
