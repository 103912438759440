import * as React from 'react';
import { IDetailsHeaderProps, IDetailsList, IRenderFunction, ScrollablePane, ScrollbarVisibility, Sticky, StickyPositionType, Selection, Spinner, ContextualMenu, IContextualMenuProps, DirectionalHint, IColumnReorderOptions, IColumn, DetailsList, CheckboxVisibility, Icon, ActionButton, SpinnerSize, IDetailsListCheckboxProps, MessageBar, MessageBarType} from '@fluentui/react';
import { history } from '../_helpers';
import i18n from '../i18n';
import { Resizable } from 're-resizable';
import { Link, Persona } from '@fluentui/react-components';

export interface Props {
  match: {
    url: string
  },
  location: any,
  searchType: string,
  userData: any,
  page: string,
  callbackFunction: any,
  loading: boolean
  
}

export interface ListExplorerState {
  webUpdateAvailable: boolean;
  userData?: any;
  repoData?: any;
  goBack: number;
  selFolder: any;
  items: any;
  selectedTags: any;
  groups: any;
  contextMenuProps: any;
  searchWord: string;
  columns: any[];
  panelWidth: any;
  alertMessage: any;
}

export class ListExplorer extends React.Component<Props, ListExplorerState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private _root:any = React.createRef<IDetailsList>();  

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props


    this._selection = new Selection({
      onSelectionChanged: () => {
        var selItem: any = this._selection.getSelection()[this._selection.getSelection().length - 1]

        setTimeout(() => {
          if (selItem && this._selection.getSelectedCount() === 1) {
            this._isMounted && this.props.callbackFunction({selItem: selItem, selection : this._selection.getSelection()});
          } else if (selItem && this._selection.getSelectedCount() > 1) {
            this._isMounted && this.props.callbackFunction({selItem: null, selection : this._selection.getSelection()});
          } else if (this._selection.getSelectedCount() === 0) {
            this._isMounted && this.props.callbackFunction({selItem: null, selection : this._selection.getSelection()});
          }
        }, 50)
      }
    });

    var panelWidth = "240px"
    if (typeof(localStorage.getItem("sidebar3Prefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("sidebar3Prefs") || "[]");
      panelWidth = panelPrefs.width
    }

    this.state = {
      webUpdateAvailable: false,
      userData: null,
      repoData: null,
      goBack: 0,
      selFolder: null,
      items: this.props.items,
      selectedTags: [],
      groups: [],
      contextMenuProps: undefined,
      searchWord: '',
      columns: this.props.columns,
      panelWidth: panelWidth,
      alertMessage: null
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this.handleSearch();

    var listEl = document.getElementsByClassName("listEl")[0]
    if(listEl) listEl.addEventListener("keydown", this._handleListKeyDown);

  }
  
  public componentDidUpdate(prevProps:any) {
    if(this.props.items !== prevProps.items) {
      this.handleSearch()
        setTimeout(() => {
          var selItem: any = this._selection.getSelection()[this._selection.getSelection().length - 1]
          if (selItem && this._selection.getSelectedCount() === 1) {
            this._isMounted && this.props.callbackFunction({selItem: selItem, selection : this._selection.getSelection()});
          } else if (selItem && this._selection.getSelectedCount() > 1) {
            this._isMounted && this.props.callbackFunction({selItem: null, selection : this._selection.getSelection()});
          } else if (this._selection.getSelectedCount() === 0) {
            this._isMounted && this.props.callbackFunction({selItem: null, selection : this._selection.getSelection()});
          }
        }, 50)
    }
    if (this.props.columns !== prevProps.columns) {
      this._isMounted && this.setState({columns: this.props.columns})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;

    var listEl = document.getElementsByClassName("listEl")[0]
    if(listEl) listEl.removeEventListener("keydown", this._handleListKeyDown, false);
  }

  onMessageReceived(event:any) {
    var data:any = event.data;

      if (data && data.msg === "webUpdateAvailable") {
        this._isMounted && this.setState({webUpdateAvailable: true})
      }
  }

  private handleSearch() {
    var newItems:any = this.props.items;
    this._isMounted && this.setState({items: newItems.slice(0)})
    if (this.props.page === "clientsExplorer"){ 
      let groups = _getGroups(newItems, 'company')
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "milestonesExplorer"){ 
      let groups = _getGroups(newItems, 'stageId', 'stageName') 
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "pipelinesExplorer"){ 
      let groups = _getGroups(newItems, 'pipelineId', 'pipelineName') 
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "projectsExplorer"){ 
      this._isMounted && this.setState({groups:null})
    }
    else if (this.props.page === "companiesExplorer" || this.props.page === "companiesPreview"){ 
      this._isMounted && this.setState({groups:null})
    }
    else if (this.props.page === "companiesExplorerTrash"){ 
      this._isMounted && this.setState({groups:null})
    }
    else if (this.props.page === "contactsExplorer"){ 
      this._isMounted && this.setState({groups:null})
    }
    else if (this.props.page === "contactsExplorerTrash"){ 
      this._isMounted && this.setState({groups:null})
    }
    else if (this.props.page === "tasks") {
      let groups = _getGroups(newItems, 'taskImportance', 'taskImportanceName')
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "pipelines") {
      let groups = _getGroups(newItems, 'pipelineId', 'pipelineName')
      this._selection.setItems(newItems, false);
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "customFieldsProject"
    || this.props.page === "customFieldsContact"
    || this.props.page === "customFieldsContract"
    || this.props.page === "customFieldsCompany"){ 
      this._isMounted && this.setState({groups:null})
    }
    else if (this.props.page === "permissions") {
      let groups = _getGroups(newItems, 'permissionRole', 'permissionRoleName')
      this._isMounted && this.setState({groups:groups})
    }
    else { 
      this._isMounted && this.setState({groups:null})
    }
  }

  private _onItemInvoked(item) {
    if (this.props.page === "companiesExplorer" || this.props.page === "companiesPreview") {
      history.push('/companies/' + item.id)
    } else if (this.props.page === "projectsExplorer") {
      history.push('/projects/' + item.id)
    } else if (this.props.page === "clientsExplorer") {
      history.push('/projects/' + item.id)
    } else if (this.props.page === "milestonesExplorer") {
      history.push('/projects/' + item.id)
    } else if (this.props.page === "boardExplorer") {
      history.push('/projects/' + item.id)
    } else if (this.props.page === "pipelinesExplorer") {
      history.push('/projects/' + item.id)
    } else if (this.props.page === "contactsExplorer" || this.props.page === "contactsExplorerTrash") {
      history.push('/contacts/' + item.id)
    } else if (this.props.page === "contractsExplorer" || this.props.page === "contractsDashboard" || this.props.page === "contractsExplorerTrash") {
      history.push('/contracts/' + item.id)
    } else if (this.props.page === "tasks") {
      //history.push('/tasks/preview?selItem=' + item.key)
      history.push('/tasks/id/' + item.id)
    } else if (this.props.page === "conversations") {
      //history.push('/conversations/preview?selItem=' + item.key)
      history.push('/conversations/id/' + item.id)
    } else if (this.props.page === "notes") {
      //history.push('/notes/preview?selItem=' + item.key)
      history.push('/notes/id/' + item.id)
    } else if (this.props.page === "emails") {
      //history.push('/emails/preview?selItem=' + item.key)
      history.push('/emails/id/' + item.id)
    } else if (this.props.page === 'search') {
      if (item.id.startsWith('Project-')) history.push('/projects/' + item.id)
      else if (item.id.startsWith('Contract-')) history.push('/contracts/' + item.id)
      else if (item.id.startsWith('Contact-') || item.id.startsWith('User-') || item.id.startsWith('U')) history.push('/contacts/' + item.id)
      else if (item.id.startsWith('Company-')) history.push('/companies/' + item.id)
      else if (item.id.startsWith('Note-')) history.push('/notes/id/' + item.id)
      else if (item.id.startsWith('Email-')) history.push('/emails/id/' + item.id)
      else if (item.id.startsWith('Conversation-')) history.push('/conversations/id/' + item.id)
      else if (item.id.startsWith('Task-')) history.push('/tasks/id/' + item.id)
    }
    else if (item.id.startsWith('View-')) {
      this.goToView(item)
    }
  }

  private _handleListKeyDown = (event) => {
    if (event.repeat) { return }
    const selection:any[] = this._selection.getSelection();
    const selectedCount = selection.length;
    let onePipelineSelected = false;
    if(this.props.page === "pipelines") {
      let differentPipeline = selection.filter((item) => {return item.pipelineId !== selection[0].pipelineId})
      onePipelineSelected = differentPipeline.length === 0
    }
    const inProjectsPage = this.props.page === 'projectsExplorer' || this.props.page === 'clientsExplorer'  || this.props.page === 'milestonesExplorer'  || this.props.page === 'pipelinesExplorer'  || this.props.page === 'boardExplorer'
    const inCustomFieldsPage = this.props.page === 'customFieldsProject' || this.props.page === 'customFieldsContact' || this.props.page === 'customFieldsContract' || this.props.page === 'customFieldsCompany'
    switch( event.code ) {
      case 'Delete': // Delete
        if(event.target.className.indexOf("ms-DetailsRow") !== -1 || event.target.className.indexOf("ms-GroupHeader") !== -1) {
          if (this.props.page === "pipelines" && selectedCount > 0  && onePipelineSelected) this.props.headerRef._showDeletePipelineForm()
          if (inProjectsPage && selectedCount > 0) this.props.headerRef._showDeleteProjectForm()
          if (this.props.page === "contractsExplorer" && selectedCount > 0) this.props.headerRef._showDeleteContractForm()
          if (this.props.page === "contactsExplorer" && selectedCount > 0) this.props.headerRef._showDeleteContactForm()
          if ((this.props.page === "companiesExplorer" || this.props.page === "companiesPreview") && selectedCount > 0) this.props.headerRef._showDeleteCompanyForm()
          if ((this.props.page === "views") && selectedCount === 1) this.props.headerRef._showFormViewDelete(selection[0])
          if (inCustomFieldsPage && selectedCount > 0) this.props.headerRef._showDeleteCustomFieldsForm()
        }
      break;
      case 'F2': //Edit
      if(event.target.className.indexOf("ms-DetailsRow") !== -1 || event.target.className.indexOf("ms-GroupHeader") !== -1) {
        if (this.props.page === "pipelines" && selectedCount > 0  && onePipelineSelected) this.props.headerRef._showEditPipelineForm()
          if (inProjectsPage && selectedCount === 1) this.props.headerRef._showEditProjectForm()
          if (this.props.page === "contractsExplorer" && selectedCount === 1) this.props.headerRef._showEditContractForm()
          if (this.props.page === "contactsExplorer" && selectedCount === 1) this.props.headerRef._showEditContactForm()
          if ((this.props.page === "companiesExplorer" || this.props.page === "companiesPreview") && selectedCount === 1) this.props.headerRef._showEditCompanyForm()
          if ((this.props.page === "views") && selectedCount === 1) this.props.headerRef._showFormViewEdit(selection[0])
          if (inCustomFieldsPage && selectedCount === 1) this.props.headerRef._showEditCustomFieldsForm()
          
        }
      break;
      case 'KeyN': //Create
        if (event.altKey) {
          if (this.props.page === "pipelines") this.props.headerRef._showNewPipelineForm()
          if (inProjectsPage) this.props.headerRef._showNewProjectForm()
          if (this.props.page === "contractsExplorer") this.props.headerRef._showNewContractForm()
          if (this.props.page === "contactsExplorer") this.props.headerRef._showNewContactForm()
          if (this.props.page === "companiesExplorer" || this.props.page === "companiesPreview") this.props.headerRef._showNewCompanyForm()
          if (inCustomFieldsPage && selectedCount === 1) this.props.headerRef._showCustomFieldsForm()
        } else if (!event.metaKey
          && !event.ctrlKey
          && !event.altKey
          && !event.shiftKey
          && event.returnValue ) {
            let key = event.key || "";
            this._handleListFocusOnKeyDown(key)
        }
        break;
      default:
        if(!event.metaKey
          && !event.ctrlKey
          && !event.altKey
          && !event.shiftKey
          && event.returnValue ) {
            let key = event.key || "";
            this._handleListFocusOnKeyDown(key)
          }
      break;
    }
  }

  private _pressedKey: any = undefined;
  private _focusItems: any = null;
  private _focusItemIndex: number = 0;

  private _handleListFocusOnKeyDown(key) {
    let currentWord:string;
    if(key.length === 1) {
      if (this._pressedKey === key && this._focusItems && this._focusItems.length > 0 && this.state.searchWord.length === 0) {
        currentWord = this.state.searchWord + key;
        this._isMounted && this.setState({searchWord: this.state.searchWord + key}, ()=> {
          this._focusItemIndex = this._focusItemIndex + 1
          var focusItem = this._focusItems[this._focusItemIndex]
          if(focusItem) {
            this.setListFocus(focusItem)
          } else {
            this._focusItemIndex = 0
            focusItem = this._focusItems[this._focusItemIndex]
            if(focusItem !== this._selection.getSelection()[0]) this.setListFocus(focusItem)
          }
        })
      } else {
        if (this.state.searchWord.length === 0) this._pressedKey = key;
        currentWord = this.state.searchWord + key;
        this._isMounted && this.setState({searchWord: this.state.searchWord + key}, ()=> {
          if (this.state.searchWord.length === 1)  {
            this._focusItems = this.state.items && this.state.items.filter(item => {
              return item && (item.name || '').toLowerCase().startsWith(this.state.searchWord.toLowerCase())
            })
          }
          this._focusItemIndex = 0
    
          let focusItem = this.state.items && this.state.items.filter(item => {
            return item && (item.name || '').toLowerCase().startsWith(this.state.searchWord.toLowerCase())
          })[this._focusItemIndex]
          if(focusItem !== this._selection.getSelection()[0]) this.setListFocus(focusItem)
        })
      }
      setTimeout(()=>{
        if (this.state.searchWord === currentWord) {
          this._isMounted && this.setState({searchWord: ''})
        }
      },1000)
    }
    else {
      this._isMounted && this.setState({searchWord: ''})
    }
    
  }

  private setListFocus(focusItem) {
    //Reselect multiple times to correctly select item in long lists
    if(focusItem) {
      setTimeout(() => {
        this._selection.setAllSelected(false)
        this._selection.setKeySelected(focusItem.key, true, true)
        var selIndex = this._selection.getSelectedIndices()[this._selection.getSelectedIndices().length-1]
        this._selection.setIndexSelected(selIndex, true, true);
        this._root.current && this._root.current.focusIndex(selIndex);
        setTimeout(() => {
          var selIndex = this._selection.getSelectedIndices()[this._selection.getSelectedIndices().length-1]
          this._selection.setIndexSelected(selIndex, true, true);
          this._root.current && this._root.current.focusIndex(selIndex);
        },200)
      },1)
    }
  }

  private _onContextMenu(item, index, ev: MouseEvent | any) {
    ev.preventDefault();

    const inProjectsPage = this.props.page === 'projectsExplorer' || this.props.page === 'clientsExplorer'  || this.props.page === 'milestonesExplorer'  || this.props.page === 'pipelinesExplorer'  || this.props.page === 'boardExplorer'
    const inCustomFieldsPage = this.props.page === 'customFieldsProject' || this.props.page === 'customFieldsContact' || this.props.page === 'customFieldsContract' || this.props.page === 'customFieldsCompany'

    const selection:any[] = this._selection.getSelection();
    const selectedCount = selection.length;
    
    let onePipelineSelected = false;
    if(this.props.page === "pipelines") {
      let differentPipeline = selection.filter((item:any) => {return item.pipelineId !== selection[0].pipelineId})
      onePipelineSelected = differentPipeline.length === 0
    }

    const contextMenuProps: IContextualMenuProps = {
      target: ev.target as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      items: this.props.page === 'pipelines' ? [
        {
          key:'editPipeline',
          name:i18n.t('app:editPipeline'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          disabled: selectedCount < 1  || !onePipelineSelected,
          onClick: () => this.props.headerRef && this.props.headerRef._showEditPipelineForm(),
        },
        {
          key:'deletePipeline',
          name:i18n.t('app:deletePipeline'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount < 1  || !onePipelineSelected,
          onClick: () => this.props.headerRef && this.props.headerRef._showDeletePipelineForm(),
        }
      ] : inProjectsPage ? [
        {
          key:'open',
          name:i18n.t('app:open'),
          iconProps: { iconName: 'OpenFile' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this._onItemInvoked(item),
        },
        {
          key:'updateProjectStatus',
          name: selection && selection[0].status === "OPEN" ? i18n.t('app:setStatusClose') : i18n.t('app:setStatusOpen'),
          iconProps: { iconName: selection && selection[0].status === "OPEN" ? 'StatusCircleInner' : 'StatusCircleRing' },
          className:'blue-icon',
          disabled: selectedCount < 1,
          onClick: () => this.props.headerRef && this.props.headerRef._updateProjectStatus(selection, selection[0].status === "OPEN"),
        },
        {
          key: 'editProjectStage',
          text: i18n.t('app:editProjectStage'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          onClick: () => this.props.headerRef && this.props.headerRef._showEditProjectStageForm(),
          hidden: !inProjectsPage,
          disabled: selectedCount < 1
        },
        {
          key:'editProject',
          name:i18n.t('app:editProject'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.props.headerRef && this.props.headerRef._showEditProjectForm(),
        },
        {
          key:'deleteProject',
          name:i18n.t('app:delete'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount < 1,
          onClick: () => this.props.headerRef && this.props.headerRef._showDeleteProjectForm(),
        }
      ] : this.props.page === 'contractsExplorer' ? [
        {
          key:'open',
          name:i18n.t('app:open'),
          iconProps: { iconName: 'OpenFile' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this._onItemInvoked(item),
        },
        {
          key:'editContract',
          name:i18n.t('app:editContract'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.props.headerRef && this.props.headerRef._showEditContractForm(),
        },
        {
          key:'deleteContract',
          name:i18n.t('app:delete'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount < 1,
          onClick: () => this.props.headerRef && this.props.headerRef._showDeleteContractForm(),
        }
      ] : this.props.page === 'contactsExplorer' ? [
        {
          key:'open',
          name:i18n.t('app:open'),
          iconProps: { iconName: 'OpenFile' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this._onItemInvoked(item),
        },
        {
          key:'editContact',
          name:i18n.t('app:editContact'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.props.headerRef && this.props.headerRef._showEditContactForm(),
        },
        {
          key:'deleteContact',
          name:i18n.t('app:delete'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount < 1,
          onClick: () => this.props.headerRef && this.props.headerRef._showDeleteContactForm(),
        }
      ] : this.props.page === 'companiesExplorer' || this.props.page === 'companiesPreview' ? [
        {
          key:'open',
          name:i18n.t('app:open'),
          iconProps: { iconName: 'OpenFile' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this._onItemInvoked(item),
        },
        {
          key:'editCompany',
          name:i18n.t('app:editCompany'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.props.headerRef && this.props.headerRef._showEditCompanyForm(),
        },
        {
          key:'deleteCompany',
          name:i18n.t('app:delete'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount < 1,
          onClick: () => this.props.headerRef && this.props.headerRef._showDeleteCompanyForm(),
        }
      ] : this.props.page === 'views' ? [
        {
          key: 'addToQuickAccess',
          text: i18n.t('app:addToQuickAccess'),
          iconProps: { iconName: 'Add' },
          className:'blue-icon',
          onClick: () => selection && this.props.headerRef && this.props.headerRef._addViewToQuickAccess(selection[0]),
          hidden: selection && (this.props.headerRef && this.props.headerRef.isInQuickAccess(selection) || selection[0].allQuickAccess),
          disabled: selectedCount !== 1
        },
        {
          key: 'removeFromQuickAccess',
          text: i18n.t('app:removeFromQuickAccess'),
          iconProps: { iconName: 'Remove' },
          className:'grey-icon',
          onClick: () => selection && this.props.headerRef && this.props.headerRef._removeViewFromQuickAccess(selection[0]),
          hidden: selection && (this.props.headerRef && !this.props.headerRef.isInQuickAccess(selection) || selection[0].allQuickAccess),
          disabled: selectedCount !== 1
        },
        {
          key: 'editView',
          text: i18n.t('app:editView'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          onClick: () => selection && this.props.headerRef && this.props.headerRef._showFormViewEdit(selection[0]),
          disabled: selectedCount !== 1
        },
        {
          key: 'deleteView',
          text: i18n.t('app:deleteView'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          onClick: () => selection && this.props.headerRef && this.props.headerRef._showFormViewDelete(selection[0]),
          disabled: selectedCount !== 1
        },
       ] : inCustomFieldsPage ? [
          {
            key:'editCustomField',
            name:i18n.t('app:editCustomField'),
            iconProps: { iconName: 'Edit' },
            className:'blue-icon',
            disabled: selectedCount !== 1,
            onClick: () => this.props.headerRef && this.props.headerRef._showEditCustomFieldsForm(),
          },
          {
            key:'deleteCustomField',
            name:i18n.t('app:deleteCustomField'),
            iconProps: { iconName: 'Delete' },
            className:'grey-icon',
            disabled: selectedCount !== 1,
            onClick: () => this.props.headerRef && this.props.headerRef._showDeleteCustomFieldsForm(),
          }
      ] : [],
      onDismiss: () => {
        this.setState({
          contextMenuProps: undefined
        });
      }
    }
    
    this._isMounted && this.setState({contextMenuProps: contextMenuProps})
    return true;

  }

  private _saveResizeState() {
    const { panelWidth } = this.state;
    
    var prefs = {
      height: 'auto',
      width: panelWidth
    }

    localStorage.setItem("sidebar3Prefs", JSON.stringify(prefs))
  }

  public render() {

    const handleColumnReorder = (draggedIndex: number, targetIndex: number) => {
      const draggedItems = this.state.columns[draggedIndex];
      const newColumns: IColumn[] = [...this.state.columns];
      // insert before the dropped item
      newColumns.splice(draggedIndex, 1);
      newColumns.splice(targetIndex, 0, draggedItems);
      this.setState({columns: newColumns})
    };
  
    const getColumnReorderOptions = (): IColumnReorderOptions => {
      return {
        frozenColumnCountFromStart: 1,
        handleColumnReorder: handleColumnReorder,
      };
    };

    return(
      (this.props.page || '').includes('Preview') ?
        <Resizable
          className='h-100'
          style={{overflowX: 'hidden'}}
          size={{ width: this.state.panelWidth, height: 'auto' }}
          onResizeStop={(e, direction, ref, d) => {
            var currentWidth = this.state.panelWidth.match(/\d+/)[0];
            this._isMounted && this.setState({
              panelWidth: +currentWidth + +d.width + "px"
            }, () => {
              this._saveResizeState();
            });
          }}
          enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
          minWidth={254}
          maxWidth={400}
          handleStyles= {{
            right: {
              "right": "-6px",
              "width": "12px",
              "zIndex": 1
            }
          }}
          handleClasses={{"right": "resize-handle"}}
        >
          <nav className="tree second list-sidebar">
            <ScrollablePane style={{width: 'calc(100% - 1px)', background: '#f2f2f2'}} scrollbarVisibility={ScrollbarVisibility.always}>
            <DetailsList
              componentRef={this._root}
              className='list-sidebar main-entity'
              items={this.state.items}
              checkboxVisibility={CheckboxVisibility.always}
              onRenderCheckbox={checkboxColumn}
              onRenderMissingItem={()=>{console.log('hello')}}
              onRenderDetailsHeader={onRenderDetailsHeader}
              onItemInvoked={(item) => this._onItemInvoked(item)}
              onItemContextMenu={(item,index,ev) => this._onContextMenu(item,index,ev)}
              columns={[
                {
                  key: 'title',
                  name: 'Title',
                  fieldName: 'title',
                  minWidth: 1,
                  isRowHeader: true,
                  data: 'string',
                  isPadded: true,
                  onRender: (item: any) => {
                    return (
                      <>
                        <div className='d-flex align-items-center'  style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                          <Persona style={{position: 'absolute', left: '-35px', display: (this._selection.getSelection()[0] && this._selection.getSelection()[0].key === item.key) || this._selection.getSelection().length > 1  ? 'none' : 'block'}} className={'activity-icon ' + (item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : '')} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}} onClick={()=>{history.push('/contacts/'+item.id)}}/>
                          <div className='align-items-center ml-1 flex-grow-1' style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <div className='d-flex'>
                              <span className='span-title mr-2 py-1' style={{textOverflow: 'ellipsis', overflowX: 'hidden'}}>{item.name}</span>
                            </div>
                            <div className='d-flex'>
                              {item.domain && <Link style={{fontWeight: 400, fontSize: 12}} target={'_blank'} rel="noreferrer" href={'https://'+item.domain.replace('https://','')} title={ item.domain }>{ item.domain }</Link> }
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  },
                  onRenderHeader: (item: any)=>{
                    return(
                      <div className='d-flex align-items-center w-100'>
                        <span style={{marginRight: '28px'}}> 
                        {i18n.t('app:name')}
                        </span>
                        <ActionButton 
                          className='ml-auto mr-3 px-0' 
                          style={{color: 'rgb(50, 49, 48)'}} 
                          iconProps={{iconName:'SortLines'}}
                          text={i18n.t('By name')} 
                          menuProps={{items: [
                            {
                              key: 'byDate',
                              text: 'By date',
                              iconProps: {iconName:'Calendar'},
                              //onClick:()=>this.sortItems('date')
                            },
                            {
                              key: 'byName',
                              text: 'By title',
                              iconProps: {iconName:'HalfAlpha'},
                              //onClick:()=>this.sortItems('title')
                            }
                          ]}}
                        />
                      </div>
                  )},

                },
              ]}
              selection={ this._selection }
              setKey="set"
            />
            </ScrollablePane>
            { this.state.contextMenuProps ?
              <ContextualMenu {...this.state.contextMenuProps} />
            : null }
            {this.state.alertMessage ?
              <div id="alertMessage">
                <MessageBar
                  messageBarType={this.state.alertMessage.type}
                  isMultiline={true}
                  dismissButtonAriaLabel="Close"
                  onDismiss={() => this.setState({alertMessage: null})}
                >
                  <div dangerouslySetInnerHTML={{__html: this.state.alertMessage.text}}></div>
                </MessageBar>
              </div>
            : null }
            {(!this.state.items || this.state.items.length === 0) && !this.props.loading && <div style={{opacity: .8}} className='mt-5 pt-2 text-center'>No tasks</div> }
            {(!this.state.items || this.state.items.length === 0) && this.props.loading && <Spinner className='mt-5 pt-2 text-center' size={SpinnerSize.medium}/> }
          </nav>
        </Resizable>
        :
        <div className={this.props.page === 'search' ? 'd-flex flex-grow-1 flex-column' : ''}>
          {this.props.page !== 'search' && this.props.page !== 'contractsDashboard' && <div className='d-flex flex-grow-1 align-items-center' style={{height: '44px', borderBottom: '1px solid #f2f2f2'}}>
            <span className='ml-3'>
              {i18n.t('app:mainView')}
            </span>
          </div>}
          <ScrollablePane className={this.props.page === 'search' ? 'flex-grow-1' : ''} style={{marginTop: this.props.page === 'search' ? '0px' : this.props.page === 'contractsDashboard' ? '260px' : '44px', position: this.props.page === 'search' ? 'relative' : 'absolute'}} scrollbarVisibility={ScrollbarVisibility.auto}>
            
            <DetailsList 
              componentRef={this._root}
              className={"listEl"}
              items={this.state.items}
              compact={this.props.isCompactMode}
              columns={this.state.columns}
              onRenderDetailsHeader={onRenderDetailsHeader}
              selectionMode={this.props.selectionMode}
              setKey="set"
              layoutMode={this.props.layoutMode}
              isHeaderVisible={true}
              selection={this._selection}
              selectionPreservedOnEmptyClick={false}
              onItemInvoked={(item) => this._onItemInvoked(item)}
              onItemContextMenu={(item,index,ev) => this._onContextMenu(item,index,ev)}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              //shimmerLines={12}
              constrainMode={this.props.constrainMode}
              listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
              groups={this.state.groups}
              columnReorderOptions={this.state.groups ? undefined : getColumnReorderOptions()}
              onRenderMissingItem={()=>{document.location.search && this.props.searchMore()}}
            />
            { this.state.items && this.state.items.length === 0 && this.props.loading ? <Spinner/> : this.state.items && this.state.items.length === 0 ? <p className='text-center mt-2'>Empty</p> : null }
            { this.props.loadingMore && <Spinner/>}
          </ScrollablePane>

          { this.state.contextMenuProps ?
            <ContextualMenu {...this.state.contextMenuProps} />
          : null }

          {this.state.alertMessage ?
            <div id="alertMessage">
              <MessageBar
                messageBarType={this.state.alertMessage.type}
                isMultiline={true}
                dismissButtonAriaLabel="Close"
                onDismiss={() => this.setState({alertMessage: null})}
              >
                <div dangerouslySetInnerHTML={{__html: this.state.alertMessage.text}}></div>
              </MessageBar>
            </div>
          : null }

          <div style={{opacity: this.state.searchWord.length > 0 ? 1 : 0 ,position: 'absolute', bottom: '16px', width: '100%', transitionDuration: '.2s'}}>
            <div style={{display: this.state.searchWord.length > 0 ? 'flex' : 'none', width: '200px', height: '80px', margin: 'auto', padding:'10px', borderRadius: '2px', color:'white', fontSize: '20px', fontWeight: 600, backgroundColor: 'rgba(0,0,0,0.6)'}}>
              <div className='m-auto' style={{textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{this.state.searchWord.toLowerCase()}</div>
            </div>
          </div>

        </div>
    )
  }

  private goToView(item) {
    if(item.itemType === "PROJECT") {
      if (item.page === 'projectsExplorer') history.push("/projects/list" + item.query)
      else if (item.page === 'clientsExplorer') history.push("/projects/clients" + item.query)
      else if (item.page === 'pipelinesExplorer') history.push("/projects/pipeline" + item.query)
      else if (item.page === 'milestonesExplorer') history.push("/projects/stages" + item.query)
      else if (item.page === 'boardExplorer') history.push("/projects/board" + item.query)
    }
    else if(item.itemType === "CONTACT") {
      if (item.page === 'contactsExplorer') history.push("/contacts" + item.query)
    }
    else if(item.itemType === "COMPANY") {
      if (item.page === 'companiesExplorer') history.push("/companies" + item.query)
    }
    else if(item.itemType === "CONTRACT") {
      if(item.page === "contractsExplorer") history.push("/contracts/list" + item.query)
    }
    else if(item.itemType === "NOTE") {
      if (item.page === 'notes') history.push("/notes" + item.query)
      else if (item.page === 'notesPreview') history.push("/notes/preview" + item.query)
    }
    else if(item.itemType === "CONVERSATION") {
      if (item.page === 'conversations') history.push("/conversations" + item.query)
      else if (item.page === 'conversationsPreview') history.push("/conversations/preview" + item.query)
    }
    else if(item.itemType === "TASK") {
      if (item.page === 'tasks') history.push("/tasks" + item.query)
      else if (item.page === 'tasksPreview') history.push("/tasks/preview" + item.query)
    }
    else if(item.itemType === "EMAIL") {
      if (item.page === 'emails') history.push("/emails" + item.query)
      if (item.page === 'emailsPreview') history.push("/emails/preview" + item.query)
    }
  }

}

function _getGroups(items, param:string = 'company', n:string = '') {
  var groups:any = []
  for (var i=0; i<items.length;i++) {
    if (param === 'stageId') {
      let parameter = items[i] && items[i].stage && items[i].stage.id !== 'null' ? items[i].stage.id : i18n.t('app:noStage');
      if (groups.filter(function(e) { return e.key === parameter+'-group'; }).length > 0) {
        groups.filter(function(e) { return e.key === parameter+'-group'; })[0].count++;
      }
      else {
        items[i] && groups.push({key: parameter+'-group', name: items[i].stage && items[i].stage.id !== 'null' ? items[i].stage.name : i18n.t('app:noStage'), startIndex: i, count: 1})
      }
    } else if (items[i]) {
      let parameter = items[i][param];
      let name = items[i][n]
      if (groups.filter(function(e) { return e.key === parameter+'-group'; }).length > 0) {
        groups.filter(function(e) { return e.key === parameter+'-group'; })[0].count++;
      }
      else {
        groups.push({key: parameter+'-group', name: name || parameter, startIndex: i, count: 1})
      }
    }
  }
  return groups
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}

function checkboxColumn(props: any, defaultRender?: IRenderFunction<IDetailsListCheckboxProps>): JSX.Element {
  return  (
    <div className='d-flex align-items-center justifty-content-center h-100 w-100'>
      {defaultRender!({
        ...props
      })}
    </div>
  )
}