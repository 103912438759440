import * as React from 'react';
import "moment/min/locales";
import Moment from 'moment';
import { ThemeProvider, TooltipHost } from '@fluentui/react';
import { DetailsListLayoutMode, Selection, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { Icon } from '@fluentui/react/lib/Icon';
import i18n from "i18next";
import { Sidebar } from '../_components/Sidebar';
import { Header } from '../_components/Header';
import { DetailsPanel } from '../_components/DetailsPanel';
import { ListExplorer } from '../_components/ListExplorer';
import { history } from '../_helpers';
import { useParams } from 'react-router-dom';
import { userService } from '../_services';
import { Link, Persona } from '@fluentui/react-components';

declare global {
  interface Window {
      require: any;
      sreader: any;
  }
}

export interface Props {
  appPlatform: string,
  database: any,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  pusherMessage: any,
  page: string,
  userData: any,
  foldersList: any,
  newFolder: any,
  repoUsers: any,
  params:any
}

export interface State {
  userData?: any;
  goBack: number;
  isLoading: boolean;
  blankLoading: boolean;
  savingTag: boolean;
  savingMessage: boolean;
  syncStatus: any;
  columns: IColumn[];
  items: any[];
  fullItemsResponse: any;
  showFilters: boolean;
  filterByNameText: string;
  fullItemsResponseByName: any;
  filterByTypeText: string;
  fullItemsResponseByType: any;
  draggedItem: any;
  selItem: any;
  showRightPanel: boolean;
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  newTag: string;
  newMessage: string;
  newFolder: string;
  savingFolder: boolean;
  showTagBulkDialog: boolean;
  hideDialog: boolean;
  activeTab: string;
  language: string;
  filterToggle: boolean;
  toolbarHeight: number;
  searchQuery: string;
  breadcrumbPath: any[];
  pipelineId: string;
  pipeline:any;
  pipelines:any[];
  customProperties: any[];
  expireIn30: any;
  expireIn60: any;
  expireIn90: any;
  expired: any;
  loadingMore: boolean;
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class ContractsPage extends React.Component<Props, State> {
  private _isMounted: boolean;
  private _selection: Selection;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();
  private loadingMore:boolean = false;
  private page:number = 1;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._selection = new Selection({
      onSelectionChanged: () => {
        this._isMounted && this.setState({
          selItem: this._selection.getSelection()[this._selection.getSelection().length - 1] || []
        });
      }
    });

    const query = new URLSearchParams(document.location.search)

    this.state = {
      userData: null,
      goBack: 0,
      isLoading: true,
      blankLoading: false,
      savingTag: false,
      savingMessage: false,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      items: [],
      showFilters: false,
      fullItemsResponse: [],
      filterByNameText: "",
      fullItemsResponseByName: [],
      filterByTypeText: "",
      fullItemsResponseByType: [],
      draggedItem: null,
      columns: [],
      selItem: null,
      showRightPanel: localStorage.detailsPanelOpen ? localStorage.detailsPanelOpen === 'true' : true,
      selectionDetails: '',
      isModalSelection: false,
      isCompactMode: true,
      newTag: '',
      newMessage: '',
      newFolder: '',
      savingFolder: false,
      showTagBulkDialog: false,
      hideDialog: true,
      activeTab: 'details',
      language: i18n.language,
      filterToggle: true,
      toolbarHeight: 89,
      searchQuery: document.location.search,
      breadcrumbPath: [{
        text: i18n.t('app:contracts'),
        key: 'contracts',
        onClick: ()=>{history.push('/contracts')}
      }],
      pipelineId: query.get('pipelineId') || 'all',
      pipeline: null,
      pipelines: [],
      customProperties: [],
      expireIn30: null,
      expireIn60: null,
      expireIn90: null,
      expired: null,
      loadingMore: false,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this.props.userData && this.getCurrentContent();
    const query = new URLSearchParams(document.location.search)
    const fileId = query.get('createContract')
    if (fileId && fileId.length > 0) {
      this.headerRef._showNewContractForm()
    }
    if (this.props.pusher && this.props.userData && this._isMounted) {
      console.log('Pusher connect: Workflow-'+ this.props.userData.repository.id +"-CONTRACT")
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTRACT"})
      this.props.pusher.connect()
    }
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    
    if(this.state.language !== i18n.language) {
      this.setState({language: i18n.language});
    }

    if (prevProps.pusher !== this.props.pusher) {
      if (this.props.pusher && this.props.userData && this._isMounted) {
        console.log('Pusher connect: Workflow-'+ this.props.userData.repository.id +"-CONTRACT")
        this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CONTRACT"})
        this.props.pusher.connect()
      }
    }

    if( this.props.userData !== prevProps.userData 
    || this.props.page !== prevProps.page 
    || this.state.searchQuery !== document.location.search) {
      let query = new URLSearchParams(document.location.search);
      let pipeline = query.get('pipelineId') || 'all';
      
      this._isMounted && this.setState({
        pipelineId: pipeline,
        searchQuery: document.location.search
      });
      let items:any = this.state.items;

      const params = new URLSearchParams(document.location.search);
      params.forEach((value, key)=>{
        let field:string = params.get(key) || "";
        let fields:string[] = field.toLowerCase().split(';');
        if (field !== 'noFilter') {
          if (field === 'all') {
            items = items.filter((item:any)=>{
              return (item[key] && item[key] !== '')
            })
          } else if (field === 'none') {
            items = items.filter((item:any)=>{
              return (!item[key] || item[key] === '')
            })
          } else if (fields && fields.length > 0) {
            items = items.filter((item:any)=>{
              return (item[key] && fields.includes(item[key].toLowerCase()))
            })
          }
        }
      })

      this.getCurrentContent();
    }

    if (prevProps.pusherMessage !== this.props.pusherMessage) {
      const { pusherMessage } = this.props;
      if(pusherMessage && pusherMessage.message) {
        console.log(pusherMessage)
        /*if (pusherMessage.message.itemType === 'CONTRACT') {
          if (pusherMessage.message.action === 'DELETE') {
            let items = this.state.items;
            items = items.filter((item)=>{return item.id !== pusherMessage.message.itemId})
            this._isMounted && this.setState({items: items})
          } else if (pusherMessage.message.action === 'UPDATE') {
            this.getCurrentContent(true);
          }
          else if (pusherMessage.message.action === 'CREATE') {
            this.getCurrentContent(true);
          }
        }*/
      }
    }

  }

  public componentWillUnmount() {
    this._isMounted = false;
    if (this.props.pusher) {
      this.props.pusher.disconnect();
    }
  }

  private getCurrentContent(hidden = false) {
    this.page = 0;
    this._isMounted && this._getColumns();
    if (this.props.userData ) {
      this._isMounted && this.setState({
        isLoading: true,
        items: hidden ? this.state.items : [],
        syncStatus: {
          message: "Syncing",
          type: "info",
          icon: "spinner"
        },
        searchQuery: document.location.search
      })
      let newItems: any = [];
      let query = document.location.search;
      const getContracts = query && query.length > 0 ?
      //When query is present we do a paginated search
      userService.searchContracts(query).then((response)=>{
        newItems = response.data.content.map((item:any)=>{return {...item, key: item.id}});
        newItems = response.data.last ? newItems :[...newItems, null];
        //newItems = this.filterByRelations(newItems);
      }).catch(()=>{
        this._isMounted && this.setState({
          isLoading: false,
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          }
        });
      })
      : 
      //When there is no query we get all entities
      userService.getContracts().then((response)=>{
        newItems = _copyAndSort(response.data.filter((item)=>{return !item.trash}), 'name', false);
        //newItems = this.filterByRelations(newItems);
      }).catch(()=>{
        this._isMounted && this.setState({
          isLoading: false,
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          }
        });
      })

      let customProperties:any[] = [];
      const getCustomProperties = userService.getCustomProperties('','CONTRACT').then((res) =>{
        customProperties = res.data.map(item=>{return {...item, id: item.id, name: item.name, position: item.customPropertyTypes.find(x=>{return x.entityType === 'CONTRACT'}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === 'CONTRACT'}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}});
        customProperties = customProperties.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
      }).catch(()=>{
        this._isMounted && this.setState({
          isLoading: false,
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          }
        })
      });

      let pipeline: any;
      let pipelines: any[];
      const getPipelines = userService.getPipelines().then(response => {
        pipelines = response.data
        pipeline = pipelines.filter((item)=>{
          return item.id === this.state.pipelineId ;
        })[0]
      }).catch((error)=>{
        console.log(error)
        this._isMounted && this.setState({
          isLoading: false,
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          }
        });
      })

      Promise.all([getContracts, getPipelines, getCustomProperties]).then((res) => {
        const currentDate = new Date().getTime();
        let expireIn30:any[] = [];
        let expireIn60:any[] = [];
        let expireIn90:any[] = [];
        let expired:any[] = [];
        for (let x in newItems) {
          let expirationDate = new Date(newItems[x].expirationDate).getTime() - currentDate
          if (expirationDate <= 0) { //Expired
            expired.push(newItems[x])
          }
          if (expirationDate <= 2592000000 && expirationDate > 0) { //30 days
            expireIn30.push(newItems[x])
          }
          if (expirationDate <= 5184000000 && expirationDate > 0) { //90 days
            expireIn60.push(newItems[x])
          }
          if (expirationDate <= 7776000000 && expirationDate > 0) { //90 days
            expireIn90.push(newItems[x])
          }
          for (let i in pipelines) {
            for (let j in pipelines[i].stages) {
              if (newItems[x].stage && pipelines[i].stages[j].id === newItems[x].stage.id) {
                newItems[x].pipelineId = pipelines[i].id
                newItems[x].pipelineName = pipelines[i].name
              }
            }
          }
          if (!newItems[x].stage) {
            newItems[x].pipelineId = 'null'
            newItems[x].pipelineName = i18n.t('app:noPipeline')
            newItems[x].stage = {id: 'null'}
          }
        }
        newItems = document.location.search.includes('?expired=true') ? _copyAndSort(expired, 'name', false) : document.location.search.includes('?expiredIn=90') ? _copyAndSort(expireIn90, 'name', false) : document.location.search.includes('?expiredIn=60') ? _copyAndSort(expireIn60, 'name', false) : document.location.search.includes('?expiredIn=30') ? _copyAndSort(expireIn30, 'name', false) : _copyAndSort(newItems, 'name', false);
        this._isMounted && this.setState({
          items: newItems.map((item:any)=>{return {...item, key: item.id}}),
          customProperties: customProperties,
          expireIn30: expireIn30,
          expireIn60: expireIn60,
          expireIn90: expireIn90,
          pipeline: pipeline,
          pipelines: pipelines,
          expired: expired,
          isLoading: false,
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          }
        },()=>{
          this._isMounted && this.setState({
            selItem: this._selection.getSelection()[this._selection.getSelection().length - 1] || null
          });
        });
        this._sortBySavedColumn(newItems);
      })
    }
  }

  private getMoreContent() {

    const { loadingMore } = this.state;

    if (!loadingMore && !this.loadingMore) {
      this.loadingMore = true;
      setTimeout(()=>{
        this._isMounted && this.setState({
          loadingMore: true,
        })
        if (document.location.search && document.location.search.length > 0) {
          this.page += 1;
          userService.searchContracts(document.location.search + '&page=' + this.page).then(async (response:any)=>{
            let oldItems = this.state.items;
            oldItems = oldItems.filter((item)=> {return item});
            var newItems:any = oldItems.concat(response.data.content)
            newItems = response.data.last ? newItems :[...newItems, null];
            const currentDate = new Date().getTime();
            let expireIn30:any[] = [];
            let expireIn60:any[] = [];
            let expireIn90:any[] = [];
            let expired:any[] = [];
            for (let i in newItems) {
              let expirationDate = new Date(newItems[i].expirationDate).getTime() - currentDate
              if (expirationDate <= 0) { //Expired
                expired.push(newItems[i])
              }
              if (expirationDate <= 2592000000 && expirationDate > 0) { //30 days
                expireIn30.push(newItems[i])
              }
              if (expirationDate <= 5184000000 && expirationDate > 0) { //90 days
                expireIn60.push(newItems[i])
              }
              if (expirationDate <= 7776000000 && expirationDate > 0) { //90 days
                expireIn90.push(newItems[i])
              }
            }
            this.loadingMore = false;

            this._isMounted && this.setState({
              items: newItems.map((item:any)=>{return item ? {...item, key: item.id} : null }),
              expireIn30: expireIn30,
              expireIn60: expireIn60,
              expireIn90: expireIn90,
              expired: expired,
              isLoading: false,
              loadingMore: false,
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            });
          }).catch(()=>{
            this.loadingMore = false;
            this._isMounted && this.setState({
              loadingMore: false,
            });
          })
        } else {
          this.loadingMore = false;
          this._isMounted && this.setState({
            loadingMore: false,
          });
        }
      },50)
    }
  }

  callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private filterByRelations(items) {
    const params = new URLSearchParams(document.location.search);
    let project = params.get('projectIds') || ''
    let contact = params.get('contactIds') || ''
    let company = params.get('companyIds') || ''
    let newItems = items;
    if (project === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.projectIds) {
          if (item.projectIds[i]) return true
        }
        return false
      })
    } else if (project === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.projectIds || item.projectIds.length === 0) return true
        return false
      })
    } else if (project && project.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.projectIds) {
          if (project.includes(item.projectIds[i])) return true
        }
        return false
      })
    }
    if (contact === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.contactIds) {
          if (item.contactIds[i]) return true
        }
        return false
      })
    } else if (contact === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.contactIds || item.contactIds.length === 0) return true
        return false
      })
    } else if (contact && contact.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.contactIds) {
          if (contact.includes(item.contactIds[i])) return true
        }
        return false
      })
    }
    if (company === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.companyIds) {
          if (item.companyIds[i]) return true
        }
        return false
      })
    } else if (company === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.companyIds || item.companyIds.length === 0) return true
        return false
      })
    } else if (company && company.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.companyIds) {
          if (company.includes(item.companyIds[i])) return true
        }
        return false
      })
    }
    return newItems;
  }

  public render() {

    const { columns, items, customProperties, isCompactMode, searchQuery, showRightPanel } = this.state;

    return (
      <ThemeProvider>
            <Header
              ref={instance => { this.headerRef = instance; }}
              userData={ this.props.userData }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              page={this.props.page}
              sidebarRef={ this.sidebarRef }
              breadcrumbPath= {this.state.breadcrumbPath}
              syncStatus = {this.state.syncStatus}
              items={items}
              callbackFunction={this.callbackFunction}
              showRightPanel={showRightPanel}
              selItem={ this.state.selItem }
              getCurrentContent={this.getCurrentContent.bind(this)}
              customProperties={customProperties}
            />
          <div className="content-wrap d-flex flex-row" >
            <Sidebar
              ref={(instance:any) => { this.sidebarRef = instance; }}
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              userData={ this.props.userData }
              page={this.props.page}
              items={items}
              searchQuery={searchQuery}
              callbackFunction={this.callbackFunction}
              pipelineId={this.state.pipelineId}
              pipeline={ this.state.pipeline }
              pipelines={this.state.pipelines}
            /> 

            <div className="list mr-auto flex-grow-1">
              {this.props.page === 'contractsDashboard' &&
                <div>
                <div className='d-flex flex-grow-1 align-items-center' style={{height: '44px', borderBottom: '1px solid #f2f2f2'}}>
                  <span className='ml-3'>
                    {i18n.t('app:mainView')}
                  </span>
                </div>
                <div className='row my-2 mx-2' style={{flexWrap: 'nowrap', overflow: 'auto'}}>
                  <div className='dashboard-card mx-2 my-2'>
                    <p>Expiring in 90 days</p>
                    <div className='contract-number yellow-circle'><span>{this.state.expireIn90 ? this.state.expireIn90.length : ''}</span></div>
                    <Link onClick={()=>{history.push('/contracts/list?expiredIn=90')}}>View list</Link>
                  </div>
                  <div className='dashboard-card mx-2 my-2'>
                    <p>Expiring in 60 days</p>
                    <div className='contract-number orange-circle'><span>{this.state.expireIn60 ? this.state.expireIn60.length : ''}</span></div>
                    <Link onClick={()=>{history.push('/contracts/list?expiredIn=60')}}>View list</Link>
                  </div>
                  <div className='dashboard-card mx-2 my-2'>
                    <p>Expiring in 30 days</p>
                    <div className='contract-number red-circle'><span>{this.state.expireIn30 ? this.state.expireIn30.length : ''}</span></div>
                    <Link onClick={()=>{history.push('/contracts/list?expiredIn=30')}}>View list</Link>
                  </div>
                  <div className='dashboard-card mx-2 my-2'>
                    <p>Total contracts</p>
                    <div className='contract-number green-circle'><span>{items && items.length > 0 ? items.length : items && !this.state.isLoading ? items.length : ''}</span></div>
                    <Link className='mt-auto' onClick={()=>{history.push('/contracts/list')}}>View all contracts</Link>
                  </div>
                  <div className='dashboard-card mx-2 my-2'>
                    <div className='d-flex align-items-center' style={{marginBottom: '14px'}}>
                    <Icon className='mr-2' style={{color: 'firebrick', marginTop: '2px'}} iconName='Info'/><span style={{color: 'firebrick'}}>Contracts expired</span>
                    </div>
                    <div className='contract-number darkred-circle'><span>{this.state.expired ? this.state.expired.length : ''}</span></div>
                    <Link className='mt-auto' onClick={()=>{history.push('/contracts/list?expired=true')}}>Review expired contracts</Link>
                  </div>
                </div>
              </div>
            } 
              <ListExplorer
                ref={instance => { this.listExplorerRef = instance; }}
                appPlatform={ this.props.appPlatform }
                isOnline={ this.props.isOnline }
                maintenance={ this.props.maintenance }
                socket={this.props.socket}
                pusher={this.props.pusher}
                userData={this.props.userData}
                foldersList={ this.props.foldersList }
                items={items}
                selItem={ this.state.selItem }
                isCompactMode={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                constrainMode={ConstrainMode.unconstrained}
                headerRef={ this.headerRef }
                detailsPanelRef={ this.detailsPanelRef }
                callbackFunction={ this.callbackFunction }
                page={this.props.page}
                sidebarRef={ this.sidebarRef }
                pipelineId={this.state.pipelineId}
                loading={this.state.isLoading}
                searchMore={this.getMoreContent.bind(this)}
                loadingMore={this.state.loadingMore}
              /> 
            </div>
            <DetailsPanel
              ref={instance => { this.detailsPanelRef = instance; }}
              appPlatform={ this.props.appPlatform }
              userData={ this.props.userData }
              repoData={ this.state.userData && this.state.userData.repository }
              foldersList={ this.props.foldersList }
              repoUsers={this.props.repoUsers}
              newFolder={ this.props.newFolder }
              items={ items }
              showRightPanel={ this.state.showRightPanel }
              selection={ this._selection }
              selItem={ this.state.selItem }
              getCurrentContent={(this.getCurrentContent.bind(this))}
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              page={ this.props.page }
              detailsPanelSection="explorer"
              callbackFunction={ this.callbackFunction }
              pipelines={this.state.pipelines}
              customProperties={customProperties}
            />
          </div>  
      </ThemeProvider>
    );
  }

  private getStage(item:any) {
    const { pipelines } = this.state;
    let pipeline:any = null;
    for (let i in pipelines) {
      for (let j in pipelines[i].stages) {
        if (item.stage && pipelines[i].stages[j].id === item.stage.id) pipeline = pipelines[i]
      }
    }
    if (pipeline) {
      pipeline.stages = pipeline.stages.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
      let nStages = pipeline.stages.length;
      let index = 0;
      return (
        pipeline.stages.map((stage)=>{
          index++;
          if (stage.id === item.stage.id) {
            return (
              <div key={item.stage.id} className='d-flex align-items-center'>
                <Icon 
                  iconName="CircleShapeSolid" 
                  className="tag mr-2" 
                  style={{
                    fontSize:12,
                    textAlign:'center',
                    color: 'rgb(16, 110, 190)',
                    opacity: index/nStages
                  }} 
                />
                <span style={{paddingBottom: '2px', fontWeight: 400}}>{item.stage.name}</span>
              </div> 
            )
          } return null;
        })
      )
    } else {
      return null
    }
  }

  private getStagesArrows(item:any) {
    const { pipelines } = this.state;
    let pipeline:any = null;
    for (let i in pipelines) {
      for (let j in pipelines[i].stages) {
        if (item.stage && pipelines[i].stages[j].id === item.stage.id) pipeline = pipelines[i]
      }
    }
    if (pipeline) {
      let selected = false;
      pipeline.stages = pipeline.stages.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
      let nStages = pipeline.stages.length;
      let index = 0;
      let colors = ['rgba(192,0,0,.8)', 'rgba(200,50,0,.8)', 'rgba(255,155,0,.8)', 'rgba(255,255,0,.8)', 'rgba(200,255,55,.8)', 'rgba(100,255,50,.8)', 'rgba(0,200,0,.8)']
      return (
        <div className='d-flex align-items-center align-self-center pt-1'>
          { 
            pipeline.stages.map((stage)=>{
              index++;
              if (!selected && stage.id !== item.stage.id) {
                return (
                  <TooltipHost key={stage.id}  content={pipeline.name + " - " + stage.name} setAriaDescribedBy={false}>
                    <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow.svg'} style={{opacity: index/nStages > .9 ? .9 : index/nStages , width: '32px', height: '20px'}}/>
                  </TooltipHost>
                )
              }
              else if (!selected && stage.id === item.stage.id) {
                selected = true
                return (
                  <TooltipHost key={stage.name}  content={pipeline.name + " - " + stage.name} setAriaDescribedBy={false}>
                    <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow.svg'} style={{opacity: index/nStages > .9 ? .9 : index/nStages , width: '32px', height: '20px'}}/>
                  </TooltipHost>
                )
              }
              else if (selected && stage.id !== item.stage.id) {
                return (
                  <TooltipHost key={stage.name}  content={pipeline.name + " - " + stage.name} setAriaDescribedBy={false}>
                    <img alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
                  </TooltipHost>
                )
              } return null;
            })
          }
        </div>  
      )
    } else {
      return null;
    }
  }

  private getStagesArrowsExpiration(item:any) {
    const currentDate = new Date().getTime();
    let expirationDate = new Date(item.expirationDate).getTime() - currentDate
    if (expirationDate <= 0) { //Expired
      return <div className='d-flex align-items-center'>
        <TooltipHost content={i18n.t('app:expiresInMore')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn90')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn60')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn30')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-red.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expired')}>
          <Icon className='px-2' style={{color: 'firebrick'}} iconName='Info'/>
        </TooltipHost>
      </div>
    }
    else if (expirationDate <= 2592000000 && expirationDate > 0) { //30 days
      return <div className='d-flex align-items-center'>
        <TooltipHost content={i18n.t('app:expiresInMore')}>
         <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn90')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn60')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn30')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-red.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
      </div>
    }
    else if (expirationDate <= 5184000000 && expirationDate > 0) { //90 days
      return <div className='d-flex align-items-center'>
        <TooltipHost content={i18n.t('app:expiresInMore')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn90')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn60')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-orange.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn30')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
      </div>
    }
    else if (expirationDate <= 7776000000 && expirationDate > 0) { //90 days
      return <div className='d-flex align-items-center'>
        <TooltipHost content={i18n.t('app:expiresInMore')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn90')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-yellow.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn60')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn30')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
      </div>
    }
    else { //>90 days
      return <div className='d-flex align-items-center'>
        <TooltipHost content={i18n.t('app:expiresInMore')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-green.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn90')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn60')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn30')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
      </div>
    }
  }

  private _getColumns() {
    var columns = [
      {
        key: 'image',
        name: '#',
        fieldName: 'image',
        minWidth: 0,
        maxWidth: 1,
        isRowHeader: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '2px'}}/>
            :
              <div style={{borderRadius: '24px', height: '28px'}}><Persona className='project-persona' name={item ? item.name : ''} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful", shape: "square" }}/></div>
            )
        }
      },
      {
        key: 'name',
        name: i18n.t('app:name'),
        fieldName: 'name',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            <span title={ item.name } style={{verticalAlign:'middle'}}>{ item.name }</span>
          )
        }
      },
      {
        key: 'status',
        name: i18n.t('app:expirationDate'),
        fieldName: 'expirationDate',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            this.getStagesArrowsExpiration(item)
          )
        },
      },
      {
        key: 'stage',
        name: i18n.t('app:Stage'),
        fieldName: 'stage',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: this.getStage.bind(this)
      },
      {
        key: 'progress',
        name: i18n.t('app:progress'),
        fieldName: 'progress',
        minWidth: 300,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        onRender: this.getStagesArrows.bind(this),
        isPadded: true
      },
      {
        key: 'signatureDate',
        name: i18n.t('app:signatureDate'),
        fieldName: 'signatureDate',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          let date = new Date(item.signatureDate)
          return (
            <span title={ Moment(item.signatureDate).format(i18n.t('Y-MMM-DD')) } style={{verticalAlign:'middle'}}>{ Moment(item.signatureDate).format(i18n.t('Y-MMM-DD')) }</span>
          )
        }
      },
      {
        key: 'startDate',
        name: i18n.t('app:startDate'),
        fieldName: 'startDate',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          let date = new Date(item.startDate)
          return (
            <span title={ Moment(item.startDate).format(i18n.t('Y-MMM-DD')) } style={{verticalAlign:'middle'}}>{ Moment(item.startDate).format(i18n.t('Y-MMM-DD')) }</span>
          )
        }
      },
      {
        key: 'expirationDate',
        name: i18n.t('app:expirationDate'),
        fieldName: 'expirationDate',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          let date = new Date(item.expirationDate)
          return (
            <span title={ Moment(item.expirationDate).format(i18n.t('Y-MMM-DD')) } style={{verticalAlign:'middle'}}>{ Moment(item.expirationDate).format(i18n.t('Y-MMM-DD')) }</span>
          )
        }
      }
    ]
    this._isMounted && this.setState({
      columns: columns
    })
  }

  onDragOver = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragEnter = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    if(this.listExplorerRef
      && !this.listExplorerRef.state.draggedItem) {
        
      setTimeout(() => {
        var el: any = document.getElementsByClassName("ms-ScrollablePane")
        for(var i = 0; i < el.length; i++) {
          if (!el[i].classList.contains("dragOverList")) {
            el[i].classList.add('dragOverList');
          }
        }
      })
    }

    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave = (e) => {
    if (e.target.className.indexOf("ms-ScrollablePane") !== -1) {
      var el: any = document.getElementsByClassName("ms-ScrollablePane")
      for(var i = 0; i < el.length; i++) {
        el[i].classList.remove('dragOverList');
      }
    }

    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onFileDrop = (e) => {
    var el: any = document.getElementsByClassName("ms-ScrollablePane")
    for(var i = 0; i < el.length; i++) {
      el[i].classList.remove('dragOverList');
    }
    
    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();

  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        newCol.isSortedDescending = !currColumn.isSortedDescending;
        newCol.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    let newItems;
    let mainColumn;
    newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending, mainColumn);
    localStorage.setItem("columnsContracts", JSON.stringify(newColumns))
    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private _sortBySavedColumn = (items: any): void => {
    if (typeof(localStorage.getItem("columnsContracts")) === "string") {
      var dashboardColumns: IColumn[] = JSON.parse(localStorage.getItem("columnsContracts") || "[]");

      for (let i = 0; i < this.state.columns.length; i++) {
        let column = this.state.columns[i]

        let savedColumn: any = dashboardColumns.filter(savedColumn => {
          return column.key === savedColumn.key
        })
        savedColumn = savedColumn[0]

        if(column && savedColumn) {
          column.isSorted = savedColumn.isSorted;
          column.isSortedDescending = savedColumn.isSortedDescending

          if(column.isSorted) {
            const { columns } = this.state;
            const newColumns: IColumn[] = columns.slice();
            const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        
            const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
            this._isMounted && this.setState({
              columns: newColumns,
              items: newItems.map((item:any)=>{return {...item, key: item.id}}),
            });
          }
        } else { 
          this._isMounted && this.setState({items: items.map((item:any)=>{return {...item, key: item.id}})})
        }
      }
    } else {
      this._isMounted && this.setState({items: items.map((item:any)=>{return {...item, key: item.id}})})
    }
  };

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean, secondColumnKey?: string): T[] {
  const key = columnKey as keyof any;
  const key2 = secondColumnKey as keyof any;
  if (key === 'amount' || key === 'signatureDate' || key === 'startDate' || key === 'expirationDate') {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (!a) return -1;
      if (!b) return 1;
      if (a[key2] < b[key2]) return -1;
      if (a[key2] > b[key2]) return 1;
      else if (parseFloat(a[key]) < parseFloat(b[key])) return isSortedDescending ? 1 : -1;
      else if (parseFloat(a[key]) > parseFloat(b[key])) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? parseFloat(a[key]) < parseFloat(b[key]) : parseFloat(a[key]) > parseFloat(b[key])) ? 1 : -1));
  } else if (key === 'stage') {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (!a) return -1;
      if (!b) return 1;
      if (a[key2] < b[key2]) return -1;
      if (a[key2] > b[key2]) return 1;
      else if (a[key] && b[key] && a[key].position < b[key].position) return isSortedDescending ? 1 : -1;
      else if (a[key] && b[key] && a[key].position > b[key].position) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] && b[key] && a[key].position < b[key].position : a[key] && b[key] && a[key].position > b[key].position) ? 1 : -1));
  } else if (key2 === 'stageId') {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (!a) return -1;
      if (!b) return 1;
      if (a['stage'] && b['stage'] && a['stage'].id < b['stage'].id) return -1;
      if (a['stage'] && b['stage'] && a['stage'].id > b['stage'].id) return 1;
      else if (a[key] && b[key] && a[key] < b[key]) return isSortedDescending ? 1 : -1;
      else if (a[key] && b[key] && a[key] > b[key]) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] && b[key] && a[key] < b[key] : a[key] && b[key] && a[key] > b[key]) ? 1 : -1));
  } else {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (!a) return -1;
      if (!b) return 1;
      if ((a[key2] ? a[key2].toLowerCase() : '') < (b[key2] ? b[key2].toLowerCase() : '')) return -1;
      if ((a[key2] ? a[key2].toLowerCase() : '') > (b[key2] ? b[key2].toLowerCase() : '')) return 1;
      else if ((a[key] ? a[key].toLowerCase() : '') < (b[key] ? b[key].toLowerCase() : '')) return isSortedDescending ? 1 : -1;
      else if ((a[key] ? a[key].toLowerCase() : '') > (b[key] ? b[key].toLowerCase() : '')) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? (a && a[key] ? a[key].toLowerCase() : '') < (b && b[key] ? b[key].toLowerCase() : '') : (a && a[key] ? a[key].toLowerCase() : '') > (b && b[key] ? b[key].toLowerCase() : '')) ? 1 : -1));
  }
}

export default withParams(ContractsPage);