import { ActionButton, Checkbox, ComboBox, DatePicker, Dropdown, Icon, IconButton, Modal, TooltipHost } from '@fluentui/react';
import { Input, Label, Textarea } from '@fluentui/react-components';
import * as React from 'react';
import i18n from '../i18n';
import { userService } from '../_services/user.service';
import ReactDOM from 'react-dom';
import { FilePicker } from './_filePicker/FilePicker';


export interface Props {
  callback: any;
  customFields: any[];
  field: any;
}

export interface States {
  hidden: boolean;
}

export class CustomField extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate() {
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private _updateValue(key, value) {
    let items = this.props.customFields;
    for (let i=0; i<items.length;i ++) {
      if (items[i].key === key) {
        items[i].value = value;
        this._isMounted && this.props.callback({customProperties: items, changed: true})
      }
    }
  }

  private _updateMultipleValues(key, item) {
    let items:any = this.props.customFields;
    for (let i=0; i<items.length;i ++) {
      if (items[i].key === key) {
        let value:any = items[i].value || [];
        items[i].value = item.selected ? [...value, item.key as string] : value.filter(key => key !== item.key);
        this._isMounted && this.props.callback({customProperties: items, changed: true})
      }
    }
  }

  public render() {

    const { field, customFields } = this.props;

    let item = customFields.find((i)=>{return i.id === field.id})
    let fieldType = item.fieldType;
    let options:any = [];
    if (item.possibleValues) {
      for (let key in item.possibleValues) {
        options.push({key:key, text: item.possibleValues[key]})
      }
      options.sort((a,b) => { return (a.text >= b.text ? 1 : -1)})
    }

    return (
      <div className='d-flex my-2'>
        <Icon 
        iconName={item.fieldType === 'STRING' ? 'TextField' 
        : item.fieldType === 'NUMBER' ? 'NumberField' 
        : item.fieldType === 'BOOLEAN' ? 'CheckboxComposite' 
        : item.fieldType === 'DATE' ? 'Calendar' 
        : item.fieldType === 'TAG' ? 'Dropdown'
        : item.fieldType === 'FILE' ? 'Document'
        : 'TextField'} 
        style={{marginTop: '5px', marginRight: '10px'}}
        />
        <Label className='form-label' style={{ marginTop: '4px'}} required={item.required}>{item.name}</Label>
        { 
          fieldType === 'STRING' && field.multiLine?
          <Textarea
            className='flex-grow-1'
            value={field.value || ""} onChange={(e,data)=>this._updateValue(field.key,data.value)}
            required={item.required}
          /> 
        : fieldType === 'STRING' && !field.multiLine?
          <Input
            className='flex-grow-1'
            value={field.value || ""} onChange={(e,data)=>this._updateValue(field.key,data.value)}
            required={item.required}
          /> 
        : fieldType === 'NUMBER' ?
          <Input
            className='flex-grow-1'
            type='number'
            value={item.value || ""} onChange={(e,data)=>this._updateValue(item.key,data.value)}
            required={item.required}
          /> 
        : fieldType === 'BOOLEAN' ?
          <Checkbox
            styles={{label: {margin: '6px 0 6px 0'}}}
            checked={item.value && item.value !== 'false'} onChange={(e,v)=>this._updateValue(item.key,v)}
            required={item.required}
          />
        : fieldType === 'TAG' && !item.multipleValues ?
          <div className='d-flex align-items-center flex-grow-1'>
            <Dropdown
              className='flex-grow-1'
              options={options}
              selectedKey={field.value} 
              onChange={(e,v:any)=>this._updateValue(field.key,v.key)}
              required={item.required}
            />
            {field.value && <IconButton style={{position: 'absolute', right: '50px', height: '28px', width: '28px', fontSize: '12px', background: 'transparent'}} styles={{icon: {fontSize: '12px', color: 'rgb(96, 94, 92)'}}} iconProps={{iconName: 'Clear'}} onClick={(e)=>this._updateValue(field.key,null)}/>}
          </div>
        : fieldType === 'TAG' && item.multipleValues ?
          <div className='d-flex align-items-center flex-grow-1'>
            <Dropdown 
              className='flex-grow-1'
              options={options}
              multiSelect
              selectedKeys={field.value}
              onChange={(e,v:any)=>this._updateMultipleValues(field.key,v)}
              required={item.required}
            />
            {field.value && <IconButton style={{position: 'absolute', right: '50px', height: '28px', width: '28px', fontSize: '12px', background: 'transparent'}} styles={{icon: {fontSize: '12px', color: 'rgb(96, 94, 92)'}}} iconProps={{iconName: 'Clear'}} onClick={(e)=>this._updateValue(field.key,null)}/>}
          </div>
        : fieldType === 'DATE' ?
          <div className='d-flex align-items-center flex-grow-1'>
            <DatePicker
              className='flex-grow-1'
              styles={{textField: {height: '32px'}}}
              value={field.value ? new Date(field.value) : undefined} 
              onSelectDate={(v:any)=>this._updateValue(field.key,v)}
              isRequired={item.required}
            />
            {field.value && <IconButton style={{position: 'absolute', right: '50px', height: '28px', width: '28px', fontSize: '12px', background: 'transparent'}} styles={{icon: {fontSize: '12px', color: 'rgb(96, 94, 92)'}}} iconProps={{iconName: 'Clear'}} onClick={(e)=>this._updateValue(field.key,null)}/>}
          </div>
        : fieldType === 'contact' ?
          <div className='d-flex'>
            <ComboBox 
              className='flex-grow-1'
              options={[]}
              required={item.required}
              //selectedKey={fieldType}
              //onChange={(e,option:any)=>{this._isMounted && this.setState({fieldType: option.key || 'singleLine'})}}
            />
            <ActionButton
              className='align-self-end'
              style={{height: '32px'}}
              iconProps={{iconName: 'Add'}}
            >
              {i18n.t('app:createContact')}
            </ActionButton>
          </div>
        : fieldType === 'FILE' ?
          <div className='d-flex flex-grow-1'>
            <Input className='flex-grow-1' value={field.value || ""} onChange={(e,data)=>{this._updateValue(field.key,data.value)}}/>
            <TooltipHost
              content={i18n.t('app:openFilePicker')}
              styles={{root: {borderRadius: '4px', position: 'absolute', marginTop: '1px', right: '17px', width: '34px', height: '29px' }}}
            >
              <IconButton styles={{icon: {color: 'rgb(50,49,48)', height: '20px'}, root: {borderRadius: '4px', width: '34px', height: '29px'}}} iconProps={{iconName: 'DriveSynergyOS'}} onClick={this.openSynergyOSpicker.bind(this)}/>
            </TooltipHost>
          </div>
        : null }
      </div>
    )
  }

  private openSynergyOSpicker() {
    this._openFilePicker().then((result:any)=>{
        if (result && result.length > 0) {
            userService.getDocument(result).then((response)=>{
                const file = response.data;
                this._updateValue(this.props.field.key,file.id)
            }).catch((error)=>{
                console.log(error)
            })
        }
    }).catch((error)=>{
        console.log(error)
    })
}

  private _openFilePicker = async () => {
    return await new Promise((resolve, reject) => {
        var showDialog = true;

        var confirm = (id) => {
          showDialog = false;
          renderDialog();
          resolve(id);
        }

        var cancel = () => {
          showDialog = false;
          renderDialog()
          reject()
        }

        var div = document.createElement('div');
        var renderDialog = () => {
          ReactDOM.render(
          <Modal
            isOpen={showDialog}
            onDismiss={()=>{cancel()}}
            isBlocking={true}
            styles={{main: {maxWidth: '1100px', width: '100%', maxHeight: '700px'}}}
            className="file-picker"
          >
            <div className="w-100 h-100">
              <div className="d-flex flex-row noselect align-items-center" style={{height: '28px', backgroundColor: '#2c2c2c', color: 'white', fontWeight: 400, fontSize: '14px'}}>
                <Icon iconName='DriveSynergyOS' className='pr-2 pl-2' style={{height: '24px'}}/><span style={{fontWeight: 600}}>SynergyOS File Picker</span>
                <IconButton onClick={()=>{showDialog=false;renderDialog()}} iconProps={{iconName: 'Cancel'}} className='mr-0 ml-auto file-picker-close-button' style={{height: '28px', color: 'white'}}/>
              </div>
              <FilePicker
                {...this.props}
                startFolder={this.props.userData.repository.id}
                location={undefined}
                database={undefined}
                isOnline={true}
                maintenance={false}
                fileExplorerType='fileExplorer'
                userData={this.props.userData}
                repoData={this.props.userData.repository} 
                foldersList={this.props.foldersList}
                newFolder={null}
                repoUsers={this.props.repoUsers}
                callback={(id) => {confirm(id)}}
                selectDocument={true}
              />
            </div>
          </Modal>
          , div
          )
        }
      renderDialog();
    })
  }

}
