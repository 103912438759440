import { DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, Icon, IconButton, IPersonaProps, PrimaryButton, Spinner, SpinnerSize, ActionButton, ValidationState, IPeoplePickerItemSelectedProps, PeoplePickerItem, NormalPeoplePicker, TimePicker, TooltipHost, IRenderFunction, IDetailsHeaderProps, ListPeoplePicker, ScrollablePane, ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, ConstrainMode } from '@fluentui/react';
import * as React from 'react';
import i18n from '../../i18n';
import { FluentProvider, Input, Label, Link, Persona, Textarea, webLightTheme } from '@fluentui/react-components';
import { userService } from '../../_services';
import uuid from 'uuid-random';
const hours = require('../../_constants/hours.json')

export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean,
  loading: boolean,
  name: string,
  description: string,
  tag: string,
  tags: any[],
  changed: boolean,
  reminders: any[];
  contacts: any[];
  loadingContacts: boolean;
  contactSelected: string;
  dueDate: any;
  dueHour: any;
  assignedTo: any[];
  currentProjects: any[];
  currentContacts: any[];
  currentContracts: any[];
  currentCompanies: any[];
  possibleProjects: any[];
  possibleContacts: any[];
  possibleContracts: any[];
  possibleCompanies: any[];
  showCurrentProjects: boolean;
  showCurrentContacts: boolean;
  showCurrentContracts: boolean;
  showCurrentCompanies: boolean;
  searchProjects: string;
  searchContacts: string;
  searchContracts: string;
  searchCompanies: string;
  loadingEntities: boolean;
  entitiesOpened: boolean;
  visibility: string;
  status: string;
}

export class FormTaskEdit extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      name: this.props.item.subject,
      description: this.props.item.description,
      tag: this.props.item.taskImportance,
      tags: [
        {key: 'URGENT', text: 'To do urgent', color: '#d69ca5'},
        {key: 'LATER', text: 'To do later', color: '#f4bfab'},
        {key: 'FOLLOW_UP', text: 'To follow-up', color: '#f9e2ae'},
        {key: 'MAYBE', text: 'Maybe', color: '#bdd99b'},
      ],
      changed: false,
      reminders: [],
      contacts: [],
      loadingContacts: true,
      contactSelected: '',
      dueDate: new Date(this.props.item.dueDate),
      dueHour: new Date(this.props.item.dueDate),
      assignedTo: [],
      currentProjects: [],
      currentContacts: [],
      currentContracts: [],
      currentCompanies: [],
      possibleProjects: [],
      possibleContacts: [],
      possibleContracts: [],
      possibleCompanies: [],
      showCurrentProjects: true,
      showCurrentContacts: true,
      showCurrentContracts: true,
      showCurrentCompanies: true,
      searchProjects: '',
      searchContacts: '',
      searchContracts: '',
      searchCompanies: '',
      loadingEntities: true,
      entitiesOpened: true,
      visibility: this.props.item.visiblity ||'PUBLIC',
      status: this.props.item.status
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this.getEntities().then(()=>{
      this._isMounted && this.setState({loadingEntities: false})
    })
    userService.getRepoUsers('ADMIN').then((response) => {
      let admins = response.data;
      userService.getRepoUsers('INTERNAL').then((response) => {
        let allUsers = admins.concat(response.data).map((item)=> {return {...item, key: item.id, text: ((item.name || '') + ' ' + (item.surname || ''))}})
        for (let i in allUsers) {
          if (this.props.item.assignedUsers.includes(allUsers[i].id)) {
            
          }
        }
        this._isMounted && this.setState({
          contacts: allUsers, 
          loadingContacts: false, 
          assignedTo: allUsers.filter((user)=>{return this.props.item.assignedUsers.includes(user.id)}),
          reminders: this.props.item.reminders.map((reminder)=>{return {
            key: uuid(),
            value: new Date(reminder),
            hours: new Date(reminder)
          }})
        })
      }).catch(()=>{
        this._isMounted && this.setState({contacts: [], loadingContacts: false})
      })
    }).catch(()=>{
      this._isMounted && this.setState({contacts: [], loadingContacts: false})
    })

  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      setTimeout(()=>this.props.callback({showTaskFormEdit: false}),200)
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private async getEntities() {
    await new Promise((resolve) => {
        userService.getCompanies().then((response)=>{
            let companies = response.data;
            for (let i=0; i<companies.length; i++) {
                companies[i].key = companies[i].id
                companies[i].text = companies[i].name
                if (this.props.item.companyIds.includes(companies[i].id)) {
                  this.addCompany(companies[i])
                }
            }
            this.setState({possibleCompanies: companies})
            resolve(true);
        }).catch(()=>{
            resolve(true);
        })
    })
    await new Promise((resolve) => {
        userService.getContacts().then((response)=>{
            let contacts = response.data;
            for (let i=0; i<contacts.length; i++) {
                contacts[i].key = contacts[i].id
                contacts[i].text = contacts[i].firstName + (contacts[i].lastName ? (' ' + contacts[i].lastName) : '')
                if (contacts[i].id === this.props.item.createdByUserId) {
                  this.addContact(contacts[i], true)
                }
                else if (this.props.item.contactIds.includes(contacts[i].id)) {
                  this.addContact(contacts[i])
                }
            }
            this.setState({possibleContacts: contacts.map((item)=>{return {...item, name: (item.firstName || '') + ' ' + (item.lastName || ''), display: (item.firstName || '') + ' ' + (item.lastName || ''), email: (item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email) || ''}})})
            resolve(true);
        }).catch(()=>{
            resolve(true);
        })
    })
    await new Promise((resolve) => {
        userService.getContracts().then((response)=>{
            let contracts = response.data;
            for (let i=0; i<contracts.length; i++) {
                contracts[i].key = contracts[i].id
                contracts[i].text = contracts[i].name
                if (this.props.item.contractIds.includes(contracts[i].id)) {
                  this.addContract(contracts[i])
                }
            }
            this.setState({possibleContracts: contracts})
            resolve(true);
        }).catch(()=>{
            resolve(true);
        })
    })
    await new Promise((resolve) => {
        userService.getProjects().then((response)=>{
            let projects = response.data;
            for (let i=0; i<projects.length; i++) {
                projects[i].key = projects[i].id
                projects[i].text = projects[i].name
                if (this.props.item.projectIds.includes(projects[i].id)) {
                  this.addProject(projects[i])
                }
            }
            this.setState({possibleProjects: projects})
            resolve(true);
        }).catch(()=>{
            resolve(true);
        })
    })
  }

  private _updateMultipleOptions(index, type, value) {
    let options = this.state.reminders;
    options[index][type] = value;
    this._isMounted && this.setState({reminders: options, changed: true});
  }

  private _addOption() {
    let options = this.state.reminders;
    options.push({key: uuid(), value: new Date(new Date(Date.now()).toDateString()), hours: new Date(new Date(Date.now()).toDateString())})
    this._isMounted && this.setState({reminders: options, changed: true});
  }

  private _deleteOption(index) {
    let options = this.state.reminders;
    options.splice(index, 1);
    this._isMounted && this.setState({reminders: options, changed: true});
  }

  private _updateContact(data) {
      if (data) {
        this._isMounted && this.setState({  
          contactSelected: data.key,
          changed: true
        })
      }
  }

  private updateTag(data) {
    if (data) {
      this._isMounted && this.setState({  
        tag: data.key,
        changed: true
      })
    }
  }

  private validateInput(input: string): ValidationState {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  }

  private addAssignedTo = (option: any, byDefault = false) : any => {
    if (option) {
        let contacts = this.state.assignedTo
        let repeated = false;
        for (let i in contacts) {
            if (contacts[i].key === option.key )repeated = true;
        }

        if (!repeated) {
            option.byDefault = byDefault
            contacts.push(option)
            byDefault ? this.setState({assignedTo: contacts}) : this.setState({assignedTo: contacts})
            return option
          }
    }
  }

  private _submit(e) {
    if (e) e.preventDefault();

    this._isMounted && this.setState({loading: true})

    const {name, description, dueDate, dueHour, assignedTo, currentProjects, currentContracts, currentContacts, currentCompanies, tag, reminders, visibility, status} = this.state;

    const contacts:string[] = currentContacts.map((item)=>{return item.id})
    const companies:string[] = currentCompanies.map((item)=>{return item.id})
    const projects:string[] = currentProjects.map((item)=>{return item.id})
    const contracts:string[] = currentContracts.map((item)=>{return item.id})
    const assignedUsers:string[] = assignedTo.map((item)=>{return item.id})
    const associateEntityRequest = {companyIds: companies, contactIds: contacts, projectIds: projects, contractIds: contracts}

    const toMilliseconds = (hrs,min) => (hrs*60*60+min*60)*1000;

    let time = dueDate.getTime() + toMilliseconds(dueHour.getHours(), dueHour.getMinutes())

    let remindersTime = reminders.map((reminder) => {
      return reminder.value.getTime() + toMilliseconds(reminder.hours.getHours(), reminder.hours.getMinutes())
    })

    const task = {
      subject: name,
      description: description,
      dueDate: time,
      taskImportance: tag,
      status: status,
      reminders: remindersTime,
      assignedUsers: assignedUsers,
      associateEntityRequest: associateEntityRequest
    }
    userService.updateTask(this.props.item.id, task).then((response)=>{
      userService.updateTaskRelations(this.props.item.id, associateEntityRequest).then((response)=>{
        if (this.props.item.visibility !== visibility) {
          userService.updateTaskVisibility(this.props.item.id, visibility).then((response)=>{
            this._isMounted && this.setState({loading: false, hidden:true})
            this.props.getCurrentContent(true);
          }).catch((error)=>{
            this._isMounted && this.setState({loading: false})
            if (error && error.data && error.data.code === 400 && error.data.message) {
              this.props.showAlertDialog('Error', error.data.message)
            } else if (error && error.data && error.data.message) {
              this.props.showAlertDialog('Error', error.data.message)
            } else {
              this.props.showAlertDialog('Error', "An error occurred trying to update the task. Please try again.")
            }
          })
        } else {
          this._isMounted && this.setState({loading: false, hidden:true})
          this.props.getCurrentContent(true);
        }
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update the task. Please try again.")
        }
      })
    }).catch((error)=>{
      this._isMounted && this.setState({loading: false})
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else {
        this.props.showAlertDialog('Error', "An error occurred trying to update the task. Please try again.")
      }
    })

  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      this._isMounted && this.setState({hidden:true})
    }
  }

  private addCompany(option: any, byDefault = false){
    if (option) {
        let companies = this.state.currentCompanies
        let repeated = false;
        for (let i in companies) {
            if (companies[i].key === option.key )repeated = true;
        }

        if (!repeated) {
            option.byDefault = byDefault
            companies.push(option)
            byDefault ? this.setState({currentCompanies: companies}) : this.setState({currentCompanies: companies, changed: true})
        }
    }
  }

  private addContact(option: any, byDefault = false){
    if (option) {
        let contacts = this.state.currentContacts
        let repeated = false;
        for (let i in contacts) {
            if (contacts[i].key === option.key )repeated = true;
        }

        if (!repeated) {
            option.byDefault = byDefault
            contacts.push(option)
            byDefault ? this.setState({currentContacts: contacts}) : this.setState({currentContacts: contacts, changed: true})
        }
    }
  }

  private addContract(option: any, byDefault = false){
    if (option) {
        let contracts = this.state.currentContracts
        let repeated = false;
        for (let i in contracts) {
            if (contracts[i].key === option.key )repeated = true;
        }

        if (!repeated) {
            option.byDefault = byDefault
            contracts.push(option)
            byDefault ? this.setState({currentContracts: contracts}) : this.setState({currentContracts: contracts, changed: true})
        }
    }
  }

  private addProject(option: any, byDefault = false){
    if (option) {
        let projects = this.state.currentProjects
        let repeated = false;
        for (let i in projects) {
            if (projects[i].key === option.key )repeated = true;
        }

        if (!repeated) {
            option.byDefault = byDefault
            projects.push(option)
            byDefault ? this.setState({currentProjects: projects}) : this.setState({currentProjects: projects, changed: true})
        }
    }
  }

  private removeFrom(item, type) {
    if (type === 'companies') {
        let items = this.state.currentCompanies.filter((company)=>{
            return item.key !== company.key
        })
        this.setState({currentCompanies: items, changed: true})
    } else if (type === 'contacts') {
        let items = this.state.currentContacts.filter((company)=>{
            return item.key !== company.key
        })
        this.setState({currentContacts: items, changed: true})
    } else if (type === 'contracts') {
        let items = this.state.currentContracts.filter((company)=>{
            return item.key !== company.key
        })
        this.setState({currentContracts: items, changed: true})
    } else if (type === 'projects') {
        let items = this.state.currentProjects.filter((company)=>{
            return item.key !== company.key
        })
        this.setState({currentProjects: items, changed: true})
    }
  }

  public render() {

    const { hidden, loading, name, description, tag, tags } = this.state;

    const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
      return personasToReturn;
    };

    const filterAssignedToByText = (
      filterText: string,
      currentPersonas: IPersonaProps[],
      limitResults?: number,
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
      if (filterText) {
          let filteredPersonas: IPersonaProps[] = this.state.contacts.filter(item => doesTextStartWith(item, filterText));
      
          filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
          filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
          return filterPromise(filteredPersonas);
      } else {
          return [];
      }
    };

    const renderItemWithSecondaryText: any = (props: IPeoplePickerItemSelectedProps) => {
      const newProps = {
        ...props,
        item: {
          ...props.item,
          ValidationState: ValidationState.valid,
          //showSecondaryText: true,
        },
      };
  
      return <PeoplePickerItem {...newProps} className={"conversationDestinatary " + (props.item.id ? "internal" : "external") } />;
    }

    const columnsCompanies:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this.setState({showCurrentCompanies: !this.state.showCurrentCompanies})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentCompanies ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:companies') + ' (' + this.state.currentCompanies.length + ')' }</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
            <div>
              <div className='d-flex align-items-center'>
                  <p title={item.name} className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)', maxWidth: '180px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                      {item.name}
                  </p>
                  { item.new && <TooltipHost content={<div className='p-1'>{i18n.t('app:newCompany')}.</div>} calloutProps={{ gapSpace: 0 }}>
                      <span className='new-badge'>{i18n.t('app:new')}</span>
                  </TooltipHost> }
              </div>
              <p title={item.domain} className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}><Link target='_blank' href={'https://'+item.domain}>{item.domain}</Link></p>
            </div>
            {item && <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,'companies')}}/>
            </TooltipHost>}
          </div>
        </>)
      }
    }]
    
    const columnsContacts:any[] = [{
        key: 'name',
        name: <div 
        onClick={()=>{this.setState({showCurrentContacts: !this.state.showCurrentContacts})}} 
        className='d-flex align-items-center relation-header'>
          <TooltipHost
            content={i18n.t('app:expand/collapse')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {marginLeft: 'auto', height: '36px' }}}
          >
            <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentContacts ? 'ChevronDown' : 'ChevronRight'}}/>
          </TooltipHost>
          <span>{i18n.t('app:contacts') + ' (' + this.state.currentContacts.length + ')'}</span>
        </div>,
        fieldName: 'name',
        //isSorted: true,
        isPadded: false,
        onRender: (item)=>{
          return (<>
            <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
              <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
              <div>
                <div className='d-flex align-items-center'>
                    <p title={item.firstName + (item.lastName ? ' ' + item.lastName : '')} className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)', maxWidth: '180px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {item.firstName + (item.lastName ? ' ' + item.lastName : '')}
                    </p>
                    { item.mentioned && <TooltipHost content={<div className='p-1'>{i18n.t('app:mentionedInText')}</div>} calloutProps={{ gapSpace: 0 }}>
                        <span className='new-badge'>{i18n.t('app:mentioned')}</span>
                    </TooltipHost> }
                </div>
                <p title={(item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email) || item.email} className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}><Link target='_blank' href={'mailto:'+((item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email) || item.email)}>{(item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email) || item.email}</Link></p>
              </div>
              {item && <TooltipHost
                content={i18n.t('app:remove')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto'}}}
              >
                <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,'contacts')}}/>
              </TooltipHost>}
            </div>
          </>)
        }
    }]

    const columnsContracts:any[] = [{
        key: 'name',
        name: <div 
        onClick={()=>{this.setState({showCurrentContracts: !this.state.showCurrentContracts})}} 
        className='d-flex align-items-center relation-header'>
          <TooltipHost
            content={i18n.t('app:expand/collapse')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {marginLeft: 'auto', height: '36px' }}}
          >
            <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentContracts ? 'ChevronDown' : 'ChevronRight'}}/>
          </TooltipHost>
          <span>{i18n.t('app:contracts') + ' (' + this.state.currentContracts.length + ')'}</span>
        </div>,
        fieldName: 'name',
        //isSorted: true,
        isPadded: false,
        onRender: (item)=>{
          return (<>
            <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
                <div className='d-flex align-items-center'>
                <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
                <div>
                    <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name}</p>
                    <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}><Link target='_blank' href={'/contracts/'+item.id}>Link</Link></p>
                </div>
              </div>
              {item && <TooltipHost
                content={i18n.t('app:remove')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto'}}}
              >
                <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,'contracts')}}/>
              </TooltipHost>}
            </div>
          </>)
        }
    }]
    
    const columnsProjects:any[] = [{
        key: 'name',
        name: <div 
        onClick={()=>{this.setState({showCurrentProjects: !this.state.showCurrentProjects})}} 
        className='d-flex align-items-center relation-header'>
          <TooltipHost
            content={i18n.t('app:remove')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {marginLeft: 'auto', height: '36px' }}}
          >
            <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentProjects ? 'ChevronDown' : 'ChevronRight'}}/>
          </TooltipHost>
          <span>{i18n.t('app:projects') + ' (' + this.state.currentProjects.length + ')'}</span>
        </div>,
        fieldName: 'name',
        //isSorted: true,
        isPadded: false,
        onRender: (item)=>{
          return (<>
            <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
              <div className='d-flex align-items-center'>
              <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
              <div>
                <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name}</p>
                <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}><Link target='_blank' href={'/projects/'+item.id}>Link</Link></p>
              </div>
              </div>
              {item && <TooltipHost
                content={i18n.t('app:remove')}
                calloutProps={{ gapSpace: 0 }}
                styles={{root: {marginLeft: 'auto'}}}
              >
                <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,'projects')}}/>
              </TooltipHost>}
            </div>
          </>)
        }
    }]

    const filterCompaniesByText = (
        filterText: string,
        currentPersonas: IPersonaProps[],
        limitResults?: number,
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = this.state.possibleCompanies.filter(item => doesTextStartWith(item, filterText));
        
            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const filterContactsByText = (
        filterText: string,
        currentPersonas: IPersonaProps[],
        limitResults?: number,
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = this.state.possibleContacts.filter(item => doesTextStartWith(item, filterText));
        
            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const filterContractsByText = (
        filterText: string,
        currentPersonas: IPersonaProps[],
        limitResults?: number,
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = this.state.possibleContracts.filter(item => doesTextStartWith(item, filterText));
        
            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const filterProjectsByText = (
        filterText: string,
        currentPersonas: IPersonaProps[],
        limitResults?: number,
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = this.state.possibleProjects.filter(item => doesTextStartWith(item, filterText));
        
            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const onRenderDetailsHeaderCompanies = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
        return (
        <>
            <div className='d-flex align-items-center'>
                {defaultRender!({
                ...props
                })}
            </div>
            { this.state.showCurrentCompanies && <div style={{background:'white'}}>
                <ListPeoplePicker disabled={this.state.loadingEntities} styles={{text: {borderColor:'#bfbfbf', borderRadius: 'none', margin: '0 10px'}, root: {padding:'10px 0'} }} onRenderSuggestionsItem={ (item:any)=>{
                    return (
                        <FluentProvider className='d-flex align-items-center w-100 px-2' style={{background: 'transparent'}}>
                          <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
                            <div>
                                <p className='p-0 m-0 text-left' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.text}</p>
                                <p className='p-0 m-0 text-left' style={{color:'rgb(96, 94, 92)', fontSize: 12}}><Link target='_blank' href={'https://'+item.domain}>{item.domain}</Link></p>
                            </div>
                        </FluentProvider>
                  )
                }} onItemSelected={(item)=>{this.addCompany(item); return null;}} onResolveSuggestions={(f,i:any)=>filterCompaniesByText(f,i)}/>
            </div>}
        </>
        );
    }

    const onRenderDetailsHeaderContacts = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
        return (
        <>
            <div className='d-flex align-items-center'>
                {defaultRender!({
                ...props
                })}
            </div>
            { this.state.showCurrentContacts && <div style={{background:'white'}}>
                <ListPeoplePicker disabled={this.state.loadingEntities} styles={{text: {borderColor:'#bfbfbf', borderRadius: 'none', margin: '0 10px'}, root: {padding:'10px 0'} }} onRenderSuggestionsItem={ (item:any)=>{
                    return (
                        <FluentProvider className='d-flex align-items-center w-100 px-2' style={{background: 'transparent'}}>
                          <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
                            <div>
                                <p className='p-0 m-0 text-left' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.text}</p>
                                <p className='p-0 m-0 text-left' style={{color:'rgb(96, 94, 92)', fontSize: 12}}><Link target='_blank' href={'mailto:'+((item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email) || item.email)}>{(item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email) || item.email}</Link></p>
                            </div>
                        </FluentProvider>
                    )
                }} onItemSelected={(item)=>{this.addContact(item); return null;}} onResolveSuggestions={(f,i:any)=>filterContactsByText(f,i)}/>
            </div>}
        </>
        );
    }

    const onRenderDetailsHeaderContracts = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
        return (
        <>
            <div className='d-flex align-items-center'>
                {defaultRender!({
                ...props
                })}
            </div>
            { this.state.showCurrentContracts && <div style={{background:'white'}}>
                <ListPeoplePicker disabled={this.state.loadingEntities} styles={{text: {borderColor:'#bfbfbf', borderRadius: 'none', margin: '0 10px'}, root: {padding:'10px 0'} }} onRenderSuggestionsItem={ (item:any)=>{
                    return (
                        <FluentProvider className='d-flex align-items-center w-100 px-2' style={{background: 'transparent'}}>
                          <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
                            <div>
                                <p className='p-0 m-0 text-left' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.text}</p>
                                <p className='p-0 m-0 text-left' style={{color:'rgb(96, 94, 92)', fontSize: 12}}><Link target='_blank' href={'/contracts/'+item.id}>{i18n.t('app:Link')}</Link></p>
                            </div>
                        </FluentProvider>
                    )
                }} onItemSelected={(item)=>{this.addContract(item); return null;}} onResolveSuggestions={(f,i:any)=>filterContractsByText(f,i)}/>
            </div>}
        </>
        );
    }

    const onRenderDetailsHeaderProjects =(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
        return (
        <>
            <div className='d-flex align-items-center' style={{backgroundColor: 'white'}}>
                {defaultRender!({
                ...props
                })}
            </div>
            { this.state.showCurrentProjects && <div style={{background:'white'}}>
                <ListPeoplePicker disabled={this.state.loadingEntities} styles={{text: {borderColor:'#bfbfbf', borderRadius: 'none', margin: '0 10px'}, root: {padding:'10px 0'} }} onRenderSuggestionsItem={ (item:any)=>{
                    return (
                        <FluentProvider className='d-flex align-items-center w-100 px-2' style={{background: 'transparent'}}>
                          <Persona className={item.id.startsWith('Project-') || item.id.startsWith('Contract-') ? 'project-persona' : item.id.startsWith('Company-') ? 'company-persona' : ''} name={item.name || ((item.firstName || '') + ' ' + (item.lastName || ''))} size='small' primaryText='' avatar={{ color: "colorful"}}/>
                            <div>
                                <p className='p-0 m-0 text-left' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.text}</p>
                                <p className='p-0 m-0 text-left' style={{color:'rgb(96, 94, 92)', fontSize: 12}}><Link target='_blank' href={'/projects/'+item.id}>{i18n.t('app:Link')}</Link></p>
                            </div>
                        </FluentProvider>
                  )
                }} onItemSelected={(item)=>{this.addProject(item); return null;}} onResolveSuggestions={(f,i:any)=>filterProjectsByText(f,i)}/>
            </div>}
        </>
        );
    }

    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Task'  src={process.env.PUBLIC_URL + '/icons/tasks.svg'} />{i18n.t('app:createTask')}</div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: 'none', background: '#f2f2f2'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog full-height " + (this.state.entitiesOpened ? 'big-width' : ''),
            onDismiss: ()=> {this.close()},
          }}
        >
          <div className='' style={{borderBottom: '1px solid #bfbfbf', position: 'sticky', top: 0, zIndex: 1}}></div>
          <FluentProvider theme={webLightTheme} className='w-100' style={{display: 'flex', height: '100%', backgroundColor: '#f2f2f2'}}>
            <div style={{width: this.state.entitiesOpened ? '66.66%' : '100%'}}>
              <div className='d-flex align-items-center py-4 px-3 pr-1' style={{height: '32px', borderBottom: '1px solid #bfbfbf'}}>
                <span style={{fontWeight: 600}}>{i18n.t('app:task')}</span>
                <TooltipHost
                  content={this.state.entitiesOpened ? i18n.t('app:closePanel') : i18n.t('app:openPanel')}
                  calloutProps={{ gapSpace: 0 }}
                  styles={{root: {marginLeft: 'auto' }}}
                >
                  <IconButton className='pr-0' iconProps={{iconName: this.state.entitiesOpened ? 'OpenPane' : 'ClosePane'}} styles={{icon: {fontSize: '20px'}}} onClick={()=>{this.setState({entitiesOpened: !this.state.entitiesOpened})}}/>
                </TooltipHost>
              </div>
              <ScrollablePane className='relation-list' style={{top: '50px', width: this.state.entitiesOpened ? '66.66%' : '100%'}}>
                <form id='form' className='px-0 pb-3 h-100 d-flex flex-column' onSubmit={this._submit.bind(this)}>
                  <input className='d-none' type='submit'></input>
                  <div className='d-flex mt-3'>
                    <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:subject')}</Label>
                    <Input className='flex-grow-1' value={name} onChange={(e,data)=>this._isMounted && this.setState({name: data.value, changed: true})} required autoFocus/>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:description')}</Label>
                    <Textarea className='flex-grow-1' value={description} onChange={(e,data)=>this._isMounted && this.setState({description: data.value, changed: true})}/>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='IconSetsFlag' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:importance')}</span>
                    <Dropdown className='flex-grow-1' styles={{ root: {borderRadius: '4px'}}} selectedKey={tag} onChange={(e, data:any) => this.updateTag(data)} options={tags} 
                      onRenderOption={(item:any)=>{return <div className='d-flex align-items-center' style={{height:'32px'}}><Icon className='mr-2' iconName='CircleFill' style={{color: item.color}}/><span>{item.text}</span></div>}}
                      onRenderTitle={(items:any)=>{return <div className='d-flex align-items-center' style={{height:'32px'}}><Icon className='mr-2' iconName='CircleFill' style={{color: items[0].color}}/><span>{items[0].text}</span></div>}}
                    />
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='Contact' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px', minWidth: '150px'}}>{i18n.t('app:assignedTo')}</span>
                    <div className='flex-grow-1 xxx'>
                    <NormalPeoplePicker disabled={this.state.loading} styles={{text: {borderRadius: '4px', background: 'white'}, root: {padding:'10px 0'}}} onRenderSuggestionsItem={ (item:any)=>{
                      return (
                          <FluentProvider className='d-flex align-items-center w-100 px-2' style={{background: 'transparent'}}>
                            <Persona name={(item.name || '') + ' ' + (item.surname || '')} size='small' primaryText='' avatar={{ color: "colorful"}}/>
                              <div>
                                  <p className='p-0 m-0 text-left' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.text}</p>
                                  <p className='p-0 m-0 text-left' style={{color:'rgb(96, 94, 92)', fontSize: 12}}><Link target='_blank' href={'mailto:'+item.email}>{item.email}</Link></p>
                              </div>
                          </FluentProvider>
                        )
                      }} 
                      onChange={(items) => {this._isMounted && this.setState({assignedTo: items || []})}}
                      onResolveSuggestions={(f,i:any)=>filterAssignedToByText(f,i)}
                      onValidateInput={this.validateInput}
                      className="p-0"
                      selectedItems={this.state.assignedTo}
                      onRenderItem={renderItemWithSecondaryText}
                    />
                  </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='Calendar' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:dueDate')}</span>
                    <DatePicker className='flex-grow-1'
                      value={this.state.dueDate}
                      onSelectDate={(date)=>{this._isMounted && this.setState({dueDate: date})}}
                    />
                    <TimePicker className='ml-3' styles={{root: {maxWidth: '100px'}}} useComboBoxAsMenuWidth defaultValue={this.state.dueHour} onChange={(e, date) => {this._isMounted && this.setState({dueHour: date, changed: true})}}/>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='D365ProjectOperationsIcon' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:status')}</span>
                    <div className='d-flex align-items-center flex-grow-1' style={{position: 'relative'}}>
                      <Dropdown className='flex-grow-1' styles={{ title: {borderRadius: '4px', paddingRight: '24px'}}} selectedKey={this.state.status} onChange={(e, data:any) => this._isMounted && this.setState({status: data.key})} options={[{key: 'OPEN', text: i18n.t('app:open')}, {key: 'CLOSE', text: i18n.t('app:closed')}]}/>
                    </div>
                  </div>
                  <div className='mt-3'>
                  <div className='d-flex'>
                    <Icon iconName='Clock' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:reminders')}</span>
                  </div>
                  <div className='flex-grow-1 mt-3'>
                    {this.state.reminders.map((item,index) => {
                      return (
                        <div className='ml-4' key={item.id || item.key} >
                          <div className='d-flex align-items-center pb-3 mb-3' style={{borderBottom: '1px solid lightgrey'}}>
                            <span className='form-label pr-3' style={{width: '90px'}}>{i18n.t('app:reminder') + ' ' + (index+1)}</span>
                            <DatePicker
                              className='flex-grow-1'
                              style={{height: '32px'}}
                              value={this.state.reminders[index].value}
                              onSelectDate={(date)=>{this._updateMultipleOptions(index, 'value', date)}}
                            />
                            <TimePicker className='mx-3' styles={{root: {maxWidth: '100px'}}} useComboBoxAsMenuWidth defaultValue={this.state.reminders[index].hours} onChange={(e, date) => {this._updateMultipleOptions(index, 'hours', date)}}/>
                            <IconButton className='ml-auto' onClick={()=>this._deleteOption(index)} iconProps={{iconName: 'Delete'}} /> 
                          </div>
                        </div>
                        )
                      })}
                      <ActionButton className='mb-3' styles={{root: {height: '28px'}}} onClick={()=> this._addOption()} iconProps={{iconName: 'Add'}} text={i18n.t('app:addReminder')}/>
                    </div>
                  </div>
                </form>
              </ScrollablePane>
            </div>
            <div style={{borderLeft: '1px solid #bfbfbf', width: '33.3%', display: this.state.entitiesOpened ? 'block' : 'none'}}>
              <div className='d-flex align-items-center' style={{borderBottom: '1px solid #bfbfbf', height: '49px'}}>
                <span className='px-4' style={{fontWeight: 600}}>{i18n.t('app:relations')}</span>
              </div>
              <ScrollablePane className='relation-list' style={{left:'66.66%', width: '33.3%', top: '50px'}}>
                <div className='mt-3'>
                  <Icon iconName='Dropdown' style={{marginRight: '10px', verticalAlign: 'middle'}}/>
                  <Label className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:permissions')}</Label>
                  <div className='d-flex'>
                    <Dropdown className='flex-grow-1' selectedKey={this.state.visibility} onChange={(e,data:any)=>{this._isMounted && this.setState({visibility: data?.key, changed: true})}} options={[{key: 'PUBLIC', text: i18n.t('app:public')}, {key: 'PRIVATE', text: i18n.t('app:private')}, {key: 'INHERIT', text: i18n.t('app:inherit')}]}/>
                  </div>
                </div>
                <ShimmeredDetailsList
                className={this.state.currentProjects.length === 0 || !this.state.showCurrentProjects ? 'mt-3 mb-2 height-fix' : 'mt-3'}
                items={this.state.showCurrentProjects ? this.state.currentProjects : []}
                columns={columnsProjects}
                onRenderDetailsHeader={onRenderDetailsHeaderProjects}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                shimmerLines={12}
                constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentProjects.length === 0 && this.state.showCurrentProjects && <div className='d-flex mb-3' style={{height: '47px', background: 'white', fontSize:12, color: '#bfbfbf', marginTop: '54px'}}>
                  <span className='m-auto'>{this.state.loadingEntities ? <Spinner size={SpinnerSize.small}/> : i18n.t('app:empty')}</span>
                </div>}
                <ShimmeredDetailsList
                className={this.state.currentContracts.length === 0 || !this.state.showCurrentContracts ? 'mt-3 mb-2 height-fix' : 'mt-3'}
                items={this.state.showCurrentContracts ? this.state.currentContracts : []}
                columns={columnsContracts}
                onRenderDetailsHeader={onRenderDetailsHeaderContracts}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                shimmerLines={12}
                constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentContracts.length === 0 && this.state.showCurrentContracts && <div className='d-flex' style={{height: '47px', background: 'white', fontSize:12, color: '#bfbfbf', marginTop: '54px'}}>
                    <span className='m-auto'>{this.state.loadingEntities ? <Spinner size={SpinnerSize.small}/> : i18n.t('app:empty')}</span>
                </div>}
                <ShimmeredDetailsList
                className={this.state.currentContacts.length === 0 || !this.state.showCurrentContacts ? 'mt-3 mb-2 height-fix' : 'mt-3'}
                items={this.state.showCurrentContacts ? this.state.currentContacts : []}
                columns={columnsContacts}
                onRenderDetailsHeader={onRenderDetailsHeaderContacts}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                shimmerLines={12}
                constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentContacts.length === 0 && this.state.showCurrentContacts && <div className='d-flex' style={{height: '47px', background: 'white', fontSize:12, color: '#bfbfbf', marginTop: '54px'}}>
                    <span className='m-auto'>{this.state.loadingEntities ? <Spinner size={SpinnerSize.small}/> : i18n.t('app:empty')}</span>
                </div>}
                <ShimmeredDetailsList
                className={this.state.currentCompanies.length === 0 || !this.state.showCurrentCompanies ? 'mt-3 mb-2 height-fix' : 'my-3'}
                items={this.state.showCurrentCompanies ? this.state.currentCompanies : []}
                columns={columnsCompanies}
                onRenderDetailsHeader={onRenderDetailsHeaderCompanies}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                shimmerLines={12}
                constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentCompanies.length === 0 && this.state.showCurrentCompanies && <div className='d-flex' style={{height: '47px', background: 'white', fontSize:12, color: '#bfbfbf', marginTop: '54px'}}>
                    <span className='m-auto'>{this.state.loadingEntities ? <Spinner size={SpinnerSize.small}/> : i18n.t('app:empty')}</span>
                </div>}
              </ScrollablePane>
            </div>
          </FluentProvider>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=>{this.close()}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton form='form' type='submit' text={i18n.t('app:save')} disabled={loading} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

}

function doesTextStartWith(item: any, filterText: string): boolean {
  let email = item.email
  return item.text.toLowerCase().indexOf(filterText.toLowerCase()) > -1 || (email && email.toLowerCase().indexOf(filterText.toLowerCase()) > -1);
}

function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
  return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
  if (!personas || !personas.length || personas.length === 0) {
      return false;
  }
  return personas.filter(item => item.text === persona.text).length > 0;
}