import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import i18n from '../../i18n';
import { Text } from '@fluentui/react'
import { userService } from '../../_services';

export interface Props {
  callback: any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
}

export class FormProjectDelete extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false,
      loading: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      this.props.callback({showDeleteProjectForm: false})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private async _delete() {
    this._isMounted && this.setState({loading: true})
    const ids = this.props.selection.map((item)=>{return item.id})
    userService.deleteProjects(ids).then(()=>{
      this._isMounted && this.setState({hidden: true});
      this.props.getCurrentContent(true);
    }).catch((error)=>{
      this._isMounted && this.setState({hidden: true, loading: false})
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else {
        console.log(error)
        this.props.showAlertDialog('Error', "An error occurred trying to delete the selected item. Please try again.")
      }
    })
  }

  public render() {

    const { loading } = this.state;

    return(
      <Dialog
        hidden={this.state.hidden}
        dialogContentProps={{
          type: DialogType.normal,
          title: this.props.selection.length > 1 ? i18n.t('app:deleteProjects') : i18n.t('app:deleteProject'),
          onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
        }}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: 360 } },
          dragOptions: undefined,
          className: "ask-delete-item-dialog",
          onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
        }}
      >
        <div className="w-100">
          <Text block variant={'large'}>
            {this.props.selection.length > 1 ? i18n.t('app:deleteProjects2') : i18n.t('app:deleteProject2')}
          </Text>

          <p className="tip text-secondary mt-3">
            <Icon iconName="Info" className="align-bottom mr-1" /> <span className="font-weight-bold">Keyboard shortcut:</span> you can delete items by pressing <span className="keyStyles">Delete</span>.
          </p> 
          
          <DialogFooter>
            <DefaultButton disabled={loading} onClick={()=>{this._isMounted && this.setState({hidden: true})}} text={i18n.t('app:cancel')} />
            <PrimaryButton disabled={loading} autoFocus onClick={ ()=>{this._delete()} } text={i18n.t('app:delete')} />
          </DialogFooter>
        </div>
      </Dialog>
    )
  }

}
