import * as React from 'react';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import "moment/min/locales";
import { ConstrainMode, DetailsListLayoutMode, IColumn, Icon, SelectionMode } from '@fluentui/react';
import i18n from "i18next";
import { ListExplorer } from '../_components/ListExplorer';
import { userService } from '../_services/user.service';
import { useParams } from 'react-router-dom';
import { AvatarGroup, AvatarGroupItem, AvatarGroupPopover, Persona, Tooltip } from '@fluentui/react-components';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

export interface Props {
  userData: any;
  appPlatform: string,
  isOnline: boolean,
  page: string,
  maintenance: boolean,
  socket: any,
  pusher: any,
  pusherMessage: any,
  params: any
}

export interface State { 
  userData: any;
  breadcrumbPath: any[];
  searchQuery: any;
  syncStatus: any;
  itemsFull: any;
  items: any;
  columns: any[];
  selItem:any;
  isLoading: boolean;
  selection: any[];
  itemType: string;
  loadingMore: boolean;
  page: number;
  possibleContacts: any[];
}

class ViewsPage extends React.Component<Props, State> {
  private _isMounted: boolean;
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private headerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();
  private loadingMore: boolean = false;


  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      userData: null,
      breadcrumbPath: this.props.params.id === 'project' ? [
        {
        text: i18n.t('app:projects'),
        key: 'projects',
        onClick: ()=>{history.push('/projects')}
        },
        {
          text: i18n.t('app:views'),
          key: 'views',
        }
      ] : this.props.params.id === 'contract' ? [
        {
          text: i18n.t('app:contracts'),
          key: 'contracts',
          onClick: ()=>{history.push('/contracts')}
          },
          {
            text: i18n.t('app:views'),
            key: 'views',
          }
      ] : this.props.params.id === 'contact' ? [
        {
          text: i18n.t('app:contacts'),
          key: 'contacts',
          onClick: ()=>{history.push('/contacts')}
          },
          {
            text: i18n.t('app:views'),
            key: 'views',
          }
      ] : this.props.params.id === 'company' ? [
        {
          text: i18n.t('app:companies'),
          key: 'companies',
          onClick: ()=>{history.push('/companies')}
        },
        {
          text: i18n.t('app:views'),
          key: 'views',
        }
      ] : this.props.params.id === 'pipeline' ? [
        {
          text: i18n.t('app:pipelines'),
          key: 'pipelines',
          onClick: ()=>{history.push('/pipelines')}
        },
        {
          text: i18n.t('app:views'),
          key: 'views',
        }
      ] : this.props.params.id === 'email' ? [
        {
          text: i18n.t('app:emails'),
          key: 'emails',
          onClick: ()=>{history.push('/emails/preview')}
        },
        {
          text: i18n.t('app:views'),
          key: 'views',
        }
      ] : this.props.params.id === 'note' ? [
        {
          text: i18n.t('app:notes'),
          key: 'notes',
          onClick: ()=>{history.push('/notes/preview')}
          },
          {
            text: i18n.t('app:views'),
            key: 'views',
          }
      ] : this.props.params.id === 'conversations' ? [
        {
          text: i18n.t('app:conversations'),
          key: 'conversations',
          onClick: ()=>{history.push('/conversations/preview')}
        },
        {
          text: i18n.t('app:views'),
          key: 'views',
        }
      ] : this.props.params.id === 'tasks' ? [
        {
          text: i18n.t('app:tasks'),
          key: 'tasks',
          onClick: ()=>{history.push('/tasks/preview')}
        },
        {
          text: i18n.t('app:views'),
          key: 'views',
        }
      ] : [],
      searchQuery: [],
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      itemsFull: [],
      items: [],
      columns: [],
      selItem: null,
      isLoading: false,
      selection: [],
      itemType: this.props.params.id,
      loadingMore: false,
      page: 0,
      possibleContacts: []
    }
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this._getColumns();
    let items:any = [];
    const params = new URLSearchParams(document.location.search);
    params.forEach((value, key)=>{
      let field:string = params.get(key) || "";
      let fields:string[] = field.toLowerCase().split(';');
      if (field !== 'noFilter') {
        if (field === 'all') {
          items = items.filter((item:any)=>{
            return (item[key] && item[key] !== '')
          })
        } else if (field === 'none') {
          items = items.filter((item:any)=>{
            return (!item[key] || item[key] === '')
          })
        } else if (fields && fields.length > 0) {
          items = items.filter((item:any)=>{
            return (item[key] && fields.includes(item[key].toLowerCase()))
          })
        }
      }
    })
    this.getCurrentContent();
  }

  public componentDidUpdate(prevProps: any) {

  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private getCurrentContent(hidden = false) {
      this._isMounted && this.setState({
        isLoading: true,
        items: hidden ? this.state.items : [],
        syncStatus: {
          message: "Syncing",
          type: "info",
          icon: "spinner"
        }
      })
      const options = {
        itemType: this.props.params.id.toUpperCase()
      }
      userService.searchViews(options).then((response)=>{
        let items:any = response.data.last ? response.data.content : [...response.data.content, null];
        userService.getRepoUsers('ADMIN,INTERNAL').then((response)=>{
          let contacts = response.data;
          for (let i=0; i<contacts.length; i++) {
              contacts[i].key = contacts[i].id
              contacts[i].text = contacts[i].name + (contacts[i].surname ? (' ' + contacts[i].surname) : '')
          }
          this._isMounted && this.setState({
            items: items,
            possibleContacts: contacts,
            isLoading: false,
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          });
        }).catch(()=>{
          this._isMounted && this.setState({
            isLoading: false,
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          });
        })
        //this._sortBySavedColumn(newItems);
      }).catch((error)=>{
        this._isMounted && this.setState({
          items: [],
          isLoading: false,
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          }
        });
      })
  }

  private getMoreContent() {

    const { loadingMore } = this.state;

    if (!loadingMore) {
      this.loadingMore = true;
      setTimeout(()=>{
        this._isMounted && this.setState({
          loadingMore: true,
        })
        if (document.location.search && document.location.search.length > 0) {
          let page = this.state.page;
          page += 1;
          const options = {
            itemType: this.props.params.id.toUpperCase(),
            page: page
          }
          userService.searchViews(options).then(async (response:any)=>{
            let oldItems = this.state.items;
            oldItems = oldItems.filter((item)=> {return item});
            var newItems:any = oldItems.concat(response.data.content)
            this.loadingMore = false;
            this._isMounted && this.setState({
              items: response.data.last ? [...newItems.map((item:any)=>{return {...item, key: item.id}})] : [...newItems.map((item:any)=>{return {...item, key: item.id}}), null],
              page: page,
              isLoading: false,
              loadingMore: false,
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            });
          }).catch(()=>{
            this.loadingMore = false;
            this._isMounted && this.setState({
              loadingMore: false,
            });
          })
        } else {
          this.loadingMore = false;
          this._isMounted && this.setState({
            loadingMore: false,
          });
        }
      },50)
    }
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private _getColumns() {

    const columns = [
      {
        key: 'name',
        name: i18n.t('app:name'),
        fieldName: 'name',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        //onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return  <span title={ item.name}>{ item.name }</span>
        }      
      },
      {
        key: 'quickAccessUserIds',
        name: i18n.t('app:visibleTo'),
        fieldName: 'quickAccessUserIds',
        minWidth: 64,
        maxWidth: 128,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        onRender: (item: any) => {
          if (item.allQuickAccess) {
            return  <span title={i18n.t('app:everyone')}>{ i18n.t('app:allUsers')}</span>
          } else if (item.quickAccessUserIds && item.quickAccessUserIds.length > 0) {
            let assignedInline:any[] = [];
            let assignedOverflow:any[] = [];
            if (item && item.quickAccessUserIds && this.state.possibleContacts) {
              let assigned: any[] = this.state.possibleContacts.filter((user) => {
                return item.quickAccessUserIds && item.quickAccessUserIds.includes(user.id) 
              })
              if (assigned.length > 4) {
                assignedInline = assigned.slice(0,4).sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
                assignedOverflow = assigned.slice(4).sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
              } else {
                assignedInline = assigned.sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
              }
            }
  
            return (
              <div className='d-flex align-items-center'>
                <AvatarGroup layout="stack" size={20}>
                  {assignedInline.map((item) => (
                    <Tooltip key={item.id} content={<Persona secondaryText={item.email} className='mt-1' textAlignment='center' size='small' name={(item.name || '') + ' ' + (item.surname || '')} avatar={{color: 'colorful'}} />} relationship="label">
                      <AvatarGroupItem name={(item.name || '') + ' ' + (item.surname || '')} />
                    </Tooltip>
                  ))}
                  { assignedOverflow && assignedOverflow.length > 0 && <AvatarGroupPopover>
                    {assignedOverflow.map((item) => (
                      <AvatarGroupItem name={(item.name || '') + ' ' + (item.surname || '')} key={item.id} />
                    ))}
                  </AvatarGroupPopover> }
                </AvatarGroup>
              </div>  
            )
          } else {
            return  <span>-</span>
          }

        },
        isPadded: true
      },
      {
        key: 'query',
        name: i18n.t('app:query'),
        fieldName: 'query',
        minWidth: 400,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        //onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return  <span title={ item.query}>{ item.query }</span>
        }      
      }
    ]

    this._isMounted && this.setState({columns: columns})
  }

  public render() {

    const { items, columns } = this.state;

    return(
      <div>
        <Header
          ref={instance => { this.headerRef = instance; }}
          userData={ this.props.userData }
          breadcrumbPath={ this.state.breadcrumbPath }
          sidebarRef={ this.sidebarRef }
          page={this.props.page}
          actions="views"
          getActivity={ null }
          syncStatus={this.state.syncStatus}
          selItem={ this.state.selItem }
          getCurrentContent={this.getCurrentContent.bind(this)}
          selection={this.state.selection}
          items={items}
        />

        <div className="content-wrap d-flex flex-row" >
          <Sidebar
            ref={(instance:any) => { this.sidebarRef = instance; }}
            headerRef={ this.headerRef }
            userData={ this.props.userData }
            page={this.props.page}
            itemType={this.state.itemType}
            items={items}
          />

          <div className="list mr-auto flex-grow-1">
            <ListExplorer
              ref={instance => { this.listExplorerRef = instance; }}
              appPlatform={ this.props.appPlatform }
              isOnline={ this.props.isOnline }
              maintenance={ this.props.maintenance }
              socket={this.props.socket}
              pusher={this.props.pusher}
              userData={this.props.userData}
              foldersList={ [] }
              items={items}
              selItem={ this.state.selItem }
              isCompactMode={true}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              constrainMode={ConstrainMode.unconstrained}
              headerRef={ this.headerRef }
              detailsPanelRef={ this.detailsPanelRef }
              callbackFunction={ this.callbackFunction }
              page={this.props.page}
              sidebarRef={ this.sidebarRef }
              loading={this.state.isLoading}
              searchMore={this.getMoreContent.bind(this)}
            />
          </div>
        </div>
      </div>
    )
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        newCol.isSortedDescending = !currColumn.isSortedDescending;
        newCol.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    let mainColumn = 'pipelineId';
    let newItems:any;
    if (column.key === mainColumn) newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    else if (column.key === 'name' || column.key === '#') newItems = _copyAndSort(items, 'position', currColumn.isSortedDescending, mainColumn);
    else newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending, mainColumn);
    localStorage.setItem("columnsPipelines", JSON.stringify(newColumns))

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private _sortBySavedColumn = (items: any): void => {
    if (typeof(localStorage.getItem("columnsPipelines")) === "string") {
      var dashboardColumns: IColumn[] = JSON.parse(localStorage.getItem("columnsPipelines") || "[]");

      for (let i = 0; i < this.state.columns.length; i++) {
        let column = this.state.columns[i]

        let savedColumn: any = dashboardColumns.filter(savedColumn => {
          return column.key === savedColumn.key
        })
        savedColumn = savedColumn[0]

        if(column && savedColumn) {
          column.isSorted = savedColumn.isSorted;
          column.isSortedDescending = savedColumn.isSortedDescending

          if(column.isSorted) {
            const { columns } = this.state;
            const newColumns: IColumn[] = columns.slice();
            const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        
            let mainColumn = 'pipelineId';
            let newItems:any;
            if (column.key === mainColumn) newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
            else if (column.key === 'name' || column.key === '#') newItems = _copyAndSort(items, 'position', currColumn.isSortedDescending, mainColumn);
            else newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending, mainColumn);
            this._isMounted && this.setState({
              columns: newColumns,
              items: newItems.map((item:any)=>{return {...item, key: item.id}}),
            });
          }
        } else { 
          this._isMounted && this.setState({items: items.map((item:any)=>{return {...item, key: item.id}})})
        }
      }
    } else {
      this._isMounted && this.setState({items: items.map((item:any)=>{return {...item, key: item.id}})})
    }
  };

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean, secondColumnKey?: string): T[] {
  const key = columnKey as keyof any;
  const key2 = secondColumnKey as keyof any;

  if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
    if (!a) return -1;
    if (!b) return 1;
    if (a[key2] < b[key2]) return -1;
    if (a[key2] > b[key2]) return 1;
    else if (a[key] < b[key]) return isSortedDescending ? 1 : -1;
    else if (a[key] > b[key]) return isSortedDescending ? -1 : 1;
    else return 0;
  })
  else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  
}

export default withParams(ViewsPage);
