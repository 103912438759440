import { Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, Icon, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import i18n from '../../i18n';
import { userService } from '../../_services/user.service';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { reorder } from '../../_helpers/sharedFunctions';
import { CustomFieldPreview } from '../CustomFieldPreview';

export interface Props {
  callback:any;
  entityType: string;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  loadingProperties: boolean;
  customProperties: any[];
  propertiesOrder: any[];
  error:any;
  changed: boolean;
}

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: "2px",
  border: "1px solid lightgrey",
  background: isDragging ? "white" : "white",
  boxShadow: isDragging ? '8px 8px 4px rgba(0,0,0,0.1)' : '0px 2px 4px rgba(0,0,0,0.1)',
  ...draggableStyle
});

export class FormCustomFieldsTemplate extends React.Component<Props, States> {
  private _isMounted: boolean;
  private _templateId: string;

  private defaultProperties = {
    name: i18n.t('workflow:name'),
    domain: i18n.t('workflow:domain'),
    contactIds: i18n.t('workflow:contactIds'),
    urls: i18n.t('workflow:urls'),
    phoneNumbers: i18n.t('workflow:phoneNumbers'),
    emailAddresses: i18n.t('workflow:emailAddresses'),
    locationAddresses: i18n.t('workflow:locationAddresses'),
    nickName: i18n.t('workflow:nickName'),
    description: i18n.t('workflow:description'),
    trash: i18n.t('workflow:trash'),
    createdAt: i18n.t('workflow:createdAt'),
    modifiedAt: i18n.t('workflow:modifiedAt'),
  }

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._templateId = "";
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      loadingProperties: true,
      customProperties: [],
      propertiesOrder: [],
      error: '',
      changed: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    userService.getCustomProperties('', this.props.entityType).then((response)=>{
      let customProperties = response.data.map(item=>{return {...item, id: item.id, name: item.name, position: item.customPropertyTypes.find(x=>{return x.entityType === this.props.entityType}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === this.props.entityType}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}});
      customProperties = customProperties.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
      for (let i in customProperties) {
        let type = 'singleLine';
        if (customProperties[i].fieldType === 'FILE') {
          type = 'file'
        } else if (customProperties[i].fieldType === 'CONTACT') {
          type = 'contact'
        } else if (customProperties[i].fieldType === 'TAG' && customProperties[i].multipleValues) {
          type = 'multiSelection'
        } else if (customProperties[i].fieldType === 'TAG') {
          type = 'dropdownSelection'
        } else if (customProperties[i].fieldType === 'DATE') {
          type = 'datePicker'
        } else if (customProperties[i].fieldType === 'BOOLEAN') {
          type = 'checkbox'
        } else if (customProperties[i].fieldType === 'NUMBER') {
          type = 'number'
        } else if (customProperties[i].fieldType === 'STRING' && customProperties[i].multiLine) {
          type = 'multiLine'
        } else if (customProperties[i].fieldType === 'STRING') {
          type = 'singleLine'
        }
        customProperties[i].type = type;
      }
      this._isMounted && this.setState({customProperties: customProperties, loading: false, loadingProperties: false})
    }).catch((error)=>{
      this._isMounted && this.setState({loading: false, loadingProperties: false})
    })
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      if (this.props.entityType === 'PROJECT') this.props.callback({showProjectTemplateForm: false})
      else if (this.props.entityType === 'CONTRACT') this.props.callback({showContractTemplateForm: false})
      else if (this.props.entityType === 'CONTACT') this.props.callback({showContactTemplateForm: false})
      else if (this.props.entityType === 'COMPANY') this.props.callback({showCompanyTemplateForm: false})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private _toggleCheck(index) {
    let items = this.state.customProperties;
    items[index].required = !items[index].required;
    this._isMounted && this.setState({customProperties: items})
  }

  private onDragEnd(result) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const items = reorder(this.state.customProperties, source.index, destination.index);
    this._isMounted && this.setState({customProperties:items, changed: true});
  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      this._isMounted && this.setState({hidden:true})
    }
  }

  private _submit(e) {
    if (e) e.preventDefault();
    const {customProperties, loading} = this.state;
    if (loading) return;

    if (customProperties && customProperties.length > 0){
      let properties = customProperties.map((property, index) => {
        return {customPropertyId: property.id, required: property.required, position: index}
      })
      userService.reorderCustomProperties(this.props.entityType, properties)
      .then(()=>{
        this.setState({loading:false, hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        console.log(error)
        this.setState({loading:false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to reorder the fields. Please try again.")
        }
      })
    }
  }

  public render() {

    const { hidden, loading, loadingProperties } = this.state;

    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Fields' src={process.env.PUBLIC_URL + '/icons/' + (this.props.entityType === 'PROJECT' ? 'projects' : this.props.entityType === 'CONTACT' ? 'contacts' : 'companies')+'.svg'} />{i18n.t('app:reorderFields') + ' - ' + (this.props.entityType === 'PROJECT' ? i18n.t('app:project') : this.props.entityType === 'CONTACT' ? i18n.t('app:contact') : i18n.t('app:company'))}</div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: loadingProperties ? 'none' : 'overlay'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog",
            onDismiss: ()=> {this.close()}
          }}
        >
          <div className='mb-4' style={{borderBottom: '1px solid #bfbfbf', width: 'calc(100% - 34px)', position: 'sticky', left: '16px', top: 0, zIndex: 1}}></div>
          <FluentProvider theme={webLightTheme}>
            <form id='form' className='mb-3' style={{padding: '0 16px'}} onSubmit={this._submit.bind(this)}>
              <input className='d-none' type='submit'></input>
              <div>
                <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                  <Droppable droppableId={'emails-column'}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps} >
                        {this.state.customProperties.map((item,index) => {
                          return (
                            <Draggable key={item.id || item.key} draggableId={item.id || item.key} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div className='d-flex align-items-center'>
                                  <Icon iconName='GripperDotsVertical' style={{fontSize: 20}}/>
                                  <span className='mx-4' style={{fontWeight: 600, minWidth: '10px'}}>{index + 1}</span>
                                  <div className='flex-grow-1 mr-4 mb-2'>
                                    <CustomFieldPreview name={item.name} fieldType={item.type} possibleValues={item.possibleValues} reorder={true}/>
                                  </div>
                                  <Checkbox className='ml-auto' checked={item.required} onChange={()=>{this._toggleCheck(index)}} styles={{label: { margin: '6px 0'} }} label={i18n.t('app:required')}/>
                                </div>
                              </div>
                            )}
                          </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              {loadingProperties && <Spinner size={SpinnerSize.medium} className="" />}
            </form>
          </FluentProvider>

          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this.close()}} text={i18n.t('app:cancel')} disabled={loading || !this.state.customProperties || this.state.customProperties.length === 0} />
            <PrimaryButton form='form' type='submit' text={i18n.t('app:save')} disabled={loading || !this.state.customProperties || this.state.customProperties.length === 0} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

}
