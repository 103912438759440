import * as React from 'react';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import "moment/min/locales";
import { ConstrainMode, DetailsListLayoutMode, Dropdown, IColumn, SelectionMode } from '@fluentui/react';
import i18n from "i18next";
import { ListExplorer } from '../_components/ListExplorer';
import { userService } from '../_services/user.service';
import { Link, Persona } from '@fluentui/react-components';

export interface Props {
  userData: any;
  appPlatform: string,
  isOnline: boolean,
  page: string,
  maintenance: boolean,
  socket: any,
  pusher: any,
  pusherMessage: any,
}

export interface State { 
  userData: any;
  breadcrumbPath: any[];
  searchQuery: any;
  syncStatus: any;
  itemsFull: any;
  items: any;
  columns: any[];
  selItem:any;
  isLoading: boolean;
  selection: any[];
}

export class PermissionsPage extends React.Component<Props, State> {
  private _isMounted: boolean;
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private headerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();


  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      userData: null,
      breadcrumbPath: [{
        text: i18n.t('app:permissions'),
        key: 'permissions',
        onClick: ()=>{history.push('/permissions')}
      }],
      searchQuery: [],
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      itemsFull: [],
      items: [],
      columns: [],
      selItem: null,
      isLoading: false,
      selection: []
    }
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this._getColumns();
    let items:any = [];
    const params = new URLSearchParams(document.location.search);
    params.forEach((value, key)=>{
      let field:string = params.get(key) || "";
      let fields:string[] = field.toLowerCase().split(';');
      if (field !== 'noFilter') {
        if (field === 'all') {
          items = items.filter((item:any)=>{
            return (item[key] && item[key] !== '')
          })
        } else if (field === 'none') {
          items = items.filter((item:any)=>{
            return (!item[key] || item[key] === '')
          })
        } else if (fields && fields.length > 0) {
          items = items.filter((item:any)=>{
            return (item[key] && fields.includes(item[key].toLowerCase()))
          })
        }
      }
    })
    this.getCurrentContent();
  }

  public componentDidUpdate(prevProps: any) {

  }

  public componentWillUnmount() {
    this._isMounted = false;
    if (this.props.pusher) {
      this.props.pusher.disconnect();
    }
  }

  private getCurrentContent() {
    this._isMounted && this.setState({
      isLoading: true,
      items: [],
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      }
    })
    userService.getPermissions().then((response) => {
      let permissions = response.data.userPermissions;
      let users = permissions.map((user) => {return user.userId})
      let editors = permissions.filter((user) => {return user.permissionRole === 'EDITOR'}).map((user) => {return user.userId})
      userService.getRepoUsers('ADMIN').then((response)=>{
        let items:any = response.data.map((a) => {return {...a, permissionRole: 'ADMIN', permissionRoleName: i18n.t('app:admin')}});
        if (users && users.length > 0) {
          userService.getRepoUsers('ALL', users.join(',')).then((response)=>{
            let internals = response.data.map((user) => {
              return {...user, permissionRole: editors.includes(user.id) ? 'EDITOR' : 'RESTRICTED_EDITOR', permissionRoleName: editors.includes(user.id) ? i18n.t('app:editor') : i18n.t('app:restrictedEditor')}
            })
            items = items.concat(internals)
            var newItems = _copyAndSort(items, 'name', false, 'role');
            this._isMounted && this.setState({
              items: newItems,
              isLoading: false,
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            });
            this._sortBySavedColumn(newItems);
          }).catch((error)=>{
            this._isMounted && this.setState({
              items: [],
              isLoading: false,
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            })
          })
        } else {
          var newItems = _copyAndSort(items, 'name', false, 'role');
          this._isMounted && this.setState({
            items: newItems,
            isLoading: false,
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          });
          this._sortBySavedColumn(newItems);
        }
      }).catch((error)=>{
        this._isMounted && this.setState({
          items: [],
          isLoading: false,
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          }
        })
      })
    }).catch((error)=>{
      this._isMounted && this.setState({
        items: [],
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      })
    })
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private _getColumns() {

    const columns = [
      {
        key: 'icon',
        name: '#',
        fieldName: 'icon',
        minWidth: 1,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        //onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '28px'}}/>
            :
              <div style={{borderRadius: '24px', height: '28px'}}><Persona className='contact-persona' name={item ? item.name + " " + item.surname : ''} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful" }}/></div>
          )
        }
      },
      {
        key: 'name',
        name: i18n.t('app:firstName'),
        fieldName: 'name',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <span title={ item.name }>{ item.name }</span>
          )
        }
      },
      {
        key: 'surname',
        name: i18n.t('app:lastName'),
        fieldName: 'surname',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <span title={ item.surname }>{ item.surname }</span>
          )
        }
      },
      {
        key: 'email',
        name: i18n.t('app:email'),
        fieldName: 'email',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return <Link href={'mailto:' + item.email} title={ item.email }>{ item.email }</Link>
        }
      },
      {
        key: 'permissionRole',
        name: i18n.t('app:role'),
        fieldName: 'permissionRole',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return <Dropdown className='hover-dropdown' styles={{root: {minWidth: '140px'}}} defaultSelectedKey={item.permissionRole} options={[{key: 'ADMIN', text: 'Admin'},{key: 'EDITOR', text: 'Editor'},{key: 'RESTRICTED_EDITOR', text: 'Restricted editor'}]}/>
        }
      },
    ]

    this._isMounted && this.setState({columns: columns})
  }

  public render() {

    const { items, columns } = this.state;

    return(
      <div>
        <Header
          ref={instance => { this.headerRef = instance; }}
          userData={ this.props.userData }
          breadcrumbPath={ this.state.breadcrumbPath }
          sidebarRef={ this.sidebarRef }
          page={this.props.page}
          actions="permissions"
          getActivity={ null }
          syncStatus={this.state.syncStatus}
          selItem={ this.state.selItem }
          getCurrentContent={this.getCurrentContent.bind(this)}
          selection={this.state.selection}
          items={items}
        />

        <div className="content-wrap d-flex flex-row" >
          <Sidebar
            ref={(instance:any) => { this.sidebarRef = instance; }}
            userData={ this.props.userData }
            page={this.props.page}
          />

          <div className="list mr-auto flex-grow-1">
            <ListExplorer
              ref={instance => { this.listExplorerRef = instance; }}
              appPlatform={ this.props.appPlatform }
              isOnline={ this.props.isOnline }
              maintenance={ this.props.maintenance }
              socket={this.props.socket}
              pusher={this.props.pusher}
              userData={this.props.userData}
              foldersList={ [] }
              items={items}
              selItem={ this.state.selItem }
              isCompactMode={true}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              constrainMode={ConstrainMode.unconstrained}
              headerRef={ this.headerRef }
              detailsPanelRef={ this.detailsPanelRef }
              callbackFunction={ this.callbackFunction }
              page={this.props.page}
              sidebarRef={ this.sidebarRef }
              loading={this.state.isLoading}
            />
          </div>
        </div>
      </div>
    )
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        newCol.isSortedDescending = !currColumn.isSortedDescending;
        newCol.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    let mainColumn = 'role';
    let newItems:any;
    if (column.key === mainColumn) newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    else newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending, mainColumn);
    localStorage.setItem("columnsPermissions", JSON.stringify(newColumns))

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private _sortBySavedColumn = (items: any): void => {
    if (typeof(localStorage.getItem("columnsPermissions")) === "string") {
      var dashboardColumns: IColumn[] = JSON.parse(localStorage.getItem("columnsPermissions") || "[]");

      for (let i = 0; i < this.state.columns.length; i++) {
        let column = this.state.columns[i]

        let savedColumn: any = dashboardColumns.filter(savedColumn => {
          return column.key === savedColumn.key
        })
        savedColumn = savedColumn[0]

        if(column && savedColumn) {
          column.isSorted = savedColumn.isSorted;
          column.isSortedDescending = savedColumn.isSortedDescending

          if(column.isSorted) {
            const { columns } = this.state;
            const newColumns: IColumn[] = columns.slice();
            const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        
            let mainColumn = 'role';
            let newItems:any;
            if (column.key === mainColumn) newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
            else newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending, mainColumn);
            this._isMounted && this.setState({
              columns: newColumns,
              items: newItems.map((item:any)=>{return {...item, key: item.id}}),
            });
          }
        } else { 
          this._isMounted && this.setState({items: items.map((item:any)=>{return {...item, key: item.id}})})
        }
      }
    } else {
      this._isMounted && this.setState({items: items.map((item:any)=>{return {...item, key: item.id}})})
    }
  };

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean, secondColumnKey?: string): T[] {
  const key = columnKey as keyof any;
  const key2 = secondColumnKey as keyof any;
  if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
    if (!a) return -1;
    if (!b) return 1;
    if (a[key2] < b[key2]) return -1;
    if (a[key2] > b[key2]) return 1;
    else if (a[key] < b[key]) return isSortedDescending ? 1 : -1;
    else if (a[key] > b[key]) return isSortedDescending ? -1 : 1;
    else return 0;
  })
  else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}