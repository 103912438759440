import i18n from "i18next";

export function _getActivityAction(item) {
  const {operationType, targets, source} = item;
  const mainTarget = targets ? targets[0] : undefined;
  const targetType = mainTarget ? mainTarget.modelType : undefined;
  const sourcetype = source ? source.modelType : undefined;

  if (operationType === 'CREATE') {
    if (targetType === 'MESSAGE') {
      return i18n.t('activity:commentedOn') + ' ' + i18n.t('activity:'+sourcetype)
    }
    return i18n.t('activity:created') + ' ' + i18n.t('activity:'+targetType)
  } else if (operationType === 'UPDATE') {
    return i18n.t('activity:edited') + ' ' + i18n.t('activity:'+targetType)
  } else if (operationType === 'DELETE') {
    return i18n.t('activity:deleted') + ' ' + i18n.t('activity:'+targetType)
  } 
}

export function _getActivityTitle(item, users) {
  const {operationType, targets, userId} = item;
  const mainTarget = targets ? targets[0] : undefined;
  const targetType = mainTarget ? mainTarget.modelType : undefined;

  if (operationType === 'CREATE') {
    return i18n.t('activity:'+targetType) +' '+ i18n.t('activity:created')  +' '+  i18n.t('activity:by')  +' '+  getContactName(users, userId) 
  } else if (operationType === 'UPDATE') {
    return i18n.t('activity:'+targetType) +' '+ i18n.t('activity:edited')  +' '+  i18n.t('activity:by')  +' '+  getContactName(users, userId) 
  } else if (operationType === 'DELETE') {
    return i18n.t('activity:'+targetType) +' '+ i18n.t('activity:delete')  +' '+  i18n.t('activity:by')  +' '+  getContactName(users, userId) 
  } 
}

export function _getActivityDescription(item) {
  const {operationType, targets} = item;
  const mainTarget = targets ? targets[0] : undefined;
  const targetType = mainTarget ? mainTarget.modelType : undefined;

  if (operationType === 'CREATE') {
    if (targetType === 'MESSAGE') {
      return i18n.t('activity:commentedInA')  +' '
    }
    return i18n.t('activity:'+targetType) +' '+ i18n.t('activity:created')  +' '+  i18n.t('activity:by')  +' '
  } else if (operationType === 'UPDATE') {
    return i18n.t('activity:'+targetType) +' '+ i18n.t('activity:edited')  +' '+  i18n.t('activity:by')  +' '
  } else if (operationType === 'DELETE') {
    return i18n.t('activity:'+targetType) +' '+ i18n.t('activity:delete')  +' '+  i18n.t('activity:by')  +' '
  } 
}

function getContactName(users, id: string) {
  let user = users.find((item) => {
    return item.id === id;
  })

  return user ? (user.name || '') + ' ' + (user.surname || '') : ''
}