import { userConstants } from '../_constants';

const getStoredData = (name: string) => {
  let a = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`);
  return a ? a[1] : '';
}

let token = getStoredData("token");
const initialState = token ? { loggedIn: true, token } : {};

export function authentication(state = initialState, action:any) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}