import { ActionButton, ComboBox, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, Icon, IconButton, Modal, PrimaryButton, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import i18n from '../../i18n';
import { userService } from '../../_services';
import { history } from '../../_helpers';
import { Divider, FluentProvider, Input, Label, Textarea, webLightTheme } from '@fluentui/react-components';
import { CustomField } from '../CustomField';
import { FilePicker } from '../_filePicker/FilePicker';
import ReactDOM from 'react-dom';
import { getItemStyle, reorder } from '../../_helpers/sharedFunctions';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  customFields: any[];
  chosenFields:any;
  chosenFieldsKeys: any;
  items: any[];
  propertiesOrder: any[];
  customProperties: any[];
  loadingProperties: boolean;
  canSubmit: boolean;
  error:any;
  phoneNumbers: any;
  locationAddresses: any;
  emailAddresses: any;
  urls: any;
  contactIds: any;
  profileImage: any;
  name: string;
  nickname: string;
  signatureDate: any;
  startDate: any;
  expirationDate: any;
  fileId: string;
  fileIds: any[];
  description: string;
  stage: string;
  pipelineId: string;
  pipelines: any[];
  stages: any[];
  stageId: string;
  changed: boolean;
}

export class FormContractEdit extends React.Component<Props, States> {
  private _isMounted: boolean;
  private _currentKey: number;


  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props
    this._currentKey = 1;

    this.state = {
      hidden: false,
      loading: false,
      customFields: [{name:"", value: ""}],
      chosenFields: [],
      chosenFieldsKeys: [],
      items: [],
      propertiesOrder: [],
      customProperties: [],
      loadingProperties: true,
      canSubmit: true,
      error: "",
      phoneNumbers: [{key: 1, text: '', addressType: 'MOBILE'}],
      locationAddresses: [],
      emailAddresses: [{key: 1, text: '', addressType: 'WORK'}],
      urls: [""],
      contactIds: [],
      profileImage: null,

      name: this.props.item.name || '',
      nickname: this.props.item.nickname || '',
      signatureDate: this.props.item.signatureDate ? new Date(this.props.item.signatureDate) : undefined,
      startDate: this.props.item.startDate ? new Date(this.props.item.startDate) : undefined,
      expirationDate: this.props.item.expirationDate ? new Date(this.props.item.expirationDate) : undefined,
      fileId: this.props.item.fileId,
      fileIds: this.props.item.fileIds.map((f)=>{
        return {key: (this._currentKey++).toString(), fileId: f}
      }),
      description: this.props.item.description || '',
      pipelineId: '',
      stage: '',
      pipelines: [],
      stages: [],
      stageId: this.props.item.stage ? this.props.item.stage.id : '',
      changed: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    userService.getPipelines().then(response => {
      let pipelines = response.data.map(pipeline => {return {...pipeline, text: pipeline.name, key: pipeline.id}} )
      let pipeline:any = null;
      for (let i in pipelines) {
        for (let j in pipelines[i].stages) {
          if (this.props.item.stage && pipelines[i].stages[j].id === this.props.item.stage.id) pipeline = pipelines[i]
        }
      }
      userService.getCustomProperties('','CONTRACT').then((res) =>{
        let customProperties = res.data.map(item=>{return {...item, id: item.id, name: item.name, position: item.customPropertyTypes.find(x=>{return x.entityType === 'CONTRACT'}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === 'CONTRACT'}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}});
        customProperties = customProperties.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
        this._isMounted && this.setState({
          pipelines: pipelines,
          pipelineId: pipeline ? pipeline.id : '',
          stages: pipeline ? pipeline.stages.map(s => {return {...s, text: s.name, key: s.id}}).sort((a,b) => { return (a.position >= b.position ? 1 : -1)}) : [],
          customProperties: customProperties.map(item => {
            let property = this.props.item.customPropertyValues.find(x=>{return x.customPropertyId === item.id})
            let value = null
            if (property && property.values && property.values.length > 0 && item.fieldType === 'TAG'){
              value = property.values
            } else if (property && property.values && property.values.length === 1){
              value = property.values[0]
            }
            return {...item, key: item.id, value: value, valueId: property ? property.id : null}
          }),
          loadingProperties: false
        })
      }).catch(error=>{
        console.log(error)
        this._isMounted && this.setState({hidden: true})
        this.props.showAlertDialog('Error', i18n.t('app:errorGettingCustomProperties'))
      })
    }).catch((error)=>{
      this._isMounted && this.setState({hidden: true})
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else {
        this.props.showAlertDialog('Error', "An error occurred trying to get existing Pipelines. Please try again.")
      }
    })

  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      this.props.callback({showEditContractForm: false})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private callback(data) {
    this._isMounted && this.setState(data)
  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          history.push('')
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      history.push('')
      this._isMounted && this.setState({hidden:true})
    }
  }

  private updateName(data) {
    this._isMounted && this.setState({name: data.value, changed: true})
  }

  private updateNickname(data) {
    this._isMounted && this.setState({nickname: data.value, changed: true})
  }

  private updateDescription(data) {
    this._isMounted && this.setState({description: data.value, changed: true})
  }

  private _submit(e) {
    if (e) e.preventDefault();
    const {loading} = this.state;
    if (loading) return;
    this._isMounted && this.setState({loading: true})
    const {name, nickname, description, signatureDate, startDate, expirationDate, customProperties, fileIds, stageId} = this.state;
    let canSubmit = true;
  
    let data = {
      name: name || null,
      nickname: nickname || null,
      description: description || null,
      signatureDate: signatureDate,
      startDate: startDate,
      expirationDate: expirationDate,
      fileIds: fileIds.map((f)=>{return f.fileId}),
      stage: stageId && stageId !== 'null' ? stageId : null,
      customPropertyValues: customProperties.map(item=>{return {customPropertyId: item.id, id: item.valueId, values: item.value && Array.isArray(item.value) ? item.value : [item.value]}}).filter(item=>{return item.values && item.values[0] !== null && item.values[0] !== '' && item.values[0] !== undefined})
    }

    if (canSubmit) {
      userService.editContract(this.props.item.id, data).then((response)=>{
        this._isMounted && this.setState({hidden: true, loadingProperties: false})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false, loadingProperties: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to edit the contract. Please try again.")
        }
      })
    } else {
      this._isMounted && this.setState({loading: false, loadingProperties: false, canSubmit: true})
    }
  }

  public render() {

    const { hidden, loading, loadingProperties, name, nickname, signatureDate, startDate, expirationDate, fileIds, description, pipelines, stages, pipelineId, stageId} = this.state;

    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Contract' src={process.env.PUBLIC_URL + '/icons/contracts.svg'} />{i18n.t('app:editContract')}</div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: loadingProperties ? 'none' : 'overlay'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog",
            onDismiss: ()=> {this.close()}
          }}
        >
          <div className='mb-4' style={{borderBottom: '1px solid #bfbfbf', width: 'calc(100% - 34px)', position: 'sticky', left: '16px', top: 0, zIndex: 1}}></div>
          <FluentProvider theme={webLightTheme}>
            <form id='form' className='mb-3' style={{padding: '0 16px'}} onSubmit={this._submit.bind(this)}>
              <input className='d-none' type='submit'></input>
              <div className='d-flex'>
                <div className='flex-grow-1'>
                  <div className='d-flex mt-2'>
                    <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:name')}</Label>
                    <Input className='flex-grow-1' value={name} onChange={(e,data)=>this.updateName(data)} required autoFocus/>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:nickName')}</span>
                    <Input className='flex-grow-1' value={nickname} onChange={(e,data)=>this.updateNickname(data)}/>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='Calendar' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:signatureDate')}</Label>
                    <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={signatureDate} onSelectDate={(date)=>this.setState({signatureDate: date, changed: true})}/>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='Calendar' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:startDate')}</Label>
                    <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={startDate} onSelectDate={(date)=>this.setState({startDate: date, changed: true})}/>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='Calendar' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:expirationDate')}</Label>
                    <DatePicker styles={{textField: {height: '32px'}}} className='flex-grow-1' value={expirationDate} onSelectDate={(date)=>this.setState({expirationDate: date, changed: true})}/>
                  </div>
                  <div className='mt-3 d-flex'>
                    <div className='d-flex'>
                      <Icon iconName='Link' style={{marginTop: '5px', marginRight: '10px'}}/>
                      <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:files')}</span>
                    </div>
                    <div className='flex-grow-1'>
                      <DragDropContext onDragEnd={this.onDragEnd_fileIds.bind(this)}>
                        <Droppable droppableId={'fileIds-column'}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps} >
                              {this.state.fileIds.map((item,index) => {
                                return (
                                  <Draggable key={item.id || item.key} draggableId={item.id || item.key} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div className='d-flex align-items-center my-3'>
                                        <Icon iconName='GripperDotsVertical' style={{fontSize: 20}}/>
                                        <span className='mx-3' style={{fontWeight: 600, minWidth:'10px'}}>{index + 1}</span>
                                        <Input className='flex-grow-1' style={{marginRight: index !== 0 ? '' : '32px'}} value={fileIds[index].fileId} onChange={(e,data)=>{this._updateFileIds(index, 'fileId', data.value)}} required/>
                                        <TooltipHost
                                          content={i18n.t('app:openFilePicker')}
                                          styles={{root: {borderRadius: '4px', position: 'absolute', marginTop: '0px', right: '49px', width: '34px', height: '30px' }}}
                                        >
                                        <IconButton styles={{icon: {color: 'rgb(50,49,48)', height: '20px'}, root: {borderRadius: '4px', width: '34px', height: '29px'}}} iconProps={{iconName: 'DriveSynergyOS'}} onClick={()=>this.openSynergyOSpicker(index)}/>
                                        </TooltipHost>
                                        {index !== 0 && <IconButton className='ml-auto' onClick={()=>this._deleteFileIds(index)} iconProps={{iconName: 'Delete'}} />}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <ActionButton className={fileIds.length > 0 ? 'mt-3' : ''} styles={{root: {height: '28px'}}} onClick={()=> this._addFileIds()} iconProps={{iconName: 'Add'}} text={i18n.t('app:addFile')}/>
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='D365ProjectOperationsIcon' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:pipeline')}</span>
                    <div className='d-flex align-items-center flex-grow-1' style={{position: 'relative'}}>
                      <ComboBox className='flex-grow-1' styles={{ root: {borderRadius: '4px'}}} allowFreeform selectedKey={pipelineId} onChange={(e, data:any) => this.updatePipeline(data)} options={pipelines}/>
                      {pipelineId && <IconButton style={{position: 'absolute', right: '40px', height: '28px', width: '0px', fontSize: '12px', background: 'transparent'}} styles={{icon: {fontSize: '12px', color: 'rgb(96, 94, 92)'}}} iconProps={{iconName: 'Clear'}} onClick={(e)=>this._isMounted && this.setState({stageId: '', stages: [], pipelineId: '', changed: true})}/>}
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='D365ProjectOperationsIcon' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:stage')}</span>
                    <div className='d-flex align-items-center flex-grow-1' style={{position: 'relative'}}>
                      <Dropdown className='flex-grow-1' styles={{ title: {borderRadius: '4px'}}} selectedKey={stageId} onChange={(e, data:any) => this._isMounted && this.setState({stageId: data.id, changed: true})} options={stages} disabled={pipelineId === ''}/>
                      {stageId && <IconButton style={{position: 'absolute', right: '40px', height: '28px', width: '0px', fontSize: '12px', background: 'transparent'}} styles={{icon: {fontSize: '12px', color: 'rgb(96, 94, 92)'}}} iconProps={{iconName: 'Clear'}} onClick={(e)=>this._isMounted && this.setState({stageId: '', changed: true})}/>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex mt-3 mb-3'>
                <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:notes')}</span>
                <Textarea className='flex-grow-1' value={description} onChange={(e,data)=>this.updateDescription(data)}/>
              </div>
              {this.state.loadingProperties ? <Spinner/> : this.state.customProperties && this.state.customProperties.length > 0 && <> 
                <Divider className='mt-3'/>
                <div className='mt-3'>
                  { this.state.customProperties.map(item => {
                    return <div className='my-3' key={item.key || item.id}>
                      <CustomField userData={this.props.userData} customFields={this.state.customProperties} field={item} callback={this.callback.bind(this)}/>
                    </div>
                  })}
                </div>
              </>}
            </form>
          </FluentProvider>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this.close()}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton form='form' type='submit' text={i18n.t('app:save')} disabled={loading} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

  private updatePipeline(data) {
    if (data) {
      let pipeline = this.state.pipelines.find((p)=>{return p.id === data.id })
      this._isMounted && this.setState({  
        pipelineId: data.key,
        stages: pipeline.stages.map(s => {return {...s, text: s.name, key: s.id}}).sort((a,b) => { return (a.position >= b.position ? 1 : -1)}),
        stageId: '',
        changed: true
      })
    }
  }

  private _updateFileIds(index, parameter, value) {
    let options = this.state.fileIds;
    options[index][parameter] = value;
    this._isMounted && this.setState({fileIds: options, changed: true});
  }

  private _addFileIds() {
    this._currentKey += 1;
    let options = this.state.fileIds;
    options.push({key: this._currentKey.toString(), fileId: ''})
    this._isMounted && this.setState({fileIds: options, changed: true});
  }

  private _deleteFileIds(index) {
    let options = this.state.fileIds;
    options.splice(index, 1);
    this._isMounted && this.setState({fileIds: options, changed: true});
  }

  private onDragEnd_fileIds(result) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const items = reorder(this.state.fileIds, source.index, destination.index);
    this._isMounted && this.setState({fileIds:items, changed: true});
  }

  private openSynergyOSpicker(index:number) {
    this._openFilePicker().then((result:any)=>{
        if (result && result.length > 0) {
            userService.getDocument(result).then((response)=>{
                const file = response.data;
                this._updateFileIds(index, 'fileId', file.id)
            }).catch((error)=>{
                console.log(error)
            })
        }
    }).catch((error)=>{
        console.log(error)
    })
  }

  private _openFilePicker = async () => {
    return await new Promise((resolve, reject) => {
        var showDialog = true;

        var confirm = (id) => {
          showDialog = false;
          renderDialog();
          resolve(id);
        }

        var cancel = () => {
          showDialog = false;
          renderDialog()
          reject()
        }

        var div = document.createElement('div');
        var renderDialog = () => {
          ReactDOM.render(
          <Modal
            isOpen={showDialog}
            onDismiss={()=>{cancel()}}
            isBlocking={true}
            styles={{main: {maxWidth: '1100px', width: '100%', maxHeight: '700px'}}}
            className="file-picker"
          >
            <div className="w-100 h-100">
              <div className="d-flex flex-row noselect align-items-center" style={{height: '28px', backgroundColor: '#2c2c2c', color: 'white', fontWeight: 400, fontSize: '14px'}}>
                <Icon iconName='DriveSynergyOS' className='pr-2 pl-2' style={{height: '24px'}}/><span style={{fontWeight: 600}}>SynergyOS File Picker</span>
                <IconButton onClick={()=>{showDialog=false;renderDialog()}} iconProps={{iconName: 'Cancel'}} className='mr-0 ml-auto file-picker-close-button' style={{height: '28px', color: 'white'}}/>
              </div>
              <FilePicker
                {...this.props}
                startFolder={this.props.userData.repository.id}
                location={undefined}
                database={undefined}
                isOnline={true}
                maintenance={false}
                fileExplorerType='fileExplorer'
                userData={this.props.userData}
                repoData={this.props.userData.repository} 
                foldersList={this.props.foldersList}
                newFolder={null}
                repoUsers={this.props.repoUsers}
                callback={(id) => {confirm(id)}}
                selectDocument={true}
              />
            </div>
          </Modal>
          , div
          )
        }
      renderDialog();
    })
  }
}
