import { userService } from '../../_services';

export default class PDFJs {

  init = (source, element, selFile) => {

    function openFile() {
      var url = selFile;

      var iframeId = 'pdfIframe';
      if (!document.getElementById(iframeId)) {
          var iframe:any = document.createElement('iframe');
          iframe.src = `${process.env.PUBLIC_URL}/pdfjs/web/viewer.html?file=${encodeURI(url)}&indexerApiUrl=${process.env.REACT_APP_SYNERGY_API_URL + userService.getServerDomain() + process.env.REACT_APP_INDEXER_API_URL}&artifactApiUrl=${process.env.REACT_APP_SYNERGY_API_URL + userService.getServerDomain() + process.env.REACT_APP_ARTIFACT_API_URL}&membershipApiUrl=${process.env.REACT_APP_SYNERGY_API_URL + userService.getServerDomain() + process.env.REACT_APP_MEMBERSHIP_API_URL}`;
          iframe.width = '100%';
          iframe.height = '100%';
          iframe.frameBorder = "none";
          iframe.name= iframeId;
          iframe.id= iframeId;
          element.appendChild(iframe);
      } else {
          var existingIframe: any = document.getElementById(iframeId);
          existingIframe.src = `./pdfjs/web/viewer.html?file=${encodeURI(url)}&indexerApiUrl=${process.env.REACT_APP_SYNERGY_API_URL + userService.getServerDomain() + process.env.REACT_APP_INDEXER_API_URL}&artifactApiUrl=${process.env.REACT_APP_SYNERGY_API_URL + userService.getServerDomain() + process.env.REACT_APP_ARTIFACT_API_URL}&membershipApiUrl=${process.env.REACT_APP_SYNERGY_API_URL + userService.getServerDomain() + process.env.REACT_APP_MEMBERSHIP_API_URL}`;
      }
    }

    openFile()
  }
}