import * as React from 'react';
import { history } from '../_helpers';
import { CompanyNewForm } from './_forms/FormCompanyNew';
import ReactDOM from 'react-dom';
import { AlertDialog } from './_alerts/AlertDialog';
import { Breadcrumb, IconButton, Spinner, SpinnerSize, CommandBar, ICommandBarItemProps, Icon, IIconProps, ContextualMenuItemType, VerticalDivider, MessageBarType } from '@fluentui/react';
import i18n from "i18next";
import { FormCompanyEdit } from './_forms/FormCompanyEdit';
import { FormCompanyDelete } from './_forms/FormCompanyDelete';
import { FormContactNew } from './_forms/FormContactNew';
import { FormContactEdit } from './_forms/FormContactEdit';
import { FormContactDelete } from './_forms/FormContactDelete';
import { FormProjectDelete } from './_forms/FormProjectDelete';
import { FormProjectNew } from './_forms/FormProjectNew';
import { FormProjectEdit } from './_forms/FormProjectEdit';
import { FormProjectEditStage } from './_forms/FormProjectEditStage';
import { Titlebar } from './Titlebar';
import { FormPipelineNew } from './_forms/FormPipelineNew';
import { FormPipelineEdit } from './_forms/FormPipelineEdit';
import { FormPipelineDelete } from './_forms/FormPipelineDelete';
import { AlertConfirmDialog } from './_alerts/AlertConfirmDialog';
import { FormCustomFieldsNew } from './_forms/FormCustomFieldsNew';
import { FormCustomFieldsTemplate } from './_forms/FormCustomFieldsTemplate';
import { FormNote } from './_forms/FormNote';
import { FormConversation } from './_forms/FormConversation';
import { FormCustomFieldsEdit } from './_forms/FormCustomFieldsEdit';
import { FormCustomFieldsDelete } from './_forms/FormCustomFieldsDelete';
import * as XLSX from 'xlsx';
import { FormRelations } from './_forms/FormRelations';
import { userService } from '../_services';
import { FormContractNew } from './_forms/FormContractNew';
import { FormContractEdit } from './_forms/FormContractEdit';
import { FormContractDelete } from './_forms/FormContractDelete';
import { FormNoteEdit } from './_forms/FormNoteEdit';
import { FormNoteDelete } from './_forms/FormNoteDelete';
import { FormEmailDelete } from './_forms/FormEmailDelete';
import { FormConversationDelete } from './_forms/FormConversationDelete';
import { FormTask } from './_forms/FormTask';
import { FormConversationEdit } from './_forms/FormConversationEdit';
import { FormPermissions } from './_forms/FormPermissions';
import { FormTaskDelete } from './_forms/FormTaskDelete';
import { FormTaskEdit } from './_forms/FormTaskEdit';
import { _getMainPermissions, _getSubPermissions } from '../_helpers/permissionsFunctions';
import { FormView } from './_forms/FormView';
import { FormViewEdit } from './_forms/FormViewEdit';
import { FormViewDelete } from './_forms/FormViewDelete';

const detectMob = () => {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

export interface Props {
  match: {
    url: string
  }
}

export interface HeaderState {
  breadcrumbPath: any,
  syncStatus: any,
  showRightPanel: boolean,
  errorMessage: string,
  searchOptions: string,
  isTrash: boolean,
  showNewCompanyForm: boolean,
  showEditCompanyForm: boolean,
  showDeleteCompanyForm: boolean,
  showNewContactForm: boolean,
  showEditContactForm: boolean,
  showDeleteContactForm: boolean,
  showNewProjectForm: boolean,
  showEditProjectForm: boolean,
  showEditProjectStageForm: boolean,
  showDeleteProjectForm: boolean,
  showNewPipelineForm: boolean,
  showEditPipelineForm: boolean;
  showDeletePipelineForm: boolean;
  showNewContractForm: boolean,
  showEditContractForm: boolean,
  showDeleteContractForm: boolean,
  showCustomFieldsForm: boolean;
  showEditCustomFieldsForm: boolean;
  showProjectTemplateForm: boolean;
  showContactTemplateForm: boolean;
  showContractTemplateForm: boolean;
  showCompanyTemplateForm: boolean;
  showDeleteCustomFieldsForm: boolean;
  showFormRelations: boolean;
  showNoteForm: boolean;
  showNoteFormEdit: boolean;
  showNoteFormDelete: boolean;
  showConversationForm: boolean;
  showConversationFormEdit: boolean;
  showConversationFormDelete: boolean;
  showEmailFormDelete: boolean;
  showTaskForm: string;
  showTaskFormEdit: boolean;
  showTaskFormDelete: boolean;
  showFormPermissions: boolean;
  showFormView: boolean;
  showFormViewEdit: boolean;
  showFormViewDelete: boolean;
  selectedView: any,
  pipelineItem: any;
  syncingUsers: boolean;
}

export class Header extends React.Component<Props, HeaderState> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.props = props
    this.state = {
      breadcrumbPath: props.breadcrumbPath,
      syncStatus: props.syncStatus,
      showRightPanel: props.showRightPanel,
      errorMessage: "",
      searchOptions: "filesByName",
      isTrash: false,
      showNewCompanyForm: false,
      showEditCompanyForm: false,
      showDeleteCompanyForm: false,
      showNewContactForm: false,
      showEditContactForm: false,
      showDeleteContactForm: false,
      showNewProjectForm: false,
      showEditProjectForm: false,
      showEditProjectStageForm: false,
      showDeleteProjectForm: false,
      showNewPipelineForm: false,
      showEditPipelineForm: false,
      showDeletePipelineForm: false,
      showNewContractForm: false,
      showEditContractForm: false,
      showDeleteContractForm: false,
      showCustomFieldsForm: false,
      showEditCustomFieldsForm: false,
      showDeleteCustomFieldsForm: false,
      showProjectTemplateForm: false,
      showContactTemplateForm: false,
      showContractTemplateForm: false,
      showCompanyTemplateForm: false,
      showFormRelations: false,
      showNoteForm: false,
      showNoteFormEdit: false,
      showNoteFormDelete: false,
      showConversationForm: false,
      showConversationFormEdit: false,
      showConversationFormDelete: false,
      showEmailFormDelete: false,
      showTaskForm: '',
      showTaskFormEdit: false,
      showTaskFormDelete: false,
      showFormPermissions: false,
      showFormView: false,
      showFormViewEdit: false,
      showFormViewDelete: false,
      selectedView: null,
      pipelineItem: null,
      syncingUsers: false
    }

  }

  public componentDidMount() {
    this._isMounted = true;
  }

  public componentDidUpdate(prevProps) {
    if(this.props.breadcrumbPath !== prevProps.breadcrumbPath) {
      setTimeout(()=>{
        this._isMounted && this.setState({breadcrumbPath: this.props.breadcrumbPath})
      },1)
    }

    if(this.props.showRightPanel !== prevProps.showRightPanel) {
      this._isMounted && this.setState({showRightPanel: this.props.showRightPanel})
    }
  }

  private _showNewCompanyForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showNewCompanyForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showEditCompanyForm() {
    _getMainPermissions(this.props.userData, this.props.selItem.id).then((response:number) => {
      if (response >= 3) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showEditCompanyForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showDeleteCompanyForm() {
    this._isMounted && this.setState({showDeleteCompanyForm: true})
  }

  private _showNewContactForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showNewContactForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showEditContactForm() {
    _getMainPermissions(this.props.userData, this.props.selItem.id).then((response:number) => {
      if (response >= 3) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showEditContactForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showDeleteContactForm() {
    this._isMounted && this.setState({showDeleteContactForm: true})
  }

  private _showNewProjectForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showNewProjectForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _updateProjectStatus(selection, projectStatus) {
    let status = projectStatus ? "CLOSE" : "OPEN"
    selection.length > 0 && userService.editProjects(selection.map((item=>{return item.id})), status, null).then(()=>{
      this.props.getCurrentContent(true);
    })
  }

  private _showEditProjectForm() {
    _getMainPermissions(this.props.userData, this.props.selItem.id).then((response:number) => {
      if (response >= 3) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showEditProjectForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showEditProjectStageForm() {
    this._isMounted && this.setState({showEditProjectStageForm: true})
  }

  private async _showDeleteProjectForm() {
    this._isMounted && this.setState({showDeleteProjectForm: true})
  }

  private _showNewPipelineForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showNewPipelineForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showEditPipelineForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showEditPipelineForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showNewContractForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showNewContractForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showEditContractForm() {
    _getMainPermissions(this.props.userData, this.props.selItem.id).then((response:number) => {
      if (response >= 3) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showEditContractForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showDeleteContractForm() {
    this._isMounted && this.setState({showDeleteContractForm: true})
  }

  private _showDeletePipelineForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showDeletePipelineForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showCustomFieldsForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showCustomFieldsForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showEditCustomFieldsForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showEditCustomFieldsForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showDeleteCustomFieldsForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showDeleteCustomFieldsForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showProjectTemplateForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showProjectTemplateForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showContactTemplateForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showContactTemplateForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showContractTemplateForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showContractTemplateForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showCompanyTemplateForm() {
    _getMainPermissions(this.props.userData, this.props.userData.repository.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showCompanyTemplateForm: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showEditRelationsForm() {
    if (this.props.selItem && (this.props.selItem.id.startsWith('Note-') || this.props.selItem.id.startsWith('Email-') || this.props.selItem.id.startsWith('Conversation-') || this.props.selItem.id.startsWith('Task-')) ) {
      _getSubPermissions(this.props.userData, this.props.selItem, this.props.parentId).then((response:number) => {
        if (response >= 3) {
          this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
        } else {
          this._isMounted && this.setState({showFormRelations: true})
        }
      }).catch((error)=>{
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.showAlertDialog('Error', error.data.message)
        } else {
          this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
        }
      })
    } else {
      _getMainPermissions(this.props.userData, this.props.selItem.id).then((response:number) => {
        if (response >= 3) {
          this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
        } else {
          this._isMounted && this.setState({showFormRelations: true})
        }
      }).catch((error)=>{
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.showAlertDialog('Error', error.data.message)
        } else {
          this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
        }
      })
    }
  }

  private _showNoteForm() {
    this._isMounted && this.setState({showNoteForm: true})
  }

  private _showNoteFormEdit() {
    _getSubPermissions(this.props.userData, this.props.selItem, this.props.parentId).then((response:number) => {
      if (response >= 3) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showNoteFormEdit: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showNoteFormDelete() {
    this._isMounted && this.setState({showNoteFormDelete: true})
  }

  private _showConversationForm() {
    this._isMounted && this.setState({showConversationForm: true})
  }

  private _showConversationFormEdit() {
    _getSubPermissions(this.props.userData, this.props.selItem, this.props.parentId).then((response:number) => {
      if (response >= 3) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showConversationFormEdit: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showConversationFormDelete() {
    this._isMounted && this.setState({showConversationFormDelete: true})
  }

  private _showEmailFormDelete() {
    this._isMounted && this.setState({showEmailFormDelete: true})
  }

  private _showTaskForm() {
    this._isMounted && this.setState({showTaskForm: 'true'})
  }

  private _showTaskFormEdit() {
    _getSubPermissions(this.props.userData, this.props.selItem, this.props.parentId).then((response:number) => {
      if (response >= 3) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showTaskFormEdit: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private _showTaskFormDelete() {
    this._isMounted && this.setState({showTaskFormDelete: true})
  }

  private _showFormPermissions() {
    _getMainPermissions(this.props.userData, this.props.page === 'permissions' ? this.props.userData?.repository?.id : this.props.selItem.id).then((response:number) => {
      if (response >= 2) {
        this.showAlertDialog(i18n.t('app:error'), i18n.t('app:noPermissionForThisAction'))
      } else {
        this._isMounted && this.setState({showFormPermissions: true})
      }
    }).catch((error)=>{
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.showAlertDialog('Error', error.data.message)
      } else {
        this.showAlertDialog('Error', "An error occurred trying to check action permissions.")
      }
    })
  }

  private isInQuickAccess(selection) {
    if (selection && selection[0]) {
      let view:any = selection[0];
      let isInQuickAccess = view.quickAccessUserIds.find((id)=>{return id === this.props.userData.user.id})
      if (isInQuickAccess) return true;
      else return false;
    }
  }

  private _addViewToQuickAccess(node) {
    userService.searchViews({itemType: node.itemType, ids: [node.id]}).then((response)=>{
      const views = response.data.content;
      for (let i in views) {
        if (views[i] && views[i].id === node.id && this.props.userData) {
          let view:any = views[i];
          view.quickAccessUserIds = view.quickAccessUserIds.filter((id)=>{return id !== this.props.userData.user.id})
          view.quickAccessUserIds.push(this.props.userData.user.id)
          const data = {
            name: view.name,
            page: view.page,
            query: view.query,
            itemType: view.itemType,
            quickAccessUserIds: view.quickAccessUserIds,
            allQuickAccess: view.allQuickAccess
          }
          userService.editView(view.id, data).then(()=>{
            this.props.sidebarRef && this.props.sidebarRef.refreshTree()
            if (this.props.page === 'views') this.props.getCurrentContent(true)
          })
        }
      }
    })
  }

  private _removeViewFromQuickAccess(node) {
    userService.searchViews({itemType: node.itemType, ids: [node.id]}).then((response)=>{
      const views = response.data.content;
      for (let i in views) {
        if (views[i] && views[i].id === node.id && this.props.userData) {
          let view:any = views[i];
          view.quickAccessUserIds = view.quickAccessUserIds.filter((id)=>{return id !== this.props.userData.user.id})
          const data = {
            name: view.name,
            page: view.page,
            query: view.query,
            itemType: view.itemType,
            quickAccessUserIds: view.quickAccessUserIds,
            allQuickAccess: view.allQuickAccess
          }
          userService.editView(view.id, data).then(()=>{
            this.props.sidebarRef && this.props.sidebarRef.refreshTree()
            if (this.props.page === 'views') this.props.getCurrentContent(true)
          })
        }
      }
    })
  }

  private _showFormView() {
    this._isMounted && this.setState({showFormView: true})
  }

  private _showFormViewEdit(selectedView: any) {
    this._isMounted && this.setState({showFormViewEdit: true, selectedView: selectedView})
  }

  private _showFormViewDelete(selectedView: any) {
    this._isMounted && this.setState({showFormViewDelete: true, selectedView: selectedView})
  }

  private callbackFunction(childData) {
    this._isMounted && this.setState(childData)
  }

  private async showAlertDialog(title:string , description:string) {
    return await new Promise((resolve, reject) => {
      var showDialog = true;

      var close = () => {
        showDialog = false;
        renderDialog()
        resolve(true)
      }

      var div = document.createElement('div');
      var renderDialog = () => {
        showDialog && ReactDOM.render(
          <AlertDialog showDialog callbackFunction={close} title={title} description={description}/>
          , div
        )
      }

      renderDialog();
    })
  }

  private async showConfirmDialog(title:string , description:string) {
    return await new Promise((resolve, reject) => {
      var showDialog = true;

      var close = (result) => {
        showDialog = false;
        renderDialog()
        resolve(result)
      }

      var div = document.createElement('div');
      var renderDialog = () => {
        showDialog && ReactDOM.render(
          <AlertConfirmDialog showDialog callback={close} title={title} description={description}/>
          , div
        )
      }

      renderDialog();
    })
  }

  public render() {
    const { showRightPanel, showNewCompanyForm, showEditCompanyForm, showDeleteCompanyForm, showNewContactForm, showEditContactForm, showDeleteContactForm, showNewProjectForm, showEditProjectForm, showEditProjectStageForm, showDeleteProjectForm, showNewPipelineForm, showEditPipelineForm, showDeletePipelineForm, showNewContractForm, showEditContractForm, showDeleteContractForm, showCustomFieldsForm, showEditCustomFieldsForm, showDeleteCustomFieldsForm, showProjectTemplateForm, showContactTemplateForm, showContractTemplateForm, showCompanyTemplateForm, showFormRelations, showNoteForm, showNoteFormEdit, showNoteFormDelete, showEmailFormDelete, showConversationForm, showConversationFormEdit, showConversationFormDelete, showTaskForm, showTaskFormEdit, showTaskFormDelete, showFormPermissions, showFormView, showFormViewEdit, showFormViewDelete } = this.state;
    const inProjectsPage = this.props.page === 'projectsExplorer' || this.props.page === 'clientsExplorer'  || this.props.page === 'milestonesExplorer'  || this.props.page === 'pipelinesExplorer'  || this.props.page === 'boardExplorer'
    const inCustomFieldsPage = this.props.page === 'customFieldsProject' || this.props.page === 'customFieldsContact' || this.props.page === 'customFieldsContract' || this.props.page === 'customFieldsCompany'
    const inEntitiesPage = this.props.page.includes('Data') || this.props.page.includes('email') || this.props.page.includes('note') || this.props.page.includes('conversation') || this.props.page.includes('task')
    const selection = this.props.selection || this.props.listExplorerRef?._selection?.getSelection();
    const selectedCount = selection ? selection.length : 0;
    let onePipelineSelected = false;
    if(this.props.page === "pipelines") {
      let differentPipeline = selection.filter((item) => {return item.pipelineId !== selection[0].pipelineId})
      onePipelineSelected = differentPipeline.length === 0
    }

    const docActionItems: ICommandBarItemProps[] = [
      {
        key: 'showLeftPanel',
        title: i18n.t('app:toggleLeftPanel'), 
        iconProps: {
          iconName: 'SidePanel',
        },
        className: 'sidePanel-button',
        onClick: () => this._toggleLeftPanel(),
      },
      {
        key: 'new',
        text: i18n.t('app:new'),
        iconProps: { iconName: 'Add' },
        className:'green-icon',
        subMenuProps: {
          items: [
            { 
              key: 'headerMainEntities',
              text: i18n.t('app:mainEntities'),
              itemType: ContextualMenuItemType.Header,
              style: {fontSize: '14px', margin: 0},
              iconProps: {style: {width: 0, margin: 0}},
              onRender:()=>{return <span>{i18n.t('app:mainEntities')}</span>},
              className: 'callout-header'
            },
            {
              key: 'createProject',
              text: i18n.t('app:project'),
              onRenderIcon: ()=> {return <img alt='projects' style={{height: '22px', width: '22px', marginLeft: '2px', marginRight: '2px'}} src={process.env.PUBLIC_URL + '/icons/projects.svg'} />},
              iconProps: { iconName: 'Work' },
              className:'blue-icon',
              onClick: this._showNewProjectForm.bind(this),
              //hidden: !inProjectsPage
            },
            {
              key: 'createContract',
              text: i18n.t('app:contract'),
              onRenderIcon: ()=> {return <img alt='contract' style={{height: '22px', width: '22px', marginLeft: '2px', marginRight: '2px'}} src={process.env.PUBLIC_URL + '/icons/contracts.svg'} />},
              iconProps: { iconName: 'TextDocument' },
              className:'blue-icon',
              onClick: this._showNewContractForm.bind(this),
              //hidden: this.props.page !== "contactsExplorer"
            },
            {
              key: 'createContact',
              text: i18n.t('app:contact'),
              onRenderIcon: ()=> {return <img alt='contacts' style={{height: '22px', width: '22px', marginLeft: '2px', marginRight: '2px'}} src={process.env.PUBLIC_URL + '/icons/contacts.svg'} />},
              iconProps: { iconName: 'Contact' },
              className:'blue-icon',
              onClick: this._showNewContactForm.bind(this),
              //hidden: this.props.page !== "contactsExplorer"
            },
            {
              key: 'createCompany',
              text: i18n.t('app:company'),
              onRenderIcon: ()=> {return <img alt='companies' style={{height: '22px', width: '22px', marginLeft: '2px', marginRight: '2px'}} src={process.env.PUBLIC_URL + '/icons/companies.svg'} />},
              iconProps: { iconName: 'CityNext2' },
              className:'blue-icon',
              onClick: this._showNewCompanyForm.bind(this),
              //hidden: this.props.page !== "companiesExplorer"
            },
            { 
              key: 'headerSubEntities',
              text: i18n.t('app:subEntities'), 
              itemType: ContextualMenuItemType.Header, 
              style: {fontSize: '14px', margin: 0},
              iconProps: {style: {width: 0, margin: 0}},
              onRender:()=>{return <span>{i18n.t('app:subEntities')}</span>},
              className: 'callout-header'
            },
            {
              key: 'customFields',
              text: i18n.t('app:customField'),
              iconProps: { iconName: 'FieldNotChanged' },
              className:'blue-icon',
              onClick: this._showCustomFieldsForm.bind(this),
              hidden: !inCustomFieldsPage
            },
            {
              key: 'createNote',
              text: i18n.t('app:createNote'),
              onRenderIcon: ()=> {return <img alt='notes' style={{height: '22px', width: '22px', marginLeft: '2px', marginRight: '2px'}} src={process.env.PUBLIC_URL + '/icons/documents.svg'} />},
              iconProps: { iconName: 'QuickNote' },
              className:'blue-icon',
              onClick: this._showNoteForm.bind(this),
            },
            {
              key: 'createConversation',
              text: i18n.t('app:createConversation'),
              onRenderIcon: ()=> {return <img alt='conversations' style={{transform: 'scale(1, 1.3)', height: '22px', width: '22px', marginLeft: '2px', marginRight: '2px'}} src={process.env.PUBLIC_URL + '/icons/conversations.svg'} />},
              iconProps: { iconName: 'CannedChat' },
              className:'blue-icon',
              onClick: this._showConversationForm.bind(this),
            },
            {
              key: 'createTask',
              text: i18n.t('app:createTask'),
              onRenderIcon: ()=> {return <img alt='tasks' style={{height: '22px', width: '22px', marginLeft: '2px', marginRight: '2px'}} src={process.env.PUBLIC_URL + '/icons/tasks.svg'} />},
              iconProps: { iconName: 'IconSetsFlag' },
              className:'blue-icon',
              onClick: this._showTaskForm.bind(this),
            },
          ]
        }
      },
      {
        key: 'item',
        text: inProjectsPage ? i18n.t('app:project')
        : inCustomFieldsPage ? i18n.t('app:customField')
        : this.props.page === "companiesExplorer" || this.props.page === "companiesPreview" ? i18n.t('app:company') 
        : this.props.page === "contractsExplorer" || this.props.page === "contractsPreview" ? i18n.t('app:contract') 
        : this.props.page === "contactsExplorer" || this.props.page === "contactsPreview" ? i18n.t('app:contact') 
        : this.props.page === "pipelines" ? i18n.t('app:pipeline')
        : i18n.t('app:item'),
        iconProps: { iconName: 'PageEdit' },
        className:'blue-icon',
        disabled: selectedCount < 1 || (inEntitiesPage && this.props.selItem && (!this.props.selItem.id.startsWith('Note-') && !this.props.selItem.id.startsWith('Email-') && !this.props.selItem.id.startsWith('Conversation-') && !this.props.selItem.id.startsWith('Task-'))),
        subMenuProps: {
          items: [
            {
              key: 'editCompany',
              text: i18n.t('app:editCompany'),
              iconProps: { iconName: 'Edit' },
              className:'blue-icon',
              onClick: this._showEditCompanyForm.bind(this),
              hidden: this.props.page !== "companiesExplorer" && this.props.page !== "companiesPreview",
              disabled: !this.props.selItem
            },
            {
              key: 'deleteCompany',
              text: i18n.t('app:delete'),
              iconProps: { iconName: 'Delete' },
              className:'grey-icon',
              onClick: this._showDeleteCompanyForm.bind(this),
              hidden: this.props.page !== "companiesExplorer" && this.props.page !== "companiesPreview",
              disabled: selectedCount < 1
            },
            {
              key: 'editContact',
              text: i18n.t('app:editContact'),
              iconProps: { iconName: 'Edit' },
              className:'blue-icon',
              onClick: this._showEditContactForm.bind(this),
              hidden: this.props.page !== "contactsExplorer",
              disabled: !this.props.selItem
            },
            {
              key: 'deleteContact',
              text: i18n.t('app:delete'),
              iconProps: { iconName: 'Delete' },
              className:'grey-icon',
              onClick: this._showDeleteContactForm.bind(this),
              hidden: this.props.page !== "contactsExplorer",
              disabled: selectedCount < 1
            },
            {
              key: 'deleteContract',
              text: i18n.t('app:delete'),
              iconProps: { iconName: 'Delete' },
              className:'grey-icon',
              onClick: this._showDeleteContactForm.bind(this),
              hidden: this.props.page !== "contractsExplorer",
              disabled: selectedCount < 1
            },
            {
              key: 'editContract',
              text: i18n.t('app:editContract'),
              iconProps: { iconName: 'Edit' },
              className:'blue-icon',
              onClick: this._showEditContactForm.bind(this),
              hidden: this.props.page !== "contractsExplorer",
              disabled: !this.props.selItem
            },
            {
              key:'updateProjectStatus',
              name: selection && selection[0] && selection[0].status === "OPEN" ? i18n.t('app:setStatusClose') : i18n.t('app:setStatusOpen'),
              iconProps: { iconName: selection && selection[0] && selection[0].status === "OPEN" ? 'StatusCircleInner' : 'StatusCircleRing' },
              className:'blue-icon',
              onClick: () => this._updateProjectStatus(selection, selection[0].status === "OPEN"),
              hidden: !inProjectsPage,
              disabled: selectedCount < 1,
            },
            {
              key: 'editProjectStage',
              text: i18n.t('app:editProjectStage'),
              iconProps: { iconName: 'Edit' },
              className:'blue-icon',
              onClick: this._showEditProjectStageForm.bind(this),
              hidden: !inProjectsPage,
              disabled: selectedCount < 1
            },
            {
              key: 'editProject',
              text: i18n.t('app:editProject'),
              iconProps: { iconName: 'Edit' },
              className:'blue-icon',
              onClick: this._showEditProjectForm.bind(this),
              hidden: !inProjectsPage,
              disabled: !this.props.selItem
            },
            {
              key: 'deleteProject',
              text: i18n.t('app:delete'),
              iconProps: { iconName: 'Delete' },
              className:'grey-icon',
              onClick: this._showDeleteProjectForm.bind(this),
              hidden: !inProjectsPage,
              disabled: selectedCount < 1
            },
            {
              key: 'editPipeline',
              text: i18n.t('app:editPipeline'),
              iconProps: { iconName: 'Edit' },
              className:'blue-icon',
              onClick: this._showEditPipelineForm.bind(this),
              hidden: this.props.page !== "pipelines",
              disabled: selectedCount < 1  || !onePipelineSelected
            },
            {
              key: 'deletePipeline',
              text: i18n.t('app:deletePipeline'),
              iconProps: { iconName: 'Delete' },
              className:'grey-icon',
              onClick: this._showDeletePipelineForm.bind(this),
              hidden: this.props.page !== "pipelines",
              disabled: selectedCount < 1  || !onePipelineSelected
            },
            {
              key: 'editCustomField',
              text: i18n.t('app:editCustomField'),
              iconProps: { iconName: 'Edit' },
              className:'blue-icon',
              onClick: this._showEditCustomFieldsForm.bind(this),
              hidden: !inCustomFieldsPage,
              disabled: !this.props.selItem
            },
            {
              key: 'deleteCustomField',
              text: i18n.t('app:deleteCustomField'),
              iconProps: { iconName: 'Delete' },
              className:'grey-icon',
              onClick: this._showDeleteCustomFieldsForm.bind(this),
              hidden: !inCustomFieldsPage,
              disabled: selectedCount < 1
            },
            {
              key: 'editItem',
              text: i18n.t('app:editItem'),
              iconProps: { iconName: 'Edit' },
              className:'blue-icon',
              onClick: this.props.selItem && (this.props.selItem.id || '').startsWith('Note-') ? this._showNoteFormEdit.bind(this) : this.props.selItem && (this.props.selItem.id || '').startsWith('Conversation-') ? this._showConversationFormEdit.bind(this) : this.props.selItem && (this.props.selItem.id || '').startsWith('Task-') ? this._showTaskFormEdit.bind(this) : undefined,
              hidden: !inEntitiesPage,
              disabled: !this.props.selItem || (this.props.selItem.id || '').startsWith('Email-') || this.props.selItem.noPermission
            },
            {
              key: 'deleteItem',
              text: i18n.t('app:delete'),
              iconProps: { iconName: 'Delete' },
              className:'grey-icon',
              onClick: selectedCount >= 1 && (selection[0]?.id || '').startsWith('Note-') ? this._showNoteFormDelete.bind(this) : selectedCount >= 1 && (selection[0]?.id || '').startsWith('Conversation-') ? this._showConversationFormDelete.bind(this) : selectedCount >= 1 && (selection[0]?.id || '').startsWith('Email-') ? this._showEmailFormDelete.bind(this) : selectedCount >= 1 && (selection[0]?.id || '').startsWith('Task-') ? this._showTaskFormDelete.bind(this) : undefined,
              hidden: !inEntitiesPage,
              disabled: selectedCount < 1 || (selection && selection?.find((item)=>{return item.noPermission}))
            },
            {
              key: 'addToQuickAccess',
              text: i18n.t('app:addToQuickAccess'),
              iconProps: { iconName: 'Add' },
              className:'blue-icon',
              onClick: () => selection && this._addViewToQuickAccess(selection[0]),
              hidden: this.props.page !== "views" || (selection && (this.isInQuickAccess(selection) || (selection[0] && selection[0].allQuickAccess))),
              disabled: selectedCount !== 1
            },
            {
              key: 'removeFromQuickAccess',
              text: i18n.t('app:removeFromQuickAccess'),
              iconProps: { iconName: 'Remove' },
              className:'grey-icon',
              onClick: () => selection && this._removeViewFromQuickAccess(selection[0]),
              hidden: this.props.page !== "views" || (selection && (!this.isInQuickAccess(selection) || (selection[0] && selection[0].allQuickAccess))),
              disabled: selectedCount !== 1
            },
            {
              key: 'editView',
              text: i18n.t('app:editView'),
              iconProps: { iconName: 'Edit' },
              className:'blue-icon',
              onClick: selection && this._showFormViewEdit.bind(this, selection[0]),
              hidden: this.props.page !== "views",
              disabled: selectedCount !== 1
            },
            {
              key: 'deleteView',
              text: i18n.t('app:deleteView'),
              iconProps: { iconName: 'Delete' },
              className:'grey-icon',
              onClick: selection && this._showFormViewDelete.bind(this, selection[0]),
              hidden: this.props.page !== "views",
              disabled: selectedCount !== 1
            },
          ]
        }
      },
      {
        key: 'view',
        text: i18n.t('app:view'),
        iconProps: { iconName: 'View' },
        className:'grey-icon',
        hidden: this.props.page === 'note' || this.props.page === 'email' || this.props.page === 'conversation' || this.props.page === 'task',
        subMenuProps: {
          styles:{container: {overflow: 'hidden'}},
          items: [
            { 
              key: 'viewAs',
              text: i18n.t('app:viewAs'), 
              itemType: ContextualMenuItemType.Header, 
              style: {fontSize: '14px', margin: 0},
              iconProps: {style: {width: 0, margin: 0}},
              onRender:()=>{return <span>{i18n.t('app:viewAs')}</span>},
              className: 'callout-header'
            },
            {
              key: 'list',
              text: i18n.t('app:listView'),
              onRenderIcon: () => { // Provide custom font awesome element
                return <div className='d-flex align-items-center' style={{maxHeight: '36px'}} >
                  <div style={{marginLeft: '5px', width:'20px', maxHeight: '36px'}}>
                    { this.props.page !== 'companiesPreview' && this.props.page !== 'tasksPreview' && this.props.page !== 'conversationsPreview' && this.props.page !== 'notesPreview' && this.props.page !== 'emailsPreview' && <Icon style={{maxHeight: '36px', color: 'rgb(16, 110, 190)'}} iconName='Checkmark' /> }
                  </div>
                  <Icon style={{marginRight: '5px', marginLeft: '5px'}} iconName='BulletedList' />
                </div>
              },
              className:'grey-icon',
              onClick: ()=> this.props.page === 'tasksPreview' ? history.push("/tasks" + document.location.search)
               : this.props.page === 'conversationsPreview'  ? history.push("/conversations" + document.location.search)
               : this.props.page === 'notesPreview'  ? history.push("/notes" + document.location.search)
               : this.props.page === 'emailsPreview'  ? history.push("/emails" + document.location.search)
               : this.props.page === 'companiesPreview'  ? history.push("/companies" + document.location.search)
               : undefined,
              hidden: inProjectsPage,
            },
            {
              key: 'panel',
              text: i18n.t('app:panelView'),
              onRenderIcon: () => { // Provide custom font awesome element
                return <div className='d-flex align-items-center' style={{maxHeight: '36px'}} >
                  <div style={{marginLeft: '5px', width:'20px', maxHeight: '36px'}}>
                    { this.props.page !== 'companiesExplorer' && this.props.page !== 'tasks' && this.props.page !== 'conversations' && this.props.page !== 'notes' && this.props.page !== 'emails' && <Icon style={{maxHeight: '36px', color: 'rgb(16, 110, 190)'}} iconName='Checkmark' /> }
                  </div>
                  <Icon style={{marginRight: '5px', marginLeft: '5px'}} iconName='SidePanel' />
                </div>
              },
              className:'grey-icon',
              onClick: ()=> this.props.page === 'tasks' ? history.push("/tasks/preview" + document.location.search)
              : this.props.page === 'conversations' ? history.push("/conversations/preview" + document.location.search)
              : this.props.page === 'notes' ? history.push("/notes/preview" + document.location.search)
              : this.props.page === 'emails' ? history.push("/emails/preview" + document.location.search)
              : this.props.page === 'companiesExplorer'  ? history.push("/companies/preview" + document.location.search)
              : undefined,
              hidden: this.props.page !== 'tasks' && this.props.page !== 'tasksPreview' && this.props.page !== 'conversations' && this.props.page !== 'conversationsPreview' && this.props.page !== 'notes' && this.props.page !== 'notesPreview' && this.props.page !== 'emails' && this.props.page !== 'emailsPreview'  && this.props.page !== 'companiesExplorer' && this.props.page !== 'companiesPreview',
            },
            {
              key: 'projectsList',
              text: i18n.t('app:listView'),
              onRenderIcon: () => { // Provide custom font awesome element
                return <div className='d-flex align-items-center' style={{maxHeight: '36px'}} >
                  <div style={{marginLeft: '5px', width:'20px', maxHeight: '36px'}}>
                    { this.props.page !== 'boardExplorer' && <Icon style={{maxHeight: '36px', color: 'rgb(16, 110, 190)'}} iconName='Checkmark' /> }
                  </div>
                  <Icon style={{marginRight: '5px', marginLeft: '5px'}} iconName='BulletedList' />
                </div>
              },
              className:'grey-icon',
              onClick: ()=> history.push("/projects/list" + document.location.search),
              hidden: !inProjectsPage
            },
            {
              key: 'projectsBoard',
              text: i18n.t('app:boardView'),
              iconProps: { iconName: 'TripleColumn' },
              onRenderIcon: () => { // Provide custom font awesome element
                return <div className='d-flex align-items-center' style={{maxHeight: '36px'}} >
                  <div style={{marginLeft: '5px', width:'20px', maxHeight: '36px'}}>
                    { this.props.page === 'boardExplorer' && <Icon style={{maxHeight: '36px', color: 'rgb(16, 110, 190)'}} iconName='Checkmark' /> }
                  </div>
                  <Icon style={{marginRight: '5px', marginLeft: '5px'}} iconName='TripleColumn' />
                </div>
              },
              className:'grey-icon',
              onClick: ()=> history.push("/projects/board/" + document.location.search),
              disabled: this.props.sidebarRef && this.props.sidebarRef.props && this.props.sidebarRef.props.pipelineId === "all",
              hidden: !inProjectsPage
            },
            { 
              key: 'groupBy',
              text: i18n.t('app:groupBy'), 
              itemType: ContextualMenuItemType.Header, 
              style: {fontSize: '14px', margin: 0},
              iconProps: {style: {width: 0, margin: 0}},
              onRender:()=>{return <span>{i18n.t('app:groupBy')}</span>},
              className: 'callout-header ' + (inProjectsPage ? '' : 'd-none')
            },
            {
              key: 'projectsNothing',
              text: i18n.t('app:nothing'),
              onRenderIcon: () => { // Provide custom font awesome element
                return <div className='d-flex align-items-center' style={{maxHeight: '36px'}} >
                  <div style={{marginLeft: '5px', width:'20px', maxHeight: '36px'}}>
                    { this.props.page === 'projectsExplorer' && <Icon style={{maxHeight: '36px', color: 'rgb(16, 110, 190)'}} iconName='Checkmark' /> }
                  </div>
                  <Icon style={{marginRight: '5px', marginLeft: '5px'}} iconName='FabricFolder' />
                </div>
              },
              className:'grey-icon',
              onClick: ()=> history.push("/projects/list" + document.location.search),
              hidden: !inProjectsPage
            },
            {
              key: 'projectsCompany',
              text: i18n.t('app:company'),
              iconProps: { iconName: 'CityNext2' },
              onRenderIcon: () => { // Provide custom font awesome element
                return <div className='d-flex align-items-center' style={{maxHeight: '36px'}} >
                  <div style={{marginLeft: '5px', width:'20px', maxHeight: '36px'}}>
                    { this.props.page === 'boardExplorer' && <Icon style={{maxHeight: '36px', color: 'rgb(16, 110, 190)'}} iconName='Checkmark' /> }
                  </div>
                  <Icon style={{marginRight: '5px', marginLeft: '5px'}} iconName='TripleColumn' />
                </div>
              },
              className:'grey-icon',
              onClick: ()=> history.push("/projects/clients" + document.location.search),
              hidden: !inProjectsPage
            },
            {
              key: 'projectsPipeline',
              text: i18n.t('app:pipeline'),
              onRenderIcon: () => { // Provide custom font awesome element
                return <div className='d-flex align-items-center' style={{maxHeight: '36px'}} >
                  <div style={{marginLeft: '5px', width:'20px', maxHeight: '36px'}}>
                    { this.props.page === 'pipelinesExplorer' && <Icon style={{maxHeight: '36px', color: 'rgb(16, 110, 190)'}} iconName='Checkmark' /> }
                  </div>
                  <Icon style={{marginRight: '5px', marginLeft: '5px'}} iconName='D365ProjectOperationsIcon' />
                </div>
              },
              className:'grey-icon',
              onClick: ()=> history.push("/projects/pipeline" + document.location.search),
              hidden: !inProjectsPage
            },
            {
              key: 'projectsMilestone',
              text: i18n.t('app:stage'),
              onRenderIcon: () => { // Provide custom font awesome element
                return <div className='d-flex align-items-center' style={{maxHeight: '36px'}} >
                  <div style={{marginLeft: '5px', width:'20px', maxHeight: '36px'}}>
                    { this.props.page === 'milestonesExplorer' && <Icon style={{maxHeight: '36px', color: 'rgb(16, 110, 190)'}} iconName='Checkmark' /> }
                  </div>
                  <Icon style={{marginRight: '5px', marginLeft: '5px'}} iconName='CRMProcesses' />
                </div>
              },
              className:'grey-icon',
              onClick: ()=> history.push("/projects/stages" + document.location.search),
              hidden: !inProjectsPage
            },
          ]
        }
      },
      {
        key: 'separator',
        itemType: ContextualMenuItemType.Divider,
        onRender: () => <VerticalDivider styles={{divider: {height: '28px', position: 'relative', top: '-2px', margin: '0 6px', backgroundColor: '#E5E5E5'}}}/>,
        iconProps: {style: {width: 0, margin: 0}}
      },
      {
        key: 'createCompany',
        text: i18n.t('app:createCompany'),
        iconProps: { iconName: 'Add' },
        className:'green-icon',
        onClick: this._showNewCompanyForm.bind(this),
        hidden: this.props.page !== "companiesExplorer" && this.props.page !== "companiesPreview",
      },
      {
        key: 'editCompany',
        text: i18n.t('app:editCompany'),
        iconProps: { iconName: 'Edit' },
        className:'blue-icon',
        onClick: this._showEditCompanyForm.bind(this),
        hidden: this.props.page !== "companiesExplorer" && this.props.page !== "companiesPreview",
        disabled: !this.props.selItem
      },
      {
        key: 'deleteCompany',
        text: i18n.t('app:delete'),
        iconProps: { iconName: 'Delete' },
        className:'grey-icon',
        onClick: this._showDeleteCompanyForm.bind(this),
        hidden: this.props.page !== "companiesExplorer" && this.props.page !== "companiesPreview",
        disabled: selectedCount < 1
      },
      {
        key: 'createContact',
        text: i18n.t('app:createContact'),
        iconProps: { iconName: 'Add' },
        className:'green-icon',
        onClick: this._showNewContactForm.bind(this),
        hidden: this.props.page !== "contactsExplorer"
      },
      {
        key: 'editContact',
        text: i18n.t('app:editContact'),
        iconProps: { iconName: 'Edit' },
        className:'blue-icon',
        onClick: this._showEditContactForm.bind(this),
        hidden: this.props.page !== "contactsExplorer",
        disabled: !this.props.selItem
      },
      {
        key: 'deleteContact',
        text: i18n.t('app:delete'),
        iconProps: { iconName: 'Delete' },
        className:'grey-icon',
        onClick: this._showDeleteContactForm.bind(this),
        hidden: this.props.page !== "contactsExplorer",
        disabled: selectedCount < 1
      },
      {
        key: 'createProject',
        text: i18n.t('app:createProject'),
        iconProps: { iconName: 'Add' },
        className:'green-icon',
        onClick: this._showNewProjectForm.bind(this),
        hidden: !inProjectsPage
      },
      {
        key: 'editProjectStage',
        text: i18n.t('app:editProjectStage'),
        iconProps: { iconName: 'Edit' },
        className:'blue-icon',
        onClick: this._showEditProjectStageForm.bind(this),
        hidden: !inProjectsPage,
        disabled: selectedCount < 1
      },
      {
        key: 'editProject',
        text: i18n.t('app:editProject'),
        iconProps: { iconName: 'Edit' },
        className:'blue-icon',
        onClick: this._showEditProjectForm.bind(this),
        hidden: !inProjectsPage,
        disabled: !this.props.selItem
      },
      {
        key: 'deleteProject',
        text: i18n.t('app:delete'),
        iconProps: { iconName: 'Delete' },
        className:'grey-icon',
        onClick: this._showDeleteProjectForm.bind(this),
        hidden: !inProjectsPage,
        disabled: selectedCount < 1
      },
      {
        key: 'createPipeline',
        text: i18n.t('app:createPipeline'),
        iconProps: { iconName: 'Add' },
        className:'green-icon',
        onClick: this._showNewPipelineForm.bind(this),
        hidden: this.props.page !== "pipelines"
      },
      {
        key: 'editPipeline',
        text: i18n.t('app:editPipeline'),
        iconProps: { iconName: 'Edit' },
        className:'blue-icon',
        onClick: this._showEditPipelineForm.bind(this),
        hidden: this.props.page !== "pipelines",
        disabled: selectedCount < 1  || !onePipelineSelected
      },
      {
        key: 'deletePipeline',
        text: i18n.t('app:deletePipeline'),
        iconProps: { iconName: 'Delete' },
        className:'grey-icon',
        onClick: this._showDeletePipelineForm.bind(this),
        hidden: this.props.page !== "pipelines",
        disabled: selectedCount < 1  || !onePipelineSelected
      },
      {
        key: 'createContract',
        text: i18n.t('app:createContract'),
        iconProps: { iconName: 'Add' },
        className:'green-icon',
        onClick: this._showNewContractForm.bind(this),
        hidden: this.props.page !== "contractsExplorer" && this.props.page !== "contractsDashboard",
      },
      {
        key: 'editContract',
        text: i18n.t('app:editContract'),
        iconProps: { iconName: 'Edit' },
        className:'blue-icon',
        onClick: this._showEditContractForm.bind(this),
        hidden: this.props.page !== "contractsExplorer" && this.props.page !== "contractsDashboard",
        disabled: !this.props.selItem
      },
      {
        key: 'deleteContract',
        text: i18n.t('app:delete'),
        iconProps: { iconName: 'Delete' },
        className:'grey-icon',
        onClick: this._showDeleteContractForm.bind(this),
        hidden: this.props.page !== "contractsExplorer" && this.props.page !== "contractsDashboard",
        disabled: selectedCount < 1
      },
      {
        key: 'createCustomField',
        text: i18n.t('app:createCustomField'),
        iconProps: { iconName: 'Add' },
        className:'green-icon',
        onClick: this._showCustomFieldsForm.bind(this),
        hidden: !inCustomFieldsPage
      },
      {
        key: 'editCustomField',
        text: i18n.t('app:editCustomField'),
        iconProps: { iconName: 'Edit' },
        className:'blue-icon',
        onClick: this._showEditCustomFieldsForm.bind(this),
        hidden: !inCustomFieldsPage,
        disabled: !this.props.selItem
      },
      {
        key: 'deleteCustomField',
        text: i18n.t('app:deleteCustomField'),
        iconProps: { iconName: 'Delete' },
        className:'grey-icon',
        onClick: this._showDeleteCustomFieldsForm.bind(this),
        hidden: !inCustomFieldsPage,
        disabled: selectedCount < 1
      },
      {
        key: 'reorderFields',
        text: i18n.t('app:reorderFields'),
        iconProps: { iconName: 'ChevronUnfold10' },
        className:'blue-icon',
        onClick: this.props.page === 'customFieldsProject' ? this._showProjectTemplateForm.bind(this) : this.props.page === 'customFieldsContact' ? this._showContactTemplateForm.bind(this) : this.props.page === 'customFieldsContract' ? this._showContractTemplateForm.bind(this) : this._showCompanyTemplateForm.bind(this),
        hidden: !inCustomFieldsPage
      },
      {
        key: 'createNote',
        text: i18n.t('app:createNote'),
        iconProps: { iconName: 'Add' },
        className:'green-icon',
        onClick: this._showNoteForm.bind(this),
        hidden: this.props.page !== 'projectData' && this.props.page !== 'contactData' && this.props.page !== 'companyData' && this.props.page !== 'contractData' && this.props.page !== 'notes' && this.props.page !== 'notesPreview'
      },
      {
        key: 'createConversation',
        text: i18n.t('app:createConversation'),
        iconProps: { iconName: 'Add' },
        className:'green-icon',
        onClick: this._showConversationForm.bind(this),
        hidden: this.props.page !== 'projectData' && this.props.page !== 'contactData' && this.props.page !== 'companyData' && this.props.page !== 'contractData' && this.props.page !== 'conversations' && this.props.page !== 'conversationsPreview'
      },
      {
        key: 'createTask',
        text: i18n.t('app:createTask'),
        iconProps: { iconName: 'Add' },
        className:'green-icon',
        onClick: this._showTaskForm.bind(this),
        hidden:  this.props.page !== 'projectData' && this.props.page !== 'contactData' && this.props.page !== 'companyData' && this.props.page !== 'contractData' && this.props.page !== 'tasks' && this.props.page !== 'tasksPreview'
      },
      {
        key: 'editItem',
        text: i18n.t('app:editItem'),
        iconProps: { iconName: 'Edit' },
        className:'blue-icon',
        onClick: this.props.selItem && (this.props.selItem.id || '').startsWith('Note-') ? this._showNoteFormEdit.bind(this) : this.props.selItem && (this.props.selItem.id || '').startsWith('Conversation-') ? this._showConversationFormEdit.bind(this) : this.props.selItem && (this.props.selItem.id || '').startsWith('Task-') ? this._showTaskFormEdit.bind(this) : undefined,
        hidden: !inEntitiesPage,
        disabled: !this.props.selItem || (!(this.props.selItem.id || '').startsWith('Note-') && !(this.props.selItem.id || '').startsWith('Conversation-') && !(this.props.selItem.id || '').startsWith('Task-')) || this.props.selItem.noPermission
      },
      {
        key: 'deleteItem',
        text: i18n.t('app:delete'),
        iconProps: { iconName: 'Delete' },
        className:'grey-icon',
        onClick: selectedCount >= 1 && (selection[0]?.id || '').startsWith('Note-') ? this._showNoteFormDelete.bind(this) : selectedCount >= 1 && (selection[0]?.id || '').startsWith('Conversation-') ? this._showConversationFormDelete.bind(this) : selectedCount >= 1 && (selection[0]?.id || '').startsWith('Email-') ? this._showEmailFormDelete.bind(this) : selectedCount >= 1 && (selection[0]?.id || '').startsWith('Task-') ? this._showTaskFormDelete.bind(this) : undefined,
        hidden: !inEntitiesPage,
        disabled: selectedCount < 1 || (selection && selection?.find((item)=>{return item.noPermission}))
      },
      {
        key: 'importExport',
        text: i18n.t('app:importExport'),
        iconProps: { iconName: 'Import' },
        className:'purple-icon',
        hidden: !inProjectsPage && this.props.page !== 'contactsExplorer' && this.props.page !== 'companiesExplorer' && this.props.page !== "companiesPreview" && this.props.page !== 'pipelines',
        subMenuProps: {
          styles:{container: {overflow: 'hidden'}},
          items: [
            {
              key: 'import',
              text: inProjectsPage ? i18n.t('app:importProjects') : this.props.page === 'contactsExplorer' ? i18n.t('app:importContacts') : i18n.t('app:importCompanies') ,
              iconProps: { iconName: 'Import' },
              className:'purple-icon',
              onClick: this.props.import,
              hidden: !inProjectsPage && this.props.page !== 'contactsExplorer' && this.props.page !== 'companiesExplorer' && this.props.page !== "companiesPreview",
            },
            {
              key: 'export',
              text: inProjectsPage ? i18n.t('app:exportProjects') : this.props.page === 'contactsExplorer' ? i18n.t('app:exportContacts') : i18n.t('app:exportCompanies') ,
              iconProps: { iconName: 'Export' },
              className:'purple-icon',
              onClick: this.props.export,
              hidden: !inProjectsPage && this.props.page !== 'contactsExplorer' && this.props.page !== 'companiesExplorer' && this.props.page !== "companiesPreview",
            },
            {
              key: 'exportTemplate',
              text: this.props.page !== 'pipelines' ? i18n.t('app:exportTemplate') : i18n.t('app:exportPipelines'),
              iconProps: { iconName: 'Export' },
              className:'purple-icon',
              onClick: this.exportTemplate.bind(this),
              hidden: !inProjectsPage && this.props.page !== 'contactsExplorer' && this.props.page !== 'companiesExplorer' && this.props.page !== "companiesPreview" && this.props.page !== 'pipelines'
            },

          ]
        }
      },

      {
        key: 'syncUsers',
        text: i18n.t('app:syncUsers'),
        onRenderIcon: ()=>{return(!this.state.syncingUsers ? 
          <Icon style={{margin: '0 5px'}} iconName='SyncOccurence'/>
        :
          <Spinner style={{margin: '0 4px'}} size={SpinnerSize.small}/>
        )},
        className:'blue-icon',
        onClick: this._syncUsers.bind(this),
        disabled: this.state.syncingUsers,
        hidden: this.props.page !== 'contactsExplorer'
      },
      {
        key: 'editPermissions',
        text: i18n.t('app:editPermissions'),
        iconProps: { iconName: 'Permissions' },
        className:'blue-icon',
        onClick: this._showFormPermissions.bind(this),
        hidden: this.props.page !== 'permissions'
      },
      {
        key: 'addToQuickAccess',
        text: i18n.t('app:addToQuickAccess'),
        iconProps: { iconName: 'Add' },
        className:'blue-icon',
        onClick: () => selection && this._addViewToQuickAccess(selection[0]),
        hidden: this.props.page !== "views" || (selection && (this.isInQuickAccess(selection) || (selection[0] && selection[0].allQuickAccess))),
        disabled: selectedCount !== 1
      },
      {
        key: 'removeFromQuickAccess',
        text: i18n.t('app:removeFromQuickAccess'),
        iconProps: { iconName: 'Remove' },
        className:'grey-icon',
        onClick: () => selection && this._removeViewFromQuickAccess(selection[0]),
        hidden: this.props.page !== "views" || (selection && (!this.isInQuickAccess(selection) || (selection[0] && selection[0].allQuickAccess))),
        disabled: selectedCount !== 1
      },
      {
        key: 'editView',
        text: i18n.t('app:editView'),
        iconProps: { iconName: 'Edit' },
        className:'blue-icon',
        onClick: selection && this._showFormViewEdit.bind(this, selection[0]),
        hidden: this.props.page !== "views",
        disabled: selectedCount !== 1
      },
      {
        key: 'deleteView',
        text: i18n.t('app:deleteView'),
        iconProps: { iconName: 'Delete' },
        className:'grey-icon',
        onClick: selection && this._showFormViewDelete.bind(this, selection[0]),
        hidden: this.props.page !== "views",
        disabled: selectedCount !== 1
      },
    ]

    const farItems: ICommandBarItemProps[] = [
      {
        key: 'showRightPanel',
        title: i18n.t('app:toggleRightPanel'), 
        iconProps: { iconName: 'SidePanelMirrored' },
        className: 'sidePanel-button',
        onClick: () => { this.props.callbackFunction({showRightPanel: !showRightPanel}) },
        //hidden: this.props.page === "home"
      }
    ]

    const backIcon: IIconProps = { iconName: 'Back' };
    const forwardIcon: IIconProps = { iconName: 'Forward' };
    const refreshIcon: IIconProps = { iconName: 'Refresh' };

    return(
      <div className="toolbar">
        <Titlebar/>
        <div className="d-flex flex-row">
          <div className={ "history-actions" + (this.props.page !== "projectsExplorer" ? " mr-auto" : "")}>
            <IconButton iconProps={backIcon} title={i18n.t('app:back')} ariaLabel="Back" onClick={ this._goBack.bind(this) }  />
            <IconButton iconProps={forwardIcon} title={i18n.t('app:forward')} ariaLabel="Forward" onClick={ this._goForward.bind(this) } />
          </div>
          {(window.innerWidth > 980 || !detectMob()) && <div className="refresh-action">
            {this.props.syncStatus.message === "Synced" ?
              <IconButton iconProps={refreshIcon} title={i18n.t('app:refresh')} ariaLabel="Refresh" onClick={ this.props.getCurrentContent ? () => { this.props.getCurrentContent() } : ()=> null } />
            :
              <div className="ms-Spinner-wrap">
                <Spinner size={SpinnerSize.small} />
              </div>
            }
          </div>}
          <div className={"folders-breadcrumb flex-grow-1" + ((this.props.isOnline && !this.props.maintenance) ? "" : " mr-3") } style={{minWidth:'60px'}}>
            <div className="row m-0 p-0">
              <div className="breadcrumb-icon">
                { this.props.page === "projectsExplorer" ?
                  <Icon iconName="Work" />
                : this.props.page === "milestonesExplorer" ?
                  <Icon iconName="Work" />
                : this.props.page === "clientsExplorer" ?
                  <Icon iconName="Work" />
                : this.props.page === "boardExplorer" ?
                  <Icon iconName="Work" />
                : this.props.page === "pipelinesExplorer" ?
                  <Icon iconName="Work" />
                : this.props.page === "tasksExplorer" ?
                  <Icon iconName="TaskLogo" />
                : this.props.page === "projectData" ?
                  <Icon iconName="Work" />
                : this.props.page === "contractsExplorer" || this.props.page === "contractsDashboard"
                || this.props.page === "contractsExplorerTrash" ?
                  <Icon iconName="TextDocument" />
                : this.props.page === "companiesExplorer" || this.props.page === "companiesPreview"
                || this.props.page === "companiesExplorerTrash" ?
                  <Icon iconName="CityNext2" />
                : this.props.page === "companyData" ?
                  <Icon iconName="CityNext2" />
                : this.props.page === "contactsExplorer"
                || this.props.page === "contactsExplorerTrash" ?
                  <Icon iconName="Contact" />
                : this.props.page === "contactData" ?
                  <Icon iconName="Contact" />
                : this.props.page === "notes" || this.props.page === "notesPreview" || this.props.page === "note" ?
                  <Icon iconName="Quicknote" />
                : this.props.page === "conversations" || this.props.page === "conversationsPreview" || this.props.page === "conversation" ?
                  <Icon iconName="CannedChat" />
                : this.props.page === "home" ?
                  <Icon iconName="Home" />
                : this.props.page === "timeTracking" ?
                  <Icon iconName="Clock" />
                : this.props.page === "pipelines" ?
                  <Icon iconName="D365ProjectOperationsIcon" />
                : this.props.page === "search" ?
                  <Icon iconName="Search" />
                : this.props.page === "tasks" || this.props.page === "tasksPreview" || this.props.page === "task" ?
                  <Icon iconName="IconSetsFlag" />
                : this.props.page === "emails" || this.props.page === "emailsPreview" || this.props.page === "email" ?
                  <Icon iconName="Mail" />
                : this.props.page === "permissions" ?
                  <Icon iconName="Permissions" />
                : this.props.page === "customFieldsProject"
                || this.props.page === "customFieldsContact"
                || this.props.page === "customFieldsCompany" ?
                  <Icon iconName="FieldNotChanged" />
                : this.props.page === "views" ?
                  <Icon iconName="View" />
                : <Icon iconName="FabricFolder" />
                }
              </div>

              <div className="col m-0 p-0" style={{minWidth:'30px'}}>
                <Breadcrumb
                  items={ this.state.breadcrumbPath }
                  ariaLabel="Breadcrumb"
                  overflowAriaLabel="More items"
                  overflowIndex={ 0 }
                  //onRenderItem={ this._onRenderBreadcrumbItem }
                />
              </div>
            </div>
          </div>
        </div>
        <CommandBar
          items={ docActionItems }
          farItems={ window.innerWidth > 980 || !detectMob() ? farItems : undefined }
          ariaLabel="Use left and right arrow keys to navigate between commands"
          className="commandBar"
        />

        { showNewCompanyForm && <CompanyNewForm userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)}/> }
        { showEditCompanyForm && <FormCompanyEdit item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showDeleteCompanyForm && <FormCompanyDelete selection={selection} item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showNewContactForm && <FormContactNew userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showEditContactForm && <FormContactEdit item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }        
        { showDeleteContactForm && <FormContactDelete selection={selection}  item={this.props.selItem} items={this.props.selection} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }        
        { showNewProjectForm && <FormProjectNew userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showEditProjectForm && <FormProjectEdit item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showEditProjectStageForm && <FormProjectEditStage selection={selection} item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showDeleteProjectForm && <FormProjectDelete selection={selection}  item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showNewPipelineForm && <FormPipelineNew userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showEditPipelineForm && <FormPipelineEdit item={this.state.pipelineItem || this.props.selection[0]} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showDeletePipelineForm && <FormPipelineDelete item={this.props.selection[0]} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showNewContractForm && <FormContractNew userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showEditContractForm && <FormContractEdit item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showDeleteContractForm && <FormContractDelete selection={selection}  item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showCustomFieldsForm && <FormCustomFieldsNew userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showEditCustomFieldsForm && <FormCustomFieldsEdit item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showDeleteCustomFieldsForm && <FormCustomFieldsDelete item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showProjectTemplateForm && <FormCustomFieldsTemplate entityType='PROJECT' userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showContactTemplateForm && <FormCustomFieldsTemplate entityType='CONTACT' userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showContractTemplateForm && <FormCustomFieldsTemplate entityType='CONTRACT' userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showCompanyTemplateForm && <FormCustomFieldsTemplate entityType='COMPANY' userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showFormRelations && <FormRelations item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)}/> }
        { showNoteForm && <FormNote currentItemId={this.props.id} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showNoteFormEdit && <FormNoteEdit item={this.props.selItem} currentItemId={this.props.id} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showNoteFormDelete && <FormNoteDelete selection={selection} item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showEmailFormDelete && <FormEmailDelete selection={selection} item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showConversationForm && <FormConversation currentItemId={this.props.id} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showConversationFormEdit && <FormConversationEdit item={this.props.selItem} currentItemId={this.props.id} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showConversationFormDelete && <FormConversationDelete selection={selection} item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showTaskForm && <FormTask id={showTaskForm} item={this.props.selItem} currentItemId={this.props.id} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showTaskFormEdit && <FormTaskEdit item={this.props.selItem} currentItemId={this.props.id} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showTaskFormDelete && <FormTaskDelete selection={selection} item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} showConfirmDialog={this.showConfirmDialog.bind(this)} /> }
        { showFormPermissions && <FormPermissions item={this.props.selItem} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} showConfirmDialog={this.showConfirmDialog.bind(this)} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)}/> }
        { showFormView && <FormView userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} showConfirmDialog={this.showConfirmDialog.bind(this)} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} sidebarRef={this.props.sidebarRef}/> }
        { showFormViewEdit && <FormViewEdit selectedView={this.state.selectedView} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} showConfirmDialog={this.showConfirmDialog.bind(this)} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} sidebarRef={this.props.sidebarRef}/> }
        { showFormViewDelete && <FormViewDelete selectedView={this.state.selectedView} userData={this.props.userData} callback={this.callbackFunction.bind(this)} page={this.props.page} showConfirmDialog={this.showConfirmDialog.bind(this)} getCurrentContent={this.props.getCurrentContent} showAlertDialog={this.showAlertDialog.bind(this)} sidebarRef={this.props.sidebarRef}/> }
      </div>
    )
  }

  private _goBack() {
      history.back()
  }

  private _goForward() {
    history.forward()
  }

  private _toggleLeftPanel() {
    var nav = this.props.page;

    if (this.props.page === "projectsExplorer"
     || this.props.page === "clientsExplorer"
     || this.props.page === "milestonesExplorer"
     || this.props.page === "pipelinesExplorer"
     || this.props.page === "boardExplorer"
     || this.props.page === "projectData") nav = "projects"
    else if (this.props.page === "contractsExplorer" 
    || this.props.page === "contractsDashboard"
    || this.props.page === "contractData") nav = "contracts"
    if (this.props.page === "companiesExplorer"
    || this.props.page === "companiesPreview"
    || this.props.page === "companiesExplorerTrash"
    || this.props.page === "companyData") nav = "companies"
    else if (this.props.page === "contactsExplorer" 
     || this.props.page === "contactsExplorerTrash" 
     || this.props.page === "contactData") nav = "contacts"
     else if (this.props.page === "conversations" || this.props.page === "conversationsPreview" || this.props.page === "conversation") nav = "conversations"
     else if (this.props.page === "emails" || this.props.page === "emailsPreview" || this.props.page === "email") nav = "emails"
     else if (this.props.page === "notes" || this.props.page === "notesPreview" || this.props.page === "note") nav = "notes"
    else if (this.props.page === "tasks" || this.props.page === "tasksPreview" || this.props.page === "task") nav = "tasks"
    else if (this.props.page === "pipelines") nav = "pipelines"
    else if (this.props.page === "timeSheet" || this.props.page === "timeTracker") nav = "timeTracking"
    else if (this.props.page === "customFields") nav = "customFields"
    else if (this.props.page === "home") nav = "home"

    if (nav) this.props.sidebarRef.toggleNav(nav)
  }

  private exportTemplate() {

    const inProjectsPage = this.props.page === 'projectsExplorer' || this.props.page === 'clientsExplorer'  || this.props.page === 'milestonesExplorer'  || this.props.page === 'pipelinesExplorer'  || this.props.page === 'boardExplorer'

    var ws_name = "Template";
    var ws_data:any;

    if (inProjectsPage) {
      ws_data = [['name', 'stage_id', 'nickname', 'notes']]
      ws_name += '_Project'
    } else if (this.props.page === 'contactsExplorer') {
      ws_data = [['firstName', 'lastName', 'nickname', 'notes', 'phone_business', 'phone_home', 'phone_mobile', 'phone_other', 'email_1', 'email_2', 'email_3', 'email_4', 'linkedin' ]]
      ws_name += '_Contact'
    } else if (this.props.page === 'companiesExplorer' || this.props.page === 'companiesPreview') {
      ws_data = [['name', 'domain', 'nickname', 'notes', 'phone_business', 'phone_home', 'phone_mobile', 'phone_other', 'email_1', 'email_2', 'email_3', 'email_4', 'linkedin' ]]
      ws_name += '_Company'
    } else if (this.props.page === 'pipelines') {
      ws_data = [['pipeline', 'pipeline_id', 'stage', 'stage_id' ]]
      for (let i in this.props.items) {
        ws_data.push([this.props.items[i].pipelineName, this.props.items[i].pipelineId, this.props.items[i].name, this.props.items[i].id])
      }
      ws_name = 'Pipelines'
    }

    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, ws_name+".xlsx");
  }

  private _syncUsers() {
    this._isMounted && this.setState({syncingUsers: true})
    userService.syncContacts().then(()=>{
      this._isMounted && this.setState({syncingUsers: false});
      this.props.getCurrentContent();
    }).catch((error)=>{
      console.log(error)
      this._isMounted && this.setState({syncingUsers: false});
    })
  }

  private _copyPrivateShortcut = (selItem, action = '') => {
    let id = selItem.id || ''
    let itemType = id.startsWith('Project-') ? 'projects'
    : id.startsWith('Contract-') ? 'contracts'
    : id.startsWith('Contact-') || id.startsWith('User-') || id.startsWith('U') ? 'contacts'
    : id.startsWith('Company-') ? 'companies'
    : id.startsWith('Pipeline-') ? 'pipelines'
    : id.startsWith('Email-') ? 'emails/id'
    : id.startsWith('Note-') ? 'notes/id'
    : id.startsWith('Conversation-') ? 'conversations/id'
    : id.startsWith('Task-') ? 'tasks/id' : ''
    
    var link = ""
    if(itemType.length > 0) {
      link = window.location.origin + "/"+itemType+"/" + id
    }

    let spanElement = document.createElement("span");
    spanElement.innerText = link;
    spanElement.style.fontFamily = '';
    spanElement.style.fontSize = 'inherit';
    document.body.appendChild(spanElement);

    const range = document.createRange();
    range.selectNode(spanElement);
    const selection:any = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy')
    document.body.removeChild(spanElement);

    let alertMessageType = MessageBarType.success
    let alertMessageText = `<span className="mr-1">${i18n.t('app:clipboard')}:</span>
      <span>${i18n.t('app:pathURLcopied')}</span>
    `

    if (this.props.listExplorerRef && this.props.listExplorerRef.state) {
      this.props.listExplorerRef && this.props.listExplorerRef.setState({
        alertMessage: {
          type: alertMessageType,
          text: alertMessageText
        }
      })
      setTimeout(() => {
        this.props.listExplorerRef && this.props.listExplorerRef.setState({ alertMessage: null })
      }, 4000)
    } else {
      this.props.callbackFunction({
        alertMessage: {
          type: alertMessageType,
          text: alertMessageText
        }
      })
      setTimeout(() => {
        this.props.callbackFunction({ alertMessage: null })
      }, 4000)
    }
  }

  private _copyPrivateShortcutName = (selectedItems) => {
    let links = document.createElement("span");
    document.body.appendChild(links);
    let index = 0;
    for (let i in selectedItems) {
      let selItem:any = selectedItems[i]
      let id = selItem.id || ''
      let itemType = id.startsWith('Project-') ? 'projects'
      : id.startsWith('Contract-') ? 'contracts'
      : id.startsWith('Contact-') || id.startsWith('User-') || id.startsWith('U') ? 'contacts'
      : id.startsWith('Company-') ? 'companies'
      : id.startsWith('Pipeline-') ? 'pipelines'
      : id.startsWith('Email-') ? 'emails/id'
      : id.startsWith('Note-') ? 'notes/id'
      : id.startsWith('Conversation-') ? 'conversations/id'
      : id.startsWith('Task-') ? 'tasks/id' : ''
      
      var link = ""
      if(itemType.length > 0) {
        link = window.location.origin + "/"+itemType+"/" + id
      }
      let anchorElem = document.createElement("a");
      anchorElem.href = link;
      anchorElem.innerText = selItem.name || selItem.subject;
      anchorElem.style.fontFamily = '';
      anchorElem.style.fontSize = 'inherit';
      anchorElem.style.textDecorationLine = 'underline';
      let comma = document.createElement("br");
      if (index > 0) links.appendChild(comma);
      links.appendChild(anchorElem);
      index++
    }

    const linkElement = links;
    const range = document.createRange();
    range.selectNode(linkElement);
    const selection:any = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy')
    document.body.removeChild(links);

    let alertMessageType = MessageBarType.success
    let alertMessageText = `<span className="mr-1">${i18n.t('app:clipboard')}:</span>
      <span>${i18n.t('app:pathURLcopiedName')}</span>
    `
    if (this.props.listExplorerRef && this.props.listExplorerRef.state) {
      this.props.listExplorerRef && this.props.listExplorerRef.setState({
        alertMessage: {
          type: alertMessageType,
          text: alertMessageText
        }
      })
      setTimeout(() => {
        this.props.listExplorerRef && this.props.listExplorerRef.setState({ alertMessage: null })
      }, 4000)
    } else {
      this.props.callbackFunction({
        alertMessage: {
          type: alertMessageType,
          text: alertMessageText
        }
      })
      setTimeout(() => {
        this.props.callbackFunction({ alertMessage: null })
      }, 4000)
    }
  }
  
}