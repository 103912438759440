import { ActionButton, ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, Icon, IconButton, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import i18n from '../../i18n';
import { userService } from '../../_services';
import countries from '../../_constants/countries.json'
import { getItemStyle, reorder } from '../../_helpers/sharedFunctions';
import { Divider, FluentProvider, Input, Label, Textarea, webLightTheme } from '@fluentui/react-components';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { CustomField } from '../CustomField';

export interface Props {
  callback:any;
  item:any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  customFields: any[];
  chosenFields:any;
  chosenFieldsKeys: any;
  items: any[];
  item: any;
  propertiesOrder: any[];
  customProperties: any[];
  loadingProperties: boolean;
  canSubmit: boolean;
  error:any;
  contactIds: any;
  profileImage: any;
  beforeName: boolean;

  name: string;
  domain: string;
  nickname: string;
  description: string;
  linkedIn: string;
  linkedInAdded: boolean;
  phoneNumbers :any[];
  emailAddresses: any[];
  urls: any[];
  locationAddresses: any;
  changed: boolean;
}

export class FormCompanyEdit extends React.Component<Props, States> {
  private _isMounted: boolean;
  private _currentKey: number;
  props: any;

  private addressTypes = [
    {key: 'ASSISTANT', text: i18n.t('app:ASSISTANT')},
    {key: 'BUSINESS', text: i18n.t('app:BUSINESS')},
    {key: 'BUSINESS2', text: i18n.t('app:BUSINESS2')},
    {key: 'BUSINESSFAX', text: i18n.t('app:BUSINESSFAX')},
    {key: 'CALLBACK', text: i18n.t('app:CALLBACK')},
    {key: 'CAR', text: i18n.t('app:CAR')},
    {key: 'COMPANY', text: i18n.t('app:COMPANY')},
    {key: 'HOME', text: i18n.t('app:HOME')},
    {key: 'HOME2', text: i18n.t('app:HOME2')},
    {key: 'HOMEFAX', text: i18n.t('app:HOMEFAX')},
    {key: 'ISDN', text: i18n.t('app:ISDN')},
    {key: 'MOBILE', text: i18n.t('app:MOBILE')},
    {key: 'OTHER', text: i18n.t('app:OTHER')},
    {key: 'OTHERFAX', text: i18n.t('app:OTHERFAX')},
    {key: 'PAGER', text: i18n.t('app:PAGER')},
    {key: 'PRIMARY', text: i18n.t('app:PRIMARY')},
    {key: 'RADIO', text: i18n.t('app:RADIO')},
    {key: 'TELEX', text: i18n.t('app:TELEX')},
    {key: 'TTYTDD', text: i18n.t('app:TTYTDD')},
  ]

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._currentKey = 1;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      customFields: [{name:"", value: ""}],
      chosenFields: [],
      chosenFieldsKeys: [],
      items: [],
      item: JSON.parse(JSON.stringify(this.props.item)),
      propertiesOrder: [],
      customProperties: [],
      loadingProperties: true,
      canSubmit: true,
      error: "",
      contactIds: [],
      beforeName: false,

      profileImage: this.props.item.image ? 'data:image/png;base64,'+this.props.item.image : null,
      name: this.props.item.name || '',
      domain: this.props.item.domain || '',
      nickname: this.props.item.nickname || '',
      description: this.props.item.description || '',
      linkedIn: this.props.item.linkedin || '',
      linkedInAdded: this.props.item.linkedin || false,
      phoneNumbers : this.props.item.phoneNumbers && this.props.item.phoneNumbers.length > 0 ? this.props.item.phoneNumbers.sort((a,b) => { return (a.position >= b.position ? 1 : -1)}).map(item=>{return {id: item.id.toString(), key: item.id.toString(), type: item.type, number: item.number}}) : [],
      emailAddresses: this.props.item.emailAddresses && this.props.item.emailAddresses.length > 0 ? this.props.item.emailAddresses.sort((a,b) => { return (a.position >= b.position ? 1 : -1)}).map(item=>{return {id: item.id.toString(), key: item.id.toString(), name: item.name, email: item.email}}) : [],
      urls: this.props.item.urls && this.props.item.urls.length > 0 ? this.props.item.urls.sort((a,b) => { return (a.position >= b.position ? 1 : -1)}).map(item=>{return {id: item.id.toString(), key: item.id.toString(), name: item.name, url: item.url}}) : [],
      locationAddresses: this.props.item.locationAddresses && this.props.item.locationAddresses.length > 0 ? this.props.item.locationAddresses.map(item=>{return {...item, id: item.id.toString(), key: item.id.toString()}}) : [],
      changed: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    userService.getCustomProperties('','COMPANY').then((response) =>{
      let customProperties = response.data.map(item=>{return {...item, id: item.id, name: item.name, position: item.customPropertyTypes.find(x=>{return x.entityType === 'COMPANY'}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === 'COMPANY'}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}});
      customProperties = customProperties.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
      this._isMounted && this.setState({customProperties: customProperties.map(item => {
        let property = this.props.item.customPropertyValues.find(x=>{return x.customPropertyId === item.id})
        let value = null
        if (property && property.values && property.values.length > 0 && item.fieldType === 'TAG'){
          value = property.values
        } else if (property && property.values && property.values.length === 1){
          value = property.values[0]
        }
        return {...item, key: item.id, value: value, valueId: property ? property.id : null}
      }), loadingProperties: false})
    }).catch(error=>{
      console.log(error)
      this._isMounted && this.setState({hidden: true})
      this.props.showAlertDialog('Error', i18n.t('app:errorGettingCustomProperties'))
    })
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      this.props.callback({showEditCompanyForm: false})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private callback(data) {
    this._isMounted && this.setState(data)
  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      this._isMounted && this.setState({hidden:true})
    }
  }

  private updateName(data) {
    this._isMounted && this.setState({name: data.value, changed: true})
  }

  private updateDomain(data) {
    this._isMounted && this.setState({domain: data.value, changed: true})
  }

  private updateNickname(data) {
    this._isMounted && this.setState({nickname: data.value, changed: true})
  }

  private updateDescription(data) {
    this._isMounted && this.setState({description: data.value, changed: true})
  }

  private _updatePhoneNumbers(index, parameter, value) {
    let options = this.state.phoneNumbers;
    options[index][parameter] = value;
    this._isMounted && this.setState({phoneNumbers: options, changed: true});
  }

  private _addPhoneNumber() {
    this._currentKey += 1;
    const key = this._currentKey
    let options = this.state.phoneNumbers;
    options.push({key: this._currentKey.toString(), number: '', type: undefined})
    this._isMounted && this.setState({phoneNumbers: options, changed: true}, ()=>{
      document.getElementById('phone-'+key)?.focus()
    });
  }

  private _deletePhoneNumber(index) {
    let options = this.state.phoneNumbers;
    options.splice(index, 1);
    this._isMounted && this.setState({phoneNumbers: options, changed: true});
  }

  private _updateLocationAddresses(index, parameter, value) {
    let options = this.state.locationAddresses;
    options[index][parameter] = value;
    if (parameter === 'country') options[index]['countryCode'] = countries.find((item)=>{return item.key === value})?.dialCode
    this._isMounted && this.setState({locationAddresses: options, changed: true});
  }

  private _addLocationAddresses() {
    this._currentKey += 1;
    const key = this._currentKey
    let options = this.state.locationAddresses;
    options.push({key: this._currentKey.toString(), text: '', addressType: undefined})
    this._isMounted && this.setState({locationAddresses: options, changed: true}, ()=>{
      document.getElementById('address-'+key)?.focus()
    });
  }

  private _deleteLocationAddresses(index) {
    let options = this.state.locationAddresses;
    options.splice(index, 1);
    this._isMounted && this.setState({locationAddresses: options, changed: true});
  }

  private _updateURLs(index, parameter, value) {
    let options = this.state.urls;
    options[index][parameter] = value;
    this._isMounted && this.setState({urls: options, changed: true});
  }

  private _addURLs() {
    this._currentKey += 1;
    const key = this._currentKey
    let options = this.state.urls;
    options.push({key: this._currentKey.toString(), name: '', url: ''})
    this._isMounted && this.setState({urls: options, changed: true}, ()=>{
      document.getElementById('url-'+key)?.focus()
    });
  }

  private _deleteURLs(index) {
    let options = this.state.urls;
    options.splice(index, 1);
    this._isMounted && this.setState({urls: options, changed: true});
  }

  private _updateEmails(index, parameter, value) {
    let options = this.state.emailAddresses;
    options[index][parameter] = value;
    this._isMounted && this.setState({emailAddresses: options, changed: true});
  }

  private _addEmails() {
    this._currentKey += 1;
    const key = this._currentKey
    let options = this.state.emailAddresses;
    options.push({key: this._currentKey.toString(), name: 'Email '+(this.state.emailAddresses.length+1), email: ''})
    this._isMounted && this.setState({emailAddresses: options, changed: true}, ()=>{
      document.getElementById('email-'+key)?.focus()
    });
  }

  private _deleteEmails(index) {
    let options = this.state.emailAddresses;
    options.splice(index, 1);
    this._isMounted && this.setState({emailAddresses: options, changed: true});
  }

  private onDragEnd_phoneNumbers(result) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const items = reorder(this.state.phoneNumbers, source.index, destination.index);
    this._isMounted && this.setState({phoneNumbers:items, changed: true});
  }

  private onDragEnd_emailAddresses(result) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const items = reorder(this.state.emailAddresses, source.index, destination.index);
    this._isMounted && this.setState({emailAddresses:items, changed: true});
  }

  private onDragEnd_urls(result) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const items = reorder(this.state.urls, source.index, destination.index);
    this._isMounted && this.setState({urls:items, changed: true});
  }

  private _updateValue(key, value) {
    let items = this.state.items;
    for (let i=0; i<items.length;i ++) {
      if (items[i].key === key) {
        items[i].value = value;
        this._isMounted && this.setState({items: items, changed: true})
      }
    }
  }

  private _updateMultipleValues(key, item) {
    let items:any = this.state.items;
    for (let i=0; i<items.length;i ++) {
      if (items[i].key === key) {
        let value:any = items[i].value || [];
        items[i].value = item.selected ? [...value, item.key as string] : value.filter(key => key !== item.key);
        this._isMounted && this.setState({items: items, changed: true})
      }
    }
  }
  
  private validateDomain() {
    let domain = this.state.items.find((a)=>{return a.key==='domain'}).value || ""
    if (domain.length > 0 && domain.includes('google.')) {
      this._updateValue('name','Google')
      this.setState({beforeName: false})
    }
  }

  private _submit(e) {
    if (e) e.preventDefault();
    this._isMounted && this.setState({loading: true})
    const {name, domain, nickname, description, profileImage, phoneNumbers, emailAddresses, urls, locationAddresses, linkedIn, customProperties, loading} = this.state;
    if (loading) return;
    let canSubmit = true;
  
    let phoneNumbersList = phoneNumbers.map((phone, i)=>{
      return {type: phone.type, number: phone.number, position: i, id: phone.id}
    })

    let emailAddressesList = emailAddresses.map((emailAddress, i)=>{
      return {name: emailAddress.name, email: emailAddress.email, position: i, id: emailAddress.id}
    })

    let urlsList = urls.map((url, i)=>{
      return {name: url.name, url: url.url, position: i, id: url.id}
    })

    let locationAddressesList = locationAddresses.map((locationAddress)=>{
      return {...locationAddress, id: locationAddress.id}
    })

    let data = {
      name: name || null,
      domain: domain || null,
      nickname: nickname || null,
      description: description || null,
      phoneNumbers: phoneNumbersList && phoneNumbersList.length > 0 ? phoneNumbersList : [],
      emailAddresses: emailAddressesList && emailAddressesList.length > 0 ? emailAddressesList : [],
      linkedin: linkedIn || null,
      urls: urlsList && urlsList.length > 0 ? urlsList : [],
      image : profileImage ? profileImage.split(',')[1] : null,
      locationAddresses: locationAddressesList && locationAddressesList.length > 0 ? locationAddressesList : [],
      customPropertyValues: customProperties.map(item=>{return {customPropertyId: item.id, id: item.valueId, values: item.value && Array.isArray(item.value) ? item.value : [item.value]}}).filter(item=>{return item.values && item.values[0] !== null && item.values[0] !== '' && item.values[0] !== undefined})
    }

    if (canSubmit) {
      userService.editCompany(this.props.item.id, data).then((response)=>{
        this._isMounted && this.setState({hidden: true, loadingProperties: false})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false, loadingProperties: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to create the contact. Please try again.")
        }
      })
    } else {
      this._isMounted && this.setState({loading: false, loadingProperties: false, canSubmit: true})
    }
  }

  public render() {

    const { hidden, loading, loadingProperties, name, domain, nickname, description, phoneNumbers, emailAddresses, urls, locationAddresses, linkedIn, linkedInAdded } = this.state;

    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Companies' src={process.env.PUBLIC_URL + '/icons/companies.svg'} />{i18n.t('app:editCompany')}</div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: loadingProperties ? 'none' : 'overlay'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog",
            onDismiss: ()=> {this.close()}
          }}
        >
          <div className='mb-4' style={{borderBottom: '1px solid #bfbfbf', width: 'calc(100% - 34px)', position: 'sticky', left: '16px', top: 0, zIndex: 1}}></div>
          <FluentProvider theme={webLightTheme}>
            <form id='form' className='mb-3' style={{padding: '0 16px'}} onSubmit={this._submit.bind(this)}>
              <input className='d-none' type='submit'></input>
              <div className='d-flex'>
                <div className='flex-grow-1'>
                  <div className='d-flex mt-2'>
                    <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}} required>{i18n.t('app:name')}</Label>
                    <Input type='text' className='flex-grow-1' value={name} onChange={(e,data)=>this.updateName(data)} required autoFocus/>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <Label className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:domain')}</Label>
                    <Input type='text' className='flex-grow-1' value={domain} onChange={(e,data)=>this.updateDomain(data)}/>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:nickName')}</span>
                    <Input type='text' name='nickname' className='flex-grow-1' value={nickname} onChange={(e,data)=>this.updateNickname(data)}/>
                  </div>
                </div>
                <div className='ml-3'>
                  <div className='contact-picture'>
                  {this.state.profileImage ? 
                      <img src={this.state.profileImage} alt='Profile' style={{width: '128px', height: '128px', marginTop: '8px'}}/>
                    : <div className='d-flex' style={{width: '128px', height: '128px', marginTop: '8px', backgroundColor: '#bfbfbf'}}>
                        <Icon className='m-auto' style={{fontSize: '42px', color: 'white'}} iconName='CityNext2'/>
                      </div>
                    }
                    <IconButton iconProps={{iconName: this.state.profileImage ? 'Edit' : 'Add'}} onClick={()=>{this._openFileExplorer()}} />
                  </div>
                </div>
              </div>
              <div className='d-flex mt-3'>
                <Icon iconName='TextField' style={{marginTop: '5px', marginRight: '10px'}}/>
                <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:notes')}</span>
                <Textarea className='flex-grow-1' value={description} onChange={(e,data)=>this.updateDescription(data)}/>
              </div>
              <div className='mt-3 d-flex'>
                <div className='d-flex'>
                  <Icon iconName='Phone' style={{marginTop: '5px', marginRight: '10px'}}/>
                  <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:phoneNumbers')}</span>
                </div>
                <div className='flex-grow-1'>
                  <DragDropContext onDragEnd={this.onDragEnd_phoneNumbers.bind(this)}>
                    <Droppable droppableId={'phones-column'}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} >
                          {this.state.phoneNumbers.map((item,index) => {
                            return (
                              <Draggable key={item.id || item.key} draggableId={item.id || item.key} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div className='d-flex align-items-center my-3'>
                                    <Icon iconName='GripperDotsVertical' style={{fontSize: 20}}/>
                                    <span className='mx-3' style={{fontWeight: 600, minWidth:'10px'}}>{index + 1}</span>
                                    <Dropdown
                                      id={'phone-'+item.key}
                                      className='mr-2 ml-1'
                                      options={this.addressTypes} 
                                      style={{width: '142px'}}
                                      styles={{ title: {borderRadius: '4px'}}}
                                      selectedKey={phoneNumbers[index].type || undefined}
                                      placeholder={i18n.t('app:selectOption')}
                                      onChange={(e,value:any)=>{this._updatePhoneNumbers(index, 'type', value.key)}}
                                    />
                                    <Input
                                      type='tel'
                                      className='flex-grow-1 mr-2'
                                      value={phoneNumbers[index].number}
                                      onChange={(e,data)=>{this._updatePhoneNumbers(index, 'number', data.value)}}
                                      style={{minWidth: '200px'}}
                                      disabled={!phoneNumbers[index].type}
                                      required
                                    />
                                    <IconButton className='ml-auto' onClick={()=>this._deletePhoneNumber(index)} iconProps={{iconName: 'Delete'}} />       
                                  </div>
                                </div>
                              )}
                            </Draggable>
                            )
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <ActionButton className={phoneNumbers.length > 0 ? 'mt-3' : ''} styles={{root: {height: '28px'}}} onClick={()=> this._addPhoneNumber()} iconProps={{iconName: 'Add'}} text={i18n.t('app:addPhoneNumber')}/>
                </div>
              </div>
              <div className='mt-3 d-flex'>
                <div className='d-flex'>
                  <Icon iconName='Mail' style={{marginTop: '5px', marginRight: '10px'}}/>
                  <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:emailAddresses')}</span>
                </div>
                <div className='flex-grow-1'>
                  <DragDropContext onDragEnd={this.onDragEnd_emailAddresses.bind(this)}>
                    <Droppable droppableId={'emails-column'}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} >
                          {this.state.emailAddresses.map((item,index) => {
                            return (
                              <Draggable key={item.id || item.key} draggableId={item.id || item.key} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div className='d-flex align-items-center my-3'>
                                    <Icon iconName='GripperDotsVertical' style={{fontSize: 20}}/>
                                    <span className='mx-3' style={{fontWeight: 600, minWidth:'10px'}}>{index + 1}</span>
                                    <Input 
                                      className='mr-2 ml-1'
                                      style={{width: '142px'}}
                                      value={emailAddresses[index].name}
                                      onChange={(e,data)=>{this._updateEmails(index, 'name', data.value)}}
                                      placeholder={i18n.t('app:email') + ' ' + (index+1)}
                                      required
                                    />
                                    <Input
                                      id={'email-'+item.key} 
                                      className='flex-grow-1 mr-2'
                                      value={emailAddresses[index].email}
                                      onChange={(e,data)=>{this._updateEmails(index, 'email', data.value)}}
                                      required
                                    />
                                    <IconButton className='ml-auto' onClick={()=>this._deleteEmails(index)} iconProps={{iconName: 'Delete'}} /> 
                                  </div>
                                </div>
                              )}
                            </Draggable>
                            )
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <ActionButton className={emailAddresses.length > 0 ? 'mt-3' : ''} styles={{root: {height: '28px'}}} onClick={()=> this._addEmails()} iconProps={{iconName: 'Add'}} text={i18n.t('app:addEmailAddress')}/>
                </div>
              </div>
              <div className='d-flex mt-3' style={{height: '32px'}}>
                <Icon iconName='Link' style={{marginTop: '5px', marginRight: '10px'}}/>
                <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:linkedIn')}</span>
                { linkedInAdded ? <div className='d-flex align-items-center flex-grow-1'>
                  <Input className='flex-grow-1 mr-2' value={linkedIn} onChange={(e,data)=>this._isMounted && this.setState({linkedIn: data.value, changed: true})}/>
                  <IconButton className='ml-auto' onClick={()=>this._isMounted && this.setState({linkedInAdded: false, linkedIn: '', changed: true})} iconProps={{iconName: 'Delete'}} />
                </div>
                :
                <ActionButton styles={{root: {height: '28px'}}} onClick={()=> this._isMounted && this.setState({linkedInAdded: true, changed: true})} iconProps={{iconName: 'Add'}} text={i18n.t('app:addLinkedIn')}/>
                }
              </div>
              <div className='mt-3 d-flex'>
                <div className='d-flex'>
                  <Icon iconName='Link' style={{marginTop: '5px', marginRight: '10px'}}/>
                  <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:urls')}</span>
                </div>
                <div className='flex-grow-1'>
                  <DragDropContext onDragEnd={this.onDragEnd_urls.bind(this)}>
                    <Droppable droppableId={'urls-column'}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} >
                          {this.state.urls.map((item,index) => {
                            return (
                              <Draggable key={item.id || item.key} draggableId={item.id || item.key} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div className='d-flex align-items-center my-3'>
                                    <Icon iconName='GripperDotsVertical' style={{fontSize: 20}}/>
                                    <span className='mx-3' style={{fontWeight: 600, minWidth:'10px'}}>{index + 1}</span>
                                    <Input
                                      id={'url-'+item.key}
                                      className='mr-2 ml-1'
                                      style={{width: '142px'}}
                                      value={urls[index].name}
                                      onChange={(e,data)=>{this._updateURLs(index, 'name', data.value)}}
                                      placeholder={i18n.t('app:URL') + ' ' + (index+1)}
                                      required
                                    />
                                    <Input 
                                      className='flex-grow-1 mr-2'
                                      value={urls[index].url}
                                      onChange={(e,data)=>{this._updateURLs(index, 'url', data.value)}}
                                      required
                                    />
                                    <IconButton className='ml-auto' onClick={()=>this._deleteURLs(index)} iconProps={{iconName: 'Delete'}} />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                            )
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <ActionButton className={urls.length > 0 ? 'mt-3' : ''} styles={{root: {height: '28px'}}} onClick={()=> this._addURLs()} iconProps={{iconName: 'Add'}} text={i18n.t('app:addURL')}/>
                </div>
              </div>
              <div className='mt-3'>
                <div className='d-flex'>
                  <Icon iconName='Mail' style={{marginTop: '5px', marginRight: '10px'}}/>
                  <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:mailAddress')}</span>
                  {locationAddresses.length === 0 && <ActionButton onClick={()=> this._addLocationAddresses()} styles={{root: {height: '28px'}}} iconProps={{iconName: 'Add'}} text={i18n.t('app:addAddress')}/>}
                </div>          
                <div className='mt-2'>
                  {locationAddresses.map((option,index) => {
                    return (<div key={option.key} className='border-line py-3 px-4 mb-3'>
                      <div className='d-flex'>
                        <span className='mt-2 mr-2' style={{fontWeight:600}}>{i18n.t('app:mailAddress') + ' ' + (index+1)}</span>
                        <IconButton className='ml-auto' onClick={()=>this._deleteLocationAddresses(index)} iconProps={{iconName: 'Delete'}} />
                      </div>
                      <div className='d-flex mt-4 align-items-center'>
                        <Label className='form-label' required>{i18n.t('app:name')}</Label>
                        <Input
                          id={'address-'+option.key} 
                          className='flex-grow-1'
                          value={locationAddresses[index].name || ''}
                          onChange={(e,data)=>{this._updateLocationAddresses(index, 'name', data.value)}}
                          required
                        />
                      </div>
                      <div className='d-flex mt-4 align-items-center'>
                        <span className='form-label'>{i18n.t('app:streetAddress')}</span>
                        <Input 
                          className='flex-grow-1'
                          value={locationAddresses[index].streetAddress || ''}
                          onChange={(e,data)=>{this._updateLocationAddresses(index, 'streetAddress', data.value)}}
                        />
                      </div>
                      <div className='d-flex mt-4 align-items-center'>
                        <span className='form-label'>{i18n.t('app:city')}</span>
                        <Input 
                          className='flex-grow-1'
                          value={locationAddresses[index].city || ''}
                          onChange={(e,data)=>{this._updateLocationAddresses(index, 'city', data.value)}}
                        />
                      </div>
                      <div className='d-flex mt-4 align-items-center'>
                        <span className='form-label'>{i18n.t('app:region')}</span>
                        <Input 
                          className='flex-grow-1'
                          value={locationAddresses[index].region || ''}
                          onChange={(e,data)=>{this._updateLocationAddresses(index, 'region', data.value)}}
                        />
                      </div>
                      <div className='d-flex mt-4 align-items-center'>
                        <span className='form-label'>{i18n.t('app:postalCode')}</span>
                        <Input 
                          className='flex-grow-1'
                          value={locationAddresses[index].postalCode || ''}
                          onChange={(e,data)=>{this._updateLocationAddresses(index, 'postalCode', data.value)}}
                        />
                      </div>
                      <div className='d-flex mt-4 align-items-center'>
                        <span className='form-label'>{i18n.t('app:country')}</span>
                        <ComboBox 
                          className='flex-grow-1 mr-2'
                          styles={{ root: {borderRadius: '4px'}}}
                          //placeholder="Choose a country"
                          allowFreeform={true}
                          autoComplete={'on'}
                          selectedKey={locationAddresses[index].country}
                          onChange={(e,value:any)=>{this._updateLocationAddresses(index, 'country', value.key)}}
                          options={countries}
                          onRenderOption={(item:any)=>{return <div className='d-flex' style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                            <span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{item.text}</span>
                          </div>}}
                        />
                        <Input 
                          disabled
                          value={countries.find((item)=>{return item.key === locationAddresses[index].country})?.dialCode || ''}
                          //onChange={(e,value:any)=>{this._updateLocationAddresses(index, 'countryCode', value)}}
                        />
                      </div>
                      <div className='d-flex mt-4'>
                        <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:description')}</span>
                        <Textarea
                          className='flex-grow-1'
                          value={locationAddresses[index].description || ''}
                          onChange={(e,data)=>{this._updateLocationAddresses(index, 'description', data.value)}}
                        />
                      </div>
                      <div className='d-flex mt-4 align-items-center'>
                        <span className='form-label'>{i18n.t('app:addressType')}</span>
                        <Dropdown 
                          options={[
                            {key: 'WORK', text: i18n.t('app:work')},
                            {key: 'HOME', text: i18n.t('app:home')},
                            {key: 'OTHER', text: i18n.t('app:other')}
                          ]} 
                          style={{width: '150px'}}
                          styles={{ title: {borderRadius: '4px'}}}
                          defaultSelectedKey={'WORK'}
                          selectedKey={locationAddresses[index].addressType}
                          onChange={(e,value:any)=>{this._updateLocationAddresses(index, 'addressType', value.key)}}
                        />          
                      </div>
                    </div>)
                  })}
                  {locationAddresses.length > 0 && <ActionButton onClick={()=> this._addLocationAddresses()} styles={{root: {height: '28px'}}} iconProps={{iconName: 'Add'}} text={i18n.t('app:addAddress')}/>}
                </div>
              </div>
              {this.state.loadingProperties ? <Spinner/> : this.state.customProperties && this.state.customProperties.length > 0 && <> 
                <Divider className='mt-3'/>
                <div className='mt-3'>
                  { this.state.customProperties.map(item => {
                    return <div className='my-3' key={item.key || item.id}>
                      <CustomField userData={this.props.userData} customFields={this.state.customProperties} field={item} callback={this.callback.bind(this)}/>
                    </div>
                  })}
                </div>
              </>}
            </form>
          </FluentProvider>

          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this.close()}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton form='form' type='submit' text={i18n.t('app:save')} disabled={loading} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

  private _openFileExplorer() {
    var input = document.createElement('input');
    input.type = 'file';
    input.setAttribute("single","");
    input.accept= "image/*"
    var self = this;

    input.onchange = e => {
        let event:any = e as Event;
        event.stopPropagation();
        event.preventDefault();
        var imageVal = event.target.files[0];
        if (imageVal.size > 102400) {
          this.props.showAlertDialog(i18n.t('app:error'), i18n.t('app:imageIsTooBig'))
        } else {
          var reader = new FileReader();
          reader.onload = function (e:any) {
              var img:any = document.createElement("img");
              img.onload = function () {
                  var canvas = document.createElement("canvas");
                  canvas.width = 128;
                  canvas.height = 128;              
                  var ctx:any = canvas.getContext("2d");
                  ctx.drawImage(img, 0, 0, 128, 128);
                  var url = canvas.toDataURL("image/png");
                  self._isMounted && self.setState({profileImage: url, changed: true})
              }
              img.src = e.target.result;
          }
          reader.readAsDataURL(imageVal);
        }
    }
    input.click();
  }

}