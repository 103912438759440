import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, Icon, IconButton, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import i18n from '../../i18n';
import { userService } from '../../_services';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { _getMainPermissions } from '../../_helpers/permissionsFunctions';

export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  customFields: any[];
  chosenFields:any;
  chosenFieldsKeys: any;
  items: any[];
  item: any;
  canSubmit: boolean;
  error:any;
  pipelineId: string;
  pipelines: any[];
  stages: any[];
  stageId: string;
  changed: boolean;
}

export class FormProjectEditStage extends React.Component<Props, States> {
  private _isMounted: boolean;
  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      customFields: [{name:"", value: ""}],
      chosenFields: [],
      chosenFieldsKeys: [],
      items: [],
      item: this.props.item,
      canSubmit: true,
      error: "",
      pipelineId: '',
      pipelines: [],
      stages: [],
      stageId:  '',
      changed: false,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    userService.getPipelines().then(response => {
      let pipelines = response.data.map(pipeline => {return {...pipeline, text: pipeline.name, key: pipeline.id}} )
      this._isMounted && this.setState({
        pipelines: pipelines,
      })
    }).catch((error)=>{
      this._isMounted && this.setState({hidden: true})
      if (error && error.data && error.data.code === 400 && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else if (error && error.data && error.data.message) {
        this.props.showAlertDialog('Error', error.data.message)
      } else {
        this.props.showAlertDialog('Error', "An error occurred trying to get existing Pipelines. Please try again.")
      }
    })
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      this.props.callback({showEditProjectStageForm: false})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private callback(data) {
    this._isMounted && this.setState(data)
  }

  private _handleKeyDown(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 65 && event.keyCode <= 90)){
      this._isMounted && this.setState({changed:true})
    }
  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      this._isMounted && this.setState({hidden:true})
    }
  }

  private updatePipeline(data) {
    if (data) {
      let pipeline = this.state.pipelines.find((p)=>{return p.id === data.id })
      this._isMounted && this.setState({  
        pipelineId: data.key,
        stages: pipeline.stages.map(s => {return {...s, text: s.name, key: s.id}}).sort((a,b) => { return (a.position >= b.position ? 1 : -1)}),
        stageId: '',
        changed: true
      })
    }
  }

  private _submit(e) {
    if (e) e.preventDefault();
    const {loading} = this.state;
    if (loading) return;
    this._isMounted && this.setState({loading: true})
    const {stageId} = this.state;
    let canSubmit = true;

    let ids = this.props.selection.map((item)=>{
      return item.id
    })

    if (canSubmit) {
      userService.editProjects(ids, null, stageId).then((response)=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update the project. Please try again.")
        }
      })
    } else {
      this._isMounted && this.setState({loading: false, canSubmit: true})
    }
  }

  public render() {

    const { hidden, loading, pipelines, pipelineId, stages, stageId  } = this.state;
    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Project' src={process.env.PUBLIC_URL + '/icons/projects.svg'} />{i18n.t('app:editProjectStage')}</div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: 'overlay'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog",
            onDismiss: ()=> {this.close()}
          }}
        >
          <div className='mb-3' style={{borderBottom: '1px solid #bfbfbf', width: 'calc(100% - 34px)', position: 'sticky', left: '16px', top: 0, zIndex: 1}}></div>
          <FluentProvider theme={webLightTheme}>
            <form id='form' className='mb-3' style={{padding: '0 16px'}} onSubmit={this._submit.bind(this)}>
              <input className='d-none' type='submit'></input>
              <div className='d-flex'>
                <div className='flex-grow-1'>
                  <div className='d-flex'>
                    <Icon iconName='D365ProjectOperationsIcon' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:pipeline')}</span>
                    <div className='d-flex align-items-center flex-grow-1' style={{position: 'relative'}}>
                      <ComboBox className='flex-grow-1' styles={{ root: {borderRadius: '4px'}}} allowFreeform selectedKey={pipelineId} onChange={(e, data:any) => this.updatePipeline(data)} options={pipelines}/>
                      {pipelineId && <IconButton style={{position: 'absolute', right: '40px', height: '28px', width: '0px', fontSize: '12px', background: 'transparent'}} styles={{icon: {fontSize: '12px', color: 'rgb(96, 94, 92)'}}} iconProps={{iconName: 'Clear'}} onClick={(e)=>this._isMounted && this.setState({stageId: '', stages: [], pipelineId: '', changed: true})}/>}
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <Icon iconName='D365ProjectOperationsIcon' style={{marginTop: '5px', marginRight: '10px'}}/>
                    <span className='form-label' style={{ marginTop: '4px'}}>{i18n.t('app:stage')}</span>
                    <div className='d-flex align-items-center flex-grow-1' style={{position: 'relative'}}>
                      <Dropdown className='flex-grow-1' styles={{ title: {borderRadius: '4px'}}} selectedKey={stageId} onChange={(e, data:any) => this._isMounted && this.setState({stageId: data.id, changed: true})} options={stages} disabled={pipelineId === ''}/>
                      {stageId && stageId !== 'null' && <IconButton style={{position: 'absolute', right: '40px', height: '28px', width: '0px', fontSize: '12px', background: 'transparent'}} styles={{icon: {fontSize: '12px', color: 'rgb(96, 94, 92)'}}} iconProps={{iconName: 'Clear'}} onClick={(e)=>this._isMounted && this.setState({stageId: '', changed: true})}/>}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </FluentProvider>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this.close()}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton form='form' type='submit' text={i18n.t('app:save')} disabled={loading} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }
}
