import * as React from 'react';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import "moment/min/locales";
import { ConstrainMode, DetailsListLayoutMode, IColumn, Icon, IconButton, SelectionMode } from '@fluentui/react';
import i18n from "i18next";
import { ListExplorer } from '../_components/ListExplorer';
import { userService } from '../_services/user.service';
import { CustomFieldPreviewDialog } from '../_components/CustomFieldPreviewDialog';

export interface Props {
  userData: any;
  appPlatform: string,
  isOnline: boolean,
  page: string,
  maintenance: boolean,
  socket: any,
  pusher: any,
  pusherMessage: any,
}

export interface State { 
  userData: any;
  breadcrumbPath: any[];
  searchQuery: any;
  syncStatus: any;
  itemsFull: any;
  items: any;
  columns: any[];
  selItem:any;
  isLoading: boolean;
  selection: any[];
  showCustomFieldPreviewDialog: boolean;
  itemToPreview: any;
}

export class CustomFieldsPage extends React.Component<Props, State> {
  private _isMounted: boolean;
  private sidebarRef:any = React.createRef();
  private headerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();


  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      userData: null,
      breadcrumbPath: [{
        text: i18n.t('app:customFields') + ' - ' + (this.props.page === 'customFieldsProject' ? i18n.t('app:project') : this.props.page === 'customFieldsContact' ? i18n.t('app:contact') : this.props.page === 'customFieldsContract' ? i18n.t('app:contract') : i18n.t('app:company')),
        key: 'customFields',
        onClick: ()=>{}
      }],
      searchQuery: [],
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      itemsFull: [],
      items: [],
      columns: [],
      selItem: null,
      isLoading: false,
      selection: [],
      showCustomFieldPreviewDialog: false,
      itemToPreview: null
    }
  }

  public componentDidMount() {
    this._isMounted = true;
    this.getCurrentContent();
    if (this.props.pusher && this.props.userData && this._isMounted) {
      console.log('Pusher connect: Workflow-'+ this.props.userData.repository.id +"-CUSTOM_PROPERTY")
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CUSTOM_PROPERTY"})
      this.props.pusher.connect()
    }
  }

  private getCurrentContent() {
      this._isMounted && this._getColumns();
      this._isMounted && this.setState({
        isLoading: true,
        items: [],
        syncStatus: {
          message: "Syncing",
          type: "info",
          icon: "spinner"
        },
        breadcrumbPath: [{
          text: i18n.t('app:customFields') + ' - ' + (this.props.page === 'customFieldsProject' ? i18n.t('app:project') : this.props.page === 'customFieldsContact' ? i18n.t('app:contact') : this.props.page === 'customFieldsContract' ? i18n.t('app:contract') : i18n.t('app:company')),
          key: 'customFields',
          onClick: ()=>{}
        }]
      })

      let entityType = this.props.page === 'customFieldsProject' ? 'PROJECT' : this.props.page === 'customFieldsContact' ? 'CONTACT' : this.props.page === 'customFieldsContract' ? 'CONTRACT' : 'COMPANY';
      
      userService.getCustomProperties('',entityType).then((response)=>{
        let items:any = this._copyAndSort(response.data, 'position', false);
        this._isMounted && this.setState({
          items: items,
          isLoading: false,
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          }
        });
        this._sortBySavedColumn(items);
      }).catch((error)=>{
        this._isMounted && this.setState({
          items: [],
          isLoading: false,
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          }
        });
      })
  }

  public componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page) {
      this._isMounted && this.getCurrentContent();
    }

    if (prevProps.pusher !== this.props.pusher) {
      if (this.props.pusher && this.props.userData && this._isMounted) {
        console.log('Pusher connect: Workflow-'+ this.props.userData.repository.id +"-CUSTOM_PROPERTY")
        this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "CUSTOM_PROPERTY"})
        this.props.pusher.connect()
      }
    }

    if (prevProps.pusherMessage !== this.props.pusherMessage) {
      const { pusherMessage } = this.props;
      if(pusherMessage && pusherMessage.message) {
        console.log(pusherMessage)
        /*if (pusherMessage.message.itemType === 'CUSTOM_PROPERTY') {
          if (pusherMessage.message.action === 'DELETE') {
            let items = this.state.items;
            items = items.filter((item)=>{return item.id !== pusherMessage.message.itemId})
            this._isMounted && this.setState({items: items})
          } else if (pusherMessage.message.action === 'UPDATE') {
            let entityType = this.props.page === 'customFieldsProject' ? 'PROJECT' : this.props.page === 'customFieldsContact' ? 'CONTACT' : this.props.page === 'customFieldsContract' ? 'CONTRACT' : 'COMPANY';
            let items = this.state.items;
            let isInlist = false;
            for (let i in items) {
              if (items[i].id === pusherMessage.message.itemId) {
                isInlist = true;
                userService.getCustomProperties(pusherMessage.message.itemId, entityType).then((response)=>{
                  if (response.data && response.data.length > 0) {
                    let items = this.state.items;
                    for (let i in items) {
                      if (items[i].id === pusherMessage.message.itemId) {
                        items[i] = response.data[0]
                        items[i].key = items[i].id;
                      }
                    }
                    this._isMounted && this.setState({items: items.map((item:any)=>{return {...item, key: item.id}})})
                  } else {
                    let items = this.state.items;
                    items = items.filter((item)=>{return item.id !== pusherMessage.message.itemId})
                    this._isMounted && this.setState({items: items})
                  }
                })
                break;
              }
            }
            if (!isInlist) {
              let entityType = this.props.page === 'customFieldsProject' ? 'PROJECT' : this.props.page === 'customFieldsContact' ? 'CONTACT' : this.props.page === 'customFieldsContract' ? 'CONTRACT' : 'COMPANY';
              userService.getCustomProperties(pusherMessage.message.itemId, entityType).then((response)=>{
                if (response.data && response.data.length > 0) {
                  let items = this.state.items;
                  let item = response.data[0]
                  item.key = item.id;
                  items.push(item)
                  items = this._copyAndSort(response.data, 'position', false);
                  this._sortBySavedColumn(items)
                }
              })
            }
          } else if (pusherMessage.message.action === 'CREATE') {
            let entityType = this.props.page === 'customFieldsProject' ? 'PROJECT' : this.props.page === 'customFieldsContact' ? 'CONTACT' : this.props.page === 'customFieldsContract' ? 'CONTRACT' : 'COMPANY';
            userService.getCustomProperties(pusherMessage.message.itemId, entityType).then((response)=>{
              if (response.data && response.data.length > 0) {
                let items = this.state.items;
                let item = response.data[0]
                item.key = item.id;
                items.push(item)
                items = this._copyAndSort(response.data, 'position', false);
                this._sortBySavedColumn(items)
              }
            })
          } else if (pusherMessage.message.action === 'REORDER' ) {
            let entityType = this.props.page === 'customFieldsProject' ? 'PROJECT' : this.props.page === 'customFieldsContact' ? 'CONTACT' : this.props.page === 'customFieldsContract' ? 'CONTRACT' : 'COMPANY';
            if (pusherMessage.message.itemId === entityType) {
              userService.getCustomProperties('', entityType).then((response)=>{
                if (response.data && response.data.length > 0) {
                  let items:any = this._copyAndSort(response.data, 'position', false);
                  this._sortBySavedColumn(items)
                }
              })
            }
          }
        }*/
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    if (this.props.pusher) {
      this.props.pusher.disconnect();
    }
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private _getColumns() {

    const columns = [
      {
        key: 'icon',
        name: '#',
        fieldName: 'fieldType',
        minWidth: 1,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <Icon 
              iconName={item.fieldType === 'STRING' ? 'TextField' 
              : item.fieldType === 'NUMBER' ? 'NumberField' 
              : item.fieldType === 'BOOLEAN' ? 'CheckboxComposite' 
              : item.fieldType === 'DATE' ? 'Calendar' 
              : item.fieldType === 'TAG' ? 'Dropdown'
              : item.fieldType === 'FILE' ? 'Document'
              : 'TextField'} 
              style={{marginTop: '5px', marginRight: '10px'}}
            />
          )
        }
      },
      {
        key: 'position',
        name: i18n.t('app:order'),
        fieldName: 'position',
        minWidth: 32,
        maxWidth: 64,
        isSorted: true,
        isSortedDescending: false,
        data: 'string',
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          let entityType = this.props.page === 'customFieldsProject' ? 'PROJECT' : this.props.page === 'customFieldsContact' ? 'CONTACT' : this.props.page === 'customFieldsContract' ? 'CONTRACT' : 'COMPANY'
          let position = item.customPropertyTypes.find(x=>{return x.entityType === entityType})?.position
          return (
            <span>{position+1}</span>
          )
        }
      },
      {
        key: 'name',
        name: i18n.t('app:name'),
        fieldName: 'name',
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        minWidth: 170,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
              <div>
                <span>{ item.name }</span>
              </div>
            )
        }
      },
      {
        key: 'description',
        name: i18n.t('app:description'),
        fieldName: 'description',
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        minWidth: 170,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
              <div>
                <span>{ item.description }</span>
              </div>
            )
        }
      },
      {
        key: 'fieldType',
        name: i18n.t('app:fieldType'),
        fieldName: 'fieldType',
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        minWidth: 170,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          let type = 'singleLine';
          if (item.fieldType === 'FILE') {
            type = 'file'
          } else if (item.fieldType === 'CONTACT') {
            type = 'contact'
          } else if (item.fieldType === 'TAG' && item.multipleValues) {
            type = 'multiSelection'
          } else if (item.fieldType === 'TAG') {
            type = 'dropdownSelection'
          } else if (item.fieldType === 'DATE') {
            type = 'datePicker'
          } else if (item.fieldType === 'BOOLEAN') {
            type = 'checkbox'
          } else if (item.fieldType === 'NUMBER') {
            type = 'number'
          } else if (item.fieldType === 'STRING' && item.multiLine) {
            type = 'multiLine'
          } else if (item.fieldType === 'STRING') {
            type = 'singleLine'
          }
          return (
              <div>
                <span>{ i18n.t('app:'+type) }</span>
              </div>
            )
        }
      },
      {
        key: 'preview',
        name: i18n.t('app:preview'),
        fieldName: 'preview',
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        minWidth: 170,
        //maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        //onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          let options:any = [];
          if (item.possibleValues) {
            for (let key in item.possibleValues) {
              options.push({'key': key, 'text': item.possibleValues[key]})
            }
          }
          return (<div className='d-flex align-items-center' style={{minWidth: '400px', maxHeight: '24px'}}>
            <IconButton 
              iconProps={{iconName: 'RedEye'}} 
              title={i18n.t('app:showPreview')} 
              onClick={()=>{
                this._isMounted && this.setState({showCustomFieldPreviewDialog: true, itemToPreview: item})
              }}
            />
          </div>)
        }
      },
    ]

    this._isMounted && this.setState({columns: columns})
  }

  public render() {

    const { items, columns, showCustomFieldPreviewDialog } = this.state;

    return(
      <div>
        <Header
          ref={instance => { this.headerRef = instance; }}
          userData={ this.props.userData }
          breadcrumbPath={ this.state.breadcrumbPath }
          sidebarRef={ this.sidebarRef }
          page={this.props.page}
          actions="customFields"
          getActivity={ null }
          syncStatus={this.state.syncStatus}
          selItem={ this.state.selItem }
          getCurrentContent={this.getCurrentContent.bind(this)}
          selection={this.state.selection}
        />

        <div className="content-wrap d-flex flex-row" >
          <Sidebar
            ref={(instance:any) => { this.sidebarRef = instance; }}
            headerRef={ this.headerRef }
            userData={ this.props.userData }
            page={this.props.page}
          />

          <div className="list mr-auto flex-grow-1">
            {items && <ListExplorer
              appPlatform={ this.props.appPlatform }
              isOnline={ this.props.isOnline }
              maintenance={ this.props.maintenance }
              socket={this.props.socket}
              pusher={this.props.pusher}
              userData={this.props.userData}
              foldersList={ [] }
              items={items}
              selItem={ this.state.selItem }
              isCompactMode={true}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              constrainMode={ConstrainMode.unconstrained}
              headerRef={ this.headerRef }
              detailsPanelRef={ this.detailsPanelRef }
              callbackFunction={ this.callbackFunction }
              page={this.props.page}
              sidebarRef={ this.sidebarRef }
              loading={this.state.isLoading}
            />}
            {showCustomFieldPreviewDialog && <CustomFieldPreviewDialog callback={this.callbackFunction.bind(this)} item={this.state.itemToPreview} />}
          </div>
        </div>
      </div>
    )
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        newCol.isSortedDescending = !currColumn.isSortedDescending;
        newCol.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    let newItems = this._copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    localStorage.setItem("columnsFields", JSON.stringify(newColumns))

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private _sortBySavedColumn = (items: any): void => {
    if (typeof(localStorage.getItem("columnsFields")) === "string") {
      var dashboardColumns: IColumn[] = JSON.parse(localStorage.getItem("columnsFields") || "[]");

      for (let i = 0; i < this.state.columns.length; i++) {
        let column = this.state.columns[i]

        let savedColumn: any = dashboardColumns.filter(savedColumn => {
          return column.key === savedColumn.key
        })
        savedColumn = savedColumn[0]

        if(column && savedColumn) {
          column.isSorted = savedColumn.isSorted;
          column.isSortedDescending = savedColumn.isSortedDescending

          if(column.isSorted) {
            const { columns } = this.state;
            const newColumns: IColumn[] = columns.slice();
            const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        
            const newItems = this._copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
            this._isMounted && this.setState({
              columns: newColumns,
              items: newItems.map((item:any)=>{return {...item, key: item.id}}),
            });
          }
        } else { 
          this._isMounted && this.setState({items: items.map((item:any)=>{return {...item, key: item.id}})})
        }
      }
    } else {
      this._isMounted && this.setState({items: items.map((item:any)=>{return {...item, key: item.id}})})
    }
  };

  private _copyAndSort<T>(items: any[], columnKey: string, isSortedDescending?: boolean): T[] {
    return items.slice(0).sort((a,b) => {
      if (!a) return -1;
      if (!b) return 1;
      if (columnKey === 'position') {
        let entityType = this.props.page === 'customFieldsProject' ? 'PROJECT' : this.props.page === 'customFieldsContact' ? 'CONTACT' : this.props.page === 'customFieldsContract' ? 'CONTRACT' : 'COMPANY'
        let positionA = a.customPropertyTypes.find(x=>{return x.entityType === entityType}).position
        let positionB = b.customPropertyTypes.find(x=>{return x.entityType === entityType}).position
        if (!isSortedDescending) {
          return positionA > positionB ? 1 : -1;
        } else {
          return positionA < positionB ? 1 : -1;
        }
      } else {
        if (!isSortedDescending) {
          return (a[columnKey] || '').localeCompare(b[columnKey] || '');
        } else {
          return (b[columnKey] || '').localeCompare(a[columnKey] || '');
        }
      }
    });
  }

}
