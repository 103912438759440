import * as React from 'react';
import i18n from "i18next";
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import { Resizable } from 're-resizable';
import { Selection, ActionButton, DetailsList, ScrollablePane, Icon, ScrollbarVisibility, IRenderFunction, IDetailsListCheckboxProps, CheckboxVisibility, Spinner, SpinnerSize, IContextualMenuProps, DirectionalHint, ContextualMenu, IDetailsHeaderProps, Sticky, StickyPositionType, IDetailsList, SelectionMode, DetailsListLayoutMode, ConstrainMode, IColumn, MessageBar } from '@fluentui/react';
import { userService } from '../_services';
import { Persona } from '@fluentui/react-components';
import { DetailsPanel } from '../_components/DetailsPanel';
import { ListExplorer } from '../_components/ListExplorer';
import { NoteViewer } from '../_components/_cards/NoteViewer';
import Moment from 'moment';

export interface Props {
  routeProps: any
}

export interface States {
  contactId: string;
  showRightPanel: boolean;
  item: any;
  syncStatus: any;
  breadcrumbPath: any[];
  updated: boolean;
  query:string;
  selItem: any;
  panelHeight: any;
  panelWidth: any;
  activities: any;
  activitiesFull: any;
  newToOld: boolean;
  allCardsCollapsed: boolean;
  cardsSortedBy: string;
  showLastItems: any[];
  searchQuery: string;
  customProperties: any[];
  contextMenuProps: any;
  possibleContacts: any[];
  columns: any;
  isLoading: boolean;
  alertMessage: any;
}

class NotesPage extends React.Component<Props, States> {
  private _isMounted: boolean;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();
  private _root:any = React.createRef<IDetailsList>();  
  private _selection: Selection;

  private updateSelection() {
    if (this.props.page === 'notesPreview') {
      let selItem:any = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null;
      if (selItem && !selItem.content) {
        userService.getNoteContent(selItem.id).then((response)=>{
          let activities = this.state.activities;
          for (let i in activities) {
            if (activities[i].id === selItem.id) {
              activities[i] = {...response.data, type: 'NOTE', key: selItem.id};
              selItem = activities[i]
            }
          }
          let newSelItem:any = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null
          this._isMounted && this.setState({
            activities: activities,
            selItem: newSelItem && newSelItem.id === selItem.id ? selItem : newSelItem
          });
        })
      }
      else {
        this._isMounted && this.setState({
          selItem: selItem
        });
      }
    }
  }

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.updateSelection()
      },
      onItemsChanged: () => {
        this.updateSelection()
      }
    });

    var panelWidth = "240px"
    if (typeof(localStorage.getItem("sidebar3Prefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("sidebar3Prefs") || "[]");
      panelWidth = panelPrefs.width
    }

    this.state = {
      contactId: this.props.userData ? this.props.userData.user.id : null,
      showRightPanel: localStorage.detailsPanelOpen ? localStorage.detailsPanelOpen === 'true' : true,
      item: null,
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      },
      breadcrumbPath: [
        {
          text: i18n.t('app:notes'),
          key: 'notes',
          onClick: ()=>{history.push('/notes')}
          }
      ],
      updated: false,
      query: 'all',
      selItem: null,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      activities: [],
      activitiesFull: [],
      newToOld: true,
      allCardsCollapsed: false,
      cardsSortedBy: 'By date',
      showLastItems: [
        {key: 'allActivities', title: 'Show all in Main view'}
      ],
      searchQuery: '',
      customProperties: [],
      contextMenuProps: null,
      possibleContacts: [],
      columns: [],
      isLoading: true,
      alertMessage: null
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this._getColumns();
    this.props.userData && this.getCurrentContent();
    if (this.props.pusher && this.props.userData && this._isMounted) {
      console.log('Pusher connect: Workflow-'+ this.props.userData.repository.id +"-NOTE")
      this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "NOTE"})
      this.props.pusher.connect()
    }
    var listEl = document.getElementsByClassName("list-sidebar")[0]
    if(listEl) listEl.addEventListener("keydown", this._handleListKeyDown);
  }
  
  public componentDidUpdate(prevProps:any, prevState:any) {
    if (prevProps.page !== this.props.page) {
      if (this.props.page === 'notesPreview') {
        let query = new URLSearchParams(document.location.search);
        let selItem = query.get('selItem');
        if (selItem) {
          this._selection.setAllSelected(false)
          setTimeout(()=>{
            this._selection.setKeySelected(selItem || '', true, true)
            var selIndex = this._selection.getSelectedIndices()[this._selection.getSelectedIndices().length-1]
            this._root.current && this._root.current.focusIndex(selIndex);
            history.replace('/notes/preview')
          },50)
        } else {
          this._selection.setAllSelected(false)
        }
      }
    }
    if(this.props.userData && (this.props.userData !== prevProps.userData || this.state.searchQuery !== document.location.search)) {
      const params = new URLSearchParams(document.location.search);
      const selItem = params.get('selItem')
      if (!selItem || selItem.length === 0) {
        this.getCurrentContent(true);
      }
    }
    if (prevProps.pusher !== this.props.pusher) {
      if (this.props.pusher && this.props.userData && this._isMounted) {
        console.log('Pusher connect: Workflow-'+ this.props.userData.repository.id +"-NOTE")
        this.props.pusher.emit('connect workflow room', {token: userService.getCookie('token'), roomName: "NOTE"})
        this.props.pusher.connect()
      }
    }
    if (prevProps.pusherMessage !== this.props.pusherMessage) {
      const { pusherMessage } = this.props;
      if(pusherMessage && pusherMessage.message) {
        console.log(pusherMessage)
        if (pusherMessage.message.itemType === 'NOTE') {
          if (pusherMessage.message.action === 'DELETE') {
            let items = this.state.activities;
            items = items.filter((item)=>{return item.id !== pusherMessage.message.itemId})
            this._isMounted && this.setState({activities: items})
          } else if (pusherMessage.message.action === 'UPDATE') {
            let items = this.state.activities;
            for (let i in items) {
              if (items[i].id === pusherMessage.message.itemId) {
                userService.getNoteContent(pusherMessage.message.itemId).then((response)=>{
                  if (response.data) {
                    items[i] = response.data
                    items[i].key = items[i].id;
                    items[i].type = 'NOTE';
                    items[i].date = items[i].modifiedDateTime;
                    this._isMounted && this.setState({activities: items.sort((a,b) => { return (a?.date >= b?.date ? -1 : 1)})}, ()=>{
                      this.updateSelection()
                    })
                  }
                })
                break;
              }
            }
          } else if (pusherMessage.message.action === 'CREATE') {
            userService.getNote(pusherMessage.message.itemId).then((response)=>{
              if (response.data) {
                let items = this.state.activities;
                let item = response.data
                item.key = item.id;
                item.type = 'NOTE';
                item.date = item.modifiedDateTime;
                items.push(item)
                this._isMounted && this.setState({activities: items.sort((a,b) => { return (a?.date >= b?.date ? -1 : 1)})}, ()=>{
                  this.updateSelection()
                })
              }
            })
          }
        }
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    if (this.props.pusher) {
      this.props.pusher.disconnect();
    }
    var listEl = document.getElementsByClassName("list-sidebar")[0]
    if(listEl) listEl.removeEventListener("keydown", this._handleListKeyDown, false);
  }

  private _handleListKeyDown = (event) => {
    if (event.repeat) { return }
    const selection:any[] = this._selection.getSelection();
    const selectedCount = selection.length;
    switch( event.code ) {
      case 'Delete': // Delete
        if(event.target.className.indexOf("ms-DetailsRow") !== -1 || event.target.className.indexOf("ms-GroupHeader") !== -1) {
          if (this.state.selItem && this.state.selItem.type === "NOTE" && selectedCount === 1) this.headerRef._showNoteFormDelete()
          else if (this.state.selItem && this.state.selItem.type === "CONVERSATION" && selectedCount === 1) this.headerRef._showConversationFormDelete()
          else if (this.state.selItem && this.state.selItem.type === "EMAIL" && selectedCount === 1) this.headerRef._showEmailFormDelete()
        }
        break;
      case 'F2': //Edit
        if(event.target.className.indexOf("ms-DetailsRow") !== -1 || event.target.className.indexOf("ms-GroupHeader") !== -1) {
          if(event.target.className.indexOf("ms-DetailsRow") !== -1 || event.target.className.indexOf("ms-GroupHeader") !== -1) {
            if (this.state.selItem && this.state.selItem.type === "NOTE" && selectedCount === 1) this.headerRef._showNoteFormEdit()
            else if (this.state.selItem && this.state.selItem.type === "CONVERSATION" && selectedCount === 1) this.headerRef._showConversationFormEdit()
          }
        }
        break;
      default:
      break;
    }
  }

  private filterByRelations(items) {
    const params = new URLSearchParams(document.location.search);
    let project = params.get('projectIds') || ''
    let contract = params.get('contractIds') || ''
    let contact = params.get('contactIds') || ''
    let company = params.get('companyIds') || ''
    let newItems = items;
    if (project === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.projectIds) {
          if (item.projectIds[i]) return true
        }
        return false
      })
    } else if (project === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.projectIds || item.projectIds.length === 0) return true
        return false
      })
    } else if (project && project.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.projectIds) {
          if (project.includes(item.projectIds[i])) return true
        }
        return false
      })
    }
    if (contract === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.contractIds) {
          if (item.contractIds[i]) return true
        }
        return false
      })
    } else if (contract === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.contractIds || item.contractIds.length === 0) return true
        return false
      })
    } else if (contract && contract.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.contractIds) {
          if (contract.includes(item.contractIds[i])) return true
        }
        return false
      })
    }
    if (contact === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.contactIds) {
          if (item.contactIds[i]) return true
        }
        return false
      })
    } else if (contact === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.contactIds || item.contactIds.length === 0) return true
        return false
      })
    } else if (contact && contact.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.contactIds) {
          if (contact.includes(item.contactIds[i])) return true
        }
        return false
      })
    }
    if (company === 'all') {
      newItems = newItems.filter((item)=>{
        for (let i in item.companyIds) {
          if (item.companyIds[i]) return true
        }
        return false
      })
    } else if (company === 'none') {
      newItems = newItems.filter((item)=>{
        if (!item.companyIds || item.companyIds.length === 0) return true
        return false
      })
    } else if (company && company.length > 0) {
      newItems = newItems.filter((item)=>{
        for (let i in item.companyIds) {
          if (company.includes(item.companyIds[i])) return true
        }
        return false
      })
    }
    return newItems;
  }

  private _getColumns() {

    const columns = [
      {
        key: 'icon',
        name: '#',
        fieldName: 'icon',
        minWidth: 1,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '0px'}}/>
            :
              <div style={{borderRadius: '0px', height: '28px'}}><Persona name={item.subject} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful", shape: "square" }}/></div>
            )
        }
      },
      {
        key: 'subject',
        name: i18n.t('app:subject'),
        fieldName: 'subject',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        //onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <span title={ item.subject }>{ item.subject }</span>
          )
        },
      },
      {
        key: 'noteType',
        name: i18n.t('app:noteType'),
        fieldName: 'noteType',
        minWidth: 220,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <div className='d-flex align-items-center w-100' style={{}}>
              <Icon iconName={item.noteType === 'PLAIN' ? 'QuickNote' : item.noteType === 'SMS' ? 'OfficeChat' : item.noteType === 'LINKEDIN' ? 'LinkedInLogo' : item.noteType === 'WHATSAPP' ? 'Phone' : item.noteType === 'CALL' ? 'Phone' : item.noteType === 'EMAIL' ? 'Mail' : ''} style={{color: 'rgb(16, 110, 190)'}}/>
              <span className='ml-1 pr-2'>{item.noteType === 'PLAIN' ? 'Standard note' : item.noteType === 'SMS' ? 'SMS' : item.noteType === 'LINKEDIN' ? 'LinkedIn' : item.noteType === 'WHATSAPP' ? 'Whatsapp' : item.noteType === 'CALL' ? 'Call' : item.noteType === 'EMAIL' ? 'Email' : ''}</span>
            </div>
          )
        },
      },
      {
        key: 'modifiedDateTime',
        name: i18n.t('app:modifiedAt'),
        fieldName: 'modifiedDateTime',
        minWidth: 150,
        isResizable: true,
        isSorted: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return <span title={ Moment(item.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }>{ Moment(item.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }</span>
        }
      },
      {
        key: 'createdDateTime',
        name: i18n.t('app:createdAt'),
        fieldName: 'createdDateTime',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return <span title={ Moment(item.createdDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }>{ Moment(item.createdDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }</span>
        }
      }
    ]

    this._isMounted && this.setState({columns: columns})
  }

  private getCurrentContent(hidden?:boolean) {
    this._isMounted && this.setState({
      activities: hidden ? this.state.activities : [],
      isLoading: true,
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      },
      searchQuery: document.location.search
    })
    
    let activity:any[] = [];
    const params = new URLSearchParams(document.location.search);
    const types = params.get('type')?.split(',') || null;
    const sortBy = params.get('sort') || '';
    const sortOrder = params.get('sortOrder') || '';
    let options = {}
    const project = params.get('projectIds')  || ''
    if (project.length > 0) options['projectIds'] = project.split(',')
    const contract = params.get('contractIds')  || ''
    if (contract.length > 0) options['contractIds'] = contract.split(',')
    const contact = params.get('contactIds')  || ''
    if (contact.length > 0) options['contactIds'] = contact.split(',')
    const company = params.get('companyIds')  || ''
    if (company.length > 0) options['companyIds'] = company.split(',')
    if (sortBy) options['sort'] = sortBy

    const getNotes = userService.getNotes(sortBy ? '&sort='+sortBy+','+sortOrder : '&sort=modifiedDateTime,DESC').then(async (response:any)=>{
      activity = [...activity, ...response.data.content.map((item)=>{return item ? {...item, type: 'NOTE', key: item.id, date: item.modifiedDateTime} : null})];
      activity = this.filterByRelations(activity);
    }).catch((error)=>{
      console.log(error);
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      })
    })

    let admins:any[] = []
    const getRepoAdmins = userService.getRepoUsers('ADMIN').then((response)=>{
      admins = response.data;
      for (let i=0; i<admins.length; i++) {
        admins[i].key = admins[i].id
        admins[i].text = admins[i].name + (admins[i].surname ? (' ' + admins[i].surname) : '')
      }
      
    }).catch((error)=>{
      console.log(error);
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      })
    })

    let contacts:any[] = []
    const getRepoUsers = userService.getRepoUsers('INTERNAL').then((response)=>{
      contacts = response.data;
      for (let i=0; i<contacts.length; i++) {
          contacts[i].key = contacts[i].id
          contacts[i].text = contacts[i].name + (contacts[i].surname ? (' ' + contacts[i].surname) : '')
      }
    }).catch((error)=>{
      console.log(error);
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      })
    })

    Promise.all([getNotes, getRepoAdmins, getRepoUsers]).then((res) => {
      this._isMounted && this.setState({
        activitiesFull: activity.filter((item)=>{return !types || types.includes(item.type.toLowerCase())}),
        activities: activity.filter((item)=>{return !types || types.includes(item.type.toLowerCase())}),
        updated: true,
        possibleContacts: contacts.concat(admins),
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        },
        selItem: null
      },()=>{
        setTimeout(()=>{this.updateSelection()},500)
      });
    })
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private sortItems(field:string) {
    const { newToOld, activities } = this.state;
    let sorted = _copyAndSort(activities, field, newToOld)
    this._isMounted && this.setState({
      newToOld: !newToOld,
      activities: sorted,
      cardsSortedBy: field === 'date' ? 'By date' : field === 'title' ? 'By title' : 'By date'
    })
  }

  private _onContextMenu(item, index, ev: MouseEvent | any) {
    ev.preventDefault();
    const selection:any[] = this._selection.getSelection();
    const selectedCount = selection.length;

    const contextMenuProps: IContextualMenuProps = {
      target: ev.target as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      items: item.type === 'CONVERSATION' ? [
        {
          key:'editConversation',
          name:i18n.t('app:editConversation'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showConversationFormEdit(),
        },
        {
          key:'deleteConversation',
          name:i18n.t('app:deleteConversation'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showConversationFormDelete(),
        }
      ] : item.type === 'NOTE' ? [
        {
          key:'editNote',
          name:i18n.t('app:editNote'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showNoteFormEdit(),
        },
        {
          key:'deleteNote',
          name:i18n.t('app:deleteNote'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showNoteFormDelete(),
        }
      ] : item.type === 'EMAIL' ? [
        {
          key:'deleteEmail',
          name:i18n.t('app:deleteEmail'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showEmailFormDelete(),
        }
      ] : [],
      onDismiss: () => {
        this.setState({
          contextMenuProps: undefined
        });
      }
    }
    
    this._isMounted && this.setState({contextMenuProps: contextMenuProps})
    return true;

  }

  public render() {
    const { item, query, activities, cardsSortedBy, columns } = this.state

    return(
      <div>
        <Header
          ref={instance => { this.headerRef = instance; }}
          userData={ this.props.userData }
          breadcrumbPath={ this.state.breadcrumbPath }
          sidebarRef={ this.sidebarRef }
          page={this.props.page}
          actions="notes"
          getActivity={ null }
          syncStatus={this.state.syncStatus}
          selItem={this.state.selItem}
          getCurrentContent={this.getCurrentContent.bind(this)}
          showRightPanel={ this.state.showRightPanel }
          callbackFunction={this.callbackFunction}
          listExplorerRef={this.listExplorerRef}
          selection={this.props.page === 'notes' ? undefined : this._selection.getSelection()}
        />
          <div className="content-wrap d-flex flex-row" >
            <Sidebar
              ref={(instance:any) => { this.sidebarRef = instance; }}
              headerRef={ this.headerRef }
              userData={ this.props.userData }
              page={this.props.page}
              callbackFunction={this.callbackFunction}
              item={item}
              items={activities}
              query={query}
            />
            {this.props.page === 'notes' ?
            <div className="list flex-grow-1 justify-content-center" style={{textAlign: 'left'}}>
              <ListExplorer
                ref={instance => { this.listExplorerRef = instance; }}
                appPlatform={ this.props.appPlatform }
                isOnline={ this.props.isOnline }
                maintenance={ this.props.maintenance }
                socket={this.props.socket}
                pusher={this.props.pusher}
                userData={this.props.userData}
                foldersList={ this.props.foldersList }
                items={activities}
                selItem={ this.state.selItem }
                isCompactMode={true}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                constrainMode={ConstrainMode.unconstrained}
                headerRef={ this.headerRef }
                detailsPanelRef={ this.detailsPanelRef }
                callbackFunction={ this.callbackFunction.bind(this) }
                page={this.props.page}
                sidebarRef={ this.sidebarRef }
                loading={this.state.isLoading}
              /> 
            </div>
          :
          <div className='d-flex flex-grow-1' style={{overflowX: 'auto', overflowY: 'hidden', height: 'calc(100vh - 129px)'}}>
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this._isMounted && this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                }, () => {
                  this._saveResizeState();
                });
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={254}
              //maxWidth={400}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree second list-sidebar">
                <ScrollablePane style={{width: 'calc(100% - 1px)', background: '#f2f2f2'}} scrollbarVisibility={ScrollbarVisibility.always}>
                <DetailsList
                  className='list-sidebar'
                  items={activities}
                  checkboxVisibility={CheckboxVisibility.always}
                  onRenderCheckbox={checkboxColumn}
                  onRenderMissingItem={()=>{console.log('hello')}}
                  onRenderDetailsHeader={onRenderDetailsHeader}
                  onItemContextMenu={(item,index,ev) => this._onContextMenu(item,index,ev)}
                  onItemInvoked={(item)=>{history.push('/notes/id/'+item.id)}}
                  columns={[
                    {
                      key: 'title',
                      name: 'Title',
                      fieldName: 'title',
                      minWidth: 1,
                      isRowHeader: true,
                      data: 'string',
                      isPadded: true,
                      onRender: (item: any) => {
                        return (
                          <>
                            <div className='d-flex align-items-center'  style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                              <img className='activity-icon' style={{height: '24px', width: '24px', minWidth: '24px', position:'absolute', left: '-33px', top: '8px', display: (this._selection.getSelection()[0] && this._selection.getSelection()[0].key === item.key) || this._selection.getSelection().length > 1  ? 'none' : 'block'}} alt='Note' src={process.env.PUBLIC_URL + '/icons/documents.svg'} />
                              <div className='align-items-center ml-1 flex-grow-1' style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                <div className='d-flex'>
                                  <span className='span-title mr-2' style={{textOverflow: 'ellipsis', overflowX: 'hidden'}}>{item.content && item.content.subject ? item.content.subject : item.subject}</span>
                                </div>
                                <div className='d-flex'>
                                  <span className='mr-2' style={{fontSize:12, textOverflow: 'ellipsis', overflowX: 'hidden'}}>{this.getContactName(item.createdByUserId)}</span>
                                </div>
                                <div className='d-flex'>
                                  <span className='' style={{fontSize:12, color: '#424242', overflowX: 'hidden', textOverflow: 'ellipsis', maxWidth: 'calc(100% - 80px)'}}>{item.from ? item.from.emailAddress.name : ''}</span>
                                  <div className='d-flex align-items-center w-100' style={{}}>
                                     <Icon iconName={item.noteType === 'PLAIN' ? 'QuickNote' : item.noteType === 'SMS' ? 'OfficeChat' : item.noteType === 'LINKEDIN' ? 'LinkedInLogo' : item.noteType === 'WHATSAPP' ? 'Phone' : item.noteType === 'CALL' ? 'Phone' : item.noteType === 'EMAIL' ? 'Mail' : ''} style={{fontSize: '12px', color: 'rgb(16, 110, 190)'}}/>
                                    <span className='ml-1 pr-2' style={{fontSize:12, color: '#424242', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{item.noteType === 'PLAIN' ? 'Standard note' : item.noteType === 'SMS' ? 'SMS' : item.noteType === 'LINKEDIN' ? 'LinkedIn' : item.noteType === 'WHATSAPP' ? 'Whatsapp' : item.noteType === 'CALL' ? 'Call' : item.noteType === 'EMAIL' ? 'Email' : ''}</span>
                                    <span className='ml-auto mr-3' style={{fontSize:12, color: '#424242'}}>{item.content && item.content.modifiedDateTime ? Moment(item.content.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) : item.savedDateTime ? Moment(item.savedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) : Moment(item.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm'))}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      },
                      onRenderHeader: (item: any)=>{
                        return(
                          <div className='d-flex align-items-center w-100'>
                            <span style={{marginRight: '28px'}}> 
                            Title
                            </span>
                            <ActionButton 
                              className='ml-auto mr-3 px-0' 
                              style={{color: 'rgb(50, 49, 48)'}} 
                              iconProps={{iconName:'SortLines'}}
                              text={cardsSortedBy} 
                              menuProps={{items: [
                                {
                                  key: 'byDate',
                                  text: 'By date',
                                  iconProps: {iconName:'Calendar'},
                                  onClick:()=>this.sortItems('date')
                                },
                                {
                                  key: 'byName',
                                  text: 'By title',
                                  iconProps: {iconName:'HalfAlpha'},
                                  onClick:()=>this.sortItems('title')
                                }
                              ]}}
                            />
                          </div>
                      )},

                    },
                  ]}
                  selection={ this._selection }
                  setKey="set"
                />
                </ScrollablePane>
                { this.state.contextMenuProps ?
                  <ContextualMenu {...this.state.contextMenuProps} />
                : null }
                {(!activities || activities.length === 0) && this.state.syncStatus.message === 'Synced' && <div style={{opacity: .8}} className='mt-5 pt-2 text-center'>No items</div> }
                {(!activities || activities.length === 0) && this.state.syncStatus.message === 'Syncing' && <Spinner className='mt-5 pt-2 text-center' size={SpinnerSize.medium}/> }
              </nav>
            </Resizable>
            <div className="list mr-auto flex-grow-1 d-flex flex-column" style={{minWidth: '420px'}}>
              <div style={{overflowY: 'hidden', overflowX: 'auto', minWidth: '400px', height: '100%'}}>
              <div className='d-flex header-card py-2'>
                <div className='d-flex flex-grow-1 align-items-center ml-1'>
                  {this.state.selItem ? 
                  <>
                    <img className='activity-icon mr-3' style={{height: '24px', width: '24px', minWidth: '24px'}} alt='Task' src={process.env.PUBLIC_URL + '/icons/documents.svg'} />
                    <span style={{fontWeight: 600}}>{this.state.selItem.subject}</span>
                  </>
                  :
                  i18n.t('app:mainView')
                  }
                </div>
              </div>
              {this.state.alertMessage ?
                <div id="alertMessage">
                  <MessageBar
                    messageBarType={this.state.alertMessage.type}
                    isMultiline={true}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => this.setState({alertMessage: null})}
                  >
                    <div dangerouslySetInnerHTML={{__html: this.state.alertMessage.text}}></div>
                  </MessageBar>
                </div>
              : null }
              <div className='flex-grow-1' style={{height: 'calc(100% - 44px)'}}>
                { this.state.selItem ?
                  <div className='h-100' key={this.state.selItem.key}>
                    {this.activityCard(this.state.selItem)}
                  </div>
                : this._selection && this._selection.getSelection().length > 1 ?
                <div className='text-center h-100  d-flex flex-column' style={{backgroundColor: 'rgb(250, 249, 248)'}}> 
                  <div className='text-center text-secondary m-auto'>
                    <Icon style={{fontSize: '24px'}} iconName='Documentation'/>
                    <p>{this._selection.getSelection().length + ' ' + i18n.t('app:itemsSelected')}</p>
                  </div>
                </div> 
                : 
                <div className='text-center h-100  d-flex flex-column' style={{backgroundColor: 'rgb(250, 249, 248)'}}> 
                  <div className='text-center text-secondary m-auto'>
                    <Icon style={{fontSize: '24px'}} iconName='Preview'/>
                    <p>{i18n.t('app:noItemSelected')}</p>
                  </div>
                </div> 
                }
                </div>
              </div>
            </div>
          </div>
          }
          <DetailsPanel
            ref={instance => { this.detailsPanelRef = instance; }}
            listExplorerRef={ this.props.page === 'notes' ? this.listExplorerRef : this }
            appPlatform={ this.props.appPlatform }
            userData={ this.props.userData }
            showRightPanel={ this.state.showRightPanel }
            callbackFunction={this.callbackFunction}
            page={this.props.page}
            selItem={ this.state.selItem }
            items={this.state.activities}
            headerRef={this.headerRef}
            getCurrentContent={this.getCurrentContent.bind(this)}
            selection={ this._selection }
            possibleContacts={this.state.possibleContacts}
          />
        </div>
      </div>
    )
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("sidebar3Prefs", JSON.stringify(prefs))
  }

  private activityCard(item) {
    return (
      <div className='h-100' onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
        <NoteViewer
          allCardsCollapsed={this.state.allCardsCollapsed}
          item={item}
          possibleContacts={this.state.possibleContacts}
        />
      </div>
    )
  }


  private getContactName(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })

    return user ? (user.name || '') + ' ' + (user.surname || '') : ''
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    //const newItems = _copyAndSort(activities, currColumn.fieldName!, currColumn.isSortedDescending);
    localStorage.setItem("columnsNotes", JSON.stringify(newColumns))
    //this._isMounted && this.setState({
    //  columns: newColumns,
    //  activities: newItems,
    //});
    let query = new URLSearchParams(document.location.search);
    query.set('sort',currColumn.fieldName!)
    query.set('sortOrder', currColumn.isSortedDescending ? 'DESC' : 'ASC')
    history.push('/notes?'+query.toString())
  };

  private _sortBySavedColumn = (items: any): void => {
    if (typeof(localStorage.getItem("columnsNotes")) === "string") {
      var dashboardColumns: IColumn[] = JSON.parse(localStorage.getItem("columnsNotes") || "[]");

      for (let i = 0; i < this.state.columns.length; i++) {
        let column = this.state.columns[i]

        let savedColumn: any = dashboardColumns.filter(savedColumn => {
          return column.key === savedColumn.key
        })
        savedColumn = savedColumn[0]

        if(column && savedColumn) {
          column.isSorted = savedColumn.isSorted;
          column.isSortedDescending = savedColumn.isSortedDescending

          if(column.isSorted) {
            const { columns } = this.state;
            const newColumns: IColumn[] = columns.slice();
            const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        
            const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
            this._isMounted && this.setState({
              columns: newColumns,
              activities: newItems.map((item:any)=>{return {...item, key: item.id}}),
            });
          }
        } else { 
          this._isMounted && this.setState({activities: items.map((item:any)=>{return {...item, key: item.id}})})
        }
      }
    } else {
      this._isMounted && this.setState({activities: items.map((item:any)=>{return {...item, key: item.id}})})
    }
  };
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean, secondColumnKey?: string): T[] {
  const key = columnKey as keyof any;
  const key2 = secondColumnKey as keyof any;
  if (key === 'modifiedDateTime' || key === 'createdDateTime') {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (!a) return -1;
      if (!b) return 1;
      if (a[key2] < b[key2]) return -1;
      if (a[key2] > b[key2]) return 1;
      else if (parseFloat(a[key]) < parseFloat(b[key])) return isSortedDescending ? 1 : -1;
      else if (parseFloat(a[key]) > parseFloat(b[key])) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? parseFloat(a[key]) < parseFloat(b[key]) : parseFloat(a[key]) > parseFloat(b[key])) ? 1 : -1));
  } else {
    return items.slice(0).sort((a,b) => {
      if (!a) return -1;
      if (!b) return 1;
      if (!isSortedDescending) {
        return (a[columnKey] || '').localeCompare(b[columnKey] || '');
      } else {
        return (b[columnKey] || '').localeCompare(a[columnKey] || '');
      }
    });
  }
}

function checkboxColumn(props: any, defaultRender?: IRenderFunction<IDetailsListCheckboxProps>): JSX.Element {
  return  (
    <div className='d-flex align-items-center justifty-content-center h-100 w-100'>
      {defaultRender!({
        ...props
      })}
    </div>
  )
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}

export default NotesPage;
