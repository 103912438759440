import { Icon, TooltipHost } from '@fluentui/react';
import { FluentProvider, Persona } from '@fluentui/react-components';
import * as React from 'react';
import i18n from '../../i18n';
import * as DOMPurify from 'dompurify';
import Moment from 'moment';

export interface Props {
  allCardsCollapsed: boolean;
  item: any;
}

export interface States {
  collapsed: boolean;
  item: any;
  possibleContacts: [];
  markdown: any;
}

export class NoteViewer extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      collapsed: this.props.allCardsCollapsed,
      item: this.props.item,
      possibleContacts: this.props.possibleContacts || [],
      markdown: null
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    let string = this.props.item.content || "";
    var MarkdownIt = require('markdown-it');
    var emoji = require('markdown-it-emoji');
    var md = new MarkdownIt();
    md.use(emoji);
    var result = md.render(DOMPurify.sanitize(string));
    let markdown = { __html: result };
    this.setState({markdown: markdown})
  }
  
  public componentDidUpdate(prevProps:any) {
    if (prevProps.allCardsCollapsed !== this.props.allCardsCollapsed) {
      this._isMounted && this.setState({collapsed: this.props.allCardsCollapsed})
    }

    if (prevProps.item !== this.props.item) {
      this._isMounted && this.setState({item: this.props.item})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public render() {
    return(
      <div className='h-100 d-flex flex-column'>
        <div style={{background: 'white', borderBottom: '1px solid #f2f2f2'}}>
          <div className='d-flex align-items-center px-2 py-2'>
            <div className='d-flex align-items-center mr-3 ml-3' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
              <span className='mr-2'>Created by </span>
              <span className='mt-1'>{this.getPersona(this.state.item.createdByUserId)}</span>
              <span>at {Moment(this.state.item.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm'))}</span>
            </div>
          </div>
          <div>
            <div className='d-flex align-items-center ml-4 mb-3' style={{}}>
              <Icon iconName={this.state.item.noteType === 'PLAIN' ? 'QuickNote' : this.state.item.noteType === 'SMS' ? 'OfficeChat' : this.state.item.noteType === 'LINKEDIN' ? 'LinkedInLogo' : this.state.item.noteType === 'WHATSAPP' ? 'Phone' : this.state.item.noteType === 'CALL' ? 'Phone' : this.state.item.noteType === 'EMAIL' ? 'Mail' : ''} style={{fontSize: '14px', color: 'rgb(16, 110, 190)'}}/>
              <span className='ml-2 mr-3' style={{fontSize:14, color: '#424242'}}>{this.state.item.noteType === 'PLAIN' ? 'Standard note' : this.state.item.noteType === 'SMS' ? 'SMS' : this.state.item.noteType === 'LINKEDIN' ? 'LinkedIn' : this.state.item.noteType === 'WHATSAPP' ? 'WhatsApp' : this.state.item.noteType === 'CALL' ? 'Call' : this.state.item.noteType === 'EMAIL' ? 'Email' : ''}</span>
            </div>
          </div>
        </div>
        <div id='main-view' className='py-3 px-3 flex-grow-1' style={{overflowY: 'auto', backgroundColor: 'rgb(250, 249, 248)'}}>
          <div className='p-3 info-card'>
            {this.state.markdown && <div className='markdown-frame'>
              <div className='markdown-body w-100' dangerouslySetInnerHTML={this.state.markdown}>
              </div>
            </div>}
          </div>
        </div>
      </div>

    )
  }

  private getPersona(id: string) {
    let user:any = this.state.possibleContacts.find((item:any) => {
      return item.id === id;
    })
    return (
      user? <TooltipHost
        content= {
          <div className='m-1'>
            <FluentProvider>
              <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
              <Persona className='mb-2' name={(user.name || '') + ' ' + (user.surname || '')} secondaryText={user.email} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
            </FluentProvider>
          </div>
        }
      >
        <Persona className='pr-2' size='extra-small' name={(user.name || '') + ' ' + (user.surname || '')} textAlignment='center' avatar={{ color: "colorful" }}/>
      </TooltipHost> : ''
    )
  }

}
