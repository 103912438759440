import { ActionButton, Checkbox, ComboBox, DatePicker, Dialog, DialogType, Dropdown, Icon, IconButton, TooltipHost} from '@fluentui/react';
import { FluentProvider, Input, Label, Textarea, webLightTheme } from '@fluentui/react-components';
import * as React from 'react';
import i18n from '../i18n';


export interface Props {
  callback:any;
  item: any;
}

export interface States {
  hidden: boolean,
  loading: boolean,
  name: string,
  description: string,
  fieldGroups: any,
  fieldGroupsKeys: any,
  fieldType: any,
  multipleOptions: any
}

export class CustomFieldPreviewDialog extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      name: '',
      description: '',
      fieldGroups: [],
      fieldGroupsKeys: [],
      fieldType: 'singleLine',
      multipleOptions: [{id: '1', key: '1', text: ''}],
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      setTimeout(()=>this.props.callback({showCustomFieldPreviewDialog: false, itemToPreview: null}),200)
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private close() {
    this._isMounted && this.setState({hidden:true})
  }

  public render() {

    const { item } = this.props;


    let options:any = [];
    if (item.possibleValues) {
      for (let key in item.possibleValues) {
        options.push({key:key, text: item.possibleValues[key]}) 
      }
      options.sort((a,b) => { return (a.text >= b.text ? 1 : -1)})
    }
    return (
      <div>
      <Dialog
      hidden={this.state.hidden}
      dialogContentProps={{
        type: DialogType.close,
        title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Filters' src={process.env.PUBLIC_URL + '/icons/filters.svg'} />{i18n.t('app:customFieldPreview')}</div>,
        onDismiss: ()=> {this.close()},
        styles: {innerContent: {overflowY: 'overlay'}}
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { maxWidth: 640 } },
        dragOptions: undefined,
        className: "form-dialog",
        onDismiss: ()=> {this.close()}
      }}
    >
      <div className='mb-3' style={{borderBottom: '1px solid #bfbfbf', width: 'calc(100% - 48px)', position: 'sticky', left: '24px', top: 0, zIndex: 1}}></div>
      <FluentProvider theme={webLightTheme}>
        <div className='d-flex mt-5 px-4'>
          <Icon 
            iconName={item.fieldType === 'STRING' ? 'TextField' 
            : item.fieldType === 'NUMBER' ? 'NumberField' 
            : item.fieldType === 'BOOLEAN' ? 'CheckboxComposite' 
            : item.fieldType === 'DATE' ? 'Calendar' 
            : item.fieldType === 'TAG' ? 'Dropdown'
            : item.fieldType === 'FILE' ? 'Document'
            : 'TextField'} 
            style={{marginTop: '5px', marginRight: '10px'}}
          />
          <Label className='form-label' style={{ marginTop: '4px'}}>{item.name}</Label>
        
          { item.fieldType === 'STRING' && item.multiLine ?
            <Textarea
              className='flex-grow-1'
            /> 
          : item.fieldType === 'STRING' ?
            <Input
            className='flex-grow-1'
            /> 
          : item.fieldType === 'NUMBER' ?
            <Input
              type='number'
              className='flex-grow-1'
            /> 
          : item.fieldType === 'BOOLEAN' ?
            <Checkbox
              className='flex-grow-1'
              styles={{label: {margin: '6px 0 6px 0'}}}
            />
          : item.fieldType === 'TAG' && item.multiSelection ?
            <Dropdown
              styles={{ title: {borderRadius: '4px'}}}
              className='flex-grow-1'
              options={options}
              multiSelect
            />
          : item.fieldType === 'TAG' ?
            <Dropdown
              styles={{ title: {borderRadius: '4px'}}}
              className='flex-grow-1'
              options={options}
            />
          : item.fieldType === 'DATE' ?
            <DatePicker
              className='flex-grow-1'
              styles={{root: {height: '32px'}}}
            />
          : item.fieldType === 'CONTACT' ?
            <div className='d-flex flex-grow-1'>
              <ComboBox
                className='flex-grow-1'
                options={[]}
              />
              <ActionButton
                className='align-self-end'
                style={{height: '32px'}}
                iconProps={{iconName: 'Add'}}
              >
                {i18n.t('New contact')}
              </ActionButton>
            </div>
          : item.fieldType === 'FILE' ?
            <div className='d-flex flex-grow-1'>
              <Input className='flex-grow-1'/>
              <TooltipHost
                content={i18n.t('app:openFilePicker')}
                styles={{root: {borderRadius: '4px', position: 'absolute', marginTop: '1px', right: '25px', width: '34px', height: '29px' }}}
              >
                <IconButton styles={{icon: {color: 'rgb(50,49,48)', height: '20px'}, root: {borderRadius: '4px', width: '34px', height: '29px'}}} iconProps={{iconName: 'DriveSynergyOS'}} />
              </TooltipHost>
            </div>
          : null }
        </div>
        </FluentProvider>
      </Dialog>
      </div>
    )
  }

}
