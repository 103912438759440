import * as React from 'react';
import { IDetailsList, ScrollablePane, ScrollbarVisibility, Selection, PrimaryButton, MessageBar, MessageBarType, MessageBarButton} from '@fluentui/react';
import { IconButton } from '@fluentui/react';
import { Persona } from '@fluentui/react/lib/Persona';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { history } from '../_helpers';
import i18n from "i18next";
import { userService } from '../_services';
import { Link } from '@fluentui/react-components';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
 const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#f4f6fa" : "#f4f6fa",
  borderLeft: "1px solid rgb(237, 235, 233)",
  borderRight: "1px solid rgb(237, 235, 233)",
  borderBottom: "1px solid rgb(237, 235, 233)",
  borderRadius : '2px',
  padding: grid,
  width: 250,
  //height: '100%',
  minHeight: 'calc(100% - 50px)',
  margin: '0 0.5rem'
});

export interface Props {
  match: {
    url: string
  },
  location: any,
  searchType: string,
  userData: any,
  page: string,
  callbackFunction: any,
  
}

export interface ColumnViewState {
  webUpdateAvailable: boolean;
  userData?: any;
  repoData?: any;
  goBack: number;
  showContextualMenu: boolean;
  selFolder: any;
  items: any;
  selectedTags: any;
  groups: any;
  columns: any;
  milestones: any;
  messageBarText: string;
  messageBarStatus: string;
  previousProjectId: string;
  previousPosition: string;
  previousResult: any;
  alertMessage: any;
}

export class ColumnView extends React.Component<Props, ColumnViewState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private _root:any = React.createRef<IDetailsList>();

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    //this._root = React.createRef<IDetailsList>(); 
    this.props = props


    this._selection = new Selection({
      onSelectionChanged: () => {
        var selItem: any = this._selection.getSelection()[this._selection.getSelection().length - 1]

        /*if(selItem) {
          var route = history.location.pathname
          var storedLastFocusedItems = JSON.parse(localStorage.getItem("lastFocusedItems") || "[]")

          var alreadyStored = storedLastFocusedItems.filter(item => {
            return item.route === route
          })[0]
          
          if(alreadyStored) {
            alreadyStored.item = selItem.id
          } else {
            var newFocusedItem = {
              route: route,
              item: selItem.id
            }
            storedLastFocusedItems.push(newFocusedItem)
          }

          localStorage.setItem("lastFocusedItems", JSON.stringify(storedLastFocusedItems))
        }*/

        setTimeout(() => {
          if (selItem && this._selection.getSelectedCount() === 1) {
            this._isMounted && this.props.callbackFunction({selItem: selItem});
          } else if (selItem && this._selection.getSelectedCount() > 1) {
            this._isMounted && this.props.callbackFunction({selItem: null});
          } else if (this._selection.getSelectedCount() === 0) {
            this._isMounted && this.props.callbackFunction({selItem: null});
          }
        }, 50)
      }
    });

    this.state = {
      webUpdateAvailable: false,
      userData: null,
      repoData: null,
      goBack: 0,
      showContextualMenu: false,
      selFolder: null,
      items: this.props.items,
      selectedTags: [],
      groups: [],
      columns: [],
      milestones: [],
      messageBarText: '',
      messageBarStatus: '',
      previousProjectId: '',
      previousPosition: '',
      previousResult: null,
      alertMessage: null
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    //this._setSelItem()
    if (this.props.page === "clientsExplorer"){ 
      let groups = _getGroups(this.props.items, 'company')
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "milestonesExplorer"){ 
      let groups = _getGroups(this.props.items, 'milestone') 
      this._isMounted && this.setState({groups:groups})
    }
    else if (this.props.page === "projectsExplorer"){ 
      this._isMounted && this.setState({groups:null})
    }
    this.handleSearch()
  }
  
  public componentDidUpdate(prevProps:any) {
    if (this.props.pipelineId === 'none' || this.props.pipelineId === 'all' || this.props.pipelineId.split(',').length > 1) {
      history.push('/projects/list' + document.location.search)
    }
    else if(this.props.items !== prevProps.items) {
      this.handleSearch()
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  onMessageReceived(event:any) {
    var data:any = event.data;

      if (data && data.msg === "webUpdateAvailable") {
        this._isMounted && this.setState({webUpdateAvailable: true})
      }
  }

  private getItemStyle = (isDragging, draggableStyle, item) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: "2px",
    border: this.props.selItem?.id === item.id ? "1px solid rgb(0, 120, 212)" : "1px solid lightgrey",
  
    // change background colour if dragging
    background: isDragging ? "white" : "white",
    boxShadow: isDragging ? '8px 8px 4px rgba(0,0,0,0.1)' : '0px 2px 4px rgba(0,0,0,0.1)',
  
    // styles we need to apply on draggables
    ...draggableStyle
  });

  private onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    let project = this.props.items.find((item)=>{return item.id === result.draggableId})
    project.stage = this.state.milestones[destination.droppableId].id;
    if (sInd === dInd) {
      const items = reorder(this.state.columns[sInd], source.index, destination.index);
      const newState = [...this.state.columns];
      newState[sInd] = items;
      this._isMounted && this.setState({columns:newState});
    } else {
      const result = move(this.state.columns[sInd], this.state.columns[dInd], source, destination);
      const newState = [...this.state.columns];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      this._isMounted && this.setState({columns:newState.filter(group => group)});
    }
    sInd !== dInd && userService.editProject(project.id, project).then(()=>{
      let id = project.id + '---' + new Date().getTime()
      this._isMounted && this.setState({previousProjectId: id, previousResult: result, messageBarText: 'projectStageUpdated', messageBarStatus: 'success'})
      setTimeout(()=>{
        id === this.state.previousProjectId && this._isMounted && this.setState({messageBarText: ''})
      },3000)
    }).catch((error)=>{
      console.log(error)
      if (sInd === dInd) {
        const items = reorder(this.state.columns[dInd], destination.index, source.index);
        const newState = [...this.state.columns];
        newState[dInd] = items;
        this._isMounted && this.setState({columns:newState});
      } else {
        const result = move(this.state.columns[dInd], this.state.columns[sInd], destination, source);
        const newState = [...this.state.columns];
        newState[sInd] = result[sInd];
        newState[dInd] = result[dInd];
  
        this._isMounted && this.setState({columns:newState.filter(group => group)});
      }
    })
  }

  private undoAction() {
    const { previousResult } = this.state;
    const sInd = +previousResult.source.droppableId;
    const dInd = +previousResult.destination.droppableId;

    let project = this.props.items.find((item)=>{return item.id === previousResult.draggableId})
    project.stage = this.state.milestones[previousResult.source.droppableId].id;

    if (sInd === dInd) {
      const items = reorder(this.state.columns[dInd], previousResult.destination.index, previousResult.source.index);
      const newState = [...this.state.columns];
      newState[dInd] = items;
      this._isMounted && this.setState({columns:newState, messageBarText: ''});
    } else {
      const result = move(this.state.columns[dInd], this.state.columns[sInd], previousResult.destination, previousResult.source);
      const newState = [...this.state.columns];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];
      this._isMounted && this.setState({columns:newState.filter(group => group), messageBarText: ''});
    }
    sInd !== dInd && userService.editProject(project.id, project).catch((error)=>{
      console.log(error)
      if (sInd === dInd) {
        const items = reorder(this.state.columns[sInd], previousResult.source.index, previousResult.destination.index);
        const newState = [...this.state.columns];
        newState[dInd] = items;
        this._isMounted && this.setState({columns:newState});
      } else {
        const result = move(this.state.columns[sInd], this.state.columns[dInd], previousResult.source, previousResult.destination);
        const newState = [...this.state.columns];
        newState[sInd] = result[sInd];
        newState[dInd] = result[dInd];
        this._isMounted && this.setState({columns:newState.filter(group => group)});
      }
    })
  }

  private handleSearch() {
    if (this.props.pipeline) {
      var stages = this.props.pipeline.stages.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})

      var columns:any = [];
  
      for (let i=0; i < stages.length; i++) {
        columns[i] = (this.props.items.filter((item)=>{
          return item.stage.id === stages[i].id
        }));
      }
  
      this._isMounted && this.setState({
        items: this.props.items,
        columns: columns,
        milestones: stages
      })
  
      let groups = _getGroups(this.props.items, 'milestone') 
      this._isMounted && this.setState({groups:groups})
    }
  }

  public render() {

    return(
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={{root:{height: '100%'}, contentContainer: {display: 'flex', flexDirection: 'column', height: '100%'}}}>
          <div className='d-flex align-items-center' style={{maxHeight: '46px', minHeight: '46px', borderBottom: '1px solid #f2f2f2'}}>
            { this.state.messageBarText && this.state.messageBarText.length > 0 ?
              <MessageBar className='h-100' styles={{content: {marginTop: 'auto', marginBottom: 'auto'}}} onDismiss={()=>{this._isMounted && this.setState({messageBarText: ''})}} isMultiline={false} actions={<div><MessageBarButton onClick={this.undoAction.bind(this)}>{i18n.t('app:undoAction')}</MessageBarButton></div>} messageBarType={MessageBarType.success}>
                {i18n.t('app:'+this.state.messageBarText)}
              </MessageBar>
            :
              <span className='ml-3'>
                {i18n.t('app:mainView')}
              </span>
            }
          </div>
          {this.state.alertMessage ?
            <div id="alertMessage">
              <MessageBar
                messageBarType={this.state.alertMessage.type}
                isMultiline={true}
                dismissButtonAriaLabel="Close"
                onDismiss={() => this.setState({alertMessage: null})}
              >
                <div dangerouslySetInnerHTML={{__html: this.state.alertMessage.text}}></div>
              </MessageBar>
            </div>
          : null }
          {this.state.columns && <>
            <PrimaryButton
              className='mt-3 ml-3 d-none h-100'
              text='Add new group'
              onClick={() => {
                this._isMounted && this.setState({columns:[...this.state.columns, []]})
              }}
            />
            <div className='d-flex flex-grow-1 py-3 px-2' style={{overflowX: 'auto'}}>
              <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                {this.state.columns.map((el, ind) => (
                  <div key={ind}>
                    <div className='mx-2 column-header' style={{borderTopColor: 'rgba(16, 110, 190, '+(ind+1)/this.state.milestones.length+')'}}>
                      <span className='pl-1'>
                        {this.state.milestones[ind].name}
                      </span>
                    </div>
                    <Droppable key={ind} droppableId={`${ind}`}>
                      {(provided, snapshot) => (
                        <div
                          className='droppable-columns'
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          {el.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={this.getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                    item
                                  )}
                                  onClick={()=>{this.props.callbackFunction({selItem: item})}}
                                >
                                  <div
                                    style={{
                                      //display: "flex",
                                      //justifyContent: "space-around"
                                    }}
                                  >
                                    <div className='col-12 px-0 my-1' style={{fontWeight:600}}>
                                      <Link style={{fontWeight: 600}} href={"/projects/"+item.id}>{item.name}</Link> 
                                    </div>
                                    <div className='col-12 px-0 my-1' style={{fontSize: 14}}>
                                      <span style={{fontWeight: 400}}>{i18n.t('app:company')+': '}</span>
                                      <span>{item.company}</span>
                                    </div>
                                    <div className='col-12 px-0 my-1' style={{fontSize: 14}}>
                                      <span style={{fontWeight: 400}}>{i18n.t('Close date')+': '}</span>
                                      <span>---</span>
                                    </div>
                                    <div className='col-12 px-0 my-1' style={{fontSize: 12, color: 'grey'}}>
                                      <span className='d-flex align-items-center'><Persona text={item.owner} color={'grey'} size={8} styles={{root: {width: '20px'}}}/>{item.owner}</span>
                                    </div>
                                    <div className='d-none col-2 px-0 my-auto'>
                                      <IconButton
                                      iconProps={{iconName:'Delete'}}
                                      onClick={() => {
                                        const newState = [...this.state.columns];
                                        newState[ind].splice(index, 1);
                                        this._isMounted && this.setState({columns:newState.filter(group => group.length)});
                                      }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                ))}
              </DragDropContext>
            </div>
          </>}
          </ScrollablePane>
    )
  }

  private _settingSelItem: boolean = false;

  private focusItem(selItem) {
    if(selItem !== null) {
      this._settingSelItem = true;
      this._selection.setAllSelected(false)
      setTimeout(() => {
        this._selection.setKeySelected(selItem, true, true)
        var selIndex = this._selection.getSelectedIndices()[0]
        this._root.current && this._root.current.focusIndex(selIndex);
        this._settingSelItem = false
      }, 200)
    }
  }

}

function _getGroups(items, param:string = 'company') {
  var groups:any = []
  for (var i=0; i<items.length;i++) {
    let parameter = items[i][param];
    if (groups.filter(function(e) { return e.key === parameter+'-group'; }).length > 0) {
      groups.filter(function(e) { return e.key === parameter+'-group'; })[0].count++;
    }
    else {
      groups.push({key: parameter+'-group', name: parameter, startIndex: i, count: 1})
    }
  }
  return groups
}
