import { Dialog, DialogType, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import i18n from '../../i18n';
import { history } from '../../_helpers';


export interface Props {
  callback: any;
}

export interface States {
  hidden: boolean;
}

export class AlertInvalidProject extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      history.push('/projects/list')
      setTimeout(()=>this.props.callbackFunction({close: true}),200)
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public render() {

    return(
      <Dialog
        hidden={this.state.hidden}
        dialogContentProps={{
          type: DialogType.normal,
          title: <div className='text-center'>{i18n.t('app:'+this.props.errorAlert)}</div>,
          onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
        }}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: 360 } },
          dragOptions: undefined,
          className: "alert-dialog",
          onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
        }}
      >
        <div className='w-100 text-center'>
          <PrimaryButton onClick={()=>{this._isMounted && this.setState({hidden:true})}} text={i18n.t('app:goToProjects')} />    
        </div>               
      </Dialog>
    )
  }

}
