export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  
export const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: '1px 0px 1px 5px',
    borderBottom: "1px solid lightgrey",
    background: 'white',
    boxShadow: isDragging ? '4px 4px 4px rgba(0,0,0,0.1)' : 'none',
    opacity: isDragging ? .5 : 1,
    ...draggableStyle,
});