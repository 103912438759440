import React from 'react';
import { history } from '../../_helpers';
import { userService } from '../../_services';

export interface PDFViewerProps {}

declare global {
  interface Window {
    require: any;
  }
}

export default class PDFViewer extends React.Component<{}, PDFViewerProps> {
	viewerRef: any;
	backend: any;
	props: any;


  constructor(props) {
    super(props);

    this.viewerRef = React.createRef();
    this.backend = new props.backend();

    this.state = {}
  }

  componentDidMount() {
    this.props.callbackFunction({
      selFile: null,
      annotationsToggle: false
    })
    localStorage.removeItem("pdfjs.history");
    window.addEventListener("message", this.onMessageReceived.bind(this), false);
    this.initPdf();
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src
      || this.props.docId !== prevProps.docId) {
      this.props.callbackFunction({
        selFile: null,
        annotationsToggle: false
      })

      var docId = this.props.docId;
      if(docId) this.initPdf()
    }
  }

  onMessageReceived(event) {
    var data:any = event.data;

    if (data && data.msg === "resetSelFile") {
      this.props.callbackFunction({
        selFile: null,
        annotationsToggle: false
      })
    }

    if (data && data.msg === "openPdf") {
      this.props.callbackFunction({
        selFile: null,
        annotationsToggle: false
      })

      var absolutePath = data.absolutePath
      var docId  = data.docId;
      
      if (absolutePath && docId) {
        var uri = "/home?absolutePath=file%3A%2F%2F" + absolutePath + "&docId=" + docId
        console.log("URI", uri)
        history.push(uri);
      }
    }
  }

  render() {
    return (
      <div ref={this.viewerRef} id='viewer' style={{ width: '100%', height: '100%' }}></div>
    )
  }

  private initPdf() {
    const element = this.viewerRef.current;
    const { src, docId, revisionId } = this.props;
    this.backend.init(src, element, this.props.selFile);
  }
}