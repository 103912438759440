import { IPersonaProps, TooltipHost } from '@fluentui/react';
import { FluentProvider, Persona } from '@fluentui/react-components';
import * as React from 'react';
import Moment from 'moment';
import i18n from '../../i18n';

export interface Props {
  item: any;
  userData: any;
}

export interface States {
  message: string;
  item: any;
  possibleContacts: any[];
  currentContacts: any[];
  currentContactsCopy: any[];
  replyTo: any[];
  replyToAll: any[];
  replyCcAll: any[];
  showCurrentContacts: boolean;
  loadingEntities: boolean;
  loading: boolean;
  page: number;
  totalPages: number;
  editingMessage: string;
  editMessage: string;
}

export class MessageViewer extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      message: '',
      item: this.props.item,
      possibleContacts: this.props.possibleContacts || [],
      currentContacts: [],
      currentContactsCopy: [],
      replyTo: [],
      replyToAll: [],
      replyCcAll: [],
      showCurrentContacts: false,
      loadingEntities: true,
      loading: true,
      page: this.props.item.page,
      totalPages: this.props.item.totalPages || 0,
      editingMessage: '',
      editMessage: ''
    };
  }

  public componentDidMount() {
    this._isMounted = true;

  }
  
  public componentDidUpdate(prevProps:any, prevState: any) {

  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private getContactName(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })

    return user ? (user.name || '') + ' ' + (user.surname || '') : ''
  }

  private getContactNameTooltip(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })

    return (
      user ? <TooltipHost
        content= {
          <div className='m-1'>
            <FluentProvider>
              <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
              <Persona className='mb-2' name={(user.name || '') + ' ' + (user.surname || '')} secondaryText={user.email} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
            </FluentProvider>
          </div>
        }
      >
        <span>{(user.name || '') + ' ' + (user.surname || '')}</span>
      </TooltipHost> : ''
    )
  }

  private getPersona(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })
    return (
      <TooltipHost
        content= {
          <div className='m-1'>
            <FluentProvider>
              <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
              <Persona className='mb-2' name={(user.name || '') + ' ' + (user.surname || '')} secondaryText={user.email} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
            </FluentProvider>
          </div>
        }
      >
        <Persona className='pr-2' size='extra-small' name={(user.name || '') + ' ' + (user.surname || '')} textAlignment='center' avatar={{ color: "colorful" }}/>
      </TooltipHost>
    )
  }

  public render() {

    const message = this.props.item.message;
    const highlights = this.props.item.highlights;

    return (
      <div className='message-wrapper d-flex px-2 w-100'>
        <Persona className="mr-2" style={{marginTop: '3px'}} name={this.getContactName(message.createdByUserId)} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
        <div className='message flex-grow-1' style={{maxWidth: 'calc(100% - 36px)'}}>
          <div className = "message-top d-flex w-100" style={{backgroundColor: this.props.userData.user.id === message.createdByUserId ? '#C7E0F4' : ''}}>
            <div className='author d-flex align-items-center'>
              {this.getContactNameTooltip(message.createdByUserId)}
              <span className='ml-1'>{' on ' + Moment(message.createdDateTime).format(i18n.t('Y-MMM-DD HH:mm'))}</span>
              {message.modifiedDateTime !== message.createdDateTime && <span className='ml-1'>(Edited)</span>}
            </div>
          </div>
          <div className='w-100' style={{overflowWrap: 'break-word', whiteSpace: 'normal'}}>
            {highlights.map((item, key) => {
              return (
                <div key={ key } className="p-2 w-100 coincidence" style={{overflowWrap: 'break-word'}} dangerouslySetInnerHTML={{__html: item}} >
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  private getUserFromEmail(email: string) {
    const {possibleContacts} = this.state;
    let user  = possibleContacts.find(user => {return user.email === email})
    return user ? <Persona key={email} name={(user.name || '') + ' ' + (user.surname || '')} size='extra-small' avatar={{ color: "colorful"}}/> 
    : <Persona className='mr-2' key={email} name={email} size='extra-small' avatar={{ color: "colorful"}}/> 
  }

}

function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
  if (!personas || !personas.length || personas.length === 0) {
      return false;
  }
  return personas.filter(item => item.text === persona.text).length > 0;
}
