import * as React from 'react';
import "moment/min/locales";
import { DetailsListLayoutMode, Selection, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { Sidebar } from '../_components/Sidebar';
import { Header } from '../_components/Header';
import { ListExplorer } from '../_components/ListExplorer';
import { DetailsPanel } from '../_components/DetailsPanel';
import { ActionButton, Dropdown, Icon, PrimaryButton, Text, TooltipHost } from '@fluentui/react';
import i18n from "i18next";
import { history } from '../_helpers';
import { userService } from '../_services';
import { AvatarGroup, AvatarGroupItem, AvatarGroupPopover, Input, Link, Persona, Tooltip } from '@fluentui/react-components';
import { MessageViewer } from '../_components/_cards/MessageViewer';
import Moment from 'moment';

declare global {
  interface Window {
      require: any;
      sreader: any;
  }
}

export interface Props {
  appPlatform: string,
  database: any,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  pusherMessage: any,
  page: string,
  userData: any,
  foldersList: any,
  repoUsers: any
}

export interface IDetailsListDashboardState {
  userData?: any;
  repoData?: any;
  goBack: number;
  isLoading: boolean;
  blankLoading: boolean;
  savingTag: boolean;
  savingMessage: boolean;
  syncStatus: any;
  downloadingFiles: any;
  creatingFoldersStructure: boolean;
  acceptedFiles: any;
  acceptedFolders: any;
  breadcrumbPath: any[];
  columns: IColumn[];
  fullItemsResponse: any;
  showFilters: boolean;
  filterByNameText: string;
  fullItemsResponseByName: any;
  filterByTypeText: string;
  fullItemsResponseByType: any;
  storedFiles: any;
  draggedItem: any;
  selItem: any;
  showRightPanel: boolean;
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  newTag: string;
  newMessage: string;
  savingFolder: boolean;
  showTagBulkDialog: boolean;
  hideDialog: boolean;
  activeTab: string;
  language: string;
  filterToggle: boolean;
  toolbarHeight: number;
  searchQuery: string;
  items: any[];
  customProperties: any[];

  searched: boolean;
  searchName: string;
  searchByName: string;
  searchType: string;
  previousSearchType: string;
  loadingMore: boolean;
  page: number;
  pipelines: any;
  repoUsers: any[]
}

export class SearchPage extends React.Component<Props, IDetailsListDashboardState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();
  private loadingMore: boolean = false;

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this._selection = new Selection({
      onSelectionChanged: () => {
        this._isMounted && this.setState({
          selItem: this._selection.getSelection()[this._selection.getSelection().length - 1] || []
        });
      }
    });

    this.state = {
      userData: null,
      repoData: null,
      goBack: 0,
      isLoading: false,
      blankLoading: false,
      savingTag: false,
      savingMessage: false,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      downloadingFiles: [],
      creatingFoldersStructure: false,
      acceptedFiles: [],
      acceptedFolders: [],
      breadcrumbPath: [{
        text: i18n.t('app:search'),
        key: 'search',
        onClick: ()=>{history.push('/search')}
      }],
      showFilters: false,
      fullItemsResponse: [],
      filterByNameText: "",
      fullItemsResponseByName: [],
      filterByTypeText: "",
      fullItemsResponseByType: [],
      storedFiles: [],
      draggedItem: null,
      columns: [],
      selItem: null,
      showRightPanel: localStorage.showRightPanel === 'true',
      selectionDetails: '',
      isModalSelection: false,
      isCompactMode: true,
      newTag: '',
      newMessage: '',
      savingFolder: false,
      showTagBulkDialog: false,
      hideDialog: true,
      activeTab: 'details',
      language: i18n.language,
      filterToggle: true,
      toolbarHeight: 89,
      searchQuery: '',
      items: [],
      customProperties: [],

      searched: false,
      searchName: '',
      searchByName: 'byName',
      searchType: 'PROJECT',
      previousSearchType: 'PROJECT',
      loadingMore: false,
      page: 0,
      pipelines: [],
      repoUsers: [],
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.props.userData && this._getRepoData();
    userService.getPipelines().then(response => {
      this.setState({pipelines: response.data.map(pipeline => {return {...pipeline, text: pipeline.name, key: pipeline.id}})    })
    }).catch((error)=>{
      console.log(error)
    })
  }

  public componentDidUpdate(prevProps: any) {

    if(this.state.language !== i18n.language) {
      this.setState({language: i18n.language});
    }

    if(this.state.searchQuery !== document.location.search || this.props.userData !== prevProps.userData) {
      this._isMounted && this.setState({
        searchQuery: document.location.search,
      })
      this.props.userData && this._getRepoData();
      this.getCurrentContent();
    }

  }

  public componentWillUnmount() {
    this._isMounted = false;
    if (this.props.pusher) {
      this.props.pusher.disconnect();
    }
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private async searchEntities(type: string, query: string) {
    return await new Promise((resolve) => {
      if (type === 'PROJECT') {
        userService.searchProjects(query).then((res)=>{resolve(res)}).catch((error)=>{resolve(error)})
      }
      else if (type === 'CONTRACT') {
        userService.searchContracts(query).then((res)=>{resolve(res)}).catch((error)=>{resolve(error)})
      }
      else if (type === 'CONTACT') {
        userService.searchContacts(query).then((res)=>{resolve(res)}).catch((error)=>{resolve(error)})
      }
      else if (type === 'COMPANY') {
        userService.searchCompanies(query).then((res)=>{resolve(res)}).catch((error)=>{resolve(error)})
      }
      else if (type === 'NOTE') {
        const params = new URLSearchParams(document.location.search);
        let options = {}
        const content = params.get('content')  || ''
        if (content.length > 0) options['content'] = content
        const subject = params.get('subject')  || ''
        if (subject.length > 0) options['subject'] = subject
        const from = params.get('from')  || ''
        if (from.length > 0) options['from'] = from
        const to = params.get('to')  || ''
        if (to.length > 0) options['to'] = to
        const fromNoteDate = params.get('fromNoteDate')  || ''
        if (fromNoteDate.length > 0) options['fromNoteDate'] = fromNoteDate
        const toNoteDate = params.get('toNoteDate')  || ''
        if (toNoteDate.length > 0) options['toNoteDate'] = toNoteDate
        const fromCreatedDateTime = params.get('fromCreatedDateTime')  || ''
        if (fromCreatedDateTime.length > 0) options['fromCreatedDateTime'] = fromCreatedDateTime
        const toCreatedDateTime = params.get('toCreatedDateTime')  || ''
        if (toCreatedDateTime.length > 0) options['toCreatedDateTime'] = toCreatedDateTime
        const fromModifiedDateTime = params.get('fromModifiedDateTime')  || ''
        if (fromModifiedDateTime.length > 0) options['fromModifiedDateTime'] = fromModifiedDateTime
        const toModifiedDateTime = params.get('toModifiedDateTime')  || ''
        if (toModifiedDateTime.length > 0) options['toModifiedDateTime'] = toModifiedDateTime
        const noteType = params.get('noteType')  || ''
        if (noteType.length > 0) options['noteType'] = noteType
        const project = params.get('projectIds')  || ''
        if (project.length > 0) options['projectIds'] = project.split(',')
        const contract = params.get('contractIds')  || ''
        if (contract.length > 0) options['contractIds'] = contract.split(',')
        const contact = params.get('contactIds')  || ''
        if (contact.length > 0) options['contactIds'] = contact.split(',')
        const company = params.get('companyIds')  || ''
        if (company.length > 0) options['companyIds'] = company.split(',')
        userService.searchNotes(query, options).then((res)=>{resolve(res)}).catch((error)=>{resolve(error)})
      }
      else if (type === 'EMAIL') {
        const params = new URLSearchParams(document.location.search);
        let options = {}
        const content = params.get('content')  || ''
        if (content.length > 0) options['content'] = content
        const subject = params.get('subject')  || ''
        if (subject.length > 0) options['subject'] = subject
        const from = params.get('from')  || ''
        if (from.length > 0) options['from'] = from
        const to = params.get('to')  || ''
        if (to.length > 0) options['to'] = to
        const fromSavedDateTime = params.get('fromSavedDateTime')  || ''
        if (fromSavedDateTime.length > 0) options['fromSavedDateTime'] = fromSavedDateTime
        const toSavedDateTime = params.get('toSavedDateTime')  || ''
        if (toSavedDateTime.length > 0) options['toSavedDateTime'] = toSavedDateTime
        const fromReceivedDateTime = params.get('fromReceivedDateTime')  || ''
        if (fromReceivedDateTime.length > 0) options['fromReceivedDateTime'] = fromReceivedDateTime
        const toReceivedDateTime = params.get('toReceivedDateTime')  || ''
        if (toReceivedDateTime.length > 0) options['toReceivedDateTime'] = toReceivedDateTime
        userService.searchEmails(query, options).then((res)=>{resolve(res)}).catch((error)=>{resolve(error)})
      }
      else if (type === 'CONVERSATION') {
        const params = new URLSearchParams(document.location.search);
        let options = {}
        const content = params.get('content')  || ''
        if (content.length > 0) options['searchMessageRequest'] = {'content': content}
        const subject = params.get('subject')  || ''
        if (subject.length > 0) options['subject'] = subject
        const fromCreatedDateTime = params.get('fromCreatedDateTime')  || ''
        if (fromCreatedDateTime.length > 0) options['fromCreatedDateTime'] = fromCreatedDateTime
        const toCreatedDateTime = params.get('toCreatedDateTime')  || ''
        if (toCreatedDateTime.length > 0) options['toCreatedDateTime'] = toCreatedDateTime
        const fromModifiedDateTime = params.get('fromModifiedDateTime')  || ''
        if (fromModifiedDateTime.length > 0) options['fromModifiedDateTime'] = fromModifiedDateTime
        const toModifiedDateTime = params.get('toModifiedDateTime')  || ''
        if (toModifiedDateTime.length > 0) options['toModifiedDateTime'] = toModifiedDateTime
        const conversationType = params.get('conversationType')  || ''
        if (conversationType.length > 0) options['conversationType'] = conversationType
        const project = params.get('projectIds')  || ''
        if (project.length > 0) options['projectIds'] = project.split(',')
        const contract = params.get('contractIds')  || ''
        if (contract.length > 0) options['contractIds'] = contract.split(',')
        const contact = params.get('contactIds')  || ''
        if (contact.length > 0) options['contactIds'] = contact.split(',')
        const company = params.get('companyIds')  || ''
        if (company.length > 0) options['companyIds'] = company.split(',')
        if (content.length > 0) {
          userService.getRepoUsers('ADMIN,INTERNAL').then((response)=> {
            this._isMounted && this.setState({repoUsers: response.data})
            userService.searchMessages(query, options).then((res)=>{resolve(res)}).catch((error)=>{resolve(error)})
          }).catch((error)=>{
            resolve(error)
          })
        } else {
          userService.searchConversations(query, options).then((res)=>{resolve(res)}).catch((error)=>{resolve(error)})
        }
      }
      else if (type === 'TASK') {
        const params = new URLSearchParams(document.location.search);
        let options = {}
        const content = params.get('content')  || ''
        if (content.length > 0) options['searchMessageRequest'] = {'content': content}
        const subject = params.get('subject')  || ''
        if (subject.length > 0) options['subject'] = subject
        const description = params.get('description')  || ''
        if (description.length > 0) options['description'] = description
        const fromCreatedDateTime = params.get('fromCreatedDateTime')  || ''
        if (fromCreatedDateTime.length > 0) options['fromCreatedDateTime'] = fromCreatedDateTime
        const toCreatedDateTime = params.get('toCreatedDateTime')  || ''
        if (toCreatedDateTime.length > 0) options['toCreatedDateTime'] = toCreatedDateTime
        const fromModifiedDateTime = params.get('fromModifiedDateTime')  || ''
        if (fromModifiedDateTime.length > 0) options['fromModifiedDateTime'] = fromModifiedDateTime
        const toModifiedDateTime = params.get('toModifiedDateTime')  || ''
        if (toModifiedDateTime.length > 0) options['toModifiedDateTime'] = toModifiedDateTime
        const fromDueDate = params.get('fromDueDate')  || ''
        if (fromDueDate.length > 0) options['fromDueDate'] = fromDueDate
        const toDueDate = params.get('toDueDate')  || ''
        if (toDueDate.length > 0) options['toDueDate'] = toDueDate
        const fromReminders = params.get('fromReminders')  || ''
        if (fromReminders.length > 0) options['fromReminders'] = fromReminders
        const toReminders = params.get('toReminders')  || ''
        if (toReminders.length > 0) options['toReminders'] = toReminders
        const taskImportance = params.get('taskImportance')  || ''
        if (taskImportance.length > 0) options['taskImportance'] = taskImportance
        const status = params.get('status')  || ''
        if (status.length > 0) options['status'] = status
        const project = params.get('projectIds')  || ''
        if (project.length > 0) options['projectIds'] = project.split(',')
        const contract = params.get('contractIds')  || ''
        if (contract.length > 0) options['contractIds'] = contract.split(',')
        const contact = params.get('contactIds')  || ''
        if (contact.length > 0) options['contactIds'] = contact.split(',')
        const company = params.get('companyIds')  || ''
        if (company.length > 0) options['companyIds'] = company.split(',')
        if (content.length > 0) {
          userService.getRepoUsers('ADMIN,INTERNAL').then((response)=> {
            this._isMounted && this.setState({repoUsers: response.data})
            userService.searchTaskMessages(query, options).then((res)=>{resolve(res)}).catch((error)=>{resolve(error)})
          }).catch((error)=>{
            resolve(error)
          })
        } else {
          userService.getRepoUsers('ADMIN,INTERNAL').then((response)=> {
            this._isMounted && this.setState({repoUsers: response.data})
            userService.searchTasks(query, options).then((res)=>{resolve(res)}).catch((error)=>{resolve(error)})
          }).catch((error)=>{
            resolve(error)
          })
        }
      }
    })
  }

  private getCurrentContent(hidden = false) {
    if (this.props.userData) {
      if (document.location.search && document.location.search.length > 0) {
        const params = new URLSearchParams(document.location.search);
        const searchType = params.get('entityType') || 'PROJECT';
        this._isMounted && this.setState({
          isLoading: true,
          searched: true,
          items: hidden ? this.state.items : [],
          previousSearchType:searchType,
          page: 0,
          syncStatus: {
            message: "Syncing",
            type: "info",
            icon: "spinner"
          }
        })
        const mainEntites = ['PROJECT', 'CONTRACT', 'CONTACT', 'COMPANY']
        this._isMounted && this._getColumns(searchType);
        this.searchEntities(searchType, document.location.search).then(async (response:any)=>{
          var newItems:any = _copyAndSort(response.data.content.filter((item)=>{return !item.trash}), 'name', false);
          let customProperties;
          mainEntites.includes(searchType) && userService.getCustomProperties('',searchType).then((res) =>{
            customProperties = res.data.map(item=>{return {...item, id: item.id, name: item.name, position: item.customPropertyTypes.find(x=>{return x.entityType === searchType}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === searchType}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}});
            customProperties = customProperties.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
            this._isMounted && this.setState({
              customProperties: customProperties
            })
          }).catch(()=>{
            this._isMounted && this.setState({
              isLoading: false,
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            });
          })
          this._isMounted && this.setState({
            items: newItems.map((item:any)=>{return {...item, key: item.id}}),
            searchType: searchType,
            previousSearchType: searchType,
            isLoading: false,
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          });
          response.data.last ? this._sortBySavedColumn([...newItems]) : this._sortBySavedColumn([...newItems, null]);
        }).catch(()=>{
          this._isMounted && this.setState({
            isLoading: false,
            syncStatus: {
              message: "Synced",
              type: "success",
              icon: "SkypeCircleCheck"
            }
          });
        })
      } else {
        this._isMounted && this.setState({
          isLoading: false,
          syncStatus: {
            message: "Synced",
            type: "success",
            icon: "SkypeCircleCheck"
          }
        });
      }

    }
  }

  private getMoreContent() {

    const { loadingMore } = this.state;

    if (!loadingMore) {
      this.loadingMore = true;
      setTimeout(()=>{
        this._isMounted && this.setState({
          loadingMore: true,
        })
        if (document.location.search && document.location.search.length > 0) {
          const searchType = this.state.previousSearchType;
          let page = this.state.page;
          page += 1;
          this.searchEntities(searchType, document.location.search + '&page=' + page).then(async (response:any)=>{
          let oldItems = this.state.items;
          oldItems = oldItems.filter((item)=> {return item});
          var newItems:any = oldItems.concat(response.data.content)
            this.loadingMore = false;
            this._isMounted && this.setState({
              items: newItems.map((item:any)=>{return {...item, key: item.id}}),
              searchType: searchType,
              previousSearchType: searchType,
              page: page,
              isLoading: false,
              loadingMore: false,
              syncStatus: {
                message: "Synced",
                type: "success",
                icon: "SkypeCircleCheck"
              }
            });
            response.data.last ? this._sortBySavedColumn([...newItems]) : this._sortBySavedColumn([...newItems, null]);
          }).catch(()=>{
            this.loadingMore = false;
            this._isMounted && this.setState({
              loadingMore: false,
            });
          })
        } else {
          this.loadingMore = false;
          this._isMounted && this.setState({
            loadingMore: false,
          });
        }
      },50)
    }
  }

  private search(e?) {
    if (e) e.preventDefault();

    const { searchName, searchType } = this.state;
    const params = new URLSearchParams('');

    const projects = this.sidebarRef ? this.sidebarRef.state.currentProjects.map(item=>{return item.id}) : []
    const contracts = this.sidebarRef ? this.sidebarRef.state.currentContracts.map(item=>{return item.id}) : []
    const contacts = this.sidebarRef ? this.sidebarRef.state.currentContacts.map(item=>{return item.id}) : []
    const companies = this.sidebarRef ? this.sidebarRef.state.currentCompanies.map(item=>{return item.id}) : []
    const searchFields = this.sidebarRef ? this.sidebarRef.state.currentFilters: []
    
    if (searchType && searchType.length > 0) {
      params.set('entityType',searchType)
    }

    for (let i in searchFields) {
      if (searchFields[i].text && (searchFields[i].text.length > 0 || searchFields[i].text > 0)) {
        params.set(searchFields[i].id,searchFields[i].text)
      }
    }

    if (projects && projects.length > 0 && projects[0].length > 0) {
      params.set('projectIds',projects.join(','))
    }

    if (contracts && contracts.length > 0 && contracts[0].length > 0) {
      params.set('contractIds',contracts.join(','))
    }

    if (contacts && contacts.length > 0 && contacts[0].length > 0) {
      params.set('contactIds',contacts.join(','))
    }

    if (companies && companies.length > 0 && companies[0].length > 0) {
      params.set('companyIds',companies.join(','))
    }

    history.push('/search?'+params.toString())
  }

  private clearSearch(e?) {
    if (e) e.preventDefault();
    history.push('/search');
    setTimeout(()=>{
      this._isMounted && this.setState({searched: false, searchType: 'PROJECT'})
      this.sidebarRef && this.sidebarRef._isMounted && this.sidebarRef.setState({currentProjects: [], currentContracts: [], currentContacts: [], currentCompanies: []})
    },1)
  }

  public render() {

    const { columns, items, customProperties, isCompactMode, searchQuery, showRightPanel } = this.state;
    const search = new URLSearchParams(document.location.search)

    return (
      <div>
            <Header
              ref={instance => { this.headerRef = instance; }}
              userData={ this.props.userData }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              page={this.props.page}
              sidebarRef={ this.sidebarRef }
              breadcrumbPath= {this.state.breadcrumbPath}
              syncStatus = {this.state.syncStatus}
              items={items}
              callbackFunction={this.callbackFunction}
              showRightPanel={showRightPanel}
              selection={ this._selection.getSelection() }
              selItem={ this.state.selItem }
              getCurrentContent={this.getCurrentContent.bind(this)}
              customProperties={customProperties}
            />
          <div className="content-wrap d-flex flex-row">
            <Sidebar
              ref={(instance:any) => { this.sidebarRef = instance; }}
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              detailsPanelRef={ this.detailsPanelRef }
              userData={ this.props.userData }
              page={this.props.page}
              items={items}
              searchQuery={searchQuery}
              callbackFunction={this.callbackFunction}
              searchType={this.state.searchType}
              pipelines={this.state.pipelines}
            /> 

            <div className="list mr-auto flex-grow-1 d-flex flex-column" >
              <div className="searchBox searchBox-top pb-0">
                <div className={'d-flex flex-grow-1 align-items-center'} style={{height: '44px', borderBottom: '1px solid #f2f2f2'}}>
                  <span className='ml-3'>
                    {i18n.t('app:mainView')}
                  </span>
                </div>
                <div className='filter-name p-3' style={{borderBottom: '1px solid #f2f2f2'}}>
                  <form id='form' className='m-0 p-0' onSubmit={this.search.bind(this)}>
                    <input className='d-none' type='submit'></input>
                    <div className='d-none w-100'>
                      <Dropdown
                        selectedKey={this.state.searchByName}
                        options={[
                          { key: 'byName', text:i18n.t('app:name')},
                          { key: 'byID', text: i18n.t('app:id')}
                        ]}
                        onChange={ (e,option:any)=> {this._isMounted && this.setState({searchByName: option?.key})} }
                        style={{width: '150px'}}
                      />
                      <Input className='ml-3 flex-grow-1' appearance='underline' name={i18n.t('app:name')} value={ this.state.searchName } onChange={ (e,data)=>{this._isMounted && this.setState({searchName: data.value})} } autoFocus />
                    </div>
                    <div className='d-flex align-items-center'>
                      {this.state.searched && <span style={{fontWeight: 600}}>{i18n.t('app:searchingType') + ' - ' + i18n.t('app:'+this.state.previousSearchType.toLowerCase())}</span>}
                      <ActionButton form='form' type='submit' className='ml-auto clear-search' iconProps={{iconName: 'Cancel'}} style={{fontWeight:500, padding:'0 4px'}} text={i18n.t('app:resetSearch')} disabled={this.state.isLoading || document.location.search === ''} onClick={this.clearSearch.bind(this)}/>
                      <PrimaryButton form='form' type='submit' className='ml-3' text={i18n.t('app:search')} disabled={this.state.isLoading} onClick={this.search.bind(this)}/>
                    </div>
                  </form>
                </div>
              </div>
              {this.state.searched && <ListExplorer
                ref={instance => { this.listExplorerRef = instance; }}
                appPlatform={ this.props.appPlatform }
                isOnline={ this.props.isOnline }
                maintenance={ this.props.maintenance }
                socket={this.props.socket}
                pusher={this.props.pusher}
                userData={this.props.userData}
                foldersList={ this.props.foldersList }
                items={items}
                selItem={ this.state.selItem }
                isCompactMode={isCompactMode}
                columns={columns}
                selectionMode={(this.state.previousSearchType === 'CONVERSATION' || this.state.previousSearchType === 'TASK') && search.get('content') ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={(this.state.previousSearchType === 'CONVERSATION' || this.state.previousSearchType === 'TASK') && search.get('content') ? DetailsListLayoutMode.justified : DetailsListLayoutMode.fixedColumns}
                constrainMode={ConstrainMode.unconstrained}
                headerRef={ this.headerRef }
                detailsPanelRef={ this.detailsPanelRef }
                callbackFunction={ this.callbackFunction }
                page={this.props.page}
                sidebarRef={ this.sidebarRef }
                loading={this.state.isLoading}
                searchMore={this.getMoreContent.bind(this)}
                loadingMore={this.state.loadingMore}
              />}
            </div>
            {!((this.state.previousSearchType === 'CONVERSATION' || this.state.previousSearchType === 'TASK') && search.get('content')) &&
            <DetailsPanel
              ref={instance => { this.detailsPanelRef = instance; }}
              appPlatform={ this.props.appPlatform }
              userData={ this.props.userData }
              repoData={ this.state.repoData }
              foldersList={ this.props.foldersList }
              repoUsers={this.props.repoUsers}
              items={ items }
              showRightPanel={ this.state.showRightPanel }
              selection={ this._selection }
              selItem={ this.state.selItem }
              headerRef={ this.headerRef }
              listExplorerRef={ this.listExplorerRef }
              page={ this.props.page }
              detailsPanelSection="explorer"
              callbackFunction={ this.callbackFunction }
              customProperties={customProperties}
              getImportance={this.getImportance.bind(this)}
            />}
          </div>  
      </div>
    );
  }

  private getImportance(item:any) {

    const tags = {
      'URGENT': '#d69ca5',
      'LATER': '#f4bfab',
      'FOLLOW_UP': '#f9e2ae',
      'MAYBE': '#bdd99b',
    }

    return (
      <div className='d-flex align-items-center'>
        <Icon 
          iconName="CircleShapeSolid" 
          className="tag mr-1" 
          style={{
            fontSize: 12,
            textAlign:'center',
            color: tags[item.taskImportance] || 'black'
          }} 
        />
        <span style={{paddingBottom: '2px', fontSize: '12px', color: 'rgb(66, 66, 66)'}}>{i18n.t('app:' + item.taskImportance)}</span>
      </div> 
    )
  }

  private _getColumns(searchType: string) {
    const params = new URLSearchParams(document.location.search);
    let content = params.get('content') || ''
    var columns = searchType === 'PROJECT' ? [
      {
        key: 'image',
        name: '#',
        fieldName: 'image',
        minWidth: 0,
        maxWidth: 1,
        isRowHeader: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '2px'}}/>
            :
              <div style={{borderRadius: '24px', height: '28px'}}><Persona className='project-persona' name={item ? item.name : ''} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful", shape: "square" }}/></div>
          )
        }
      },
      {
        key: 'name',
        name: i18n.t('app:name'),
        fieldName: 'name',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            <span title={ item.name } style={{verticalAlign:'middle'}}>{ item.name }</span>
          )
        }
      },
      {
        key: 'stage',
        name: i18n.t('app:Stage'),
        fieldName: 'stage',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: this.getStage.bind(this)
      },
      {
        key: 'progress',
        name: i18n.t('app:progress'),
        fieldName: 'progress',
        minWidth: 300,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        onRender: this.getStagesArrows.bind(this),
        isPadded: true
      },
      {
        key: 'status',
        name: i18n.t('app:status'),
        fieldName: 'status',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            <span title={ i18n.t('app:'+item.status) } style={{verticalAlign:'middle'}}>{ i18n.t('app:'+item.status) }</span>
          )
        }
      },
      {
        key: 'nickname',
        name: i18n.t('app:nickName'),
        fieldName: 'nickname',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            <span title={ item.nickname } style={{verticalAlign:'middle'}}>{ item.nickname }</span>
          )
        }
      }
    ] : searchType === 'CONTRACT' ? [
      {
        key: 'image',
        name: '#',
        fieldName: 'image',
        minWidth: 0,
        maxWidth: 1,
        isRowHeader: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '2px'}}/>
            :
              <div style={{borderRadius: '24px', height: '28px'}}><Persona className='project-persona' name={item ? item.name : ''} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful", shape: "square" }}/></div>
            )
        }
      },
      {
        key: 'name',
        name: i18n.t('app:name'),
        fieldName: 'name',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            <span title={ item.name } style={{verticalAlign:'middle'}}>{ item.name }</span>
          )
        }
      },
      {
        key: 'status',
        name: i18n.t('app:status'),
        fieldName: 'expirationDate',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            this.getStatusArrows(item)
          )
        },
      },
      {
        key: 'stage',
        name: i18n.t('app:Stage'),
        fieldName: 'stage',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: this.getStage.bind(this)
      },
      {
        key: 'progress',
        name: i18n.t('app:progress'),
        fieldName: 'progress',
        minWidth: 300,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        onRender: this.getStagesArrows.bind(this),
        isPadded: true
      },
      {
        key: 'signatureDate',
        name: i18n.t('app:signatureDate'),
        fieldName: 'signatureDate',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          let date = new Date(item.signatureDate)
          return (
            <span title={ Moment(item.signatureDate).format(i18n.t('Y-MMM-DD')) } style={{verticalAlign:'middle'}}>{ Moment(item.signatureDate).format(i18n.t('Y-MMM-DD')) }</span>
          )
        }
      },
      {
        key: 'startDate',
        name: i18n.t('app:startDate'),
        fieldName: 'startDate',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          let date = new Date(item.startDate)
          return (
            <span title={ Moment(item.startDate).format(i18n.t('Y-MMM-DD')) } style={{verticalAlign:'middle'}}>{ Moment(item.startDate).format(i18n.t('Y-MMM-DD')) }</span>
          )
        }
      },
      {
        key: 'expirationDate',
        name: i18n.t('app:expirationDate'),
        fieldName: 'expirationDate',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          let date = new Date(item.expirationDate)
          return (
            <span title={ Moment(item.expirationDate).format(i18n.t('Y-MMM-DD')) } style={{verticalAlign:'middle'}}>{ Moment(item.expirationDate).format(i18n.t('Y-MMM-DD')) }</span>
          )
        }
      }
    ] : searchType === 'CONTACT' ? [
      {
        key: 'icon',
        name: '#',
        fieldName: 'icon',
        minWidth: 1,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        //onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '28px'}}/>
            :
              <div style={{borderRadius: '24px', height: '28px'}}><Persona className='contact-persona' name={item ? item.firstName + " " + item.lastName : ''} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful" }}/></div>
          )
        }
      },
      {
        key: 'firstName',
        name: i18n.t('app:firstName'),
        fieldName: 'firstName',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        //isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <span title={ item.firstName }>{ item.firstName }</span>
          )
        }
      },
      {
        key: 'lastName',
        name: i18n.t('app:lastName'),
        fieldName: 'lastName',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        //isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <span title={ item.lastName }>{ item.lastName }</span>
          )
        }
      },
      {
        key: 'nickname',
        name: 'Nickname',
        fieldName: 'nickname',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <Text title={ item.nickname }>{ item.nickname }</Text>
          )
        }
      },
      {
        key: 'emailAddresses',
        name: i18n.t('app:emailAddress'),
        fieldName: 'emailAddresses',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        //isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          if (item.emailAddresses && item.emailAddresses.length > 0) {
            let email = item.emailAddresses.find(item=>{return item.position === 0}) || item.emailAddresses[0]
            return (
              <Link style={{fontWeight: 400}} href={'mailto:'+email.email} title={ email.email } target='_blank' rel='noreferrer'>{ email.email }</Link> 
            )
          } else {
            return <></>
          }
        }
      },
      {
        key: 'phoneNumbers',
        name: i18n.t('app:phoneNumber'),
        fieldName: 'phoneNumbers',
        minWidth: 120,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        //isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          if (item.phoneNumbers && item.phoneNumbers.length > 0) {
            let phone = item.phoneNumbers.find(item=>{return item.position === 0}) || item.phoneNumbers[0]
            return (
              <Link style={{fontWeight: 400}}  href={'tel:'+phone.number} title={ phone.number } target='_blank' rel='noreferrer'>{ phone.number }</Link> 
            )
          } else {
            return <></>
          }
        }
      },
      {
        key: 'linkedin',
        name: i18n.t('app:linkedIn'),
        fieldName: 'linkedin',
        minWidth: 320,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        //isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <Link style={{fontWeight: 400}} href={item.linkedin} title={ item.linkedin } target='_blank' rel='noreferrer'>{ item.linkedin }</Link> 
          )
        }
      },
      {
        key: 'contactType',
        name: i18n.t('app:type'),
        fieldName: 'contactType',
        minWidth: 100,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        //isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <span title={ item.contactType } className={'tag-badge ' + (item.contactType === 'INTERNAL' ? 'internal' : 'external')}>{ i18n.t('app:'+(item.contactType === 'INTERNAL' ? 'INTERNAL' : 'EXTERNAL')) }</span>
          )
        }
      }
    ] : searchType === 'COMPANY' ? [
      {
        key: 'icon',
        name: '#',
        fieldName: 'icon',
        minWidth: 1,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '0px'}}/>
            :
              <div style={{borderRadius: '0px', height: '28px'}}><Persona className='company-persona' name={item.name} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful", shape: "square" }}/></div>
            )
        }
      },
      {
        key: 'Company name',
        name: i18n.t('app:name'),
        fieldName: 'name',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <span title={ item.name }>{ item.name }</span>
          )
        }
      },
      {
        key: 'domain',
        name: i18n.t('app:domain'),
        fieldName: 'domain',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            item.domain && <Link style={{fontWeight: 400}} target={'_blank'} rel="noreferrer" href={'https://'+item.domain.replace('https://','')} title={ item.domain }>{ item.domain }</Link> 
          )
        }
      },
      {
        key: 'nickname',
        name: i18n.t('app:nickName'),
        fieldName: 'nickname',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <Text title={ item.nickname }>{ item.nickname }</Text>
          )
        }
      },
      {
        key: 'linkedin',
        name: i18n.t('app:linkedIn'),
        fieldName: 'linkedin',
        minWidth: 320,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        //isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <Link style={{fontWeight: 400}} href={item.linkedin} title={ item.linkedin } target='_blank' rel='noreferrer'>{ item.linkedin }</Link> 
          )
        }
      }
    ] : searchType === 'EMAIL' ? [
      {
        key: 'icon',
        name: '#',
        fieldName: 'icon',
        minWidth: 1,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '0px'}}/>
            :
              <div style={{borderRadius: '0px', height: '28px'}}><Persona name={item.subject} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful", shape: "square" }}/></div>
            )
        }
      },
      {
        key: 'subject',
        name: i18n.t('app:subject'),
        fieldName: 'subject',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <span title={ item.subject }>{ item.subject }</span>
          )
        },
      },
      {
        key: 'savedDateTime',
        name: i18n.t('app:savedDate'),
        fieldName: 'savedDateTime',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return <span title={ Moment(item.savedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }>{ Moment(item.savedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }</span>
        }
      }
    ] : searchType === 'NOTE' ? [
      {
        key: 'icon',
        name: '#',
        fieldName: 'icon',
        minWidth: 1,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '0px'}}/>
            :
              <div style={{borderRadius: '0px', height: '28px'}}><Persona name={item.subject} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful", shape: "square" }}/></div>
            )
        }
      },
      {
        key: 'subject',
        name: i18n.t('app:subject'),
        fieldName: 'subject',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <span title={ item.subject }>{ item.subject }</span>
          )
        },
      },
      {
        key: 'createdAt',
        name: i18n.t('app:createdAt'),
        fieldName: 'createdDateTime',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return <span title={ Moment(item.createdDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }>{ Moment(item.createdDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }</span>
        }
      }
    ] : searchType === 'CONVERSATION' && content.length > 0 ? [
      {
        key: 'message',
        name: i18n.t('app:message'),
        fieldName: 'message',
        minWidth: 1,
        isRowHeader: true,
        data: 'string',
        onRender: (item: any) => {
          return <MessageViewer userData={this.props.userData} item={item} possibleContacts={this.state.repoUsers}/>
        },
      },
    ] : searchType === 'CONVERSATION' ? [
      {
        key: 'icon',
        name: '#',
        fieldName: 'icon',
        minWidth: 1,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '0px'}}/>
            :
              <div style={{borderRadius: '0px', height: '28px'}}><Persona name={item.subject} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful", shape: "square" }}/></div>
            )
        }
      },
      {
        key: 'subject',
        name: i18n.t('app:subject'),
        fieldName: 'subject',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <span title={ item.subject }>{ item.subject }</span>
          )
        },
      },
      {
        key: 'createdAt',
        name: i18n.t('app:createdAt'),
        fieldName: 'createdDateTime',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return <span title={ Moment(item.createdDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }>{ Moment(item.createdDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }</span>
        }
      }
    ] : searchType === 'TASK' && content.length > 0 ? [
      {
        key: 'message',
        name: i18n.t('app:message'),
        fieldName: 'message',
        minWidth: 1,
        isRowHeader: true,
        data: 'string',
        onRender: (item: any) => {
          return <MessageViewer userData={this.props.userData} item={item} possibleContacts={this.state.repoUsers}/>
        },
      },
    ] : searchType === 'TASK' ? [
      {
        key: 'icon',
        name: '#',
        fieldName: 'icon',
        minWidth: 1,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '0px'}}/>
            :
              <div style={{borderRadius: '0px', height: '28px'}}><Persona name={item.subject} primaryText='' size='small' textAlignment='center' avatar={{ color: "colorful", shape: "square" }}/></div>
            )
        }
      },
      {
        key: 'subject',
        name: i18n.t('app:subject'),
        fieldName: 'subject',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <span title={ item.subject }>{ item.subject }</span>
          )
        },
      },
      {
        key: 'taskImportance',
        name: i18n.t('app:importance'),
        fieldName: 'taskImportance',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: this.getImportance.bind(this)
      },
      {
        key: 'assignedUsers',
        name: i18n.t('app:assignedTo'),
        fieldName: 'assignedUsers',
        minWidth: 64,
        maxWidth: 128,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        onRender: (item: any) => {
          let assignedInline:any[] = [];
          let assignedOverflow:any[] = [];
      
          if (item && item.assignedUsers && this.state.repoUsers) {
            let assigned: any[] = this.state.repoUsers.filter((user) => {
              return item.assignedUsers && item.assignedUsers.includes(user.id) 
            })
            if (assigned.length > 4) {
              assignedInline = assigned.slice(0,4).sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
              assignedOverflow = assigned.slice(4).sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
            } else {
              assignedInline = assigned.sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
            }
          }

          return (
            <div className='d-flex align-items-center'>
              <AvatarGroup layout="stack" size={20}>
                {assignedInline.map((item) => (
                  <Tooltip key={item.id} content={<Persona secondaryText={item.email} className='mt-1' textAlignment='center' size='small' name={(item.name || '') + ' ' + (item.surname || '')} avatar={{color: 'colorful'}} />} relationship="label">
                    <AvatarGroupItem name={(item.name || '') + ' ' + (item.surname || '')} />
                  </Tooltip>
                ))}
                { assignedOverflow && assignedOverflow.length > 0 && <AvatarGroupPopover>
                  {assignedOverflow.map((item) => (
                    <AvatarGroupItem name={(item.name || '') + ' ' + (item.surname || '')} key={item.id} />
                  ))}
                </AvatarGroupPopover> }
              </AvatarGroup>
            </div>  
            )
        },
        isPadded: true
      },
      {
        key: 'dueDate',
        name: i18n.t('app:dueDate'),
        fieldName: 'dueDate',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return <span title={ Moment(item.dueDate).format(i18n.t('Y-MMM-DD HH:mm')) }>{ Moment(item.dueDate).format(i18n.t('Y-MMM-DD HH:mm')) }</span>
        }
      },
      {
        key: 'status',
        name: i18n.t('app:status'),
        fieldName: 'status',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            <span title={ i18n.t('app:'+item.status) } style={{verticalAlign:'middle'}}>{ i18n.t('app:'+item.status) }</span>
          )
        }
      },
    ]    
    : []
    this._isMounted && this.setState({
      columns: columns
    })
  }

  onDragOver = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragEnter = (e) => {
    e.dataTransfer.dropEffect = 'copy'
    if(this.listExplorerRef
      && !this.listExplorerRef.state.draggedItem) {
        
      setTimeout(() => {
        var el: any = document.getElementsByClassName("ms-ScrollablePane")
        for(var i = 0; i < el.length; i++) {
          if (!el[i].classList.contains("dragOverList")) {
            el[i].classList.add('dragOverList');
          }
        }
      })
    }

    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave = (e) => {
    if (e.target.className.indexOf("ms-ScrollablePane") !== -1) {
      var el: any = document.getElementsByClassName("ms-ScrollablePane")
      for(var i = 0; i < el.length; i++) {
        el[i].classList.remove('dragOverList');
      }
    }

    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  onFileDrop = (e) => {
    var el: any = document.getElementsByClassName("ms-ScrollablePane")
    for(var i = 0; i < el.length; i++) {
      el[i].classList.remove('dragOverList');
    }
    
    let event:any = e as Event;
    event.stopPropagation();
    event.preventDefault();

  }

  private getStage(item:any) {
    const { pipelines } = this.state;
    let pipeline:any = null;
    for (let i in pipelines) {
      for (let j in pipelines[i].stages) {
        if (item.stage && pipelines[i].stages[j].id === item.stage.id) pipeline = pipelines[i]
      }
    }
    if (pipeline) {
      pipeline.stages = pipeline.stages.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
      let nStages = pipeline.stages.length;
      let index = 0;
      return (
        pipeline.stages.map((stage)=>{
          index++;
          if (stage.id === item.stage.id) {
            return (
              <div key={item.stage.id} className='d-flex align-items-center'>
                <Icon 
                  iconName="CircleShapeSolid" 
                  className="tag mr-2" 
                  style={{
                    fontSize:12,
                    textAlign:'center',
                    color: 'rgb(16, 110, 190)',
                    opacity: index/nStages
                  }} 
                />
                <span style={{paddingBottom: '2px', fontWeight: 400}}>{item.stage.name}</span>
              </div> 
            )
          } return null;
        })
      )
    } else {
      return null
    }
  }

  private getStagesArrows(item:any) {
    const { pipelines } = this.state;
    let pipeline:any = null;
    for (let i in pipelines) {
      for (let j in pipelines[i].stages) {
        if (item.stage && pipelines[i].stages[j].id === item.stage.id) pipeline = pipelines[i]
      }
    }
    if (pipeline) {
      let selected = false;
      pipeline.stages = pipeline.stages.sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
      let nStages = pipeline.stages.length;
      let index = 0;
      let colors = ['rgba(192,0,0,.8)', 'rgba(200,50,0,.8)', 'rgba(255,155,0,.8)', 'rgba(255,255,0,.8)', 'rgba(200,255,55,.8)', 'rgba(100,255,50,.8)', 'rgba(0,200,0,.8)']
      return (
        <div className='d-flex align-items-center align-self-center pt-1'>
          {
            pipeline.stages.map((stage)=>{
              index++;
              if (!selected && stage.id !== item.stage.id) {
                return (
                  <TooltipHost key={stage.id}  content={pipeline.name + " - " + stage.name} setAriaDescribedBy={false}>
                    <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow.svg'} style={{opacity: index/nStages > .9 ? .9 : index/nStages , width: '32px', height: '20px'}}/>
                  </TooltipHost>
                )
              }
              else if (!selected && stage.id === item.stage.id) {
                selected = true
                return (
                  <TooltipHost key={stage.name}  content={pipeline.name + " - " + stage.name} setAriaDescribedBy={false}>
                    <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow.svg'} style={{opacity: index/nStages > .9 ? .9 : index/nStages , width: '32px', height: '20px'}}/>
                  </TooltipHost>
                )
              }
              else if (selected && stage.id !== item.stage.id) {
                return (
                  <TooltipHost key={stage.name}  content={pipeline.name + " - " + stage.name} setAriaDescribedBy={false}>
                    <img alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
                  </TooltipHost>
                )
              } return null;
            })
          }
        </div>  
      )
    } else {
      return null;
    }
  }

  private getStatusArrows(item:any) {
    const currentDate = new Date().getTime();
    let expirationDate = new Date(item.expirationDate).getTime() - currentDate
    if (expirationDate <= 0) { //Expired
      return <div className='d-flex align-items-center'>
        <TooltipHost content={i18n.t('app:expiresInMore')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn90')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn60')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn30')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-red.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expired')}>
          <Icon className='px-2' style={{color: 'firebrick'}} iconName='Info'/>
        </TooltipHost>
      </div>
    }
    else if (expirationDate <= 2592000000 && expirationDate > 0) { //30 days
      return <div className='d-flex align-items-center'>
        <TooltipHost content={i18n.t('app:expiresInMore')}>
         <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn90')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn60')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn30')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-red.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
      </div>
    }
    else if (expirationDate <= 5184000000 && expirationDate > 0) { //90 days
      return <div className='d-flex align-items-center'>
        <TooltipHost content={i18n.t('app:expiresInMore')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{ width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn90')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn60')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-orange.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn30')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
      </div>
    }
    else if (expirationDate <= 7776000000 && expirationDate > 0) { //90 days
      return <div className='d-flex align-items-center'>
        <TooltipHost content={i18n.t('app:expiresInMore')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn90')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-yellow.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn60')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn30')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
      </div>
    }
    else { //>90 days
      return <div className='d-flex align-items-center'>
        <TooltipHost content={i18n.t('app:expiresInMore')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-green.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn90')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn60')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
        <TooltipHost content={i18n.t('app:expiresIn30')}>
          <img className='selected' alt='arrow' src={process.env.PUBLIC_URL + '/img/arrow-grey.svg'} style={{width: '32px', height: '20px'}}/>
        </TooltipHost>
      </div>
    }
  }

  private _getRepoData() {
    this._isMounted && this.setState({
      repoData: this.props.userData.repository
    })
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    localStorage.setItem("columnsSearch", JSON.stringify(newColumns))
    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _sortBySavedColumn = (items: any): void => {
    this._isMounted && this.setState({items: items.map((item:any)=>{return item ? {...item, key: item.id} : null})})
  };

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  return items.slice(0).sort((a,b) => {
    if (!a) return -1;
    if (!b) return 1;
    if (!isSortedDescending) {
      return (a[columnKey] || '').localeCompare(b[columnKey] || '');
    } else {
      return (b[columnKey] || '').localeCompare(a[columnKey] || '');
    }
  });
}