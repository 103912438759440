import { ActionButton, ConstrainMode, DefaultButton, DetailsListLayoutMode, Dialog, DialogFooter, DialogType, Icon, IconButton, IDetailsHeaderProps, IRenderFunction, Pivot, PivotItem, PrimaryButton, ScrollablePane, SelectionMode, ShimmeredDetailsList, Spinner, SpinnerSize, TooltipHost, } from '@fluentui/react';
import { FluentProvider, Input, Label, Link, Persona, Switch, webLightTheme } from '@fluentui/react-components';
import * as React from 'react';
import i18n from '../../i18n';
import { userService } from '../../_services';

export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  loadingEntities: boolean;
  contacts: any[];
  filteredContacts: any[];
  groups: any[];
  filteredGroups: any[];
  relationsContacts: boolean;
  currentEditors: any[];
  showCurrentEditors: boolean;
  currentRestrictedEditors: any[];
  showCurrentRestrictedEditors: boolean;
  currentViewers: any[];
  showCurrentViewers: boolean;
  entityType: string;
  filter:string;
  sortedAZ: boolean;
  changed: boolean;
  showUsers: boolean;
  showGroups: boolean;
  defaultEditors: any[];
  defaultRestrictedEditors: any[];
  defaultViewers: any[];
  showDefaultEditors: boolean;
  showDefaultRestrictedEditors: boolean;
  showDefaultViewers: boolean;
  inheritPermissions: boolean;
}

export class FormPermissions extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      hidden: false,
      loading: false,
      loadingEntities: false,
      contacts: [],
      filteredContacts: [],
      groups: [], 
      filteredGroups: [],
      relationsContacts: true,
      currentEditors: [],
      showCurrentEditors: false,
      currentRestrictedEditors: [],
      showCurrentRestrictedEditors: false,
      currentViewers: [],
      showCurrentViewers: false,
      entityType: '',
      filter: '',
      sortedAZ: true,
      changed: false,
      showUsers: false,
      showGroups: false,
      defaultEditors: [],
      defaultRestrictedEditors: [],
      defaultViewers: [],
      showDefaultEditors: false,
      showDefaultRestrictedEditors: false,
      showDefaultViewers: false,
      inheritPermissions: true,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    let defaultPermissions:any[] = [];
    let permissions:any[] = [];
    let repoUsers:any[] = [];
    let repoGroups:any[] = [];
    let inheritPermissions = false;
    const getDefaultPermissions = userService.getPermissions().then((response) => {
      defaultPermissions = response.data.userPermissions.concat(response.data.groupPermissions)
    }).catch((error) => {
      this._isMounted && this.setState({loadingEntities: false})
    })
    const getPermissions = userService.getPermissions(this.props.page === 'permissions' ? null : this.props.item ? this.props.item.id : null).then((response) => {
      permissions = (this.props.page === 'permissions' || response.data.id === this.props.item.id) ? response.data.userPermissions.concat(response.data.groupPermissions) : [];
      inheritPermissions = this.props.page === 'permissions' ? false : response.data.id === this.props.item.id ? response.data.inheritPermissions : true;
    }).catch(()=>{
      this._isMounted && this.setState({loadingEntities: false})
    })

    const getRepoUsers = userService.getRepoUsers('INTERNAL').then((response)=>{
      repoUsers = response.data;
    }).catch(()=>{
      this._isMounted && this.setState({loadingEntities: false})
    })
    const getRepoGroups =  userService.getRepoGroups().then((response)=>{
      repoGroups = response.data;
    }).catch(()=>{
      this._isMounted && this.setState({loadingEntities: false})
    })

    Promise.all([getDefaultPermissions, getPermissions, getRepoUsers, getRepoGroups]).then((res) => {
      const editorsDefault = defaultPermissions.filter((item) => {return item.permissionRole === 'EDITOR'}).map((item) => {return item.userId || item.groupId})
      const restrictedEditorsDefault = defaultPermissions.filter((item) => {return item.permissionRole === 'RESTRICTED_EDITOR'}).map((item) => {return item.userId  || item.groupId})
      const viewersDefault = defaultPermissions.filter((item) => {return item.permissionRole === 'VIEWER'}).map((item) => {return item.userId  || item.groupId})
      const editorsUsersDefault = repoUsers.filter((user) => {return editorsDefault.includes(user.id)})
      const restrictedEditorsUsersDefault = repoUsers.filter((user) => {return restrictedEditorsDefault.includes(user.id)})
      const viewersUsersDefault = repoUsers.filter((user) => {return viewersDefault.includes(user.id)})
      const editorsGroupsDefault = repoGroups.filter((user) => {return editorsDefault.includes(user.id)})
      const restrictedEditorsGroupsDefault = repoGroups.filter((user) => {return restrictedEditorsDefault.includes(user.id)})
      const viewersGroupsDefault = repoGroups.filter((user) => {return viewersDefault.includes(user.id)})

      const editors = permissions.filter((item) => {return item.permissionRole === 'EDITOR'}).map((item) => {return item.userId || item.groupId})
      const restrictedEditors = permissions.filter((item) => {return item.permissionRole === 'RESTRICTED_EDITOR'}).map((item) => {return item.userId  || item.groupId})
      const viewers = permissions.filter((item) => {return item.permissionRole === 'VIEWER'}).map((item) => {return item.userId  || item.groupId})
      const editorsUsers = repoUsers.filter((user) => {return editors.includes(user.id)})
      const restrictedEditorsUsers = repoUsers.filter((user) => {return restrictedEditors.includes(user.id)})
      const viewersUsers = repoUsers.filter((user) => {return viewers.includes(user.id)})
      const data = repoUsers.sort((a,b) => {return ((a.name || '') + ' ' + (a.surname ? ' ' + a.surname : '')).localeCompare((b.name || '') + (b.surname ? ' ' + b.surname : ''))});
      const editorsGroups = repoGroups.filter((user) => {return editors.includes(user.id)})
      const restrictedEditorsGroups = repoGroups.filter((user) => {return restrictedEditors.includes(user.id)})
      const viewersGroups = repoGroups.filter((user) => {return viewers.includes(user.id)})
      const groups = repoGroups.sort((a,b) => {return (a.name).localeCompare(b.name)});
      this._isMounted && this.setState({
        contacts: data, 
        filteredContacts: data,
        groups: groups,
        filteredGroups: groups,
        currentEditors: editorsUsers.concat(editorsGroups),
        currentRestrictedEditors: restrictedEditorsUsers.concat(restrictedEditorsGroups),
        currentViewers: viewersUsers.concat(viewersGroups),
        defaultEditors: editorsUsersDefault.concat(editorsGroupsDefault),
        defaultRestrictedEditors: restrictedEditorsUsersDefault.concat(restrictedEditorsGroupsDefault),
        defaultViewers: viewersUsersDefault.concat(viewersGroupsDefault),
        inheritPermissions: inheritPermissions,
        loadingEntities: false
      })
    })
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      this.props.callback({showFormPermissions: false})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      this._isMounted && this.setState({hidden:true})
    }
  }

  private _submit() {
    const {loading} = this.state;
    if (loading) return;
    this._isMounted && this.setState({loading: true})

    const {currentEditors, currentRestrictedEditors, currentViewers} = this.state;
    let editors = currentEditors.filter((item)=>{return !item.id.startsWith('Group-')}).map(a=>{return {userId: a.id, permissionRole: 'EDITOR'}});
    let restrictedEditors = currentRestrictedEditors.filter((item)=>{return !item.id.startsWith('Group-')}).map(a=>{return {userId: a.id, permissionRole: 'RESTRICTED_EDITOR'}});
    let viewers = currentViewers.filter((item)=>{return !item.id.startsWith('Group-')}).map(a=>{return {userId: a.id, permissionRole: 'VIEWER'}});
    let editorsGroups = currentEditors.filter((item)=>{return item.id.startsWith('Group-')}).map(a=>{return {groupId: a.id, permissionRole: 'EDITOR'}});
    let restrictedEditorsGroups = currentRestrictedEditors.filter((item)=>{return item.id.startsWith('Group-')}).map(a=>{return {groupId: a.id, permissionRole: 'RESTRICTED_EDITOR'}});
    let viewersGroups = currentViewers.filter((item)=>{return item.id.startsWith('Group-')}).map(a=>{return {groupId: a.id, permissionRole: 'VIEWER'}});
    let permissions = {
      userPermissions: editors.concat(restrictedEditors).concat(viewers),
      groupPermissions: editorsGroups.concat(restrictedEditorsGroups).concat(viewersGroups),
      inheritPermissions: this.state.inheritPermissions
    }
    if (this.props.page === 'permissions') {
      userService.updatePermissions(permissions).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else if (this.props.page === 'companyData' || this.props.page === 'companiesExplorer') {
      userService.updateCompanyPermissions(this.props.item.id, permissions).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else if (this.props.page === 'contactData' || this.props.page === 'contactsExplorer') {
      userService.updateContactPermissions(this.props.item.id, permissions).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else if (this.props.page === 'contractData' || this.props.page === 'contractsExplorer') {
      userService.updateContractPermissions(this.props.item.id, permissions).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else if (this.props.page === 'emailsExplorer') {
      userService.updateEmailPermissions(this.props.item.id, permissions).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else if (this.props.page === 'notes') {
      userService.updateNotePermissions(this.props.item.id, permissions).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else if (this.props.page === 'conversations') {
      userService.updateConversationPermissions(this.props.item.id, permissions).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    } else {
      userService.updateProjectPermissions(this.props.item.id, permissions).then(()=>{
        this._isMounted && this.setState({hidden: true})
        this.props.getCurrentContent(true);
      }).catch((error)=>{
        this._isMounted && this.setState({loading: false})
        if (error && error.data && error.data.code === 400 && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else if (error && error.data && error.data.message) {
          this.props.showAlertDialog('Error', error.data.message)
        } else {
          this.props.showAlertDialog('Error', "An error occurred trying to update relations. Please try again.")
        }
      })
    }
  }

  private sortItems(items, asc:boolean) {
    if (asc) {
      return items.slice(0).sort((a: any, b: any) => {
        if ((a.name + ' ' + a.surname) < (b.name + ' ' + b.surname)) return -1;
        if ((a.name + ' ' + a.surname) > (b.name + ' ' + b.surname)) return 1;
        else return 0;
      })
    } else {
      return items.slice(0).sort((a: any, b: any) => {
        if ((a.name + ' ' + a.surname) < (b.name + ' ' + b.surname)) return 1;
        if ((a.name + ' ' + a.surname) > (b.name + ' ' + b.surname)) return -1;
        else return 0;
      })
    }

  }

  private addTo(item: any, type: string) {
    if(!this.isSelected(item)) {
      if (type === 'editors') this.setState({currentEditors: this.sortItems([...this.state.currentEditors, item], this.state.sortedAZ), showCurrentEditors: true, changed: true})
      if (type === 'restrictedEditors') this.setState({currentRestrictedEditors: this.sortItems([...this.state.currentRestrictedEditors, item], this.state.sortedAZ), showCurrentRestrictedEditors: true, changed: true})
      if (type === 'viewers') this.setState({currentViewers: this.sortItems([...this.state.currentViewers, item], this.state.sortedAZ), showCurrentViewers: true, changed: true})
    }
  }

  private removeFrom(item: any, type: string) {
    if (type === 'editors') this.setState({currentEditors: this.state.currentEditors.filter((i)=>{return i.id !== item.id}), changed: true})
    if (type === 'restrictedEditors') this.setState({currentRestrictedEditors: this.state.currentRestrictedEditors.filter((i)=>{return i.id !== item.id}), changed: true})
    if (type === 'viewers') this.setState({currentViewers: this.state.currentViewers.filter((i)=>{return i.id !== item.id}), changed: true})
  }

  private isSelected(item: any) {
    return this.state.currentEditors.filter((i)=>{return i.id === item.id}).length > 0 || this.state.currentRestrictedEditors.filter((i)=>{return i.id === item.id}).length > 0 || this.state.currentViewers.filter((i)=>{return i.id === item.id}).length > 0
  }

  private changeEntityType(type: string) {
    this.setState({entityType: type})
  }

  private filter(filter:string) {
    let value = filter.toLowerCase()
    if (value && value.length > 0) {
      let items = this.state.contacts.filter((item)=>{
        let name = item.name + ' ' + item.surname;
        return name.toLowerCase().includes(value)
      })
      let groups = this.state.groups.filter((item)=>{
        return item.name.toLowerCase().includes(value)
      })
      this._isMounted && this.setState({filteredContacts: items, filteredGroups: groups, filter: value})
    } else {
      this._isMounted && this.setState({filteredContacts: this.state.contacts, filteredGroups: this.state.groups, filter: value})
    }
  }

  private toggleAll() {
    if (this.state.showCurrentEditors && this.state.showCurrentRestrictedEditors && this.state.showCurrentViewers) {
      this._isMounted && this.setState({showCurrentEditors: false, showCurrentRestrictedEditors: false, showCurrentViewers: false})
    } else {
      this._isMounted && this.setState({showCurrentEditors: true, showCurrentRestrictedEditors: true, showCurrentViewers: true})
    }
  }

  private toggleSort() {
    this._isMounted && this.setState({
      sortedAZ: !this.state.sortedAZ,
      currentEditors: this.sortItems(this.state.currentEditors, !this.state.sortedAZ),
      currentRestrictedEditors: this.sortItems(this.state.currentRestrictedEditors, !this.state.sortedAZ),
      currentViewers: this.sortItems(this.state.currentViewers, !this.state.sortedAZ)
    })
  }

  public render() {

    const { hidden, loading } = this.state;
    const columns:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showUsers: !this.state.showUsers})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showUsers ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:Users') + ' (' + this.state.contacts.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-2 align-items-center" style={{userSelect:'none'}} onClick={()=>{this.addTo(item,this.state.entityType)}}>
            <Persona name={(item.name || '') + ' ' + (item.surname || '')} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}>
                <Link target='_blank' rel="noreferrer noopener"  href={'mailto:'+item.email}>{item.email}</Link>
              </p>
            </div>
            { !this.isSelected(item) ? 
             <TooltipHost
              content={i18n.t('app:add')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
              >
              <IconButton style={{color: 'rgb(0, 120, 212)'}} className='ml-auto' iconProps={{iconName: 'Add'}} />
            </TooltipHost>
            :
            <IconButton disabled style={{opacity: 1, background: 'transparent', color: 'green', width:'30px', fontSize:18, textAlign: 'center', verticalAlign: 'middle'}} className='ml-auto icon-disabled' iconProps={{iconName:'CheckMark'}} />
          }
          </div>
        </>)
      }
    }]

    const columnsDefaultPermissionsEditor:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showDefaultEditors: !this.state.showDefaultEditors})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showDefaultEditors ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:editors') + ' (' + this.state.defaultEditors.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            <Persona name={(item.name || '') + ' ' + (item.surname || '')} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            <div style={{marginLeft: '12px'}}>
              {item.id.startsWith('Group-') ? 
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name + ' (' + item.users.length + 'p.)'}</p>
              :
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              }
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}>
                <Link target='_blank' rel="noreferrer noopener"  href={'mailto:'+item.email}>{item.email}</Link>
              </p>
            </div>
          </div>
        </>)
      }
    }]

    const columnsDefaultPermissionsRestrictedEditors:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showDefaultRestrictedEditors: !this.state.showDefaultRestrictedEditors})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showDefaultRestrictedEditors ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:restrictedEditors') + ' (' + this.state.defaultRestrictedEditors.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            <Persona name={(item.name || '') + ' ' + (item.surname || '')} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            <div style={{marginLeft: '12px'}}>
              {item.id.startsWith('Group-') ? 
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name + ' (' + item.users.length + 'p.)'}</p>
              :
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              }
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}>
                <Link target='_blank' rel="noreferrer noopener"  href={'mailto:'+item.email}>{item.email}</Link>
              </p>
            </div>
          </div>
        </>)
      }
    }]

    const columnsDefaultPermissionsViewers:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showDefaultViewers: !this.state.showDefaultViewers})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showDefaultViewers ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:viewers') + ' (' + this.state.defaultViewers.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            <Persona name={(item.name || '') + ' ' + (item.surname || '')} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            <div style={{marginLeft: '12px'}}>
              {item.id.startsWith('Group-') ? 
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name + ' (' + item.users.length + 'p.)'}</p>
              :
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              }
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}>
                <Link target='_blank' rel="noreferrer noopener"  href={'mailto:'+item.email}>{item.email}</Link>
              </p>
            </div>
          </div>
        </>)
      }
    }]

    const columnsGroups:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showGroups: !this.state.showGroups})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showGroups ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:groups') + ' (' + this.state.groups.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-2 align-items-center" style={{userSelect:'none'}} onClick={()=>{this.addTo(item,this.state.entityType)}}>
            <Persona name={item.name} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name + ' (' + item.users.length + 'p.)'}</p>
            </div>
            { !this.isSelected(item) ? 
             <TooltipHost
              content={i18n.t('app:add')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
              >
              <IconButton style={{color: 'rgb(0, 120, 212)'}} className='ml-auto' iconProps={{iconName: 'Add'}} />
            </TooltipHost>
            :
            <IconButton disabled style={{opacity: 1, background: 'transparent', color: 'green', width:'30px', fontSize:18, textAlign: 'center', verticalAlign: 'middle'}} className='ml-auto icon-disabled' iconProps={{iconName:'CheckMark'}} />
          }
          </div>
        </>)
      }
    }]
  
    const columnsEditors:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentEditors: !this.state.showCurrentEditors})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentEditors ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:editors') + ' (' + this.state.currentEditors.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            <Persona name={(item.name || '') + ' ' + (item.surname || '')} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            <div style={{marginLeft: '12px'}}>
              {item.id.startsWith('Group-') ? 
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name + ' (' + item.users.length + 'p.)'}</p>
              :
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              }
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}>
                <Link target='_blank' rel="noreferrer noopener"  href={'mailto:'+item.email}>{item.email}</Link>
              </p>
            </div>
            <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,'editors')}}/>
            </TooltipHost>
          </div>
        </>)
      }
    }]

    const columnsRestrictedEditors:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentRestrictedEditors: !this.state.showCurrentRestrictedEditors})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentRestrictedEditors ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:restrictedEditors') + ' (' + this.state.currentRestrictedEditors.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            <Persona name={(item.name || '') + ' ' + (item.surname || '')} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}>
                <Link target='_blank' rel="noreferrer noopener"  href={'mailto:'+item.email}>{item.email}</Link>
              </p>
            </div>
            <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,'restrictedEditors')}}/>
            </TooltipHost>
          </div>
        </>)
      }
    }]

    const columnsViewers:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentViewers: !this.state.showCurrentViewers})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse')}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentViewers ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:viewers') + ' (' + this.state.currentViewers.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            <Persona name={(item.name || '') + ' ' + (item.surname || '')} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            <div style={{marginLeft: '12px'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{(item.name || '') + ' ' + (item.surname || '')}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)'}}>
                <Link target='_blank' rel="noreferrer noopener"  href={'mailto:'+item.email}>{item.email}</Link>
              </p>
            </div>
            <TooltipHost
              content={i18n.t('app:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,'viewers')}}/>
            </TooltipHost>
          </div>
        </>)
      }
    }]

    const onRenderDetailsHeaderEditors = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center'>
          {defaultRender!({
            ...props
          })}
          <TooltipHost
            content={i18n.t('app:showEditors')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {position: 'relative', left: '-40px'} }}
          >
            <IconButton className='panel-icon' iconProps={{iconName: 'OpenPaneMirrored'}} styles={{icon: {fontSize: 20, color: this.state.entityType === 'editors' ? 'rgb(0, 120, 212)' : ''}}} onClick={()=>{this.changeEntityType('editors'); this.setState({showCurrentEditors: true})}}/>
          </TooltipHost>
        </div>
      );
    }

    const onRenderDetailsHeaderRestrictedEditors =(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center' style={{backgroundColor: 'white'}}>
          {defaultRender!({
            ...props
          })}
          <TooltipHost
            content={i18n.t('app:showRestrictedEditors')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {position: 'relative', left: '-40px'} }}
          >
            <IconButton className='panel-icon' iconProps={{iconName: 'OpenPaneMirrored'}} styles={{icon: {fontSize: 20, color: this.state.entityType === 'restrictedEditors' ? 'rgb(0, 120, 212)' : ''}}} onClick={()=>{this.changeEntityType('restrictedEditors'); this.setState({showCurrentRestrictedEditors: true})}}/>
          </TooltipHost>
        </div>
      );
    }

    const onRenderDetailsHeaderViewers =(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center' style={{backgroundColor: 'white'}}>
          {defaultRender!({
            ...props
          })}
          <TooltipHost
            content={i18n.t('app:showViewers')}
            calloutProps={{ gapSpace: 0 }}
            styles={{root: {position: 'relative', left: '-40px'} }}
          >
            <IconButton className='panel-icon' iconProps={{iconName: 'OpenPaneMirrored'}} styles={{icon: {fontSize: 20, color: this.state.entityType === 'viewers' ? 'rgb(0, 120, 212)' : ''}}} onClick={()=>{this.changeEntityType('viewers'); this.setState({showCurrentViewers: true})}}/>
          </TooltipHost>
        </div>
      );
    }

    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Relations'  src={process.env.PUBLIC_URL + '/icons/relations.svg'} />{i18n.t('app:editPermissions')}</div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: 'none', background: '#f2f2f2'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog full-height",
            onDismiss: ()=> {this.close()},
          }}
        >
          <div className='' style={{borderBottom: '1px solid #bfbfbf', position: 'sticky', top: 0, zIndex: 1}}></div>
          <FluentProvider theme={webLightTheme} className='w-100' style={{display: 'flex', height: '100%', backgroundColor: '#f2f2f2'}}>
            <div className='w-50'>
              <div className='d-flex align-items-center py-4 px-2' style={{height: '32px', borderBottom: '1px solid #bfbfbf'}}>
                <TooltipHost
                  content={i18n.t('app:expand/collapseAll')}
                  calloutProps={{ gapSpace: 0 }}
                  styles={{root: {marginRight: '8px'}}}
                >
                  <IconButton
                    onClick={()=>this.toggleAll()} 
                    className='expand-icon' 
                    iconProps={{iconName: this.state.showCurrentEditors && this.state.showCurrentRestrictedEditors && this.state.showCurrentViewers ? 'CollapseContent' : 'ExploreContent', styles: {root: {fontSize: 18, color: 'rgb(0, 120, 212)'}}}} 
                  />
                </TooltipHost>
                <span style={{fontWeight: 600}}>{i18n.t('app:permissions')}</span>
                <TooltipHost
                  key={'sort'}
                  content={i18n.t('app:switchOrder')}
                  id={'sort'}
                  calloutProps={{ gapSpace: 0 }}
                  styles={{root: {marginLeft: 'auto'}}}
                >
                  <ActionButton
                    onClick={()=>this.toggleSort()} 
                    className='expand-icon'
                    text={i18n.t('app:ByName')}
                    iconProps={{iconName: this.state.sortedAZ ? 'SortDown' : 'SortUp'}}
                    style={{height: '32px'}} 
                  />
                </TooltipHost>
              </div>
              <ScrollablePane className='relation-list w-50' style={{top: '50px'}}>
                {this.props.page !== 'permissions' && <>
                <div className='d-flex align-items-center w-100 mt-4 pb-1'>
                  <Label className='form-label flex-grow-1'>{i18n.t('app:inheritDefaultPermissions')}</Label>
                  <Switch checked={this.state.inheritPermissions} onChange={()=>{this._isMounted && this.setState({inheritPermissions: !this.state.inheritPermissions})}}/>
                </div>
                {this.state.inheritPermissions && <>
                <ShimmeredDetailsList
                  className={this.state.defaultEditors.length === 0 || !this.state.showDefaultEditors ? 'mt-3 height-fix' : 'mt-3'}
                  items={this.state.showDefaultEditors ? this.state.defaultEditors : []}
                  columns={columnsDefaultPermissionsEditor}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={true}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  ariaLabelForShimmer="Content is being fetched"
                  enterModalSelectionOnTouch={true}
                  shimmerLines={12}
                  constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.defaultEditors.length === 0 && this.state.showDefaultEditors && <div className='d-flex mb-4' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                  <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
                </div>}
                <ShimmeredDetailsList
                  className={this.state.defaultRestrictedEditors.length === 0 || !this.state.showDefaultRestrictedEditors ? 'mt-4 height-fix' : 'my-4'}
                  items={this.state.showDefaultRestrictedEditors ? this.state.defaultRestrictedEditors : []}
                  columns={columnsDefaultPermissionsRestrictedEditors}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={true}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  ariaLabelForShimmer="Content is being fetched"
                  enterModalSelectionOnTouch={true}
                  shimmerLines={12}
                  constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.defaultRestrictedEditors.length === 0 && this.state.showDefaultRestrictedEditors && <div className='d-flex mb-4' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                  <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
                </div>}
                <ShimmeredDetailsList
                  className={this.state.defaultViewers.length === 0 || !this.state.showDefaultViewers ? 'mt-4 height-fix' : 'my-4'}
                  items={this.state.showDefaultViewers ? this.state.defaultViewers : []}
                  columns={columnsDefaultPermissionsViewers}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={true}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  ariaLabelForShimmer="Content is being fetched"
                  enterModalSelectionOnTouch={true}
                  shimmerLines={12}
                  constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.defaultViewers.length === 0 && this.state.showDefaultViewers && <div className='d-flex mb-4' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                  <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
                </div>}
                </>}
                </>}
                <Label className='form-label my-4'>{this.props.page === 'permissions' ? i18n.t('app:defaultPermissions') : i18n.t('app:customPermissions')}</Label>
                <ShimmeredDetailsList
                  className={this.state.currentEditors.length === 0 || !this.state.showCurrentEditors ? 'height-fix' : 'mb-4'}
                  items={this.state.showCurrentEditors ? this.state.currentEditors : []}
                  columns={columnsEditors}
                  onRenderDetailsHeader={onRenderDetailsHeaderEditors}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={true}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  ariaLabelForShimmer="Content is being fetched"
                  enterModalSelectionOnTouch={true}
                  shimmerLines={12}
                  constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentEditors.length === 0 && this.state.showCurrentEditors && <div className='d-flex mb-4' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                  <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
                </div>}
                <ShimmeredDetailsList
                  className={this.state.currentRestrictedEditors.length === 0 || !this.state.showCurrentRestrictedEditors ? 'mt-4 height-fix' : 'my-4'}
                  items={this.state.showCurrentRestrictedEditors ? this.state.currentRestrictedEditors : []}
                  columns={columnsRestrictedEditors}
                  onRenderDetailsHeader={onRenderDetailsHeaderRestrictedEditors}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={true}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  ariaLabelForShimmer="Content is being fetched"
                  enterModalSelectionOnTouch={true}
                  shimmerLines={12}
                  constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentRestrictedEditors.length === 0 && this.state.showCurrentRestrictedEditors && <div className='d-flex mb-4' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                  <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
                </div>}
                <ShimmeredDetailsList
                  className={this.state.currentViewers.length === 0 || !this.state.showCurrentViewers ? 'mt-4 height-fix' : 'my-4'}
                  items={this.state.showCurrentViewers ? this.state.currentViewers : []}
                  columns={columnsViewers}
                  onRenderDetailsHeader={onRenderDetailsHeaderViewers}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={true}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  ariaLabelForShimmer="Content is being fetched"
                  enterModalSelectionOnTouch={true}
                  shimmerLines={12}
                  constrainMode={ConstrainMode.unconstrained}
                />
                {this.state.currentViewers.length === 0 && this.state.showCurrentViewers && <div className='d-flex mb-4' style={{height: '48px', background: 'white', fontSize:12, color: '#bfbfbf'}}>
                  <span className='my-auto ml-5'>{i18n.t('app:empty')}</span>
                </div>}
              </ScrollablePane>
            </div>
            <div className='w-50' style={{borderLeft: '1px solid #bfbfbf'}}>
              <div className='d-flex align-items-center' style={{borderBottom: '1px solid #bfbfbf', height: '49px'}}>
                {this.state.entityType === '' &&  <span className='pl-3' style={{fontWeight: 600}}>{i18n.t('app:selectRoleTitle')}</span>}
                {this.state.entityType === 'editors' && <span className='pl-3' style={{fontWeight: 600}}>{i18n.t('app:editor')}</span>}
                {this.state.entityType === 'restrictedEditors' && <span className='pl-3' style={{fontWeight: 600}}>{i18n.t('app:restrictedEditor')}</span>}
                {this.state.entityType === 'viewers' && <span className='pl-3' style={{fontWeight: 600}}>{i18n.t('app:viewer')}</span>}
                {this.state.entityType !== '' && <ActionButton className='expand-icon ml-auto mr-4' style={{height: '32px'}} text={i18n.t('app:closeRole')} onClick={()=>{this._isMounted && this.setState({entityType: ''})}} iconProps={{iconName: 'Cancel'}}/>}
              </div>
              {this.state.entityType === '' ? 
              <>
                <div className='text-center' style={{top: 'calc(50% - 49px - 32px)', position: 'relative', opacity: .8}}>
                  <div>{i18n.t('app:selectRole')}</div>
                  <div className='mt-3'>
                    <p className='d-flex align-items-center justify-content-center m-0'>{i18n.t('app:selectRoleDescription1')}<Icon className='ml-2' style={{fontSize: 20, marginTop: '4px'}} iconName='OpenPaneMirrored'/></p>
                    
                    <p>{i18n.t('app:selectRoleDescription2')}</p>
                  </div>
                </div>
              </> 
              :
              <>
              <div className='d-flex mt-4 mx-3'>
                <Input 
                  contentBefore={<Icon iconName='Filter' />}
                  className='filter-entities flex-grow-1' 
                  placeholder={i18n.t('app:filterByName')}
                  value={this.state.filter} 
                  onChange={(e,data)=>{this.filter(data.value)}}
                />
              </div>
              <ScrollablePane className='relation-list w-50' style={{left:'50%', top: '130px'}}>
              <ShimmeredDetailsList
                className={this.state.filteredContacts.length === 0 || !this.state.showUsers ? 'height-fix' : 'mb-4'}
                items={this.state.showUsers ? this.state.filteredContacts : []}
                columns={columns}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                shimmerLines={12}
                constrainMode={ConstrainMode.unconstrained}
              />
              <ShimmeredDetailsList
                className={this.state.filteredGroups.length === 0 || !this.state.showGroups ? 'mt-4 height-fix' : 'my-4'}
                items={this.state.showGroups ? this.state.filteredGroups : []}
                columns={columnsGroups}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                shimmerLines={12}
                constrainMode={ConstrainMode.unconstrained}
              />
              </ScrollablePane>
              </>
              }
            </div>
          </FluentProvider>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=>{this.close()}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton onClick={()=>{this._submit()}} text={i18n.t('app:save')} disabled={loading || (!this.state.inheritPermissions && this.state.currentEditors.length === 0 && this.state.currentRestrictedEditors.length === 0 && this.state.currentViewers.length === 0 && this.props.page !== 'permissions') } />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }
}
