import { ActionButton, Checkbox, ComboBox, DatePicker, Dropdown, Icon, IconButton, TooltipHost } from '@fluentui/react';
import { Input, Label, Textarea } from '@fluentui/react-components';
import * as React from 'react';
import i18n from '../i18n';


export interface Props {
  fieldType: string;
  possibleValues: any[];
  name: string;
}

export interface States {
  hidden: boolean;
}

export class CustomFieldPreview extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      hidden: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
  }
  
  public componentDidUpdate() {
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public render() {

    const { fieldType, possibleValues, name } = this.props;

    let options:any = [];
    if (possibleValues) {
      for (let key in possibleValues) {
        options.push({key:key, text: this.props.reorder ? possibleValues[key] : possibleValues[key].text}) 
      }
      options.sort((a,b) => { return (a.text >= b.text ? 1 : -1)})
    }

    return (
      <div className='d-flex mt-2'>
       <Icon 
          iconName={fieldType === 'singleLine' || fieldType === 'multiLine' ? 'TextField' 
          : fieldType === 'number' ? 'NumberField' 
          : fieldType === 'checkbox' ? 'CheckboxComposite' 
          : fieldType === 'datePicker' ? 'Calendar' 
          : fieldType === 'dropdownSelection' || fieldType === 'multiSelection' ? 'Dropdown'
          : fieldType === 'singleLine' ? 'file'
          : 'TextField'} 
          style={{marginTop: this.props.reorder && fieldType === 'multiLine' ? '18px' : '5px', marginRight: '10px'}}
        />
        <Label className='form-label' style={{ marginTop: this.props.reorder && fieldType === 'multiLine' ? '17px' : '4px'}}>{name || i18n.t('app:label')}</Label>
      
        { fieldType === 'singleLine' ?
          <Input
            className='flex-grow-1'
          /> 
        : fieldType === 'multiLine' ?
          <Textarea
            className='flex-grow-1'
          /> 
        : fieldType === 'number' ?
          <Input
            type='number'
            className='flex-grow-1'
          /> 
        : fieldType === 'checkbox' ?
          <Checkbox
            className='flex-grow-1'
            styles={{label: {margin: '6px 0 6px 0'}}}
          />
        : fieldType === 'dropdownSelection' ?
          <Dropdown
            styles={{ title: {borderRadius: '4px'}}}
            className='flex-grow-1'
            options={options}
          />
        : fieldType === 'multiSelection' ?
          <Dropdown
            styles={{ title: {borderRadius: '4px'}}}
            className='flex-grow-1'
            options={options}
            multiSelect
          />
        : fieldType === 'datePicker' ?
          <DatePicker
            className='flex-grow-1'
            styles={{root: {height: '32px'}}}
          />
        : fieldType === 'contact' ?
          <div className='d-flex flex-grow-1'>
            <ComboBox
              className='flex-grow-1'
              options={[]}
            />
            <ActionButton
              className='align-self-end'
              style={{height: '32px'}}
              iconProps={{iconName: 'Add'}}
            >
              {i18n.t('New contact')}
            </ActionButton>
          </div>
        : fieldType === 'file' ?
        <div className='d-flex flex-grow-1'>
          <Input className='flex-grow-1'/>
          <TooltipHost
            content={i18n.t('app:openFilePicker')}
            styles={{root: {borderRadius: '4px', position: 'relative', marginTop: '1px', right: '35px', width: '0px', height: '29px' }}}
          >
            <IconButton styles={{icon: {color: 'rgb(50,49,48)', height: '20px'}, root: {borderRadius: '4px', width: '34px', height: '29px'}}} iconProps={{iconName: 'DriveSynergyOS'}} />
          </TooltipHost>
        </div>
        : null }
      </div>
    )
  }

}
