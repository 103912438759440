import { userService } from "../_services/user.service";

export async function _reorderCustomFields(company, contact, project) {
    let companies, contacts, projects;

    await new Promise((resolve) => {
        if (company) userService.getCustomProperties('', 'COMPANY').then((response)=>{
            companies = response.data
            .map(item=>{return {...item, position: item.customPropertyTypes.find(x=>{return x.entityType === 'COMPANY'}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === 'COMPANY'}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}})
            .sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
            .map((property, index) => {return {customPropertyId: property.id, required: property.required, position: index}})
            userService.reorderCustomProperties('COMPANY', companies).then(()=>{
                resolve(true)
            });
        })
        else resolve(true)
    })

    await new Promise((resolve) => {
        if (contact) userService.getCustomProperties('', 'CONTACT').then((response)=>{
            contacts = response.data
            .map(item=>{return {...item, position: item.customPropertyTypes.find(x=>{return x.entityType === 'CONTACT'}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === 'CONTACT'}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}})
            .sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
            .map((property, index) => {return {customPropertyId: property.id, required: property.required, position: index}})
            userService.reorderCustomProperties('CONTACT', contacts).then(()=>{
                resolve(true)
            });
        })
        else resolve(true)
    })

    await new Promise((resolve) => {
        if (project) userService.getCustomProperties('', 'PROJECT').then((response)=>{
            projects = response.data
            .map(item=>{return {...item, position: item.customPropertyTypes.find(x=>{return x.entityType === 'PROJECT'}).position, required: item.customPropertyTypes.find(x=>{return x.entityType === 'PROJECT'}).required, fieldType: item.fieldType, possibleValues: item.possibleValues}})
            .sort((a,b) => { return (a.position >= b.position ? 1 : -1)})
            .map((property, index) => {return {customPropertyId: property.id, required: property.required, position: index}})
            userService.reorderCustomProperties('PROJECT', projects).then(()=>{
                resolve(true)
            });
        })
        else resolve(true)
    })
}
