import * as React from 'react';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import "moment/min/locales";
import { DocumentCard, DocumentCardTitle, Icon, IDocumentCardStyles, mergeStyles, Spinner } from '@fluentui/react';
import i18n from "i18next";
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { DetailsPanel } from '../_components/DetailsPanel';
import { userService } from '../_services';

export interface Props {
  userData: any;
  appPlatform: string,
  isOnline: boolean,
}

export interface HomeState { 
  userData: any;
  showRightPanel: boolean;
  breadcrumbPath: any[];
  searchQuery: any;
  syncStatus: any;
  update: boolean;
  projectCount: number;
  contractCount: number;
  contactCount: number;
  companyCount: number;
  pipelineCount: number;
  loading: boolean;
}

export class HomePage extends React.Component<Props, HomeState> {
  private _isMounted: boolean;
  private sidebarRef:any = React.createRef();
  private headerRef:any = React.createRef();

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      userData: null,
      showRightPanel: localStorage.detailsPanelOpen ? localStorage.detailsPanelOpen === 'true' : true,
      breadcrumbPath: [{
        text: i18n.t('app:home'),
        key: 'home',
        onClick: ()=>{history.push('/home')}
      }],
      searchQuery: [],
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      update: false,
      projectCount: 0,
      contractCount: 0,
      contactCount: 0,
      companyCount: 0,
      pipelineCount: 0,
      loading: true,
    }
  }

  public componentDidMount() {
    this._isMounted = true
    this._isMounted && this.setState({update: true})
    this.getCurrentContent()
  }

  private cardStyles: IDocumentCardStyles = {
    root: { marginRight: 20, maxWidth: 480 },
  };

  private conversationTileClass = mergeStyles({ height: 240 });

  private getCurrentContent() {
    const projectCount = userService.getProjectCount('?status=OPEN')
    const contractCount = userService.getContractCount()
    const contactCount = userService.getContactCount()
    const companyCount = userService.getCompanyCount()
    const pipelineCount = userService.getPipelineCount()
    this._isMounted && this.setState({loading: true})

    Promise.all([projectCount, contractCount, contactCount, companyCount, pipelineCount]).then((response) => {
      this._isMounted && this.setState({
        loading: false, 
        projectCount: response[0]?.data,
        contractCount: response[1]?.data,
        contactCount: response[2]?.data,
        companyCount: response[3]?.data,
        pipelineCount: response[4]?.data
      })
    })
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  public render() {
    return(
      <div>
        <Header
          ref={instance => { this.headerRef = instance; }}
          userData={ this.props.userData }
          breadcrumbPath={ this.state.breadcrumbPath }
          sidebarRef={ this.sidebarRef }
          page="home"
          actions="home"
          getActivity={ null }
          syncStatus={this.state.syncStatus}
          callbackFunction={this.callbackFunction}
          getCurrentContent={this.getCurrentContent.bind(this)}
        />

        <div className="content-wrap d-flex flex-row">
          <Sidebar
            ref={(instance:any) => { this.sidebarRef = instance; }}
            userData={ this.props.userData }
            page='home'
          />
          <div className="list flex-grow-1 justify-content-center" style={{textAlign: 'left', backgroundColor: 'rgb(250, 249, 248)'}}>
            <p style={{fontSize: 18, borderBottom: '1px solid rgb(191, 191, 191)', margin: '0 32px'}} className='my-0 py-4'>{i18n.t('app:mainEntities')}</p>
            <div className='row ml-4 mr-0'>
              <DocumentCard
                className='mr-4 mt-4 home-card'
                aria-label='Projects'
                styles={this.cardStyles}
                onClick={()=>history.push('/projects/pipeline')} >
                <div className={this.conversationTileClass} style={{display: 'flex', flexDirection: 'column', height: '180px'}}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <img alt='Projects' style={{height: '32px', width: '32px'}} src={process.env.PUBLIC_URL + '/icons/projects.svg'} />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Projects" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <div className='d-flex flex-grow-1 align-items-center'>
                  { this.state.loading ? 
                  <Spinner className='m-auto'/> 
                  : 
                  <div className='contract-number my-0'><span>{this.state.projectCount}</span></div>
                  }
                  </div>
                  <div className='d-flex px-3 pb-3'>
                    <span style={{opacity: .6}}>Open projects </span>
                  </div>
                </div>
              </DocumentCard>
              <DocumentCard
                className='mr-4 mt-4 home-card'
                aria-label='Contracts'
                styles={this.cardStyles}
                onClick={()=>history.push('/contracts')} >
                <div className={this.conversationTileClass} style={{display: 'flex', flexDirection: 'column', height: '180px'}}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <img alt='Contract' style={{height: '32px', width: '32px'}} src={process.env.PUBLIC_URL + '/icons/contracts.svg'} />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Contracts" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <div className='d-flex flex-grow-1 align-items-center'>
                  { this.state.loading ? 
                  <Spinner className='m-auto'/> 
                  : 
                  <div className='contract-number my-0'><span>{this.state.contractCount}</span></div>
                  }
                  </div>
                  <div className='d-flex px-3 pb-3'>
                    <span style={{opacity: .6}}>Total contracts </span>
                  </div>
                </div>
              </DocumentCard>
              <DocumentCard
                className='mr-4 mt-4 home-card'
                aria-label='Contacts'
                styles={this.cardStyles}
                onClick={()=>history.push('/contacts')} >
                <div className={this.conversationTileClass} style={{display: 'flex', flexDirection: 'column', height: '180px'}}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <img alt='Contacts' style={{height: '32px', width: '32px'}} src={process.env.PUBLIC_URL + '/icons/contacts.svg'} />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Contacts" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <div className='d-flex flex-grow-1 align-items-center'>
                  { this.state.loading ? 
                  <Spinner className='m-auto'/> 
                  : 
                  <div className='contract-number my-0'><span>{this.state.contactCount}</span></div>
                  }
                  </div>
                  <div className='d-flex px-3 pb-3'>
                    <span style={{opacity: .6}}>Total contacts </span>
                  </div>
                </div>
              </DocumentCard>
              <DocumentCard
                className='mr-4 mt-4 home-card'
                aria-label='Companies'
                styles={this.cardStyles}
                onClick={()=>history.push('/companies')}>
                <div className={this.conversationTileClass} style={{display: 'flex', flexDirection: 'column', height: '180px'}}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <img alt='Companies' style={{height: '32px', width: '32px'}} src={process.env.PUBLIC_URL + '/icons/companies.svg'} />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Companies" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <div className='d-flex flex-grow-1 align-items-center'>
                  { this.state.loading ? 
                  <Spinner className='m-auto'/> 
                  : 
                  <div className='contract-number my-0'><span>{this.state.companyCount}</span></div>
                  }
                  </div>
                  <div className='d-flex px-3 pb-3'>
                    <span style={{opacity: .6}}>Total companies </span>
                  </div>
                </div>
              </DocumentCard>
              {/*<DocumentCard
                className='mr-4 mt-4 home-card'
                aria-label='Time tracking'
                styles={this.cardStyles}
                onClick={()=>history.push('/time-sheet')}>
                <div className={this.conversationTileClass}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <img alt='Time tracker' style={{height: '32px', width: '32px'}} src={process.env.PUBLIC_URL + '/icons/recents.svg'} />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Time tracking" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <DocumentCardTitle
                    title="Track your time."
                    shouldTruncate
                    showAsSecondaryTitle
                  />
                </div>
              </DocumentCard>*/}
            </div>
            <p style={{fontSize: 18, borderBottom: '1px solid rgb(191, 191, 191)', margin: '0 32px'}} className='my-0 py-4'>{i18n.t('app:subEntities')}</p>
            <div className='row ml-4 mr-0'>
            <DocumentCard
                className='mr-4 mt-4 home-card big-200'
                aria-label='Notes'
                styles={this.cardStyles}
                onClick={()=>history.push('/notes/preview')}>
                <div className={this.conversationTileClass}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <img alt='Notes' style={{height: '32px', width: '32px'}} src={process.env.PUBLIC_URL + '/icons/documents.svg'} />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Notes" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <DocumentCardTitle
                    title="List of notes related to you."
                    shouldTruncate
                    showAsSecondaryTitle
                  />
                </div>
              </DocumentCard>
              <DocumentCard
                className='mr-4 mt-4 home-card big-200'
                aria-label='Conversations'
                styles={this.cardStyles}
                onClick={()=>history.push('/conversations/preview')}>
                <div className={this.conversationTileClass}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <img alt='Conversations' style={{transform: 'scale(1, 1.3)', height: '32px', width: '32px'}} src={process.env.PUBLIC_URL + '/icons/conversations.svg'} />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Conversations" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <DocumentCardTitle
                    title="List of conversations related to you."
                    shouldTruncate
                    showAsSecondaryTitle
                  />
                </div>
              </DocumentCard>
              <DocumentCard
                className='mr-4 mt-4 home-card big-200'
                aria-label='Tasks'
                styles={this.cardStyles}
                onClick={()=>history.push('/tasks/preview')}>
                <div className={this.conversationTileClass}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <img alt='Tasks' style={{height: '32px', width: '32px'}} src={process.env.PUBLIC_URL + '/icons/tasks.svg'} />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Tasks" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <DocumentCardTitle
                    title="List of tasks."
                    shouldTruncate
                    showAsSecondaryTitle
                  />
                </div>
              </DocumentCard>
              <DocumentCard
                className='mr-4 mt-4 home-card big-200'
                aria-label='Emails'
                styles={this.cardStyles}
                onClick={()=>history.push('/emails/preview')}>
                <div className={this.conversationTileClass}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <Icon className='activity-icon' style={{width: '32px', minWidth: '32px'}} {...getFileTypeIconProps({ extension: '.eml', size: 32, imageFileType: 'svg' }) } />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Emails" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <DocumentCardTitle
                    title="List of emails."
                    shouldTruncate
                    showAsSecondaryTitle
                  />
                </div>
              </DocumentCard>
            </div>
            <p style={{fontSize: 18, borderBottom: '1px solid rgb(191, 191, 191)', margin: '0 32px'}} className='my-0 py-4'>{i18n.t('app:other')}</p>
            <div className='row ml-4 mr-0'>
              <DocumentCard
                className='mr-4 mt-4 home-card'
                aria-label='Pipelines'
                styles={this.cardStyles}
                onClick={()=>history.push('/pipelines')}>
                <div className={this.conversationTileClass} style={{display: 'flex', flexDirection: 'column', height: '180px'}}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <img alt='Pipelines' style={{height: '32px', width: '32px'}} src={process.env.PUBLIC_URL + '/icons/pipelines.svg'} />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Pipelines" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <div className='d-flex flex-grow-1 align-items-center'>
                  { this.state.loading ? 
                  <Spinner className='m-auto'/> 
                  : 
                  <div className='contract-number my-0'><span>{this.state.pipelineCount}</span></div>
                  }
                  </div>
                  <div className='d-flex px-3 pb-3'>
                    <span style={{opacity: .6}}>Pipelines </span>
                  </div>
                </div>
              </DocumentCard>
              <DocumentCard
                className='mr-4 mt-4 home-card big-200'
                aria-label='Search'
                styles={this.cardStyles}
                onClick={()=>history.push('/search')}>
                <div className={this.conversationTileClass}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <img alt='Search' style={{height: '32px', width: '32px'}} src={process.env.PUBLIC_URL + '/icons/search.png'} />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Search" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <DocumentCardTitle
                    title="Search for anything."
                    shouldTruncate
                    showAsSecondaryTitle
                  />
                </div>
              </DocumentCard>
              <DocumentCard
                className='mr-4 mt-4 home-card big-200'
                aria-label='Permissions'
                styles={this.cardStyles}
                onClick={()=>history.push('/permissions')}>
                <div className={this.conversationTileClass}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <img alt='Search' style={{height: '32px', width: '32px'}} src={process.env.PUBLIC_URL + '/icons/relations.svg'} />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Permissions" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <DocumentCardTitle
                    title="Permissions."
                    shouldTruncate
                    showAsSecondaryTitle
                  />
                </div>
              </DocumentCard>
              <DocumentCard
                className='mr-4 mt-4 home-card big-200'
                aria-label='Search'
                styles={this.cardStyles}
                onClick={()=>history.push('/custom-fields/project')}>
                <div className={this.conversationTileClass}>
                  <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                    <img alt='Search' style={{height: '32px', width: '32px'}} src={process.env.PUBLIC_URL + '/icons/filters.svg'} />
                    <DocumentCardTitle className='pr-0 pl-2 title' title="Custom fields" shouldTruncate />
                  </div>
                  <hr className='my-2 separator'/>
                  <DocumentCardTitle
                    title="Custom fields."
                    shouldTruncate
                    showAsSecondaryTitle
                  />
                </div>
              </DocumentCard>
            </div>
          </div>
          <DetailsPanel
            appPlatform={ this.props.appPlatform }
            userData={ this.props.userData }
            showRightPanel={ this.state.showRightPanel }
            callbackFunction={this.callbackFunction}
            page={"home"}
            selItem={null}
            items={[]}
            headerRef={this.headerRef}
          />
        </div>
      </div>
    )
  }
}