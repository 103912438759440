import * as React from 'react';
import "moment/min/locales";
import PDFViewer from './PDFViewer';
import PDFJSBackend from './pdfjs';
import { userService } from '../../_services';
import i18n from '../../i18n';

declare global {
  interface Window {
    require: any;
  }
}


export interface Props {
  selFile: any;
}

export interface State {
  selFile: any;
  annotationsToggle: boolean;
  absolutePath: string;
  docId: string;
  revisionId: string;
  xPos: number;
  yPos: number;
  showMenu: boolean;
}

export class PdfRoot extends React.Component<Props, State> {
  private _isMounted: boolean;

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      selFile: null,
      annotationsToggle: false,
      absolutePath: "",
      docId: this.props.selFile && this.props.selFile.id,
      revisionId: this.props.selFile && this.props.selFile.activeRevisionId,
      xPos: 0,
      yPos: 0,
      showMenu: false
    }
  }

  public componentDidMount() {
    this._isMounted = true;

    var searchParams = window.location.search;
    if (searchParams) {
      const params = new URLSearchParams(searchParams);
      var absolutePath:any = params.get("absolutePath");
      var docId:any = params.get("selItem");

      this._isMounted && this.setState({ absolutePath: absolutePath, docId: docId })
    }
    window.document.addEventListener('googleSearch', handleEvent, false)
    function handleEvent(e) {
      window.open('https://www.google.com/search?q='+e.detail);
    }


    window.addEventListener("message", this.onMessageReceived.bind(this), false);
  
    // Custom titlebar content height 100vh fix
    var titlebar : HTMLDivElement | null  = document.querySelector('.titlebar');
    if(titlebar instanceof HTMLDivElement) {
      var titlebarHeight = titlebar.offsetHeight;
      var pdfViewerHeightFix : HTMLDivElement | null = document.querySelector('#pdfViewer');
      if(pdfViewerHeightFix instanceof HTMLDivElement) {
        pdfViewerHeightFix.style.height = 'calc(100vh - ' + titlebarHeight + 'px)';
      }
    }
  }

  public componentDidUpdate(prevProps: any, prevState: State) {
    if (this.state.selFile !== prevState.selFile) {

      if (this.state.selFile && this.state.selFile.annotations.length > 0) {
        if (!prevState.selFile
          || (prevState.selFile && this.state.selFile.docId !== prevState.selFile.docId)) {
          this.setState({
            annotationsToggle: true
          })
        }
      }
    }

    if (this.props.selFile && this.props.selFile !== prevProps.selFile) {
        this._isMounted && this.setState({ absolutePath: '', docId: this.props.selFile.id, revisionId: this.props.selFile.activeRevisionId })
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  onMessageReceived(event) {
    var data:any = event.data;

    if (data.msg === "selFile") {
      var selFile = data.selFile;

      if(selFile) {
        this.setState({
          selFile: {
            docId: selFile.docId,
            annotations: JSON.parse(data.selFile.annotations)
          }
        })
      } else {
        this.setState({ selFile: null })
      }
    }

    if (data.msg === "annotationsToggle") {
      const { annotationsToggle } = this.state;

      this.setState({
        annotationsToggle: !annotationsToggle
      })
    }
  }

  callbackFunction = (childData) => {
      this.setState(childData)
  }

  public render() {

    return(
      <div id="pdfViewer" className='h-100' style={{overflowY: 'hidden'}}>
        <div id="pdfContent" className="content-wrap d-flex flex-row h-100">
          <div className="mr-auto flex-grow-1">
            {(this.state.docId && this.state.docId.length > 1) || window.innerWidth <= 600 ? null
            : <span style={{position: 'absolute', top: '50%', textAlign: 'center', width:'100%', zIndex:-1}}>{i18n.t('app:noFileLoaded')}</span> }
            <PDFViewer
              callbackFunction={ this.callbackFunction }
              backend={PDFJSBackend}
              src={ this.state.absolutePath }
              selFile={this.props.selFile}
            />
          </div>
        </div>
      </div>
    )
  }
}