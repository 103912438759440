import { ActionButton, DefaultButton, Dialog, DialogFooter, DialogType, Icon, IconButton, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import { FluentProvider, Input, Label, Textarea, webLightTheme } from '@fluentui/react-components';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import * as React from 'react';
import i18n from '../../i18n';
import { userService } from '../../_services';

export interface Props {
  callback:any;
  item: any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  customFields: any[];
  chosenFields:any;
  chosenFieldsKeys: any;
  items: any[];
  propertiesOrder: any[];
  customProperties: any[];
  loadingProperties: boolean;
  canSubmit: boolean;
  error:any;
  stages: any;
  name: string;
  description: string;
  oldName: string;
  oldDescription : string;
  oldStages : any;
  changed: boolean;
}

export class FormPipelineEdit extends React.Component<Props, States> {
  private _isMounted: boolean;
  private _currentKey: number;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._currentKey = 1;
    this.props = props

    this.state = {
      hidden: false,
      loading: false,
      customFields: [{name:"", value: ""}],
      chosenFields: [],
      chosenFieldsKeys: [],
      items: [],
      propertiesOrder: [],
      customProperties: [],
      loadingProperties: false,
      canSubmit: true,
      error: "",
      stages: [{key: '1', id: '1', name: '', description: ''}],
      name: '',
      description: '',
      oldName: '',
      oldDescription : '',
      oldStages : [],
      changed: false,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    let stages = this.props.item.pipeline ? this.props.item.pipeline.stages.slice(0).sort((a,b) => { return (a.position >= b.position ? 1 : -1)}) : [];
    this.props.item && this.props.item.pipeline && this.setState({name: this.props.item.pipeline.name, oldName: this.props.item.pipeline.name, description: this.props.item.pipeline.description, oldDescription: this.props.item.pipeline.description, stages: JSON.parse(JSON.stringify(stages)), oldStages: JSON.parse(JSON.stringify(stages))})
  }
  
  public componentDidUpdate() {
    if (this.state.hidden) {
      this.props.callback({showEditPipelineForm: false, pipelineItem: null})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private _handleKeyDown(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 65 && event.keyCode <= 90)){
      this._isMounted && this.setState({changed:true})
    }
  }

  private close() {
    if (this.state.changed) {
      this.props.showConfirmDialog(i18n.t('app:discard'), i18n.t('app:areYouSureDiscard')).then(result=>{
        if (result) {
          this._isMounted && this.setState({hidden:true})
        }
      })
    } else {
      this._isMounted && this.setState({hidden:true})
    }
  }

  private _updateStages(index, parameter, data) {
    let options = this.state.stages;
    options[index][parameter] = data.value;
    this._isMounted && this.setState({stages: options, changed: true});
  }

  private _addStage() {
    this._currentKey += 1;
    let options = this.state.stages;
    options.push({key: this._currentKey.toString(), id: this._currentKey.toString(), name: '', description: ''})
    this._isMounted && this.setState({stages: options, changed: true});
  }

  private _deleteStage(index) {
    let options = this.state.stages;
    options.splice(index, 1);
    this._isMounted && this.setState({stages: options, changed: true});
  }

  private _updateName(data:any) {
    this._isMounted && this.setState({name: data.value, changed: true})
  }

  private _updateDecription(data:any) {
    this._isMounted && this.setState({description: data.value, changed: true})
  }

  private async _submit(e) {
    if (e) e.preventDefault();
    const {loading} = this.state;
    if (loading) return;
    if (this.state.name === '') {
      this._isMounted && this.setState({error: 'A pipeline must have a name'})
    } else {
      let errorMessage = '';
      this._isMounted && this.setState({loading: true})
      const {stages, oldStages} = this.state;
      let stagesOrder:any[] = [];
      for (let i in stages) {
        stagesOrder.push({name: stages[i].name, position: i})
      }
  
      //Get removed Stages
      let removedStages:any[] = [];
      for(let i in oldStages) {
        let removed = true;
        for (let j in stages) {if (stages[j].id === oldStages[i].id) removed = false}
        if (removed) removedStages.push(oldStages[i])
      }
  
      //Get new Stages
      let newStages:any[] = [];
      for(let i in stages) {
        let added = true;
        for (let j in oldStages) {if (stages[i].id === oldStages[j].id) added = false}
        if (added) newStages.push({name: stages[i].name, position: i, description: stages[i].description})
      }
  
      //Get updated Stages
      let updatedStages:any[] = [];
      for(let i in stages) {
        for (let j in oldStages) {
          if (stages[i].id === oldStages[j].id && (stages[i].name !== oldStages[j].name || stages[i].description !== oldStages[j].description || i !== j)) {
            updatedStages.push({id: stages[i].id, name: stages[i].name, position: i, description: stages[i].description})
          }
        }
      }
      let pipeline = {
        name: this.state.name,
        description: this.state.description
      }
      if (this.props.item.pipeline.name !== this.state.name || this.props.item.pipeline.description !== this.state.description) {
        await new Promise((resolve, reject) => {
          userService.editPipeline(this.props.item.pipelineId, pipeline).then((response)=>{
            resolve(true);
          }).catch((error)=>{
            console.log(error);
            errorMessage = 'Error updating pipeline. Please try again.'
            this._isMounted && this.setState({error: errorMessage})
            resolve(true);
          })
        })
      }
      if (removedStages && removedStages.length > 0) {
        const stageIds:string[] = removedStages.map(stage => {return stage.id})
        await new Promise((resolve, reject) => {
          userService.deleteStages(this.props.item.pipelineId, stageIds).then(()=>{
            resolve(true);
          }).catch((error)=>{
            console.log(error);
            errorMessage = 'Error updating pipeline. Please try again.'
            this._isMounted && this.setState({error: errorMessage})
            resolve(true);
          })
        })
      }
      if (newStages && newStages.length > 0) {
        await new Promise((resolve, reject) => {
          userService.createStages(this.props.item.pipelineId, newStages).then(()=>{
            resolve(true);
          }).catch((error)=>{
            console.log(error);
            errorMessage = 'Error updating pipeline. Please try again.'
            this._isMounted && this.setState({error: errorMessage})
            resolve(true);
          })
        })
      }
      if (updatedStages && updatedStages.length > 0) {
        await new Promise((resolve, reject) => {
          userService.editStages(this.props.item.pipelineId, updatedStages).then(()=>{
            resolve(true);
          }).catch((error)=>{
            console.log(error);
            errorMessage = 'Error updating pipeline. Please try again.'
            this._isMounted && this.setState({error: errorMessage})
            resolve(true);
          })
        })
      }
  
      if (errorMessage && errorMessage.length > 0) {
        this._isMounted && this.setState({loading: false})
      } else {
        this._isMounted && this.setState({loading: false, hidden: true})
        this.props.getCurrentContent(true);
      }
    }
  }

  private onDragEnd(result) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const items = reorder(this.state.stages, source.index, destination.index);
    this._isMounted && this.setState({stages:items});
  }

  public render() {

    const { hidden, loading, loadingProperties, error } = this.state;

    return(
      <div>
        <Dialog
          hidden={hidden}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex align-items-center' style={{height: '30px'}}><img className='mr-3' style={{height: '28px', width: '28px'}} alt='Pipeline' src={process.env.PUBLIC_URL + 'icons/pipelines.svg'} />{i18n.t('app:editPipeline')}</div>,
            onDismiss: ()=> {this.close()},
            styles: {innerContent: {overflowY: loadingProperties ? 'none' : 'overlay'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog small-dialog full-height",
            onDismiss: ()=> {this.close()}
          }}
        >
          <div className='' style={{borderBottom: '1px solid #bfbfbf', width: 'calc(100% - 48px)', position: 'sticky', left: '24px', top: 0, zIndex: 1}}></div>
          {error && error.length > 0 && <div className='mx-4' style={{position: 'sticky', top: '1px', zIndex: 99}}><MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar></div>}
          <FluentProvider theme={webLightTheme} className='mt-4 mx-4'>
            <span className='form-label' style={{color: 'rgb(0, 120, 212)'}}>{i18n.t('app:name')}</span>
            <form className='p-0' id='form' onSubmit={this._submit.bind(this)}>
              <div className='mt-3 d-flex'>
                <div className='flex-grow-1'>
                  <div className='d-flex align-items-center mt-2'>
                    <Icon iconName='TextField' style={{marginTop: '3px', marginRight: '6px'}}/>
                    <Label className='form-label' style={{ marginTop: '2px'}} required>{i18n.t('app:name')}</Label>
                    <Input className='flex-grow-1' value={this.state.name} onChange={(e,v:any)=>this._updateName(v)} required/>
                  </div>
                </div>
              </div>
            </form>
            <div className='mt-4 d-flex'>
              <div className='flex-grow-1'>
                <div className='d-flex mt-2'>
                  <Icon iconName='TextField' style={{marginTop: '3px', marginRight: '6px'}}/>
                  <span className='form-label' style={{ marginTop: '2px'}}>{i18n.t('app:description')}</span>
                  <Textarea className='flex-grow-1' style={{minWidth: '10px'}} value={this.state.description} onChange={(e,v:any)=>this._updateDecription(v)}/>
                </div>
              </div>
            </div>
            <div className='my-4'>
              <span className='form-label' style={{color: 'rgb(0, 120, 212)'}}>{i18n.t('app:stages')}</span>
              <div className='mt-3'>
                <div className='d-flex align-items-center' style={{borderBottom: '1px solid #bfbfbf', padding: '11px', fontWeight: 600}}>
                  <span className='mr-2'>#</span>
                  <span className='mx-3'>{i18n.t('app:order')}</span>
                  <span className='mx-2 flex-grow-1'>{i18n.t('app:name')}</span>
                  <span className='mx-2 ml-4 flex-grow-1'>{i18n.t('app:description')}</span>
                </div>
                <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                  <Droppable droppableId={'stages-column'}>
                    {(provided, snapshot) => (
                      <div
                        //className='droppable-columns'
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {this.state.stages.map((item,index) => {
                          return (
                            <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div className='d-flex align-items-center my-3'>
                                  <Icon className='mr-2' iconName='GripperDotsVertical' style={{fontSize: 20}}/>
                                  <span className='mx-4' style={{fontWeight: 600}}>{index + 1}</span>
                                  <Input
                                    type='text'
                                    className='flex-grow-1'
                                    value={this.state.stages[index].name || ''}
                                    onChange={(e,value:any)=>{this._updateStages(index, 'name', value)}}
                                    style={{minWidth: '10px', marginLeft: '18px', marginRight: '18px'}}
                                    autoFocus
                                  />
                                  <Input
                                    type='text'
                                    className='flex-grow-1'
                                    value={this.state.stages[index].description || ''}
                                    onChange={(e,value:any)=>{this._updateStages(index, 'description', value)}}
                                    style={{minWidth: '10px'}}
                                  />
                                  {<IconButton  className='ml-2' onClick={()=>this._deleteStage(index)} iconProps={{iconName: 'Delete'}} />
                                  }           
                                </div>
                              </div>
                            )}
                          </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <ActionButton className='mt-3' onClick={()=> this._addStage()} iconProps={{iconName: 'Add'}} text={i18n.t('app:addStage')}/>
              </div>
            </div>
          </FluentProvider>
          {loadingProperties && <Spinner />}
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this.close()}} text={i18n.t('app:cancel')} disabled={loading} />
            <PrimaryButton form='form' type='submit' text={i18n.t('app:save')} disabled={loading || !this.state.name} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }

  private getStageColor(i:number) {
    let nStages = this.state.stages.length;
    let index = i + 1;
    return (
        <Icon 
          iconName="CircleShapeSolid" 
          className="tag mr-2" 
          style={{
            fontSize:12,
            textAlign:'center',
            color: 'rgb(16, 110, 190)',
            opacity: index/nStages,
            marginRight: '10px',
            width: '32px'
          }} 
        />
    )
  }

}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: '1px 0px 1px 5px',
  //margin: `0 0 14px 0`,
  //borderRadius: "2px",
  borderBottom: "1px solid lightgrey",
  background: 'white',
  //borderTop: isDragging ? "1px solid lightgrey" : 'none',
  

  // change background colour if dragging
  //background: isDragging ? "white" : "white",
  boxShadow: isDragging ? '4px 4px 4px rgba(0,0,0,0.1)' : 'none',

  // styles we need to apply on draggables
  ...draggableStyle
});